import * as React from 'react';
import { Props } from './PeriodForm.types';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import { getDatesFromRange } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.utils';
import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';

let styles = {
  container: '',
  picker: '',
  text: '',
  rangePicker: '',
};

export class PeriodForm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onNewWeeksSelected = (range: WeekRange) => {
    if (this.props.loaded === false) {
      return;
    }
    const { daysRangeList, onChangeStartWeek, onChangeEndWeek } = this.props;

    const dates = getDatesFromRange(range, daysRangeList);
    if (this.props.selectedStartWeek !== dates.start) {
      onChangeStartWeek(dates.start);
    }
    if (this.props.selectedEndWeek !== dates.end) {
      onChangeEndWeek(dates.end);
    }
  };

  render() {
    if (this.props.loaded === false) {
      return <div />;
    }
    const { rangeList, selectedStartWeek, selectedEndWeek, daysRangeList, label, classes } = this.props;
    styles = classes ? classes : styles;

    let allowFrom = '';
    let allowTo = '';
    if (Array.isArray(rangeList) && rangeList.length > 0) {
      allowFrom = rangeList[0].id;
      allowTo = rangeList[rangeList.length - 1].id;
    }

    return (
      <div className={styles.container} data-qa="datepicker">
        <div className={styles.picker}>
          <label className={styles.text}>{label}</label>
          <div className={styles.rangePicker}>
            <WeekRangePicker
              allowFrom={allowFrom}
              allowTo={allowTo}
              dateToNameMap={daysRangeList}
              initialSelectedFrom={selectedStartWeek || ''}
              initialSelectedTo={selectedEndWeek || ''}
              onNewWeeksSelected={this.onNewWeeksSelected}
              selectionOptions={[{ label: 'Start' }, { label: 'End' }]}
            />
          </div>
        </div>
      </div>
    );
  }
}
