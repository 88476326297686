import { z } from 'zod';
import { LOCATION, PRODUCT, TIME, DEFAULT_DIMENSIONS } from 'src/utils/Domain/Constants';

export const zRequiredDimensions = z.union([z.literal(PRODUCT), z.literal(LOCATION), z.literal(TIME)]);

export type Space<A> = { location: A; product: A; time: A; [k: string]: A };

const zSpaceRemainder = <T extends z.ZodTypeAny>(t: T, val: any) => {
  for (const k of Object.getOwnPropertyNames(val)) {
    // product, location, and time already validated, I guess we'll validate them again and shrug
    if (!t.safeParse(val[k]).success) {
      return false;
    }
  }
  return true;
};

export const zSpace = <T extends z.ZodTypeAny>(t: T) =>
  z.custom<Space<z.infer<T>>>(
    (val: any) =>
      t.safeParse(val.product).success &&
      t.safeParse(val.location).success &&
      t.safeParse(val.time).success &&
      zSpaceRemainder(t, val)
  );

export type RequiredDimension = z.infer<typeof zRequiredDimensions>;

export function isRequiredDimension(s: string): s is RequiredDimension {
  return [...DEFAULT_DIMENSIONS].some((dim) => dim === s);
}

export const URI = 'Space';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type URI = typeof URI;
