import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { projectState, StateSelection } from 'src/components/MacroGridPair/MacroGridPair.selectors';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ProductDetailsOwnProps } from './ProductDetails.container';

function selectState(state: AppState, ownProps: ProductDetailsOwnProps): StateSelection {
  const { title } = ownProps;
  const { productDetails: viewState } = state.pages.hindsighting.aggregateViews;
  const { macroDataState, gridDataState, viewDefns } = viewState;
  const macroData = getUniqueDataFromCache(viewState, HashType.macro)?.tree || [];
  const gridData = getUniqueDataFromCache(viewState, HashType.grid)?.tree || [];
  const configLoaded = !viewState.isConfigLoading;
  const gridDataLoaded = isDataLoaded(macroDataState);
  const macroDataLoaded = isDataLoaded(gridDataState);
  const dataLoaded = gridDataLoaded && macroDataLoaded;
  const viewDataState = [macroDataState, gridDataState];

  return {
    title,
    configLoaded,
    gridDataLoaded,
    macroDataLoaded,
    dataLoaded,
    subheaderState: state.subheader,
    macroData,
    gridData,
    viewDefns,
    viewDataState,
  };
}

export const selectData = createSelector(selectState, projectState);
