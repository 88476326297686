import { Lens } from 'monocle-ts';

import { AppState } from 'src/store';
import { AssortmentStrategySlice, PagesSlice } from 'src/pages/Pages.reducer';
import { HindsightingSlice } from 'src/pages/Hindsighting/Hindsighting.reducer';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { StyleColorReviewSlice } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { AssortmentBuildSlice } from 'src/pages/AssortmentBuild/AssortmentBuild.reducer';
import { AssortmentCartSlice } from 'src/pages/AssortmentCart/AssortmentCart.slice';
import { AllocationSlice } from 'src/pages/Allocation/Allocation.reducer';
import { TargetListReduxSlice } from 'src/pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.types';

export const pagesLens = Lens.fromProp<AppState>()('pages');
export const perspectiveLens = Lens.fromProp<AppState>()('perspective');
export const planTrackerLens = Lens.fromProp<AppState>()('planTracker');

export const scopeConfigLens = Lens.fromProp<AppState>()('scope');
export const scopeLensGetter = scopeConfigLens.asGetter();
export const scopeLens = scopeConfigLens.compose(Lens.fromProp<AssortmentScopeSelectorSlice>()('scope'));
export const subheaderLens = Lens.fromProp<AppState>()('subheader');

// Hindsighting lenses
export const hindsightingLens = pagesLens.compose(Lens.fromProp<AppState['pages']>()('hindsighting'));
export const styleColorReviewLens = hindsightingLens.compose(Lens.fromProp<HindsightingSlice>()('styleColorReview'));
export const canvasViewLens = styleColorReviewLens.compose(Lens.fromProp<StyleColorReviewSlice>()('canvasView'));
export const sharedDataLens = styleColorReviewLens.compose(Lens.fromProp<StyleColorReviewSlice>()('sharedData'));
export const exceptionsSummaryLens = hindsightingLens.compose(Lens.fromProp<HindsightingSlice>()('exceptionsSummary'));

// Assortment Strategy lenses
export const assortmentStrategyLens = pagesLens.compose(Lens.fromProp<PagesSlice>()('assortmentStrategy'));
export const targetSettingLens = assortmentStrategyLens.compose(
  Lens.fromProp<AssortmentStrategySlice>()('targetSetting')
);
export const targetListLens = assortmentStrategyLens.compose(Lens.fromProp<AssortmentStrategySlice>()('targetList'));
export const targetSettingNewLens = targetListLens.compose(Lens.fromProp<TargetListReduxSlice>()('targetNew'));

// Assortment Build lenses
export const assortmentBuildLens = pagesLens.compose(Lens.fromProp<PagesSlice>()('assortmentBuild'));
export const styleEditLens = assortmentBuildLens.compose(Lens.fromProp<AssortmentBuildSlice>()('styleEdit'));
export const assortmentCartLens = Lens.fromProp<AppState>()('cart');
export const assortmentAddStylesLens = assortmentBuildLens.compose(
  Lens.fromPath<AssortmentBuildSlice>()(['addStyles'])
);
export const assortmentCartItemsLens = assortmentCartLens.compose(Lens.fromProp<AssortmentCartSlice>()('cartItems'));

export const worklistLens = Lens.fromProp<AppState>()('worklist');

// Allocation Lenses
export const allocationLens = pagesLens.compose(Lens.fromProp<PagesSlice>()('allocation'));
export const allocationSizeEligibilityLens = allocationLens.compose(
  Lens.fromProp<AllocationSlice>()('sizeEligibility')
);
export const allocationParameterTogglesLens = allocationLens.compose(
  Lens.fromProp<AllocationSlice>()('parameterToggles')
);
