import { AxiosInstance } from 'axios';
import { ViewConfig } from 'src/state/ViewConfig/ViewConfig.types';
import { zReportConfig, ReportConfig } from './Reports.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { makeErrorLog } from 'src/components/Mfp/PivotConfigurator/utils';
import { zSmartPlanPlayload } from 'src/state/scope/codecs/SmartPlan/SmartPlanSection';

export const getConfigBaseRoute = (siloId: string) => `${API_BASE_URL}/silo/${siloId}/config`;

/**
 * MFP Configuration Service, separate from the Asst one for legacy reasons
 */
export class ConfigService {
  protected client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public getViewConfig(siloId: string): Promise<ViewConfig> {
    return this.client.get<ViewConfig>(`${getConfigBaseRoute(siloId)}/view-config`).then((response) => response.data);
  }

  public getReportConfig(siloId: string) {
    return this.client
      .get<ReportConfig>(`${getConfigBaseRoute(siloId)}/reports`)
      .then((response) => zReportConfig.parse(response.data));
  }

  public getSmartPlanWizardConfig(siloId: string) {
    return (
      this.client
        .get<unknown>(`${getConfigBaseRoute(siloId)}/smartplanwizard`)
        .then((resp) => zSmartPlanPlayload.parse(resp.data))
        // TODO this may or may not be wrapped in .data
        .then((r) => r)
        .catch(makeErrorLog('failed to get smartplan config'))
    );
  }
}

export default ConfigService;
