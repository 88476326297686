import { get, isFunction } from 'lodash/fp';
import { Store } from 'redux';

function predicateFromAccessor<T>(accessorStr: string) {
  return (oldState: T, newState: T) => {
    return get(accessorStr, oldState) !== get(accessorStr, newState);
  };
}

export default function createObserver<T>(store: Store<T>) {
  type Accessor = ((oldState: T, newState: T) => boolean) | string;
  let prevState = store.getState();

  return {
    on(predicate: Accessor, callback: (a: T) => void) {
      const actPredicate = isFunction(predicate) ? predicate : predicateFromAccessor<T>(predicate);
      prevState = store.getState();
      return store.subscribe(() => {
        const newState = store.getState();
        if (actPredicate(prevState, newState)) {
          prevState = newState;
          callback(newState);
        }
      });
    },
    once(predicate: Accessor, callback: (a: T) => void) {
      const actPredicate = isFunction(predicate) ? predicate : predicateFromAccessor<T>(predicate);
      prevState = store.getState();
      const unsubscribe = store.subscribe(() => {
        const newState = store.getState();
        if (actPredicate(prevState, newState)) {
          prevState = newState;
          unsubscribe();
          callback(newState);
        }
      });
    },
  };
}
