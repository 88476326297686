import { get, has, isObject } from 'lodash';

export function maybeReturnNestData(respO: Record<string, unknown>) {
  if (has(respO, 'data')) {
    const nested = respO.data;
    if (isObject(nested) && has(nested, 'data')) {
      return get(nested, 'data');
    } else {
      return respO.data;
    }
  } else {
    return respO;
  }
}
