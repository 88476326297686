import { ChartDimensionConfig } from './EditableChart.slice';
import {
  ServerMetric,
  Formats,
  ServerScope,
  isScopeReady,
  ServerScopeResponse,
  ServerScopeMember,
} from 'src/state/scope/Scope.types';
import { PivotCell } from 'src/pivot/PivotCell';
import PivotManager from 'src/pivot/Pivot.client';
import numeral from 'numbro';

import { merge } from 'lodash';
import Highcharts, { PointDropEventObject } from 'highcharts';
import { seriesColors, markerColors, markerFillColors } from './EditableChartHooks';
import { METRICS } from 'src/utils/Domain/Constants';
import { useCallback, useMemo } from 'react';
import { ActionCreatorWithPreparedPayload } from '@reduxjs/toolkit';
import { DropdownItemProps, StrictDropdownProps } from 'semantic-ui-react';
import { memberToDropdown } from 'src/components/Mfp/MfpScopeSelector/MfpScopebar.container';

const getMetricFromId = function(mainConfig: ServerScope, dimId: string): ServerScope['metrics'][0] {
  const met = mainConfig.metrics.find((m) => m.id === dimId.replace('member:', ''));
  if (!met) {
    throw new Error('Metric id not found in chart, this shouldnt happen');
  }
  return met;
};

const formatToAxisLabel = (formats: Formats): string => {
  const standard = formats.standard!.replace(/[^$|%]/g, '');
  switch (standard) {
    case '$':
      return '$';
    case '%':
      return '%';
    default:
      return '';
  }
};

const dimensionToAxis = (metric: ServerMetric, index: number): Highcharts.YAxisOptions => {
  return {
    title: {
      text: `${metric.name} ${formatToAxisLabel(metric.formats!)}`,
    },
    opposite: index % 2 === 1,
  };
};

export const chartConfigToChartOptions = (
  chartConfig: ChartDimensionConfig,
  mainConfig: ServerScope,
  oldChartOptions: Highcharts.Options,
  updatePoint: (event: PointDropEventObject) => void
): Highcharts.Options => {
  const { xAxisDimension } = chartConfig;

  // setup the empty yAxis
  const axes = chartConfig.metricDimensions.items
    .map((metricId) => {
      return getMetricFromId(mainConfig, metricId);
    })
    .map(dimensionToAxis);

  const series = chartConfig.metricDimensions.items
    .map((id) => {
      return mainConfig.metrics.find((m) => m.id === id.replace('member:', ''))!;
    })
    .map(dimensionToSeriesOptions);

  let newChartOptions: Highcharts.Options = merge(oldChartOptions, {
    series,
    yAxis: axes,
    plotOptions: {
      series: {
        point: {
          events: {
            drop: updatePoint,
          },
        },
      },
    },
  });
  // setup the xAxis categories
  // @ts-ignore
  const levelMembers = (mainConfig.members[xAxisDimension.dimension] as ServerScopeMember[])
    .filter((i) => i.level === xAxisDimension.items[0].replace('level:', ''))
    .map((l) => l.name)
    .sort();
  return (newChartOptions = merge(newChartOptions, {
    xAxis: {
      categories: levelMembers,
    },
  }));
};

export const getChartData = (pivotManager: PivotManager): Promise<PivotCell[]> => {
  const results: PivotCell[] = [];

  const maxCols = pivotManager.getColCount();
  const maxRows = pivotManager.getRowCount();

  // TODO pre-load the cells with unloaded values so they can be set here
  pivotManager.forAllPresent((cell: PivotCell) => {
    cell.needsReload = true;
  });
  return pivotManager
    .loadMoreCells({
      startRow: 0,
      endRow: maxRows,
      startColumn: 0,
      endColumn: maxCols,
    })
    .then(() => {
      for (let r = 0; r <= maxRows; r++) {
        for (let c = 0; c < maxCols; c++) {
          const cell = pivotManager.getCell(r, c);
          if (cell) {
            results.push(cell);
          }
        }
      }
      return results;
    });
};

export const metricSelectionToChartDimensionConfig = (
  metricId: string,
  idx: number,
  oldConfig: ChartDimensionConfig
): ChartDimensionConfig => {
  const newItems = [...oldConfig.metricDimensions.items];
  newItems[idx] = `member:${metricId}`;

  return {
    ...oldConfig,
    metricDimensions: {
      dimension: METRICS,
      items: newItems,
    },
  };
};

export const mergeTimeAndChartData = (cell: PivotCell): Highcharts.PointOptionsObject => {
  return {
    y: Number(cell.value),
  };
};

export const dimensionToSeriesOptions = (metric: ServerMetric, idx: number): Highcharts.SeriesLineOptions => {
  function formatter(this: Highcharts.Point) {
    // highcharts formatter callback function
    const standardFormat = metric.formats!.standard!; // TODO: add chartFormat?
    const formatFunction = standardFormat.includes('$') ? 'formatCurrency' : 'format';
    return this.series.name + ': ' + numeral(this.y)[formatFunction](standardFormat);
  }

  return {
    id: metric.id,
    name: metric.name,
    type: 'line',
    yAxis: idx,
    color: seriesColors[idx],
    dragDrop: {
      draggableY: true, // TODO check for non-actualized wp weeks here
      dragHandle: {
        cursor: 'ns-resize',
      },
    },
    marker: {
      fillColor: markerFillColors[idx],
      lineColor: markerColors[idx],
    },
    zoneAxis: 'x',
    tooltip: {
      pointFormatter: formatter,
    },
  };
};

export const defaultChartOptions: Highcharts.Options = {
  legend: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  chart: {
    height: 200,
  },
  yAxis: [
    {
      minorTicks: false,
      plotLines: [
        {
          // highlight y-axis zero
          color: '#696969',
          id: 'plotLineZero',
          width: 2,
          value: 0.0,
        },
      ],
    },
    {
      minorTicks: false,
    },
  ],
  xAxis: {
    minorTicks: false,
    crosshair: true,
  },
  plotOptions: {
    series: {
      lineWidth: 2,
      marker: {
        symbol: 'circle',
        lineWidth: 1,
      },
    },
  },
  tooltip: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
    borderWidth: 2,
    style: {
      color: '#000000',
    },
    padding: 5,
    split: true,
  },
};

export const useHandleChangeMetric = (
  requestChart: ActionCreatorWithPreparedPayload<[ChartDimensionConfig], ChartDimensionConfig, string, never, never>,
  chartDimensionConfig: ChartDimensionConfig | undefined,
  dimensionItemIndex: number
) => {
  return useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: StrictDropdownProps) => {
      if (!chartDimensionConfig) {
        return;
      }
      const { value } = data;
      const metricConfig = chartDimensionConfig.metricDimensions;
      if (typeof value === 'string') {
        // TODO make this not sad
        if (metricConfig.items[dimensionItemIndex] === value) {
          return;
        } // don't update it unless it's a new metric
        const newConfig = metricSelectionToChartDimensionConfig(value, dimensionItemIndex, chartDimensionConfig);
        requestChart(newConfig);
      }
    },
    [chartDimensionConfig, dimensionItemIndex, requestChart]
  );
};

export const useMemoDropdownMetrics = (
  mainConfig: ServerScopeResponse | undefined
): DropdownItemProps[] | undefined => {
  return useMemo(() => {
    if (mainConfig && isScopeReady(mainConfig)) {
      return mainConfig.metrics.map(memberToDropdown);
    }
    return undefined;
  }, [mainConfig]);
};

export const useSelectedMetric = (
  index: number,
  dropdownMetrics: DropdownItemProps[] | undefined,
  chartDimensionConfig: ChartDimensionConfig | undefined
) => {
  return useMemo(() => {
    return dropdownMetrics && chartDimensionConfig
      ? chartDimensionConfig.metricDimensions.items[index].replace('member:', '')
      : undefined;
  }, [index, dropdownMetrics, chartDimensionConfig]);
};
