import { style } from 'typestyle';
import { important, rem } from 'csx';

const buttonSize = 2.2; // rem
const calculatedButtonSize = important(rem(buttonSize) as number);

const acceptButton = style({
  width: calculatedButtonSize,
  minWidth: calculatedButtonSize,
  height: calculatedButtonSize,
  backgroundColor: important('#00a89b'),
  color: important('white'),
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: important(0),
});

export default acceptButton;
