import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapValues } from 'lodash';
import { PivotCell } from 'src/pivot/PivotCell';
import { TopMembers } from 'src/services/Scope.client';
import {
  anchorIdsFromMemberTrees,
  clearScope,
  receivedCreateScope,
  receivedScope,
  requestScope,
} from 'src/state/scope/Scope.slice';
import { createNewNonWorkingScope } from 'src/state/workingSets/nonWorkingSets.slice';

export interface MacroSummariesSlice {
  summariesLoading: boolean;
  metricsToSummarize: string[] | null;
  summaryData: PivotCell[] | null;
  versionsToSummarize: VersionsToSummarize | null;
  selectedMacroAnchors: TopMembers | null;
}

export interface VersionsToSummarize {
  primary: string;
  secondary: string;
  delta: string;
}
const initialSummariesState: MacroSummariesSlice = {
  summariesLoading: false,
  metricsToSummarize: null,
  versionsToSummarize: null,
  summaryData: null,
  selectedMacroAnchors: null,
};

export type SummaryVersions = [string, string, string];
interface SetMetricsPayload {
  metricsToSummarize: string[];
  versionsToSummarize: SummaryVersions;
}

const macroSummariesSliceReducer = createSlice({
  name: 'macroSummaries',
  initialState: initialSummariesState,
  reducers: {
    setMetricsToSummarize: {
      prepare: (metrics: string[], versions: [string, string, string]) => {
        return {
          payload: {
            metricsToSummarize: metrics,
            versionsToSummarize: versions,
          },
        };
      },
      reducer: (state, action: PayloadAction<SetMetricsPayload>) => {
        return {
          ...(state as MacroSummariesSlice),
          versionsToSummarize: {
            primary: action.payload.versionsToSummarize[0],
            secondary: action.payload.versionsToSummarize[1],
            delta: action.payload.versionsToSummarize[2],
          },
          metricsToSummarize: action.payload.metricsToSummarize,
        };
      },
    },
    setSelcetedMacroAnchors: (state, action: PayloadAction<TopMembers>) => {
      state.selectedMacroAnchors = action.payload;
    },
    requestSummarizedScope: (state) => {
      return {
        ...(state as MacroSummariesSlice),
        summariesLoading: true,
      };
    },
    receivedSummarizedScope: (state, action: PayloadAction<PivotCell[]>) => {
      return {
        ...(state as MacroSummariesSlice),
        summariesLoading: false,
        summaryData: action.payload,
      };
    },
    summaryError: () => {
      return initialSummariesState;
    },
    clearSummary: () => {
      return initialSummariesState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestScope, () => initialSummariesState);
    builder.addCase(clearScope, () => initialSummariesState);
    builder.addCase(createNewNonWorkingScope, (state) => {
      state.metricsToSummarize = null;
      state.summaryData = null;
    });
    builder.addCase(receivedScope, (state, action) => {
      if (action.payload.type === 'ScopeReady') {
        const anchorIds = anchorIdsFromMemberTrees(action.payload.memberTrees);
        state.selectedMacroAnchors = mapValues(anchorIds, (ids) => [ids[0]]);
      }
    });
    builder.addCase(receivedCreateScope, (state, action) => {
      if (action.payload.type === 'ScopeReady') {
        const anchorIds = anchorIdsFromMemberTrees(action.payload.memberTrees);
        state.selectedMacroAnchors = mapValues(anchorIds, (ids) => [ids[0]]);
      }
    });
    return builder;
  },
});

export const {
  setMetricsToSummarize,
  requestSummarizedScope,
  setSelcetedMacroAnchors,
  receivedSummarizedScope,
  summaryError,
  clearSummary,
} = macroSummariesSliceReducer.actions;
export default macroSummariesSliceReducer.reducer;
