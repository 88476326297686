import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewItem, GeoTrendsConfigData, ViewDefnState } from 'src/dao/tenantConfigClient';
import { ExtendedPointObject } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { Pivot, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { GeoTrendsChartsDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { ViewDataState } from 'src/types/Domain';

export interface TenantResponse {
  mapConfig?: GeoTrendsConfigData;
  chartConfig?: GeoTrendsChartsDefn;
  showFlowStatus: boolean;
  showLookBackPeriod: boolean;
}

interface GeoTrendsSlice {
  mapUri: string;
  mapData: BasicPivotItem[];
  mapDataState: ViewDataState;
  chartData: BasicPivotItem[];
  chartDataState: ViewDataState;
  viewDefnState: ViewDefnState;
  chartConfig?: GeoTrendsChartsDefn;
  mapConfig?: GeoTrendsConfigData;
  selectedItem: ExtendedPointObject | undefined;
  selectedGeoLevel?: TenantConfigViewItem;
  showLookBackPeriod: boolean;
  showFlowStatus: boolean;
}

interface Geodata {
  data: Pivot;
  selectedGeoLevel: TenantConfigViewItem;
}

const initialState: GeoTrendsSlice = {
  mapUri: '',
  chartConfig: undefined,
  mapData: [],
  mapDataState: ViewDataState.idle,
  mapConfig: undefined,
  chartData: [],
  chartDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
  selectedItem: undefined,
  selectedGeoLevel: undefined,
  showLookBackPeriod: true,
  showFlowStatus: true,
};

const geoTrendsSlice = createSlice({
  name: 'GeoTrends',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.viewDefnState = ViewDefnState.idle;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.mapConfig = action.payload.mapConfig;
      state.chartConfig = action.payload.chartConfig;
      state.showFlowStatus = action.payload.showFlowStatus;
      state.showLookBackPeriod = action.payload.showLookBackPeriod;
    },
    requestGeoData(state) {
      state.mapDataState = ViewDataState.idle;
    },
    receiveGeoData(state, action: PayloadAction<Geodata>) {
      state.mapDataState = ViewDataState.liveDataReady;
      state.mapData = action.payload.data.tree;
      state.selectedGeoLevel = action.payload.selectedGeoLevel;
    },
    selectGeoLevel(state, action: PayloadAction<TenantConfigViewItem>) {
      state.selectedGeoLevel = action.payload;
    },
    geotrendsRequestChartData(state) {
      state.chartDataState = ViewDataState.idle;
    },
    geotrendsReceiveChartData(state, action: PayloadAction<BasicPivotItem[]>) {
      state.chartData = action.payload;
      state.chartDataState = ViewDataState.liveDataReady;
    },
    selectPoint(state, action: PayloadAction<ExtendedPointObject>) {
      // don't bother trying to get the types right here
      state.selectedItem = action.payload as any;
    },
    resetPoint(state) {
      state.selectedItem = undefined;
    },
    cleanUp() {
      return initialState;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    receiveGeoDataError(state) {
      state.mapDataState = ViewDataState.liveDataReady;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestGeoData,
  receiveGeoData,
  selectGeoLevel,
  geotrendsRequestChartData,
  geotrendsReceiveChartData,
  selectPoint,
  resetPoint,
  cleanUp,
  receiveError,
  receiveGeoDataError,
} = geoTrendsSlice.actions;

export default geoTrendsSlice.reducer;
