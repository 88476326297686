import React, { useCallback } from 'react';
import { isNil } from 'lodash';
import { AccordionActions } from '@material-ui/core';
import '@ag-grid-community/styles/ag-grid.css';
import { Button, DropdownItemProps, StrictDropdownItemProps } from 'semantic-ui-react';

import './_submission-wizard.scss';
import { checkedIcon, uncheckedIcon } from 'src/components/Mfp/PivotConfigurator/PivotConfigurator';
import { ControlPoints, WizardSection } from './submission-wizard.types';

export function setWizardDefaults(
  planMovementOption: StrictDropdownItemProps | undefined,
  controlPoints: ControlPoints
) {
  const [_from, _, to] = parsePlanMovementOptionValue(planMovementOption);

  // select all products/locations by default
  const controlPoint = controlPoints[to];
  const productsArr = controlPoint.product.map((product) => product.id);
  const locationsArr = controlPoint.location.map((clearlocation) => clearlocation.id);
  // set time to current year
  const currentYear = new Date().getFullYear().toString();
  const timeObj = controlPoint.time.find((time) => time.id === currentYear);
  const time = !isNil(timeObj) ? timeObj.id : '';
  const timeOptions = controlPoint.time.map((time) => {
    return {
      text: time.name,
      value: time.id,
    };
  });

  return {
    selectedProducts: new Set(productsArr),
    selectedLocations: new Set(locationsArr),
    time,
    timeOptions,
  };
}

export function setWizardActualizeDefaults(
  planMovementOption: StrictDropdownItemProps | undefined,
  controlPoints: ControlPoints
) {
  const to = (planMovementOption?.value as string) || '';

  // select all products/locations by default
  const controlPoint = controlPoints[to];
  const productsArr = controlPoint.product.map((product) => product.id);
  const locationsArr = controlPoint.location.map((location) => location.id);
  // set time to current year
  const currentYear = new Date().getFullYear().toString();
  const timeObj = controlPoint.time.find((time) => time.id === currentYear);
  const time = !isNil(timeObj) ? timeObj.id : '';
  const timeOptions = controlPoint.time.map((time) => {
    return {
      text: time.name,
      value: time.id,
    };
  });

  return {
    selectedProducts: new Set(productsArr),
    selectedLocations: new Set(locationsArr),
    time,
    timeOptions,
  };
}

export function getSelectedOption(
  dataValue: string,
  options: DropdownItemProps[] | undefined
): { text: string; option: DropdownItemProps | undefined } {
  const option = options?.find(({ value, text }) => value === dataValue || text === dataValue);
  const text = option?.text?.toString() || '';
  return { text, option };
}

// parse option value to get 'To' value (i.e. sv-to-[rp])
export function parsePlanMovementOptionValue(planMovementOption: StrictDropdownItemProps | undefined): string[] {
  const value = (!isNil(planMovementOption) ? planMovementOption.value || '' : '') as string;
  const [_from, _, to] = value.split('-');
  return [_from, _, to];
}

type WizardActionsProps = {
  section: number;
  onContinue: (section: number) => void;
  onGoBack: (section: number) => void;
  loading?: boolean;
  disabled?: boolean;
};

export const WizardActions = ({
  section,
  onContinue,
  onGoBack,
  loading = false,
  disabled = false,
}: WizardActionsProps) => {
  // determines continue or submit
  const showContinue = section !== WizardSection.Summary;
  const showBackButton = section !== WizardSection.Plan;
  return (
    <AccordionActions>
      {showBackButton && <Button content="Back" onClick={() => onGoBack(section)} />}
      <Button
        content={showContinue ? 'Continue' : 'Submit'}
        className="submission-wizard-continue-button"
        data-qa={`submission-wizard-btn-${showContinue ? 'continue' : 'submit'}`}
        onClick={() => onContinue(section)}
        loading={loading}
        disabled={disabled || loading}
      />
    </AccordionActions>
  );
};

type TogglesSelectAllProps = {
  isChecked: boolean;
  onCheckBoxToggle: () => void;
};

export const TogglesSelectAll = ({ isChecked, onCheckBoxToggle }: TogglesSelectAllProps) => {
  return (
    <div className="toggles-select-all" onClick={onCheckBoxToggle}>
      <span className={'toggles-select-all-text'}>Select All?</span>
      <div className={`toggles-select-all-checkbox ${isChecked ? checkedIcon : uncheckedIcon}`} />
    </div>
  );
};

type TogglesStatus = {
  itemCount: number;
  selectionCount: number;
};

export const TogglesStatus = ({ itemCount, selectionCount }: TogglesStatus) => {
  return (
    <div className="toggles-status">
      <span>{`Total: ${itemCount} | Selected: ${selectionCount}`}</span>
    </div>
  );
};

type TabPanelProps = {
  value: number;
  index: number;
};

export const TabPanel: React.FunctionComponent<TabPanelProps> = ({ value, index, children }) => {
  return <div>{value === index && children}</div>;
};

export const useGoForward = (setExpanded: (value: React.SetStateAction<number>) => void, maxSteps: number) => {
  return useCallback(
    (section: number) => {
      if (section <= maxSteps) {
        setExpanded(section + 1);
      }
    },
    [maxSteps, setExpanded]
  );
};

export const useGoBack = (setExpanded: (value: React.SetStateAction<number>) => void) => {
  return useCallback(
    (section: WizardSection) => {
      if (section === 0) {
        // already at the beginning, don't do anything
        return;
      }

      setExpanded(section - 1);
    },
    [setExpanded]
  );
};
