import React from 'react';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';
import ChoiceProductivity from 'src/common-ui/components/Macros/ChoiceProductivity/ChoiceProductivity';
import KeyFinancial from 'src/common-ui/components/Macros/KeyFinancial/KeyFinancial';
import { MacrosAccordionProps } from 'src/common-ui/components/Macros/Macros.types';

export const ChoiceProductivityAccordion = ({
  dataLoaded,
  macros,
  overrideTransition = false,
  onToggleAccordion,
}: MacrosAccordionProps) => {
  const [keyFinancial, ...productivityItems] = macros;
  return (
    <StyledAccordion
      expanded={true}
      title="Choice Productivity"
      onToggle={onToggleAccordion}
      overrideTransition={overrideTransition}
    >
      <div className="choice-productivity-container">
        <div className="fin-wrapper">
          <KeyFinancial
            metrics={keyFinancial?.metrics || []}
            extraClasses={keyFinancial?.extraClasses}
            dataLoaded={dataLoaded}
            ignoreHorizontalSpacing={true}
          />
        </div>
        <div className="choice-wrapper">
          {productivityItems.map((item, i) => {
            return <ChoiceProductivity key={i} {...item} dataLoaded={dataLoaded} />;
          })}
        </div>
      </div>
    </StyledAccordion>
  );
};
