import { AxiosInstance } from 'axios';
import _ from 'lodash';
import PivotConfig from '../pivot/PivotConfig';
import {
  PivotPayload,
  DimensionPivotMap,
  DIMENSION_TYPE,
  METRIC_TYPE,
  MetricPivotMap,
  VersionPivotMap,
  VERSION_TYPE,
  PivotResponsePayload,
  DELTA_VARTYPE,
} from './Pivot.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { VARIANCE_VERSION } from 'src/utils/Domain/Constants';

export interface MockCell {
  row: Position['r'];
  col: Position['c'];
  metricId: string;
  value?: string;
  tags: string[]; // TODO: type this
  advisoryTags: string[];
  currencyId: string | null;
  formatKey: string;
  coreVer: string[];
}
export interface Position {
  r: number;
  c: number;
}

export interface CellPosition {
  position: Position;
  cell: MockCell;
}

export interface LockState {
  cellLocked: boolean;
  globalLocked: boolean;
}

const BASE_CONTEXT = `${API_BASE_URL}/context`;

export default class Pivot {
  protected client: AxiosInstance;
  private pivotName: string;

  constructor(client: AxiosInstance, pivotName: string) {
    this.client = client;
    // TODO: revisit this as a getter
    this.pivotName = encodeURIComponent(pivotName);
  }

  public createPivotContext(config: PivotConfig): Promise<PivotResponsePayload> {
    if (!config.scopeId) {
      return Promise.reject('No Scope Selected');
    }
    const payloadJson: PivotPayload = {
      rows: [],
      columns: [],
      pivotName: this.pivotName,
      relocalizeCurrency: config.relocalizeCurrency,
    };
    config.getColGroups().forEach((group) => {
      const isMeasure = group.isMeasureGroup();
      if (!group.isRevisionGroup()) {
        // @ts-ignore
        const groupJson: DimensionPivotMap | MetricPivotMap = {
          type: isMeasure ? METRIC_TYPE : DIMENSION_TYPE,
          members: group.getVisible().map((field) => field.member.id),
        };
        if (!isMeasure) {
          // @ts-ignore
          groupJson.on = group.dimension.id;
          // @ts-ignore
          groupJson.h = group.dimension.hierarchy;
        }
        payloadJson.columns.push(groupJson);
      } else {
        const groupJson: VersionPivotMap = {
          type: VERSION_TYPE,
          members: group.getVisible().map((field) => {
            if (field.member.type === VARIANCE_VERSION) {
              const o = {
                type: VARIANCE_VERSION,
                version: field.member.id.split('||')[0],
                against: field.member.id.split('||')[1],
                // not actually always DELTA, but forced here because I am morally bankrupt
                varType: field.member.id.split('||')[2] as typeof DELTA_VARTYPE,
                hidden: false,
              } as const;
              return o as any;
            }
            const o = {
              // not actually always SINGLE, but forced here because I am morally bankrupt
              type: field.member.type!,
              version: field.member.id.replace(field.member.type!, ''),
              hidden: false,
            } as const;
            return o as any;
          }),
        };
        payloadJson.columns.push(groupJson);
      }
    });
    config.getRowGroups().forEach((group) => {
      const isMeasure = group.isMeasureGroup();
      if (!group.isRevisionGroup()) {
        // @ts-ignore
        const groupJson: DimensionPivotMap | MetricPivotMap = {
          type: isMeasure ? METRIC_TYPE : DIMENSION_TYPE,
          members: group.getVisible().map((field) => field.member.id),
        };
        if (!isMeasure) {
          // @ts-ignore
          groupJson.on = group.dimension.id;
          // @ts-ignore
          groupJson.h = group.dimension.hierarchy;
        }
        payloadJson.rows.push(groupJson);
      } else {
        const groupJson: VersionPivotMap = {
          type: VERSION_TYPE,
          members: group.getVisible().map((field) => {
            if (field.member.type === VARIANCE_VERSION) {
              return {
                type: VARIANCE_VERSION,
                version: field.member.id.split('||')[0],
                against: field.member.id.split('||')[1],
                // not actually always DELTA, but forced here because I am morally bankrupt
                varType: field.member.id.split('||')[2] as typeof DELTA_VARTYPE,
                hidden: false,
              } as any;
            }
            return {
              // not actually always SINGLE, but forced here because I am morally bankrupt
              type: field.member.type!,
              version: field.member.id.replace(field.member.type!, ''),
              hidden: false,
            } as any;
          }),
        };
        payloadJson.rows.push(groupJson);
      }
    });

    return this.client
      .put<PivotResponsePayload>(`${BASE_CONTEXT}/${config.scopeId}/pivot/${this.pivotName}`, payloadJson)
      .then((response) => response.data);
  }

  public updateCell(cfg: PivotConfig, r: number, c: number, value: any) {
    return this.client
      .post<any>(`${BASE_CONTEXT}/${cfg.scopeId}/pivot/${this.pivotName}/cells/${r}/${c}`, value)
      .then((response) => response.data);
  }

  public updateCells(cfg: PivotConfig, cells: any[]) {
    const payload = JSON.stringify(cells);

    return this.client
      .post<any>(`${BASE_CONTEXT}/${cfg.scopeId}/pivot/${this.pivotName}/cells`, payload)
      .then((response) => response.data);
  }

  public queryCells(
    cfg: PivotConfig,
    positions: Position[],
    numRows: number,
    numCols: number
  ): Promise<CellPosition[]> {
    const rows = positions.map((p) => p.r);
    const cols = positions.map((p) => p.c);
    let rMin = _.min(rows);
    let rMax = _.max(rows);
    let cMin = _.min(cols);
    let cMax = _.max(cols);

    if (typeof rMin !== 'number' || typeof rMax !== 'number' || typeof cMin !== 'number' || typeof cMax !== 'number') {
      return Promise.resolve([]);
    }

    const range = 10;
    rMin = Math.floor(rMin / range) * range;
    rMax = Math.min(numRows - 1, Math.ceil(rMax / range) * range);
    cMin = Math.floor(cMin / range) * range;
    cMax = Math.min(numCols - 1, Math.ceil(cMax / range) * range);

    return this.client
      .get<MockCell[]>(`${BASE_CONTEXT}/${cfg.scopeId}/pivot/${this.pivotName}/cells`, {
        params: {
          rowstart: rMin,
          rowend: rMax,
          colstart: cMin,
          colend: cMax,
        },
      })
      .then((response) => response.data)
      .then((values) => {
        return values.map((cell) => {
          return {
            cell,
            position: { r: cell.row, c: cell.col },
          };
        });
      });
  }

  public toggleLockableCells(cfg: PivotConfig, positions: Position[], lockState: string): Promise<boolean> {
    let prevPromise;

    for (const pos of positions) {
      const lockStateJson = JSON.stringify(lockState);
      if (prevPromise) {
        prevPromise = prevPromise.then(() => {
          return this.client
            .put<LockState>(
              `${BASE_CONTEXT}/${cfg.scopeId}/pivot/${this.pivotName}/cells/${pos.r}/${pos.c}/locked`,
              lockStateJson
            )
            .then((response) => response.data.globalLocked);
        });
      } else {
        prevPromise = this.client
          .put<LockState>(
            `${BASE_CONTEXT}/${cfg.scopeId}/pivot/${this.pivotName}/cells/${pos.r}/${pos.c}/locked`,
            lockStateJson
          )
          .then((response) => response.data.globalLocked);
      }
    }

    if (prevPromise) {
      return prevPromise;
    }

    return Promise.reject('No Cells');
  }
}
