export const topOptions = [
  {
    dataIndex: 'department',
    text: 'Department',
    api: 'level',
  },
  {
    dataIndex: 'class',
    text: 'Class',
    api: 'level',
  },
  {
    dataIndex: 'powerdriver',
    text: 'Powerdriver',
    api: 'level',
  },
];

export const targetFirst = [
  {
    dataIndex: 'department',
    text: 'Department',
    api: 'level',
  },
  {
    dataIndex: 'class',
    text: 'Class',
    api: 'level',
  },
  {
    dataIndex: 'powerdriver',
    text: 'Powerdriver',
    api: 'level',
  },
  {
    dataIndex: 'class2',
    text: 'Class',
    api: 'level',
  },
  {
    dataIndex: 'subClass2',
    text: 'SubClass',
    api: 'level',
  },
];

export const targetOptions = [
  {
    dataIndex: 'class',
    options: [
      {
        text: 'Dresses - Everyday',
        dataIndex: 'CL-111',
      },
      {
        text: 'Dresses - Occasion',
        dataIndex: 'CL-112',
      },
    ],
  },
  {
    dataIndex: 'subClass',
    options: [
      {
        text: 'Party',
        dataIndex: 'SB-112-1',
      },
      {
        text: 'Work',
        dataIndex: 'SB-111-2',
      },
      {
        text: 'Wedding Guest',
        dataIndex: 'SB-112-2',
      },
      {
        text: 'Casual',
        dataIndex: 'SB-111-1',
      },
    ],
  },
];

export const options = [
  {
    dataIndex: 'department',
    multiSelect: false,
    options: [
      {
        dataIndex: 'class1',
        text: 'Class',
      },
      {
        dataIndex: 'subClass1',
        text: 'SubClass',
      },
    ],
  },
  {
    dataIndex: 'powerdriver',
    options: [
      {
        dataIndex: 'climate',
        text: 'Climate',
      },
      {
        dataIndex: 'income',
        text: 'Income',
      },
    ],
  },
];

export const seedPlan = [
  {
    dataIndex: 'mfp',
    text: 'MFP',
  },
  {
    dataIndex: 'ly',
    text: 'LY',
  },
  {
    dataIndex: 'userdef',
    text: 'User Defined',
  },
];

export const seedOptions = [
  {
    dataIndex: 'mfp',
    options: [
      {
        dataIndex: 'total_sls',
        text: 'Total Sales',
      },
    ],
  },
  {
    dataIndex: 'ly',
    options: [
      {
        dataIndex: 'total_sls',
        text: 'Total Sales',
      },
    ],
  },
  {
    dataIndex: 'userdef',
    options: [
      {
        dataIndex: 'total_sls',
        text: 'Total Sales',
      },
    ],
  },
];
