import { connect } from 'react-redux';
import { flow, get } from 'lodash';

import CollectionView from 'src/components/views/CollectionView/CollectionView';
import container from 'src/ServiceContainer';
import { ASSORTMENT, STYLE_ID } from 'src/utils/Domain/Constants';
import { AppState, AppThunkDispatch } from 'src/store';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import {
  CollectionViewDispatchProps,
  CollectionViewStateProps,
} from 'src/components/views/CollectionView/CollectionView.types';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import {
  receiveCollectionViewTenantConfig,
  receiveError as receiveCollectionViewError,
  requestCollectionViewTenantConfig,
} from './CollectionView.slice';
import {
  getProcessedStyleColorData,
  getStyleColorSummary,
  getFilteredFlatStyleColorData,
} from './CollectionView.selectors';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { CollectionViewComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { CollectionViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { getfabProps } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

export type EmptyOwnProps = Record<string, unknown>;
export interface CollectionViewOwnProps extends z.infer<typeof CollectionViewComponentProps> {}

function mapStateToProps(
  state: AppState,
  { defns, fabType = FabType.none }: CollectionViewOwnProps
): CollectionViewStateProps {
  const { collectionView: viewState } = state.pages.hindsighting.styleColorReview;
  const styles = getProcessedStyleColorData(state);
  const flatStyles = getFilteredFlatStyleColorData(state);
  const summary = getStyleColorSummary(state);
  const loaded = !viewState.isConfigLoading && isDataLoaded(viewState.viewDataState);
  const fabProps = getfabProps(state, fabType);
  const adornments: AdornmentType[] = get(viewState.viewDefn, 'adornments', []);

  return {
    ...viewState,
    loaded,
    sortBy: state.subheader.sortBy,
    groupBy: state.subheader.groupBy,
    subheaderViewDefns: defns.subheader,
    styles,
    flatStyles,
    summary,
    viewDataState: viewState.viewDataState,
    fab: fabProps,
    adornments
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: CollectionViewOwnProps): CollectionViewDispatchProps {
  const { tenantConfigClient } = container;
  const { defns, keys } = ownProps;
  return {
    onShowView() {
      dispatch(requestCollectionViewTenantConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [CollectionViewDefn],
        })
        .then(([viewDefn, favs]) => {
          // need a better type here, like getTenantViewDefnsWithFavorites returns a tuple of which has the last member always as a favorite item
          // @ts-ignore
          getLocalConfig(defns.view[0], favs, dispatch);
          dispatch(
            receiveCollectionViewTenantConfig({
              viewDefn,
              identityProps: keys,
            })
          );
        })
        .catch((error) => {
          return dispatch(
            receiveCollectionViewError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.collectionView,
              issues: error,
              defnId: error.defnId,
            })
          );
        });
    },
    onItemClicked(item: BasicPivotItem) {
      if (!ownProps.showPopover) {
        dispatch(
          setRightContainerPayload({
            type: RightContainerPayloadType.History,
            id: item.id,
            parentId: item[STYLE_ID],
          })
        );
      }
    },
  };
}

const wrappedView = flow(() => CollectionView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
