import store, { AppState } from 'src/store';
import { AppType, BoundEnabledPerspective, BoundPerspective } from 'src/services/configuration/codecs/bindings.types';
import { isNil } from 'lodash';

export function provideAppName<T>(f: (_appName: AppType) => Promise<T>) {
  const perspective = store.getState().perspective.selected;
  if (!perspective) {
    return Promise.reject(new Error('You have no perspective selected.'));
  }
  return f(perspective.appType);
}

export interface ProvideAppContextArgs {
  appName: AppType;
  fingerprint?: string;
}
export function provideAppContext<T>(f: (context: ProvideAppContextArgs) => Promise<T>) {
  const state = store.getState();
  const perspective = state.perspective.selected;
  const fingerprint = state.appConfig.configFingerprint;
  if (!perspective) {
    return Promise.reject(new Error('You have no perspective selected.'));
  }
  return f({
    appName: perspective.appType,
    fingerprint,
  });
}

export function isHistoryTabContext(): boolean {
  const state = store.getState();
  const tabContexts = state.appConfig.tenantConfig.context;
  const currentTab = state.perspective.activeTab || '';

  // converts current tab to same structure/casing as tabContexts
  const normalizedTab = currentTab.replace('-', '').toLowerCase();
  const isHistory = tabContexts.hindsighting.tabs.find((context) => context.toLowerCase() === normalizedTab);

  return !isNil(isHistory);
}

/** @deprecated, see `getActiveContextString` in `PerspectiveSelection.tsx` */
export function getSectionContext() {
  const state: AppState = store.getState();
  const context = state.appConfig.tenantConfig.context;
  const activeTab = state.perspective.activeTab.toLowerCase().replace('-', '');
  // find the index of the context that contains the activeTab
  const activeContextIndex = Object.values(context)
    .map((ctx) => ctx.tabs.map((tab) => tab.toLowerCase()))
    .findIndex((x) => x.indexOf(activeTab) > -1);
  return activeContextIndex > -1 ? Object.keys(context)[activeContextIndex] : '';
}

/** @deprecated, see `getActiveContext` in `PerspectiveSelection.tsx` */
export function getSectionContextObj() {
  const state: AppState = store.getState();
  const context = state.appConfig.tenantConfig.context;
  const activeTab = state.perspective.activeTab.toLowerCase().replace('-', '');
  // find the index of the context that contains the activeTab
  const activeContextIndex = Object.values(context)
    .map((ctx) => ctx.tabs.map((tab) => tab.toLowerCase()))
    .findIndex((x) => x.indexOf(activeTab) > -1);
  return activeContextIndex > -1 ? Object.values(context)[activeContextIndex] : undefined;
}

export const isPerspective = (value: BoundPerspective): value is BoundPerspective => {
  return value.type && ['Perspective', 'DisabledBubble', 'Bubble', 'RouteBubble'].includes(value.type);
};
export const isEnabledPerspective = (value: BoundPerspective): value is BoundEnabledPerspective => {
  return value.type && ['Perspective'].includes(value.type);
};
export const isEnabledPerspectiveOrBubble = (value: BoundPerspective): value is BoundEnabledPerspective => {
  return value.type && ['Perspective', 'Bubble'].includes(value.type);
};
