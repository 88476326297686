import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { get, forEach, map, isNil } from 'lodash';
import { style } from 'typestyle';
import { ViewSimilarItemsModal } from '../../ViewSimilarItems/ViewSimilarItemsModal';
import { ViewSimilarItemsModalProps } from '../../ViewSimilarItems/ViewSimilarItems.types';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
const containerStyles = style({
  display: 'flex',
});

type Props = ICellRendererParams & {
  displayValue: string;
  isDataTransposed: boolean;
  modalParams: ViewSimilarItemsModalProps & {
    updates: {
      from: string;
      to: string;
    }[];
  };
  getValue: (id: string, attr: string) => string;
};

type State = {};

export default class ModalLinkRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
    this.props.eGridCell.addEventListener('click', this.openModal);
  }

  componentWillUnmount() {
    this.props.eGridCell.removeEventListener('click', this.openModal);
  }

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  onSubmit = (selected: BasicPivotItem[]) => {
    const { column, api, isDataTransposed } = this.props;
    if (selected != null && selected.length > 0) {
      if (isDataTransposed && !isNil(column)) {
        forEach(this.props.modalParams.updates, (updateKVs) => {
          const newValue = get(selected[0], updateKVs.from);
          api.getRowNode(updateKVs.to)?.setDataValue(column.getColId(), newValue);
        });
      } else {
        // FIXME: How to handle the non transposed?
        // node.data[node.data.id] = v;
      }
    } else {
      // do nothing. Clearing is likely unintended.
    }
    api.refreshCells();
  };

  constructSearch = () => {
    const { getValue, isDataTransposed, column } = this.props;
    let search = '';
    if (isDataTransposed && column?.getColId() != null) {
      search = map(this.props.modalParams.searchBy, (attr) => {
        return getValue(column?.getColId(), attr);
      }).join('&');
      if (search === '&') {
        search = '';
      }
    } else {
      // FIXME: How to handle the non transposed?
      // node.data[node.data.id] = v;
    }
    return search;
  };

  render() {
    const viewSimProps: ViewSimilarItemsModalProps = this.props.modalParams;
    return (
      <div className={containerStyles}>
        <ViewSimilarItemsModal
          {...viewSimProps}
          topMembers={this.props.column?.getColId()}
          initialSearchBy={this.constructSearch()}
          onSubmit={this.onSubmit}
        />
        {/* <span className={valueStyles}>{this.props.displayValue}</span> */}
      </div>
    );
  }
}
