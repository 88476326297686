import { cssRaw, style } from 'typestyle';

const dataGridStyles = style({
  borderLeft: '1px solid #ddd',
  borderRight: '1px solid #ddd',
  minWidth: 0,
  $nest: {
    '& .ag-cell': {
      height: 'inherit',
    },
    '&.ag-theme-material .ag-header-cell-label .ag-header-icon': {
      height: 'auto',
    },
    '.ag-filter-apply-panel button': {
      display: 'inline-block',
      padding: '0px 10px',
      borderRadius: ' 4px',
      cursor: 'pointer',
      border: '1px solid rgb(137, 151, 155)',
      boxShadow: 'rgb(6 22 33 / 30%) 0px 1px 2px',
      color: 'rgb(61, 79, 88)',
      fontSize: '11px',
      height: '36px',
      transition: 'all 150ms ease-in-out 0s',
      $nest: {
        '&:hover': {
          color: 'rgb(61, 79, 88)',
          backgroundColor: ' rgb(255, 255, 255)',
          border: ' 1px solid rgb(93, 108, 116)',
          boxShadow: 'rgb(0 0 0 / 30%) 0px 4px 4px, rgb(231 238 236) 0px 0px 0px 3px',
        },
      },
    },
    // make sure nested group column icons have the same spacing as the non-nested columns
    '.group-child .ag-sort-ascending-icon:not(.ag-hidden), .group-child .ag-sort-descending-icon:not(.ag-hidden)': {
      paddingLeft: 'var(--ag-grid-size)',
    },
  },
});

cssRaw(`
.${dataGridStyles}.ag-theme-material .ag-row {
  --ag-range-selection-background-color: rgba(122, 134, 203, 0);
  --ag-selected-row-background-color: rgba(122, 134, 203, 0);
}
`);
export default {
  dataGridStyles,
};
