import { GridViewConfig } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pivot, BasicPivotItem, TimeEntry } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from '../ErrorBoundary/ErrorBoundary.slice';

export interface VisualizeSlice {
  vizViewDefns?: GridViewConfig; // TODO
  isConfigLoading: boolean;
  isDataLoading: boolean;
  data?: Pivot;
  selectedDataId?: string;
  timeEntries: TimeEntry[];
  planCurrent?: string;
  summaryData: BasicPivotItem[] | undefined;
}

const initialState: VisualizeSlice = {
  isConfigLoading: false,
  isDataLoading: false,
  timeEntries: [],
  summaryData: undefined,
};

const visualizeSliceReducer = createSlice({
  name: 'visualize',
  initialState: initialState,
  reducers: {
    requestVisualizeConfig: (state) => {
      state.isConfigLoading = true;
    },
    receiveVisualizeConfig: (state, action: PayloadAction<GridViewConfig>) => {
      state.vizViewDefns = action.payload;
      state.isConfigLoading = false;
    },
    requestVisualizeData: (state, action: PayloadAction<string>) => {
      state.isDataLoading = true;
      state.data = undefined;
      state.selectedDataId = action.payload;
    },
    receivedVisualizeData: (state, action: PayloadAction<Pivot | undefined>) => {
      state.isDataLoading = false;
      state.data = action.payload;
    },
    receivedVisualizeMacroData: (state, action: PayloadAction<BasicPivotItem[]>) => {
      state.summaryData = action.payload;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
  },
});

export const {
  requestVisualizeConfig,
  receiveVisualizeConfig,
  requestVisualizeData,
  receivedVisualizeData,
  receivedVisualizeMacroData,
  receiveError
} = visualizeSliceReducer.actions;

export default visualizeSliceReducer.reducer;
