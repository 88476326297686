import { style } from 'typestyle';

const noDataOverlay = style({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const noDataOverlayLink = style({
  padding: '0 0.3rem',
  cursor: 'pointer',
});

export default {
  noDataOverlay,
  noDataOverlayLink,
};
