import React from 'react';
import './_MacroSummary.scss';
import ServiceContainer from 'src/ServiceContainer';

export const upArrowClass = 'fa-caret-up fa-lg';
export const downArrowClass = 'fa-caret-down fa-lg';
export const noArrowClass = 'fa-minus fa-sm'; // this one a bit smaller than the others

export interface MetricInfo {
  metricLabel: string | null;
  versionLabels: {
    primary: string;
    secondary: string;
    delta: string;
  } | null;
  value: {
    primary?: number;
    secondary?: number;
    delta?: number;
  } | null;
  summaryFormat: string | null | undefined; // the input shape is somewhat ambiguous
}
// this will need to be configureable per-metric someday
const DEFAULT_VARIANCE_FORMAT = '0%';

const getSummaryDisplayValue = (
  numeral: typeof ServiceContainer['localization'],
  metricValue: number | undefined,
  summaryFormat: string | null | undefined
): string | number => {
  // return a non-breaking space if the data isn't ready yet
  // so that the empty macro summary is the same height as a filled one
  if (metricValue === undefined) {
    return '\u00A0';
  }

  if (summaryFormat) {
    if (typeof summaryFormat === 'string') {
      const formatFunction = summaryFormat.includes('$') || summaryFormat.includes('￡') ? 'formatCurrency' : 'format';
      return numeral(metricValue)[formatFunction](summaryFormat);
    }
  }
  return metricValue;
};

const getArrowClass = (metric: number | undefined): string => {
  const value = metric;
  const directionClass = 'metric-arrow fas';

  // TODO special numbers are returned from the server as 0
  // no especial way to render them
  if (value && !Number.isFinite(value)) {
    return '';
  }

  if (value && value > 0) {
    return `${directionClass} ${upArrowClass} up`;
  } else if (value && value < 0) {
    return `${directionClass} ${downArrowClass} down`;
  }
  return `${directionClass} ${noArrowClass} no-arrow`;
};

const MacroSummary = (metric: MetricInfo) => {
  const { metricLabel, versionLabels } = metric;
  const numeral = ServiceContainer.localization;

  return (
    <div className="macro-summary">
      <div className="left-summary">
        <div className="primary-metric-name">{metricLabel}</div>
        <div className="primary-metric-value">
          {getSummaryDisplayValue(numeral, metric.value?.primary, metric.summaryFormat)}
        </div>
        <div className="secondary-metric">
          <div className="secondary-metric-name">{versionLabels?.secondary}</div>
          <div className="secondary-metric-value">
            {getSummaryDisplayValue(numeral, metric.value?.secondary, metric.summaryFormat)}
          </div>
        </div>
      </div>
      <div className="right-summary">
        <div className="delta-name">{versionLabels?.delta}</div>
        <div className="delta-display">
          <i className={getArrowClass(metric.value?.delta)} />
          {/* eslint-disable-next-line max-len */}
          <div className="delta-value">
            {getSummaryDisplayValue(numeral, metric.value?.delta, DEFAULT_VARIANCE_FORMAT)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MacroSummary;
