import { ofType } from 'redux-observable';
import { concatMap, filter, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { receivedCreateScope, receivedScope } from 'src/state/scope/Scope.slice';

import type { AppThunk, AppEpic } from 'src/store';
import { addComment, deleteComment, loadScopeComments } from './Comments.actions';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { resetComments } from './Comments.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const scopeReadyGetComments: AppEpic =
  (action$): Observable<AppThunk> => {
    return action$.pipe(
      ofType(
        receivedScope.type, receivedCreateScope.type, deleteComment.fulfilled.type
      ),
      concatMap(() => {
        return of(loadScopeComments());
      })
    );
  };

export const changeEditableStateOfComments: AppEpic =
  (action$, state$): Observable<AnyAction | AppThunk> => {
    return action$.pipe(
      ofType(setActivePage.type),
      map(() => state$.value.perspective.activePageConf?.component),
      filter(inputIsNotNullOrUndefined),
      concatMap((component) => {
        // TODO: dont hardcode this magic
        switch (component) {
          case 'MfpFavorite':
          case 'MfpSummaryGrid':
            return of(loadScopeComments());
          default:
            return of(resetComments());
        }
      })
    );
  };
