import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewDataState } from 'src/types/Domain';
import { isEmpty } from 'lodash';
import { cacheCheckFetchPivotData } from '../StyleColorReview.slice';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

interface TenantResponse {
  viewDefn: TenantConfigViewData;
  identityPropsConfig?: IdentityPropsConfig;
  unmodifiedViewDefn?: TenantConfigViewData;
  calcViewDefn?: TenantConfigViewData;
}

export interface SummaryViewSlice {
  tenantConfigLoading: boolean;
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  calcViewDefn: TenantConfigViewData;
  requiredViewDefns: string[];
  identityPropsConfig: IdentityPropsConfig;
  cacheHash: string | null; // do we need a map<hash,dataState>?
  viewDataState: ViewDataState;
}

const initialState: SummaryViewSlice = {
  tenantConfigLoading: false,
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  calcViewDefn: {} as TenantConfigViewData,
  requiredViewDefns: [],
  identityPropsConfig: {} as IdentityPropsConfig,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

const summaryViewReducer = createSlice({
  name: 'SummaryView',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.tenantConfigLoading = true;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.tenantConfigLoading = false;
      state.viewDefn = action.payload.viewDefn;

      // Only modify the remaining portions of the payload if they're empty and are replacing it with non-empty data.
      // Otherwise, in some cases the state gets emptied when it is needed
      // INT-1198 & INT-1322

      if (isEmpty(state.unmodifiedViewDefn) && !isEmpty(action.payload.unmodifiedViewDefn)) {
        state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn || ({} as TenantConfigViewData);
      }
      if (isEmpty(state.calcViewDefn) && !isEmpty(action.payload.calcViewDefn)) {
        state.calcViewDefn = action.payload.calcViewDefn || ({} as TenantConfigViewData);
      }
      if (isEmpty(state.identityPropsConfig) && !isEmpty(action.payload.identityPropsConfig)) {
        state.identityPropsConfig = action.payload.identityPropsConfig || ({} as IdentityPropsConfig);
      }
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = summaryViewReducer.actions;

export function fetchSummaryViewData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default summaryViewReducer.reducer;
