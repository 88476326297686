import React, { KeyboardEvent, useRef, useState } from 'react';
import { IRowNode, IHeaderParams } from '@ag-grid-community/core';
import { CustomHeaderProps } from '@ag-grid-community/react';
import { Tooltip } from '@material-ui/core';
import { TextValidationEditor, TextValidationEditorProps } from './TextValidationEditor';
import massEditstyles from 'src/components/MassEdit/MassEdit.styles';
import { ROW_SELECTION_REQUIRED_TEXT } from 'src/utils/Domain/Constants';

// TODO: find better location for this
export interface EditableGridHeaderEditorBaseProps {
  onApplyEdit: (value: unknown, nodes: IRowNode[], colDef: unknown) => Promise<void>;
  getSelectedItems: () => IRowNode[];
}

export interface TextValidationHeaderEditorParams
  extends IHeaderParams,
    CustomHeaderProps,
    EditableGridHeaderEditorBaseProps {
  menuIcon: string;
  editorParams: TextValidationEditorProps;
}

export const TextValidationHeaderEditor = (props: TextValidationHeaderEditorParams) => {
  const { column, editorParams, onApplyEdit, getSelectedItems } = props;

  const headerInputRef = useRef<HTMLInputElement>(null);
  const [headerValue, setHeaderValue] = useState('');

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const textValue = event.target.value;
    const { whitelist, maxLength } = editorParams;
    const value = TextValidationEditor.validateText(textValue, whitelist, maxLength);
    setHeaderValue(value);
  };

  const handleApply = () => {
    headerInputRef.current?.blur();
    const items = getSelectedItems();
    onApplyEdit(headerValue, items, column.getColDef());
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const disabled = getSelectedItems().length === 0;
  const title = disabled ? ROW_SELECTION_REQUIRED_TEXT : '';
  return (
    <Tooltip title={title}>
      <input
        type="text"
        className={massEditstyles.massEditInput}
        ref={headerInputRef}
        maxLength={editorParams.maxLength}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        value={headerValue}
      />
    </Tooltip>
  );
};
