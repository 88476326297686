import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { isNil } from 'lodash';
import { fetchFlowTypeData, cleanUp, receiveTenantConfig, setGroupingInfo } from './FlowType.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded, topMemInWorklistSelected } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  ColumnGroupedViewComponent,
  maybeGetComponentProps,
  isSameComponentType,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { ExtraPivotOptions, getAggBys, organizeListDataOptions } from '../StyleColorReview.slice';
import {
  receiveScopeConfig,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { emptySubheaderState, receiveViewDefns } from 'src/components/Subheader/Subheader.slice';

export const flowTypeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      setActiveSubPage.type,
      updateSelectedItem.type,
      receiveViewDefns.type,
      receiveTenantConfig.type,
      receiveScopeConfig.type
    ),
    map(() => maybeGetComponentProps<ColumnGroupedViewComponent>(state$.value, ConfDefnComponentType.flowType)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns, topMembers, groupingInfo }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      // We use a custom aggby for this screen, so we don't need the subheader agg by information.
      const aggBy = getAggBys(emptySubheaderState, dataApi);
      const options: ExtraPivotOptions = !isNil(topMembers) ? { topMembers, aggBy } : { aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      if (isSameComponentType<ColumnGroupedViewComponent>(state$.value, ConfDefnComponentType.flowType)) {
        return of(cleanUp(), setGroupingInfo(groupingInfo), fetchFlowTypeData(finalModelDefn, finalOptions));
      }

      return of(setGroupingInfo(groupingInfo), fetchFlowTypeData(finalModelDefn, finalOptions));
    })
  );
};
