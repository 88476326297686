import { ColDef } from '@ag-grid-community/core';
declare type ColumnDef = ColDef & {
  children?: ColumnDef[];
};
export declare const nestedHeaderKey = 'nestedHeader';
export declare const nestedHeaderGroupKey = 'nestedHeaderGroup';

function agHeaderTemplate(extraClasses: string) {
  return `<div class="ag-cell-label-container ${extraClasses}" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
      <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
      <span ref="eFilter" class="ag-header-icon ag-filter-icon" aria-hidden="true"></span>
      <span ref="eSortOrder" class="ag-header-icon ag-sort-order" aria-hidden="true"></span>
      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true"></span>
      <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true"></span>
      <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
  </div>`;
}

type HeaderChildren = ColDef & { children?: ColumnDef[] | undefined };
export function multiHeaderDecorate(colDefs: ColumnDef[]): ColumnDef[] {
  const decorated: HeaderChildren[] = [];
  for (const colDef of colDefs) {
    const newColDef = Object.assign({}, colDef);
    const { children } = newColDef;
    if (children) {
      const newChildren: HeaderChildren[] = [];
      for (const child of children) {
        const newChild = Object.assign({}, child);
        const newParams = child.headerComponentParams || {};
        newParams.template = agHeaderTemplate('group-child');
        newChild.headerComponentParams = newParams;
        newChildren.push(newChild);
      }
      newColDef.children = newChildren;
    } else {
      const newParams = colDef.headerComponentParams || {};
      newParams.template = agHeaderTemplate('no-group');
      colDef.headerComponentParams = newParams;
    }
    decorated.push(newColDef);
  }
  return decorated;
}
