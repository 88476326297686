import { AppDispatch, AppState } from 'src/store';
import { getScopeReadyData, getScopeId } from '../../../state/scope/Scope.types';

import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { MfpSubheaderValueProps } from './MfpSubheader';
import { setRightContainerPayload, toggleRightContainer } from 'src/components/RightContainer/RightContainer.slice';

const getCurrentSubheaderBtns = (state: AppState): string[] | undefined => {
  const currentViewComponentProps = state.perspective.activePageConf && (state.perspective.activePageConf.componentProps as any); //TO DO: Fix this type later on

  if (
    !currentViewComponentProps ||
    !currentViewComponentProps.subheader ||
    !currentViewComponentProps.subheader.extraBtns
  ) {
    return undefined;
  }
  return currentViewComponentProps.subheader.extraBtns;
};
export function mapStateToProps(state: AppState): MfpSubheaderValueProps {
  const readyScope = getScopeReadyData(state.mfpScope);
  const id = getScopeId(state.mfpScope);

  const activeExtraBtns = getCurrentSubheaderBtns(state);
  if (!readyScope || !id) {
    return {
      hasEditableRevision: false,
      activeExtraBtns,
      commentsOpen:
        state.rightContainer.payload?.type === RightContainerPayloadType.Comments && state.rightContainer.open,
    };
  }

  const hasEditableRevision = readyScope.hasEditableRevision;
  return {
    hasEditableRevision,
    activeExtraBtns,
    commentsOpen:
      state.rightContainer.payload?.type === RightContainerPayloadType.Comments && state.rightContainer.open,
  };
}

export const mapDispatchToProps = (dispatch:AppDispatch)=>{
  return {
    toggleRightContainer: () => {
      dispatch(setRightContainerPayload({ type: RightContainerPayloadType.Comments }));
      dispatch(toggleRightContainer());
    }
  }
}
export interface MfpSubheaderDispatchProps extends ReturnType<typeof mapDispatchToProps> {};