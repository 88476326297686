import { ofType } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AppEpic } from 'src/store';
import { receivedCreateScope, receivedScope } from 'src/state/scope/Scope.slice';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { setLastScopeCreated } from './UiPose.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

export const saveLastCreatedScope: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(receivedCreateScope.type, receivedScope.type),
    map(() => getScopeReadyData(state$.value.mfpScope)),
    filter(inputIsNotNullOrUndefined),
    mergeMap((newScope) => {
      const currentModule = getMfpModule(state$.value)?.pathSlot;

      if (!currentModule || !newScope?.currentAnchors) {
        return EMPTY;
      }
      return of(setLastScopeCreated([currentModule, newScope.currentAnchors]));
    })
  );
};
