import Modal from '@trendmicro/react-modal';
import React, { useEffect } from 'react';
import { Button, Dropdown, Grid, Segment } from 'semantic-ui-react';
import './_Scopebar.scss';
import { ModalBodyComponentProps } from 'src/components/Mfp/MfpScopebarOption/ScopebarOptionModal';
import { isEmpty, isUndefined } from 'lodash';
import { useHandleKeyPress } from 'src/utils/Component/hooks/hooks';
import { AppState, AppThunkDispatch } from 'src/store';
import { forceRefreshGrid, balanceScope } from 'src/state/scope/Scope.actions';
import { serverScopeMemberFromId } from './MfpScopeUtils';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { ReseedPlanModalOwnProps } from 'src/components/Mfp/Reseed/ReseedPlanModal';
import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AnchorRadioSelect from 'src/components/AnchorRadioSelect/AnchorRadioSelect';
import { Command } from 'src/state/scope/codecs/Commands';
import { keyToCommand } from 'src/state/scope/codecs/projections/PlanMetadataToDropdown';
import { anchorsFromCommandDetails, getBalanceCommands, hasCommands } from 'src/state/scope/codecs/Commands.utils';
import {
  useCommandToDropdown,
  useCommandToTimeDropdown,
  useCurrentCommands,
  useFocusButtonRef,
  useHandleChangeCommand,
  useSelectedCommand,
  useSelectedPlanId,
  useSelectedTime,
  useSetPlanId,
} from 'src/utils/Component/hooks/PlanManagement.hooks';

const mapStateToProps = (state: AppState) => {
  const { settings } = state;
  const dimensionLabel = settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(state.mfpScope);

  if (!readyScope || isEmpty(readyScope.commands)) {
    return;
  }
  // map it twice, as hasCommands doesn't return the correct type
  // filter so that we don't get empty sets of commands
  const commands = readyScope.commands
    .map(getBalanceCommands)
    .filter(hasCommands)
    .map(getBalanceCommands);

  return {
    commands,
    dimensionLabel,
    // the below extracts the ServerScopeMembers from the set of plan
    anchor: serverScopeMemberFromId(anchorsFromCommandDetails(commands), readyScope.mainConfig.members),
    isMultiScope: readyScope.isMultiScope,
    entries: settings.entriesByKey
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedBalanceScope: (balanceCommand: Command['command']) => {
      return dispatch(balanceScope(balanceCommand)).then(() => dispatch(forceRefreshGrid()));
    },
  };
};

export type EopToBopModalValueProps = ReturnType<typeof mapStateToProps>;
export type EopToBopModalDispatchProps = ReturnType<typeof mapDispatchToProps>;
type EopToBopModalProps = ModalBodyComponentProps &
  EopToBopModalValueProps &
  EopToBopModalDispatchProps &
  ReseedPlanModalOwnProps;

const EopToBopModal = (props: EopToBopModalProps) => {
  const { commands, onCancel, onSubmit, dispatchedBalanceScope, dimensionLabel, isMultiScope, anchor, entries } = props;

  const buttonRef = useFocusButtonRef();
  const [loading, setLoading] = useState(false);
  const [mutationPending, setMutationPending] = useState(false);

  const [selectedPlanId, setSelectedPlanId] = useSelectedPlanId(commands);
  const currentCommands = useCurrentCommands(commands, selectedPlanId);
  const [selectedTime, setSelectedTime] = useSelectedTime(currentCommands);
  const handleChangePlanId = useSetPlanId(commands, setSelectedPlanId);
  const versionOptions = useCommandToDropdown(commands, selectedPlanId, selectedTime, entries);
  const timeOptions = useCommandToTimeDropdown(currentCommands, entries);
  const [selectedCommand, setSelectedCommand] = useSelectedCommand(currentCommands);
  const handleChangeCommand = useHandleChangeCommand(setSelectedCommand);

  useEffect(() => {
    if(isEmpty(commands) && isUndefined(anchor)){
      setLoading(false); // no data, doesn't need to remain loading
    } else if(isEmpty(commands)){
      setLoading(true);
    }
  }, [commands, anchor])

  const handleSubmit = useCallback(async () => {
    if (currentCommands && selectedCommand) {
      setMutationPending(true);
      const maybeFoundCommand = keyToCommand(selectedCommand, currentCommands.commands);
      if (!maybeFoundCommand) {
        return;
      }
      await dispatchedBalanceScope(maybeFoundCommand.command)
        .catch(() => {
          toast.error('An error occured copying EOP to BOP');
          throw new Error('An error occured copying EOP to BOP');
        })
        .then(() => {
          if (!isMultiScope) {
            onSubmit(); // only close modal on success
          }
        })
        .finally(() => setMutationPending(false));
    }
  }, [currentCommands, selectedCommand, dispatchedBalanceScope, isMultiScope, onSubmit]);

  const handleEnterPress = useHandleKeyPress(handleSubmit);

  return (
    <React.Fragment>
      <div className="initialize-plan">
        <Grid doubling={true} stretched={true}>
          <Grid.Column>
            <div className="dropdown-group">
              <Segment>
                Select the Plan period and version to to Copy End of Plan data into the beginning of your WP
              </Segment>
              <div>
                {commands && dimensionLabel && anchor ? (
                  <AnchorRadioSelect
                    labelDimenion={dimensionLabel}
                    anchor={anchor}
                    onUpdateAnchorSelections={handleChangePlanId}
                  />
                ) : null}
              </div>
              <Dropdown
                fluid={true}
                loading={loading}
                data-qa="eop-period-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={timeOptions}
                value={selectedTime}
              />
            </div>
            <div className="dropdown-group">
              <div className="dropdown-group-label">Select the Plan Version</div>
              <Dropdown
                fluid={true}
                loading={loading}
                data-qa="eop-version-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={versionOptions}
                value={selectedCommand}
                onChange={handleChangeCommand}
              />
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <Modal.Footer>
        <Button content="Close" onClick={onCancel} />
        <div ref={buttonRef} className="submit-button-modal" tabIndex={0} onKeyPress={handleEnterPress}>
          <Button
            content="Submit"
            className="import-version-modal-button"
            data-qa="eop-copy-btn-submit"
            onClick={handleSubmit}
            loading={loading || mutationPending}
            disabled={isUndefined(anchor)}
          />
        </div>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EopToBopModal);
