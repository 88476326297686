import React from 'react';
import Divider from '@material-ui/core/Divider';
import KeyFinancial from 'src/common-ui/components/Macros/KeyFinancial/KeyFinancial';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';
import { MacrosAccordionProps } from 'src/common-ui/components/Macros/Macros.types';

export const KeyFinancialsAccordion = ({
  dataLoaded,
  macros,
  overrideTransition = false,
  onToggleAccordion,
}: MacrosAccordionProps) => {
  return (
    <StyledAccordion
      expanded={true}
      title="Key Financials"
      onToggle={onToggleAccordion}
      overrideTransition={overrideTransition}
    >
      {macros.map((item, i, arr) => {
        const isLastItem = i === arr.length - 1;
        return (
          <React.Fragment key={i}>
            <KeyFinancial {...item} key={i} dataLoaded={dataLoaded} />
            {isLastItem ? null : <Divider orientation="vertical" flexItem={true} />}
          </React.Fragment>
        );
      })}
    </StyledAccordion>
  );
};
