import { style } from 'typestyle';

const clickable = style({
  cursor: 'pointer',
});

const badgeContainer = style({
  display: 'inline-block',
  paddingRight: '5px',
});

export default { badgeContainer, clickable };
