import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MacroSummary from 'src/common-ui/components/Macros/MacroSummary/MacroSummary';
import { macrosDividerOverrides } from 'src/common-ui/components/Macros/MacroSummary/MacroSummary.styles';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';
import { MacrosAccordionProps } from 'src/common-ui/components/Macros/Macros.types';

const StyledHorizontalDivider = withStyles(() => macrosDividerOverrides)(Divider);

export const MacroSummariesAccordion = ({
  dataLoaded,
  macros,
  overrideTransition = false,
  onToggleAccordion,
}: MacrosAccordionProps) => {
  return (
    <StyledAccordion
      expanded={true}
      title="Summary"
      onToggle={onToggleAccordion}
      overrideTransition={overrideTransition}
    >
      <section className="macro-summaries">
        {macros.map((item, i, arr) => {
          const middleIndex = arr.length / 2;
          // finds the item that is wrapped so the vertical divider can be removed and the horizontal divider can be inserted
          const isWrappedItem = i === middleIndex - 1;
          const isLastItem = i === arr.length - 1;
          return (
            <React.Fragment key={i}>
              <MacroSummary {...item} key={i} dataLoaded={dataLoaded} />
              {isWrappedItem || isLastItem ? null : <Divider orientation="vertical" flexItem={true} />}
              {isWrappedItem ? <StyledHorizontalDivider orientation="horizontal" /> : null}
            </React.Fragment>
          );
        })}
      </section>
    </StyledAccordion>
  );
};
