import BoundTabZipper from 'src/pages/NavigationShell/NavigationZipper/BoundTabZipper';
import { BoundSection } from 'src/services/configuration/codecs/bindings.types';
import { Option, chain, map, fromNullable } from 'fp-ts/lib/Option';
import BoundViewZipper from 'src/pages/NavigationShell/NavigationZipper/BoundViewZipper';
import { pipe } from 'fp-ts/lib/function';

export default class BoundSectionZipper {
  readonly type: 'section' = 'section';
  private section: Readonly<BoundSection>;
  private parent: Readonly<BoundTabZipper>;

  constructor(parent: Readonly<BoundTabZipper>, section: Readonly<BoundSection>) {
    this.parent = parent;
    this.section = section;
  }

  public getSection(): Readonly<BoundSection> {
    return this.section;
  }

  public getParent(): Readonly<BoundTabZipper> {
    return this.parent;
  }

  public getView(id: string): Option<Readonly<BoundViewZipper>> {
    return pipe(
      fromNullable(this.section.boundViews),
      chain((views) => fromNullable(views.find((view) => view.id === id))),
      map((view) => new BoundViewZipper(this, view))
    );
  }

  public getViews(): readonly BoundViewZipper[] {
    return this.section.boundViews.map((view) => new BoundViewZipper(this, view));
  }
}
