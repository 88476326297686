import numbro from 'numbro';

function percent(value: number | string, format = '0.00'): string {
  if (isNaN(value as number)) {
    return '';
  }
  return numbro(value).format(`${format}%`);
}

function arrowPercentTwoDecimal(value: number | string) {
  return percent(value);
}

function thousand(value: number | string): string {
  return numbro(value).format('0,0');
}

function twoDecimal(value: number | string): string {
  return numbro(value).format('0.00');
}

function threeDecimal(value: number | string): string {
  return numbro(value).format('0.000');
}

function usMoney(value: number | string, format = '$0,0.00'): string {
  return numbro(value).format(format);
}

function usMoneyNoCents(value: number | string): string {
  return numbro(value).format('$0,0');
}

function poundNoCents(value: number | string) {
  return numbro(value)
    .format('$0,0')
    .replace('$', '£');
}
interface FilterConfig {
  filter: string;
  filterParams?: {
    buttons: string[];
  };
}
function columnFilterType(type?: string): FilterConfig {
  let filterType;
  switch (type) {
    case 'text':
      filterType = 'agTextColumnFilter';
      break;
    case 'number':
      filterType = 'agNumberColumnFilter';
      break;
    case 'date':
      filterType = 'agDateColumnFilter';
      break;
    default:
      filterType = 'agSetColumnFilter'; // Defaulting to list filter
  }

  const filterParams = {
    buttons: ['apply', 'reset'],
  };

  // Exclude default filterParams for 'agSetColumnFilter'
  if (filterType === 'agSetColumnFilter') {
    return {
      filter: filterType,
    };
  }

  return {
    filter: filterType,
    filterParams: filterParams,
  };
}

export const Renderer = {
  arrowPercentTwoDecimal,
  percent,
  thousand,
  twoDecimal,
  threeDecimal,
  usMoney,
  usMoneyNoCents,
  poundNoCents,
  columnFilterType,
};
