import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Axios from 'src/services/axios';

import ImageCropper from 'src/components/ImageCropper/ImageCropper/ImageCropper';
import styles from './ImageCropperModal.styles';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

type LocalAxiosResponse = {
  data: { data: { fileName: string } };
};

type Props = {
  open: boolean;
  passedImage: string;
  returnImage: (image: string) => void;
  closeModal: () => void;
};

type State = {
  uploadedImage: string | null;
};

export default class ImageCropperModal extends React.Component<Props, State> {
  imageCropper: React.RefObject<ImageCropper> = React.createRef();
  constructor(props: Props) {
    super(props);
    this.state = {
      uploadedImage: null,
    };
  }

  handleCancel = () => {
    const { closeModal } = this.props;

    this.setState(
      {
        uploadedImage: null,
      },
      closeModal
    );
  };

  handleSave = async () => {
    try {
      if (this.imageCropper.current) {
        const newImageBlob = await this.imageCropper.current.getCroppedImage();
        const formData = new FormData();

        if (!isNil(newImageBlob)) {
          formData.append('file', newImageBlob);
          Axios.post('api/asset/upload', formData).then((uploadResp: LocalAxiosResponse) => {
            const fileName = uploadResp.data.data.fileName;
            return Axios.post('/api/asset/saveNoCrop', {
              id: fileName,
            }).then((resp) => {
              this.props.returnImage(resp.data.data);
            });
          });
        }
      }
    } catch (_error) {
      toast.error('An Error Occurred Trying To Save Image');
    }
  };

  handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        uploadedImage: reader.result as string,
      });
    };
    if (e.target.files) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const { open } = this.props;
    const image = this.state.uploadedImage || this.props.passedImage;
    return (
      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason === 'escapeKeyDown') {
            this.handleCancel();
          }
        }}
      >
        <div className={styles.titleContainer}>
          <span className={styles.title}>Crop Image</span>
        </div>
        <div className="input-file-container">
          <input type="file" onChange={this.handleImageUpload} />
        </div>
        <div className={styles.dialogBody}>
          <div className={styles.cropperContainer}>
            <ImageCropper ref={this.imageCropper} image={image} />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonSave}>
            <span className={styles.buttonText} onClick={() => this.handleCancel()}>
              Cancel
            </span>
          </div>
          <div className={styles.buttonSave} onClick={() => this.handleSave()}>
            <span className={styles.buttonText}>SAVE</span>
          </div>
        </div>
      </Dialog>
    );
  }
}
