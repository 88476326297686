import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import Axios from 'src/services/axios';
import { PostTextModalFab } from 'src/services/configuration/codecs/confdefn';
import styles from '../ButtonModal/ButtonModal.styles';
import * as validator from 'validator';
import { TemplateModalTitle } from '../TemplateModalWithButton/TemplateModalWithButton';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    backgroundColor: '#00a89b',
    color: 'white',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(1),
    backgroundColor: '#00a89b',
    color: 'white',
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#ccc',
    },
    '&:hover': {
      backgroundColor: '#00a89b',
    },
  },
  cancelButton: {
    backgroundColor: '#ccc',
  },
  errorText: {
    color: 'red',
  },
}));
const AddUserModal = ({ dataApi, text, fabTooltip }: Omit<PostTextModalFab, 'fabType'>) => {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validator.isEmail(newEmail));
  };
  const handleCancel = () => {
    setModalOpen(false);
    setEmail('');
    setIsValidEmail(false);
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await Axios.post(dataApi?.url || '', { email: email });

      // Wait for the Axios.post promise to resolve before checking the response status
      if (response.status === 200) {
        toast.error(`Email ${email} already exists`);
      } else if (response.status === 201) {
        toast.success(`User ${email} has been created`);
      } else {
        // Handle other status codes as needed
        toast.error('Unexpected error occurred');
      }
    } catch (error) {
      toast.error('Failed to make a request');
    } finally {
      handleCancel(); // Close the dialog regardless of the response
    }
  };
  const fabClick = () => {
    setModalOpen(true);
    setEmail('');
  };

  let fabButton = (
    <button className={styles.fab} onClick={fabClick}>
      <i className="fas fa-plus" aria-hidden="true" />
    </button>
  );
  if (fabTooltip) {
    fabButton = (
      <Tooltip title={fabTooltip} arrow>
        {fabButton}
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      {fabButton}
      <Dialog open={isModalOpen} className={classes.modal}>
        <DialogTitle className={classes.modalTitle}>
          <TemplateModalTitle title={'Add New User'} onCancel={handleCancel}></TemplateModalTitle>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <h5>{text}</h5>
          <TextField
            label="Email"
            type="text"
            value={email}
            onChange={handleInputChange}
            fullWidth
            error={!isValidEmail}
            helperText={!isValidEmail && <span className={classes.errorText}>Enter a valid email</span>}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} className={classes.submitButton} disabled={!isValidEmail}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Toaster */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
    </React.Fragment>
  );
};

export default AddUserModal;
