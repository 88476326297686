import React from 'react';

export interface ScopeBarTextProps {
  content: string;
}

const ScopeText = (props: ScopeBarTextProps) => {
  const { content = '' } = props;
  const textArray = content.split(' | ');

  return (
    <ul className="scope-text-list">
      {textArray.map((item) => (
        <li className="scope-text-item" key={item}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default ScopeText;
