import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import Tooltip from '@material-ui/core/Tooltip';

type TooltipRendererProps = Record<string, any> & ICellRendererParams;

type TooltipRendererState = Record<string, any>;

class TooltipRenderer extends React.Component<TooltipRendererProps, TooltipRendererState> {
  // Promise.resolve will only resolve 'input' if it is a promise.
  isPromise(input: Promise<any> | string) {
    return Promise.resolve(input) == input;
  }

  render() {
    const { value } = this.props;

    if (!value || this.isPromise(value)) {
      return <div />;
    }

    return (
      <Tooltip title={value} placement="top">
        <div style={{ overflowX: 'hidden' }}>{value}</div>
      </Tooltip>
    );
  }
}

export default TooltipRenderer;
