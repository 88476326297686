import React, { Component } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { Button, StrictButtonProps } from 'semantic-ui-react';

export interface ButtonRendererProps extends ICellRendererParams {
  onClick: () => void,
  label?: string,
  icon?: JSX.Element,
  extraButtonProps?: StrictButtonProps
}

export default class ButtonRenderer extends Component<ButtonRendererProps> {

  handleClick = (e: React.MouseEvent) => {
    this.props.onClick();
  }

  render() {
    const { icon, label, extraButtonProps } = this.props;
    const btnProps: StrictButtonProps = {
      ...extraButtonProps,
      icon: icon ? true : false,
      onClick: this.handleClick
    };

    return (
      <span>
        <Button {...btnProps}
        >
          {label ? label : null}
          {icon ? icon : null}
        </Button>
      </span>
    );
  }
}
