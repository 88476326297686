import React from 'react';
import styles, { gridClass, container } from './ExceptionsSummary.styles';
import '@ag-grid-enterprise/core';
import { SeverityRenderer } from 'src/components/SeverityRenderer/SeverityRenderer';
import { StarPercentRenderer } from 'src/components/StarPercentRenderer/StarPercentRenderer';
import { isEmpty, isNil } from 'lodash';
import Subheader from 'src/components/Subheader/Subheader.container';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import type { ColDef, ValueGetterParams, GridReadyEvent, GridApi, ColumnApi } from '@ag-grid-community/core';
import { ExceptionsSummaryConfigs } from './ExceptionsSummary.slice';
import { ViewDataState } from 'src/types/Domain';
import { PrintProps } from 'src/components/higherOrder/Print/Print';

export interface ExceptionsSummaryProps {
  exceptions: ExceptionList;
  dataLoading: boolean;
  configLoading: boolean;
  viewDefns: ExceptionsSummaryConfigs | undefined;
  viewDataState: ViewDataState;
}
export interface ExceptionsSummaryDispatchProps {
  onShowView(): void;
  onRefetchData(): void;
}
interface ExceptionsSummaryState {
  placeholder: boolean;
}

interface ExceptionChild {
  id: string;
  name: string;
  itemcount: number;
  maxitemcount: number;
  severitycounts: number[];
}

interface Exception {
  id: string;
  name: string;
  description: string;
  type?: string;
  path: string;
  children: ExceptionChild[];
}

export type ExceptionList = Exception[];

export interface Props {
  exceptions: ExceptionList | undefined;
}

const defaultColDef: ColDef = {
  // suppressMovable: true,
  lockPinned: true,
  lockPosition: true,
  resizable: false,
  filter: true,
  sortable: true,
};

const frameworkComponents = {
  severityRenderer: SeverityRenderer,
  starRenderer: StarPercentRenderer,
};

// this is an ag-grid type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const getNodeChildDetails = (node: any) => {
//   if (node.children && !isEmpty(node.children)) {
//     return {
//       ...node,
//       group: true,
//       children: node.children,
//       expanded: true,
//       field: 'id',
//     };
//   } else {
//     return node;
//   }
// };

export class ExceptionsSummary extends React.Component<
  ExceptionsSummaryProps & ExceptionsSummaryDispatchProps & PrintProps,
  ExceptionsSummaryState
> {
  gridApi?: GridApi;
  columnApi?: ColumnApi;
  colDefs: ColDef[] | undefined = undefined;

  componentDidMount(): void {
    this.props.onShowView();
  }

  onSearchReturn = (s: string): void => {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(s);
    }
    return;
  };

  handleGridReady = (params: GridReadyEvent): void => {
    // assign private vars to callback
    if (params.api && params.columnApi) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    }
  };

  render() {
    if (!this.colDefs && this.props.viewDefns) {
      this.colDefs = this.props.viewDefns.columns.map((c) => {
        const col: ColDef = {
          field: c.dataIndex,
          headerName: c.text ? c.text : c.dataIndex,
          cellRenderer: c.renderer,
          flex: 1
        };
        switch (c.xtype) {
          case 'trendheader':
            col.cellClass = (params) =>
              params.data.children && !isEmpty(params.data.children) ? 'exception-group' : 'exception-child';
            col.rowGroup = true
            break;
          case 'itemcount':
            col.valueGetter = (params: ValueGetterParams) => {
              if (!isNil(params.data.itemcount)) {
                return `${params.data.itemcount} of ${params.data.maxitemcount}`;
              }
              return undefined;
            };
            col.cellClass = 'itemcount';
            break;
          case 'worklist':
            col.valueGetter = (params: ValueGetterParams) => {
              if (!isNil(params.data.worklistcount)) {
                return `${params.data.worklistcount} of ${params.data.maxworklistcount}`;
              }
              return undefined;
            };
            col.cellClass = 'worklist';
        }
        return col;
      });
    }
    return (
      <div className={styles} data-qa="exceptions-summary-container">
        <Subheader
          title={this.props.viewDefns?.main.title || 'Exceptions'} // replace from config
          showSearch={true}
          searchReturn={this.onSearchReturn}
          viewDataState={this.props.viewDataState}
        />
        <div className={container} data-qa="exceptions-list">
          {this.colDefs ? (
            <ExtendedDataGrid
              columnDefs={this.colDefs}
              data={this.props.exceptions}
              className={gridClass}
              loaded={!isEmpty(this.props.exceptions)}
              frameworkComponents={frameworkComponents}
              // events
              onGridReady={this.handleGridReady}
              rowHeight={47}
              extraAgGridProps={{
                suppressMovableColumns: true,
                defaultColDef: defaultColDef,
                enableRangeSelection: false,
                suppressMultiRangeSelection: true,
                treeData: true,
                getDataPath: (data) => {
                  return data.path;
                },
                groupDefaultExpanded: -1,
                autoGroupColumnDef: {
                  headerName: '',
                  width: 0
              },
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
