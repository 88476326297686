import * as React from 'react';

import { Option } from './WeekRangePicker';
import { getWeekLabel } from './WeekRangePicker.utils';
import { radioOptionsContainer } from './RadioOptions.styles';
import { DayPickerType } from './RadioOptions.styles';
import { StylingOverrides } from './WeekRangePicker.interface';
import { classes } from 'typestyle';
import Tooltip from '@material-ui/core/Tooltip';

export interface DateToNameMap {
  start_date: {};
  end_date: {};
}

interface RadioOptionsProps {
  parentContainer: DayPickerType;
  dateToNameMap: DateToNameMap;
  selectionOptions: Option[];
  onOptionChange: (idx: number) => void;
  overrides?: StylingOverrides;
  extraBtn?: JSX.Element;
}

export default class RadioOptions extends React.Component<RadioOptionsProps> {
  constructor(props: RadioOptionsProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(idx: number) {
    this.props.onOptionChange(idx);
  }

  getWeekLabel(option: Option, dateToNameMap: DateToNameMap) {
    return option.dateSelection && getWeekLabel(option.dateSelection, dateToNameMap);
  }

  renderOption = (option: Option, idx: number) => {
    if (option.hidden) {
      return null;
    }

    const optionContent = (
      <div
        key={option.label}
        className={classes('option-container', option.disabled ? 'option-container-disabled' : '')}
      >
        <label className={classes('option', option.disabled ? 'option-disabled' : '')}>
          <input
            type="radio"
            checked={option.selected}
            className={classes('option-input', option.disabled ? 'option-input-disabled' : '')}
            onChange={() => this.onChange(idx)}
            data-qa-action={`option-${idx}`}
            disabled={option.disabled}
          />
          <span className={classes('option-label', option.disabled ? 'option-label-disabled' : '')}>
            {option.label}
          </span>
        </label>
        <span className={classes('week-label', option.disabled ? 'week-label-disabled' : '')}>
          {this.getWeekLabel(option, this.props.dateToNameMap)}
        </span>
      </div>
    );

    return !option.disabled ? (
      optionContent
    ) : (
      <Tooltip title="Unable to modify selection outside of allowed range">{optionContent}</Tooltip>
    );
  };

  render() {
    const { parentContainer, selectionOptions, overrides, extraBtn } = this.props;
    const isDefaultSelection = selectionOptions.length === 1 && selectionOptions[0].hidden;

    return (
      <div className={radioOptionsContainer(parentContainer, overrides)}>
        {extraBtn && <div className="option-container">{extraBtn}</div>}
        {!isDefaultSelection && this.props.selectionOptions.map((option, idx) => this.renderOption(option, idx))}
      </div>
    );
  }
}
