import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DimensionItem } from 'src/components/Mfp/PivotConfigurator/utils';

export interface ChartDimensionConfig {
  metricDimensions: DimensionItem;
  xAxisDimension: DimensionItem;
  versions: DimensionItem;
}

export interface EditableChartState {
  loaded: boolean;
  chartDimensionConfig: ChartDimensionConfig | undefined;
  forceRefreshChart: boolean;
}

const initState: EditableChartState = {
  loaded: false,
  chartDimensionConfig: undefined,
  forceRefreshChart: false,
};

const chartSlice = createSlice({
  name: 'editableChart',
  initialState: initState,
  reducers: {
    requestChart: (state, action: PayloadAction<ChartDimensionConfig>) => {
      return {
        ...state,
        chartDimensionConfig: action.payload,
        loaded: false,
      };
    },
    setChartDataStale: (state) => {
      state.forceRefreshChart = true;
      return state;
    },
    completeRefreshChart: (state) => {
      state.forceRefreshChart = false;
      return state;
    },
    chartFailed: (state) => {
      // TODO add some error handling here
      return state;
    },
  },
});

export const { requestChart, completeRefreshChart, setChartDataStale, chartFailed } = chartSlice.actions;
export default chartSlice.reducer;
