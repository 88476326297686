import React, { Component } from 'react';
import {
  isNil,
  pick,
  omit,
  isBoolean,
  isArray,
  isEmpty,
  noop,
  isEqual,
  mapValues,
  get,
  mapKeys,
  findIndex,
} from 'lodash';
import { classes } from 'typestyle';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  TextField,
} from '@material-ui/core';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Subheader from 'src/components/Subheader/Subheader.container';
import {
  ParameterTogglesProps,
  ParameterTogglesState,
  ParameterTogglesField,
  ParameterTogglesFieldRadio,
} from 'src/pages/Allocation/ParameterToggles/ParameterToggles.types';
import {
  SectionToggle,
  SectionDropdown,
  LightTooltip,
} from 'src/pages/Allocation/ParameterToggles/ParameterToggles.utils';
import { getProductItem } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { fabBtn } from 'src/pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.styles';
import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import styles from 'src/pages/Allocation/ParameterToggles/ParameterToggles.styles';
import Axios from 'src/services/axios';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import InputInteger from 'src/common-ui/components/Inputs/Integer/InputInteger';
import { getTooltipTitle } from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails';
import columnDetailStyles from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails.styles';
import ServiceContainer from 'src/ServiceContainer';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { SubheaderDropdownProps } from 'src/components/Subheader/SubheaderDropdown';
import { EditCoordinates } from 'src/dao/pivotClient';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { toastAndLog } from 'src/services/loggingService';

const ATTR_UPSERT = 'api/attribute/upsert';
const PanelKeyText = 'parameters_panel';
const UnknownEditorType = 'Unknown Editor';

export default class ParameterToggles extends Component<ParameterTogglesProps, ParameterTogglesState> {
  constructor(props: ParameterTogglesProps) {
    super(props);

    this.state = {
      fabDataProcessing: false,
      currentPanel: `${PanelKeyText}${0}`, // default to first panel open
      data: null,
    };
  }

  componentDidMount() {
    this.props.onShowView();
    this.fetchParameterSettings();
  }

  componentDidUpdate(oldProps: ParameterTogglesProps) {
    const shouldRefetch =
      !isEqual(oldProps.config, this.props.config) ||
      !isEqual(this.props.selectedTopMember, oldProps.selectedTopMember);
    if (shouldRefetch) {
      this.fetchParameterSettings();
    }
  }

  async fetchParameterSettings() {
    const { config, selectedTopMember } = this.props;

    if (config) {
      const topMemberConf = config.subheaderDropdowns?.topMember;
      let params: Record<string, unknown> = {};
      if (!isNil(topMemberConf)) {
        if (isNil(selectedTopMember)) return noop();
        params = {
          topMembers: selectedTopMember,
        };
      }
      if (!isNil(config.dataApi)) {
        const settings = (
          await ServiceContainer.pivotService.listData(config.dataApi.defnId, AppType.Assortment, params)
        ).flat[0];
        this.setState({
          data: settings,
        });
      } else {
        const settings = await getProductItem(this.props.scopeParams.productMember);
        this.setState({
          data: settings.data.data,
        });
      }
    }
  }

  executeAllocationPlan = () => {
    const { config, onSubmitPlan } = this.props;
    const { data } = this.state;

    if (config == null || data == null) return;

    if (!isNil(config.allocPlanModel)) {
      onSubmitPlan(config.allocPlanModel, data as Record<string, string | boolean> | null);
    } else if (!isNil(config.fabDataApi)) {
      const fabDataApi = config.fabDataApi;
      this.setState({ fabDataProcessing: true }, () => {
        const processedApi = processApiParams(fabDataApi, data);
        ServiceContainer.pivotService
          .listData(fabDataApi.defnId, AppType.Assortment, processedApi.params)
          .catch((error) => toastAndLog('An error occurred during processing', error))
          .finally(() => this.setState({ fabDataProcessing: false }));
      });
    }
  };

  updateData = async (data: Record<string, unknown>) => {
    const { config } = this.props;
    if (isNil(config)) return;
    if (config.updateCoordinateMap) {
      // handle granular
      const coordinateMap = mapValues(config.updateCoordinateMap, (v) => {
        return get(this.state.data, v);
      }) as EditCoordinates;
      await ServiceContainer.pivotService.granularEditSubmitData([
        {
          coordinates: coordinateMap,
          ...mapKeys(data, (v, k) => {
            return k
              .replace('attribute:', '')
              .replace('member:', '')
              .replace(':id', '')
              .replace(':name', '');
          }),
        },
      ]);
      const response = await ServiceContainer.pivotService.redecorate({
        coordinates: [this.state.data as EditCoordinates],
        defnId: config.dataApi?.defnId ?? 'MISSING_DEFNID',
        nestData: false,
        aggBy: ['custom:anything'],
      });
      this.setState({
        data: response[0],
      });
    } else {
      const response = await Axios.post(
        ATTR_UPSERT,
        {
          product: this.props.scopeParams.productMember,
          ...data,
        },
        {
          params: {
            appName: 'Assortment',
          },
        }
      );
      this.setState({
        data: response.data.data,
      });
    }
  };

  handlePanelChange = (newPanel: string) => {
    const lastPanel = this.state.currentPanel;
    const panelClosing = lastPanel === newPanel;
    const panel = panelClosing ? null : newPanel;

    this.setState({
      currentPanel: panel,
    });
  };

  handleParameterChangeAll = async (keys: string[], checked: boolean[]) => {
    await this.updateData({
      ...keys.reduce((prev, news, i) => ({ ...prev, [keys[i]]: checked[i] }), {}),
    });
  };

  handleParameterChange = async (key: string, checked: boolean) => {
    await this.updateData({
      [key]: checked,
    });
  };

  handleDropdownChange = async (key: string, selection: { value: string }[] | { value: string }) => {
    const convertSelection = !isArray(selection) ? [selection] : selection;
    const data = {
      [key]: convertSelection.map((i) => i.value),
    };

    await this.updateData(data);
  };

  handleRadioGroupChange = async (event: React.ChangeEvent<HTMLInputElement>, panel: ParameterTogglesFieldRadio) => {
    const { data } = this.state;
    const keys: string[] = [],
      values: boolean[] = [];

    const newSelectedDataIndex = event.target.value;
    if (data && data[newSelectedDataIndex] === true) {
      return;
    }
    panel.radioFields.forEach(async (x) => {
      if (x.dataIndex !== newSelectedDataIndex) {
        keys.push(x.dataIndex);
        values.push(false);
      }
    });
    keys.push(newSelectedDataIndex);
    values.push(true);
    await this.handleParameterChangeAll(keys, values);
  };

  handleTextInputChange = (event: { target: { value: string | number | boolean | null; id: string } }) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.id]: event.target.value,
      },
    });
  };

  handleNumericInputChange = (id: string, value: string | number) => {
    this.setState({
      data: {
        ...this.state.data,
        [id]: value,
      },
    });
  };

  sendTextInput = async (key: string, value: string | number) => {
    await this.updateData({
      [key]: value,
    });
  };

  renderSectionParameters = (panelFields: ParameterTogglesField[]) => {
    const { data } = this.state;

    if (isNil(data)) {
      return [];
    }

    return panelFields.map((field, index) => {
      switch (field.editor) {
        case 'toggle':
          const toggleValue = data[field.dataIndex];
          if (!isBoolean(toggleValue)) {
            return UnknownEditorType;
          }

          return (
            <SectionToggle
              key={`toggle-${field.dataIndex}-${index}`}
              {...pick(field, ['text', 'infoText'])}
              isChecked={toggleValue}
              onChange={(_event, checked) => this.handleParameterChange(field.dataIndex, checked)}
            />
          );
        case 'multiselect':
          const selectValue: string[] = ((data[field.dataIndex] as unknown) as string[]) || []; // FIXME: remove fallback once dataIndex is ready
          const selectValueString = `{${selectValue.join(',')}}`;
          return (
            <SectionDropdown
              key={`dropdown-${field.dataIndex}-${index}`}
              {...omit(field, ['editor'])}
              id={this.props.scopeParams.locationMember}
              selection={selectValueString}
              multiSelect={true}
              inputHeight={110}
              onDropdownChange={(selection) => this.handleDropdownChange(field.dataIndex, selection)}
              dataQa="parameter-multi-dropdown"
            />
          );
        case 'singleSelect':
          const selectionVal: string[] = (data[field.dataIndex] as unknown) as string[];
          const selectionString = `${selectionVal}`;
          return (
            <SectionDropdown
              key={`dropdown-${field.dataIndex}-${index}`}
              {...omit(field, ['editor'])}
              id={this.props.scopeParams.locationMember}
              selection={selectionString}
              inputHeight={55}
              multiSelect={false}
              onDropdownChange={(selection) => this.handleDropdownChange(field.dataIndex, selection)}
              dataQa="parameter-single-dropdown"
            />
          );
        case 'radiogroup':
          const labels = field.radioFields.map(({ text, dataIndex }) => (
            <FormControlLabel key={text} value={dataIndex} control={<Radio />} label={text} />
          ));
          const selected = field.radioFields.find(({ dataIndex }) => data[dataIndex] === true);
          return (
            <FormControl style={{ marginLeft: '5rem' }}>
              <FormLabel id="radio-buttons-group-label">{field.text}</FormLabel>
              <RadioGroup
                name="radio-buttons-group"
                data-qa="parameter-radio-button-container"
                value={selected ? selected.dataIndex : field.radioFields[0].dataIndex}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleRadioGroupChange(event, field)}
              >
                {labels}
              </RadioGroup>
            </FormControl>
          );
        case 'string':
          const textValue = data[field.dataIndex] as string;
          return (
            <div className={styles.sectionParameter}>
              <div className="text-container">
                <div className="text">{field.text}</div>
                {field.infoText && !isEmpty(field.infoText) && (
                  <LightTooltip title={field.infoText}>
                    <i className="fal fa-info-circle" />
                  </LightTooltip>
                )}
              </div>
              <Tooltip
                className={columnDetailStyles.dataItem}
                data-qa="multiselect-tooltip"
                title={getTooltipTitle(field.infoText || ' ')}
                placement="right"
              >
                <TextField
                  id={`${field.dataIndex}`}
                  variant="outlined"
                  onChange={this.handleTextInputChange}
                  onBlur={() => this.sendTextInput(field.dataIndex, textValue)}
                  data-qa={'allocation-input-text'}
                  value={textValue}
                />
              </Tooltip>
            </div>
          );
        case 'integer':
          const maybeNumberValue = (data[field.dataIndex] as unknown) as number;
          const boundHandleNumericInputChange = (value: string | number | null) => {
            if (value && typeof value === 'number') {
              return this.handleNumericInputChange(field.dataIndex, value);
            } else if (value && typeof value === 'string') {
              this.handleTextInputChange({
                target: { id: field.dataIndex, value },
              } as React.ChangeEvent<HTMLInputElement>);
            }
          };
          return (
            <React.Fragment>
              <FormControlLabel key={field.text} value={field.dataIndex} control={<div />} label={field.text} />
              <InputInteger
                id={`${field.dataIndex}`}
                onChange={boundHandleNumericInputChange}
                onBlur={() => this.sendTextInput(field.dataIndex, maybeNumberValue)}
                data-qa={'allocation-input-number'}
                editable={field.editable}
                value={maybeNumberValue}
              />
            </React.Fragment>
          );
        default:
          return UnknownEditorType;
      }
    });
  };

  renderSectionPanels = () => {
    if (isNil(this.props.config)) {
      return [];
    }

    return this.props.config.sections.map((sectionConfig, index) => {
      const panelKey = `${PanelKeyText}${index}`;
      const expanded = this.state.currentPanel === panelKey;

      return (
        <Accordion
          expanded={expanded}
          onChange={() => this.handlePanelChange(panelKey)}
          key={panelKey}
          data-qa="parameter-section"
        >
          <AccordionSummary
            className={classes(
              expanded ? classes(styleEditStyles.stickyHeaderArrowExpanded) : styleEditStyles.stickyHeaderArrow
            )}
            expandIcon={<i className={`fas fa-angle-down`} />}
          >
            <div className={styles.sectionPanel}>
              <i className={classes(sectionConfig.icon, 'panel-icon')} />
              <span className={styleEditStyles.sectionText}>{sectionConfig.title}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ minHeight: 'initial' }} className={styleEditStyles.scrollableSectionDetails}>
            {expanded ? this.renderSectionParameters(sectionConfig.fields) : <div />}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  generateSubheaderExtraDropdownProps(): SubheaderDropdownProps[] {
    const { topMemberDropdownProps } = this.props;
    if (topMemberDropdownProps) {
      return [
        {
          ...topMemberDropdownProps,
          dataQa: 'top-member',
          handleChangeOnDropdown: (event: React.ChangeEvent<HTMLSelectElement>) => {
            // handle change
            const { topMemberDropdownProps } = this.props;
            if (isNil(topMemberDropdownProps)) {
              return;
            }

            const newValue = event.currentTarget.textContent;
            const valueIndex = findIndex(topMemberDropdownProps.options, (option) => {
              return option.text === newValue;
            });
            const selection = topMemberDropdownProps.options[valueIndex];

            this.props.onUpdateTopMember(selection.text);
          },
        },
      ];
    } else return [];
  }

  render() {
    const { isLoading, config } = this.props;
    const { fabDataProcessing, data } = this.state;

    if (isLoading || isNil(config) || isNil(data)) {
      return <Overlay type="loading" visible={true} />;
    }

    const subheaderProps: SubheaderOwnProps = {
      title: config.title,
      showAlternateFlowStatus: false,
      showAlternateSearch: false,
      extraDropdowns: this.generateSubheaderExtraDropdownProps(),
    };

    return (
      <div className={styles.toggleContainer} data-qa="parameter-container">
        <Subheader {...subheaderProps} />
        <section className={styles.accordionContainer}>{this.renderSectionPanels()}</section>
        <section className={fabBtn}>
          <Tooltip title="Allocate all worklist items" enterDelay={0} arrow>
            <Fab color="secondary" aria-label="Execute Allocation Plan" onClick={this.executeAllocationPlan}>
              <i
                style={{ color: 'white' }}
                className={classes(fabDataProcessing ? 'fa fa-spinner fa-spin' : 'fal fa-large fa-forward')}
              />
            </Fab>
          </Tooltip>
        </section>
      </div>
    );
  }
}
