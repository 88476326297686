import { style } from 'typestyle';

export const sectionContainer = style({
  $debugName: 'section-title',
  display: 'flex',
  alignItems: 'center',
  margin: '0.7rem',
  cursor: 'pointer',
  width: 300,
  $nest: {
    '.section-icon': {
      marginRight: '10px',
      width: 20,
    },
  },
});
export const viewsContainer = style({
  display: 'flex',
  width: '15rem',
  alignItems: 'center',
  margin: '0.1rem',
  $nest: {
    '.view-icon': {
      marginRight: '10px',
      width: 16,
    },
    '.view-name': {
      fontSize: '.75rem',
      cursor: 'default',
    },
  },
});

export const autocompleteStyle = style({
  display: 'flex',
  marginTop: 10,
  $nest: {
    '& * .s5-MuiAutocomplete-endAdornment': {
      width: 47,
      display: 'flex',
    },
    '& > .s5-MuiAutocomplete-popper': {
      marginTop: 40,
    },
  },
});
export const perspectiveSelectStyle = style({
  marginTop: 20,
  display: 'flex',
});

export const iconClass = style({
  marginLeft: 7,
  marginTop: 7,
});

export const leftIconClass = style({
  fontSize: 16,
  width: 16,
  marginRight: 10,
  textAlign: 'center',
  cursor: 'pointer',
});

export const textFieldContainer = style({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '0.5em',
});
