import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { projectState, StateSelection } from 'src/components/MacroGridPair/MacroGridPair.selectors';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { isEmpty } from 'lodash';
import { NestedAttributeOwnProps } from 'src/services/configuration/codecs/ownProps';

function selectState(state: AppState, ownProps: NestedAttributeOwnProps): StateSelection {
  const { title } = ownProps;
  const { nestedAttribute: viewState } = state.pages.hindsighting.aggregateViews;
  const { viewDataState, viewDefns, isConfigLoading, aggBys } = viewState;
  const configLoaded = !isConfigLoading;
  const dataLoaded = isDataLoaded(viewDataState);
  const treeData = getDataFromCache(viewState)?.tree || [];
  const gridData = !isEmpty(treeData) ? treeData[0].children : [];
  const macroData = !isEmpty(treeData) ? treeData : [];

  return {
    title,
    configLoaded,
    dataLoaded,
    gridDataLoaded: dataLoaded,
    macroDataLoaded: dataLoaded,
    subheaderState: state.subheader,
    macroData,
    gridData,
    viewDefns,
    aggBys,
    viewDataState,
  };
}

export const selectAndProjectState = createSelector(selectState, projectState);
