import { AxiosInstance, AxiosResponse } from 'axios';
import { LOCATION, PRODLIFE, PRODUCT, TIME } from '../utils/Domain/Constants';
import { isValidPerspectivePath } from '../state/scope/Scope.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import Scope from './Scope.client';
import LoggingService from './Logging.service';
import { z } from 'zod';
// due to an import error in some tests, these need to be imported last, and in this order
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import qs from 'query-string';
import { reduce } from 'lodash';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
import { Publishing } from 'src/state/scope/ScopeManagement.slice';

const getAdminRoute = (siloId: string, module: string, endPaths?: string) =>
  `${API_BASE_URL}/silo/${siloId}/admin/${module}${endPaths}`;

interface MarkPlanLP {
  id: number;
  marker: 'lp'; // May extend this someday
}

export interface PlansFilters {
  version?: Publishing['publishVersions'];
  ownedBySelf?: boolean;
  [TIME]?: string[];
  [LOCATION]?: string[];
  [PRODLIFE]?: string[];
  [PRODUCT]?: string[];
}

export default class AdminService {
  protected client: AxiosInstance;
  protected logger: LoggingService;
  private logError: (error: Error) => Error;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.logger = new LoggingService(this.client);
    this.logError = (error: Error) => Scope.logAsyncOrDecoderError(this.logger, error);
  }

  // TODO: add filters
  public getPlans(module: string, siloId: string, filters?: PlansFilters): Promise<PlanMetadata[]> {
    if (!isValidPerspectivePath(module)) {
      throw new Error('An invalid perspective was accessed');
    }
    const vers = filters && filters.version ? filters.version.map(version => version.id).join() : [];
    const prods = filters?.[PRODUCT]?.join() ?? [];
    const locs = filters?.[LOCATION]?.join() ?? [];
    const _filters = filters ? { ...filters, version: vers, product: prods, location: locs } : undefined;
    const endPath = _filters ? `/plans?${qs.stringify(_filters)}` : `/plans}`;
    return this.client
      .get(getAdminRoute(siloId, module, endPath))
      .then((response) => z.array(PlanMetadata).parse(response.data))
      .catch((error) => {
        toast.error('An error occured while getting the plans ');
        ServiceContainer.loggingService.error('An error occured while getting the plans', error.stack);
        throw new Error('An error occured while getting the plans');
      });
  }

  public getMarkedPlans(module: string, siloId: string) {
    if (!isValidPerspectivePath(module)) {
      throw new Error('An invalid perspective was accessed');
    }
    return this.client
      .get(getAdminRoute(siloId, module, '/mark'))
      .then((response) => z.array(PlanMetadata).parse(response.data))
      .catch((error) => {
        toast.error('An error occured while attempting to mark the plans');
        ServiceContainer.loggingService.error('An error occured while attempting to mark the plans', error.stack);
        throw new Error('An error occured while attempting to mark the plans');
      });
  }

  public getApprovedSubmissions(module: string, siloId: string) {
    if (!isValidPerspectivePath(module)) {
      throw new Error('An invalid perspective was accessed');
    }
    return this.client
      .get(getAdminRoute(siloId, module, '/approve'))
      .then((response) => z.array(PlanMetadata).parse(response.data))
      .catch((error) => {
        toast.error('An error occured while attempting to submit the plans');
        ServiceContainer.loggingService.error('An error occured while attempting to submit the plans', error.stack);
        throw new Error('An error occured while attempting to submit the plans');
      });
  }

  public approvePlan(module: string, planId: number, forceOp: boolean, siloId: string) {
    if (!isValidPerspectivePath(module)) {
      throw new Error('An invalid perspective was accessed');
    }
    const payload = JSON.stringify({
      id: planId,
      type: 'Plan',
      forceOp: forceOp,
    });

    return this.client.post(getAdminRoute(siloId, module, '/approve'), payload).catch((error) => {
      toast.error('An error occured while updating the plans ');
      ServiceContainer.loggingService.error('An error occured while updating the plans', error.stack);
      throw new Error('An error occured while updating the plans');
    });
  }

  public markPlan(planId: number, siloId: string, module: string) {
    const payload: MarkPlanLP = {
      id: planId,
      marker: 'lp',
    };

    return this.client
      .post(getAdminRoute(siloId, module, '/mark'), JSON.stringify(payload))
      .then((response) => response.data)
      .catch((error) => {
        toast.error('An error occured while updating the plans ');
        ServiceContainer.loggingService.error('An error occured while updating the plans', error.stack);
        throw new Error('An error occured while updating the plans');
      });
  }

  public deletePlan(planId: number, module: string, siloId: string) {
    if (!isValidPerspectivePath(module)) {
      toast.error('An invalid perspective was accessed');
      throw new Error('An invalid perspective was accessed');
    }
    return this.client.delete(getAdminRoute(siloId, module, `/plans/${planId}`)).catch((error) => {
      toast.error('An error occured while deleting the plans');
      ServiceContainer.loggingService.error('An error occured while deleting the plans', error.stack);
      throw new Error('An error occured while deleting the plans');
    });
  }
  public clonePlan(planIds: number[], version: string, module: string, siloId: string) {
    if (!isValidPerspectivePath(module)) {
      toast.error('An invalid perspective was accessed');
      throw new Error('An invalid perspective was accessed');
    }

    const payload = {
      planIds: planIds,
      destinationVersion: version,
    };

    return this.client.post(getAdminRoute(siloId, module, '/apply'), payload).catch(this.logError);
  }
  public clonePlanActualize(planIds: number[], version: string, module: string, siloId: string) {
    if (!isValidPerspectivePath(module)) {
      toast.error('An invalid perspective was accessed');
      throw new Error('An invalid perspective was accessed');
    }

    // chain promises to execute sequentially
    const chain: Promise<AxiosResponse<any> | Error> = reduce(
      planIds,
      async (previousPromise, nextID) => {
        await previousPromise;

        const payload = {
          planId: nextID,
        };

        return this.client.post(getAdminRoute(siloId, module, '/actualize'), payload).catch(this.logError);
      },
      (Promise.resolve() as unknown) as Promise<AxiosResponse<any> | Error>
    );

    return chain;
  }
}
