import Axios from 'src/services/axios';
import { errorToLoggingPayload } from 'src/services/loggingService';
type ConfigPath = 'conf' | 'view';
const CONFIG_SERVICE_PATH_PREFIX = `/api/config`;

export const getAllViewDefns = async () => {
  const resp = await Axios.get(`${CONFIG_SERVICE_PATH_PREFIX}/uidefn/view/`);
  return resp.data;
};
export const getAllConfDefns = async () => {
  const resp = await Axios.get(`${CONFIG_SERVICE_PATH_PREFIX}/uidefn/conf/`);
  return resp.data;
};
// A defn not being found (404) isn't bad, just means it needs a POST and not a PUT
const getDefn = async <T>(type: ConfigPath, defnName: string) => {
  try {
    const resp = await Axios.get<T>(`${CONFIG_SERVICE_PATH_PREFIX}/uidefn/${type}/${defnName}.${type}defn`, {
      headers: {
        Accept: 'application/octet-stream',
      },
    });
    return resp.data;
  } catch (e) {
    // @ts-ignore 'e' is of type unknown
    if (e.response && e.response.status == 404) {
      return 'not found';
    } else {
      console.error(errorToLoggingPayload(e)); // eslint-disable-line no-console
    }
  }
  return;
};
const updateConfig = async (type: ConfigPath, defnName: string, data: any[]) => {
  const path = `/uidefn/${type}/${defnName}.${type}defn`;
  return await Axios({
    method: 'PUT',
    url: `${CONFIG_SERVICE_PATH_PREFIX}${path}`,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    data,
  }).catch((e) => {
    console.error(errorToLoggingPayload(e)); // eslint-disable-line no-console
  });
};

export const getViewDefn = async <T>(viewDefnName: string) => {
  const defn = await getDefn<T>('view', viewDefnName);
  return defn == 'not found' ? undefined : defn;
};
export const getConfDefn = async <T>(confDefnName: string) => {
  const defn = await getDefn<T>('conf', confDefnName);
  return defn == 'not found' ? undefined : defn;
};

export const updateViewDefn = async (viewDefnName: string, data: any) => {
  await updateConfig('view', viewDefnName, data);
};
export const updateConfDefn = async (confDefnName: string, data: any) => {
  await updateConfig('conf', confDefnName, data);
};
