import React, { ChangeEvent, useEffect, useState } from 'react';
import { isEmpty, isNil, isUndefined, isNull, isNaN } from 'lodash';
import validator from 'validator';
import { toast } from 'react-toastify';
import limitedEvaluate, { mathScope } from 'src/services/configuration/MathJS.service';
import ServiceContainer from 'src/ServiceContainer';
import { InputCharacterWhitelist } from 'src/common-ui/components/Inputs/InputGeneric/InputGeneric';

export interface InputIntegerProps {
  value?: string | number | null;
  onChange: (text: string | number | null) => void;
  onBlur?: (prop: any) => any;
  editable?: boolean;
  valid?: boolean;
  autoFocus?: boolean;
  className?: string;
  nullable?: boolean;
  id?: string;
}

const InputInteger = (props: InputIntegerProps) => {
  const { editable, onChange, nullable, value: propValue, id } = props;

  const [localValue, setLocalValue] = useState(() => propValue);

  useEffect(() => {
    setLocalValue(propValue);
  }, [propValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const trimmedValue = newValue.trim();

    if (validator.isNumeric(trimmedValue)) {
      setLocalValue(trimmedValue);
    } else {
      const whitelistedValue = validator.whitelist(trimmedValue, InputCharacterWhitelist.basicMath);
      setLocalValue(whitelistedValue);
    }
  };

  const handleBlur = () => {
    if ((isNil(localValue) || isEmpty(localValue)) && !nullable) {
      setLocalValue(0);
      onChange(0);
      return;
    }

    // onChange can't handle undefined, default to null
    let nextValue = isUndefined(propValue) ? null : propValue;
    try {
      const internalValue = localValue?.toString() || '';

      // outputs NaN for anything equation like
      // also prevents numbers from being handled in limitedEvaluate below (like 0) resulting in null
      const parsedInternalValue = Number(internalValue);

      // check internalValue for empty string because passing empty string to Number(...) above converts to 0 which is unwanted
      if (isEmpty(internalValue) || isNaN(parsedInternalValue)) {
        const evaluated = limitedEvaluate ? limitedEvaluate(internalValue, mathScope) : null;
        nextValue = !isNil(evaluated) ? evaluated : null;
      } else {
        nextValue = parsedInternalValue;
      }
    } catch (e) {
      toast.error('Unable to parse the input expression, the edit has been reverted', {
        position: toast.POSITION.TOP_LEFT,
      });
      // FIXME: uncommenting this causes the vitest implementation to crash,
      // it seems to be because I am not mocking the service properly or it could be an imports order issue
      // Unfortunately haven't figured this out yet with vite & msw (https://github.com/mswjs/msw)
      ServiceContainer.loggingService.error(
        `Error: unable to parse an expression the user entered '${localValue}'`,
        JSON.stringify((e as Error).stack)
      );
    } finally {
      setLocalValue(nextValue);
      onChange(nextValue);
    }
  };

  // input's `value` attribute can't handle null,
  // also won't update if passing undefined, so instead set to empty string so rendered data updates
  const finalValue = isNil(localValue) ? '' : localValue;
  return (
    <input
      id={id}
      data-qa={'InputInteger'}
      onChange={handleChange}
      onBlur={handleBlur}
      value={finalValue}
      disabled={!editable}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
    />
  );
};

export default InputInteger;
