import React from 'react';
import _, { isEqual, isEmpty } from 'lodash';
import { Renderer } from 'src/utils/Domain/Renderer';
import { Point, Series } from 'highcharts';
import { HighchartsReactRef, RenderProps } from './PieRender';
import HighchartsReact from 'highcharts-react-official';

export class ChartRender extends React.Component<RenderProps> {
  chartRefs: (HighchartsReactRef | null)[];
  constructor(props: RenderProps) {
    super(props);
    this.chartRefs = [];
  }

  componentDidUpdate(prevProps: Readonly<RenderProps>): void {
    if (
      this.props.printWidth &&
      !isEqual(this.props.printWidth, prevProps.printWidth) &&
      this.props.isPrintMode &&
      !isEmpty(this.chartRefs)
    ) {
      // convert inches to pixels, 1 inch = 96 px based on MDN
      // docs here : https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units
      const width = (parseFloat(this.props.printWidth) * 120) / this.chartRefs.length;
      this.chartRefs.forEach((chartRef) => {
        if (chartRef && chartRef.chart) {
          chartRef.chart.chartWidth = width;
          chartRef.chart.reflow();
        }
      });
    }
    if (!this.props.isPrintMode && !isEmpty(this.chartRefs)) {
      this.chartRefs.forEach((chartRef) => {
        if (chartRef && chartRef.chart) {
          chartRef.chart.reflow();
        }
      });
    }
  }
  shouldComponentUpdate(nextProps: RenderProps) {
    return !_.isEqual(this.props.data, nextProps.data) || !isEqual(this.props.printWidth, nextProps.printWidth);
  }

  render() {
    const { data, view, config } = this.props;

    const bars = _.map(
      _.map(view.view, (c) => {
        return {
          title: c.text,
          view: c,
          series: _.map(c.view, (item) => ({
            name: item.text,
            type: 'column',
            innerSize: '40%',
            data: _.map(data, item.dataIndex),
          })),
        };
      }),
      (res, ind) => {
        const inconfig = _.cloneDeep(config);
        inconfig.title = {
          ...inconfig.title,
          text: res.title,
          floating: true,
          margin: 0,
          y: 5,
          verticalAlign: 'top',
        };
        inconfig.tooltip = {
          formatter: function(this: { point: Point; series: Series }) {
            const value = Renderer.renderJustValue(this.point.y, res.view);
            return `<small>
              ${this.point.category}
              </small><br />
              ${this.series.name}: <b>${value}</b>`;
          },
        };
        inconfig.xAxis = { categories: _.map(data, 'name') };
        inconfig.chart = {
          ...inconfig.chart,
          type: 'column',
          marginTop: 25,
        };
        const series = _.map(res.series, (d) => {
          return { name: d.name, data: d.data };
        });
        return (
          <HighchartsReact
            key={ind}
            options={{ ...inconfig, series }}
            ref={(ref) => (this.chartRefs[ind] = ref)}
            containerProps={{
              style: { width: '33%', height: '100%' },
            }}
          />
        );
      }
    );
    return bars;
  }
}
