import { style } from 'typestyle';
import { RED_PRIMARY } from 'src/utils/Style/Theme';
import { extraSmallFont } from 'src/utils/Style/Theme';

export const BottomBarContainer = style({
  minHeight: '1.25rem',
  $nest: {
    '.summary': {
      fontSize: extraSmallFont,
      opacity: 0.8,
      padding: '0.375rem 0 0.185rem',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      $nest: {
        '&-description': {
          marginBottom: 0,
          $nest: {
            '&:not(:last-of-type)::after': {
              content: '" | "',
              marginRight: '0.185rem',
            },
          },
        },
        '&-error': {
          color: RED_PRIMARY,
          margin: '0 0 0 auto',
        },
        '&-list': {
          display: 'flex',
          marginBottom: 0,
        },
        '&-term': {
          fontWeight: 400,
          $nest: {
            '&::after': {
              content: '": "',
              marginRight: '0.185rem',
            },
          },
        },
      },
    },
  },
});

export const LookBackContainer = style({
  display: 'flex',
  $nest: {
    '.look-back': {
      marginRight: '10px',
    },
  },
});
