import { TEAL_PRIMARY } from 'src/common-ui/theme';
import { style } from 'typestyle';
import { important } from 'csx';

const dataContainer = style({
  $debugName: 'data-container',
  height: '100%',
});

const contentContainer = style({
  $debugName: 'content-container',
  display: 'flex',
  width: '100%',
  height: `calc(100% - ${55}px)`,
  overflowY: 'visible',
  overflowX: 'hidden',
});

const searchContainer = style({
  $debugName: 'search-container',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  borderRight: '1px solid #ddd',
  maxWidth: '300px',
  minWidth: '300px',
  margin: '0 0.5rem',
  padding: '2rem 1rem 0 0',
});
const subTitle = style({
  marginBottom: '0.5rem !important',
});

const textArea = {
  marginBottom: '1rem',
  resize: 'none',
} as const;

const buttonSearch = style({
  color: 'white !important',
  backgroundColor: important(TEAL_PRIMARY),
});

const buttonClear = style({
  color: important(TEAL_PRIMARY),
  borderColor: important(TEAL_PRIMARY),
  marginTop: '1rem !important',
});

const noData = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});
export { dataContainer, contentContainer, searchContainer, subTitle, textArea, buttonSearch, buttonClear, noData };
