import React from 'react';
import moment from 'moment';

import WeekRangePicker from 'src/common-ui/components/WeekRange/WeekRangePicker';
import { WeekRange } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';

import FloorsetSelectorForm from 'src/components/AssortmentScopeSelector/FloorSetSelectorForm/FloorsetSelectorForm.container';
import { DaysRangeListResponse } from 'src/types/Scope';
import { StateProps, DispatchProps, RouteProps } from './AssortmentPeriodForm.container';
import { isNil, sortBy } from 'lodash';
import styles from '../AssortmentScopeSelector.styles';
import { getClosestSunday } from 'src/common-ui/components/WeekRange/WeekRangePicker.utils';
import { ASSORTMENT_CONTEXT } from 'src/utils/Domain/Constants';

export interface Props extends StateProps, DispatchProps, RouteProps {}

export interface CurrentAndPastDatesData {
  start: string;
  historyStart: string;
  end: string;
  historyEnd: string;
}
export function getCurrentAndPastDatesFromRange(
  range: WeekRange,
  daysRangeList: DaysRangeListResponse,
  daysPastRangeList: DaysRangeListResponse
): CurrentAndPastDatesData {
  const { from, to } = range;

  const fromDate = moment(from).format('YYYY-MM-DD');
  const toDate = moment(to).format('YYYY-MM-DD');
  const fromId = daysRangeList.start_date[fromDate];
  const toId = daysRangeList.end_date[toDate];

  const daysDiff = moment(to).diff(moment(from), 'days');
  const historyFrom = getClosestSunday(moment(from).subtract(365, 'days'));
  const historyFromDate = historyFrom.format('YYYY-MM-DD');
  const historyToDate = historyFrom.add(daysDiff, 'days').format('YYYY-MM-DD');

  let historyFromId = daysPastRangeList.start_date[historyFromDate];
  // If the to date is outside of the range of the list (earlier date), get the earliest date in rangelist
  if (Object.keys(daysPastRangeList.start_date).every((x) => x > historyFromDate)) {
    historyFromId = Object.values(daysPastRangeList.start_date).sort()[0];
  }
  let historyToId = daysPastRangeList.end_date[historyToDate];
  // If the to date is outside of the range of the list (later date), get the latest date in rangelist
  if (Object.keys(daysPastRangeList.end_date).every((x) => x < historyToDate)) {
    historyToId = Object.values(daysPastRangeList.end_date)
      .sort()
      .slice(-1)[0];
  }

  const historyGuard = (historyRange: Record<string, string>, id: string | undefined) => {
    let range = sortBy(historyRange, (value) => value);
    if (isNil(id)) return range[range.length - 1];
    range = range.slice(0, range.length);
    return range.includes(id) ? id : range[range.length - 1];
  };

  return {
    start: fromId,
    end: toId,
    historyStart: historyGuard(daysPastRangeList.start_date, historyFromId),
    historyEnd: historyGuard(daysPastRangeList.end_date, historyToId),
  };
}

export default function AssortmentPeriodForm(props: Props) {
  const {
    selectedStartWeek,
    selectedEndWeek,
    daysRangeList,
    daysPastRangeList,
    allowTo,
    allowFrom,
    onChangeStartWeek,
    onChangeEndWeek,
  } = props;

  const onNewWeeksSelected = (range: WeekRange) => {
    const dates = getCurrentAndPastDatesFromRange(range, daysRangeList, daysPastRangeList);
    onChangeStartWeek({ start: dates.start, historyStart: dates.historyStart });
    onChangeEndWeek({ end: dates.end, historyEnd: dates.historyEnd });
  };

  return (
    <div className={styles.scopeColumn}>
      <FloorsetSelectorForm region={ASSORTMENT_CONTEXT} />
      <div className="picker" data-qa="datepicker-assortmentperiod">
        <WeekRangePicker
          autoSelect={true}
          allowFrom={allowFrom}
          allowTo={allowTo}
          dateToNameMap={daysRangeList}
          initialSelectedFrom={selectedStartWeek || ''}
          initialSelectedTo={selectedEndWeek || ''}
          onNewWeeksSelected={(range: WeekRange) => onNewWeeksSelected(range)}
          selectionOptions={[{ label: 'Start' }, { label: 'End' }]}
        />
      </div>
      <div className="text">Assortment Period</div>
    </div>
  );
}
