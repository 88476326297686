import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterSelection, SelectionOverride } from 'src/common-ui/components/Filters/Filters';
import { FilterCondition } from 'src/dao/filterClient';
import { FilterGroup } from 'src/types/Scope';
import { calcInitalOverride, mergeSelectionOverrides, resetSelectionOverrides } from 'src/utils/Filter/Filters';
import { ReduxSlice } from './FilterPanel.container';

export const initialState: ReduxSlice = {
  isFilterSensitive: false,
  updatedAt: undefined,
  state: [],
  isFlushing: false,
  isLoading: false,
  selectionOverrides: [],
  lastSelections: [],
  lastPostedSelections: [],
  containsInvalid: false,
  selectionsChanged: false,
  lastOverridedSection: '',
};

type FiltersUpdated = {
  filters: FilterGroup[];
  updatedAt?: number;
};

type FiltersAfterSelection = {
  filters: FilterGroup[];
  postedSelections: FilterSelection[];
};

const receiveFilterStateAction = (state: ReduxSlice, action: PayloadAction<FiltersUpdated>) => {
  if (action.payload.updatedAt) {
    state.updatedAt = action.payload.updatedAt;
  }

  state.isFlushing = false;
  state.isLoading = false;
  state.state = action.payload.filters;
};

const filterPanelSliceReducer = createSlice({
  name: 'FilterPanel',
  initialState: initialState,
  reducers: {
    receiveFilterState: receiveFilterStateAction,
    // duplicate this action so that we can trigger separately when receiving state (such as after a scope),
    // and receive state after an explicit filter submission
    receiveFilterStateAfterSubmission: (state: ReduxSlice, action: PayloadAction<FiltersUpdated>) => {
      receiveFilterStateAction(state, action);
    },
    updateFilterSelectionsFromServer: (state: ReduxSlice, action: PayloadAction<SelectionOverride[]>) => {
      const selections = action.payload;

      state.selectionOverrides = selections;
      state.lastSelections = selections;
      state.lastPostedSelections = selections;
      state.isFlushing = false;
    },
    receiveFilterStateAfterSelection(state, action: PayloadAction<FiltersAfterSelection>) {
      const initialOverrides = calcInitalOverride(action.payload.filters);

      state.isFlushing = false;
      state.isLoading = false;
      state.state = action.payload.filters;
      state.lastPostedSelections = action.payload.postedSelections;
      // FIXME: do we need to filter out set selections available in Assortment but not in Hindsighting
      // state.selectionOverrides = initialOverrides;
      state.lastOverridedSection = '';
    },
    overwriteSelectionOverridesByDefnId(
      state,
      action: PayloadAction<{
        defnId: string;
        selections: SelectionOverride[];
      }>
    ) {
      const newOverrides = state.selectionOverrides.filter((s) => s.filterDefnId !== action.payload.defnId);
      const selectionOverrides = mergeSelectionOverrides(newOverrides, action.payload.selections);
      state.selectionOverrides = selectionOverrides;
      state.isFlushing = false;
      // TODO: verify that this can be removed
      if (state.selectionOverrides[0]) {
        state.lastOverridedSection = state.selectionOverrides[0].filterDefnId;
      }
    },
    updateSelectionOverrides(state, action: PayloadAction<SelectionOverride[]>) {
      const selectionOverrides = mergeSelectionOverrides(state.selectionOverrides, action.payload);
      state.selectionOverrides = selectionOverrides;
      state.isFlushing = false;
      // TODO: verify that this can be removed
      if (state.selectionOverrides[0]) {
        state.lastOverridedSection = state.selectionOverrides[0].filterDefnId;
      }
    },
    // disregards unsaved selections and resets to selections received from the backend
    rollbackSelectionOverrides(state, action: PayloadAction<FilterCondition[]>) {
      const newOverrides = resetSelectionOverrides(state.selectionOverrides, action.payload);
      state.selectionOverrides = newOverrides;
    },
    makeUpdateLastSelection(state, action: PayloadAction<FilterSelection[]>) {
      state.lastSelections = action.payload;
    },
    updateFilterSensitivity(state, action: PayloadAction<boolean>) {
      state.isFilterSensitive = action.payload;
    },
    flushSelectionOverridesStarted(state) {
      state.isFlushing = false;
    },
    isLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateFilterContainsInvalid(state, action: PayloadAction<boolean>) {
      state.containsInvalid = action.payload;
    },
  },
});

export const {
  receiveFilterStateAfterSelection,
  receiveFilterState,
  receiveFilterStateAfterSubmission,
  updateFilterContainsInvalid,
  updateFilterSensitivity,
  makeUpdateLastSelection,
  overwriteSelectionOverridesByDefnId,
  updateSelectionOverrides,
  rollbackSelectionOverrides,
  flushSelectionOverridesStarted,
  isLoading,
  updateFilterSelectionsFromServer,
} = filterPanelSliceReducer.actions;
export default filterPanelSliceReducer.reducer;
