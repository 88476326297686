import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const modal = style({
  $debugName: 'modalContainer',
  maxWidth: '30vw',
  margin: '0 auto',
});
const titleContainer = style({
  display: 'flex',
});
const leftTitleContainer = style({
  flexGrow: 1,
  cursor: 'default',
});

const rightTitleContainer = style({
  flexGrow: 0,
  cursor: 'pointer',
});
const modalTitle = style({
  $debugName: 'modalTitle',
  backgroundColor: TEAL_PRIMARY,
  padding: '0.5rem 1rem !important',
  color: 'white',
});
const icon = style({
  width: '3rem',
  height: '3rem',
  backgroundColor: 'whitesmoke',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '5px',
});
const iconsContainer = style({
  $debugName: 'Icon-Container',
  display: 'flex',
  flexWrap: 'wrap',
  maxHeight: '30vh',
  height: '20rem',
  width: '18.2rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  alignContent: 'flex-start',
  marginLeft: -12,
});
const contentContainer = style({
  marginBottom: '1rem',
  marginTop: '0.5rem',
  $nest: {
    '.search-container': {
      width: '100%',
      $nest: {
        input: {
          width: '100%',
        },
      },
    },
  },
});
export {
  modal,
  contentContainer,
  icon,
  titleContainer,
  leftTitleContainer,
  rightTitleContainer,
  modalTitle,
  iconsContainer,
};
