import { AxiosInstance, AxiosPromise } from 'axios';
import PivotConfig from 'src/pivot/PivotConfig';
import { ReportItem, ILLEGAL_XLS_SHEET_CHARACTERS } from './Reports.types';
import { getEffeciveGroups, PivotOptions } from 'src/components/Mfp/PivotConfigurator/utils';
import { ConfigGroup, ConfigItem } from 'src/components/Mfp/PivotConfigurator/PivotConfigurator.types';
import { ServerScope } from 'src/state/scope/Scope.types';
import { getGroupFromConfigItem } from 'src/components/Mfp/PivotConfigurator/utils';
import _, { uniqueId, cloneDeep } from 'lodash';
import PivotManager from 'src/pivot/Pivot.client';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { ROWS } from '../utils/Domain/Constants';
import queryString from 'query-string';

interface ReportClientProps {
  // downloading from an existing pivot only requires client
  // such as the download button on the grid
  // report need their pivots setup before download
  client: AxiosInstance;
  allAvailableListing?: ConfigGroup[];
  mainConfig?: ServerScope;
  scopeId?: string;
  token: string;
}

export interface ReportStatus {
  id: string;
  reportReady: boolean;
}

export default class ReportClient {
  private client: AxiosInstance;
  private allAvailableListing: ConfigGroup[] | undefined;
  private levelsMap: _.Dictionary<{}> | undefined;
  private scopeId!: string | undefined;
  private reportToken: {
    token: string | null;
  } = {
    token: null, // default
  };
  private workingSetSource!: EventSource;

  constructor(props: ReportClientProps) {
    this.client = props.client;

    if (props.mainConfig) {
      this.allAvailableListing = props.allAvailableListing;
      this.levelsMap = _.keyBy(_.flatMap(props.mainConfig.levels), 'id');
      this.scopeId = props.scopeId;
      this.reportToken = {
        token: props.token,
      };
    }
  }
  public createReportContexts(id: string, report: ReportItem) {
    if (!report || !id) {
      throw new Error('Report not Found');
    }

    return Promise.all(
      report.value.flatMap((pivot) => {
        const pivotConfigs = this.reportDefToPivotConfig(pivot);

        const confs = pivotConfigs.map((conf) => {
          // need to remove illegal sheet name characters
          const pivotName = (conf.name || `Sheet ${uniqueId()}`).replace(ILLEGAL_XLS_SHEET_CHARACTERS, '');

          const pivotManager = new PivotManager({
            config: conf.pivotConfig,
            axios: this.client,
            pivotName: pivotName,
          });
          return pivotManager.createContext();
        });
        return confs;
      })
    );
  }

  private reportDefToPivotConfig(pivot: PivotOptions) {
    if (pivot.groupBy) {
      const dim = this.allAvailableListing!.find((dim) => dim.id === pivot.groupBy!.dimension)!;
      const levels = dim.children!.flatMap((c) => this.findLevelChildren(c, pivot.groupBy!.item));

      return levels.map((chld) => {
        // make a copy of the source pivot, replacing the each element from the groupBy level
        // as the new level to build a pivot
        const newPivot = cloneDeep(pivot);

        newPivot[ROWS].unshift({
          dimension: dim.id,
          items: [chld.id],
        });

        return {
          pivotConfig: this.buildPivotConfig(newPivot),
          name: chld.name,
        };
      });
    }
    // no groupBy, just send back one config
    return [
      {
        pivotConfig: this.buildPivotConfig(pivot),
        name: pivot.name || `Sheet ${uniqueId()}`,
      },
    ];
  }

  private buildPivotConfig(pivot: PivotOptions) {
    if (!this.scopeId) {
      throw new Error('Building a report pivot requires a scope id');
    }
    const effective = getEffeciveGroups(this.allAvailableListing!, pivot);

    const rowMemberInfo: ConfigGroup[] = effective.row;
    const colMemberInfo: ConfigGroup[] = effective.column;

    const pivotRows = rowMemberInfo.map((mi) =>
      // @ts-ignore
      getGroupFromConfigItem(mi, pivot, this.levelsMap)
    );
    const pivotCols = colMemberInfo.map((mi) =>
      // @ts-ignore
      getGroupFromConfigItem(mi, pivot, this.levelsMap)
    );

    const pivotConfig = new PivotConfig({
      scopeId: this.scopeId,
      rows: pivotRows,
      columns: pivotCols,
    });
    return pivotConfig;
  }

  public requestCreateReport(reportItem: ReportItem): AxiosPromise<ReportStatus> {
    // Report generation is async w/ polling, so request it be created,
    // and the workingSets server sent event will send back when it's ready
    return this.createReportContexts(reportItem.id, reportItem).then((pivots) => {
      const exportOptions = reportItem.exportOptions ? reportItem.exportOptions : {};
      const queryParams = queryString.stringify(
        {
          ...exportOptions,
          ...this.reportToken,
          pivots: pivots.join(),
          reportName: reportItem.name, // this probably should be encoded, but isn't currently
        },
        {
          encode: true, // should already be encoded
        }
      );
      const requestGenerateReportUrl = `${API_BASE_URL}/context/${this.scopeId}/pivot/exports?${queryParams}`;

      return this.client.get<ReportStatus>(requestGenerateReportUrl);
    });
  }

  private findLevelChildren(configItem: ConfigItem, level: string) {
    // build up an array of config items that are of the same level
    const items: ConfigItem[] = [];

    function findLevels(ci: ConfigItem) {
      if (`level:${ci.level}` === level) {
        items.push(ci);
      }
      if (ci.children) {
        ci.children.forEach((child) => {
          findLevels(child);
        });
      }
    }
    findLevels(configItem);

    return items;
  }
}
