import { ofType } from 'redux-observable';
import { mergeMap, map, filter, of } from 'rxjs';
import { isEmpty } from 'lodash';
import { AppEpic } from 'src/store';
import {
  fetchStyleByLevelCompanionData,
  fetchStyleByLevelOvertimeData,
  receiveTenantConfig,
  receiveCompanionData,
  setSelectedId,
  removeOldData,
} from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime.slice';
import { inputIsNotNullOrUndefined, topMemInWorklistSelected } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  NestedOvertimeCombineComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';

export const styleByLevelOvertimeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      receiveTenantConfig.type,
      receiveCompanionData.type, // here for detecting initial companionData load and item selected in state
      setSelectedId.type
    ),
    map(() =>
      maybeGetComponentProps<NestedOvertimeCombineComponent>(state$.value, ConfDefnComponentType.nestedStyleOverTime)
    ),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(inputIsNotNullOrUndefined),
    // filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ dataApi, companionDataApi }) => {
      // FIXME: this is currently set to empty in config
      // when ready, need to update config structure to match other components
      // const { model: modelDefn } = defns;
      const overtimeDefn = dataApi.params.defnId;
      const companionDefn = companionDataApi?.params.defnId || '';
      const topMembers = state$.value.pages.hindsighting.styleByLevelOvertime.selectedCompanionId;
      const overtimeOptions: ListDataOptions = {
        ...dataApi.params,
        topMembers,
      };
      const companionOptions = companionDataApi?.params || {};
      // overtime data request needs a topmember which comes from companionData selection
      return isEmpty(topMembers)
        ? of(fetchStyleByLevelCompanionData(companionDefn, companionOptions))
        : of(removeOldData(), fetchStyleByLevelOvertimeData(overtimeDefn, overtimeOptions));
    })
  );
};
