import { style, media } from 'typestyle';
import { important, viewHeight } from 'csx';
import { extraSmallFont, TEAL_PRIMARY, smallBreakpoint } from 'src/utils/Style/Theme';

function gridWrapperHeightMediaQuery(height: number) {
  return {
    height: viewHeight(height),
  };
}

export const viewContainer = style({
  $debugName: 'assortmentPublishViewContainer',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
});

export const gridDetails = style({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: extraSmallFont,
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
});

export const gridCount = style({
  marginRight: '10px',
});
export const exitButton = 'exit-btn fas fa-times-circle';
export const modalHeader = style({
  width: '100%',
  backgroundColor: TEAL_PRIMARY,
  padding: '1em',
  marginBottom: '1em',
  $nest: {
    h3: {
      fontSize: '1.25em',
      margin: 0,
      color: 'white',
      paddingLeft: '1.25em',
      display: 'inline-block',
    },
    '.exit-btn': {
      float: 'right',
      fontSize: '1.25em',
      color: 'white',
      cursor: 'pointer',
    },
  },
});
export const modal = style({
  justifyContent: window.innerWidth > smallBreakpoint ? 'center' : 'inherit',
  display: 'flex',
  alignItems: 'center',
});
export const gridWrapper = style(
  media({ minWidth: 0, maxWidth: 1650 }, gridWrapperHeightMediaQuery(65)),
  media({ minWidth: 1651 }, gridWrapperHeightMediaQuery(70)),
  {
    $nest: {
      '& .data-grid.publishGrid': {
        height: '100%',
        fontSize: '13px',
      },
      [`.ag-row.ag-row-focus:not(.ag-row-hover) .s5-MuiCheckbox-root`]: {
        color: 'white',
      },
    },
  }
);

export const actionsContainer = style({
  $debugName: 'actionsContainer',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const actionButton = style({
  color: important('#ffffff'),
  backgroundColor: important(TEAL_PRIMARY),
  marginRight: important('10px'),
  $nest: {
    '&:disabled': {
      pointerEvents: important('visible'),
      cursor: important('not-allowed'),
      backgroundColor: important('#d6d6d6'),
    },
  },
});

export const headerCheckbox = style({
  $debugName: 'headerCheckbox',
  $nest: {
    '& .ag-header-select-all': {
      marginLeft: '12px',
    },
    '.ag-pinned-left-header': {
      border: 'none !important',
    },
  },
});
