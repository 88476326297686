import React, { Component } from 'react';
import DataGrid, { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import { cloneDeep, isNil } from 'lodash';
import { gridContainerStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { ColDef, IDetailCellRendererParams, IRowNode } from '@ag-grid-community/core';

export interface MasterDetailRendererProps extends IDetailCellRendererParams {
  dataGridProps: DataGridProps;
  detailHeight: number;
}

export class DetailGridRenderer extends Component<MasterDetailRendererProps, any> {
  constructor(props: MasterDetailRendererProps) {
    super(props);
    this.state = {
      data: [],
      componentHeight: 0,
    };
  }

  componentDidMount() {
    this.props.getDetailRowData({
      ...this.props,
      successCallback: (data) => {
        setTimeout(() => {
          const offset = 50;
          const rowHeight = 30;
          this.setState({
            data,
            componentHeight: data.length * rowHeight + offset,
          });
        }, 200);
      },
    });
  }

  render() {
    if (isNil(this.state.data) || this.state.data.length <= 0) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>Loading...</div>;
    }
    const gridProps = cloneDeep(this.props.dataGridProps);
    if (isNil(gridProps.extraAgGridProps)) gridProps.extraAgGridProps = {};
    gridProps.extraAgGridProps.groupDisplayType = 'multipleColumns';
    gridProps.extraAgGridProps.groupDefaultExpanded = 1;
    gridProps.extraAgGridProps.isExternalFilterPresent = () => true;
    gridProps.extraAgGridProps.doesExternalFilterPass = (params: IRowNode) => {
      const val = params.data['hide_row'];
      return val !== 1 && val !== '1';
    };
    gridProps.extraAgGridProps.autoGroupColumnDef = {
      pinned: true,
    };
    gridProps.columnDefs.forEach((colDef: ColDef) => {
      if ((colDef as ColDef).rowGroup) (colDef as ColDef).hide = true;
    });
    return (
      <div className={gridContainerStyle} style={{ height: this.state.componentHeight }}>
        <DataGrid {...gridProps} data={this.state.data} />
      </div>
    );
  }
}
