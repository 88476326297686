import Axios from 'src/services/axios';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ValidValues } from '../StyleEdit/StyleEditSection/StyleEditSection.client';

const mockApi = 'http://localhost:3555/v2/api';

export async function getAllColors(excludeInMember?: string) {
  let url = 'api/attribute/validValues/one?appName=Assortment&ids=cccolor';
  if (excludeInMember != null) {
    const encodedValue = encodeURIComponent(excludeInMember);
    url += `&excludeInMember=${encodedValue}`;
  }
  return Axios.get(url).then((results): string[] => {
    return results.data.data.map((item: ValidValues) => item.id).sort();
  });
}

export function getNewStyleColor(styleId: string) {
  const encodedValue = encodeURIComponent(styleId);
  const url = `${mockApi}/style/${encodedValue}/newStyleColor`;
  return Axios.get(url).then(
    (results): Record<string, any> => {
      return results.data;
    }
  );
}

export function getNewStyleColorPlaceholder(styleId: string) {
  const encodedValue = encodeURIComponent(styleId);

  const url = `${mockApi}/style/${encodedValue}/placeholder`;
  return Axios.get(url).then((results): string => {
    return results.data;
  });
}
export function getAllPowerdrivers(department: string): Promise<BasicPivotItem[]> {
  const encodedValue = encodeURIComponent(department);
  return Axios.get(`api/attribute/all?appName=Assortment&memberId=${encodedValue}&childLevel=subclass`)
    .then((resp) => {
      return resp.data.success ? resp.data.data : [];
    })
    .then((stylecolors: BasicPivotItem[]) => {
      return stylecolors;
    });
}
