import PivotManager from '../../../pivot/Pivot.client';
import { PivotCell } from '../../../pivot/PivotCell';
import PivotConfig from '../../../pivot/PivotConfig';
import { SettingsByKey } from '../../../services/Settings';
import { AgPivot } from './AgPivot';
import { SelectionRange } from './IPivot';
import { AgPivot as IAgPivot } from 'src/components/Mfp/Pivot/AgPivot';
import { mapDispatchToProps } from './AgPivot.container';
import { SplitViewGridType } from 'src/pages/Mfp/MfpGrid/split-view.types';
import { ScopeReadyData, ScopeStateUnion } from 'src/state/scope/Scope.types';
import type { Classes } from 'jss';
import type { ServiceContainer } from 'src/ServiceContainer';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';


export interface IAgPivotState {
  ready: boolean,
  manager: PivotManager,
  selectionRange?: SelectionRange,
  loading: boolean,
  isEditing: boolean,
  /**
   * Used only for detecting changes from parent
   */
  originalConfig: PivotConfig,
  colDefs: ColDef[] | ColGroupDef[] | undefined
}
export type IAgPivotProps = AgPivotOwnProps & AgPivotValueProps & AgPivotDispatchProps;

export interface AgPivotOwnProps {
  config: PivotConfig,
  onMount?: () => void,
  onDataChange?: (pivot: AgPivot) => void,
  onSelectionChange?: (range: PivotCell[]) => void,
  handleDownloadXLS?: (gridType?: SplitViewGridType) => void,
  handleSetPivot?: (pivot: IAgPivot) => void,
  pivotName?: string, // use for split view mainly, to designate a top/bottom pivot
  // use for smart plan, to designate the grid should read it's state from somewhere else
  alternateScopeStatus?: ScopeStateUnion,
  bulkUpdateOnly?: boolean // used for smart plan, to force lazy updates
}

export enum CellAdornmentType {
  dynamicCaret = 'dynamicCaret'
}

export interface CellAdornment {
  adornment: CellAdornmentType,
  space: {
    coreVersions: string[],
    tags: string[]
  }
}

export interface AgPivotValueProps {
  pivotName: string,
  levels: ScopeReadyData['mainConfig']['levels'] | undefined,
  scopeStatus: ScopeStateUnion,
  settings?: SettingsByKey,
  advisoryClasses: Classes<string | number>,
  forceRefreshGrid?: boolean,
  cellAdornments: CellAdornment[]
}

export type AgPivotDispatchProps = ReturnType<typeof mapDispatchToProps>

export interface CellRange {
  startColumn: number,
  endColumn: number,
  startRow: number,
  endRow: number
}
