import { AxiosInstance } from 'axios';
import { AbstractInMemoryCrud } from './crud/AbstractInMemoryCrud';
import { ICrudEntry } from './crud/ICrudInterface';
import { getConfigBaseRoute } from './Config.service';
import { Space } from 'src/space';
import { CellAdornment } from 'src/components/Mfp/Pivot/AgPivot.types';
import { ServerScopeMember } from 'src/state/scope/Scope.types';

export interface SettingsEntry extends ICrudEntry {
  value: string;
  type?: string;
}

export interface SettingsByKey {
  [key: string]: SettingsEntry;
}

export interface SettingsPayload {
  entries: SettingsEntry[];
}
export interface AncillaryPayload extends SettingsPayload {
  dimensionLabelProperty: Space<keyof ServerScopeMember>;
  cellAdornments: CellAdornment[];
}

export interface LocalizationPayload {
  baseLocale: string;
}

export interface OverlayVersion {
  id: string;
  name: string;
  metrics: string[];
}

export interface AdvisoryStyle {
  id: string;
  type: 'outline' | 'hatch';
  color: string;
}

export class Settings extends AbstractInMemoryCrud<SettingsEntry> {
  protected client: AxiosInstance;

  constructor(client: AxiosInstance) {
    super();
    this.client = client;
  }

  public getSettings(siloId: string): Promise<SettingsEntry[]> {
    return this.client
      .get<SettingsPayload>(`${getConfigBaseRoute(siloId)}/settings`)
      .then((response) => response.data)
      .then((data) => data.entries)
      .then((entries) => this.load(entries))
      .then(() => this.entries.slice(0));
  }

  public getAncillaryConfig(siloId: string): Promise<AncillaryPayload> {
    // this doesn't use the AbstractInMemoryCrud interface
    return this.client
      .get<AncillaryPayload>(`${getConfigBaseRoute(siloId)}/ancillary`)
      .then((response) => {
        return response.data;
      })
      .then((data) => data);
  }

  public getLocalizationConfig(siloId: string) {
    // this doesn't use the AbstractInMemoryCrud interface
    return this.client.get<LocalizationPayload>(`${getConfigBaseRoute(siloId)}/localization`).then((response) => {
      return response.data;
    });
  }

  public getOverlayVersions(siloId: string) {
    return this.client.get<OverlayVersion[]>(`${getConfigBaseRoute(siloId)}/system-versions`).then((response) => {
      return response.data;
    });
  }

  public getAdvisoryConfig(siloId: string) {
    return (
      this.client
        // there's some extra keys on this file we don't need in the frontend, so don't pass them through
        .get<{ advisoryStyling: AdvisoryStyle[] }>(`${getConfigBaseRoute(siloId)}/advisories`)
        .then((response) => {
          return response.data.advisoryStyling;
        })
    );
  }
}

export default Settings;
