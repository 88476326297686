import { GridViewConfig } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.slice';
import { Pivot, SubmitPayload, OverTimeType } from 'src/worker/pivotWorker.types';
import serviceContainer from 'src/ServiceContainer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  cacheCheckFetchPivotData,
  fetchListData,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { AppThunkDispatch } from 'src/store';
import { defaultTo, isNil } from 'lodash';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';

export interface PricingSlice {
  viewDefns: GridViewConfig;
  isConfigLoading: boolean;
  massEditData?: Pivot;
  events: PriceEvent[];
  selection: string;
  cacheHashCompanion: string | null;
  cacheHashOverTime: string | null;
  viewDataStateCompanion: ViewDataState;
  viewDataStateOverTime: ViewDataState;
  viewDataStateMassEdit: ViewDataState;
  selectedItem: string;
}

export interface PriceEvent {
  ccpriceevent: string;
  expression: string;
}

const initialState: PricingSlice = {
  viewDefns: {
    grid: {} as TenantConfigViewData,
    unmodifiedViewDefn: {} as TenantConfigViewData,
    listSort: {} as TenantConfigViewData,
    list: {} as TenantConfigViewData,
    subheaderRollUp: {} as TenantConfigViewData,
  },
  isConfigLoading: false,
  events: [],
  selection: '',
  viewDataStateCompanion: ViewDataState.idle,
  viewDataStateOverTime: ViewDataState.idle,
  viewDataStateMassEdit: ViewDataState.idle,
  cacheHashCompanion: null,
  cacheHashOverTime: null,
  selectedItem: '',
};

const pricingOverTimeReducer = createSlice({
  name: 'PricingOverTime',
  initialState: initialState,
  reducers: {
    requestPricingOverTimeConfig(state) {
      state.isConfigLoading = true;
    },
    receivePricingOverTimeConfig(state, action: PayloadAction<GridViewConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSomePricingOverTimeConfig(state, action: PayloadAction<TenantConfigViewData>) {
      state.isConfigLoading = false;
      state.viewDefns.grid = {
        ...state.viewDefns.grid,
        ...action.payload,
      };
    },
    requestOverTimeData(state) {
      state.viewDataStateOverTime = ViewDataState.liveDataLoadingNoCache;
    },
    receiveOverTimeCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashOverTime = action.payload;
    },
    receiveOverTimeCachedData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.liveDataLoadingFoundCache;
        state.selection = action.payload.memberId;
      }
    },
    receiveOverTimeLiveData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.liveDataReady;
        state.selection = action.payload.memberId;
      }
    },
    receivePricingEvents(state, action: PayloadAction<PriceEvent[] | null>) {
      state.events = defaultTo(action.payload, []);
    },
    requestMassEditData(state) {
      state.viewDataStateMassEdit = ViewDataState.liveDataLoadingNoCache;
    },
    receiveMassEditData(state, action: PayloadAction<Pivot>) {
      state.viewDataStateMassEdit = ViewDataState.liveDataReady;
      state.massEditData = action.payload;
    },
    requestCompanionData(state) {
      state.viewDataStateCompanion = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCompanionCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashCompanion = action.payload;
    },
    receiveCompanionCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        state.viewDataStateCompanion = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveCompanionLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        state.viewDataStateCompanion = ViewDataState.liveDataReady;
      }
    },
    updateSelectedItem(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    refreshOvertimeData: () => {
      // nothing to do here, this action triggers refetch in epic
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestPricingOverTimeConfig,
  receivePricingOverTimeConfig,
  requestOverTimeData,
  receiveOverTimeCacheHash,
  receiveOverTimeCachedData,
  receiveOverTimeLiveData,
  receivePricingEvents,
  requestMassEditData,
  receiveMassEditData,
  requestCompanionData,
  receiveCompanionCacheHash,
  receiveCompanionCachedData,
  receiveCompanionLiveData,
  updateSelectedItem,
  refreshOvertimeData,
  receiveError,
  cleanUp,
  receiveSomePricingOverTimeConfig,
} = pricingOverTimeReducer.actions;

export function fetchOverTimeData(memberId: string, overrideDefnId?: string) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.getOverTimeData(memberId, OverTimeType.pricing, overrideDefnId),
    requestOverTimeData,
    receiveOverTimeCacheHash,
    (cacheHash: string) => receiveOverTimeCachedData({ memberId, cacheHash }),
    (cacheHash: string) => receiveOverTimeLiveData({ memberId, cacheHash })
  );
}

export function fetchCompanionData(modelDefn: string) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.fitViewCacheCheck(modelDefn),
    requestCompanionData,
    receiveCompanionCacheHash,
    receiveCompanionCachedData,
    receiveCompanionLiveData
  );
}

export function fetchMassEditData(modelDefn: string) {
  return fetchListData(modelDefn, requestMassEditData, receiveMassEditData);
}

export interface SubmitPayloadParams {
  memberId: string;
}

export function submitPayload(payload: GranularEditPayloadItem[], shouldRefresh: boolean) {
  return async (dispatch: AppThunkDispatch) => {
    if (shouldRefresh) {
      // show loading overlay for view since update may take a while to complete, then refetch view data
      // fetchOverTimeData handles hiding loading overlay
      dispatch(requestOverTimeData());
      await serviceContainer.pivotService.granularEditSubmitData(payload);
      dispatch(refreshOvertimeData());
    }

    await serviceContainer.pivotService.granularEditSubmitData(payload);
  };
}

export default pricingOverTimeReducer.reducer;
