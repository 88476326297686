import { z } from 'zod';

export const Perspective = z.string();

export const ProplessComponents = z.union([
  z.literal('Reporting'),
  z.literal('ConfigEditor'),
  // MFP components
  z.literal('MfpReporting'),
  z.literal('MfpMassActualize'),
  z.literal('MfpMassCopy'),
]);

export const CartItemType = z.union([z.literal('similar'), z.literal('existing')]);
