import { SelectedWeekRange } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';

export function getWeeksFitView(range: SelectedWeekRange | undefined, isAssortment: boolean | undefined) {
  if (range) {
    if (!!isAssortment) {
      return {
        salesStart: range.from,
        salesEnd: range.to,
      };
    } else {
      return {
        historyStart: range.from,
        historyEnd: range.to,
      };
    }
  }

  return {};
}
