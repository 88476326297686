import { update } from 'src/services/lenses/Lenses.actions';
import { AppState, AppThunkDispatch } from 'src/store';
import { omitBy, cloneDeep } from 'lodash';
import fp from 'lodash/fp';
import { Lens } from 'monocle-ts';
import { makeCartService } from 'src/pages/AssortmentCart/AssortmentCart.service';
import { AssortmentAddSlice } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import serviceContainer from 'src/ServiceContainer';
import { STYLE_ID, IMG_URI } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { isNil } from 'lodash/fp';
import { watermarkBlob } from 'src/utils/Images/WatermarkImage';
import { validateStyleName, getStyleColorsWithAttributes } from '../StyleEdit/StyleEdit.client';
import { createId } from '@paralleldrive/cuid2';
import { getCroppedImgFromString } from 'src/components/ImageCropper/ImageCropper/Cropper.utils';
import { assortmentCartLens, assortmentCartItemsLens, assortmentAddStylesLens } from 'src/services/lenses/lenses';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { AssortmentCartSlice } from 'src/pages/AssortmentCart/AssortmentCart.slice';
import { resolvePath } from 'src/cdn';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { receiveError } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
export const noImage = resolvePath(noImagePath);

export function addSelectedItemsToCart(cartItemType?: 'similar' | 'existing', level?: string) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    if (isNil(cartItemType) || isNil(level)) {
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          name: ConfDefnComponentType.configurableGrid,
          message: `This component is not properly configured for adding items to cart. Missing 'cartItemType' and 'level'.`,
        })
      );
      return;
    }

    const state = getState();
    const cartService = makeCartService(assortmentCartItemsLens);
    const selectionService = makeCartService(
      assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('selectedItemsForCart'))
    );

    // update cart count with what is in addStyles slice
    const cartCount = state.pages.assortmentBuild.addStyles.selectedItemsForCart.length;
    const cartItemsToAdd = assortmentCartLens.compose(Lens.fromProp<AssortmentCartSlice>()('cartItemsToAddCount'));
    dispatch(update(cartItemsToAdd.asSetter().set(cartCount), 'Items to Add Count Updated'));

    const { selectedItemsForCart: selectionItems } = assortmentAddStylesLens
      .compose(Lens.fromProps<AssortmentAddSlice>()(['selectedItemsForCart']))
      .get(state);

    if (selectionItems.length <= 0) {
      return;
    }

    const groupedSelections = fp.groupBy(STYLE_ID, fp.cloneDeep(selectionItems));
    // TODO: validate this is grabbing scope values from correct state
    const currentContext = getSectionContext();
    const isHindsighting = IS_HINDSIGHTING(currentContext);
    const validMembersListData = isHindsighting ? 'HistoryStyleDependents' : 'AssortmentStyleDependents';
    const scopeSelections = state.scope.selections;
    let extraParams = {};
    if (isNil(scopeSelections.startSales) && isNil(scopeSelections.endSales)) {
      extraParams = {
        historyStart: scopeSelections.historyStart,
        historyEnd: scopeSelections.historyEnd,
      };
    } else {
      extraParams = {
        salesStart: scopeSelections.startSales,
        salesEnd: scopeSelections.endSales,
      };
    }

    let allChoices: BasicPivotItem[];
    let styleAssortmentInfo: BasicPivotItem[] = [];
    await Promise.all([
      (async () => {
        allChoices = (
          await serviceContainer.pivotService.listData(validMembersListData, AppType.Assortment, {
            topMembers: Object.keys(groupedSelections)
              .filter(fp.isString)
              .join(','),
            ...extraParams,
            nestData: false,
            sortBy: 'slsu',
          })
        ).flat;
      })(),
      (async () => {
        if (cartItemType === 'existing') {
          const topMembers = Object.keys(groupedSelections)
            .filter(fp.isString)
            .join(',');
          styleAssortmentInfo = await getStyleColorsWithAttributes(topMembers, true);
        }
      })(),
    ]);

    const finalStyleSelections = await Promise.all(
      fp.map(async (item: BasicPivotItem) => {
        item.type = cartItemType;
        item.name = item['member:style:name'];
        item.description = item['member:style:description'];
        item.id = item[STYLE_ID];
        item._id = createId();
        item.valid = true;

        if (cartItemType === 'similar') {
          item.name = `S5_${item['member:style:name']}`;
          item.valid = await validateStyleName(item.name);
        }

        if (cartItemType === 'existing') {
          const chosenStyles = styleAssortmentInfo.filter((style) => {
            return style[STYLE_ID] === item.id;
          });
          if (chosenStyles.length > 0) {
            item.assortmentChildren = chosenStyles[0].children;
          }
        }

        if (cartItemType === 'similar' || item[IMG_URI] === '') {
          const scaledImage = await getCroppedImgFromString(item[IMG_URI]);
          const watermarkedImage = await watermarkBlob(scaledImage).catch((_err) => noImage);
          if (watermarkedImage) {
            item[IMG_URI] = watermarkedImage.replace(/generation=[0-9]*&/, '');
            item.isWatermarked = true;
          }
        }

        const styleChoices = await Promise.all(
          allChoices
            .filter((unfilteredChocie) => {
              return unfilteredChocie[STYLE_ID] === item.id;
            })
            .map(async (styleChoice) => {
              const final = omitBy(styleChoice, (_v, k) => {
                if (['dimension', 'shortestPathToRoot', 'ordered', 'index'].indexOf(k) >= 0) {
                  return true;
                }
                return false;
              }) as BasicPivotItem;
              final.type = cartItemType;
              if (cartItemType === 'similar') {
                const scaledImage = await getCroppedImgFromString(final[IMG_URI]);
                const watermarkedImage = await watermarkBlob(scaledImage).catch(() => noImage);
                if (watermarkedImage) {
                  final[IMG_URI] = watermarkedImage.replace(/generation=[0-9]*&/, '');
                  final.isWatermarked = true;
                }
              }
              return final;
            })
        );

        if (level === 'stylecolors') {
          const idsToAdd = fp.map('id', fp.get(item.id, groupedSelections));
          item.children = styleChoices.filter((choice) => {
            return idsToAdd.indexOf(choice.id) >= 0;
          });
        } else {
          item.children = styleChoices;
        }

        item.currentChildren = cloneDeep(styleChoices);
        return item;
      }, fp.uniqBy(STYLE_ID, selectionItems))
    );

    // add the selection to the cart
    finalStyleSelections.forEach((style) => {
      dispatch(
        update((s: AppState): AppState => {
          return cartService.concatChildToItem(
            // @ts-ignore
            (style as unknown) as BasicPivotItem,
            ((style as unknown) as BasicPivotItem).children,
            cartItemType || 'existing',
            s
          );
        }, `Adding style and stylecolors to cart.`)
      );
    });

    // And finally remove everything from the selection, since it's in the cart now
    dispatch(update(selectionService.removeAllItemsFromCart, 'Clear Select Styles cart.'));
    dispatch(update(cartItemsToAdd.set(0), 'Clear Item Count in Add'));
  };
}
