import type { ICellRendererParams } from '@ag-grid-community/core';
import Comments from 'src/components/Mfp/Comments/Comments';
import { MacroSummaryDetailRenderer } from 'src/components/MacroSummaryModal/MacroSummaryDetail';
import React from 'react';

export const DETAIL_COMMENTS = 'comments';
export const DETAIL_SUMMARY = 'summary';
const DETAIL_NONE = 'none';
type DetailComponentTypes = typeof DETAIL_COMMENTS | typeof DETAIL_SUMMARY | typeof DETAIL_NONE;
interface MasterDetailSwitchRendererParams extends ICellRendererParams {
  data: {
    detailType: DetailComponentTypes
  }
}
/**
 * MasterDetailSwitch is the component that decides which detail component to render
 * It uses a magical string that is attached to the detail node by the cell renderer called
 * detailType that isn't documented anywhere but here.
 *
 * The cellRenderer is required to set it in the onClick that opens the detail renderer
 *
 * @param props props with a magic string attached to params.data.detailType
 * @returns JSX.Element the component switched to
 */
const MasterDetailSwitch = (props: MasterDetailSwitchRendererParams) => {
  return (
    <div>
      {(() => {
        switch (props.data.detailType) {
          case DETAIL_NONE:
            return <div />;
          case DETAIL_SUMMARY:
            return <MacroSummaryDetailRenderer {...props} />;
          // This is currently unused by the CommentsCellRenderer,
          // but left here in case we ever need to switch the master detail
          case DETAIL_COMMENTS:
            return <Comments />;
          default:
            return <div />;
        }
      })()}
    </div>
  );
};

export default MasterDetailSwitch;
