import { connect } from 'react-redux';

import container from 'src/ServiceContainer';
import {
  ParameterTogglesOwnProps,
  ParameterTogglesConfig,
} from 'src/pages/Allocation/ParameterToggles/ParameterToggles.types';
import { allocationParameterTogglesLens, scopeLens } from 'src/services/lenses/lenses';
import {
  requestParameterTogglesConfig,
  receiveParameterTogglesConfig,
} from 'src/pages/Allocation/ParameterToggles/ParemeterToggles.slice';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import ParameterToggles from 'src/pages/Allocation/ParameterToggles/ParameterToggles';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { AppState, AppThunkDispatch } from 'src/store';
import { isNil, isEmpty } from 'lodash';
import { getFloorsetData, getSelectedFloorset } from 'src/components/ConfigurableGrid/ConfigurableGrid.selectors';
import { getTopMemberDropdownProps } from './ParameterToggles.selectors';
import { fetchFloorsetData, setFloorsetSelection } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';

function mapStateToProps(state: AppState, _ownProps: ParameterTogglesOwnProps) {
  const scope = scopeLens.get(state);
  const parameterToggles = allocationParameterTogglesLens.get(state);
  const { isConfigLoading, isDataLoading, viewDefn /* data */ } = parameterToggles;

  let hasTopMemberDropdown = false;
  if (!isNil(viewDefn)) {
    hasTopMemberDropdown = !isNil(viewDefn.subheaderDropdowns?.topMember);
  }
  const topMemberDropdown = getFloorsetData(state);
  const loadingDropdown = hasTopMemberDropdown && isEmpty(topMemberDropdown);
  const topMemberDropdownProps = getTopMemberDropdownProps(state);
  let selectedTopMember: string | null = null;
  if (hasTopMemberDropdown && !loadingDropdown) {
    selectedTopMember = getSelectedFloorset(state) ?? topMemberDropdown[0].id;
  }

  return {
    isLoading: isConfigLoading || isDataLoading || loadingDropdown,
    config: viewDefn,
    scopeParams: {
      productMember: scope.productMember || '',
      locationMember: scope.locationMember || '',
    },
    topMemberDropdownProps,
    selectedTopMember,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: ParameterTogglesOwnProps) {
  return {
    async onShowView() {
      dispatch(requestParameterTogglesConfig());
      const config = await container.tenantConfigClient.getTenantViewDefn<ParameterTogglesConfig>({
        defnId: ownProps.defns.view[0],
        appName: ASSORTMENT,
      });
      if (config.subheaderDropdowns?.topMember) {
        dispatch(fetchFloorsetData(config.subheaderDropdowns.topMember.dataApi));
      }
      dispatch(receiveParameterTogglesConfig(config));
    },
    onUpdateTopMember(selection: string) {
      dispatch(setFloorsetSelection(selection));
    },
    onSubmitPlan(defnId: string, data: Record<string, boolean | string> | null) {
      dispatch(updateAssortmentPlan('Worklist', { defnId, data }));
    },
  };
}
export interface ParameterTogglesDispatchProps extends ReturnType<typeof dispatchToProps> { }
export interface ParameterTogglesValueProps extends ReturnType<typeof mapStateToProps> { }

export default connect(mapStateToProps, dispatchToProps)(makeScopeSensitive(ParameterToggles));
