import { TargetRequest, TargetSettingReduxSlice } from './TargetSetting.types';

export function createEmptySlice(): TargetSettingReduxSlice {
  return {
    seedValue: 100000,
    rowData: [],
    viewConf: null,
    seedDataIndex: 'slsr',
    id: '',
    reconcileViewConf: null,
    targetSetup: {} as TargetRequest,
  };
}

export function targetSettingReducer(
  state: TargetSettingReduxSlice = createEmptySlice(),
  _action: unknown
): TargetSettingReduxSlice {
  return state;
}
