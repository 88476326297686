import { connect } from 'react-redux';
import { changeScope } from '../AssortmentScopeSelector/AssortmentScopeSelector.slice';
import Headerbar from 'src/components/Headerbar/Headerbar';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import selectAndProjectState from './Headerbar.selector';
import {
  flushSelectionOverridesStarted,
  receiveFilterState,
  isLoading,
  updateFilterSelectionsFromServer,
} from 'src/components/FilterPanel/FilterPanel.slice';
import serviceContainer from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { flatMap } from 'lodash';
import { preSetActivePage, setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { cleanUp, onShowQuickReconcile } from '../Reconcilation/QuickRecapView/QuickReconcile.slice';
import {
  getActiveContextString,
  getActivePerspective,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection.selectors';

export function getFilterOptions() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const activePerspective = getActivePerspective(state);
    if (!activePerspective) {
      throw new Error('Perspective not found');
    }

    const appName = activePerspective.appType;
    const section = getActiveContextString(state);

    dispatch(isLoading(true));
    serviceContainer.filterClient.getFullFilters({ section, appName }).then((serverFilters) => {
      const refreshResult = serverFilters;
      dispatch(receiveFilterState({ filters: refreshResult.filters, updatedAt: Date.now() }));
    });
  };
}

export function refreshFilterSelections() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    dispatch(flushSelectionOverridesStarted());

    const state = getState();
    const activePerspective = getActivePerspective(state);
    if (!activePerspective) {
      throw new Error('Perspective not found');
    }

    const appName = activePerspective.appType;
    serviceContainer.filterClient.getOnlyFilterSelections(appName).then((serverFilterSelections) => {
      // this is where filter selections can be initially loaded, when the panel
      // is opened and no filters happen to be present for some reason
      // dispatch(receiveFilterState(data.filters[filterKey], 0));
      const selectionIdsToFilterSelections = flatMap(serverFilterSelections, (f) => {
        return flatMap(f.filterSet, (fs) => {
          return {
            filterDefnId: f.filterId,
            id: fs,
            value: true,
          };
        });
      });
      dispatch(updateFilterSelectionsFromServer(selectionIdsToFilterSelections));
    });
  };
}

function dispatchToProps(dispatch: AppThunkDispatch) {
  return {
    onToggleScopeSelector() {
      dispatch(changeScope());
    },
    onOpenFilters() {
      dispatch(setRightContainerPayload({ type: RightContainerPayloadType.Filter }));
    },
    onOpenQuickReconcile() {
      dispatch(onShowQuickReconcile());
    },
    onCloseQuickReconcile() {
      // FIXME: find out if we can do away with cleaning up the slice on close
      // if so, then can remove this and open func, moving the open state to `ReconcileLink`
      dispatch(cleanUp());
    },
    onOpenPlanQueue() {
      dispatch(setRightContainerPayload({ type: RightContainerPayloadType.PlanQueue, id: '' }));
    },
    setActivePage(data: string) {
      dispatch(preSetActivePage());
      dispatch(setActivePage(data));
    },
  };
}
export interface DispatchProps extends ReturnType<typeof dispatchToProps> {}

export default connect(selectAndProjectState, dispatchToProps)(makePrintSensitive(Headerbar));
