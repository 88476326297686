import Modal from '@trendmicro/react-modal';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownItemProps, DropdownProps, Ref, Segment } from 'semantic-ui-react';
import { AppState, AppThunkDispatch } from 'src/store';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { isEmpty, isNil } from 'lodash';
import { requestAddPrivateVersion } from 'src/state/scope/Scope.actions';
import { toast } from 'react-toastify';
import { getImportPrivateCommands, hasCommands } from 'src/state/scope/codecs/Commands.utils';
import { useFocusDropdown, useVersionState } from 'src/utils/Component/hooks/PlanManagement.hooks';

export type VersionModalBodyComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof dispatchToProps> & {
    loading: boolean;
    onCancel?: () => void;
    onSubmit?: (name?: string) => void;
    text?: string;
    smartSave?: boolean; // this is used only for smartsave.tsx to bypass the redux save
  };

const mapStateToProps = (state: AppState) => {
  const readyScope = getScopeReadyData(state.mfpScope);
  const { settings } = state;

  if (!readyScope || isEmpty(readyScope.commands)) {
    return;
  }

  // map it twice, as hasCommands doesn't return the correct type
  // filter so that we don't get empty sets of commands
  const commands = readyScope.commands
    .map(getImportPrivateCommands)
    .filter(hasCommands)
    .map(getImportPrivateCommands);

  return {
    commands,
    scope: readyScope,
    pendingWrites: readyScope.pendingWrites > 0,
    entries: settings.entriesByKey,
  };
};

const dispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedRequestAddPrivateVersion: (version: { versionName: string; smartSave: boolean }) => {
      return dispatch(requestAddPrivateVersion(version));
    },
  };
};

const SaveVersion = (props: VersionModalBodyComponentProps) => {
  const {
    onCancel,
    onSubmit,
    commands,
    dispatchedRequestAddPrivateVersion,
    text = 'WP',
    smartSave = false,
    pendingWrites,
    entries,
  } = props;

  const ddRef = useFocusDropdown();
  const loading = isNil(commands);
  const [mutationPending, setMutationPending] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined);
  const [versionOptions, setVersionOptions] = useVersionState(commands, entries);

  const handleSubmit = useCallback(
    async (options: DropdownItemProps[] | undefined, selectedVersion: string | undefined) => {
      if (selectedVersion && onSubmit && options && commands) {
        const verToSubmit = options.find((o) => o.value === selectedVersion);
        setMutationPending(true);
        if (!verToSubmit || !verToSubmit.text) {
          setMutationPending(false);
          return;
        }
        const addVersionPromise = await dispatchedRequestAddPrivateVersion({
          versionName: verToSubmit.text.toString(),
          smartSave,
        });
        if (addVersionPromise.type === requestAddPrivateVersion.fulfilled.type) {
          onSubmit(); // only close the modal on success
        } else if (addVersionPromise.type === requestAddPrivateVersion.rejected.type) {
          toast.error('An error occured submitting your version', {
            position: toast.POSITION.TOP_LEFT,
          });
        }
        setMutationPending(false);
      }
    },
    [commands, dispatchedRequestAddPrivateVersion, onSubmit, smartSave]
  );

  const handleOnSave = useCallback(() => {
    // TODO: modal to confirm if overwriting an existing version?
    handleSubmit(versionOptions, selectedVersion);
  }, [handleSubmit, versionOptions, selectedVersion]);

  const handleOnAdd = useCallback(
    (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      const { value } = data;
      if (typeof value !== 'string' || !commands || !versionOptions) {
        return;
      }

      const newOptions = [...versionOptions, { text: value, value: value }];
      setSelectedVersion(value);
      setVersionOptions(newOptions);
    },
    [commands, setVersionOptions, versionOptions]
  );

  const handleOnChange = useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      if (data.value && typeof data.value === 'string') {
        setSelectedVersion(data.value);
      }
    },
    [setSelectedVersion]
  );
  const handleEnterPress = useCallback(
    (evt: React.KeyboardEvent<HTMLElement>) => {
      if (evt.key === 'Enter') {
        handleSubmit(versionOptions, selectedVersion);
      }
    },
    [handleSubmit, selectedVersion, versionOptions]
  );

  return (
    <React.Fragment>
      <Modal.Body>
        <div data-qa="version-save-container">
          <Segment>
            {/* eslint-disable-next-line max-len */}
            Enter a name to save your {text} as, or select an existing name to overwrite that version with your {text}
          </Segment>
          <Ref innerRef={ddRef}>
            <Dropdown
              fluid={true}
              loading={loading}
              search={true}
              selection={true}
              allowAdditions={true}
              data-qa="save-version-dropdown"
              options={versionOptions}
              onAddItem={handleOnAdd}
              onChange={handleOnChange}
              value={selectedVersion}
              onKeyPress={handleEnterPress}
            ></Dropdown>
          </Ref>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button content="Close" onClick={onCancel} />
        <Button
          content="Save"
          loading={loading || mutationPending || pendingWrites}
          disabled={pendingWrites}
          className="save-version-modal-button"
          data-qa="version-save-btn-save"
          onClick={handleOnSave}
          onKeyPress={handleEnterPress}
        />
      </Modal.Footer>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, dispatchToProps)(SaveVersion);
