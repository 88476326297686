export class ImageCache {
  loaded: boolean;
  image: HTMLImageElement;
  loader: ImageLoader;
  imgSrc: string;
  noImageUrl: string;

  constructor(imgSrc: string, loader: ImageLoader, noImageUrl: string) {
    this.image = new Image();
    this.loaded = false;
    this.loader = loader;
    this.image.onload = this.onLoad.bind(this);
    this.image.onerror = this.onError.bind(this);
    this.image.src = imgSrc;
    this.noImageUrl = noImageUrl;
    this.imgSrc = noImageUrl;
  }

  onLoad() {
    this.loaded = true;
    this.imgSrc = this.image.src;
    this.loader.onLoad(this.image.src, this.imgSrc);
  }
  onError() {
    this.loaded = true;
    this.imgSrc = this.noImageUrl;
    this.loader.onLoad(this.image.src, this.imgSrc);
  }
  getImageSrc(): string {
    return this.imgSrc;
  }
}

export class ImageLoader {
  imgMap: Map<string, ImageCache>;
  noImageUrl: string;
  callback: (actualImage: string, visibleImage?: string) => void;

  constructor(callback: (actualImage: string) => void, noImageUrl: string) {
    this.imgMap = new Map<string, ImageCache>();
    this.callback = callback;
    this.noImageUrl = noImageUrl;
  }

  getImageSrc(key: string) {
    let imgCache = this.imgMap.get(key);
    if (!imgCache) {
      imgCache = new ImageCache(key, this, this.noImageUrl);
      this.imgMap.set(key, imgCache);
    }
    return imgCache.getImageSrc();
  }

  add(key: string) {
    if (!this.imgMap.has(key)) {
      const imgCache = new ImageCache(key, this, this.noImageUrl);
      this.imgMap.set(key, imgCache);
    }
  }

  delete(key: string) {
    this.imgMap.delete(key);
  }

  has(key: string) {
    return this.imgMap.has(key);
  }

  loaded() {
    const loaded = new Set<string>();
    for (const cache of this.imgMap.values()) {
      loaded.add(cache.getImageSrc());
    }
    return loaded;
  }

  onLoad(actualImage: string, visibleImage: string) {
    if (typeof this.callback === 'function') {
      this.callback.apply(this, [actualImage, visibleImage]);
    }
  }
}
