import React, { forwardRef } from 'react';
import { Badge, Icon, Link, Tooltip, withStyles, createStyles, TooltipProps } from '@material-ui/core';
import { classes } from 'typestyle';
import { px } from 'csx';
import styles from '../Headerbar.styles';

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      top: px(4),
      right: px(-2),
    },
    root: {
      // pass values from `containerStyle` in IconLink to StyledBadge
      // currently only used by the PlanLink component to limit size allocated when refresh available
      maxWidth: (innerprops: any) => innerprops.style.maxWidth,
    },
  })
)(Badge);

interface IconLinkProps {
  id: string;
  text: string;
  iconClass: string;
  iconStyle?: React.CSSProperties;
  linkClass?: string;
  linkStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  showBadge?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

export const IconLink = forwardRef<null, IconLinkProps>(function IconLink(props, ref) {
  const {
    id,
    text,
    iconClass,
    iconStyle,
    linkClass,
    linkStyle,
    containerStyle,
    showBadge = false,
    onClick,
    onMouseOver,
    onMouseLeave,
    tooltipText = '',
    tooltipPlacement,
  } = props;

  // TODO: want to toggle parent popover on focus/blur
  return (
    <Tooltip title={tooltipText} arrow={true} placement={tooltipPlacement}>
      <StyledBadge
        color="secondary"
        variant="dot"
        invisible={!showBadge}
        style={{ ...containerStyle }}
        overlap="rectangular"
      >
        <Link
          id={id}
          component="button"
          className={classes(styles.iconLink, linkClass)}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          data-qa="IconLink"
          style={{ ...linkStyle }}
          ref={ref}
        >
          <Icon
            className={`${iconClass} ${styles.iconRightSpace}`}
            component="i"
            style={{ fontSize: 'small', ...iconStyle }}
          />
          <span>{text}</span>
        </Link>
      </StyledBadge>
    </Tooltip>
  );
});
