import { AnyAction as BaseAction } from 'redux';
import { toast } from 'react-toastify';
import { isNil } from 'lodash';
import Axios from 'src/services/axios';
import { AppState, AppThunkDispatch } from 'src/store';
import { update } from 'src/services/lenses/Lenses.actions';
import { startPlan } from 'src/pages/AssortmentBuild/Planning.slice';
import { planTrackerLens } from 'src/services/lenses/lenses';
import { FabTypePlanning } from 'src/services/configuration/codecs/confdefn';
import { getUrl } from './StyleEdit.utils';
import service from 'src/ServiceContainer';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

export function updateAssortmentPlan(
  updateType: 'PlanQueue' | 'Worklist',
  options?: { data: Record<string, boolean | string> | null; defnId: string }
) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    dispatch(startPlan());

    const planEndpoint = updateType === 'PlanQueue' ? 'plan' : 'planWorklist';
    const defnId = options?.defnId || 'FlowSheetPlan';
    const data = options?.data || null;
    const planningFab = getState().appConfig.tenantConfig.fabTypes.planning as FabTypePlanning;
    const dataApi = planningFab.dataApi;

    if (isNil(dataApi)) {
      // legacy path
      await Axios.post(`/api/assortment/${planEndpoint}`, data, {
        params: {
          appName: 'Assortment',
          defnId,
        },
      }).catch(() => {
        dispatch(
          update(
            planTrackerLens.modify((pTrack) => {
              return {
                ...pTrack,
                assortmentPlanInProgress: false,
                shouldCheckAssortment: false,
                askForRefresh: false,
              };
            }),
            'PLAN_ERRORED'
          )
        );
      });
    } else if (dataApi.isListData) {
      // listData request
      const finalDefnId = dataApi.defnId || defnId;
      await service.pivotService.listData(finalDefnId, AppType.Assortment, dataApi.params).catch((e) => {
        toast.error(`An error occured submitting your plan`);
        service.loggingService.error('An error occured submitting a plan', e.stack);
      });
    } else {
      // non-listData request
      const dataUrl = getUrl(dataApi);
      await Axios.get(dataUrl);
    }
  };
}
