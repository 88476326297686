import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isNil, get } from 'lodash';

import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { ASSORTMENT, ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { TreePivot } from 'src/worker/pivotWorker.types';

import Subheader from 'src/components/Subheader/Subheader.container';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FavoriteListItemStorage, FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';
import {
  setSelectedId,
  receiveTenantConfig as receiveStyleTenantConfig,
  requestTenantConfig as requestStyleTenantConfig,
  updateTenantConfig,
} from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime.slice';
import { CompanionOvertimeConfig } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView.types';
import { OvertimeView } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView';
import { StyleByLevelOvertimeDispatchProps } from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/StyleByLevelOvertime';
import { WRAPPER_SUBHEADER_HEIGHT } from 'src/pages/Worklist/Worklist.styles';
import { TabbedComponentWrapperOwnProps } from 'src/pages/Worklist/Worklist.types';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';

export function mapStateToPropsStyleOvertime(state: AppState, ownProps: TabbedComponentWrapperOwnProps) {
  const customHeader = getHeaderTextFromState(state, true);
  const viewState = state.pages.hindsighting.styleByLevelOvertime;
  const {
    viewDataState,
    viewDefnState,
    data,
    companionData,
    viewDefn,
    unmodifiedViewDefn,
    companionDefn,
    selectedCompanionId,
    configureSelections,
    defaultConfigureSelections,
    defaultCompanionSortField,
  } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);

  return {
    ...ownProps,
    configLoaded,
    dataLoaded,
    customHeader,
    subheaderState: state.subheader,
    data,
    companionData,
    viewDefn,
    companionDefn,
    unmodifiedViewDefn,
    selectedCompanionId,
    configureSelections,
    defaultConfigureSelections,
    defaultCompanionSortField,
    aggBys: ['style', 'stylecolor'],
  };
}

export function dispatchToPropsStyleOvertime(
  dispatch: AppThunkDispatch,
  ownProps: TabbedComponentWrapperOwnProps
): StyleByLevelOvertimeDispatchProps {
  const { viewDefns, selectedItemId } = ownProps;
  const dispatchHandlers = {
    async onShowView() {
      dispatch(requestStyleTenantConfig());
      const configResponse = await container.tenantConfigClient.getTenantViewDefnsWithFavorites<
        CompanionOvertimeConfig
      >({
        defnIds: viewDefns,
        appName: ASSORTMENT,
      });
      const unmodifiedViewDefn: CompanionOvertimeConfig = configResponse[0];
      const favoritesList = (configResponse as any)[viewDefns.length];

      const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
        viewDefns[0],
        (favoritesList as unknown) as FavoriteResponseItem[],
        dispatch,
        (unmodifiedViewDefn as unknown) as TenantConfigViewData
      );
      const configWithFavorites = isNil(localConfig)
        ? configResponse[0]
        : ((localConfig.config as unknown) as CompanionOvertimeConfig);

      dispatch(
        receiveStyleTenantConfig({
          viewDefn: configWithFavorites,
          unmodifiedViewDefn,
        })
      );
      // calling this triggers the epic to fetchOvertimeData, bypassing the epic was causing extra calls to be made
      dispatch(setSelectedId(selectedItemId));
    },
    onRefresh(_id: string) {
      // calling this triggers the epic to fetchOvertimeData
      dispatch(setSelectedId(selectedItemId));
    },
    onConfigUpdate(config: CompanionOvertimeConfig) {
      dispatch(updateTenantConfig(config));
    },
  };

  return dispatchHandlers;
}

type StyleOvertimeProps = ReturnType<typeof mapStateToPropsStyleOvertime>;
type StyleOvertimeGridWithSubheader = {
  subheaderSummary?: string;
  overtimeData?: TreePivot;
} & StyleOvertimeProps &
  StyleByLevelOvertimeDispatchProps;

const StyleOvertimeGridWithSubheader = ({ subheaderSummary, ...props }: StyleOvertimeGridWithSubheader) => {
  const {
    viewDefn,
    data,
    subheaderState,
    unmodifiedViewDefn,
    dataLoaded,
    aggBys,
    customHeader,
    selectedItemId,
    onRefresh,
    onShowView,
  } = props;

  useEffect(() => {
    onShowView();
  }, []);

  useEffect(() => {
    onRefresh('');
    // we only want to trigger this when the item changes, onRefresh is changed every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemId]);

  const onUpdateConfig = (config: TenantConfigViewData) => {
    props.onConfigUpdate && props.onConfigUpdate(config);
  };

  const viewConfigurator: ViewConfiguratorModalProps = {
    viewConfig: (viewDefn as unknown) as TenantConfigViewData,
    unmodifiedViewDefn: (unmodifiedViewDefn as unknown) as TenantConfigViewData,
    updateConfig: onUpdateConfig,
  };
  const filteredViewDefnCols = viewDefn.columns?.filter((col) => isNil(col.visible) || col.visible);
  const finalViewDefn = { ...viewDefn, columns: filteredViewDefnCols };

  return (
    <React.Fragment>
      <AutoSizer style={{ height: `100%`, width: '100%' }}>
        {({ height }) => {
          return (
            <React.Fragment>
              <Subheader
                title={''}
                viewConfigurator={viewConfigurator}
                showSearch={true}
                showFlowStatus={false}
                summary={subheaderSummary}
                errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
              />
              {/* Height needs to be explicit for grid.  */}
              <div style={{ height: height - WRAPPER_SUBHEADER_HEIGHT }}>
                <OvertimeView
                  loaded={dataLoaded}
                  viewConfig={finalViewDefn}
                  rowHeight={get(finalViewDefn, 'main.rowHeight', 30)}
                  aggBys={aggBys}
                  treeData={data || []}
                  search={subheaderState.search}
                  hasCompanion={false}
                  exportOptions={{
                    fileName: 'Style Color Over Time',
                    customHeader: customHeader,
                  }}
                />
              </div>
            </React.Fragment>
          );
        }}
      </AutoSizer>
    </React.Fragment>
  );
};

export const TabbedStyleOvertimeGrid = connect(
  mapStateToPropsStyleOvertime,
  dispatchToPropsStyleOvertime
  // @ts-ignore
)(makeScopeAndFilterSensitive(makePopoverSensitive(StyleOvertimeGridWithSubheader)));
