import { Pivot, SubmitPayload, OverTimeType } from 'src/worker/pivotWorker.types';
import { GridViewConfig } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.slice';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import serviceContainer from 'src/ServiceContainer';
import { AppThunkDispatch } from 'src/store';
import {
  cacheCheckFetchPivotData,
  fetchListData,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { CacheCheckResponse } from 'src/services/pivotService';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { FlowSheetGridDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';

// yeah this is gross
export interface FlowSheetViewDefns extends Omit<GridViewConfig, 'grid'> { grid: FlowSheetGridDefn }
export interface FlowSheetSlice {
  viewDefns: FlowSheetViewDefns;
  isConfigLoading: boolean;
  selectedFlowDataId?: string;
  massEditData?: Pivot;
  selectedItem: string;
  cacheHashCompanion: string | null;
  cacheHashOverTime: string | null;
  viewDataStateCompanion: ViewDataState;
  viewDataStateOverTime: ViewDataState;
  viewDataStateMassEdit: ViewDataState;
}

const initialState: FlowSheetSlice = {
  viewDefns: {
    grid: {} as FlowSheetGridDefn,
    unmodifiedViewDefn: {} as TenantConfigViewData,
    listSort: {} as TenantConfigViewData,
    list: {} as TenantConfigViewData,
    subheaderRollUp: {} as TenantConfigViewData,
  } as FlowSheetViewDefns,
  isConfigLoading: false,
  viewDataStateCompanion: ViewDataState.idle,
  viewDataStateOverTime: ViewDataState.idle,
  viewDataStateMassEdit: ViewDataState.idle,
  cacheHashCompanion: null,
  cacheHashOverTime: null,
  selectedItem: '',
};

const flowSheetByStyleReducer = createSlice({
  name: 'FlowSheetByStyle',
  initialState,
  reducers: {
    requestFlowSheetByStyleConfig(state) {
      state.isConfigLoading = true;
    },
    receiveFlowSheetByStyleConfig(state, action: PayloadAction<FlowSheetViewDefns>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSomeFlowSheetConfig(state, action: PayloadAction<FlowSheetViewDefns>) {
      state.isConfigLoading = false;
      state.viewDefns.grid = {
        ...state.viewDefns.grid,
        ...action.payload,
      };
    },
    requestOverTimeData(state, action: PayloadAction<string>) {
      state.viewDataStateOverTime = ViewDataState.liveDataLoadingNoCache;
      state.selectedFlowDataId = action.payload;
    },
    receiveOverTimeCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashOverTime = action.payload;
    },
    receiveOverTimeCachedData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.liveDataLoadingFoundCache;
        state.selectedFlowDataId = action.payload.memberId;
      }
    },
    receiveOverTimeLiveData(state, action: PayloadAction<{ memberId: string; cacheHash: string }>) {
      if (action.payload.cacheHash === state.cacheHashOverTime) {
        state.viewDataStateOverTime = ViewDataState.liveDataReady;
        state.selectedFlowDataId = action.payload.memberId;
      }
    },
    requestMassEditData(state) {
      state.viewDataStateMassEdit = ViewDataState.liveDataLoadingNoCache;
    },
    receiveMassEditData(state, action: PayloadAction<Pivot>) {
      state.viewDataStateMassEdit = ViewDataState.liveDataReady;
      state.massEditData = action.payload;
    },
    requestCompanionData(state) {
      state.viewDataStateCompanion = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCompanionCacheHash(state, action: PayloadAction<string>) {
      state.cacheHashCompanion = action.payload;
    },
    receiveCompanionCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        state.viewDataStateCompanion = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    updateSelectedItem(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    receiveCompanionLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHashCompanion) {
        state.viewDataStateCompanion = ViewDataState.liveDataReady;
      }
    },
    refreshOvertimeData: () => {
      // nothing to do here, this action triggers refetch in epic
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestFlowSheetByStyleConfig,
  receiveFlowSheetByStyleConfig,
  requestOverTimeData,
  receiveOverTimeCacheHash,
  receiveOverTimeCachedData,
  receiveOverTimeLiveData,
  requestMassEditData,
  receiveMassEditData,
  requestCompanionData,
  receiveCompanionCacheHash,
  receiveCompanionCachedData,
  receiveCompanionLiveData,
  updateSelectedItem,
  refreshOvertimeData,
  receiveError,
  cleanUp,
  receiveSomeFlowSheetConfig,
} = flowSheetByStyleReducer.actions;

export function fetchCompanionData([modelDefn, massEditModelDefn]: string[]) {
  return async (dispatch: AppThunkDispatch): Promise<AnyAction | void> => {
    cacheCheckFetchPivotData(
      serviceContainer.pivotService.fitViewCacheCheck(modelDefn),
      requestCompanionData,
      receiveCompanionCacheHash,
      receiveCompanionCachedData,
      receiveCompanionLiveData
    )(dispatch);

    return dispatch(fetchListData(massEditModelDefn, requestMassEditData, receiveMassEditData));
  };
}

export function fetchMassEditData(massEditModelDefn: string) {
  return async (dispatch: AppThunkDispatch): Promise<AnyAction | void> => {
    return dispatch(fetchListData(massEditModelDefn, requestMassEditData, receiveMassEditData));
  };
}

export function fetchOverTimeData(memberId: string, dataApi?: ListDataApi, overrideDefnId?: string) {
  let otFetch: Promise<CacheCheckResponse>;
  // Fallback to old /flowsheet fetch when listdata not provided
  // (to prevent complication when upgrading)
  if (dataApi == null) {
    otFetch = serviceContainer.pivotService.getOverTimeData(memberId, OverTimeType.flowsheet, overrideDefnId);
  } else {
    otFetch = serviceContainer.pivotService.listDataCacheCheck(dataApi.defnId, {
      topMembers: memberId,
      aggBy: dataApi.params.aggBy || 'level:stylecolor',
      nestData: false,
    });
  }
  return cacheCheckFetchPivotData(
    otFetch,
    () => requestOverTimeData(memberId),
    receiveOverTimeCacheHash,
    (cacheHash: string) => receiveOverTimeCachedData({ memberId, cacheHash }),
    (cacheHash: string) => receiveOverTimeLiveData({ memberId, cacheHash })
  );
}

// TODO: see about combining this logic with pricing's submit payload
// - would allow this function to not only submit but also subsequently plan and/or refetch overtime data
export function submitPayload(payload: SubmitPayload) {
  return async () => {
    await serviceContainer.pivotService.submitFlowSheetPayload(payload);
  };
}
export function submitMassEditPayload(payload: GranularEditPayloadItem[]) {
  return async () => {
    await serviceContainer.pivotService.granularEditSubmitData(payload);
  };
}

export default flowSheetByStyleReducer.reducer;
