// tslint:disable:no-any
import * as _ from 'lodash';
import { isString } from 'lodash';

export interface ObjectDeserializer {
  deserialize(responseBody: string): Promise<any[]>;
}
const MAGIC_CHUNK_SIZE = 100;
export function split(input: string): Promise<readonly string[][]> {
  return Promise.resolve(input.split('\n')).then((parts) => _.chunk(parts, MAGIC_CHUNK_SIZE));
}

export interface ObjectChunk {
  readonly '@path': string;
}
function lowerify(parseable: any) {
  if (typeof parseable === 'object' && !Array.isArray(parseable)) {
    return Object.keys(parseable).reduce((acc, next) => {
      const key = next.toLocaleLowerCase();
      acc[key] = parseable[next];
      if (key === 'attribute:img:name' || key === 'attribute:img:id') {
        acc[key] = (acc[key] as string).replace(/generation=[0-9]*&/, '');
      }
      if (isString(acc[key])) {
        acc[key] = acc[key].trim();
      }
      return acc;
    }, {} as any);
  }
  return parseable;
}
export function parseChunks(input: readonly string[][]): Promise<readonly ObjectChunk[][]> {
  return input.reduce(
    (sequencer, current) =>
      sequencer.then((s) => {
        const parsed = current.map((v) => JSON.parse(v)).map(lowerify);

        return s.concat([parsed]);
      }),
    Promise.resolve([] as any[])
  );
}
const sym = /^\.([a-zA-Z_][a-zA-Z_0-9]*)/;
const ind = /^\[([0-9]+)\]/;
export class PathParseDriver {
  readonly input: string;
  readonly offset: number;
  readonly remaining: string;
  readonly parsed: readonly PathRef[];

  static create(input: string): PathParseDriver {
    return new PathParseDriver(input, 0, input, []);
  }

  static run(input: string): readonly PathRef[] {
    let state = PathParseDriver.create(input);
    while (!state.done()) {
      state = state.advance();
    }
    return state.parsed;
  }

  done(): boolean {
    return this.offset === this.input.length;
  }

  advance(): PathParseDriver {
    if (this.done()) {
      throw new Error('Illegal state. We are done');
    }
    const symTok = this.remaining.match(sym);

    if (symTok !== null) {
      const matchLength = symTok[0].length;
      const ref: PathRef = { type: 'prop', prop: symTok[1].toLocaleLowerCase() };
      return new PathParseDriver(this.input, this.offset + matchLength, this.remaining.substring(symTok[0].length), [
        ...this.parsed,
        ref,
      ]);
    }
    const idxTok = this.remaining.match(ind);
    if (idxTok != null) {
      const matchLength = idxTok[0].length;
      const ref: PathRef = { type: 'index', idx: parseInt(idxTok[1], 10) };
      return new PathParseDriver(
        this.input,
        this.offset + matchLength,
        this.remaining.substring(idxTok[0].length),
        this.parsed.concat([ref])
      );
    }
    throw new Error(`Invalid path input. Could not find a property or index at offset ${this.offset}`);
  }

  private constructor(input: string, offset: number, remaining: string, parsed: readonly PathRef[]) {
    this.input = input;
    this.offset = offset;
    this.remaining = remaining;
    this.remaining = input.substr(this.offset);
    this.parsed = parsed;
  }
}
// Construct a default value that can be indexed by nextRef
export function ensureValue(nextRef: PathRef, current: any): any {
  // TODO: Maybe type validation?
  if (nextRef.type === 'index') {
    return current || [];
  }
  return current || {};
}

export function writeChunk(path: readonly PathRef[], offset: number, value: any, target: any): any | null {
  if (path.length === offset) {
    return value;
  }
  const ref = path[offset];

  if (ref.type === 'prop') {
    if (offset === path.length - 1) {
      target[ref.prop] = value;
    } else {
      target[ref.prop] = ensureValue(path[offset + 1], target[ref.prop]);
      writeChunk(path, offset + 1, value, target[ref.prop] || {});
    }
  } else if (ref.type === 'index') {
    if (offset === path.length - 1) {
      target[ref.idx] = value;
    } else {
      target[ref.idx] = ensureValue(path[offset + 1], target[ref.idx]);
      writeChunk(path, offset + 1, value, target[ref.idx] || []);
    }
  }
  return null;
}
export function mergeChunks(input: readonly ObjectChunk[][]): Promise<any> {
  let output: any = {};
  // Write a chunk
  return input
    .reduce(
      (thunk, chunks) =>
        thunk.then(() =>
          Promise.resolve(
            chunks.forEach((chunk) => {
              const path = PathParseDriver.run(chunk['@path']);
              const result = writeChunk(path, 0, chunk, output);
              if (result !== null) {
                output = result;
              }
            })
          )
        ),
      Promise.resolve()
    )
    .then(() => output);
}

// Mutate a target object according to the given updates.
// Returns a value if the update command has an empty path (i.e. targets the root)
// Otherwise, returns null
export function unwrapChunks(v: { data: any }): any {
  return v.data;
}
export function makeObjectDeserializer(): ObjectDeserializer {
  return {
    async deserialize(responseBody: string): Promise<any> {
      return split(responseBody.trim())
        .then(parseChunks)
        .then(mergeChunks)
        .then(unwrapChunks);
    },
  };
}

export type PathRef = PropRef | IndexRef;

export interface Ref {
  readonly type: string;
}

export interface PropRef extends Ref {
  readonly type: 'prop';
  readonly prop: string;
}

export interface IndexRef extends Ref {
  readonly type: 'index';
  readonly idx: number;
}
