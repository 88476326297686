import React from 'react';

import styles from 'src/components/TopNavbar/TopNavbar.styles';

import { Button } from '@material-ui/core';

import BrandLogo from '../../images/S5Logo-White-NoInnovation-Centered.png';

export const Logout = (props: { message: string; onLogin: () => void }) => {
  return (
    <React.Fragment>
      <nav className={`bg-dark ${styles.mainNavBar}`}>
        <span className="logo-container">
          <img className="logo" src={BrandLogo} alt="" data-qa="TopNavHomeIcon" />
        </span>
      </nav>
      <div
        style={{ height: '500px' }}
        className={'logout d-flex flex-column justify-content-center align-items-center'}
      >
        <div className={'h3 m-5'}>{props.message}</div>
        <Button className={'m-4'} variant="contained" onClick={props.onLogin}>
          Log back in
        </Button>
      </div>
    </React.Fragment>
  );
};
