import { upperFirst, noop } from 'lodash';
import { BindAll } from 'lodash-decorators';
import React from 'react';
import { connect } from 'react-redux';
import { ServerScopeResponse } from '../../../state/scope/Scope.types';
import { DEFAULT_DIMENSIONS } from '../../../utils/Domain/Constants';
import LoadingMask from '../../LoadingMask/LoadingMask';
import ReseedPlanModal from '../Reseed/ReseedPlanModal';
import './_Scopebar.scss';
import EopToBopModal from './EopToBopModal';
import { mapDispatchToProps, mapStateToProps } from './MfpScopebar.container';
import {
  openImportQaSelector,
  openReseedQaSelector,
  openVersionQaSelector,
  openCopyQaSelector,
  ScopebarProps,
  ScopebarState,
  ScopebarValueProps,
  ScopebarDispatch,
  openSubmitQaSelector,
  openCommentsQaSelector,
} from './MfpScopebar.types';
import ScopeText from './MfpScopeText';
import { getMemberName } from './MfpScopeUtils';
import ScopebarOption from 'src/components/Mfp/MfpScopebarOption/ScopebarOption';
import SaveVersion from 'src/components/Mfp/SaveVersion/SaveVersion';
import ImportFromVersion from 'src/components/Mfp/ImportFromVersion/ImportFromVersion';
import { AppState } from 'src/store';
import ScopeStatusPopover from './MfpScopeStatusPopover/ScopeStatusPopover';
import { getPrimary } from 'src/components/Mfp/PivotConfigurator/utils';
import SubmitPlan from 'src/components/Mfp/SubmitPlan/SubmitPlan';
import { withRouter } from 'src/components/higherOrder/withRouter';

@BindAll()
export class MfpScopebar extends React.Component<ScopebarProps, ScopebarState> {
  private modalTimeout = 500;

  constructor(props: ScopebarProps) {
    super(props);

    this.state = {
      loading: false,
      isScopeVisible: false,
      scopeError: false,
    };
  }

  public getScopeText() {
    const displayText: [string | string[]] = [[]];
    const scopeConfig: ServerScopeResponse | undefined = this.props.scopeConfig;
    const labelDimension = this.props.labelDimensions!;
    if (scopeConfig && scopeConfig.scopeReady) {
      const members = scopeConfig.memberTrees;
      if (members) {
        DEFAULT_DIMENSIONS.forEach((key) => {
          if (members[key]) {
            const name = getMemberName(key, scopeConfig, this.props.settingsByKey);
            if (name && labelDimension) {
              const multiScope: string[] = [];
              const getName = `${name} -`;
              // @ts-ignore
              getPrimary(members[key]).forEach((member) => {
                // this will set string of labelDimenion[key] as a key of the object "member.v"
                const labelType = labelDimension[key] as keyof typeof member.v;
                members[key][0].data.length !== 0
                  ? multiScope.push(` ${member.v[labelType]}`)
                  : displayText.push(`${member.v[labelType]}`);
              });
              const text = getName + multiScope.join();
              displayText.push(text);
            }
          }
        });
      }
      if (scopeConfig.workflow) {
        displayText.push(upperFirst(scopeConfig.workflow));
      }
    } else {
      displayText.push('Please wait a moment while your scope is prepared');
    }

    if (!displayText.length) {
      displayText.push('No Scope Available');
    }

    if (this.props.moduleName) {
      const facetName = this.props.moduleName;
      displayText.push(facetName);
    }

    // this line remove the first empty array that I initial,
    //if I'm not initialize it will got the error :Source has 0 element(s) but target requires 1.
    displayText.shift();
    return displayText.join(' | ');
  }

  public onModalClose() {
    if (this.props.scopeId) {
      this.setState({
        isScopeVisible: false,
        loading: false,
      });
    }
  }

  public onInitializeCancel() {
    const { clearScope } = this.props;
    if (clearScope) {
      clearScope();
    }
    // TODO setActivePage to bubbles here or something else
    window.location.pathname = '/';
  }

  public render() {
    const { loading } = this.state;

    const { hasEditableRevision, updateScopeInfo, pendingWrites } = this.props;

    return (
      <div className="scopebar">
        <ScopeText content={this.getScopeText()} />
        <ul className="scope-options-list">
          <ScopebarOption
            dataQa={openSubmitQaSelector}
            text={'Submit'}
            itemClass={'submit-button'}
            iconClass={'fas fa-fw fa-paper-plane'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalDataQa: 'submit-plan-modal',
              modalTitle: 'Submit Plan',
              modalClassName: 'import-version-modal',
              modalBodyComponent: SubmitPlan,
              modalBodyComponentProps: {
                onItemChange: noop,
                onSubmit: noop,
                loading: loading,
              },
            }}
          />
          <ScopebarOption
            dataQa={openCopyQaSelector}
            text={'Copy EOP to BOP'}
            iconClass={'far fa-fw fa-copy'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalDataQa: undefined,
              modalTitle: 'Copy EOP to BOP',
              modalClassName: 'import-version-modal',
              modalBodyComponent: EopToBopModal,
              modalBodyComponentProps: {
                copyPeriod: undefined,
                copyVersionOptions: undefined,
                copyVersion: undefined,
                onItemChange: noop,
                onSubmit: undefined,
                loading: loading,
              },
            }}
          />
          <ScopebarOption
            dataQa={openVersionQaSelector}
            text={'Save As...'}
            iconClass={'far fa-fw fa-save'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalTitle: 'Save as Version',
              modalClassName: 'save-version-modal',
              modalDataQa: 'save-version-modal',
              modalBodyComponent: SaveVersion,
              modalBodyComponentProps: {
                loading: loading,
                onSubmit: noop,
                onItemChange: noop,
              },
            }}
          />
          <ScopebarOption
            dataQa={openImportQaSelector}
            text={'Import from Version'}
            iconClass={'far fa-fw fa-file-import'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalTitle: 'Import Version from',
              modalClassName: 'import-version-modal',
              modalDataQa: 'import-version-modal',
              modalBodyComponent: ImportFromVersion,
              modalBodyComponentProps: {
                loading,
                onSubmit: undefined,
                onItemChange: noop,
              },
            }}
          />
          <ScopebarOption
            dataQa={openReseedQaSelector}
            text={'Reseed'}
            iconClass={'fas fa-fw fa-sync'}
            isDisabled={!hasEditableRevision}
            optionModalProps={{
              modalTitle: 'Reseed Working Plan',
              modalClassName: 'import-version-modal',
              modalDataQa: 'reseed-modal',
              modalBodyComponent: ReseedPlanModal,
              modalBodyComponentProps: {
                onItemChange: noop,
                onSubmit: undefined,
                loading: loading,
              },
            }}
          />
          <ScopebarOption
            dataQa={openCommentsQaSelector}
            isDisabled={!hasEditableRevision}
            text={'Comment'}
            iconClass={'fal fa-fw fa-pencil'}
            itemClass={this.props.commentsOpen ? 'open' : 'closed'}
            optionModalProps={null}
            onClick={this.props.toggleRightContainer}
          />
          {/* Scope button is not a ScopebarOption because of all the additonal logic tracked in this component */}
          <ScopeStatusPopover
            scopeId={this.props.scopeId}
            onScopeClick={this.props.handleToggleMfpScopeSelector}
            scopeContexts={this.props.scopeContexts}
            pendingWrites={pendingWrites}
            labelDimension={this.props.labelDimensions}
            handleUpdateScopeInfo={(contextId, destinationPerspective) => {
              return updateScopeInfo(contextId, destinationPerspective);
            }}
            deleteScope={this.props.deleteScope}
          />
        </ul>
        <div className="scope-options">
          {this.props.scopeId && this.props.isFetchingScope && <LoadingMask coverAll={false} />}
        </div>
      </div>
    );
  }
}

export default connect<ScopebarValueProps, ScopebarDispatch, null, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MfpScopebar));
