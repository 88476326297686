import { FilterSelection } from 'src/common-ui/components/Filters/Filters';
import { Scope } from 'src/types/Scope';
import { isNil } from 'lodash';
import { Perspective } from 'src/services/configuration/codecs/bindings.types';

const SESSION_PERSPECTIVE = 'perspective';
const SESSION_PAGE = 'page';
const SESSION_TAB = 'tab';
const SESSION_SUBPAGE = 'subPage';
const SESSION_HISTORY_START = 'historyStart';
const SESSION_HISTORY_END = 'historyEnd';
const SESSION_ASST_START = 'asstStart';
const SESSION_ASST_END = 'asstEnd';
const SESSION_FLOORSET = 'floorset';
const SESSION_PRODUCT_LEVEL = 'productLevel';
const SESSION_PRODUCT_MEMBER = 'productMember';
const SESSION_LOCATION_LEVEL = 'locationLevel';
const SESSION_LOCATION_MEMBER = 'locationMember';
const SESSION_FILTERS = 'filters';

const SESSION_KEYS = [
  SESSION_PERSPECTIVE,
  SESSION_PAGE,
  SESSION_SUBPAGE,
  SESSION_TAB,
  SESSION_HISTORY_START,
  SESSION_HISTORY_END,
  SESSION_ASST_START,
  SESSION_ASST_END,
  SESSION_FLOORSET,
  SESSION_PRODUCT_LEVEL,
  SESSION_PRODUCT_MEMBER,
  SESSION_LOCATION_LEVEL,
  SESSION_LOCATION_MEMBER,
  SESSION_FILTERS,
];

export const getSessionFilters = () => {
  const filters = localStorage.getItem(SESSION_FILTERS);
  const filtersArray = filters ? filters.split(',') : [];
  const filterSelections: FilterSelection[] = [];
  filtersArray.forEach((selection) => {
    const index = selection.indexOf(':');
    const [filterDefnId, id] = [selection.slice(0, index), selection.slice(index + 1)];
    if (filterDefnId && id) {
      filterSelections.push({
        filterDefnId,
        id,
      });
    }
  });
  return filterSelections;
};

export const getSessionScope = () => {
  const productLevel = localStorage.getItem(SESSION_PRODUCT_LEVEL);
  const productMember = localStorage.getItem(SESSION_PRODUCT_MEMBER);
  const locationLevel = localStorage.getItem(SESSION_LOCATION_LEVEL);
  const locationMember = localStorage.getItem(SESSION_LOCATION_MEMBER);
  const historyEnd = localStorage.getItem(SESSION_HISTORY_END);
  const historyStart = localStorage.getItem(SESSION_HISTORY_START);
  const asstStart = localStorage.getItem(SESSION_ASST_START);
  const asstEnd = localStorage.getItem(SESSION_ASST_END);
  const floorset = localStorage.getItem(SESSION_FLOORSET);

  return {
    productLevel,
    productMember,
    locationLevel,
    locationMember,
    historyEnd,
    historyStart,
    asstStart,
    asstEnd,
    floorset,
  };
};

export const getSessionTab = () => {
  return localStorage.getItem(SESSION_TAB);
};

export const getSessionPage = () => {
  return localStorage.getItem(SESSION_PAGE);
};

export const getSessionSubPage = () => {
  return localStorage.getItem(SESSION_SUBPAGE);
};

export const getSessionPerspective = (): Perspective | null => {
  const maybePerspective = localStorage.getItem(SESSION_PERSPECTIVE);

  if (maybePerspective) {
    return maybePerspective;
  }
  return null;
};

export const getSessionAll = () => {
  return {
    ...getSessionScope(),
    filters: getSessionFilters(),
    tab: getSessionTab(),
    perspective: getSessionPerspective(),
    page: getSessionPage(),
  };
};

export const setSessionPerspective = (perspective: Perspective) => {
  localStorage.setItem(SESSION_PERSPECTIVE, perspective);
};

export const setSessionPage = (page: string) => {
  localStorage.setItem(SESSION_PAGE, page);
};

export const setSessionSubPage = (subPage: string | undefined) => {
  // SPIKE does setting this to '' break anything?
  localStorage.setItem(SESSION_SUBPAGE, subPage ? subPage : '');
};

export const setSessionTab = (tab: string) => {
  localStorage.setItem(SESSION_TAB, tab);
};

export const setSessionFilters = (filters: FilterSelection[]) => {
  // Convert selections to string for local storage
  const selectedFilters = filters.reduce((accum, current) => {
    return current.filterDefnId + ':' + current.id + ',' + accum;
  }, '');
  localStorage.setItem(SESSION_FILTERS, selectedFilters);
};

export const setSessionScope = (scope: Scope) => {
  scope.productLevel && localStorage.setItem(SESSION_PRODUCT_LEVEL, scope.productLevel);
  scope.productMember && localStorage.setItem(SESSION_PRODUCT_MEMBER, scope.productMember);
  scope.locationLevel && localStorage.setItem(SESSION_LOCATION_LEVEL, scope.locationLevel);
  scope.locationMember && localStorage.setItem(SESSION_LOCATION_MEMBER, scope.locationMember);
  scope.historyStart && localStorage.setItem(SESSION_HISTORY_START, scope.historyStart);
  scope.historyEnd && localStorage.setItem(SESSION_HISTORY_END, scope.historyEnd);
  scope.start && localStorage.setItem(SESSION_ASST_START, scope.start);
  scope.end && localStorage.setItem(SESSION_ASST_END, scope.end);
  scope.floorSet && localStorage.setItem(SESSION_FLOORSET, scope.floorSet);
};

export const removeSessionAll = () => {
  SESSION_KEYS.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const removeSessionScope = () => {
  const fullSessionScope = [
    SESSION_PRODUCT_LEVEL,
    SESSION_PRODUCT_MEMBER,
    SESSION_LOCATION_LEVEL,
    SESSION_LOCATION_MEMBER,
    SESSION_HISTORY_END,
    SESSION_HISTORY_START,
    SESSION_ASST_END,
    SESSION_ASST_START,
    SESSION_FLOORSET,
  ];
  fullSessionScope.forEach((item) => localStorage.removeItem(item));
};

export const isSessionScopeValid = () => {
  const neededScope = [
    SESSION_PRODUCT_LEVEL,
    SESSION_PRODUCT_MEMBER,
    SESSION_LOCATION_LEVEL,
    SESSION_LOCATION_MEMBER,
    SESSION_HISTORY_END,
    SESSION_HISTORY_START,
    SESSION_ASST_END,
    SESSION_ASST_START,
  ];
  return neededScope.every(
    (current) => !isNil(localStorage.getItem(current) && localStorage.getItem(current) !== 'undefined')
  );
};
