import { AxiosInstance } from 'axios';
import LoggingService from 'src/services/Logging.service';
import type { Comment, ProtoComment } from './Comments.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';

const getCommentsRoute = (siloId: string, planId?: PlanId) => `${API_BASE_URL}/silo/${siloId}/comments/${planId ? planId : ''}` as const;

export default class CommentsService {
  protected client: AxiosInstance;
  protected logger: LoggingService;
  private logError: (logMessage: string, error: unknown) => Error;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.logger = new LoggingService(this.client);
    this.logError = (logMessage: string, error: unknown) => {
      // remote logging happens here
      // bubble the error with message back up to the view layer to notify the user
      this.logger.error(logMessage, error);
      return new Error(logMessage);
    };
  }

  public addComment(siloId: string, planId: PlanId, newComment: ProtoComment) {
    const url = getCommentsRoute(siloId, planId);

    return this.client.post(url, newComment).then((_resp) => {
      // Note: this isn't returned because it just 204's with no body
      _resp;
    }).catch((err) => {
      throw this.logError('An error occured adding your comment', err);
    });
  }

  public getPlanComments(siloId: string, planId: PlanId) {
    const url = getCommentsRoute(siloId, planId);

    return this.client.get<Comment[]>(url).then((resp) => {
      return resp.data;
    }).catch((err) => {
      throw this.logError('An error occured adding your comment', err);
    });
  }

  public async updateComment(siloId: string, commentId: string) {
    // TODO: implement in backend
    const url = `${getCommentsRoute(siloId)}/comments/${commentId}`;

    try {
      return await this.client.put(url);
    } catch (err) {
      throw this.logError('An error occured updating your comment', err);
    }
  }

  public async deleteComment(siloId: string, commentId: string) {
    const url = `${getCommentsRoute(siloId)}${commentId}`;
    return this.client.delete(url).then((_resp) => {
      // Note: this isn't returned because it just 204's with no body
      _resp;
    }).catch((err) => {
      throw this.logError('An error occured deleting your comment', err);
    });
  }
}