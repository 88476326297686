import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Skeleton from '@material-ui/lab/Skeleton';

import { Overlay, GeoChart } from 'src/common-ui';
import { KeyFinancialsAccordion } from 'src/common-ui/components/Macros/KeyFinancial/KeyFinancialsAccordion';
import { ChoiceProductivityAccordion } from 'src/common-ui/components/Macros/ChoiceProductivity/ChoiceProductivityAccordion';

import { StateProjection } from './Summary.selectors';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import { summaryStyle } from './Summary.styles';
import Subheader from 'src/components/Subheader/Subheader.container';
import { MACRO_TRENDS_WARNING_MESSAGE } from 'src/pages/Hindsighting/MacroTrends/MacroTrends';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';

export interface DispatchProps {
  onShowView(): void;
  onRefetchData(): void;
}

interface MacroTrendAnalysisProps
  extends Pick<SummaryProps, 'dataLoaded' | 'trendAnalysis' | 'productMixAndTrend' | 'globalRegionVolAndTrend'> {}

const MacroTrendAnalysis = ({
  dataLoaded,
  trendAnalysis,
  productMixAndTrend,
  globalRegionVolAndTrend,
}: MacroTrendAnalysisProps) => {
  // if (true) {
  if (!dataLoaded) {
    return (
      <div className="macro-trend-analysis-items-container">
        <div className="col-4">
          <Skeleton className="trend-analysis-skeleton" variant="rect" animation="wave" />
        </div>
        <div className="col-4">
          <Skeleton className="trend-analysis-skeleton" variant="rect" animation="wave" />
        </div>
        <div className="col-4">
          <Skeleton className="trend-analysis-skeleton" variant="rect" animation="wave" />
        </div>
      </div>
    );
  }

  return (
    <div className="macro-trend-analysis-items-container">
      <div className="col-4">
        <HighchartsReact options={trendAnalysis} />
      </div>
      <div className="col-4">
        <HighchartsReact options={productMixAndTrend} />
      </div>
      <div className="col-4">
        <GeoChart {...globalRegionVolAndTrend} />
      </div>
    </div>
  );
};

interface SummaryProps extends StateProjection, DispatchProps {}

interface SummaryState {
  keyFinancialsExpanded: boolean;
  choiceProductivityExpanded: boolean;
  trendAnalysisExpanded: boolean;
}

export default class Summary extends React.Component<SummaryProps, SummaryState> {
  constructor(props: SummaryProps) {
    super(props);

    this.state = {
      keyFinancialsExpanded: true,
      choiceProductivityExpanded: true,
      trendAnalysisExpanded: true,
    };

    this.toggleKeyFinancials = this.toggleKeyFinancials.bind(this);
    this.toggleChoiceProductivity = this.toggleChoiceProductivity.bind(this);
    this.toggleMacroTrendAnalysis = this.toggleMacroTrendAnalysis.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: SummaryProps, _prevState: SummaryState) {
    if (!this.props.dataLoaded || !prevProps.dataLoaded) {
      return;
    }
    const { flowStatus, onRefetchData } = this.props;
    if (prevProps.flowStatus.join('') !== flowStatus.join('')) {
      onRefetchData();
    }
  }

  toggleKeyFinancials() {
    this.setState({
      keyFinancialsExpanded: !this.state.keyFinancialsExpanded,
    });
  }

  toggleChoiceProductivity() {
    this.setState({
      choiceProductivityExpanded: !this.state.choiceProductivityExpanded,
    });
  }

  toggleMacroTrendAnalysis() {
    this.setState({
      trendAnalysisExpanded: !this.state.trendAnalysisExpanded,
    });
  }

  render() {
    if (!this.props.configLoaded) {
      return <Overlay type="loading" visible={true} />;
    }

    const {
      title,
      dataLoaded,
      viewDataState,
      keyFinancials,
      choiceProductivity,
      trendAnalysis,
      productMixAndTrend,
      globalRegionVolAndTrend,
      showLookBackPeriod,
      showFlowStatus
    } = this.props;

    const { keyFinancialsExpanded, choiceProductivityExpanded, trendAnalysisExpanded } = this.state;
    const subheaderProps: SubheaderOwnProps = {
      title,
      showFlowStatus,
      showLookBackPeriod,
      errorCondition: MACRO_TRENDS_WARNING_MESSAGE,
      viewDataState,
    };

    return (
      <div className={summaryStyle(keyFinancialsExpanded, choiceProductivityExpanded, trendAnalysisExpanded)}>
        <Subheader {...subheaderProps} />
        <div className={'summary-container'}>
          <KeyFinancialsAccordion
            dataLoaded={dataLoaded}
            macros={keyFinancials}
            onToggleAccordion={this.toggleKeyFinancials}
          />

          <ChoiceProductivityAccordion
            dataLoaded={dataLoaded}
            macros={choiceProductivity}
            onToggleAccordion={this.toggleChoiceProductivity}
          />
          <StyledAccordion expanded={true} title="Macro Trend Analysis">
            <MacroTrendAnalysis
              dataLoaded={dataLoaded}
              trendAnalysis={trendAnalysis}
              productMixAndTrend={productMixAndTrend}
              globalRegionVolAndTrend={globalRegionVolAndTrend}
            />
          </StyledAccordion>
        </div>
      </div>
    );
  }
}
