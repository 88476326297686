import { ZodError, ZodIssue, ZodIssueCode } from 'zod';

// this is to get the locale numeric ordinal suffixes
// TODO: i18n this whenever we implement i18n
const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);
const formatOrdinals = (n: number): string => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};
// This function will render message base on error type
const formatMessageOnErrorType = (ordinalMessage: string, issue: ZodIssue) => {
  const defaultMessageError = `${ordinalMessage}`;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      return `${defaultMessageError} has ${issue.code}, ${issue.expected} is allowed, but ${issue.received} was found`;
    case ZodIssueCode.unrecognized_keys:
    case ZodIssueCode.invalid_union:
    case ZodIssueCode.invalid_enum_value:
    case ZodIssueCode.invalid_arguments:
    case ZodIssueCode.invalid_return_type:
    case ZodIssueCode.invalid_date:
    case ZodIssueCode.invalid_string:
    case ZodIssueCode.too_small:
    case ZodIssueCode.too_big:
    case ZodIssueCode.invalid_intersection_types:
  }
  return `${defaultMessageError} ${issue.message.toLowerCase()}`;
};
const zodIssuePathToHumanMessage = (issue: ZodIssue): string => {
  const pathMessage: (string | number)[] = [];
  issue.path.forEach((curr, idx, path) => {
    if (typeof curr === 'number' && typeof path[idx - 1] === 'string') {
      // zod issue paths list the key, followed by the array index if an array,
      // detect that here, and switch the path strings around to be more readable
      const prevPath = path[idx - 1];
      pathMessage.push(`${formatOrdinals(curr + 1)} ${prevPath} entry`);
    } else if (typeof curr === 'string' && typeof path[idx + 1] === 'number') {
      // if in a string followed by a number, skip, as we'll append the path string
      // in the next loop interation above
      //noop
    } else {
      // otherwise put the string in and left pad it with a space to be more readable
      pathMessage.push(` ${curr}`);
    }
  });
  const ordinalMessage = pathMessage
    .map((el) => {
      // add 1 to the array index, as numbers in english
      // are one indexed, as opposed to javascript, which is zero indexed
      return typeof el === 'number' ? formatOrdinals(el + 1) : el;
    })
    .join();
  return formatMessageOnErrorType(ordinalMessage, issue);
};

export const zodErrorToHumanMessage = (zodErrors: ZodError<any>): string[] => {
  return zodErrors.issues.map(zodIssuePathToHumanMessage);
};
