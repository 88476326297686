import { connect } from 'react-redux';
import { TargetList } from '../TargetList/TargetList';
import { ValueProps as TLValProps, FunctionProps as TLFuncProps, OwnProps } from '../TargetList/TargetList.types';
import { deleteTargetItem, TargetType } from '../Criteria/Criteria.client';
import { capitalize, last, noop } from 'lodash/fp';
import { TargetSettingReduxSlice } from '../TargetSetting/TargetSetting.types';
import { targetListLens } from 'src/services/lenses/lenses';
import { AppState, AppThunkDispatch } from 'src/store';
import { pullDateInformationFromScope } from './TargetList.epics';

function mapListStateToProps(state: AppState): TLValProps {
  const { rowData, viewConf } = targetListLens.get(state);
  return {
    rowData: rowData || [],
    config: viewConf || null,
  };
}

function getTargetType(): TargetType {
  const hashparams = window.location.hash.split('/');
  return capitalize(last(hashparams) || 'product') as TargetType;
}

function mapListDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): TLFuncProps {
  return {
    onShowView: noop,
    onRefetchData: noop,
    onRowAction: (action: string, selectedRow: TargetSettingReduxSlice) => {
      const resp = selectedRow.id;
      let version: string;
      switch (action) {
        case 'View OP':
          version = 'OP';
          break;
        case 'View RP':
          version = 'RP';
          break;
        case 'Edit WP':
          version = 'WP';
          break;
        case 'Remove':
          return deleteTargetItem({
            type: getTargetType(),
            id: resp,
          }).then(() => {
            return dispatch(pullDateInformationFromScope(ownProps));
          });
        default:
          return;
      }
      // strip the trailing slash to prevent weird routing
      window.location.hash = `${window.location.hash.replace(/\/$/, '')}/${resp}/${version}`;
      return null;
    },
  };
}

export default connect(mapListStateToProps, mapListDispatchToProps)(TargetList);
