import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Powerdriver } from './AssortmentCart';
import { CartItem } from './AssortmentCart.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface AssortmentCartSlice {
  cartItems: CartItem[];
  cartItemsToAddCount: number;
  colors: string[];
  powerdrivers: Powerdriver[];
}

const initialState: AssortmentCartSlice = {
  cartItems: [],
  colors: [],
  powerdrivers: [],
  cartItemsToAddCount: 0,
};

const addStylesReducer = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    resetAssortmentCart: () => initialState,
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
  },
});

// As or right now this is one of two places where the actions are handled via lenses.
// SelectStyles is built in a similar way.
export const { resetAssortmentCart, receiveError } = addStylesReducer.actions;
export default addStylesReducer.reducer;
