import { filterAndSortPivotItems } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { createSelector } from 'reselect';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { AppState } from 'src/store';
import { getDataFromCache } from 'src/services/pivotServiceCache';

const getFlatData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.productivity)?.tree || [];
};

const getSubheader = (state: AppState) => {
  return state.subheader;
};
const getViewDefn = (state: AppState) => {
  return state.pages.hindsighting.productivity.viewDefn;
};
export const reorderWithSortBy = createSelector(getFlatData, getSubheader, getViewDefn, (flat, subheader, viewDefn) => {
  let searchFields = externalGridSearchFields;
  if (viewDefn && viewDefn.searchIndexes) {
    searchFields = viewDefn.searchIndexes;
  }
  return filterAndSortPivotItems(subheader.search, subheader.sortBy, searchFields, [], flat as BasicPivotItem[]);
});
