import React from 'react';

interface TopNavIconLinkProps {
  id?: string;
  iconClass: string;
  href: string;
  onClick?: () => void;
  target?: string;
}

const TopNavIconLink = ({ id, iconClass, href, onClick, target = '_self' }: TopNavIconLinkProps) => {
  return (
    <a id={id} className="navbar-brand" href={href} onClick={onClick} target={target} data-qa="TopNavIconLink">
      <i className={iconClass} aria-hidden="true" data-qa="TopNavIconLinkIcon" />
    </a>
  );
};

export default React.memo(TopNavIconLink);
