import { style, classes } from 'typestyle';
import { important, px, rem } from 'csx';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const fontSize = rem(0.85);
export const modalContainer = (width: number | null, height: number | null) => {
  const widthSize = width ? `${width}px` : 'fit-content';
  const heightSize = width ? `${height}px` : '80%';
  return style({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '30%',
    minHeight: '50%',
    margin: '0 auto',
    background: 'white',
    boxShadow: '3px 3px 9px 3px rgba(0,0,0,0.44)',
    width: `${widthSize}`,
    height: `${heightSize}px`,
    maxWidth: '-webkit-fill-available',
    maxHeight: '90%',
  });
};
const styles = {
  overlay: style({
    background: 'rgba(0, 0, 0, .5)',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  modalContainer: style({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: px(500),
    minWidth: px(700),
    background: 'white',
    boxShadow: '3px 3px 9px 3px rgba(0,0,0,0.44)',
  }),
  header: style({
    $debugName: 'cardHeader',
    backgroundColor: TEAL_PRIMARY,
    padding: '0.5rem 1rem',
  }),
  sectionsContainer: style({
    $debugName: 'sectionsContainer',
    background: 'white',
    maxHeight: '80%',
    padding: 20,
    overflow: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    margin: '0 auto',
    maxWidth: '100%',
  }),
  receiptContainer: style({
    $debugName: 'receiptContainer',
    padding: 10,
  }),
  tabContainer: style({
    $debugName: 'tabContainer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: 40,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    overflow: 'auto',
    $nest: {
      '.s5-MuiTabScrollButton-root.s5-Mui-disabled': { opacity: 0.3 },
    },
  }),
  tabButton: style({
    $debugName: 'tabButton',
    textAlign: 'center',
    height: '100%',
    flexGrow: 1,
    border: 'none',
    outline: 0,
    color: 'grey',
    fontWeight: 600,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
    fontSize: '0.95em',
    background: 'transparent',
    $nest: {
      '&:focus': {
        outline: 0,
      },
    },
  }),
  sectionText: style({
    $debugName: 'sectionText',
    fontSize: '1.05em',
    fontWeight: 600,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
  }),
  tableRow: style({
    $debugName: 'tableRow',
    height: px(55),
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  }),
  tableCell: style({
    $debugName: 'tableCell',
    width: px(150),
    minWidth: px(135),
    textAlign: 'center',
    fontSize,
    padding: 0,
    display: 'table-cell',
    verticalAlign: 'middle',
    height: 'inherit',
  }),
  headerSection: style({
    $debugName: 'headerSection',
    paddingBottom: 10,
  }),
  noDataSection: style({
    padding: '10rem',
    fontSize: '2rem',
    textAlign: 'center',
  }),
  tableSection: style({
    $debugName: 'tableSection',
    width: '90%',
    marginLeft: '5%',
    display: 'table',
  }),
  actionButtons: {
    root: style({
      marginRight: 0,
      marginTop: 'auto',
      paddingRight: '0.75rem',
      justifyContent: 'flex-end',
      borderTop: 'solid 1px rgba(224, 224, 224, 1)',
      $nest: {
        button: {
          marginLeft: '10px',
        },
      },
    }),
  },
};

const enhancedStyles = {
  tableCellBody: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellBody',
      fontWeight: 400,
      height: 'inherit',
    })
  ),
  tableCellTotal: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellTotal',
      fontWeight: 600,
    })
  ),
  tableCellHeader: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellHeader',
      color: 'black',
      fontWeight: 600,
    })
  ),
  tableCellInput: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellInput',
      background: 'rgb(220, 243, 241)',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      $nest: {
        '& *': {
          textAlign: 'center',
          verticalAlign: 'middle',
          fontSize: `${fontSize} !important`,
        },
        '& input': {
          border: 'none',
          height: 'inherit',
          background: 'rgb(220, 243, 241)',
          $nest: {
            '&:focus': {
              outline: 'none',
              borderBottom: '2px solid blue',
            },
          },
        },
      },
    })
  ),
  tabButtonActive: classes(
    styles.tabButton,
    style({
      $debugName: 'tabButtonActive',
      color: important(TEAL_PRIMARY), // for some reason this value was not overriding tabButton color
    })
  ),
};

const enhancedViewStyles = {
  tableCellBodyFirst: classes(
    enhancedStyles.tableCellBody,
    style({
      $debugName: 'tableCellBodyFirst',
      borderBottom: 0,
      textAlign: 'right',
      paddingRight: 10,
    })
  ),
  tableCellTotalFirst: classes(
    enhancedStyles.tableCellTotal,
    style({
      $debugName: 'tableCellTotalFirst',
      borderBottom: 0,
      textAlign: 'right',
      paddingRight: 10,
    })
  ),
  tableCellHeaderFirst: classes(
    enhancedStyles.tableCellHeader,
    style({
      $debugName: 'tableCellHeaderFirst',
      borderBottom: 0,
      color: 'white',
    })
  ),
};

export default {
  ...styles,
  ...enhancedStyles,
  ...enhancedViewStyles,
};
