import React from 'react';
import { classes } from 'typestyle';
import { choiceProductivityStyle } from './ChoiceProductivity.styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { getDirectionClasses } from 'src/common-ui/components/Macros/Macros.utils';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';

export default function ChoiceProductivity(props: MacrosProps) {
  if (!Object.keys(props).length) {
    return null;
  }
  const { dataLoaded, metrics, extraClasses } = props;
  const { primary, directional, secondary } = metrics;

  const containerClass = classes(choiceProductivityStyle, extraClasses ? extraClasses : '');
  const { iconClass, middleContainerClass } = getDirectionClasses(directional.direction);

  if (!dataLoaded) {
    return (
      <div className={containerClass}>
        <div className="left-container">{primary.label}</div>
        <Skeleton className="middle-container-skeleton" variant="circle" animation="wave" width={60} height={60} />
        <div className="right-container">
          <section className="secondary-skeleton">
            <Skeleton className="arrow-skeleton dir" variant="text" animation="wave" />
            <Skeleton className="diff-skeleton" variant="text" animation="wave" />
          </section>
          <Skeleton variant="text" animation="wave" />
        </div>
      </div>
    );
  }

  return (
    <div className={containerClass}>
      <div className="left-container">{primary.label}</div>
      <div className={middleContainerClass}>{primary.rendered}</div>
      <div className="right-container">
        <div className="diff-container">
          <i className={iconClass} />
          <span className="diff">{directional.rendered}</span>
        </div>
        <div className="secondary">
          {secondary.label} {secondary.rendered}
        </div>
      </div>
    </div>
  );
}
