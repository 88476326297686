import React from 'react';
import { CustomLoadingOverlayProps } from '@ag-grid-community/react';
import './GridLoadingOverlay.css';

export default function GridLoadingOverlay(_props: CustomLoadingOverlayProps) {
  return (
    <div className="grid-loading-overlay-container">
      <div className="bars-container">
        {Array(10)
          .fill(null)
          .map((_, idx) => (
            <div className="bar" key={idx} />
          ))}
      </div>
    </div>
  );
}
