import React from 'react';
import Button from '@material-ui/core/Button';
import { getNavigationButtonStyle } from './NavigationButton.styles';

type NavigationButtonProps = {
  buttonText: string;
  dataQa?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const NavigationButton = (props: NavigationButtonProps) => {
  const { buttonText, dataQa, onClick, disabled = false } = props;

  return (
    <Button
      data-qa={dataQa}
      className={getNavigationButtonStyle(disabled)}
      disabled={disabled}
      size={'small'}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};
