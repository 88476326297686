export interface ColorSwatchItem {
  type: string;
  name?: string;
  description?: string;
  swatchPath: string;
  id: string;
  colorId: string;
}

export enum CartItemType {
  similar = 'similar',
  existing = 'existing',
  concept = 'concept',
}
export interface CartItem {
  children?: any[];
  id: string;
  _id: string;
  name: string;
  imgSrc: string;
  description: string;
  swatches: ColorSwatchItem[];
  currentChildren: ColorSwatchItem[];
  currentAssortmentChildren?: ColorSwatchItem[];
  type: CartItemType;
  valid: boolean;
  activePowerdriverIndex?: number;
}
