import { connect } from 'react-redux';
import { BoundEnabledPerspective, BoundTab } from 'src/services/configuration/codecs/bindings.types';
import { setFocusedGroup, setCollapsed } from './SideNav.slice';
import { SideNav } from 'src/components/Sidenav/Sidenav';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { AppState, AppThunkDispatch } from 'src/store';
import { WithRouterProps } from 'src/components/higherOrder/withRouter';
import { AxiosInstance } from 'axios';
import { deleteFavorite, loadFavorites } from 'src/state/favorites/favorites.slice';
import { FavoritesEntry } from 'src/services/Favorites';
import { getScopeId, getScopeReadyData } from 'src/state/scope/Scope.types';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

export interface SideNavOwnProps extends Pick<WithRouterProps, 'routerLocation'> {
  className: string;
  sectionName: string;
  perspective: BoundEnabledPerspective;
  tab: BoundTab;
}

export interface StateProps extends SideNavOwnProps {
  groupId: string | null;
  collapsed?: boolean;
  mfpFavorites: FavoritesEntry[];
  mfpScopeId?: string;
  showBookmarks: boolean;
  onlyIndirectMfpScoping: boolean;
  mfpScopeReady: boolean;
}

export interface DispatchProps {
  setFocusedGroup(groupId: string | null): void;
  setCollapsed(collapsed?: boolean): void;
  loadFavorites?: (axios: AxiosInstance) => void;
  removeFavorite?: (axios: AxiosInstance, key: string) => void;
}

export interface Props extends StateProps, DispatchProps, PrintProps { }

function stateToProps(state: AppState, ownProps: SideNavOwnProps): StateProps {
  const mfpScopeId = getScopeId(state.mfpScope);
  const mfpScopeReadyStatus = !!getScopeReadyData(state.mfpScope);
  const onlyIndirectMfpScoping = !!getMfpModule(state)?.onlyIndirectMfpScoping;
  const showBookmarks = state.appConfig.tenantConfig.showBookmarks;

  return {
    ...ownProps,
    groupId: state.sidenav.groupId,
    collapsed: state.sidenav.collapsed,
    mfpFavorites: state.favorites.favorites,
    mfpScopeId: mfpScopeId || '',
    mfpScopeReady: mfpScopeReadyStatus,
    onlyIndirectMfpScoping: onlyIndirectMfpScoping,
    showBookmarks,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setFocusedGroup(groupId: string) {
      return dispatch(setFocusedGroup(groupId));
    },
    setCollapsed(collapsed: boolean) {
      return dispatch(setCollapsed(collapsed));
    },
    loadFavorites(axios: AxiosInstance) {
      return dispatch(loadFavorites(axios));
    },
    removeFavorite: (axios: AxiosInstance, key) => dispatch(deleteFavorite(axios, key)),
  };
}

const sensitiveView = makePrintSensitive(SideNav);
export default connect(stateToProps, dispatchToProps)(sensitiveView);
