import { ICellRendererParams } from '@ag-grid-community/core';
import { isNil } from 'lodash/fp';
import { getSwatchUrl } from '../../StyleEdit.utils';
import { PINKISH, GRAY, CURSOR_POINTER, CURSOR_NOT_ALLOWED } from 'src/utils/Style/Theme';
interface CellRendererComp {
  // Optional - Params for rendering. The same params that are passed to the cellRenderer function.
  init?(params: ICellRendererParams): void;

  // Mandatory - Return the DOM element of the component, this is what the grid puts into the cell
  getGui(): HTMLElement;

  // Optional - Gets called once by grid after rendering is finished - if your renderer needs to do any cleanup,
  // do it here
  destroy?(): void;

  // Mandatory - Get the cell to refresh. Return true if the refresh succeeded, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create
  // a new component in it's place with the new values.
  refresh(): boolean;
}

export interface SwatchSelector {
  swatch: string;
  id: string;
  description: string;
}

export interface ColorHeaderParams {
  $selector: () => SwatchSelector;
  id: string;
  isRemovable: boolean;
  displayRemove?: boolean;
  removeClicked: (id: string) => void;
}

interface Params extends ColorHeaderParams, ICellRendererParams {}

function createElementFromString(html: string) {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  return template.content.firstChild as ChildNode;
}

export class ColorHeaderRenderer implements CellRendererComp {
  styleId!: string;
  getSwatchInfo!: () => SwatchSelector;
  displayRemove: boolean | undefined;
  isRemovable!: boolean;
  eGui!: HTMLElement;
  removeButton!: HTMLElement | null;
  removeHandler!: (id: string) => void;

  // this need pointer-events: auto so that the remove button allows clicks,
  // because the parent has pointer-events: none
  appendChild = () => {
    const { description, swatch } = this.getSwatchInfo();
    const img = getSwatchUrl(swatch);
    const header = createElementFromString(`
      <div style="width: 100%;height: 100%;display: flex;flex-direction: column;justify-content: space-between;align-items: center;" >
        <div style="width: 100%;display: flex;flex-direction: column;align-items: center;margin-top: 10px;" >
          <img style="width: 25px;height: 25px;border: 1px solid grey;" src='${img}' />
          <div style="width: 100%;word-wrap: normal;word-break: break-all;white-space: normal;line-height: 1rem;padding-top: 6px;">
            <span style="font-size: 0.9em;">${description}</span>
          </div>
        </div >
        <button
          style="font-weight: bold;letter-spacing: 0.04rem;border: none;height: 35px;line-height: .5rem; pointer-events: auto;"
          data-remove-color="${this.styleId}">
          REMOVE
        </button>
      </div>
    `);

    this.eGui.appendChild(header);

    // store away reference for event listener cleanup
    this.removeButton = this.eGui.querySelector(`button[data-remove-color="${this.styleId}"]`);
    if (this.removeButton) {
      this.removeButton.addEventListener('click', this.remove);
      this.styleButton();
    }
  };

  init(params: Params) {
    this.eGui = document.createElement('div');
    this.eGui.style.cssText = 'width: 100%;';

    // store away for use later
    const { id: styleId, isRemovable, displayRemove } = params;
    this.getSwatchInfo = params.$selector;

    this.displayRemove = displayRemove;
    this.isRemovable = isRemovable;
    this.styleId = styleId;
    this.removeHandler = params.removeClicked;

    this.appendChild();
  }

  getGui() {
    return this.eGui;
  }

  refresh() {
    this.appendChild();
    return false;
  }

  destroy() {
    if (this.removeButton) {
      this.removeButton.removeEventListener('click', this.remove);
    }
  }

  remove = (event: MouseEvent) => {
    this.removeHandler(this.styleId);
    event.stopPropagation(); // keeps the section from closing so the modal can be displayed
  };

  styleButton() {
    if (isNil(this.removeButton)) {
      return;
    }

    if (!this.displayRemove) {
      this.removeButton.style.display = 'none';
    } else if (!this.isRemovable) {
      this.removeButton.style.color = GRAY; // gray
      this.removeButton.style.cursor = CURSOR_NOT_ALLOWED;
      this.removeButton.setAttribute('disabled', 'disabled');
    } else {
      this.removeButton.style.color = PINKISH; // pink-ish
      this.removeButton.style.cursor = CURSOR_POINTER;
      this.removeButton.removeAttribute('disabled');
    }
  }
}
