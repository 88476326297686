import classNames from 'classnames';
import { BindAll } from 'lodash-decorators';
import React, { CSSProperties, HTMLAttributes } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <i className="drag-handle far fa-bars" />
));

interface MinimizableItemProps {
  children?: React.ReactNode,
  style?: HTMLAttributes<HTMLDivElement>["style"],
  className?: string,
  title: string,
  isMinimized?: boolean,
  optionChildren?: React.ReactNode,
  disableMinimize?: boolean
}

interface MinimizableItemState {
  isMinimized: boolean
}

@BindAll()
class MinimizableItem extends React.Component<
  MinimizableItemProps,
  MinimizableItemState
  > {
  constructor(props: MinimizableItemProps) {
    super(props);
    this.state = {
      isMinimized: this.props.isMinimized || false
    };
  }

  public onClickMinimize() {
    this.setState({ isMinimized: !this.state.isMinimized });
  }

  public render() {
    let minIcon = (
      <i
        className="far fa-chevron-up min-icon"
        onClick={this.onClickMinimize}
      />
    );
    let className = 'draggable-item';
    if (this.state.isMinimized) {
      minIcon = (
        <i
          className="far fa-chevron-down min-icon"
          onClick={this.onClickMinimize}
        />
      );
      className += ' draggable-item-minimized';
    }
    return (
      <div
        className={classNames(this.props.className, className)}
        style={this.props.style}
      >
        <div className="header-bar">
          {!this.props.disableMinimize && minIcon}
          <span className="header-bar-title">{this.props.title}</span>
          <span className="draggable-item-options">
            {this.props.optionChildren}
            <DragHandle />
          </span>
        </div>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
export default MinimizableItem;
