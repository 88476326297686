import * as React from 'react';
import { findIndex, isArray } from 'lodash/fp';
import { default as styles } from './Criteria.style';
import { classes } from 'typestyle';
import { CriteriaProps, CriteriaOption } from './Criteria.types';
import { Checkbox } from '@material-ui/core';

export class Criteria extends React.Component<CriteriaProps> {
  constructor(props: CriteriaProps) {
    super(props);
    this.renderList = this.renderList.bind(this);
  }

  optionSelected = (selection: CriteriaOption, checkForMulti?: boolean): void => {
    this.props.onOptionSelected(selection, this.props.showCheckAll && checkForMulti);
  };

  checkAll(_options: CriteriaOption[]) {
    this.props.selectAllRight();
  }

  componentDidMount() {
    if (this.props.onShowView) {
      this.props.onShowView();
    }
  }

  componentDidUpdate(oldProps: CriteriaProps) {
    if (this.props.selections.length === 1 && this.props.selections[0] !== oldProps.selections[0]) {
      if (this.props.selectFirstSeed) {
        this.props.selectFirstSeed();
      }
    }
  }

  renderList(option: CriteriaOption, multi?: boolean): JSX.Element {
    const { text, dataIndex } = option;
    const selected = (() => {
      const single = findIndex(option, this.props.selections) >= 0 ? styles.selected : null;
      if (single) {
        return single;
      }
      if (!multi || !this.props.showCheckAll) {
        return null;
      }
      let inMulti;
      this.props.selections.some((selections: unknown) => {
        if (isArray(selections)) {
          if (
            findIndex((opt) => {
              return opt.dataIndex === option.dataIndex;
            }, selections) >= 0
          ) {
            inMulti = styles.selected;
            return true;
          }
        }
        return false;
      });
      return inMulti;
    })();
    const disabled = option.disabled ? 'disabled' : null;
    return (
      <div
        key={dataIndex}
        onClick={() => (!disabled ? this.optionSelected(option, multi) : null)}
        className={classes(styles.item, selected, disabled)}
      >
        <span className={styles.itemText}>{text}</span>
      </div>
    );
  }

  render() {
    const { titles, options, showCheckAll, datepicker } = this.props;
    const leftBox = options[0]
      ? options[0].map((option) => {
          return this.renderList(option, false);
        })
      : undefined;
    const rightBox = options[1]
      ? options[1].map((option) => {
          return this.renderList(option, true);
        })
      : undefined;
    const leftTitle = titles[0] && options[0] ? <span className={styles.listTitleText}>{titles[0]}</span> : undefined;
    let rightTitle = titles[1] && options[1] ? <span className={styles.listTitleText}>{titles[1]}</span> : undefined;
    const triangle = options[1] ? <div className={styles.triangle} /> : undefined;
    let thirdBox;

    if (showCheckAll && rightTitle) {
      rightTitle = (
        <div className={styles.listTitleText}>
          <Checkbox checked={this.props.allSelected} onClick={() => this.checkAll(options[1])} />
          <span className={styles.checkboxTitle}>{titles[0]}</span>
        </div>
      );
    }

    // TODO: This shouldn't be here, but I haven't the time to fix it :/
    if (datepicker) {
      thirdBox = (
        <React.Fragment>
          <div className={styles.triangleBox}>{triangle}</div>
          <article className={styles.outerBox}>
            <section className={styles.itemList}>
              <div className={styles.datepickerBox}>{datepicker}</div>
            </section>
          </article>
        </React.Fragment>
      );
    }

    return (
      <div className={styles.container}>
        <article className={styles.outerBox}>
          <section className={styles.itemList}>
            <div className={styles.listTitle}>{leftTitle}</div>
            <div className={styles.innerList}>{leftBox}</div>
          </section>
        </article>
        <div className={styles.triangleBox}>{triangle}</div>
        <article className={styles.outerBox}>
          <section className={styles.itemList}>
            <div className={styles.listTitle}>{rightTitle}</div>
            <div className={styles.innerList}>{rightBox}</div>
          </section>
        </article>
        {thirdBox}
      </div>
    );
  }
}
