import { style } from 'typestyle';
import { highlight } from '../../theme';
import { isOutsideViewport, pixelsToNumber } from './WeekRangePicker.utils';
import { INPUT_HEIGHT, SCROLLBAR_WIDTH, STATIC_LEFT_OFFSET } from './WeekRangePicker.styles';
import { StylingOverrides } from './WeekRangePicker.interface';

/**
 * FIXME: We need this type until react-day-picker can be updated.
 * The type has been fixed.
 * https://github.com/gpbl/react-day-picker/commit/0e761414586ab124fe962ddca44014dc24fdef9e
 */
export type DayPickerType = {
  dayPicker: HTMLDivElement;
} | null;

export function radioOptionsContainer(parentContainer: DayPickerType, overrides?: StylingOverrides) {
  const radioContainerMarginLeft = 15;
  const radioContainerMarginTop = 50;
  let top;
  let left;
  let containerHeight;
  let minWidth;
  if (parentContainer && parentContainer.dayPicker) {
    const { height: parentContainerHeight } = parentContainer.dayPicker.getBoundingClientRect();

    if (parentContainerHeight) {
      containerHeight = parentContainerHeight - radioContainerMarginTop;
      const parentContainerComputedStyles = window.getComputedStyle(parentContainer.dayPicker);
      const isOut = isOutsideViewport(parentContainer.dayPicker, SCROLLBAR_WIDTH);

      const hasParentMovedTop = !!pixelsToNumber('top', parentContainerComputedStyles);
      const heightPosition = -(INPUT_HEIGHT + parentContainerHeight);
      if (isOut.bottom || hasParentMovedTop) {
        top = heightPosition;
      }

      const hasPickerMovedLeft = !!pixelsToNumber('left', parentContainerComputedStyles);
      if (isOut.right || hasPickerMovedLeft) {
        left = -STATIC_LEFT_OFFSET;
      }
    }
  }

  if (!containerHeight || containerHeight === 0) {
    containerHeight = 273;
  }

  if (overrides) {
    if (overrides.minWidth !== undefined) {
      minWidth = overrides.minWidth;
    }
    if (overrides.top !== undefined) {
      top = overrides.top;
    }
  }

  // Don't go off screen
  if (left && left < -260) {
    left = -260;
  }

  return style({
    $debugName: 'radioOptionsContainer',
    color: 'black',
    minWidth: minWidth,
    display: 'flex',
    flexDirection: 'column',
    height: containerHeight,
    justifyContent: 'center',
    left: left,
    listStyle: 'none',
    marginLeft: radioContainerMarginLeft,
    marginTop: radioContainerMarginTop,
    position: 'absolute',
    top: top,
    zIndex: 102,
    $nest: {
      '.option-container': {
        minHeight: 50,
        $nest: {
          '&-disabled': {
            cursor: 'not-allowed',
            color: '#dee2e6',
          },
        },
      },
      '.option': {
        alignItems: 'center',
        display: 'flex',
        $nest: {
          '&-disabled': {
            cursor: 'not-allowed',
          },
          '&-input': {
            margin: '0 5px',
            $nest: {
              '&-disabled': {
                cursor: 'not-allowed',
              },
            },
          },
          '&-label': {
            fontSize: '1rem',
            margin: '0 5px',
          },
        },
      },
      '.week-label': {
        color: highlight,
        display: 'flex',
        fontSize: '0.5rem',
        fontStyle: 'italic',
        fontWeight: 600,
        justifyContent: 'center',
        paddingLeft: '22px',
        $nest: {
          '&-disabled': {
            color: '#dee2e6',
          },
        },
      },
    },
  });
}
