import { style } from 'typestyle';
import { important } from 'csx';

export default style({
  $nest: {
    '&>*:first-child': {
      $nest: {
        '& .ag-root-wrapper': {
          $nest: {
            '& .ag-root-wrapper-body': {
              $nest: {
                '& .ag-root': {
                  $nest: {
                    // We hide the header entirely since the popover grids don't use the grid headers (instead they build their own)
                    '& .ag-header-viewport,& .ag-header': {
                      display: 'none'
                    },
                    '& .ag-body-viewport': {
                      $nest: {
                        // Left/title column
                        '& .ag-pinned-left-cols-container': {
                          width: important('170px'),
                          minWidth: important('150px'),
                          $nest: {
                            '& .ag-row': {
                              border: 0,
                              background: 'white',
                              $nest: {
                                '& .ag-cell': {
                                  fontSize: important('0.7rem'),
                                  textAlign: 'right',
                                  display: 'inline-block',
                                  width: important('170px'),
                                  minWidth: important('150px'),
                                  paddingLeft: important('0'),
                                },
                              },
                            },
                          },
                        },
                        // Main body / right side
                        '& .ag-center-cols-viewport': {
                          $nest: {
                            '& .ag-center-cols-container': {
                              $nest: {
                                '& .ag-row': {
                                  border: 0,
                                  background: 'white',
                                  $nest: {
                                    '& .ag-cell': {
                                      fontSize: important('0.7rem'),
                                      textAlign: 'center',
                                      display: 'inline-block',
                                      $nest: {
                                        '& div': {
                                          // tslint:disable:max-line-length
                                          textOverflow: important('ellipsis'),
                                          overflow: important('hidden'),
                                          // tslint:enable:max-line-length
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
