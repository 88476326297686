import React from 'react';
import {
  DimensionItem,
  getAvailableListing,
  getEffeciveGroups,
  getGroupFromConfigItem,
  PivotOptions,
} from 'src/components/Mfp/PivotConfigurator/utils';
import { flatMap, isEmpty, keyBy } from 'lodash';
import PivotConfig from 'src/pivot/PivotConfig';
import { getScopeReadyData, ScopeStateUnion } from 'src/state/scope/Scope.types';
import { SettingsState } from 'src/state/settings/settings.slice';

export function createPivoteConfig(
  settings: SettingsState['entriesByKey'],
  scopeToSummarize: ScopeStateUnion,
  rows: DimensionItem[],
  columns: DimensionItem[],
  readOnly = false
) {
  const readyData = getScopeReadyData(scopeToSummarize);
  if (readyData === undefined) {
    return undefined;
  }
  const mainConfig = readyData.mainConfig;
  const scopeId = mainConfig.id;
  if (scopeId === undefined) {
    return undefined;
  }
  const all = getAvailableListing(mainConfig, settings);

  const viewParams: PivotOptions = {
    rows,
    columns,
  };
  const effective = getEffeciveGroups(all, viewParams);
  const levelsMap = keyBy(flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
  const pivotCols = effective.column.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
  if (isEmpty(pivotCols) || isEmpty(pivotRows)) {
    // Safety check for invalid pivots being called
    // TODO: report and throw error here
    throw new Error('Attempted to create an empty pivot');
  }
  return new PivotConfig({
    readOnly,
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols,
  });
}
