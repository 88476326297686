import { find, isArray, isEmpty, isNil } from 'lodash';
import { partial } from 'lodash/fp';
import { update } from 'src/services/lenses/Lenses.actions';
import { getConfigurableGridData, getLeafIdentifier } from 'src/components/ConfigurableGrid/ConfigurableGrid.selectors';
import { AppState, AppThunkDispatch } from 'src/store';
import { ConfigurableGridOwnProps } from './ConfigurableGrid.types';
import { Lens } from 'monocle-ts';
import { AssortmentAddSlice } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import { makeCartService } from 'src/pages/AssortmentCart/AssortmentCart.service';
import { assortmentAddStylesLens } from 'src/services/lenses/lenses';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export function toggleSelectionsInCart(
  ownProps: ConfigurableGridOwnProps,
  selections: BasicPivotItem | BasicPivotItem[]
) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const selectionService = makeCartService(
      assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('selectedItemsForCart'))
    );
    const state = getState();
    const leafId = getLeafIdentifier(ownProps);
    const ungroupedData = getConfigurableGridData(state, {
      showFlowStatus: ownProps.showFlowStatus,
      leafId,
    });

    if (isArray(selections)) {
      const foundPivotItems = ungroupedData.filter((ugd) => {
        const foundItem = selections.find((item) => ugd[leafId] === item.id);
        return !isNil(foundItem);
      });

      if (isEmpty(foundPivotItems)) {
        return;
      }

      foundPivotItems.forEach((fpi) => {
        dispatch(update(partial(selectionService.toggleItem, [fpi]), `Toggle item ${fpi.id} in 'to cart' list.`));
      });
    } else {
      const foundPivotItem = find(ungroupedData, (i) => i[leafId] === selections.id);
      if (foundPivotItem == null) {
        return;
      }
      dispatch(update(partial(selectionService.toggleItem, [foundPivotItem]), `Toggle item in 'to cart' list.`));
    }
  };
}
