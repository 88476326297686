import dayjs from 'dayjs';
import { get } from 'lodash';
import type { ValueFormatterFunc } from '@ag-grid-community/core';
import type { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';

// Date formatter for ag-grid, from PlanMetadata to string
export const formatDate: ValueFormatterFunc<PlanMetadata, any> = (params): string => {
  const key = params.colDef.field;
  // when rowGrouping, data is nullable
  if (!params.data || key !== 'createdAt') { return ''; }
  const plan = params.data;

  if (key in plan && plan[key] instanceof dayjs) {
    return plan[key].format(DATE_FORMAT);
  } else if (plan.createdAt) {
    return get(plan, key).format(DATE_FORMAT);
  }
  return '';
};

// 12 hour,min, short weekday, short month, 2 digit day of month ('Tuesday, Jan 31')
// https://github.com/iamkun/dayjs/blob/dev/docs/en/API-reference.md#list-of-all-available-formats
export const DATE_FORMAT = 'h:mm a, ddd, MMM DD';
