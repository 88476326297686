import React, { useState } from 'react';
import { dropdownMenu, button } from './SplitButton.styles';
import { MenuItemDef } from '@ag-grid-community/core';
import { noop, isEmpty } from 'lodash';
import { Tooltip, CircularProgress, Button, Menu, ButtonGroup } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
type Props = {
  text: string;
  icon: string;
  isLoading: boolean;
  isDisabled: boolean;
  onButtonClick: (() => void) | undefined;
  menuItems: MenuItemDef[];
};

const SplitButton = ({ text, icon, isDisabled, isLoading, onButtonClick = noop, menuItems }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const toggle = () => setOpen(!isOpen);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    toggle();
    setAnchorEl(event.currentTarget);
  };
  return (
    <ButtonGroup classes={{ root: button }} size="small">
      <Button disabled={isDisabled} onClick={onButtonClick}>
        {isLoading ? (
          <CircularProgress size={20} color={'inherit'} disableShrink={true} />
        ) : (
          <i className={icon as string} />
        )}
        <span>{text}</span>
      </Button>
      {isEmpty(menuItems) ? null : (
        <Button onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} disabled={isDisabled} size="small">
          <Menu
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: dropdownMenu }}
          >
            {menuItems.map((menuItem, index) => {
              const { name, action = noop, icon, tooltip = '' } = menuItem;
              const handleClick = () => {
                toggle();
                action();
              };

              return (
                <Tooltip key={`${name}-${index}`} title={tooltip} placement="left">
                  <div onClick={handleClick}>
                    <i className={icon as string} />
                    <span>{name}</span>
                  </div>
                </Tooltip>
              );
            })}
          </Menu>
        </Button>
      )}
    </ButtonGroup>
  );
};

export default SplitButton;
