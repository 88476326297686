import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import {
  WorkingSetReport,
  receivedWorkingSets,
  receivedReports,
  WorkingSetContextPayload,
  WorkingSetContext,
  setLastPublishTime,
  PublishEventPayload,
  receivedPublish,
  receivedActualsUpdate,
} from 'src/state/workingSets/workingSets.slice';
import { Dispatch, AnyAction } from 'redux';
import { updatePersistenceStatus } from 'src/state/scope/Scope.slice';
import { PersistMessage } from 'src/state/scope/Scope.types';
import dayjs from 'dayjs';
import { defer, Observable, of, onErrorResumeNext } from 'rxjs';
import { concatAll, delay, repeat, switchMap, tap } from 'rxjs/operators';

const SSE_CONTEXT_TYPE = 'context-async-state';
const SSE_PERSIST_TYPE = 'context-persist-state';
const SSE_REPORT_TYPE = 'report-async-state';
const SSE_PUBLISH_TYPE = 'publish-event-status';
const SSE_ACTUALS_TYPE = 'actuals-event-status';

type MFPMessageEvent = ContextsEvent | PersistEvent | ReportEvent | PublishEvent | LegacyPublishEvent | ActualsEvent;

interface ContextsEvent extends Event {
  type: typeof SSE_CONTEXT_TYPE;
  data: WorkingSetContextPayload[];
}
interface PersistEvent extends Event {
  type: typeof SSE_PERSIST_TYPE;
  data: PersistMessage;
}
interface ReportEvent extends Event {
  type: typeof SSE_REPORT_TYPE;
  data: WorkingSetReport[];
}
// TODO: remove when new publish event shape is deployed
interface LegacyPublishEvent extends Event {
  type: typeof SSE_PUBLISH_TYPE;
  data: { lastPublish: string };
}

interface PublishEvent extends Event {
  type: typeof SSE_PUBLISH_TYPE;
  data: PublishEventPayload;
}

interface ActualsEvent extends Event {
  type: typeof SSE_ACTUALS_TYPE;
  data: never;
}

// This should totally only ever be called once to create application singleton after login
// If it is called more, bad things will probably happen

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SseMessage = { name: string; payload: any };

function eventSource(accessToken: string): Observable<Event> {
  return new Observable((observer) => {
    const eventSource = new EventSource(`${API_BASE_URL}/events?token=${accessToken}`);

    function onMessage(e: Event) {
      observer.next(e);
    }
    function onError(err: Event) {
      if (err.currentTarget) {
        const es: EventSource = err.currentTarget as EventSource;
        if (es.readyState === EventSource.CLOSED) {
          observer.complete();
          eventSource.removeEventListener('message', onMessage, false);
          eventSource.removeEventListener('error', onError, false);
        } else {
          observer.error(err);
          eventSource.removeEventListener('message', onMessage, false);
          eventSource.removeEventListener('error', onError, false);
        }
      }
    }

    eventSource.addEventListener(SSE_PERSIST_TYPE, onMessage);
    eventSource.addEventListener(SSE_CONTEXT_TYPE, onMessage);
    eventSource.addEventListener(SSE_REPORT_TYPE, onMessage);
    eventSource.addEventListener(SSE_PUBLISH_TYPE, onMessage);
    eventSource.onerror = onError;

    return () => {
      eventSource.removeEventListener('message', onMessage, false);
      eventSource.removeEventListener('error', onError, false);
      eventSource.close();
    };
  });
}

const serverEventStreamListener = (dispatch: Dispatch<AnyAction>, message: Event) => {
  // TODO fix this coercison nonsense
  const msg = message as MFPMessageEvent; // This is the correct type, but the DOM lists it incorrectly
  // TODO replace the below with zod parsing for safety;
  const data = JSON.parse((msg.data as unknown) as string) as MFPMessageEvent['data'];

  switch (msg.type) {
    case SSE_CONTEXT_TYPE: {
      const d = data as WorkingSetContext[];
      const parsedDates: WorkingSetContext[] = d.map(
        (ws): WorkingSetContext => {
          return {
            ...ws,
            contextCreationTime: dayjs(ws.contextCreationTime),
          };
        }
      );
      dispatch(receivedWorkingSets(parsedDates));
      break;
    }
    case SSE_PERSIST_TYPE:
      dispatch(updatePersistenceStatus(data as PersistMessage));
      break;
    case SSE_REPORT_TYPE:
      dispatch(receivedReports(data as WorkingSetReport[]));
      break;
    case SSE_PUBLISH_TYPE:
      // TODO: remove when new publish event shape is deployed
      if ((data as LegacyPublishEvent['data']).lastPublish) {
        dispatch(setLastPublishTime((data as LegacyPublishEvent['data']).lastPublish));
      } else {
        dispatch(receivedPublish(data as PublishEvent['data']));
      }
      break;
    case SSE_ACTUALS_TYPE:
      dispatch(receivedActualsUpdate());
      break;
    default:
      break;
  }
};

const MIN_DELAY_MS = 500;
const MAX_DELAY_MS = 60000; // 60 s

export function startSse(dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>) {
  // The MFP server is not always deployed, we use this to determine if we should backoff because the SSE will spin
  // The MFP server SSE is currently configured to always emit events at startup, so if we succeed in getting a connetion
  // we will get an event
  // We can use this property to determine whether we should backoff or not
  let successfulEmit: boolean = false;
  let delayMs = MIN_DELAY_MS;

  function onSuccessfulEmit(_ev: Event) {
    successfulEmit = true;
  }

  function computeNextDelay(): number {
    // Disabling no-console because this is useful debugging information
    if (successfulEmit) {
      // eslint-disable-next-line no-console
      console.info('MFP SSE reconnect fast');
      delayMs = MIN_DELAY_MS;
    } else {
      // eslint-disable-next-line no-console
      console.info('MFP SSE reconnect backoff');
      delayMs = Math.min(4 * delayMs, MAX_DELAY_MS);
    }
    // Reset the state here
    successfulEmit = false;
    return delayMs;
  }

  function launchEventSource(token: string): Observable<Event> {
    return onErrorResumeNext(
      eventSource(token).pipe(tap(onSuccessfulEmit)),
      defer(() =>
        of(launchEventSource(token))
          .pipe(delay(computeNextDelay()))
          .pipe(concatAll())
      )
    );
  }

  return accessToken$
    .pipe(switchMap(launchEventSource))
    .pipe(repeat())
    .subscribe((ev) => serverEventStreamListener(dispatch, ev));
}
