import React, { useState, useEffect, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { iconClass, leftIconClass, textFieldContainer } from './ConfigEditor.styles';
import { keys } from '../../utils/Domain/Constants';
import IconPopper from 'src/common-ui/components/IconPopper/IconPopper';
import { DeleteConfirmationModal, DeleteType } from '../Modals/DeleteConfirmationModal';

type TextFieldLabelProps = {
  isSelected: boolean;
  defaultValue: string;
  isEditable: boolean;
  leftIcon: string;
  isDefault?: boolean;
  isView?: boolean;
  onChangeIcon: (iconClass: string) => void;
  onChangeName: (value: string) => void;
  onEditStart: () => void;
  onDelete: () => void;
};

const TextFieldLabel = (props: TextFieldLabelProps) => {
  const {
    isSelected,
    defaultValue,
    isEditable: propsIsEditable,
    leftIcon,
    isView,
    onChangeName,
    onChangeIcon,
    onEditStart,
    onDelete,
  } = props;
  const [isEditable, setEditable] = useState(false);
  const [name, setName] = useState(defaultValue);
  const [showIconModal, setShowIconModal] = useState(false);
  const [icon, setIcon] = useState(leftIcon);
  const [isDelete, setIsDelete] = useState(false);
  useEffect(() => {
    setIcon(leftIcon);
  }, [leftIcon]);

  useEffect(() => {
    setName(defaultValue);
  }, [defaultValue]);

  const handleClose = useCallback(() => {
    setIsDelete(false);
  }, [setIsDelete]);

  if (isSelected && isEditable && propsIsEditable) {
    return (
      <TextField
        onBlur={() => {
          setEditable(false);
          if (defaultValue != name) {
            onChangeName(name);
          } else {
            setName(defaultValue);
          }
        }}
        value={name}
        onChange={(e: any) => {
          setName(e.target.value);
        }}
        onKeyDown={(e: any) => {
          if (e.type === 'keydown' && e.keyCode === keys.enter) {
            e.target.blur();
          }
        }}
        InputProps={{ disableUnderline: true }}
        style={{ width: '100%' }}
      />
    );
  }
  return (
    <div className={textFieldContainer}>
      <IconPopper
        open={showIconModal}
        handleCloseModal={() => setShowIconModal(false)}
        onSelectIcon={(iconClass: string) => {
          onChangeIcon(iconClass);
        }}
      />
      <div>
        <i className={`${icon} ${leftIconClass}`} onClick={() => setShowIconModal(true)} />
        <span style={{ cursor: 'pointer', fontWeight: isView && isSelected ? 700 : 'initial' }}>{name}</span>
      </div>
      {isSelected && (
        <div>
          <i
            className={`fa fa-pencil ${iconClass}`}
            style={{ color: TEAL_PRIMARY, cursor: 'pointer' }}
            onClick={() => {
              onEditStart();
              setEditable(true);
            }}
          />
          <i
            className={`fa fa-trash ${iconClass}`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsDelete(true);
            }}
          />
        </div>
      )}
      {isDelete && (
        <DeleteConfirmationModal
          isOpen={isDelete}
          onDelete={onDelete}
          onClose={handleClose}
          item={{ view: name }}
          deleteType={DeleteType.configuratorView}
        />
      )}
    </div>
  );
};

export default TextFieldLabel;
