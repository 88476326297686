import {
  companionBorder,
  companionExpandedWidth,
} from '../../../common-ui/components/CompanionListView/CompanionListView.styles';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import { borderHighlightColor, smallFont, smallMediumFont } from 'src/utils/Style/Theme';
import { style } from 'typestyle';
import { editableCell } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.styles';

const styles = style({
  $debugName: 'size-eligibility',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const container = style({
  $debugName: 'size-eligibility-list',
  display: 'flex',
  height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
  $nest: {
    ['> .subcont-flexible']: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
  },
});
const gridClass = style({
  // height: '100%',
  flexDirection: 'column',
  height: '100%',
  borderRight: '1px solid #ddd',
  $nest: {
    ['.checkbox-size-cell']: {
      textAlign: 'center',
    },
    [`.ag-row-focus:not(.ag-row-hover) ${editableCell}`]: {
      color: 'white',
    },
  },
});

const highlightedCell = style({
  backgroundColor: 'rgba(220, 243, 241, .7)',
});

const sizeRangeTitle = style({
  $debugName: 'size-range-title',
  fontStyle: 'italic',
  fontWeight: 1.1,
  fontSize: '1.5em',
});
const companionStyles = style({
  borderRight: companionBorder,
  width: companionExpandedWidth,
  height: '100%',
  $nest: {
    '&.scroll-target': {
      borderColor: borderHighlightColor,
    },
    '.control-bar > label': {
      fontSize: smallMediumFont,
    },
    '.control-bar .dropdown': {
      fontSize: smallMediumFont,
      alignItems: 'center',
      display: 'flex',
    },
    '.control-bar .dropdown-item': {
      fontSize: smallFont,
    },
    '&.collapsed': {
      maxWidth: 115,
    },
    '> header': {
      borderBottom: companionBorder,
    },
    '.id': {
      fontSize: '0.7rem',
    },
    '.name': {
      height: 40,
      display: 'flex',
      overflow: 'auto',
      fontSize: '0.8rem',
    },
  },
});

export { gridClass, container, companionStyles, sizeRangeTitle, highlightedCell };
export default styles;
