import {
  PivotCell,
  CELL_TAG_LOCKABLE,
  CELL_TAG_UNEDITABLE,
  CELL_TAG_LOCKED,
  CELL_TAG_RELAXED,
  CELL_TAG_FROZEN
} from './PivotCell';

export const getCellStateFromCells = (cells: PivotCell[]): {
  isUnFreezeable: boolean,
  isUnlockable: boolean,
  isRelaxed: boolean
} => {

  const isUnFreezeable =
    cells.filter(
      (cell: PivotCell) =>
        cell &&
        cell.hasTag(CELL_TAG_FROZEN) &&
        cell.hasTag(CELL_TAG_LOCKABLE) &&
        !cell.hasTag(CELL_TAG_UNEDITABLE)
    ).length > 0;

  const isUnlockable =
    cells.filter(
      (cell: PivotCell) =>
        cell &&
        cell.hasTag(CELL_TAG_LOCKED) &&
        cell.hasTag(CELL_TAG_LOCKABLE) &&
        !cell.hasTag(CELL_TAG_UNEDITABLE)
    ).length > 0;

  const isRelaxed =
    cells.filter(
      (cell: PivotCell) =>
        cell &&
        cell.hasTag(CELL_TAG_RELAXED) &&
        cell.hasTag(CELL_TAG_LOCKABLE) &&
        !cell.hasTag(CELL_TAG_UNEDITABLE)
    ).length > 0;

  return {
    isUnFreezeable,
    isUnlockable,
    isRelaxed
  };
};
