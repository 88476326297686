import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const button = style({
  background: TEAL_PRIMARY,
  color: 'white',
  height: 40,
  cursor: 'pointer',
  margin: '0 auto',
  width: 150,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: '.9rem',
});

const styles = {
  container: style({
    width: 500,
  }),
  body: style({
    width: '100%',
    height: '85%',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    $nest: {
      'a:hover': {
        textDecoration: 'none',
      },
    },
  }),
  button: classes(
    button,
    style({
      $nest: {
        '&:hover': {
          background: TEAL_PRIMARY,
          color: 'white',
          textDecoration: 'none !important',
        },
      },
    })
  ),
  input: style({
    height: 40,
    width: 300,
    border: '1px solid #ccc',
    borderRadius: '5px',
    display: 'inline-block',
    padding: '6px 12px',
    cursor: 'pointer',
    marginRight: '10px',
  }),
  titleContainer: style({
    height: '15%',
    width: '100%',
    padding: 15,
    marginBottom: 10,
  }),
  buttonCancel: style({
    position: 'absolute',
    right: 15,
    top: 15,
    fontWeight: 600,
    cursor: 'pointer',
  }),
  title: style({
    fontSize: '1rem',
    fontWeight: 600,
  }),
  excelButton: style({
    color: TEAL_PRIMARY,
    borderColor: TEAL_PRIMARY,
    marginTop: '1rem !important',
  }),
};

export default styles;
