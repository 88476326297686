import Axios from 'src/services/axios';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { Indexable } from 'src/types/Primitive';

export interface ReportGroup {
  id: string;
  name: string;
  items: string[];
}

export interface Report {
  id: string;
  name: string;
  description: string;
  reportParams: string;
  paramGroup: string;
  modelId: string;
  templateId: string;
  params?: Indexable;
}

export interface Identifyable {
  id: string;
  name: string;
}

export interface Params {
  [key: string]: Identifyable[];
}

export interface ParamGroup {
  id: string;
  defaultParams: Indexable;
  params: Params;
}

export interface ReportConfig {
  reportGroups: ReportGroup[];
  reports: Report[];
  paramGroups: ParamGroup[];
}

export interface ReportClient {
  getReportConfig(appName: AppType): Promise<ReportConfig>;
}

export function makeReportClient(): ReportClient {
  return {
    getReportConfig(appName: AppType): Promise<ReportConfig> {
      return Axios.get('/api/report?appName=' + appName).then((response) => response.data.data);
    },
  };
}
