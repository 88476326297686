import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { GeoTrendsConfigData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ViewDataState } from 'src/types/Domain';
import { ViewDefnState } from 'src/dao/tenantConfigClient';

interface ConfigViewItem extends TenantConfigViewItem {
  defaultAggBy?: string;
}
export interface ViewDefns {
  keyFinancials: ConfigViewItem;
  choiceProductivity: ConfigViewItem;
  productMixAndTrend: ConfigViewItem;
  trendAnalysis: ConfigViewItem;
  globalRegionVolumeAndTrend: GeoTrendsConfigData;
}

export interface ViewData {
  keyFinancials: BasicPivotItem[];
  choiceProductivity: BasicPivotItem[];
  trendAnalysis: BasicPivotItem[];
  productMixAndTrend: BasicPivotItem[];
  globalRegionVolumeAndTrend: BasicPivotItem[];
}

export interface SummarySlice {
  viewDefnState: ViewDefnState;
  viewDataState: ViewDataState;
  flowStatusUpdated?: boolean;
  viewDefns: ViewDefns;
  viewData: ViewData;
}

const initialState: SummarySlice = {
  viewDefnState: ViewDefnState.idle,
  viewDataState: ViewDataState.idle,
  flowStatusUpdated: true,
  viewDefns: {
    keyFinancials: {} as ConfigViewItem,
    choiceProductivity: {} as ConfigViewItem,
    productMixAndTrend: {} as ConfigViewItem,
    trendAnalysis: {} as ConfigViewItem,
    globalRegionVolumeAndTrend: {} as GeoTrendsConfigData,
  } as ViewDefns,
  viewData: {
    keyFinancials: [],
    choiceProductivity: [],
    trendAnalysis: [],
    productMixAndTrend: [],
    globalRegionVolumeAndTrend: [],
  },
};

const summarySlice = createSlice({
  name: 'Summary',
  initialState,
  reducers: {
    requestSummaryConfigs(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveSummaryConfigs(state, action: PayloadAction<ViewDefns>) {
      state.viewDefns = action.payload;
      state.viewDefnState = ViewDefnState.loaded;
    },
    requestSummaryData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveSummaryData(state, action: PayloadAction<ViewData>) {
      state.viewData = action.payload;
      state.viewDataState = ViewDataState.liveDataReady;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestSummaryConfigs,
  receiveSummaryConfigs,
  requestSummaryData,
  receiveSummaryData,
  receiveError,
  cleanUp,
} = summarySlice.actions;

export default summarySlice.reducer;
