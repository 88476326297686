import { Tabs, Tab, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { tabStyle, tabClass, addNewTabClass } from './HorizontalTabs.styles';
import { makePathSlot, TenantTabConfigurator } from './ConfigEditor.utils';
import { keys } from '../../utils/Domain/Constants';
import { DeleteConfirmationModal, DeleteType } from 'src/components/Modals/DeleteConfirmationModal';

export interface HorizontalTabs {
  tabs: TenantTabConfigurator[];
  defaultTabs: TenantTabConfigurator[];
  selectedTabIndex: number;
  onTabsChange?: (tabs: TenantTabConfigurator[]) => void;
  onSelectionChange: (index: number) => void;
}

interface TextFieldLabelProps {
  defaultValue: string;
  isSelected: boolean;
  availableNames: string[];
  reservedPathSlots: string[];
  deleteTab: () => void;
  onChangeName: (value: string) => void;
}

const EditableTabLabel = (props: TextFieldLabelProps) => {
  const { isSelected, defaultValue, availableNames, reservedPathSlots, onChangeName, deleteTab } = props;
  const [isEditable, setEditable] = useState(false);
  const [name, setName] = useState(defaultValue);
  // Names cannot be duplicates, but also cannot be the default tab names, even if the name is changed
  // As the pathSlot remains the same
  const isDuplicate = availableNames.indexOf(makePathSlot(name)) > -1;
  const isReservedName = reservedPathSlots.indexOf(makePathSlot(name)) > -1;

  useEffect(() => {
    setName(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setEditable(false);
  }, [isSelected]);

  if (isSelected && isEditable) {
    let helperText = isDuplicate && 'Name already in use';
    if (isReservedName) helperText = 'Name is reserved';
    return (
      <TextField
        error={isDuplicate || isReservedName}
        onBlur={() => {
          setEditable(false);
          if (defaultValue != name && !isDuplicate && !isReservedName) {
            onChangeName(name);
          } else {
            setName(defaultValue);
          }
        }}
        onKeyDown={(e: any) => {
          if (e.type === 'keydown' && e.keyCode === keys.enter) {
            e.target.blur();
          }
        }}
        value={name}
        onChange={(e: any) => setName(e.target.value)}
        InputProps={{ disableUnderline: true }}
        helperText={helperText}
      />
    );
  }
  return (
    <div>
      <span style={{ marginRight: 5 }}>{name}</span>
      {isSelected && (
        <i className="fa fa-pencil" style={{ color: TEAL_PRIMARY, marginRight: 5 }} onClick={() => setEditable(true)} />
      )}
      {isSelected && (
        <i
          className="fa fa-trash"
          style={{}}
          onClick={(e: any) => {
            e.stopPropagation();
            deleteTab();
          }}
        />
      )}
    </div>
  );
};

const HorizontalTabs = (props: HorizontalTabs) => {
  const { tabs, defaultTabs, selectedTabIndex, onTabsChange, onSelectionChange } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const addNewTab = () => {
    const newTabs = tabs.slice(0);
    let name = 'Created Tab';
    let x = 1;
    // Make sure created tabs have non-overlapping names
    while (newTabs.map((x) => x.name).indexOf(name) > -1) {
      name = 'Created Tab ' + x;
      x++;
    }
    newTabs.push({
      id: makePathSlot(name),
      name: name,
      pathSlot: makePathSlot(name),
      disabled: false,
      leftNavSections: [],
      defaultSection: '',
      inPerspectives: [],
      isCustom: true,
    });
    onTabsChange && onTabsChange(newTabs);
  };

  const renameTab = (index: number, newName: string, isCustom?: boolean) => {
    const newTabs = tabs.slice(0);
    newTabs[index].name = newName;
    // Only change the id and pathSlot when renaming if it's a custom-made tab
    if (isCustom) {
      newTabs[index].id = makePathSlot(newName);
      newTabs[index].pathSlot = makePathSlot(newName);
    }
    onTabsChange && onTabsChange(newTabs);
  };

  const deleteTab = useCallback(() => {
    const newTabs = tabs.slice(0);
    newTabs.splice(selectedTabIndex, 1);
    onTabsChange && onTabsChange(newTabs);
    setDeleteModalOpen(false);
  }, [onTabsChange, selectedTabIndex, tabs]);

  return (
    <React.Fragment>
      <Tabs
        value={selectedTabIndex}
        onChange={(_e: any, newValue: number) => {
          onSelectionChange(newValue);
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        style={{ height: 60 }}
      >
        {tabs.map((tab, i) => {
          const isSelected = i == selectedTabIndex;
          const selectedStyles = isSelected ? { fontWeight: 600 } : {};
          return (
            <Tab
              style={{ ...tabStyle, ...selectedStyles }}
              className={tabClass}
              key={i}
              label={
                <EditableTabLabel
                  onChangeName={(newName: string) => renameTab(i, newName, tab.isCustom)}
                  deleteTab={() => setDeleteModalOpen(true)}
                  defaultValue={tab.name}
                  isSelected={isSelected}
                  availableNames={tabs.filter((x) => x.id != tab.id).map((x) => makePathSlot(x.name))}
                  reservedPathSlots={defaultTabs.filter((x) => x.id != tab.id).map((x) => x.pathSlot)}
                />
              }
            />
          );
        })}
        <Tab
          icon={<i className="fal fa-plus-circle" style={{ color: TEAL_PRIMARY }}></i>}
          style={{ ...tabStyle, width: 180, top: -7 }}
          className={addNewTabClass}
          label="Add New Tab"
          onClick={() => addNewTab()}
        />
      </Tabs>
      {deleteModalOpen && (
        <DeleteConfirmationModal
          item={{ tab: tabs[selectedTabIndex]?.name }}
          isOpen={deleteModalOpen}
          onDelete={deleteTab}
          onClose={() => setDeleteModalOpen(false)}
          deleteType={DeleteType.configuratorTab}
        />
      )}
    </React.Fragment>
  );
};

export default HorizontalTabs;
