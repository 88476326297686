import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem, RegionItem } from 'src/worker/pivotWorker.types';
import { PointOptions } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface MacroMixSlice {
  allConf?: TenantConfigViewData;
  chartData?: BasicPivotItem[];
  data?: BasicPivotItem[];
  dataLoaded: boolean;
  chartDataLoaded: boolean;
  confLoaded: boolean;
  dataOutOfData: boolean;
  selectedLevels?: TenantConfigViewItem;
  selectedBox?: PointOptions;
  regions?: RegionItem[];
}

interface ReceiveData {
  listData: BasicPivotItem[];
  regions: RegionItem[];
}

const initialState: MacroMixSlice = {
  dataLoaded: false,
  chartDataLoaded: false,
  dataOutOfData: true,
  confLoaded: false,
};

const macroMixSlice = createSlice({
  name: 'MacroMix',
  initialState,
  reducers: {
    requestViewConfig(state) {
      state.confLoaded = false;
    },
    receiveViewConfig(state, action: PayloadAction<TenantConfigViewData>) {
      state.allConf = action.payload;
      state.confLoaded = true;
    },
    requestMacroMixData(state) {
      state.dataLoaded = false;
    },
    receiveMacroMixData(state, action: PayloadAction<ReceiveData>) {
      state.dataLoaded = true;
      state.data = action.payload.listData;
      state.regions = action.payload.regions;
    },
    requestChartData(state) {
      state.chartDataLoaded = false;
    },
    receiveChartData(state, action: PayloadAction<BasicPivotItem[]>) {
      state.chartDataLoaded = true;
      state.chartData = action.payload;
    },
    selectBox(state, action: PayloadAction<PointOptions>) {
      state.selectedBox = action.payload;
    },
    updateLevels(state, action: PayloadAction<TenantConfigViewItem>) {
      state.selectedLevels = action.payload;
    },
    clearChartData(state) {
      state.chartDataLoaded = false;
      state.chartData = undefined;
      state.selectedBox = undefined;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
  },
});

export const {
  requestViewConfig,
  receiveViewConfig,
  requestMacroMixData,
  receiveMacroMixData,
  requestChartData,
  receiveChartData,
  selectBox,
  updateLevels,
  clearChartData,
  receiveError,
} = macroMixSlice.actions;

export default macroMixSlice.reducer;
