import {
  OwnProps,
  ValueProps,
  FunctionProps,
  CriteriaOption,
  CriteriaReduxSlice,
  ValidMembersData,
} from './Criteria.types';
import { findIndex, find, last, get, cloneDeep } from 'lodash/fp';
import { remove } from 'lodash';
import { Lens, fromTraversable } from 'monocle-ts';
import { update } from 'src/services/lenses/Lenses.actions';
import { connect } from 'react-redux';
import { Criteria } from './Criteria';
import { getValidMembers, getValidAttributes, getHierarchy } from './Criteria.client';
import { hasCustomGetter } from './Criteria.types';
import { toast } from 'react-toastify';
import { Traversable } from 'fp-ts/lib/Array';
import _ from 'lodash';
import { AppState, AppThunkDispatch } from 'src/store';

function push<T>(i: T, arr: T[]): T[] {
  arr.push(i);
  return arr;
}

function mapStateToProps(state: AppState, ownProps: OwnProps): ValueProps {
  const { selections, options } = ownProps.lens.get(state);
  const newSelections: any = selections;
  return {
    allSelected: _.isArray(newSelections[1]) && _.isArray(options[1]) && newSelections[1].length === options[1].length,
    ...ownProps.lens.get(state),
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): FunctionProps {
  const { lens } = ownProps;
  const optionsAndSelectionsLens = Lens.fromProps<CriteriaReduxSlice>()(['options', 'selections', 'dataApi']);
  const props = {
    onShowView: () => {
      dispatch(async (_innerDispatch, getState: () => AppState) => {
        const state = getState();
        const { options, selections, dataApi } = ownProps.lens.get(state);
        if (options != null && options.length > 0 && selections != null) {
          if (!hasCustomGetter(ownProps) && ownProps.filterByHierarchy && dataApi) {
            const parentId = ownProps.parentLevelLens.get(state);
            const hierarchy = await getHierarchy('product', dataApi, parentId || 'department');
            const optionsTraversal = fromTraversable(Traversable)<CriteriaOption[]>();
            const optionTraversal = fromTraversable(Traversable)<CriteriaOption>();
            const composedTrav = lens
              .compose(Lens.fromPath<CriteriaReduxSlice>()(['options']))
              .composeTraversal(optionsTraversal)
              .compose(optionTraversal);
            dispatch(
              update(
                composedTrav.modify((option) => {
                  if (hierarchy.indexOf(option.dataIndex) >= 0 && option.dataIndex !== parentId) {
                    return {
                      ...option,
                      disabled: true,
                    };
                  }
                  return {
                    ...option,
                    disabled: false,
                  };
                }),
                'Disabling options to high in hierarchy.'
              )
            );
            const newOptions = ownProps.lens.compose(Lens.fromProp<CriteriaReduxSlice>()('options')).get(getState());
            if (selections.length > 0) {
              const selection = newOptions[0].find((opt) => opt.dataIndex === selections[0].dataIndex);
              if (selection && !selection.disabled) {
                return;
              }
            }
            const firstAvailable = newOptions[0].find((opt) => !opt.disabled);
            if (firstAvailable != null) {
              return props.onOptionSelected(firstAvailable);
            }
          } else if (selections.length <= 0) {
            props.onOptionSelected(options[0][0]);
          }
        }
      });
    },
    selectFirstSeed: () => {
      dispatch((_innerDispatch, getState: () => AppState) => {
        const { options, selections } = ownProps.lens.get(getState());
        if (options != null && options.length > 0 && selections != null && selections.length <= 1) {
          props.onOptionSelected(options[1][0]);
        }
      });
    },
    onReset: () => {
      // TODO implement criteria reset
    },
    onOptionSelected: (selection: CriteriaOption) => {
      dispatch(async (_innerDispatch, getState: () => AppState) => {
        const state = getState();
        const currentRequest = lens.get(state).currentRequest;
        let optionIndex = -1;
        if (currentRequest) {
          await currentRequest;
        }
        const { selections, options } = lens.get(state);
        let newSelections: any = cloneDeep(selections);
        // TODO: This multiselect is going to be a mess, fix please
        // first find which option set:
        options.some((option, index) => {
          option.some((innOpt) => {
            if (innOpt.dataIndex === selection.dataIndex) {
              optionIndex = index;
              return true;
            }
            return false;
          });
          return optionIndex !== -1;
        });

        if (optionIndex === 1 && ownProps.showCheckAll) {
          if (get(1, selections) == null) {
            newSelections[1] = [selection];
          } else {
            if (
              findIndex((sel: CriteriaOption) => {
                return sel.dataIndex === selection.dataIndex;
              }, newSelections[1]) >= 0
            ) {
              remove(newSelections[1], (sel: CriteriaOption) => {
                return sel.dataIndex === selection.dataIndex;
              });
            } else {
              newSelections[1].push(selection);
            }
          }
          return _innerDispatch(
            update(lens.compose(Lens.fromProp<CriteriaReduxSlice>()('selections')).set(newSelections))
          );
        }

        const selected = findIndex(selection, selections);
        if (selected >= 0) {
          newSelections = selections;
          return;
        } else {
          const curOpts: CriteriaOption[][] = options;
          let ind = findIndex((opts) => {
            return find(selection, opts) != null;
          }, curOpts);
          if (ind < 0) {
            newSelections = selections.concat(selection);
            ind = 0;
          } else {
            newSelections = selections.slice(0, ind).concat(selection);
          }
          if (hasCustomGetter(ownProps)) {
            const newOptions = push(ownProps.getOptions(selection.dataIndex), options.slice(0, ind + 1));
            return _innerDispatch(
              update(lens.compose(optionsAndSelectionsLens).set({ selections: newSelections, options: newOptions }))
            );
          }
          let promise;
          if (selection.dataApi && selection.type) {
            if (selection.type === 'level') {
              let parent;
              try {
                parent = ownProps.parentIdLens.get(state) || undefined;
              } catch (e) {
                toast.error('Failed to select a top level.', {
                  position: toast.POSITION.TOP_LEFT,
                });
              }
              promise = getValidMembers({
                member: selection.dataIndex,
                parent,
                url: selection.dataApi.url,
              }).then((resp: ValidMembersData[]) => {
                const newOptions = resp.map((item: ValidMembersData) => {
                  return {
                    dataIndex: item.id,
                    text: item.description,
                  };
                });
                return push(newOptions, options.slice(0, ind + 1));
              });
            } else if (selection.type === 'attribute') {
              promise = getValidAttributes({
                member: selection.dataIndex,
                topLevel: ownProps.parentLevelLens.get(state),
                topLevelId: ownProps.parentIdLens.get(state),
                url: selection.dataApi.url,
              }).then((resp: ValidMembersData[]) => {
                const newOptions = resp.map((item: ValidMembersData) => {
                  return {
                    dataIndex: item.id,
                    text: item.description,
                  };
                });
                return push(newOptions, options.slice(0, ind + 1));
              });
            }
          }
          if (promise == null) {
            promise = Promise.resolve(options);
          }
          return promise.then((opts: CriteriaOption[][]) => {
            if (newSelections.length < opts.length) {
              const lastOpts = last(opts);
              if (lastOpts != null && lastOpts.length > 0) {
                if (last(opts) === get(1, opts) && ownProps.showCheckAll) {
                  newSelections.push([get(0, lastOpts)]);
                } else {
                  newSelections.push(get(0, lastOpts));
                }
              }
            }
            _innerDispatch(
              update(lens.compose(optionsAndSelectionsLens).set({ selections: newSelections, options: opts }))
            );
          });
        }
      });
    },
    selectAllRight: () => {
      dispatch((_innerDispatch, getState: () => AppState) => {
        const state = getState();
        const { selections, options } = lens.get(state);
        const arraySelections: any = selections;
        if (arraySelections[1] == null || arraySelections[1].length !== options[1].length) {
          const newSelections = [arraySelections[0], options[1]];
          dispatch(update(lens.compose(Lens.fromProp<CriteriaReduxSlice>()('selections')).set(newSelections)));
        } else {
          dispatch(update(lens.compose(Lens.fromProp<CriteriaReduxSlice>()('selections')).set([selections[0]])));
        }
      });
    },
  };
  return props;
}

export default connect(mapStateToProps, mapDispatchToProps)(Criteria);
