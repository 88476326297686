import { BoundTenant } from 'src/services/configuration/codecs/bindings.types';
import BoundTabZipper from 'src/pages/NavigationShell/NavigationZipper/BoundTabZipper';
import { Option, chain, map, fromNullable } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';

export default class BoundTenantZipper {
  readonly type: 'tenant' = 'tenant';
  private tenant: Readonly<BoundTenant>;

  constructor(tenant: Readonly<BoundTenant>) {
    this.tenant = tenant;
  }

  public getTenant(): Readonly<BoundTenant> {
    return this.tenant;
  }

  public getTab(id: string): Option<Readonly<BoundTabZipper>> {
    return pipe(
      fromNullable(this.tenant.boundTabs),
      chain((tabs) => fromNullable(tabs.find((tab) => tab.id === id))),
      map((tab) => new BoundTabZipper(this, tab))
    );
  }

  public getTabs(): readonly BoundTabZipper[] {
    return this.tenant.boundTabs.map((tab) => new BoundTabZipper(this, tab));
  }
}
