import Axios from 'src/services/axios';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import {
  AssortmentFavorite,
  FavoriteListItem,
  FavoriteResponse,
} from 'src/components/Subheader/Favorites/Favorites.types';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { provideAppName } from 'src/utils/Domain/Perspective';
import { isEmpty } from 'lodash';
import { AssortmentResponse } from 'src/worker/pivotWorker.types';

export const postAssortmentFavorite = (defnId: string, favorite: AssortmentFavorite): Promise<void> => {
  return provideAppName((appName) => {
    try {
      return Axios.post(`/api/favorites/by-path/${defnId}/${favorite.id}?appName=${appName}&active=${true}`, favorite);
    } catch (e) {
      toast.error('An error occured saving your favorites');
      ServiceContainer.loggingService.error('An error occured saving a favorite', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};

export const getAssortmentFavoriteByDefnId = (defnId: string): Promise<FavoriteResponse[]> => {
  return provideAppName(async (appName) => {
    try {
      const response = await Axios.get<AssortmentResponse<FavoriteResponse[]>>(
        `/api/favorites/by-path/${defnId}?appName=${appName}`
      );
      return response.data.data;
    } catch (e) {
      toast.error('An error occured fecthing your favorites');
      ServiceContainer.loggingService.error('An error occured fetching a favorite', (e as Error).stack);
      return Promise.reject();
    }
  });
};

export const clearActiveAssortmentFavorite = (defnId: string): Promise<void> => {
  return provideAppName((appName) => {
    try {
      return Axios.post(`/api/favorites/by-path/${defnId}/clearActives?appName=${appName}`);
    } catch (e) {
      toast.error('An error occured clearing favorite actives');
      ServiceContainer.loggingService.error('An error occured clearing favorite actives', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};

export const setActiveAssortmentFavorite = (defnId: string, favItemId: string): Promise<void> => {
  return provideAppName((appName) => {
    try {
      return Axios.post(`/api/favorites/by-path/${defnId}/${favItemId}/setActive?appName=${appName}`);
    } catch (e) {
      toast.error('An error occured setting favorite actives');
      ServiceContainer.loggingService.error('An error occured setting favorite actives', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};

/** @deprecated see postAssortmentFavorite, this will be removed after favorites improvements */
export const postFavorite = async (
  viewConfigurator: ViewConfiguratorModalProps,
  newFavItem: FavoriteListItem,
  active: boolean
) => {
  const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
  const isActive = active ? true : false;
  return provideAppName(async (appName) => {
    try {
      return Axios.post(`/api/favorites/by-path/${favId}/${newFavItem.id}?appName=${appName}&active=${isActive}`, {
        ...newFavItem,
      });
    } catch (e) {
      toast.error('An error occured saving your favorites');
      ServiceContainer.loggingService.error('An error occured saving a favorite', errorToLoggingPayload(e));
      return Promise.reject(); // TODO: verify this is okay, was previously resolved
    }
  });
};

/** @deprecated see getAssortmentFavoriteByDefnId, this will be removed after favorites improvements */
export const getFavoriteByDefnId = async (defnId: string) => {
  return provideAppName(async (appName) => {
    return Axios.get(`/api/favorites/by-path/${defnId}?appName=${appName}`).catch((e) => {
      toast.error('An error occured fecthing your favorites');
      ServiceContainer.loggingService.error('An error occured fetching a favorite', e.stack);
    });
  });
};

export const deleteFavorite = (key: string) => {
  return Axios.delete(`/api/favorites/by-key/${key}`);
};

/** @deprecated see clearActiveAssortmentFavorite, this will be removed after favorites improvements */
export const clearFavoriteActives = async (favId?: string) => {
  return provideAppName(async (appName) => {
    if (isEmpty(favId)) return Promise.reject();
    try {
      return Axios.post(`/api/favorites/by-path/${favId}/clearActives?appName=${appName}`);
    } catch (e) {
      toast.error('An error occured clearing favorite actives');
      ServiceContainer.loggingService.error('An error occured clearing favorite actives', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};

/** @deprecated see setActiveAssortmentFavorite, this will be removed after favorites improvements */
export const setFavoriteActive = async (viewConfigurator: ViewConfiguratorModalProps, favItem: FavoriteListItem) => {
  return provideAppName(async (appName) => {
    const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
    try {
      return Axios.post(`/api/favorites/by-path/${favId}/${favItem.id}/setActive?appName=${appName}`);
    } catch (e) {
      toast.error('An error occured setting favorite actives');
      ServiceContainer.loggingService.error('An error occured setting favorite actives', errorToLoggingPayload(e));
      return Promise.reject();
    }
  });
};
