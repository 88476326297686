import Axios from 'src/services/axios';
import { pickBy, flatten, isNil } from 'lodash';
import { ASSORTMENT, IMG_URI } from 'src/utils/Domain/Constants';
import { uploadImage, watermarkAndUpload } from 'src/utils/Images/ImageUpload.client';
import { LifecycleStoreData } from 'src/components/LifecycleStoreModal/LifecycleStoreModal';
import { resolvePath } from 'src/cdn';
import { toastAndLog } from 'src/services/loggingService';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
const noImage = resolvePath(noImagePath);

const SAVECART = `api/assortment/addToAssortment?appName=${ASSORTMENT}`;

export interface AddItemsToAssortmentParams {
  items: BasicPivotItem[];
  lifecycleStoreData?: LifecycleStoreData;
  conceptPowerDrivers?: BasicPivotItem[];
}

interface StoreCountData {
  [key: string]: string[];
}

function AssortmentClientFactory() {
  const factory = {
    addItemsToAssortment: async ({ items, lifecycleStoreData }: AddItemsToAssortmentParams): Promise<void> => {
      items.forEach((style) => {
        style.children.forEach((sc) => {
          sc.type = sc.type || style.type || 'existing';
        });
        style.children = style.children.filter(
          (sc) => sc['attribute:isassortment:id'] !== 'true' || style.type === 'similar'
        );
      });
      const styleItems = items
        .map((style) => {
          return {
            ...pickBy(style, (v, k) => ['id', 'name', 'description', 'type'].indexOf(k) >= 0),
            children: style.children
              .map((sc) =>
                pickBy(
                  sc,
                  (v, k) =>
                    ['id', 'name', 'description', 'type', 'attribute:cccolor:id', 'attribute:img:id'].indexOf(k) >= 0
                )
              )
              .map((sc) => {
                if (sc.type === 'concept') {
                  sc.type = 'similar';
                }
                return sc;
              }),
          };
        })
        .map((style: any) => {
          if (style.type === 'concept') {
            style.type = 'similar';
          }
          return style;
        });
      const newSimilar = flatten(
        styleItems.map((style: { children: any[] }) => {
          return style.children.filter((child) => child.type === 'similar');
        })
      );
      await Promise.all(
        newSimilar.map(async (sc: any) => {
          let imageUrl: string = sc[IMG_URI];
          if (isNil(imageUrl)) {
            imageUrl = await watermarkAndUpload(noImage);
          } else if (sc.isWatermarked) {
            imageUrl = await uploadImage(sc[IMG_URI]);
          } else {
            imageUrl = await watermarkAndUpload(sc[IMG_URI]);
          }
          if (imageUrl) {
            sc[IMG_URI] = imageUrl.replace(/generation=[0-9]*&/, '');
          }
        })
      );
      const toPost = {
        lifecycle: lifecycleStoreData ? lifecycleStoreData.lifecycleData : null,
        StoreEligibility: lifecycleStoreData ? lifecycleStoreData.storeData : null,
        styles: styleItems,
      };

      try {
        await Axios.post(SAVECART, toPost);
        toastAndLog('Items successfully added to the assortment plan');
      } catch (error: any) {
        toast.error(`An error occurred adding your items to the assortment`);
        ServiceContainer.loggingService.error(
          `addItemsToAssortment error: ${errorToLoggingPayload(error)}`,
          error.stack
        );
      }
    },
    calculateStoreCount: async (productId: string, floorsetId: string, storeCountData: StoreCountData[]) => {
      const requestParams = {
        appName: ASSORTMENT,
        floorsetId,
        productId,
      };

      const resp = await Axios.post('/api/assortment/calculateStoreCount', storeCountData, { params: requestParams });

      const storeCount = resp.data.data;
      return storeCount;
    },
  };
  return factory;
}

export const AssortmentClient = AssortmentClientFactory();
