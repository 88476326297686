import React from 'react';
import { isEmpty, isNil } from 'lodash';

import { Switch, Tooltip, withStyles } from '@material-ui/core';
import { ParameterTogglesFieldBoolean } from 'src/pages/Allocation/ParameterToggles/ParameterToggles.types';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import { getTooltipTitle } from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails';
import DividedColumnDetailsDropdown from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetailsDropdown';

import columnDetailStyles from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetails.styles';
import styles from 'src/pages/Allocation/ParameterToggles/ParameterToggles.styles';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.common.black,
    backgroundColor: '#f4f3f4',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

type SectionToggleProps = Omit<ParameterTogglesFieldBoolean, 'editor' | 'dataIndex'> & {
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const SectionToggle = ({ text, infoText, isChecked, onChange }: SectionToggleProps) => {
  return (
    <div className={styles.sectionParameter}>
      <div className="text-container">
        <div className="text">{text}</div>
        {infoText && !isEmpty(infoText) && (
          <LightTooltip title={infoText}>
            <i className="fal fa-info-circle" />
          </LightTooltip>
        )}
      </div>
      <Switch checked={isChecked} onChange={onChange}  data-qa="switch-button"/>
    </div>
  );
};

type SectionDropdownProps = Omit<ParameterTogglesFieldBoolean, 'editor'> & {
  id: string;
  selection: string;
  multiSelect?: boolean;
  inputHeight: number;
  dataQa?: string;
  onDropdownChange: (selection: { value: string; label: string }[]) => void;
};

export const SectionDropdown = ({
  id,
  selection,
  multiSelect,
  onDropdownChange,
  inputHeight,
  dataQa,
  ...field
}: SectionDropdownProps) => {
  if (isNil(field.dataApi)) {
    return <div />;
  }

  const processedDataApi = processApiParams(field.dataApi, { id });
  const formattedValue = arrayStringToArray(selection, true);
  // empty tooltip values won't trigger tooltip
  const tooltipTitle: string | string[] | React.ReactNode = getTooltipTitle(formattedValue);

  return (
    <div className={styles.sectionParameter}>
      <div className="text-container">
        <div className="text">{field.text}</div>
        {field.infoText && !isEmpty(field.infoText) && (
          <LightTooltip title={field.infoText}>
            <i className="fal fa-info-circle" />
          </LightTooltip>
        )}
      </div>
      <Tooltip
        className={columnDetailStyles.dataItem}
        data-qa="multiselect-tooltip"
        title={tooltipTitle}
        placement="right"
      >
        <DividedColumnDetailsDropdown
          dataQa={dataQa || `params-toggle-select`}
          styleId={''} // unused
          value={selection}
          dataApi={processedDataApi}
          multiSelect={multiSelect}
          renderIdOnly={true}
          allowEmptyOption={false}
          handleDropdownChange={onDropdownChange}
          inputParams={{ height: inputHeight }}
        />
      </Tooltip>
    </div>
  );
};
