import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { style, classes } from 'typestyle';
import { isString, has, isEqual, isNil } from 'lodash';

const CLEAR_ELEMENT_ID = 'clearValueButton';

type Props = ICellRendererParams & {
  isDataTransposed: boolean;
};
type State = Record<string, any>;

const containerStyles = style({
  display: 'flex',
});

const valueStyles = style({
  overflowX: 'hidden',
  marginRight: '0.5rem',
});

const iconStyles = style({
  color: 'red',
  fontWeight: 400,
  $nest: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default class ClearValueCellRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // need to stop event propagation on the actual cell element if necessary
    this.props.eGridCell.addEventListener('click', this.updateCellValue);
  }

  componentWillUnmount() {
    this.props.eGridCell.removeEventListener('click', this.updateCellValue);
  }

  updateCellValue = (event: MouseEvent) => {
    const targetId = event.target ? (event.target as HTMLElement).id : '';
    if (targetId === CLEAR_ELEMENT_ID) {
      event.stopPropagation();
      const { node, column, api, isDataTransposed } = this.props;
      if (isDataTransposed && !isNil(column)) {
        column.getColId();
        node.setDataValue(column.getColId(), ''); // TODO: may need to set to different types here
      } else {
        node.data[node.data.id] = '';
      }
      api.refreshCells();
    }
  };

  render() {
    let value: string;
    if (isString(this.props.value)) {
      value = this.props.value;
    } else if (has(this.props.value, 'weekNo')) {
      value = this.props.value.weekNo;
    } else {
      value = '';
    }
    return (
      <div className={containerStyles}>
        <span className={valueStyles}>{value}</span>
        {!isEqual(value, '') ? <i id={CLEAR_ELEMENT_ID} className={classes('fas fa-times', iconStyles)} /> : null}
      </div>
    );
  }
}
