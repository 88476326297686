import * as React from 'react';
import { Button } from '@material-ui/core';
import { RouteLink } from 'src/types/RouteLink';
import styles, { searchMediaStyles } from './TopNavbar.styles';
import { Typeahead, Highlighter, TypeaheadMenuProps } from 'react-bootstrap-typeahead';
import { mediumBreakpoint } from 'src/utils/Style/Theme';
import { Navigate } from 'react-router-dom';
import { join, isString } from 'lodash';
import { Option } from 'react-bootstrap-typeahead/types/types';
export interface TopNavSearchProps {
  routeLinks: RouteLink[];
  onEnter: () => void;
  onLeave: () => void;
}

export interface TopNavSearchState {
  minLength: number;
  selectHintOnEnter: boolean;
  newPath: string;
  selectedOption: RouteLink | null;
}

const MINIMUM_CHARACTERS_REQUIRED = 0;

export class UnmemoizedTopNavSearch extends React.Component<TopNavSearchProps, TopNavSearchState> {
  constructor(props: TopNavSearchProps) {
    super(props);
    this.state = {
      minLength: MINIMUM_CHARACTERS_REQUIRED,
      selectHintOnEnter: true,
      newPath: '',
      selectedOption: null,
    };
  }

  handleInputChange = (_event: React.FormEvent<HTMLFormElement>) => {
    const { selectedOption } = this.state;
    if (selectedOption && selectedOption.link) {
      this.triggerNavigation(selectedOption.link);
    }
  };

  triggerNavigation = (path: string) => {
    if (path) {
      this.setState({
        newPath: path,
      });
      this.props.onLeave();
    }
  };

  renderMenuItemChildren = (option: Option, menuProps: TypeaheadMenuProps) => {
    if (isString(option)) return <React.Fragment />;
    const routeLink = option as RouteLink;
    const breadcrumb = routeLink.highlightPrefix.split('/').slice(2);
    return (
      <React.Fragment>
        <Highlighter key="name" search={menuProps.text}>
          {routeLink.name}
        </Highlighter>
        ,
        <div key="breadcrumb">
          <small>{breadcrumb.length > 1 && join(breadcrumb, ' > ')}</small>
        </div>
      </React.Fragment>
    );
  };

  selectTypeahead = (selected: Option[]) => {
    if (!isString(selected)) {
      const optionSelected = selected as RouteLink[];
      this.setState(
        {
          selectedOption: optionSelected[0],
        },
        () => {
          const newSelectedOption = optionSelected[0] || this.state.selectedOption;
          if (newSelectedOption) {
            this.triggerNavigation(newSelectedOption.link);
          }
        }
      );
    }
  };

  render() {
    const { newPath } = this.state;
    const { routeLinks } = this.props;
    const realRoutes = routeLinks.filter((route) => !route.disabled);

    if (newPath) {
      this.setState({
        newPath: '',
        selectedOption: null,
      });
      return <Navigate to={newPath} />;
    }

    const addonClass = `navbar-brand ${styles.TopNavSearchIconContainer}`;

    return (
      <form onSubmit={this.handleInputChange} data-qa="TopNavSearch" className={searchMediaStyles}>
        <div className={addonClass}>
          <i className="fas fa-search" data-qa="TopNavSearchIcon" />
        </div>
        <div className={styles.TopNavSearch} data-qa="TopNavSearchInput">
          <Typeahead
            {...this.state}
            id={'search'}
            labelKey="name"
            align={window.innerWidth < mediumBreakpoint ? 'right' : undefined}
            onChange={this.selectTypeahead}
            onFocus={this.props.onEnter}
            onBlur={this.props.onLeave}
            renderMenuItemChildren={this.renderMenuItemChildren}
            options={realRoutes}
            selectHint={(shouldSelect, event) => event.key === 'Enter' || shouldSelect}
            placeholder="Search actions, views, menu items..."
          />
          <Button type="submit" data-qa="TopNavSearchButton" hidden={true} />
        </div>
      </form>
    );
  }
}

export default React.memo(UnmemoizedTopNavSearch);
