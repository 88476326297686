import { isEmpty, isNil, find, partial, omit, every } from 'lodash';
import { ExcelExportParams, ProcessCellForExportParams, GridApi, CsvExportParams } from '@ag-grid-community/core';
import { Renderer } from 'src/utils/Domain/Renderer';
import { TargetSettingConfigColumn } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.types';

export type GridExportType = 'csv' | 'excel';

function checkConfigsRoot(dataIndex: string, columnConfigs: TargetSettingConfigColumn[]) {
  return find(columnConfigs, (rootConfig) => rootConfig.dataIndex === dataIndex);
}

function checkConfigsChildren(dataIndex: string, columnConfigs: TargetSettingConfigColumn[]) {
  let childConfig: TargetSettingConfigColumn | undefined;

  for (let index = 0; index < columnConfigs.length; index++) {
    const rootConfig = columnConfigs[index];

    if (isNil(rootConfig.children)) {
      continue;
    } else if (isNil(childConfig)) {
      childConfig = find(rootConfig.children, (child) => child.dataIndex === dataIndex);
    }

    if (!isNil(childConfig)) {
      // found config in child
      break;
    }
  }

  return childConfig;
}

function getCellColumnConfig(
  dataIndex: string,
  columnConfigs: TargetSettingConfigColumn[]
): TargetSettingConfigColumn | null {
  if (isEmpty(columnConfigs)) {
    return null;
  }

  // check column config's root (and children if applicable) for matching dataIndex
  const rootConfig = checkConfigsRoot(dataIndex, columnConfigs);
  const childConfig = checkConfigsChildren(dataIndex, columnConfigs);
  const dataIndexColumnConfig = rootConfig || childConfig;
  return !isNil(dataIndexColumnConfig) ? dataIndexColumnConfig : null;
}

function getCellRendererType(dataIndex: string, columnConfigs: TargetSettingConfigColumn[]): string {
  if (isEmpty(columnConfigs)) {
    return '';
  }

  const dataIndexColumnConfig = getCellColumnConfig(dataIndex, columnConfigs);
  return !isNil(dataIndexColumnConfig) ? dataIndexColumnConfig.renderer || '' : '';
}

function processCellCallback(columnConfigs: TargetSettingConfigColumn[], params: ProcessCellForExportParams) {
  // get dataIndex and renderer for current node for proper value formatting
  const { column, value } = params;
  const dataIndex = column.getColId();
  const rendererType = getCellRendererType(dataIndex, columnConfigs);
  const rendererFunction = !isEmpty(rendererType) ? Renderer[rendererType] : undefined;
  const cellValue = !isNil(rendererFunction) ? rendererFunction(value) : value;
  return cellValue;
}

export function getExportOptions(columnConfigs: TargetSettingConfigColumn[]): ExcelExportParams | CsvExportParams {
  return {
    processCellCallback: partial(processCellCallback, columnConfigs),
  };
}

export function onGridExport(
  exportType: GridExportType,
  gridApi: GridApi | null,
  columnConfigs: TargetSettingConfigColumn[] = []
) {
  if (exportType === 'excel') {
    const excelOptions = getExportOptions(columnConfigs) as ExcelExportParams;
    gridApi?.exportDataAsExcel(excelOptions);
  } else {
    const csvOptions = getExportOptions(columnConfigs) as CsvExportParams;
    gridApi?.exportDataAsCsv(csvOptions);
  }
}

export function isGridApiReady(gridApi: GridApi | null) {
  if (isNil(gridApi)) {
    return false;
  }
  // destroyCalled is a private boolean so we need to omit it for the Api check
  const apiKeys = omit(gridApi, ['destroyCalled']);
  // if the api has values & they are all undefined the gridApi is not loaded
  const unloadedApi = every(apiKeys, (k) => isNil(k));
  return !unloadedApi;
}
