import * as dropdownStyles from 'src/utils/Style/Dropdown.styles';
import { classes, style, keyframes } from 'typestyle';
import * as scopeSelectorStyles from '../AssortmentScopeSelector.styles';
import { PICKER_WIDTH } from '../AssortmentScopeSelector.styles';

const titleOverrides = style({
  cursor: 'pointer',
  marginTop: 2,
  marginLeft: '0.5em',
  paddingTop: 7,
});

const loadingAnimation = keyframes({
  '0%': { width: 0 },
  '70%': { width: '100%', opacity: 1 },
  '90%': { opacity: 0, width: '100%' },
  '100%': { opacity: 0, width: 0 },
});

export const loadingText = style({
  position: 'relative',
  width: PICKER_WIDTH,
  height: 19,
  left: 'calc(50% - 72px)',
  top: 7,
  $nest: {
    '&::after': {
      content: `'Loading...'`,
      color: 'white',
      position: 'absolute',
      width: '100%',
      height: 19,
      left: 6,
      top: 0,
      zIndex: 1,
    },
    '&::before': {
      content: `' '`,
      position: 'absolute',
      backgroundColor: '#5BCFC7',
      top: -1,
      left: 0,
      height: 20,
      width: 0,
      zIndex: 0,
      opacity: 1,
      transformOrigin: '100% 0%',
      animationDuration: '7.5s',
      animationName: loadingAnimation,
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
    },
  },
});

const bounceballFrames = keyframes({
  '0%': {
    top: 30,
    height: 5,
    borderRadius: '60px 60px 20px 20px',
    transform: 'scaleX(2)',
  },
  '35%': {
    height: 15,
    borderRadius: '50%',
    transform: 'scaleX(1)',
  },
  '100%': {
    top: 0,
  },
});

export const bounceball = style({
  position: 'relative',
  display: 'inline-block',
  height: 37,
  width: 15,
  $nest: {
    '&::before': {
      position: 'absolute',
      content: `' '`,
      display: 'block',
      top: 0,
      width: 15,
      height: 15,
      borderRadius: '50%',
      backgroundColor: '#fbae17',
      transformOrigin: '50%',
      animationDuration: '500ms',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease',
      animationDirection: 'alternate',
      animationName: bounceballFrames,
    },
  },
});

export const floorsetDropdownTitle = classes(
  titleOverrides,
  dropdownStyles.formLabel,
  scopeSelectorStyles.default.scopeSelectorDropdownSmall
);
