import { style } from 'typestyle';

export default {
  container: style({
    $debugName: 'star-percent-renderer',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '6px',
    $nest: {
      ['.star-percent-text']: {
        marginLeft: '0.5em',
        marginTop: '3px',
      },
    },
  }),
};
