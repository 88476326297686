import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'src/store';
import { z } from 'zod';
import { RouteToLocationComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import QueryString from 'query-string';

export interface RouteToLocationOwnProps extends z.infer<typeof RouteToLocationComponentProps> {}

export interface RouteToLocationStateProps {
  url: string;
}

export interface RouteToLocationDispatchProps {
  onShowView: (url: string) => void;
}

export interface RouteToLocationProps extends RouteToLocationDispatchProps, RouteToLocationStateProps {}

function mapStateToProps(state: AppState, ownProps: RouteToLocationOwnProps): RouteToLocationStateProps {
  const { dataApi } = ownProps;
  const newParams = {};
  for (const param in dataApi.params) {
    if (state.scope.selections[dataApi.params[param]]) {
      newParams[param] = state.scope.selections[dataApi.params[param]];
    }
  }
  const query = QueryString.stringify({ ...dataApi.params, ...newParams });
  return {
    url: `${dataApi.url}?${query}`,
  };
}

function dispatchToProps(): RouteToLocationDispatchProps {
  return {
    onShowView: async (url: string) => {
      // Example redirect string ?facet=bu&location=GP-01&prodlife=FP&product=DP-0078-S01&time=2021_W40
      window.location.href = url;
      return;
    },
  };
}

const RouteToLocation = ({ onShowView, url }: RouteToLocationProps) => {
  useEffect(() => {
    onShowView(url);
  }, [onShowView, url]);

  return <div>Redirecting</div>;
};

export default connect(mapStateToProps, dispatchToProps)(RouteToLocation);
