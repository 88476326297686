import { style, media } from 'typestyle';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';

export const TREND_UP_COLOR = '#00FF00';
export const TREND_DOWN_COLOR = '#ff0001';
export const NO_TREND_COLOR = '#ffd931';

export const MAP_BUBBLE_MIN_SIZE = '1%';
export const MAP_BUBBLE_MAX_SIZE = '15%';

const geoTrendsContainer = style({
  $debugName: 'geoTrendsContainer',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const fullHeightGridItem = style(
  media(
    { minWidth: 0, maxWidth: 1500 },
    // -100px for padding on small screens
    { height: `calc(100% - ${SUBHEADER_HEIGHT}px - 100px)` }
  ),
  {
    $debugName: 'fullHeightGridItem',
    height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
  }
);
const fullWidthHalfHeightGridItem = style(media({ minWidth: 0, maxWidth: 1500 }, { height: '50%' }), {
  $debugName: 'fullWidthHalfHeightGridItem',
  width: '100%',
});

const chartContainer = style({
  $debugName: 'geotrends-simple-chart',
  height: '100%',
  position: 'relative',
});

const dropDownMapChart = style({
  position: 'absolute',
  zIndex: 9,
  right: 10,
  top: 5,
});

export default {
  geoTrendsContainer,
  fullHeightGridItem,
  fullWidthHalfHeightGridItem,
  chartContainer,
  dropDownMapChart,
};
