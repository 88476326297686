import React from 'react';
import styles from './HistoryStylePane.styles';
import { DispatchProps, StateProps } from './HistoryStylePane.container';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import Renderer from 'src/utils/Domain/Renderer';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import { ColorSwatchItemProps } from 'src/common-ui/components/ColorSwatch/ColorSwatchItem';
import { STYLE_ID, CC_COLOR, STYLE_DESCRIPTION, STYLE_NAME, STYLE_COLOR_NAME } from 'src/utils/Domain/Constants';
import { classes } from 'typestyle';
import HistoryStylePaneCollapsableGroup from 'src/components/HistoryStylePane/HistoryStylePaneCollapsableGroup';

import { resolvePath } from 'src/cdn';
import ValidOptions from 'src/components/ValidOption/ValidOptions';
import { STYLE_COLOR_ID } from '../../utils/Domain/Constants';
import { size, isString, isEmpty, isNil } from 'lodash';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import { getSwatchUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import ServiceContainer from 'src/ServiceContainer';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import CenteredImage from '../CenteredImage/CenteredImage';

const noImage = resolvePath(noImagePath);

type PropValue = any;

export interface RequiredData {
  [STYLE_ID]: string;
  id: string;
}
interface Props extends StateProps, DispatchProps {
  dataId: string;
  styleColorId: string;
  styleColorName?: string;
  isAssortmentBuild: boolean;
  onDescriptionReady: (description: string) => void;
  onTitleReady: (title: string) => void;
  onDataReady: (data: BasicPivotItem[]) => void;
}
interface State {
  selectedPosition: number;
  defaultPosition: number;
}

const isMissingColorId = (swatch: ColorSwatchItemProps) => isNil(swatch.colorId) || isEmpty(swatch.colorId);

export default class HistoryStylePane extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props) {
    const { currentItem, styleColorId } = props;
    let defaultPos = 0;
    if (currentItem !== null) {
      const position = currentItem.findIndex((item) => item.id === styleColorId);
      if (position > -1) {
        defaultPos = position;
      } else if (!isEmpty(props.styleColorName)) {
        const idx = currentItem.findIndex((x) => x[STYLE_COLOR_NAME] === props.styleColorName);
        defaultPos = idx;
      }
    }
    return {
      defaultPosition: defaultPos,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPosition: -1,
      defaultPosition: 0,
    };
  }

  componentDidMount() {
    const { dataId, onShowHistoryStylePane, isAssortmentBuild } = this.props;
    onShowHistoryStylePane(dataId, isAssortmentBuild);
  }

  componentDidUpdate(prevProps: Props) {
    const { dataId, currentItem, onShowHistoryStylePane, moveItemToHistory, isAssortmentBuild } = this.props;
    if (prevProps.dataId !== dataId) {
      if (currentItem !== null && currentItem.length !== 0) {
        moveItemToHistory(dataId, currentItem);
      }
      onShowHistoryStylePane(dataId, isAssortmentBuild);
    }
  }

  getCurrentPosition = () => {
    const { selectedPosition, defaultPosition } = this.state;
    const { currentItem } = this.props;
    let position = defaultPosition;
    if (selectedPosition >= 0) {
      position = selectedPosition;
    }
    if (currentItem && position > currentItem.length) {
      // state can be left over from the last style loaded
      // fallback here if position is high than current length
      position = defaultPosition;
    }
    return position;
  };

  buildSwatches = (items: BasicPivotItem[] | null) => {
    let swatches: ColorSwatchItemProps[] = [];
    if (items === null) {
      return swatches;
    }
    swatches = items.map((item, index) => {
      return {
        id: item[STYLE_COLOR_ID],
        colorId: item[CC_COLOR],
        noImageUrl: noImage,
        swatchPath: `${getSwatchUrl(item[CC_COLOR])}`,
        selected:
          this.state.selectedPosition > -1
            ? index === this.state.selectedPosition
            : index === this.state.defaultPosition,
      };
    });

    return swatches;
  };

  handleClickOnSwatch = (id: string, position: number) => {
    this.setState({
      selectedPosition: position,
    });
  };

  xtypeRenderer(data: BasicPivotItem, viewDefnSlice: TenantConfigViewItem) {
    let renderedJsx;

    switch (viewDefnSlice.xtype) {
      case 'swatches':
        const swatches = this.buildSwatches(this.props.currentItem);
        const isMissingColorIds = swatches.some(isMissingColorId);
        if (isMissingColorIds) {
          const colorlessIds = swatches
            .filter(isMissingColorId)
            .map((swatch) => swatch.id)
            .join(',');
          ServiceContainer.loggingService.info(
            `No '${CC_COLOR}' value for following style color item(s): ${colorlessIds}`
          );
        }

        renderedJsx = (
          <div>
            {Renderer.colorSwatch(
              swatches,
              this.handleClickOnSwatch,
              this.getCurrentPosition(),
              styles.historyStylePaneSwatches
            )}
          </div>
        );
        break;
      case 'validoptions':
        const selection = data[viewDefnSlice.dataIndex];
        const convertedSelection: string[] = isString(selection) ? arrayStringToArray(selection, false) : selection;
        const formattedSelection = !isEmpty(convertedSelection)
          ? convertedSelection.map((item) => item.replace(/"/g, ''))
          : [];
        renderedJsx = <ValidOptions editable={false} selected={formattedSelection} />;
        break;
      case 'image':
        const imgSrc: string = data[viewDefnSlice.dataIndex] || noImage;
        renderedJsx = (
          <div data-qa={`${viewDefnSlice.dataIndex}-value`}>
            <CenteredImage src={imgSrc} width={styles.STYLE_PANE_IMG_WIDTH} height={styles.STYLE_PANE_IMG_HEIGHT} />
          </div>
        );
        break;
      case 'array':
        const propValue = data[viewDefnSlice.dataIndex];
        let renderedValues: any[] = [];
        if (Array.isArray(propValue)) {
          renderedValues = propValue.map((value: PropValue) => Renderer.renderJustValue(value, viewDefnSlice));
        }
        renderedJsx = <div data-qa={`${viewDefnSlice.dataIndex}-value`}>{renderedValues.join(', ')}</div>;
        break;
      case 'string':
      default:
        const renderedValue = Renderer.renderValue(data, viewDefnSlice);
        if (!renderedValue) {
          renderedJsx = (
            <div data-qa={`${viewDefnSlice.dataIndex}-value`} dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
          );
        } else {
          renderedJsx = <div data-qa={`${viewDefnSlice.dataIndex}-value`}>{renderedValue}</div>;
        }
    }
    return renderedJsx;
  }

  generateSection = (data: BasicPivotItem, viewDefnSlice: TenantConfigViewItem, indexValue: number) => {
    if (viewDefnSlice.columns) {
      const subcontent: JSX.Element[] = viewDefnSlice.columns.map((slice, index) => {
        return this.generateSection(data, slice, index);
      });
      return (
        <HistoryStylePaneCollapsableGroup key={indexValue} indexValue={indexValue} viewDefnSlice={viewDefnSlice}>
          {subcontent}
        </HistoryStylePaneCollapsableGroup>
      );
    }
    let containerChildren;
    let specialClassName;
    const jsx = this.xtypeRenderer(data, viewDefnSlice);

    if (viewDefnSlice.style === 'title') {
      specialClassName = styles.historyStylePaneTitle;
    } else if (viewDefnSlice.style === 'description') {
      specialClassName = styles.historyStylePaneDescription;
    } else if (viewDefnSlice.xtype === 'swatches') {
      specialClassName = styles.historyStylePaneSwatchContainer;
    }

    if (viewDefnSlice.text) {
      containerChildren = (
        <React.Fragment>
          <div className={styles.historyStylePaneLabel}>
            <div className={styles.historyStylePaneLabelText} data-qa={`${viewDefnSlice.dataIndex}-text`}>
              {viewDefnSlice.text || ''}
            </div>
          </div>
          <div className={styles.historyStylePaneValue}>{jsx}</div>
        </React.Fragment>
      );
    } else if (
      viewDefnSlice.dataIndex !== STYLE_ID &&
      viewDefnSlice.dataIndex !== STYLE_NAME &&
      viewDefnSlice.dataIndex !== STYLE_DESCRIPTION
    ) {
      containerChildren = jsx;
    } else {
      // no longer showing title or desc
      containerChildren = null;
    }

    return (
      <div
        key={indexValue}
        className={classes(styles.historyStylePaneTuple, specialClassName)}
        data-qa={viewDefnSlice.text}
      >
        {containerChildren}
      </div>
    );
  };

  generateStructure = (data: BasicPivotItem, viewDefn: TenantConfigViewData) => {
    return viewDefn.view.map((section, index) => {
      return this.generateSection(data, section, index);
    });
  };

  buildJsx = (data: BasicPivotItem[] | null, viewDefn: TenantConfigViewData) => {
    const { onDescriptionReady, onTitleReady, onDataReady } = this.props;
    if (data === null || size(data) < 1) {
      // clear prior selection
      onDescriptionReady('');
      onTitleReady('');
      onDataReady([]);
      return <div data-qa="StylePaneNoData">Pivot returned no data.</div>;
    }

    onDescriptionReady(data[0][STYLE_DESCRIPTION]);
    onTitleReady(data[0][STYLE_NAME]);
    onDataReady(data);

    const sections: JSX.Element[][] = [];
    data.forEach((item) => {
      sections.push(this.generateStructure(item, viewDefn));
    });
    if (sections.length === 0) {
      return <div data-qa="StylePaneNoData">No data returned.</div>;
    }
    const position = this.getCurrentPosition();
    return sections[position];
  };

  render() {
    const { loading, viewDefn, currentItem } = this.props;
    if (!viewDefn) {
      return <div />;
    }
    const content = this.buildJsx(currentItem, viewDefn);

    return (
      <div className={styles.historyStylePane} data-qa="StylePaneBodyContainer">
        <Overlay visible={loading} type="loading" />
        {content}
      </div>
    );
  }
}
