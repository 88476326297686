import { classes, style, media } from 'typestyle';
import { TEAL_PRIMARY } from '../../theme';

const IMGWIDTH = 150;
const TOPHEIGHT = IMGWIDTH * 1.5;
const CARD_PADDING = 10;

const swatches = style({
  $debugName: 'historyStylePaneSwatches',
  height: 30,
  width: 'max-content',
  display: 'inline-block',
  $nest: {
    div: {
      border: '1px solid #aca899',
      cursor: 'pointer',
      width: 20,
      height: 20,
      margin: '5px 3px',
      display: 'inline-block',
    },
  },
});

const card = style(
  media(
    { type: 'print' },
    {
      position: 'static',
    }
  ),
  {
    height: TOPHEIGHT + CARD_PADDING * 2,
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    justifyContent: 'space-between',
  }
);

const image = style({
  height: '100%',
  display: 'inline-table',
});

const description = style({
  textAlign: 'left',
  width: '100%',
  fontWeight: 600,
  maxHeight: '4.2em',
  lineHeight: '1.4em',
  overflow: 'hidden',
});

const button = style({
  $nest: {
    i: {
      marginLeft: '6px',
    },
  },
});

const center = style({
  alignSelf: 'center',
  height: '100%',
});

const linkButton = style({
  fontWeight: 'bold',
  fontSize: '0.85rem',
  textDecoration: 'underline',
  color: TEAL_PRIMARY,
  cursor: 'pointer',
});

const centerContents = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  height: '100%',
});

const flexColumn = style({
  flexDirection: 'column',
});

const gridLeft = style({
  display: 'flex',
  $nest: {
    '&>*': {
      padding: 8,
    },
  },
});

const gridRight = style({
  maxWidth: 400,
});

const titleColumn = style({
  paddingLeft: '10%',
  maxWidth: '70%',
});

const imageContainer = style({
  alignSelf: 'center',
  height: '95%',
  flexShrink: 0,
  display: 'inline-block',
  backgroundSize: 'cover',
  width: 150,
  paddingBottom: 0,
});

const swatchContainer = style({
  paddingLeft: 4,
  paddingTop: 35,
});

const swatchContainerActual = style({
  marginTop: 10,
  overflowX: 'auto',
  overflowY: 'hidden',
  width: '41vw',
  maxWidth: 500,
  display: 'inline-block',
});

const nameContainer = style({
  marginTop: 20,
});

const linkColumn = classes(centerContents, flexColumn);
const duplicateButton = classes(linkButton, style({ paddingTop: 15 }));

export default {
  card,
  image,
  description,
  swatches,
  button,
  center,
  linkButton,
  centerContents,
  flexColumn,
  gridLeft,
  gridRight,
  titleColumn,
  linkColumn,
  duplicateButton,
  imageContainer,
  swatchContainer,
  swatchContainerActual,
  nameContainer,
};
