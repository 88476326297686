import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import { TenantConfigViewData, ViewDefnState } from 'src/dao/tenantConfigClient';
import type { TrendSummaryConfig } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { ComponentErrorType, ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { AppState, AppThunkDispatch } from 'src/store';
import { isNil, set } from 'lodash';
import service from 'src/ServiceContainer';
import Axios from 'src/services/axios';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { receiveFloorsetData, requestFloorsetData } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { AnyAction as BaseAction } from 'redux';

export interface QuickTrendsSlice {
  summaryViewDefn: TrendSummaryConfig | null;
  detailsViewDefn: TenantConfigViewData | null;
  viewDefnState: ViewDefnState;
  trendsSummaryDataState: ViewDataState;
  trendsSummaryCacheHash: string | null;
  trendsDetailsCacheHash: string | null;
  trendsDetailsDataState: ViewDataState;
}

const initialState: QuickTrendsSlice = {
  viewDefnState: ViewDefnState.idle,
  summaryViewDefn: null,
  detailsViewDefn: null,
  trendsSummaryDataState: ViewDataState.idle,
  trendsDetailsDataState: ViewDataState.idle,
  trendsSummaryCacheHash: null,
  trendsDetailsCacheHash: null,
};

const quickTrendsReducer = createSlice({
  name: 'QuickTrends',
  initialState,
  reducers: {
    requestTrendsConfig: (state) => {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTrendsConfig: (
      state,
      action: PayloadAction<{ summaryViewDefn: TrendSummaryConfig; detailsViewDefn: TenantConfigViewData }>
    ) => {
      state.summaryViewDefn = action.payload.summaryViewDefn;
      state.detailsViewDefn = action.payload.detailsViewDefn;
      state.viewDefnState = ViewDefnState.loaded;
    },
    refreshQuickTrendsTrendData: () => {
      // nothing to do here, this action triggers refetch in epic
    },
    requestSummaryData(state) {
      state.trendsSummaryDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveSummaryCacheHash(state, action: PayloadAction<string>) {
      state.trendsSummaryCacheHash = action.payload;
    },
    receiveSummaryCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.trendsSummaryCacheHash) {
        state.trendsSummaryDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveSummaryLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.trendsSummaryCacheHash) {
        state.trendsSummaryDataState = ViewDataState.liveDataReady;
      }
    },
    requestDetailsData(state) {
      state.trendsDetailsDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveDetailsCacheHash(state, action: PayloadAction<string>) {
      state.trendsDetailsCacheHash = action.payload;
    },
    receiveDetailsCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.trendsDetailsCacheHash) {
        state.trendsDetailsDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveDetailsLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.trendsDetailsCacheHash) {
        state.trendsDetailsDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  receiveTrendsConfig,
  requestTrendsConfig,
  requestSummaryData,
  receiveSummaryCacheHash,
  receiveSummaryCachedData,
  receiveSummaryLiveData,
  requestDetailsData,
  receiveDetailsCacheHash,
  receiveDetailsCachedData,
  receiveDetailsLiveData,
  refreshQuickTrendsTrendData,
  cleanUp,
  receiveError,
} = quickTrendsReducer.actions;

export function fetchQuickTrendsData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestSummaryData,
    receiveSummaryCacheHash,
    receiveSummaryCachedData,
    receiveSummaryLiveData
  );
}
export function fetchQuickTrendsDetailsData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestDetailsData,
    receiveDetailsCacheHash,
    receiveDetailsCachedData,
    receiveDetailsLiveData,
  );
}
export function fetchFloorsetDataForQuickTrends(floorsetApi: ClientDataApi | undefined) {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    if (isNil(floorsetApi)) {
      // if there is no magic floorset dropdown, skip the rest of this and let the function go to
      // fetchConfigurableGridData(), where we will attempt to make a listData call without
      // sending in the floorset information
      dispatch(refreshQuickTrendsTrendData());
      return;
    }
    dispatch(requestFloorsetData());

    const { url: floorsetUrl, headers: floorsetHeaders, params: floorsetParams } = floorsetApi;
    const floorsetOptions = {
      headers: floorsetHeaders,
      params: floorsetParams,
    };
    const currentAppName = getState().perspective.selected?.appType;
    if (!currentAppName) throw new Error('AppName not found, this should not happen');
    set(floorsetOptions, 'params.appName', currentAppName);

    try {
      const floorsets = await Axios.get(floorsetUrl, floorsetOptions).then((resp) => {
        if (isNil(resp.data.data) || resp.data.success === false) {
          // this endpoint doesn't return 5xx when it fails, it returns success: false with a 200 response
          // so manually throw here in that case
          throw new Error('Failed to fetch floorsetdata');
        }
        return resp.data.data as BasicItem[];
      });
      dispatch(receiveFloorsetData(floorsets));
    } catch (error) {
      const err = error as (Error & {defnId: string});
      dispatch(
        receiveError({
          type: ComponentErrorType.data,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.quickTrends,
          stack: (error as Error)?.stack,
          issues: error,
          defnId: err.defnId,
        })
      );
    }
  };
}

export default quickTrendsReducer.reducer;
