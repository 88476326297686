import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, withStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { noop } from 'lodash';
import {
  accordionOverrides,
  accordionSummaryOverrides,
  accordionSummaryDisabledOverrides,
  accordionDetailsOverrides,
  accordionSummaryTopographyOverrides,
} from 'src/components/StyledAccordion/StyledAccordion.styles';

const LocalAccordion = withStyles(() => accordionOverrides)(Accordion);
const StyledAccordionSummary = withStyles(() => accordionSummaryOverrides)(AccordionSummary);
const StyledAccordionSummaryDisabled = withStyles(() => accordionSummaryDisabledOverrides)(AccordionSummary);
const StyledAccordionDetails = withStyles(() => accordionDetailsOverrides)(AccordionDetails);
const StyledTypography = withStyles(() => accordionSummaryTopographyOverrides)(Typography);

type StyledAccordionProps = {
  title: string;
  expanded: boolean;
  expandable?: boolean;
  overrideTransition?: boolean;
  onToggle?: () => void;
};

export const StyledAccordion: React.FunctionComponent<StyledAccordionProps> = ({
  title,
  expanded,
  expandable = true,
  overrideTransition = false,
  onToggle = noop,
  children,
}) => {
  const summary = expandable ? (
    <StyledAccordionSummary expandIcon={expandable ? <ExpandMore /> : null} IconButtonProps={{ edge: 'start' }}>
      <StyledTypography variant="body2">{title}</StyledTypography>
    </StyledAccordionSummary>
  ) : (
    <StyledAccordionSummaryDisabled expandIcon={expandable ? <ExpandMore /> : null} IconButtonProps={{ edge: 'start' }}>
      <StyledTypography variant="body2">{title}</StyledTypography>
    </StyledAccordionSummaryDisabled>
  );

  const transitionProps = overrideTransition
    ? {
        timeout: {
          appear: 0,
          enter: 0,
          exit: 50,
        },
      }
    : undefined;

  return (
    <LocalAccordion
      defaultExpanded={expanded}
      square={true}
      elevation={0}
      onChange={onToggle}
      TransitionProps={transitionProps}
    >
      {summary}
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </LocalAccordion>
  );
};
