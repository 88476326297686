import { style } from 'typestyle';

export default {
  root: style({
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 100,
  }),

  select: {
    root: style({
      fontSize: '10px',
    }),
  },

  formControl: style({
    width: '100%',
  }),
};
