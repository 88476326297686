import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { ExceptionList } from './ExceptionsSummary';
import { BasicPivotItem, TreePivot } from 'src/worker/pivotWorker.types';

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.exceptionsSummary)?.tree || [];
};
/*
Take Tree pivot for each row (incl nested):
  1. populate a path property using the @path 
  2. flatten all rows to one level to use with `treeData = true` in Ag-Grid
*/
function flattenNestedTreeData(data: TreePivot): TreePivot {
  const result = data.map((row) => {
    const childData = flattenNestedTreeData(row.children);
    const hardPath = row['@path'];
    // this looks crazy, but it's simply looking for all `[number]` in @path (sample: ".data[0].children[0]" -> [0,0]")
    const indexPath = [...hardPath.matchAll(/\[([0-9]*)\]/g)].map(x => x[1]);
    return [{
      ...row,
      path: indexPath
    } as BasicPivotItem].concat(childData); // concat here flattens children into parent
  }).reduce((acc, row) => {
    return acc.concat(row); // this flattens all rows' children+self into one flat list
  }, [])
  return result;
}
export type ProcessedDataSelector = OutputSelector<AppState, ExceptionList, (res1: BasicPivotItem[]) => ExceptionList>;
export const getExceptions = createSelector(getTreeData, (treeData) => {
  return flattenNestedTreeData(treeData);
});
