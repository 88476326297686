import { style } from 'typestyle';

export const timeChartStyles = style({
  $debugName: 'timechart',
  display: 'flex',
  $nest: {
    '.chart-container': {
      flexGrow: 1,
      width: 'calc(100% - 130px)',
    },
  },
});
