import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from 'src/store';
import service from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { OvertimeConfig } from 'src/pages/AssortmentBuild/OvertimeView/OvertimeView.types';
import { TimeChartConfig } from '../TimeChart/TimeChart';

export const fetchEnhancedOvertimeConfigs = createAsyncThunk<
  Array<OvertimeConfig | TimeChartConfig>,
  string[],
  ThunkApi & { rejectValue: string }
>('TargetList/fetchConfig', async (viewDefns, { rejectWithValue }) => {
  try {
    return service.tenantConfigClient.getTenantViewDefns<OvertimeConfig | TimeChartConfig>({
      defnIds: viewDefns,
      appName: ASSORTMENT,
    });
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
