function cdnResolvePath(path: string): string {
  try {
    // TODO: Don't use exceptions for control flow.
    // This appears to be a fast way of verifying if path is a data: or absolute uri
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = new URL(path);
    return path;
  } catch (e) {
    return window.__CDN_RESOLVE__!(path);
  }
}

export const resolvePath: (path: string) => string = window.__CDN_RESOLVE__ ? cdnResolvePath : (p: string) => p;
