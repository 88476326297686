import { GridApi, RowClickedEvent, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { get, has, isNil } from 'lodash/fp';

import { Lens } from 'monocle-ts';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { classes } from 'typestyle';

import { Fab, Tooltip, Modal } from '@material-ui/core';

import Subheader from 'src/components/Subheader/Subheader.container';
import Renderer from 'src/utils/Domain/Renderer';
import coalesce from 'src/utils/Functions/Coalesce';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import {
  targetSettingLens,
  targetListLens,
  scopeLensGetter,
  targetSettingNewLens,
  scopeConfigLens,
} from 'src/services/lenses/lenses';
import ControlPopper from '../ControlPopper/ControlPopper';
import { TargetType } from '../Criteria/Criteria.client';
import TargetCreation from '../TargetCreation/TargetCreation.container';
import { TargetSettingContainer } from '../TargetSetting/TargetSetting.container';
import * as TargetSettingStyles from '../TargetSetting/TargetSetting.styles';
import { TargetSettingConfigColumn, TargetSettingReduxSlice, TrueColDef } from '../TargetSetting/TargetSetting.types';
import { fabBtn } from './TargetList.styles';
import { Props, TargetListReduxSlice, State } from './TargetList.types';
import { onGridExport, GridExportType, isGridApiReady } from './TargetList.utils';
import { partial, includes, flow } from 'lodash';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { parse, evaluate } from 'mathjs';
import * as globalMath from 'mathjs';
import { withRouter } from 'src/components/higherOrder/withRouter';

const TARGET_LIST_ROW_HEIGHT = 48;
const TARGET_LIST_HEADER_HEIGHT = 56;
class UnsensitiveTargetList extends React.Component<Props, State> {
  gridApi: GridApi | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      createNewOpen: false,
      popperRef: null,
      popperOpen: false,
      confirmOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.onShowView) {
      this.props.onShowView();
    }
  }

  onTargetListExport = (exportType: GridExportType) => {
    const columnConfigs = !isNil(this.props.config) ? this.props.config.grid.columns : [];
    onGridExport(exportType, this.gridApi, columnConfigs);
  };

  createColumnDef = (columnInfo: TargetSettingConfigColumn): TrueColDef => {
    const options: TrueColDef = {
      headerName: columnInfo.text,
      colId: `targetSetup.${columnInfo.dataIndex}`,
      pinned: columnInfo.pinned,
      cellClass: [TargetSettingStyles.cell],
    };
    if (columnInfo.editable === true) {
      options.editable = true;
      options.cellClass = [TargetSettingStyles.editableColumn, TargetSettingStyles.cell];
    }
    if (columnInfo.calculation != null) {
      const calculation = columnInfo.calculation;
      options.valueGetter = (params) => {
        const calc = parse(calculation);
        if (params.data == null) {
          return '';
        }
        const vars = {};
        calc
          .filter((node) => node.isSymbolNode)
          .forEach((node) => {
            const id = node.name || '';
            if (!has(id, vars) && !has(id, globalMath)) {
              vars[id] = coalesce(get(`${id}`, params.data.targetSetup), params.getValue(id), params.data[id], '0');
            }
          });
        return evaluate(calculation, vars);
      };
    } else {
      options.field = `targetSetup.${columnInfo.dataIndex}`;
    }
    if (columnInfo.aggregatorFunction) {
      options.aggFunc = `${columnInfo.aggregatorFunction}`;
    } else if (columnInfo.aggregator) {
      options.aggFunc = columnInfo.aggregator;
    } else if (columnInfo.dataIndex === 'name') {
      options.footerValueGetter = () => 'Total';
    }

    if (columnInfo.renderer) {
      options.valueFormatter = (params) => {
        if (columnInfo.renderer != null) {
          if (Renderer[columnInfo.renderer]) {
            return Renderer[columnInfo.renderer](params.value);
          }
        }
        return params.value;
      };
    }
    return options;
  };

  onRowClicked = (event: RowClickedEvent) => {
    if (event.event) {
      if (event.event.target === this.state.popperRef) {
        this.setState({
          popperRef: null,
          popperOpen: false,
        });
      }
      this.setState({
        popperRef: event.event.target as HTMLElement,
        popperOpen: true,
      });
    }
  };

  generatePoppersection = (_targetType: TargetType) => {
    if (!isGridApiReady(this.gridApi)) {
      return;
    }
    const callback = (action: string) => {
      this.setState({
        popperOpen: false,
      });
      const rows = this.gridApi?.getSelectedRows() || [];
      if (rows.length > 0) {
        const selected: TargetSettingReduxSlice = rows[0];
        this.props.onRowAction(action, selected);
      }
    };
    // check if rp/op exists
    let availVers = ['WP'];

    const rows = this.gridApi?.getSelectedRows() || [];
    if (rows.length > 0) {
      const selected: TargetSettingReduxSlice = rows[0];
      availVers = selected.targetSetup?.versions || availVers;
    }
    const basicControlPopper = {
      open: this.state.popperOpen,
      anchorRef: this.state.popperRef as HTMLElement,
      controls: [
        {
          icon: 'fa fa-pencil-alt',
          text: 'Edit',
          color: '#FAA33F',
          onClick: () => callback('Edit WP'),
          enabled: true,
        },
        {
          icon: 'fa fa-pencil-alt',
          text: 'View OP',
          color: '#FAA33F',
          onClick: () => callback('View OP'),
          enabled: includes(availVers, 'OP'),
        },
        {
          icon: 'fa fa-pencil-alt',
          text: 'View RP',
          color: '#FAA33F',
          onClick: () => callback('View RP'),
          enabled: includes(availVers, 'RP'),
        },
        {
          icon: 'fa fa-trash-alt',
          text: 'Remove',
          color: '#E5454C',
          onClick: () => this.setState({ confirmOpen: true }),
          enabled: true,
        },
      ],
    };
    return <ControlPopper {...basicControlPopper} />;
  };

  renderList = (targetType: TargetType) => {
    const { config, rowData } = this.props;

    if (config == null) {
      return <div> Loading... </div>;
    }

    const columnDefs = config.grid.columns.map((info: TargetSettingConfigColumn) => this.createColumnDef(info));

    return (
      <div
        onClick={() => {
          if (this.state.popperOpen) {
            this.setState({
              popperOpen: false,
            });
          }
        }}
      >
        <Subheader title={config.text || ''} />
        <ConfirmationModal
          isOpen={this.state.confirmOpen}
          descriptionText={'Do you want to delete the selected target?'}
          onConfirm={() => {
            const rows = this.gridApi?.getSelectedRows() || [];
            if (rows.length > 0) {
              const selected: TargetSettingReduxSlice = rows[0];
              this.props.onRowAction('Remove', selected);
            }
            this.setState({ confirmOpen: false });
          }}
          onCancel={() =>
            this.setState({
              confirmOpen: false,
            })
          }
        />
        <div
          style={{ height: 525, width: '100%' }}
          className={classes('ag-theme-material', 'data-grid', TargetSettingStyles.dataGrid)}
        >
          <AgGridReact
            rowData={rowData}
            rowHeight={TARGET_LIST_ROW_HEIGHT}
            headerHeight={TARGET_LIST_HEADER_HEIGHT}
            singleClickEdit={true}
            onGridReady={(params: GridReadyEvent) => {
              if (params.api) {
                this.gridApi = params.api;
              }
            }}
            rowSelection="single"
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: false,
              width: 200,
            }}
            columnDefs={columnDefs}
            onRowClicked={(event: RowClickedEvent) => {
              if (event) {
                this.onRowClicked(event);
              }
            }}
            getContextMenuItems={() => {
              return [
                'expandAll',
                'contractAll',
                'copy',
                'resetColumns',
                {
                  name: 'CSV Export',
                  action: partial(this.onTargetListExport, 'csv'),
                },
                {
                  name: 'Excel Export',
                  action: partial(this.onTargetListExport, 'excel'),
                },
              ];
            }}
          ></AgGridReact>
          <section className={fabBtn}>
            <Tooltip title="Create New Target" arrow>
              <Fab
                color="secondary"
                aria-label="Create New Target"
                onClick={() =>
                  this.setState({
                    createNewOpen: true,
                  })
                }
              >
                <i className={classes('fas fa-plus', TargetSettingStyles.addButton)} />
              </Fab>
            </Tooltip>

            <Modal
              open={this.state.createNewOpen}
              onClose={() =>
                this.setState({
                  createNewOpen: false,
                })
              }
              style={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '90vw',
                  height: '90vh',
                  minWidth: '700px',
                  backgroundColor: 'white',
                }}
              >
                <TargetCreation
                  lens={targetSettingNewLens}
                  scopeLens={scopeLensGetter}
                  targetType={targetType}
                  closeModal={() =>
                    this.setState({
                      createNewOpen: false,
                    })
                  }
                />
              </div>
            </Modal>
          </section>
          {this.generatePoppersection(targetType)}
        </div>
      </div>
    );
  };

  render() {
    const { type, routerLocation } = this.props;

    return (
      <div style={{ height: '100%', overflow: 'auto' }}>
        <Routes>
          <Route
            path={`/*`}
            element={
              type === 'Product' ? (
                <Navigate to={`${routerLocation.pathname}/product`} />
              ) : (
                <Navigate to={`${routerLocation.pathname}/location`} />
              )
            }
          />
          <Route path={`:type/*`} element={this.renderList(type)} />

          <Route
            path={`:targetKey/:version/*`}
            element={
              <TargetSettingContainer
                lens={targetSettingLens}
                scopeConfigLens={scopeConfigLens.asGetter()}
                targetCreationLens={targetListLens.compose(Lens.fromProp<TargetListReduxSlice>()('targetNew'))}
                routerParams={this.props.routerParams}
                routerLocation={this.props.routerLocation}
                routerNavigate={this.props.routerNavigate}
                routerSearch={this.props.routerSearch}
              />
            }
          />
        </Routes>
      </div>
    );
  }
}

const sensitiveView = flow(() => UnsensitiveTargetList, withRouter)();
export const TargetList = makeScopeSensitive<Props>(sensitiveView);
