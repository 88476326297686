import React, { useCallback, useRef, useState } from 'react';
import { Popover, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { classes } from 'typestyle';

const useStyles = makeStyles((_theme) =>
  createStyles({
    root: {
      fontSize: '0.85rem',
      padding: '0.5rem 1rem',
    },
  })
);

export interface TopNavIconPopoverMenuItem {
  text: string;
  qaString: string;
  className?: string;
  onClick: () => void;
}

interface TopNavIconPopoverProps {
  icon: string;
  iconQaString: string;
  dropdownItems: TopNavIconPopoverMenuItem[];
}

const TopNavIconPopover = ({ icon, iconQaString, dropdownItems }: TopNavIconPopoverProps) => {
  const [open, setPopoverOpen] = useState(false);
  const anchorElement = useRef(null);
  const styles = useStyles();

  const togglePopover = useCallback(() => {
    const nextOpenState = !open;
    setPopoverOpen(nextOpenState);
  }, [open]);

  return (
    <div ref={anchorElement}>
      <a onClick={togglePopover}>
        <i className={icon} data-qa={`TopNav${iconQaString}Toggle`}></i>
      </a>
      <Popover
        open={open}
        onClose={togglePopover}
        anchorEl={anchorElement.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {dropdownItems.map(({ text, className = '', qaString, onClick }) => {
          return (
            <MenuItem
              className={classes(className, styles.root)}
              key={text}
              onClick={onClick}
              data-qa={`TopNav${qaString}`}
            >
              {text}
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
};

export default React.memo(TopNavIconPopover);
