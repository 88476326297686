import { RangeItem } from 'src/types/Scope';

export function getWeekRangeText(idStart: string | null, idEnd: string | null, rangeItems: RangeItem[]) {
  const foundRangeItemStart = rangeItems.length && rangeItems.find((item) => item.id === idStart);
  const foundRangeItemEnd = rangeItems.length && rangeItems.find((item) => item.id === idEnd);
  // tslint:disable-next-line:max-line-length
  return `${(foundRangeItemStart && foundRangeItemStart.description) || ''} to ${(foundRangeItemEnd &&
    foundRangeItemEnd.description) ||
    ''}`;
}

export default getWeekRangeText;
