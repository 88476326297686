import { connect } from 'react-redux';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { ExceptionsSummary, ExceptionsSummaryDispatchProps, ExceptionList } from './ExceptionsSummary';
import {
  requestExceptionsSummaryConfig,
  receiveExceptionsSummaryConfig,
  requestExceptionsSummaryError,
  ExceptionsSummaryConfigs,
  fetchExceptionsSummaryData,
} from './ExceptionsSummary.slice';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { exceptionsSummaryLens } from 'src/services/lenses/lenses';
import { AppState, AppThunkDispatch } from 'src/store';
import { ExceptionsSummaryComponent } from 'src/services/configuration/codecs/confdefnComponents';
import { defaultTo } from 'lodash';
import { ExtraPivotOptions, organizeListDataOptions } from '../../StyleColorReview/StyleColorReview.slice';
import { getExceptions } from './ExceptionsSummary.selector';
import { ViewDataState } from 'src/types/Domain';

type LoadingExceptions = {
  dataLoading: true;
  configLoading: true;
  viewDataState: ViewDataState;
};
type LoadedExceptions = {
  dataLoading: boolean;
  configLoading: boolean;
  exceptions: ExceptionList | undefined;
  viewDefns: ExceptionsSummaryConfigs | undefined;
  viewDataState: ViewDataState;
};

const mapStateToProps = (state: AppState): LoadingExceptions | LoadedExceptions => {
  const exceptionsSummarySlice = exceptionsSummaryLens.get(state);
  return {
    exceptions: (getExceptions(state) as unknown) as ExceptionList,
    dataLoading: exceptionsSummarySlice.isGridDataLoading,
    configLoading: exceptionsSummarySlice.isConfigLoading,
    viewDefns: exceptionsSummarySlice.viewDefns,
    viewDataState: exceptionsSummarySlice.viewDataState,
  };
};

function dispatchToProps(
  dispatch: AppThunkDispatch,
  ownProps: ExceptionsSummaryComponent['componentProps']
): ExceptionsSummaryDispatchProps {
  return {
    onShowView() {
      // this or is a fallback for the previous behavior
      // it should be removed eventually
      const defnIds = defaultTo(ownProps.configApi.params.defnId, 'HistoryExceptionsSummary');
      dispatch(requestExceptionsSummaryConfig());
      container.tenantConfigClient
        .getTenantViewDefns({
          defnIds: [defnIds],
          appName: ownProps.configApi.params.appName || ASSORTMENT,
        })
        .then((config) => {
          const maybeExcConfig = config[0];
          if (maybeExcConfig.columns && maybeExcConfig.columns !== undefined && maybeExcConfig.main) {
            dispatch(receiveExceptionsSummaryConfig((maybeExcConfig as unknown) as ExceptionsSummaryConfigs)); // ssshhhh
          }
          const options: ExtraPivotOptions = {
            aggBy: ownProps.dataApi.params.aggBy || 'custom:groupid,custom:id',
          };
          const finalOptions = organizeListDataOptions(options, ownProps.dataApi);
          return dispatch(fetchExceptionsSummaryData(ownProps.dataApi.defnId, finalOptions));
        })
        .catch((_error) => {
          // TODO log error and toast
          dispatch(requestExceptionsSummaryError());
        });
    },
    onRefetchData() {
      const options: ExtraPivotOptions = {
        aggBy: ownProps.dataApi.params.aggBy || 'custom:groupid,custom:id',
      };
      const finalOptions = organizeListDataOptions(options, ownProps.dataApi);
      dispatch(fetchExceptionsSummaryData(ownProps.dataApi.defnId, finalOptions));
    },
  };
}

export default connect(
  mapStateToProps,
  dispatchToProps
  // @ts-ignore
)(makePrintSensitive(makeScopeAndFilterSensitive(ExceptionsSummary)));
