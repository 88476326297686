import * as React from 'react';
import Axios from 'src/services/axios';
import { isNil, merge, isNumber, isArray, isEmpty, concat, isString, isUndefined, isEqual } from 'lodash';

import styles from './DividedColumnDetails.styles';
import {
  DividedColumnDetailsProps,
  DividedColumnDetailsState,
  DividedColumnDetailsSection,
  DividedColumnDetailsSectionColumn,
  DividedColumnDetailsRenderer,
  ValueSelection,
  ValuePreviousSelection,
  LocalMathType,
  MixedPromiseResponse,
} from './DividedColumnDetails.types';
import DividedColumnDetailsDropdown from './DividedColumnDetailsDropdown';
import { processApiParams, getUrl, getViewProm } from '../StyleEdit.utils';
import { updateStyleItem } from '../StyleEdit.client';
import { BasicItem } from 'src/worker/pivotWorker.types';
import DependentPropDropdown from './DependentPropDropdown';
import ServiceContainer from 'src/ServiceContainer';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import InputGeneric from 'src/common-ui/components/Inputs/InputGeneric/InputGeneric';
import { SUBCLASS_ID, STYLE_COLOR_ID, STYLE_ID } from 'src/utils/Domain/Constants';
import { classes } from 'typestyle';
import * as globalMath from 'mathjs';
import { executeCalculation } from 'src/utils/LibraryUtils/MathUtils';
import { Renderer } from 'src/utils/Domain/Renderer';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import LocalPivotServiceContext from 'src/components/StylePreview/PivotServiceContext';
import { toast } from 'react-toastify';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { createId } from '@paralleldrive/cuid2';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

function nullDetected(value: string[]) {
  return value.some((str) => {
    if (isNil(str)) {
      return true;
    }

    // check for 'Undefined' as a string value
    return isString(str) ? str.toLowerCase().trim() === 'undefined' : false;
  });
}

export function getTooltipTitle(value: string | string[]): string | JSX.Element {
  if (!isArray(value)) {
    return value;
  }

  if (isEmpty(value) || nullDetected(value)) {
    return ''; // don't show tooltip for empty multi-selections
  }

  return (
    <React.Fragment>
      {value.map((title) => (
        <div key={title}>{title}</div>
      ))}
    </React.Fragment>
  );
}

export default class ColumnDetails extends React.Component<DividedColumnDetailsProps, DividedColumnDetailsState> {
  state: DividedColumnDetailsState;
  math = globalMath.create(globalMath.all) as globalMath.MathJsStatic;
  static contextType = LocalPivotServiceContext;
  context!: React.ContextType<typeof LocalPivotServiceContext>;
  componentId: string = createId();

  constructor(props: DividedColumnDetailsProps) {
    super(props);

    this.state = {
      isLoading: false,
      dependentProps: {},
      dependentCalcs: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: DividedColumnDetailsProps) {
    if (this.props.styleId !== prevProps.styleId) {
      this.getData(true);
    } else if (this.props.currentRefreshCount > prevProps.currentRefreshCount) {
      const isCurrent = this.props.lastEditedSection === this.componentId;
      if (isCurrent) {
        // This breaks style edit class change
        // if (isEqual(this.props.dataApi, prevProps.dataApi) && this.props.dataApi.isListData) {
        //   this.redecorateData();
        // }
      } else {
        this.getData(true);
      }
    }
  }

  redecorateData = async () => {
    const { dataApi } = this.props;
    if (!dataApi.isListData || isNil(this.state.data)) {
      this.setState({ isLoading: false });
      return;
    }
    const newData = await ServiceContainer.pivotService.redecorate({
      coordinates: [this.state.data],
      defnId: dataApi.defnId,
      nestData: false,
      aggBy: dataApi.params.aggBy.split(','),
    });
    this.setState({
      data: newData[0],
    });
  };

  getQueue() {
    const { styleId, dataApi, configApi } = this.props;
    const listDataCall = this.context.listData;
    let dataProm: Promise<MixedPromiseResponse>;
    if (dataApi.isListData) {
      dataProm = listDataCall(dataApi.defnId, AppType.Assortment, dataApi.params).then((resp) => {
        return {
          data: {
            data: resp.flat[0],
          },
        };
      });
    } else {
      const dataUrl = getUrl(dataApi);
      dataProm = Axios.get(dataUrl);
    }

    let requests = [getViewProm(configApi), dataProm];
    if (!isNil(styleId)) {
      const encodedValue = encodeURIComponent(styleId);
      requests = concat(
        requests,
        Axios.get(`/api/member/ancestors/mapping?memberId=${encodedValue}&dimension=product`)
      );
    }

    return requests;
  }

  getData(override?: boolean) {
    // trigger the loader display
    if (this.state.isLoading && !override) return;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const queue = this.getQueue();

        const promise = Promise.all(queue).then((resp) => {
          const sectionData = maybeReturnNestData(resp[0]) as any;
          const styleData = resp[1].data.data;
          let styleMemberData = null;

          if (queue.length > 2) {
            // merge ancestor info into styleData
            const ancestorData = resp[2].data.data;
            const memberData = {};
            Object.keys(ancestorData).forEach((level: string) => {
              memberData[`member:${level}:id`] = ancestorData[level]['id'];
              memberData[`member:${level}:name`] = ancestorData[level]['name'];
            });

            styleMemberData = merge(memberData, styleData);
          }

          const sections: DividedColumnDetailsSection[] = sectionData.sections;
          const dependentProps = {};

          if (!isNil(sectionData.dependentProps)) {
            const keys = Object.keys(sectionData.dependentProps);
            keys.forEach((prop: string) => {
              const dependents = sectionData.dependentProps[prop];
              dependentProps[prop] = dependents;
            });
          }

          const dependentCalcs = {};
          if (sections) {
            sections.forEach((section) =>
              section.columns.forEach((column) => {
                if (column.dependentCalc) {
                  dependentCalcs[column.dataIndex] = column.dependentCalc;
                }
              })
            );
          }

          this.setState({
            isLoading: false,
            sections,
            data: queue.length > 2 ? styleMemberData : styleData,
            dependentProps,
            dependentCalcs,
          });
        });

        promise.catch((err: any) => {
          toast.error('An error occurred fetching your style edit data');
          ServiceContainer.loggingService.error(`An error occurred: ${errorToLoggingPayload(err)}`, err.stack);
        });
      }
    );
  }

  createDividedColumnDetailSection(viewDefn: DividedColumnDetailsSection, sectionKey: string) {
    const halfLength = Math.ceil(viewDefn.columns.length / 2);
    const leftSide = this.renderColumns(viewDefn.columns.slice(0, halfLength));
    const rightSide = this.renderColumns(viewDefn.columns.slice(halfLength));

    return (
      <div key={sectionKey} className={styles.container}>
        <div className={styles.titleColumn} data-qa="section-title">
          <span>{viewDefn.text}</span>
        </div>
        <div className={styles.dataColumnWrapper} data-qa="section-data-column-wrapper">
          {leftSide}
          {rightSide}
        </div>
        <div className={styles.divider} />
      </div>
    );
  }

  renderColumns(columns: DividedColumnDetailsSectionColumn[]) {
    const { data } = this.state;
    const values: DividedColumnDetailsSectionColumn[] = [];

    columns.forEach((column) => {
      values.push({
        ...column,
        text: !isNil(data) ? data[column.dataIndex] : 'N/A',
      });
    });

    return (
      <div className={styles.dataColumn} data-qa="section-data-column">
        <div className={styles.textColumn}>{columns.map((column) => this.createLabelComponent(column))}</div>
        <div className={styles.valueColumn}>{values.map((item, index) => this.createValueComponent(item, index))}</div>
      </div>
    );
  }

  createLabelComponent = (item: DividedColumnDetailsSectionColumn) => {
    const text = item.text;
    if (item.hidden) {
      return null;
    }
    return (
      <div
        className={styles.dataItem}
        style={item.inputParams && item.inputParams.height ? { height: item.inputParams.height } : undefined}
        key={text}
        data-qa="column-value-label"
      >
        {text}
      </div>
    );
  };

  createValueComponent = (item: DividedColumnDetailsSectionColumn, componentIndex: number) => {
    const { styleId } = this.props;
    const {
      text,
      dataIndex,
      dataApi,
      renderer,
      inputType,
      editable,
      asCsv,
      multiSelect,
      valueType,
      mandatory,
      hidden,
      allowEmptyOption,
      concatOptionValues,
      inputParams,
      disabledBy,
    } = item;
    const { data } = this.state;
    const value = !isNil(data) ? data[dataIndex] : '';
    const key = `${dataIndex}-${componentIndex}`;

    if (hidden) {
      return null;
    }

    if (!editable && inputType !== 'dependentDropdown') {
      const formattedText = this.formatText(text, renderer);
      return (
        <div className={classes(styles.dataItem, styles.dataText)} key={key} data-qa="non-editable-column-value">
          <div className={styles.dataText}>{formattedText}</div>
        </div>
      );
    }

    let isDisabled = false;
    if (!isNil(disabledBy) && !isNil(data) && !isNil(data[disabledBy])) {
      isDisabled = true;
    }

    switch (inputType) {
      case 'dropdown': {
        if (!dataApi) return <div />;
        const processedDataApi = processApiParams(dataApi, !isNil(data) ? data : {});
        const allowEmpty = isNil(allowEmptyOption) ? true : allowEmptyOption;
        const concatValues = isNil(concatOptionValues) ? false : concatOptionValues;
        const formattedValue = arrayStringToArray(value, true);
        // empty tooltip values won't trigger tooltip
        const tooltipTitle: string | string[] | React.ReactNode = !multiSelect ? '' : getTooltipTitle(formattedValue);

        return (
          <Tooltip
            className={styles.dataItem}
            data-qa="multiselect-tooltip"
            key={key}
            title={tooltipTitle}
            placement="right"
          >
            <DividedColumnDetailsDropdown
              dataQa={`select-${key}`}
              key={key}
              value={value}
              styleId={styleId}
              dataApi={processedDataApi}
              asCsv={asCsv}
              multiSelect={multiSelect}
              handleDropdownChange={(selection) => this.handleDropdownChange(selection, item)}
              allowEmptyOption={allowEmpty}
              concatOptionValues={concatValues}
              disabled={isDisabled}
              inputParams={inputParams}
            />
          </Tooltip>
        );
      }
      case 'dependentDropdown': {
        if (!dataApi) return <div />;
        const processedDataApi = processApiParams(dataApi, !isNil(data) ? data : {});
        const allowEmpty = isNil(allowEmptyOption) ? true : allowEmptyOption;
        const concatValues = isNil(concatOptionValues) ? false : concatOptionValues;
        return (
          <DependentPropDropdown
            key={key}
            value={value}
            styleId={styleId}
            dataApi={processedDataApi}
            handleDropdownChange={(selection) => this.handleDropdownChange(selection, item)}
            dataQa={`select-${key}`}
            mandatory={mandatory}
            allowEmptyOption={allowEmpty}
            concatOptionValues={concatValues}
            editable={editable && !isDisabled}
          />
        );
      }
      case 'text': {
        const formattedText = this.formatText(value, renderer);

        return (
          <InputGeneric
            key={key}
            className={classes(styles.dataItem, styles.dataTextInput)}
            editable={true}
            autoFocus={false}
            value={formattedText}
            valid={true}
            disabled={isDisabled}
            onBlur={(newText: string) => {
              let result;
              if (valueType === 'number') {
                // TODO: make a configurable parser for this...
                result = parseFloat(newText.replace(/^\$*/g, ''));
              } else {
                result = newText;
              }
              const selection = {
                // mimic dropdown selection structure
                value: result,
                label: newText,
              };
              this.handleDropdownChange(selection, item);
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  formatText(text: string, renderer: DividedColumnDetailsRenderer | undefined) {
    const numericValue = Number(text); // below expects to work with number types only
    const valueRenderer = renderer ?? '';
    const rendererFn = Renderer[valueRenderer];
    return !isNil(rendererFn) && !isNaN(numericValue) ? rendererFn(numericValue) : text;
  }

  handleDropdownChange = (selection: ValueSelection, item: DividedColumnDetailsSectionColumn) => {
    // if the selection is undefined, don't go through this (dependent dropdowns without options)
    if (isUndefined(selection)) return;
    const { styleId } = this.props;
    const { data, dependentCalcs } = this.state;
    const { dataIndex } = item;
    let updatedData: BasicItem | undefined;
    let prevSelection = {};

    // assume data update will succeed...on failure 'rollback' to original values
    const nameDataIndex = dataIndex.replace(':id', ':name');
    const updatedValue = isNumber(selection.value) ? (!isNaN(selection.value) ? selection.value : 0) : selection.value;

    if (!isNil(data)) {
      // update attribute (id and name fields)
      // assuming at this level we will never be updating id, name, description on style
      // only members/attributes now above

      // store prev selection for rollback
      prevSelection = {
        [dataIndex]: data[dataIndex],
        [nameDataIndex]: data[nameDataIndex],
      };

      updatedData = { ...data };
      updatedData[dataIndex] = updatedValue;
      updatedData[nameDataIndex] = selection.label;
    }

    // check if dataindex is used in any dependent calculations, and if it is, update those calcs
    const calcKeys = Object.keys(dependentCalcs);
    if (calcKeys.length > 0 && updatedData) {
      calcKeys.forEach((key) => {
        let updateCalculation = false;
        const calcObj = dependentCalcs[key];
        const params = calcObj.params;

        if (params) {
          for (const p in params) {
            if (dataIndex === params[p] || nameDataIndex === params[p]) {
              updateCalculation = true;
            }
          }
        }
        if (updateCalculation && updatedData) {
          const getDataFromKey = (key2: string) => {
            if (!updatedData) return { rowNodeFound: false, data: undefined };
            return {
              rowNodeFound: true,
              data: updatedData[key2],
            };
          };
          const newValue = executeCalculation(this.math, calcObj, getDataFromKey);
          const keyName = key.replace(':id', ':name');
          updatedData[key] = newValue;
          updatedData[keyName] = newValue;
          this.saveData(styleId, key, newValue);
        }
      });
    }

    this.setState(
      {
        data: updatedData,
      },
      () => this.saveData(styleId, dataIndex, updatedValue, prevSelection)
    );
  };

  saveData(styleId: string, dataIndex: string, value: string | number, _prevSelection?: ValuePreviousSelection) {
    let newData = {};

    if (!dataIndex.includes('member:') && !dataIndex.includes('level:')) {
      // regular attribute update
      const attr = dataIndex.replace('attribute:', '').replace(':id', '');
      newData = {
        id: styleId,
        [attr]: value,
      };
    } else {
      // get member hierarchy values (really only care about class and subclass at this point)
      const { data } = this.state;

      if (!isNil(data) && dataIndex === SUBCLASS_ID) {
        // Only submit updates for SUBCLASS_ID (as only the nearest ancestor makes sense for submission)
        const parents = Object.keys(data)
          .filter((key) => {
            return key.match(/member:.*:id/) != null && [STYLE_ID, STYLE_COLOR_ID].indexOf(key) < 0;
          })
          .map((key) => data[key]);
        newData = {
          id: styleId,
          // We need to include all parents as we can't be certain which ones are actually relevantly
          // updated.
          parent: parents,
        };
      } else {
        return;
      }
    }

    updateStyleItem(newData)
      .then(() => {
        this.context.clearPivotCache();
        this.props.refreshSections(this.componentId);
      })
      .catch((e) => {
        toast.error('An error occured updating your style');
        ServiceContainer.loggingService.error('An error occured updating the style edit in styleeditsection', e.stack);
        // FIXME: handle rollback?
      });
  }

  render() {
    if (!this.props.expanded) {
      // in the mounted but in un-expanded state, renderer nothing to save resources
      return <React.Fragment></React.Fragment>;
    }
    if (this.state.isLoading) {
      return (
        <Overlay type="loading" visible={true} fitParent={true} qaKey={'StyleEditSectionDividedColumnDetailsOverlay'} />
      );
    }

    const { sections } = this.state;
    if (isNil(sections)) {
      return null;
    }

    const columns = sections.map((section, index) => {
      return this.createDividedColumnDetailSection(section, `${section.text}-${index}`);
    });
    return (
      <div className={styles.outerContainer} data-qa="column-details-container">
        {columns}
      </div>
    );
  }
}
