import { Metric } from 'src/common-ui/components/Macros/Macros.types';
import React from 'react';
import { style } from 'typestyle';

const metricsContainer = style({
  display: 'flex',
  marginLeft: '15%',
  marginTop: 30,
  $nest: {
    '.highlight-entry': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 100,
      flexShrink: 0,
      marginRight: '3%',
      width: '14%',
    },
    '.highlight-number': {
      color: '#CC432A',
      fontSize: '150%',
      letterSpacing: -2,
      transform: 'scale(1, 1.2)',
      marginLeft: '5%',
    },
    '.highlight-text': {
      fontSize: 16,
      marginLeft: 10,
      paddingTop: 13,
      lineHeight: '13px',
      transform: 'scale(1, 1.2)',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
  },
});

type HighlightMetricsProps = {
  metrics: Metric[];
};

export const MacroSummaryHighlights = ({ metrics }: HighlightMetricsProps) => {
  return (
    <div className={metricsContainer}>
      {metrics.map((metric: Metric, idx) => (
        <div key={idx} className="highlight-entry">
          <div className="highlight-text">{metric.label}</div>
          <div className="highlight-number">{metric.rendered}</div>
        </div>
      ))}
    </div>
  );
};
