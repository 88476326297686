import React from 'react';
import { assign } from 'lodash';
import { toast } from 'react-toastify';
import { FilterSelection } from 'src/common-ui/components/Filters/Filters';
import { HINDSIGHTING } from 'src/utils/Domain/Constants';
import { Scope } from 'src/types/Scope';
import { makeScopeClient } from 'src/dao/scopeClient';
import { RestoreSessionState, RestoreSessionProps } from './RestoreSession.container';
import { getSessionAll, isSessionScopeValid } from './RestoreSession.utils';
import { Perspective } from 'src/services/configuration/codecs/bindings.types';
import {
  getPerspectiveConfig,
  PerspectiveConfig,
  isPerspective,
  isEnabledPerspective,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection';
import ServiceContainer from 'src/ServiceContainer';
import './RestoreSession.scss';
import { getPerspectiveLookupKey } from 'src/services/configuration/service';

class RestoreSession extends React.Component<RestoreSessionProps, RestoreSessionState> {
  state = {
    loading: false,
  };

  applyNav = async (perspective: Perspective, activeTab: string, perspectiveConfig: PerspectiveConfig) => {
    const groups = perspectiveConfig.view.filter(isPerspective);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const perspectiveBinding = groups.find((x) => {
      const perspectiveLookupKey = getPerspectiveLookupKey(x);
      return perspectiveLookupKey == perspective;
    })!;

    if (isEnabledPerspective(perspectiveBinding)) {
      this.props.setPerspective(perspectiveBinding);
      this.props.setActiveTab(activeTab);
    }
  };

  applyScope = async (sessionScope: Partial<Scope>) => {
    const scopeClient = makeScopeClient();
    // Request scope to set perspective
    this.props.requestScope();
    const response = await scopeClient.getScope();
    // Only set scope if the received scope is different than the stored scope
    const needSetScope = Object.keys(sessionScope).reduce((accum, key) => {
      return sessionScope[key] !== response.scope[key] ? true : accum;
    }, false);
    if (needSetScope) {
      const requiredScopeSelections = {
        ...response.scope,
        ...sessionScope,
        locationMemberName: null,
        productMemberName: null,
      };
      const response2 = await scopeClient.setScope(requiredScopeSelections);
      this.props.receiveScope(response2.scope);
    } else {
      this.props.receiveScope(response.scope);
    }
  };

  applyFilters = (filterSelections: FilterSelection[]) => {
    const selectionOverrides = filterSelections.map((override) => assign({}, override, { value: true }));
    this.props.setFilterSelections(selectionOverrides);
  };

  applySession = async (
    perspective: Perspective,
    tab: string | null,
    scope: Partial<Scope>,
    filterSelections: FilterSelection[]
  ) => {
    const config = await getPerspectiveConfig().then((x) => x.data);
    await this.applyNav(perspective, tab || HINDSIGHTING, config);
    if (isSessionScopeValid()) {
      await this.applyScope(scope);
      this.applyFilters(filterSelections);
    }
  };

  onClick = () => {
    const {
      productMember,
      productLevel,
      locationLevel,
      locationMember,
      historyEnd,
      historyStart,
      asstStart,
      asstEnd,
      floorset,
      filters,
      tab,
      perspective,
      page,
    } = getSessionAll();

    if (!perspective) return;

    this.setState({
      loading: true,
    });
    toast.dismiss('restoreSession');

    const scope = {
      productLevel,
      productMember,
      locationLevel,
      locationMember,
      floorSet: floorset,
      start: asstStart,
      end: asstEnd,
      historyStart,
      historyEnd,
    };

    this.applySession(perspective, tab, scope, filters)
      .then(() => {
        if (page) {
          this.props.routerNavigate(page);
        }
      })
      .catch((e) => {
        ServiceContainer.loggingService.error('Error: Unable to restore previous session', e);
        this.destroy();
        toast.error('Error: Unable to restore previous session.', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  destroy() {
    toast.dismiss('restoreSession');
    this.setState({ loading: false });
  }

  componentDidMount() {
    toast(
      <div onClick={this.onClick} className="restore-session-message">
        <p>Click here to pick up where you left off! </p>
      </div>,
      {
        position: toast.POSITION.TOP_LEFT,
        closeOnClick: false,
        autoClose: false,
        draggable: false,
        type: 'info',
        toastId: 'restoreSession',
      }
    );
  }

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    return <React.Fragment />;
  }
}
export default RestoreSession;
