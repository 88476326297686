import { style, classes } from 'typestyle';
import { _p } from 'src/utils/Style/NestUtils';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { NestedCSSProperties } from 'typestyle/lib/types';

const timeBox = 'timeBox',
  innerBox = 'innerBox',
  mainBody = 'main-body',
  floorsetWrapper = 'floorsetWrapper',
  selectBox = 'selectBox';

const text = 'text',
  picker = 'picker',
  rangePicker = 'rangePicker';

const dropdown = 'std-dropdown',
  formLabel = 'std-title',
  CARETSIZE = 6,
  selectorHeight = '40px';

const weekPickerBoxPosition: NestedCSSProperties = {
  position: 'fixed',
  width: 750,
  height: 320,
  marginTop: -160,
  marginLeft: -375,
  zIndex: 10,
  top: '50%',
  left: '50%',
};

export default {
  container: style({
    $debugName: 'targetCreation-Container',
    height: '90vh',
    width: '90vw',
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      '> header': {
        width: '100%',
        padding: '0.5rem 0.75rem',
        marginBottom: 0,
        fontSize: '1rem',
        color: 'inherit',
        backgroundColor: '#f7f7f7',
        borderBottom: '1px solid #ebebeb',
      },
    },
  }),
  body: style({
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'row',
  }),
  content: style({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: '0.5rem 0.75rem',
    $nest: {
      [_p(mainBody)]: {
        flexGrow: 1,
      },
    },
  }),
  stepping: classes(
    style({
      background: '#f4f3f4',
      paddingLeft: '0.5rem',
    }),
    '.border-right'
  ),
  timeContainer: style({
    $debugName: 'targetCreation-TimeContainer',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    $nest: {
      '.week-range-picker-box': weekPickerBoxPosition,
      [_p(timeBox)]: {
        flexGrow: 1,
        position: 'relative',
        $nest: {
          [_p(innerBox)]: {
            width: '90%',
            border: '1px solid #dee2e6',
            height: '60%',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: 'auto',
            padding: '6%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            $nest: {
              ['& > header']: {
                fontWeight: 'bold',
                fontSize: '1.2rem',
              },
              [_p(floorsetWrapper)]: {
                display: 'flex',
                alignItems: 'center',
                $nest: {
                  ['& > *']: {
                    flexGrow: 1,
                    flexBasis: 0,
                  },
                  ['& > *:last-child']: {
                    alignSelf: 'flex-end',
                    flexGrow: 1,
                    flexBasis: 0,
                  },
                },
              },
              [_p(selectBox)]: {
                border: '1px solid #dee2e6',
              },
            },
          },
        },
      },
    },
  }),
  innerBox,
  timeBox,
  mainBody: style({
    width: '100%',
    height: '100%',
    $nest: {
      '.week-range-picker-box': weekPickerBoxPosition,
    },
  }),
  floorsetWrapper,
  selectBox,
  floorset: {
    formLabel,
    dropdown,
    loadText: style({
      width: '100%',
      left: 0,
      $nest: {
        ['&::before']: {
          width: '100%',
        },
      },
    }),
    form: style({
      $debugName: 'std-form',
      width: '100%',
      height: selectorHeight,
      $nest: {
        [_p(formLabel)]: {
          $debugName: formLabel,
          transform: 'translate(0, 12px) scale(1)',
          fontSize: '1rem',
          marginLeft: '5%',
        },
        [_p(dropdown)]: {
          $debugName: dropdown,
          color: TEAL_PRIMARY,
          cursor: 'pointer',
          marginTop: 5,
          paddingLeft: 8,
          fontSize: '1.1rem',
          $nest: {
            i: {
              top: `calc(32% - ${CARETSIZE}px)`, // size of caret
              right: 10,
              position: 'absolute',
              pointerEvents: 'none',
              color: TEAL_PRIMARY,
              paddingTop: 2,
            },
          },
        },
      },
    }),
  },
  datepicker: {
    text,
    picker,
    rangePicker,
    container: style({
      $nest: {
        [_p(picker)]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          $nest: {
            [_p(text)]: {
              width: '90%',
              alignSelf: 'flex-start',
              flexGrow: 1,
              flexBasis: 0,
              height: '100%',
            },
            [_p(rangePicker)]: {
              height: '100%',
              width: '100%',
              border: '1px solid #dee2e6',
              minWidth: 240,
              flexGrow: 1,
              flexBasis: 0,
              $nest: {
                ['& > div']: {
                  backgroundColor: 'white',
                  fontSize: '0.75rem',
                  $nest: {
                    ['& input']: {
                      color: TEAL_PRIMARY,
                    },
                    ['& .fa']: {
                      color: 'darkgray',
                    },
                    ['& > div']: {
                      $nest: {
                        ['& > *:first-child']: {
                          top: -800,
                          height: 3000,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    }),
  },
  disabledFieldset: style({
    pointerEvents: 'none',
    $nest: {
      '.rangePicker': {
        backgroundColor: 'lightgray',
        $nest: {
          '> div': {
            backgroundColor: 'lightgray !important',
          },
        },
      },
    },
  }),
  seedText: style({
    opacity: 0.8,
    position: 'absolute',
    width: '90%',
    padding: 20,
  }),
};
