import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { AppState } from 'src/store';
import { setSelcetedMacroAnchors } from './MacroSummaries.slice';
import { getMappedSummaries } from './MacroSummaries.selector';
import { getScopeObject } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';

export function mapStateToProps(state: AppState, ownProps: { showMinimizableItem?: boolean }) {
  const readyScope = getScopeReadyData(state.mfpScope);

  const currentAnchors = readyScope ? readyScope.currentAnchors : undefined;
  const anchorMembers = readyScope ? getScopeObject(readyScope.mainConfig.memberTrees) : undefined;

  return {
    metrics: getMappedSummaries(state),
    showMinimizableItem: ownProps.showMinimizableItem,
    selectedMacroAnchors: state.macroSummaries.selectedMacroAnchors,
    currentAnchors,
    anchorMembers,
  };
}

export const mapDispatchToProps = {
  setSelcetedMacroAnchors,
};
