import React, { useRef, useState } from 'react';
import { IHeaderParams } from '@ag-grid-community/core';
import { CustomHeaderProps } from '@ag-grid-community/react';
import { Tooltip } from '@material-ui/core';
import { classes } from 'typestyle';
import { EditableGridHeaderEditorBaseProps } from './TextValidationHeaderEditor';
import { IntegerEditorParams, arrowlessNumberInputStyle } from './IntegerEditor';
import { ROW_SELECTION_REQUIRED_TEXT } from 'src/utils/Domain/Constants';
import massEditstyles from 'src/components/MassEdit/MassEdit.styles';

export interface IntegerHeaderEditorProps
  extends IHeaderParams,
    CustomHeaderProps,
    EditableGridHeaderEditorBaseProps,
    IntegerEditorParams {
  regularPosition?: boolean;
}

function formatValue(asPercent: boolean, value: number) {
  return asPercent ? value * 0.01 : value;
}

export const IntegerHeaderEditor = (props: IntegerHeaderEditorProps) => {
  const {
    column,
    getSelectedItems,
    min = 0,
    max = undefined,
    nullable = false,
    onApplyEdit,
    percent = false,
    regularPosition = false,
  } = props;

  const [headerValue, setHeaderValue] = useState<number | null>(null);
  const headerInputRef = useRef<HTMLInputElement>(null);

  const handleApply = () => {
    if (headerInputRef.current?.checkValidity() === false) {
      headerInputRef.current?.reportValidity();
      return;
    }

    headerInputRef.current?.blur();
    const items = getSelectedItems();

    let finalValue;
    if (nullable) {
      finalValue = headerValue === null ? null : formatValue(percent, headerValue);
    } else {
      finalValue = headerValue === null ? 0 : formatValue(percent, headerValue);
    }

    onApplyEdit(finalValue, items, column.getColDef());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);

    if (!event.target.checkValidity()) {
      event.target.reportValidity();
    }

    const setToNull = isNaN(value);
    const finalValue = setToNull ? null : value;
    setHeaderValue(finalValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const disabled = getSelectedItems().length === 0;
  const title = disabled ? ROW_SELECTION_REQUIRED_TEXT : '';
  const position = regularPosition ? 'inherit' : 'absolute';

  return (
    <Tooltip title={title}>
      <input
        type="number"
        ref={headerInputRef}
        className={classes(massEditstyles.massEditInput, arrowlessNumberInputStyle)}
        style={{ position }}
        disabled={disabled}
        // cannot send null to input, send empty string instead
        value={headerValue ?? ''}
        min={min}
        max={max}
        step="any"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </Tooltip>
  );
};
