import React from 'react';
import { ConfigGroup } from '../../components/Mfp/PivotConfigurator/PivotConfigurator.types';

export function createDimensionList(availableGroups: ConfigGroup[]) {
  return availableGroups.map((group, idx, arr) => {
    return (
      <div className={'dim-item'} key={group.id}>
        {' '}
        {group.name} ({group.children ? group.children.map(child => child.name) : null})
        {idx !== arr.length - 1 ? ',' : null}{' '}
      </div>
    );
  });
}
