import { mapKeys } from 'lodash';

export function removeColons<T extends { [k: string]: unknown }>(record: { [k: string]: unknown }): T {
  return mapKeys(record, (_v, key) => {
    const splitKey = key.split(':');
    if (splitKey.length > 1) {
      return splitKey[1];
    } else {
      return splitKey[0];
    }
  }) as T;
}
