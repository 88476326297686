import React from 'react';
import { CellValueChangedEvent } from '@ag-grid-community/core';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TimeEntry, TimeSheetInfo, TreePivot } from 'src/worker/pivotWorker.types';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { Option } from 'src/components/Configure/ConfigureModal';
import { GridItem } from 'src/utils/Component/AgGrid/AgDataFormat';
import { FlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetGrid';
import { CustomInputAttributes, ValidValuesInputParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { ComponentTypeGridDispatchProps } from 'src/pages/AssortmentBuild/Pricing/PricingGrid';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { EditableOverTimeGridComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { IRowNode } from '@ag-grid-community/core';
import { FlowSheetGridDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { SubheaderSlice, SubheaderViewDefns } from 'src/components/Subheader/Subheader.slice';
import { DaysRangeListResponse } from 'src/types/Scope';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import {
  LoadingStateProjectionProps,
  LoadedStateProjectionProps,
} from 'src/components/ListGridPair/ListGridPair.selectors';
import { FlowSheetSlice } from './FlowSheetByStyle.slice';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export interface FlowSheetGridProps extends ComponentTypeGridDispatchProps {
  loaded: boolean;
  itemId?: string;
  overTimeData?: TreePivot;
  rowHeight?: number;
  groupRowHeight?: number;
  scopeTimeEntries: TimeEntry[];
  anchorField?: string;
  editable: boolean;
  columnWidth?: number;
  showUndoBtn?: boolean;
  gridDefn?: FlowSheetGridDefn;
  onValueChange?: (payload: GranularEditPayloadItem[], runPlan?: boolean) => void;
  onItemClicked?: (item: Record<string, any>, eventTarget?: HTMLElement) => void;
  updateAssortmentPlan?: () => void;
  adornments: AdornmentType[];
}

export type RowData = {
  id: string;
  additionalId?: string;
  path: string[];
  groupId: string;
  measureId?: string;
  name?: string;
  editable?: boolean;
  inputType?: string;
  hidden: boolean;
  renderer?: string;
  classes?: string[];
  calculation?: string;
  valueTests?: string[];
  includeInAllUpdates?: boolean;
  editableByCalc?: string;
  ignoreLock?: boolean;
  ignorePublished?: boolean;
  extraData: {
    [extra: string]: number | string | boolean;
  };
} & (RowDataStdInputParams | RowDataVVInputParams);

interface RowDataStdInputParams {
  inputType?: 'receiptsAdjCalculator' | 'integer' | 'textValidator' | 'checkbox' | undefined;
  inputParams?: CustomInputAttributes;
}

interface RowDataVVInputParams {
  inputType: 'validValues';
  inputParams?: ValidValuesInputParams;
}

export interface FrameworkComponents {
  [renderer: string]: React.ReactNode;
}

export interface FlowSheetGridState {
  columnDefs?: (ColDef | ColGroupDef)[];
  rowData: RowData[];
  rowDataOld: RowData[]; // 1 step behind
  anchorField?: string;
  context: {
    componentParent: FlowSheetGrid;
  };
  frameworkComponents: FrameworkComponents;
}

export const EDITABLE_BG_COLOR = 'rgba(119, 255, 244, 0.2)';
export const IS_PUBLISHABLE_INDEX = 'ispublishable';
export const PUBLISH_INDEX = 'dc_publish';
export const LOCKED_INDEX = 'is_locked';
export const SYS_VRP_INDEX = 'sys_vrp';
export const OVERRIDE_VRP_INDEX = 'dc_uservrp';
export const PO_VRP_INDEX = 'po_vrp';
export const TEST_PO = 'testpo';
export const FLOORSET_PO = 'floorsetpo';
export const DC_USERADJ = 'dc_useradj';
export const DC_FINREV = 'dc_sc_finrev';
export const DC_ONORDER = 'dc_onorder';

export function getId(groupId: string, measureId: string): string {
  return `${groupId}_${measureId}`;
}

export interface FlowSheetCellRendererParams extends ICellRendererParams {
  data: RowData;
  editable: (rowNode: IRowNode, colDef: ColDef) => boolean;
  onHeaderClick: (item: { id: string | undefined; parentId: string | undefined }) => Record<string, any>;
  // This needed to be a function to get the adornments at time of rendering
  getAdornments: () => AdornmentType[];
}
export interface FlowSheetCellValueChangedEvent extends CellValueChangedEvent {
  data: RowData;
}

export interface EditRendererState {
  value?: number | string | boolean;
}

export interface GridMeasureDefn {
  text: string;
  dataIndex: string;
  renderer?: string;
  editable?: boolean;
  inputType?: string;
  calculation?: string;
  dependentData?: string[];
  valueTests?: string[];
  inputParams?: CustomInputAttributes;
  editableByCalc?: string;
  ignoreLock?: boolean;
  ignorePublished?: boolean;
  visible?: boolean;
  hideFromConfigurator?: boolean;
  includeInAllUpdates?: boolean;
  classes?: string[];
}

export interface OwnProps extends EditableOverTimeGridComponentProps {}

export interface FlowSheetAndPricingMapStateToProps {
  identityField: string;
  treeData: TreePivot;
  subheaderState: SubheaderSlice;
  subheaderViewDefns: SubheaderViewDefns;
  shouldFilterFlowStatus: boolean;
  allowFrom: string;
  allowTo: string;
  rangeList: DaysRangeListResponse;
  timeInfo: TimeSheetInfo;
  adornments: AdornmentType[];
  selectedId: string;
  rowHeight?: number;
  groupRowHeight?: number;
  columnWidth?: number;
  configLoaded: boolean;
  dataLoaded: boolean;
  scopeStart: string | null;
  overTimeDataLoaded: boolean;
  massEditDataLoaded: boolean;
  liveDataReady: boolean;
  overTimeData: TreePivot;
  itemLocation?: string;
}
export type LoadedFlowSheetAndPricingMapStateToProps = LoadedStateProjectionProps & FlowSheetAndPricingMapStateToProps;
export type LoadingFlowSheetAndPricingMapStateToProps = LoadingStateProjectionProps &
  FlowSheetAndPricingMapStateToProps;
export type FlowSheetAndPricingMapState =
  | LoadedFlowSheetAndPricingMapStateToProps
  | LoadingFlowSheetAndPricingMapStateToProps;

export interface FlowSheetAndPricingDispatchProps {
  updateConfigureSelections?(selections: Option[]): void;
  submitPayload(payload: GranularEditPayloadItem[], shouldRefresh?: boolean): void;
  getMassEditData?(): void;
  onCompanionItemClick(memberId: string): void;
  onShowView(): void;
  onRefetchData(): void;
  onRefetchOvertimeData?(): void;
  onDestroy(): void;
  onUpdateConfig(config: TenantConfigViewData): void;
  updateAssortmentPlan(): void;
}

interface FlowSheetProps
  extends OwnProps,
    FlowSheetAndPricingDispatchProps,
    PrintProps,
    FlowSheetSlice,
    StyleDetailsPopoverProps {
  activeTab?: string;
}
interface LoadedFlowSheetProps extends FlowSheetProps, LoadedFlowSheetAndPricingMapStateToProps {}
interface LoadingFlowSheetProps extends FlowSheetProps, LoadingFlowSheetAndPricingMapStateToProps {}
export type FlowSheetByStyleProps = LoadedFlowSheetProps | LoadingFlowSheetProps;

export interface State {
  uniqueItems: GridItem[]; // should this be moved?
  rowData?: RowData[];
  selectedItem?: string; //remove me later
  companionSortDirection: SortByDirection;
  companionCollapsed: boolean;
  companionSortField?: string;
  companionLevelField?: string;
  companionData: CompanionDataItem[];
  isDefault?: boolean;
  configureIsOpen: boolean;
  getMassEdit?: boolean;
  configureLastSelected?: Option[];
  isCompanionChangeOnDefault?: boolean;
}

export interface CompanionDataItem {
  id: string;
  title?: string;
  name: string;
  stars: number;
  imageUri: string;
  [key: string]: string | number | undefined;
}
