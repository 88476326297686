import { zPivotOptions } from 'src/components/Mfp/PivotConfigurator/utils';
import { zSmartPlanTarget } from './SmartPlanTarget';
import { z } from 'zod';
const SMARTPLAN_BALANCE = z.literal('Balance');
const SMARTPLAN_SUMMARY = z.literal('Summary');
const SMARTPLAN_TARGETS = z.literal('Targets');
const SMARTPLAN_GRID = z.literal('Grid');
const SMARTPLAN_DESCRIPTION = z.literal('Description');

const SMARTPLAN_COMPONENTS = z.union([
  SMARTPLAN_BALANCE,
  SMARTPLAN_SUMMARY,
  SMARTPLAN_TARGETS,
  SMARTPLAN_GRID,
  SMARTPLAN_DESCRIPTION,
]);

export const zSmartPlanSection = z.object({
  title: z.string(),
  icon: z.string(),
  targets: z.array(zSmartPlanTarget),
});

export const zSmartPlanStep = z.object({
  /* Section Title (not implemented yet) */
  title: z.string(),
  /* Section Description */
  description: z.union([z.string(), z.null()]),
  /* Component Type */
  component: SMARTPLAN_COMPONENTS,
  /* Sections, leaves as empty array for non-target component */
  sections: z.union([z.null(), z.array(zSmartPlanSection)]),
  viewParams: z.union([zPivotOptions, z.null()]),
});
export const zSmartPlanPlayload = z.object({
  steps: z.array(zSmartPlanStep),
});
export interface SmartPlanPayload extends z.infer<typeof zSmartPlanPlayload> {}
export interface SmartPlanStep extends z.infer<typeof zSmartPlanStep> {}
export interface SmartPlanSection extends z.infer<typeof zSmartPlanSection> {}
