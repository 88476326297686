import { important } from 'csx';
import { style } from 'typestyle';

// mark made me do this
export const getNavigationButtonStyle = (disabled: boolean): string => {
  return style({
    textTransform: important('none'),
    fontWeight: disabled ? important('normal') : important('bold'),
    marginRight: important('5px'),
    whiteSpace: important('nowrap'),
    cursor: disabled ? important('not-allowed') : important('pointer'),
    color: disabled ? important('rgb(85, 85, 85)') : important('#f38d9d'),
    pointerEvents: important('auto'),
  });
};
