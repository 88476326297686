import { CanvasViewSlice, TenantResponse } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { has } from 'lodash';
import { BasicPivotItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { AnyAction as BaseAction } from 'redux';
import { AppThunkDispatch } from 'src/store';

import { CartItemType } from '../../AssortmentCart/AssortmentCart.types';

export enum AssortmentAddSearchDataState {
  noSearchItemsFound = 'noSearchItemsFound',
}
/** Reducer shape for 'Add Existing/Similar Style/StyleColor' views. */
export interface AssortmentAddSlice extends Omit<CanvasViewSlice, 'data' | 'isDataLoading' | 'viewDataState'> {
  /** Items selected in current view that are to be added to the cart. */
  selectedItemsForCart: BasicPivotItem[];
  isSelectable: true;
  reload?: boolean;
  viewDataState: ViewDataState | AssortmentAddSearchDataState;
  level: string;
  cartItemType: CartItemType;
  searchData: BasicPivotItem[];
}

export const initialState: AssortmentAddSlice = {
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  tenantConfigLoading: false,
  selectedItemsForCart: [],
  calcViewDefn: {} as TenantConfigViewData,
  requiredViewDefns: [],
  isSelectable: true,
  reload: true,
  popoverTitle: '',
  identityPropsConfig: {} as IdentityPropsConfig,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
  level: '',
  cartItemType: CartItemType.similar,
  searchData: [],
};

export function isAssortmentAddSlice(slice: CanvasViewSlice | AssortmentAddSlice): slice is AssortmentAddSlice {
  return has(slice, 'selectedItemsForCart');
}

const assortmentAddReducer = createSlice({
  name: 'AssortmentAdd',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.tenantConfigLoading = true;
    },
    receiveTenantConfig(
      state,
      action: PayloadAction<
        TenantResponse & { modelDefn: string | undefined; level?: string; cartItemType?: CartItemType }
      >
    ) {
      state.tenantConfigLoading = false;
      state.viewDefn = action.payload.viewDefn;
      state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn || ({} as TenantConfigViewData);
      state.calcViewDefn = action.payload.calcViewDefn || ({} as TenantConfigViewData);
      state.identityPropsConfig = action.payload.identityPropsConfig || ({} as IdentityPropsConfig);
      state.modelDefn = action.payload.modelDefn;
      state.level = action.payload.level || '';
      state.cartItemType = action.payload.cartItemType || CartItemType.similar;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    requestSearchData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
      state.searchData = [];
    },
    receiveSearchData(
      state,
      action: PayloadAction<{
        searchData: BasicPivotItem[];
        searchDataState:
          | ViewDataState.liveDataReady
          | AssortmentAddSearchDataState.noSearchItemsFound
          | ViewDataState.idle;
      }>
    ) {
      state.viewDataState = action.payload.searchDataState;
      state.searchData = action.payload.searchData;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
  },
});

// As of right now this is one of two places where some actions are handled via lenses.
// AddStyles is built in a similar way.

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  requestSearchData,
  receiveSearchData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
} = assortmentAddReducer.actions;

export function fetchAssortmentAddData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export function fetchAssortmentAddBySearchData(ids: string[]) {
  // export function fetchAssortmentAddBySearchData(modelDefn: string, options: ListDataOptions) {
  // return cacheCheckFetchPivotData(
  //   service.pivotService.listDataCacheCheck(modelDefn, options),
  //   requestData,
  //   receiveCacheHash,
  //   receiveCachedData,
  //   receiveLiveData,
  //   true
  // );
  /* eslint-disable no-console */
  return (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    dispatch(requestSearchData());
    return service.pivotService.asstAddSearchSubmitData([], '').then((resp) => console.log('resp:', resp));
    // return dispatch(receiveSearchData());
  };
}

export default assortmentAddReducer.reducer;
