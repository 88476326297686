import { requestChart, ChartDimensionConfig, setChartDataStale } from './EditableChart.slice';
import { ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { AppEpic } from 'src/store';
import { getScopeReadyData, ServerScope } from 'src/state/scope/Scope.types';
import {
  getAvailableListing,
  DimensionItem,
  getEffeciveGroups,
  getGroupFromConfigItem,
} from 'src/components/Mfp/PivotConfigurator/utils';
import { VisualizeSummaryPivotViewParams } from 'src/pages/Mfp/MfpGrid/visualize-summary-pivot-ag.types';
import _ from 'lodash';
import PivotConfig from 'src/pivot/PivotConfig';
import PivotManager from 'src/pivot/Pivot.client';
import { AxiosInstance } from 'axios';
import { NEVER, Observable, of } from 'rxjs';
import { AnyAction } from 'redux';
import { SettingsState } from 'src/state/settings/settings.slice';
import { requestRefreshGrid } from 'src/state/scope/Scope.slice';

export const refreshChartPivot: AppEpic = (action$, state$): Observable<AnyAction | Observable<never>> => {
  return action$.pipe(
    ofType(requestChart.type, requestRefreshGrid.type),
    concatMap(() => {
      const readyScope = getScopeReadyData(state$.value.mfpScope);

      if (readyScope) {
        return of(setChartDataStale());
      }
      return NEVER;
    })
  );
};

export function buildChartPivotManager(
  mainConfig: ServerScope,
  settings: SettingsState['entriesByKey'],
  chartDimensionConfig: ChartDimensionConfig,
  scopeId: string,
  client: AxiosInstance
) {
  const all = getAvailableListing(mainConfig, settings);

  const rows: DimensionItem[] = [chartDimensionConfig.metricDimensions, chartDimensionConfig.versions];

  const columns = [chartDimensionConfig.xAxisDimension];
  const viewParams: VisualizeSummaryPivotViewParams = {
    title: 'Visualization',
    rows,
    columns,
    summaryMetrics: {} as any,
  };

  const effective = getEffeciveGroups(all, viewParams);
  const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
  const pivotCols = effective.column.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols,
  });
  const pivotManager = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: 'ChartPivot',
  });
  return pivotManager;
}
