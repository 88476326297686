import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { MassEditConfig } from 'src/components/MassEdit/MassEdit';
import { IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { cacheCheckFetchPivotData } from '../StyleColorReview.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewDataState } from 'src/types/Domain';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface GridViewConfig {
  grid: TenantConfigViewData;
  listSort: TenantConfigViewData;
  subheaderRollUp: TenantConfigViewData;
  listLevelBy?: TenantConfigViewData; // use for PricingOverTime companion view levelBy
  list: TenantConfigViewData;
  graphs?: TenantConfigViewData;
  summary?: TenantConfigViewData;
  massEdit?: MassEditConfig;
  unmodifiedViewDefn: TenantConfigViewData;
  identityPropsConfig?: GridViewIdentityPropsConfig;
}

export interface GridViewIdentityPropsConfig extends Pick<IdentityPropsConfig, 'id'> {}

export interface GridViewSlice {
  viewDefns: GridViewConfig;
  // TODO: move to using viewDefnState instead here
  isConfigLoading: boolean;
  viewDataState: ViewDataState;
  cacheHash: string | null;
}

const initialState: GridViewSlice = {
  cacheHash: null,
  isConfigLoading: false,
  viewDefns: {
    grid: {} as TenantConfigViewData,
    listSort: {} as TenantConfigViewData,
    listLevelBy: {} as TenantConfigViewData,
    subheaderRollUp: {} as TenantConfigViewData,
    list: {} as TenantConfigViewData,
    massEdit: {} as MassEditConfig,
    unmodifiedViewDefn: {} as TenantConfigViewData,
    identityPropsConfig: {} as GridViewIdentityPropsConfig,
  },
  viewDataState: ViewDataState.idle,
};

const gridViewReducer = createSlice({
  name: 'GridView',
  initialState,
  reducers: {
    requestGridViewConfig(state) {
      state.isConfigLoading = true;
    },
    receiveGridViewConfig(state, action: PayloadAction<GridViewConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveUpdatedGridColumns(state, action: PayloadAction<TenantConfigViewItem[]>) {
      state.isConfigLoading = false;
      state.viewDefns.grid.view = action.payload;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestGridViewConfig,
  receiveGridViewConfig,
  receiveUpdatedGridColumns,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  receiveError,
  cleanUp,
} = gridViewReducer.actions;

export function fetchGridViewData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default gridViewReducer.reducer;
