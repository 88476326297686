/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';
import { isNumber, isNil, isObject, isString, isArray, has } from 'lodash/fp';
import { DaysRangeListResponse } from 'src/types/Scope';
import { getWeekLabel, getDateFromWeek } from 'src/common-ui/components/WeekRange/WeekRangePicker.utils';
import { MathJsStatic } from 'mathjs';
import { memoize, uniq } from 'lodash';
import { GroupItem } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import * as math from 'mathjs';

export interface GetDataCalculation {
  rowNodeFound: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export interface ParamedCalc {
  eval: string | string[];
  params?: { [s: string]: string };
}

function isInvalidNumber(val: unknown) {
  return (isNumber(val) && (isNaN(val) || !isFinite(val))) || isNil(val);
}

// Constant for raw data, used for compatibility fallback
const RAW_DATA_KEY = '__DATA';
const SIZE_FN_NAME = 'size';
const SUM_FN_NAME = 'sum';

function shouldCompatRewrite(formula: math.MathNode): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (formula.isFunctionNode && (formula.fn as any).name === 'sum' && formula.args?.[0].isConstantNode) || false;
}

export const getVarsFromCalc = (calculation: math.MathNode): string[] => {
  const returnVars: string[] = [];
  calculation
    .filter((node) => node.isSymbolNode)
    .forEach((node) => {
      const id = node.name || '';
      if (!has(id, math) && returnVars.indexOf(id) === -1) {
        returnVars.push(id);
      }
    });
  return uniq(returnVars);
};

function resolveInputData(formula: math.MathNode, data: GroupItem[], fallbackKey: string): Record<string, unknown[]> {
  const uniqueVariables = getVarsFromCalc(formula);

  const resolved: Record<string, unknown[]> = {};
  for (const identifier of uniqueVariables) {
    // sum(1) or similar was requested and rewritten so
    if (identifier == RAW_DATA_KEY) {
      resolved[RAW_DATA_KEY] = data;
    } else {
      resolved[identifier] = data.map((item) => {
        if (isNumber(item[identifier])) {
          return item[identifier];
        }
        if (isNumber(item[fallbackKey])) {
          return item[fallbackKey];
        }
        // Not sure this is worth spamming backend with...
        // eslint-disable-next-line no-console
        console.warn(`math input missing primary+fallback key data for ${identifier}+${fallbackKey}`);
        return 0;
      });
    }
  }
  return resolved;
}

export function evaluateFormula(formula: string, data: GroupItem[], fallbackKey: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const m = math as any;
  const parsed = math
    .parse(formula)
    // Rewrite old seshat thing to a constant
    .transform((node) => {
      if (shouldCompatRewrite(node)) {
        return new m.FunctionNode(SUM_FN_NAME, [new m.FunctionNode(SIZE_FN_NAME, [new m.SymbolNode(RAW_DATA_KEY)])]);
      } else {
        return node;
      }
    });
  const inputData = resolveInputData(parsed, data, fallbackKey);
  const result = parsed.evaluate(inputData);
  if (result.isResultSet) {
    const valueOf = result.valueOf();
    return valueOf[valueOf.length - 1];
  } else {
    return result;
  }
}

export const importDefaultFunctions = (math: MathJsStatic) => {
  const defaultFn = (value: number, defaultVal: number) => {
    if (isInvalidNumber(value)) {
      return defaultVal;
    }
    return value;
  };

  math.import(
    {
      default: defaultFn,
    },
    { silent: true, override: true }
  );
};

export const importDateFunctions = (math: MathJsStatic, mergedRangeList: DaysRangeListResponse) => {
  if (has('daysToWeeks', math)) return;
  const s5week = (weekString: string) => {
    const date =
      getDateFromWeek(weekString, mergedRangeList.start_date) || getDateFromWeek(weekString, mergedRangeList.end_date);
    if (date) {
      return date;
    }
    return;
  };
  const weeks = (value: number) => {
    return value * 7;
  };
  const days = (value: number) => {
    return value;
  };
  const daysToWeeks = (value: number) => {
    return value / 7;
  };
  const add = math.typed('add', {
    'Date, number': function (a: Date, b: number) {
      return getWeekLabel(
        moment(a)
          .hours(0)
          .add(b, 'days')
          .toDate(),
        mergedRangeList
      );
    },
    'number, Date': function (a: number, b: Date) {
      return getWeekLabel(
        moment(b)
          .hours(0)
          .add(a, 'days')
          .toDate(),
        mergedRangeList
      );
    },
  });
  const subtract = math.typed('subtract', {
    'Date, number': function (a: Date, b: number) {
      return getWeekLabel(
        moment(a)
          .hours(0)
          .subtract(b, 'days')
          .toDate(),
        mergedRangeList
      );
    },
    'number, Date': function (a: number, b: Date) {
      // Technically this shouldn't work at all. "7 - 2018-W28" doesn't really make sense
      return getWeekLabel(
        moment(b)
          .hours(0)
          .subtract(a, 'days')
          .toDate(),
        mergedRangeList
      );
    },
    'Date, Date': function (a: Date, b: Date) {
      const daysBetween = moment(a)
        .hours(0)
        .diff(moment(b).hours(0), 'days');
      return daysBetween;
    },
  });
  const nil = (a: unknown) => {
    return a == null;
  };

  math.import(
    {
      nil: nil,
      add: add,
      subtract: subtract,
      s5week: s5week,
      weeks: weeks,
      days: days,
      daysToWeeks,
    },
    { silent: true }
  );
  return math;
};
export const getVarsFromCalcString = (math: MathJsStatic, calculation: string): string[] => {
  const returnVars: string[] = [];
  const calc = math.parse(calculation);
  calc
    .filter((node) => node.isSymbolNode)
    .forEach((node) => {
      const id = node.name || '';
      if (!has(id, math) && returnVars.indexOf(id) === -1) {
        returnVars.push(id);
      }
    });
  return returnVars;
};
const parseMemo = memoize(
  (math: MathJsStatic, calcString: string) => {
    const expression = math.parse(calcString);
    return {
      parsed: expression,
      compiled: expression.compile(),
    };
  },
  (_math: MathJsStatic, calcString: string) => calcString
);

const internalExecuteCalculation = (
  math: MathJsStatic,
  calculation: string | string[],
  getDataFromKey: (key: string) => GetDataCalculation,
  extraVars?: { [key: string]: string },
  allowInvalid?: boolean
) => {
  const calcString: string = isArray(calculation) ? calculation.join('\n') : calculation;
  const calc = parseMemo(math, calcString);

  const vars = {};
  // If calculation is an object and has params, use those, if not, lookup dataindexes
  if (extraVars) {
    Object.keys(extraVars).forEach((key) => {
      const getDataCalc = getDataFromKey(extraVars[key]);
      if (getDataCalc.rowNodeFound) {
        vars[key] = getDataCalc.data;
      } else {
        vars[key] = undefined;
      }
    });
  } else {
    calc.parsed
      .filter((node) => node.isSymbolNode)
      .forEach((node) => {
        const id = node.name || '';
        if (vars[id] || math[id]) {
          return;
        }
        const getDataCalc = getDataFromKey(id);
        if (getDataCalc.rowNodeFound) {
          const data = getDataCalc.data;
          vars[id] = data;
        } else {
          // Does few unnecessary lookups if there is a variable that isn't a dataindex. Shouldn't affect performance
        }
      });
  }

  let result;
  try {
    result = calc.compiled.evaluate(vars);
    // Parses arrays that go through
    if (result.isResultSet) {
      const valueOf = result.valueOf();
      result = valueOf[valueOf.length - 1];
    }
  } catch (e) {
    // console.error(e);
  }
  if (isInvalidNumber(result) && allowInvalid !== true) {
    result = 0;
  }
  return result;
};

// Call this to do a calc. This just tells it how to parse it depending on the structure of the calculation value
export const executeCalculation = (
  math: MathJsStatic,
  calculation: string | ParamedCalc,
  getDataFromKey: (key: string) => GetDataCalculation,
  allowInvalid = false
) => {
  if (isObject(calculation)) {
    const paramedCalc = calculation as ParamedCalc;
    if (paramedCalc.params) {
      return internalExecuteCalculation(math, paramedCalc.eval, getDataFromKey, paramedCalc.params, allowInvalid);
    } else {
      return internalExecuteCalculation(math, paramedCalc.eval, getDataFromKey, undefined, allowInvalid);
    }
  } else if (isString(calculation)) {
    return internalExecuteCalculation(math, calculation, getDataFromKey, undefined, allowInvalid);
  }
};
