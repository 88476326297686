import { connect } from 'react-redux';
import { AnyAction as BaseAction } from 'redux';

import { TOP_DOWN, LEVEL_PROD_ROOT, lookBackPeriodProp } from 'src/utils/Domain/Constants';
import container from 'src/ServiceContainer';
import Summary, { DispatchProps } from './Summary';
import selectAndProjectState from './Summary.selectors';

import {
  requestSummaryConfigs,
  receiveSummaryConfigs,
  receiveSummaryData,
  receiveError,
  requestSummaryData,
} from './Summary.slice';
import { makeScopeSensitive } from 'src/components/higherOrder/ScopeSensitive';
import { Scope } from 'src/types/Scope';
import { isNil } from 'lodash';
import { AppState, AppThunkDispatch } from 'src/store';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { SummaryViewTopDownOwnProps } from 'src/services/configuration/codecs/ownProps';

export function getTopMembers(scope: Scope) {
  return [scope.productMember, scope.locationMember].join(',');
}

function getFinancialData(ownProps: SummaryViewTopDownOwnProps) {
  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction> | void => {
    const { summary } = getState().pages.hindsighting;
    const { flowStatus, lookBackPeriod } = getState().subheader;
    const { scope } = getState().scope;
    if (isNil(summary.viewDefns) || !scope.valid) {
      return;
    }
    const globalRegionVolumeAndTrendViewDefault = summary.viewDefns.globalRegionVolumeAndTrend.defaultAggBy || '';
    const keyFinancialsViewSelection = summary.viewDefns.keyFinancials.defaultAggBy;
    const { pivotService } = container;
    dispatch(requestSummaryData());
    const currentFlowStatus = ownProps.showFlowStatus ? flowStatus.join(',') : undefined;
    return Promise.all([
      pivotService.listData(ownProps.keyFinancialsDataApi.defnId, TOP_DOWN, {
        aggBy: keyFinancialsViewSelection,
        flowStatus: currentFlowStatus,
        [lookBackPeriodProp]: lookBackPeriod,
        topMembers: getTopMembers(getState().scope.scope),
      }),
      pivotService.listData(ownProps.choiceProductivityDataApi.defnId, TOP_DOWN, {
        aggBy: LEVEL_PROD_ROOT,
        flowStatus: currentFlowStatus,
        topMembers: getTopMembers(getState().scope.scope),
      }),
      pivotService.listData(ownProps.analysisDataApi.defnId, TOP_DOWN, {
        aggBy: 'level:month',
        [lookBackPeriodProp]: lookBackPeriod,
        topMembers: getTopMembers(getState().scope.scope),
      }),
      pivotService.listData(ownProps.geoTrendDataApi.defnId, TOP_DOWN, {
        flowStatus: currentFlowStatus,
        aggBy: globalRegionVolumeAndTrendViewDefault,
        [lookBackPeriodProp]: lookBackPeriod,
        topMembers: getTopMembers(getState().scope.scope),
      }),
    ])
      .then(([keyFinancials, choiceProductivity, trendAnalysis, globalRegionVolumeAndTrend]) => {
        return dispatch(
          receiveSummaryData({
            keyFinancials: keyFinancials.tree,
            choiceProductivity: choiceProductivity.tree,
            trendAnalysis: trendAnalysis.tree,
            productMixAndTrend: [],
            globalRegionVolumeAndTrend: globalRegionVolumeAndTrend.tree,
          })
        );
      })
      .catch((error) => {
        return dispatch(
          receiveError({
            type: ComponentErrorType.data,
            message: (error as Error)?.message,
            name: ConfDefnComponentType.summary,
            stack: (error as Error)?.stack,
            issues: error,
          })
        );
      });
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: SummaryViewTopDownOwnProps): DispatchProps {
  const { tenantConfigClient } = container;

  return {
    onShowView() {
      const tenantParams = {
        defnIds: ownProps.defns.view,
        appName: TOP_DOWN,
      };
      dispatch(requestSummaryConfigs());

      Promise.all([tenantConfigClient.getTenantViewDefns(tenantParams)]).then(([configs]) => {
        const viewConfig = configs[0];
        dispatch(
          receiveSummaryConfigs({
            keyFinancials: viewConfig.view[0],
            choiceProductivity: viewConfig.view[1],
            trendAnalysis: viewConfig.view[2],
            productMixAndTrend: viewConfig.view[3],
            // shhh
            globalRegionVolumeAndTrend: (viewConfig.view[4] as unknown) as GeoTrendsConfigData,
          })
        );

        dispatch(getFinancialData(ownProps));
      });
    },
    onRefetchData() {
      dispatch(getFinancialData(ownProps));
    },
  };
}

const scopeSensitiveComponent = makeScopeSensitive(Summary);
// @ts-ignore
export default connect(selectAndProjectState, dispatchToProps)(scopeSensitiveComponent);
