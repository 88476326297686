import { combineReducers } from 'redux';
import { default as flowSheetReducer } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.slice';

import { default as pricingReducer } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';
import { default as assortmentPublishReducer } from './AssortmentPublish/AssortmentPublish.slice';
import visualizeReducer from 'src/components/Visualize/Visualize.slice';
import styleEditReducer from './StyleEdit/StyleEdit.slice';
import { default as assortmentAddReducer } from './AssortmentAdd/AssortmentAdd.slice';
import configurableGridReducer from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { default as floorsetComparisonReducer } from './FloorsetComparison/FloorsetComparison.slice';
import enhancedOvertimeReducer from 'src/components/EnhancedOvertime/EnhancedOvertime.slice';

const assortmentBuildReducers = combineReducers({
  flowSheet: flowSheetReducer,
  pricing: pricingReducer,
  addStyles: assortmentAddReducer,
  styleEdit: styleEditReducer,
  floorsetComparison: floorsetComparisonReducer,
  assortmentPublish: assortmentPublishReducer,
  visualize: visualizeReducer,
  enhancedOvertime: enhancedOvertimeReducer,
  configurableGrid: configurableGridReducer,
});

export default assortmentBuildReducers;

export interface AssortmentBuildSlice extends ReturnType<typeof assortmentBuildReducers> {}
