import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from 'src/store';
import Settings, { AdvisoryStyle, AncillaryPayload, LocalizationPayload, SettingsEntry } from '../../services/Settings';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { isNil } from 'lodash';

export const getSettings = createAsyncThunk<
  {
    settings: SettingsEntry[];
    localization: LocalizationPayload;
    ancillary: AncillaryPayload;
    advisoryStyles: AdvisoryStyle[];
  }, // return shape
  Settings,
  ThunkApi
>('settings/getSettings', async (settingsService, { getState, rejectWithValue }) => {
  const state = getState();
  const currentModule = getMfpModule(state);
  const siloId = currentModule?.siloId;

  if (isNil(siloId)) {
    return rejectWithValue('Unable to fetch settings, siloId is not present.');
  }

  const proms: [
    Promise<SettingsEntry[]>,
    Promise<LocalizationPayload>,
    Promise<AncillaryPayload>,
    Promise<AdvisoryStyle[]>
  ] = [
    settingsService.getSettings(siloId),
    settingsService.getLocalizationConfig(siloId),
    settingsService.getAncillaryConfig(siloId),
    settingsService.getAdvisoryConfig(siloId),
  ];
  return await Promise.all(proms).then((settingsData) => {
    return {
      settings: settingsData[0],
      localization: settingsData[1],
      ancillary: settingsData[2],
      advisoryStyles: settingsData[3],
    };
  });
});
