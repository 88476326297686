import { DropdownItemProps, StrictDropdownItemProps } from 'semantic-ui-react';
import { Command, CommandAnchor, CommandImport, CommandImportPrivate } from '../Commands';
import { isCommandAnchor } from '../Commands.utils';
import { get } from 'lodash';
import { SettingsByKey } from 'src/services/Settings';

export interface StricterDropdownItemProps extends StrictDropdownItemProps {
  value: string;
  command: Command;
}

export const commandToKey = (c: CommandAnchor | CommandImport | CommandImportPrivate): string => {
  return isCommandAnchor(c) ? `${c.type}${c.displaySource}${c.displayTime}` : `${c.type}${c.displaySource}`;
};

export const keyToCommand = (key: string, commands: Command[]): Command | undefined => {
  return commands.find((c) => {
    return isCommandAnchor(c)
      ? `${c.type}${c.displaySource}${c.displayTime}` === key
      : `${c.type}${c.displaySource}` === key;
  });
};

export const commandToTimeDropdown = (c: Command): StricterDropdownItemProps => {
  return {
    text: 'displayTime' in c ? c.displayTime : c.displaySource,
    value: commandToKey(c),
    command: c,
  };
};
/**
 * Function that turns a command into a "time" label, which is used where the interface
 * needs to display a command's time period, such as Reseed Plan Period, EOP Plan Period,
 * or initialization modal time.
 *
 * This function will attempt to find the time label from ancillary.json if it is a system version
 * `CommandAnchor` (such as for actuals), or the version name if not (such as RP or a saved version),
 * and fallback to other strings if a regular label isn't found.
 * @param c The command to label
 * @param entries The ancillary.json
 * @returns string label
 */
export const commandToTimeLabel = (c: Command, entries?: SettingsByKey): string => {
  // If it's a Anchor command, try and get the `displayTime` label,
  // otherwise, use `displaySource`,
  // and failing that, start case the display source as a final fallback
  return 'displayTime' in c
    ? get(entries, `revision.${c.displayTime}.display`)?.value || c.displayTime
    : get(entries, `revision.${c.displaySource}.display`)?.value || c.displaySource;
};
/**
 * Function that turns a command into a "version" label, which is used as a user-friendly
 * label where we need to show the originating version of the command.
 *
 * This is distinct from `commandToTimeLabel`, because sometimes we need to label the same version differntly,
 * depedning on context.  For example, for `ty-actuals`, we'd show "TY" as the "time" label,
 * and "Actuals" as the version
 *
 * This function will attempt to find the time label from ancillary.json if it is a system version
 * `CommandAnchor` (such as for actuals), or the version name if not (such as RP or a saved version),
 * and fallback to other strings if a regular label isn't found.
 * @param c The command to label
 * @param entries The ancillary.json
 * @returns string label
 */
export const commandToVersionLabel = (c: Command, entries?: SettingsByKey): string => {
  // If it's a Anchor command, try and get the `displayTime` label,
  // otherwise, use `displaySource`,
  // and failing that, start case the display source as a final fallback
  return get(entries, `revision.${c.displaySource}.display`)?.value || c.displaySource;
};

export const commandToDisplayDropdown = (c: Command, entries?: SettingsByKey): DropdownItemProps => {
  return {
    text: commandToVersionLabel(c, entries),
    value: commandToKey(c),
    command: c,
  };
};
