import { combineReducers } from 'redux';
import SizeEligibilityListGridReducer from 'src/pages/Allocation/SizeEligibilityListGrid/SizeEligibilityListGrid.slice';
import ParameterTogglesReducer, {
  ParameterTogglesSlice,
} from 'src/pages/Allocation/ParameterToggles/ParemeterToggles.slice';

export interface AllocationSlice {
  sizeEligibility: ReturnType<typeof SizeEligibilityListGridReducer>;
  parameterToggles: ParameterTogglesSlice;
}

export default combineReducers({
  sizeEligibility: SizeEligibilityListGridReducer,
  parameterToggles: ParameterTogglesReducer,
});
