import { style, media } from 'typestyle';

import { separationBorderColor } from 'src/utils/Style/Theme';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';

export const COLHEADERHEIGHT = 75;
export const STD_GROUPHEADERHEIGHT = 40;

const borderStyle = `1px solid ${separationBorderColor}`;
const printBorderStyle = '1px solid #000 !important'; // this overrides a bootstrap important & is just for the print media query
const colCard = 'col-card';
const colGroupHeader = 'column-grouping-header'; // container for individual column headers
const colRightCard = 'col-right-card';

function p(str: string): string {
  return `.${str}`;
}
const hoverButton = style({
  height: 'calc(100% - 18px)',
  position: 'absolute',
  width: 35,
  backgroundColor: 'rgba(155, 155, 155, .3)',
  zIndex: 1,
  border: 0,
});

const fabBtn = style({
  position: 'fixed',
  bottom: '45px',
  right: '45px',
  $nest: {
    '.fas': {
      fontSize: '1rem',
    },
  },
});

const rowGrouping = style({
  $debugName: 'rowGrouping',
  overflowY: 'auto',
});

export const cardContainer = style(
  media({ type: 'print' }, { display: 'block' }),
  media({ minWidth: 0, maxWidth: 1650 }, { height: `calc(100% - ${SUBHEADER_HEIGHT}px)` }),
  {
    $debugName: 'cardContainer',
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  }
);

// container for all the column headers
const groupsHeader = style(
  media(
    { type: 'print' },
    {
      display: 'inline',
    }
  ),
  {
    display: 'flex',
  }
);

// container for individual column headers
const groupHeader = style({
  $debugName: 'groupHeader',
  paddingTop: 10,
  display: 'inline-block',
  textAlign: 'center',
  $nest: {
    '> *': {
      display: 'inline-block',
    },
    '> h1': {
      fontWeight: 'bold',
      fontSize: '.9rem',
      paddingRight: 10,
    },
    '> h2': {
      fontWeight: 'normal',
      fontSize: '.75rem',
    },
    '> h2::before': {
      content: '" "',
      height: 20,
    },
    '& .add-all-checkbox': {
      padding: '3px 10px',
      width: '2em',
      height: '1.4em',
      display: 'inline-block',
    },
  },
});

const mainContainer = style({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'auto',
  zIndex: 'auto',
});

const colGroupCont = style(media({ minWidth: 0, maxWidth: 1650 }, { height: `calc(100% - ${SUBHEADER_HEIGHT}px)` }), {
  height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
  overflowY: 'hidden',
  width: '100%',
});

export const styles = {
  mainContainer,
  cardContainer,
  groupHeader,
  groupsHeader,
  inPrintMode: style({
    $debugName: 'inPrintMode',
    $nest: {
      [p(rowGrouping)]: {
        overflow: 'visible',
        $nest: {
          [p(groupHeader)]: {
            marginTop: 20,
            borderTop: borderStyle,
          },
        },
      },
      [p(cardContainer)]: {
        display: 'block',
        position: 'relative',
        overflow: 'visible',
        padding: 0,
      },
      [p(colGroupCont)]: {
        width: '100%',
        flexGrow: 1,
        display: 'block',
        overflow: 'visible',
      },
    },
  }),
  rowGrouping,
  columnGrouping: style(
    media(
      { type: 'print' },
      {
        // cards page break down below the max grid height when printing, so don't
        // show the border at the bottom
        // TODO: fix the borders in column group when printing
        border: printBorderStyle,
        borderBottom: 'none',
        $nest: {
          [p(colGroupHeader)]: {
            maxHeight: COLHEADERHEIGHT,
            borderBottom: printBorderStyle,
            $nest: {
              '> *': {
                display: 'block',
              },
              '&:not(:first-of-type)': {
                borderLeft: printBorderStyle,
              },
            },
          },
          [p(colRightCard)]: {
            borderRight: printBorderStyle,
          },
          [p(colCard)]: {
            display: 'inline-block',
            verticalAlign: 'top',
            position: 'static',
          },
          '.border-right': {
            borderRight: printBorderStyle,
          },
          ['header' + p(groupsHeader)]: {
            // this has to be more specific here due to a bug that makes
            // print styles not override the exact same selector
            display: 'inline',
          },
        },
      }
    ),
    {
      $debugName: 'columnGrouping',
      overflow: 'visible',
      border: borderStyle,
      $nest: {
        [p(colGroupHeader)]: {
          maxHeight: COLHEADERHEIGHT,
          borderBottom: borderStyle,
          $nest: {
            '> *': {
              display: 'block',
            },
            '&:not(:first-of-type)': {
              borderLeft: borderStyle,
            },
          },
        },
        [p(colRightCard)]: {
          borderRight: borderStyle,
        },
        [p(colCard)]: {
          paddingTop: 15,
        },
        '.border-right': {
          borderRight: borderStyle,
        },
      },
    }
  ),
  cardGroupsContainer: style({
    $debugName: 'cardGroupsContainer',
    display: 'flex',
    $nest: {
      '.group-container': {
        flex: 1,
      },
      '.border-right': {
        borderRight: borderStyle,
      },
    },
  }),
  colGroupCont,
  colGroupHeader,
  colRightCard,
  colCard,
  hoverButton,
  fabBtn,
};
