import { style } from 'typestyle';
import { primaryTextColor } from 'src/utils/Style/Theme';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';

export const ACCORDION_HEADER_HEIGHT = 35;
export const KEY_FINANCIAL_EXPANDED_HEIGHT = 135;
export const CHOICE_PRODUCTIVITY_EXPANDED_HEIGHT = 200;

export function categorySummaryStyle(financialsOpen: boolean, productivityOpen: boolean) {
  const financialsOffset = financialsOpen ? KEY_FINANCIAL_EXPANDED_HEIGHT : ACCORDION_HEADER_HEIGHT;
  const productivityOffset = productivityOpen ? CHOICE_PRODUCTIVITY_EXPANDED_HEIGHT : ACCORDION_HEADER_HEIGHT;
  const offset = SUBHEADER_HEIGHT + financialsOffset + productivityOffset;

  return style({
    height: '100%',
    $nest: {
      '.category-summary-container': {
        height: '100%',
      },
      '.choice-productivity-container': {
        display: 'flex',
        $nest: {
          '&.print': {
            height: 'auto',
          },
          '.fin-wrapper': {
            display: 'flex',
            width: '20%',
            alignItems: 'center',
            minWidth: 220,
            // override key financial item styles when rendered in choice productivity
            $nest: {
              '.key-financial-item': {
                backgroundColor: 'white',
              },
              header: {
                fontWeight: 300,
              },
              '.primary': {
                color: primaryTextColor,
                flex: 'none',
                marginRight: '1rem',
              },
            },
          },
          '.choice-wrapper': {
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
          },
        },
      },
      '.grid-container': {
        height: `calc(100% - calc(${offset}px + 0.15rem))`,
        $nest: {
          '&.print': {
            height: 'unset',
            overflowX: 'auto',
          },
          '.data-grid': {
            height: `calc(100% - ${ACCORDION_HEADER_HEIGHT}px)`,
            fontSize: '0.8rem',
          },
        },
      },
    },
  });
}
