import React from 'react';
import { identity, pick, isNil } from 'lodash';
import { connect } from 'react-redux';

import { AppState, clearCachedData } from 'src/store';
import { wrappedDispatch, WrappedDispatch } from 'src/utils/Redux/Dispatch';

export interface ViewRefreshable {
  // TODO make this something else and refactor all the impls to have an onRefetchData dispatcher
  onShowView?(): void;
  onRefetchData?(): void;
}

export interface InjectedProps<ParentProps> {
  scopeLastUpdated: number;
  filterLastUpdated: number;
  parentProps: ParentProps;
  refreshRequestedAt: number;
  onRefetchData?(): void;
}

export interface State {
  scopeLastUpdated: number;
  filterLastUpdated: number;
  refreshRequestedAt: number;
  callLocalFunctions?: () => void;
}

export interface Nameable {
  displayName?: string;
  name?: string;
}

function mergeProps<P extends ViewRefreshable>(
  state: AppState,
  _wrapped: WrappedDispatch,
  parentProps: P
): InjectedProps<P> {
  return {
    onRefetchData: parentProps.onRefetchData || parentProps.onShowView || undefined,
    scopeLastUpdated: state.scope.updatedAt || 0,
    filterLastUpdated: state.filters.updatedAt || 0,
    refreshRequestedAt: state.planTracker.refreshRequestedAt || 0,
    parentProps,
  };
}

export function getDisplayName(nameable: Nameable) {
  return nameable.displayName || nameable.name || 'Component';
}
function vGreaterZero(v: number | null | undefined) {
  return !isNil(v) && v > 0;
}

export function makeScopeSensitive<P>(WrappedComponent: React.ComponentType<P>) {
  class ScopeSensitive extends React.Component<InjectedProps<P>, State> {
    static getDerivedStateFromProps(nextProps: InjectedProps<P>, state: State) {
      // scopeLastUpdated is only set after Receive_Scope_After_Set, which causes weirdness with how the
      // undef's come through here (since it comes through undef until scope is reset in an active session)
      if (vGreaterZero(state.scopeLastUpdated)) {
        const isStale = nextProps.scopeLastUpdated > state.scopeLastUpdated;
        const refreshStale =
          vGreaterZero(nextProps.refreshRequestedAt) && nextProps.refreshRequestedAt > state.refreshRequestedAt;
        if (isStale || refreshStale) {
          // All data is stale, clear the cached data in Style and StyleColor Review.
          clearCachedData();
          if (nextProps.onRefetchData) {
            nextProps.onRefetchData();
          } else if (state.callLocalFunctions) {
            state.callLocalFunctions();
          }
        }
      }
      return pick(nextProps, 'scopeLastUpdated', 'refreshRequestedAt');
    }
    wrappedComponentRef: React.RefObject<ViewRefreshable> = React.createRef();
    displayName = `ScopeSensitive(${getDisplayName(WrappedComponent)})`;
    constructor(props: InjectedProps<P>) {
      super(props);
      this.state = {
        scopeLastUpdated: -1,
        filterLastUpdated: -1,
        refreshRequestedAt: -1,
        callLocalFunctions: () => {
          const ref = this.wrappedComponentRef;
          if (ref && ref.current) {
            if (ref.current.onShowView) {
              ref.current.onShowView();
            }
            if (ref.current.onRefetchData) {
              ref.current.onRefetchData();
            }
          }
        },
      };
    }

    render() {
      return (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          <WrappedComponent ref={this.wrappedComponentRef} {...this.props.parentProps} />
        </div>
      );
    }
  }
  // @ts-ignore
  return connect<AppState, WrappedDispatch, P, InjectedProps<P>>(identity, wrappedDispatch, mergeProps)(ScopeSensitive);
}
