import { ViewDefnState, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ParetoSummaryPivot } from 'src/components/views/ParetoAnalysis/ParetoSummary';
import { ViewDataState } from 'src/types/Domain';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cacheCheckFetchPivotData } from '../../StyleColorReview/StyleColorReview.slice';
import serviceContainer from 'src/ServiceContainer';
import { FitViewOptions, ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export type ParetoPage = 'summary' | 'details';
export interface ParetoAnalysisSlice {
  configureInstructions?: string;
  summary?: ParetoSummaryPivot;
  viewDefns: TenantConfigViewData[];
  viewDefnState: ViewDefnState;
  summaryDataState: ViewDataState;
  analysisDataState: ViewDataState;
  summaryCacheHash: string | null;
  analysisCacheHash: string | null;
}

const initialState: ParetoAnalysisSlice = {
  summary: undefined,
  viewDefns: [],
  viewDefnState: ViewDefnState.idle,
  summaryDataState: ViewDataState.idle,
  analysisDataState: ViewDataState.idle,
  summaryCacheHash: null,
  analysisCacheHash: null,
};

const paretoAnalysisReducer = createSlice({
  name: 'ParetoAnalysis',
  initialState,
  reducers: {
    requestParetoAnalysisTenantConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveParetoAnalysisTenantConfig(state, action: PayloadAction<TenantConfigViewData[]>) {
      state.viewDefnState = ViewDefnState.loaded;
      state.viewDefns = action.payload;
    },
    updateItemStripConfig(state, action: PayloadAction<TenantConfigViewData>) {
      const viewDefns = state.viewDefns;
      // replace item strip view defn in state
      if (viewDefns.length > 1) {
        viewDefns[1] = action.payload;
      }
    },
    requestParetoSummaryData(state) {
      state.summaryDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveParetoSummaryCacheHash(state, action: PayloadAction<string>) {
      state.summaryCacheHash = action.payload;
    },
    receiveParetoSummaryCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.summaryCacheHash) {
        state.summaryDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveParetoSummaryLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.summaryCacheHash) {
        state.summaryDataState = ViewDataState.liveDataReady;
      }
    },
    requestParetoAnalysisData(state) {
      state.analysisDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveParetoAnalysisCacheHash(state, action: PayloadAction<string>) {
      state.analysisCacheHash = action.payload;
    },
    receiveParetoAnalysisCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.analysisCacheHash) {
        state.analysisDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveParetoAnalysisLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.analysisCacheHash) {
        state.analysisDataState = ViewDataState.liveDataReady;
      }
    },
    cleanParetoAnalysisUp() {
      return initialState;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
  },
});

export const {
  requestParetoAnalysisTenantConfig,
  receiveParetoAnalysisTenantConfig,
  updateItemStripConfig,
  requestParetoSummaryData,
  receiveParetoSummaryCacheHash,
  receiveParetoSummaryCachedData,
  receiveParetoSummaryLiveData,
  requestParetoAnalysisData,
  receiveParetoAnalysisCacheHash,
  receiveParetoAnalysisCachedData,
  receiveParetoAnalysisLiveData,
  cleanParetoAnalysisUp,
  receiveError,
} = paretoAnalysisReducer.actions;

export function fetchParetoSummaryData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestParetoSummaryData,
    receiveParetoSummaryCacheHash,
    receiveParetoSummaryCachedData,
    receiveParetoSummaryLiveData
  );
}

export function fetchParetoAnalysisData(modelDefn: string, options: FitViewOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.fitViewCacheCheck(modelDefn, options),
    requestParetoAnalysisData,
    receiveParetoAnalysisCacheHash,
    receiveParetoAnalysisCachedData,
    receiveParetoAnalysisLiveData
  );
}

export default paretoAnalysisReducer.reducer;
