import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { isNil } from 'lodash';

export interface ProcessedDataSelector
  extends OutputSelector<
    AppState,
    BasicPivotItem[] | null,
    (res: BasicPivotItem[] | null) => BasicPivotItem[] | null
  > {}

const getHistoryStylePane = (state: AppState) => {
  return state.historyStylePane.currentItem;
};

function processCurrentItem(item: BasicPivotItem[] | null): BasicPivotItem[] | null {
  return isNil(item) ? null : item;
}

export const getProcessedCurrentItem: ProcessedDataSelector = createSelector(getHistoryStylePane, (currentItem) => {
  return processCurrentItem(currentItem);
});
