import classNames from 'classnames';
import React from 'react';
import './_LockToggle.scss';
import { defaultTo } from 'lodash';

export interface LockToggleProps {
  active?: boolean,
  hidden?: boolean,
  onClick?: () => void
}

export default class LockToggle extends React.Component<LockToggleProps> {
  public render() {
    const activeClass = this.props.active ? 'active' : '';
    const hidden = defaultTo(this.props.hidden, false);
    const noLocksText = 'Default';
    const unlockAllText = 'Reset';
    const text = this.props.active ? unlockAllText : noLocksText;
    const iconClass = this.props.active ? 'fa-unlock' : 'fa-lock';

    return (
      <div
        className={classNames('lock-toggle', activeClass)}
        onClick={this.props.onClick}
        hidden={hidden}
      >
        {text}
        <span className={classNames('lock-icon-container')}>
          <i className={classNames('fa', iconClass)} />
        </span>
      </div>
    );
  }
}
