import React, { ReactNode } from 'react';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import './_DraggableContainer.scss';

const SortableItem = SortableElement(({ value }: { value: ReactNode }) => {
  return <React.Fragment>{value}</React.Fragment>;
});
const SortableList = SortableContainer(({ items }: { items: React.ReactNode[] }) => {
  return (
    <div className="draggable-container">
      {items.map((value: ReactNode, index: number) => {
        return <SortableItem key={index} index={index} value={value} />;
      })}
    </div>
  );
});

export interface DCState {
  items: React.ReactNode[];
}

export interface DCProps {
  children: React.ReactNode | React.ReactNode[];
  id?: string;
}

class DraggableContainer extends React.Component<DCProps, DCState> {
  public static getDerivedStateFromProps(props: DCProps, state: DCState) {
    let items;
    if (props.children instanceof Array) {
      items = props.children;
    } else {
      items = [props.children];
    }
    return {
      ...state,
      items,
    };
  }

  constructor(props: DCProps) {
    super(props);
    let items;
    if (this.props.children instanceof Array) {
      items = this.props.children;
    } else {
      items = [this.props.children];
    }
    this.state = {
      items,
    };
  }
  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { items } = this.state;

    this.setState({
      items: arrayMove(items, oldIndex, newIndex),
    });
  };
  public render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} useDragHandle={true} />;
  }
}
export default DraggableContainer;
