import React from 'react';
import ColorSwatchItem, { ColorSwatchItemProps } from './ColorSwatchItem';

import { style as typeStyle } from 'typestyle';
import { noop } from 'lodash';

export interface Props extends React.HTMLAttributes<{}> {
  selected: number;
  colors: ColorSwatchItemProps[];
  colorItemClassName?: string;
  colorItemStyle?: React.CSSProperties;
  noImageUrl: string;
  renderTooltip?: boolean;
  onChangeColor?: (id: string, position: number) => void;
  getSwatchUrl: (colorId: string) => string;
}

const ColorSwatch = (props: Props) => {
  const {
    colors,
    colorItemClassName,
    colorItemStyle,
    className,
    selected,
    style,
    renderTooltip = true,
    onChangeColor,
    getSwatchUrl,
  } = props;

  const colorItems = colors.map((colorItem, index) => {
    return (
      <ColorSwatchItem
        key={colorItem.id}
        swatchPath={getSwatchUrl(colorItem.colorId)}
        colorId={colorItem.colorId}
        id={colorItem.id}
        selected={index === selected}
        className={colorItemClassName}
        style={colorItemStyle}
        noImageUrl={props.noImageUrl}
        renderTooltip={renderTooltip}
        onClick={() => {
          onChangeColor ? onChangeColor(colorItem.id, index) : noop();
        }}
      />
    );
  });

  const swatchClass = typeStyle({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  });

  return (
    <div
      className={className ? className : swatchClass}
      style={{
        ...style,
      }}
    >
      {colorItems}
    </div>
  );
};

export default ColorSwatch;
