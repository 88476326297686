import React from 'react';
import MacroSummaries from 'src/components/Mfp/MacroSummaries/MacroSummaries';
import type { ICellRendererParams } from '@ag-grid-community/core';

type MacroSummaryDetailRendererProps = ICellRendererParams;

export const MacroSummaryDetailRenderer = (_props: MacroSummaryDetailRendererProps) => {
  return (
    <div>
      <MacroSummaries showMinimizableItem={false} />
    </div>
  );
};
