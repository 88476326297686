import { isNil } from 'lodash';
import { OutputSelector, createSelector } from 'reselect';
import { BoundEnabledPerspective } from 'src/services/configuration/codecs/bindings.types';
import { AppContext, CartButton, Context, EditStyleButton } from 'src/services/configuration/codecs/confdefn';
import { QuickReconButton } from 'src/services/configuration/codecs/confdefn';
import { getPerspectiveLookupKey } from 'src/services/configuration/service';
import { AppState } from 'src/store';

interface ActiveTabContextIndexSelector
  extends OutputSelector<AppState, number, (appContext: AppContext, activeTab: string) => number> {}

interface ActiveContextSelector
  extends OutputSelector<
    AppState,
    Context | undefined,
    (appContext: AppContext, activeTabContextIndex: number) => Context | undefined
  > {}

interface ActiveContextStringSelector
  extends OutputSelector<AppState, string, (appContext: AppContext, activeTabContextIndex: number) => string> {}

interface PerspectiveEditStyleButtonConfigSelector
  extends OutputSelector<
    AppState,
    EditStyleButton | undefined,
    (
      activePerspective: BoundEnabledPerspective | null,
      activeContext: Context | undefined
    ) => EditStyleButton | undefined
  > {}

interface PerspectiveCartButtonConfigSelector
  extends OutputSelector<
    AppState,
    CartButton | undefined,
    (activePerspective: BoundEnabledPerspective | null, activeContext: Context | undefined) => CartButton | undefined
  > {}

interface QuickReconButtonConfigSelector
  extends OutputSelector<
    AppState,
    QuickReconButton | undefined,
    (
      activePerspective: BoundEnabledPerspective | null,
      activeContext: Context | undefined
    ) => QuickReconButton | undefined
  > {}

const getAppContextConfig = (state: AppState): AppContext => {
  return state.appConfig.tenantConfig.context;
};

const getActiveTab = (state: AppState): string => {
  return state.perspective.activeTab.toLowerCase().replace('-', '');
};

export const getActivePerspective = (state: AppState): BoundEnabledPerspective | null => {
  return state.perspective.selected;
};

export const getPerspectiveCanScopeWithFloorsets = (state: AppState): boolean => {
  return state.perspective.selected?.canScopeWithFloorsets ? state.perspective.selected.canScopeWithFloorsets : false;
};

const getActiveTabContextIndex: ActiveTabContextIndexSelector = createSelector(
  getAppContextConfig,
  getActiveTab,
  (appContext, activeTab) => {
    // find the index of the context that contains the activeTab
    return Object.values(appContext)
      .map((ctx) => ctx.tabs.map((tab) => tab.toLowerCase()))
      .findIndex((x) => x.indexOf(activeTab) > -1);
  }
);

export const getActiveContext: ActiveContextSelector = createSelector(
  [getAppContextConfig, getActiveTabContextIndex],
  (appContext, activeContextIndex) => {
    return activeContextIndex > -1 ? Object.values(appContext)[activeContextIndex] : undefined;
  }
);

export const getActiveContextString: ActiveContextStringSelector = createSelector(
  [getAppContextConfig, getActiveTabContextIndex],
  (appContext, activeContextIndex) => {
    return activeContextIndex > -1 ? Object.keys(appContext)[activeContextIndex] : '';
  }
);

export const getActivePerspectiveEditStyleButtonConfig: PerspectiveEditStyleButtonConfigSelector = createSelector(
  [getActivePerspective, getActiveContext],
  (activePerspective, activeContext) => {
    if (isNil(activePerspective) || isNil(activeContext)) {
      return undefined;
    }

    // only return the config if the button is enabled (by configuration) in the active perspective
    const perspectiveLookupKey = getPerspectiveLookupKey(activePerspective);
    const enabledInPerspective = isNil(activeContext?.editStyleBtn)
      ? undefined
      : activeContext.editStyleBtn.inPerspectives.includes(perspectiveLookupKey);
    return enabledInPerspective ? activeContext?.editStyleBtn : undefined;
  }
);

export const getActivePerspectiveCartButtonConfig: PerspectiveCartButtonConfigSelector = createSelector(
  [getActivePerspective, getActiveContext],
  (activePerspective, activeContext) => {
    if (isNil(activePerspective) || isNil(activeContext)) {
      return undefined;
    }

    // only return the config if the button is enabled (by configuration) in the active perspective
    const perspectiveLookupKey = getPerspectiveLookupKey(activePerspective);
    const enabledInPerspective = isNil(activeContext?.cartBtn)
      ? undefined
      : activeContext.cartBtn.inPerspectives.includes(perspectiveLookupKey);
    return enabledInPerspective ? activeContext?.cartBtn : undefined;
  }
);

export const getActivePerspectiveQuickReconButtonConfig: QuickReconButtonConfigSelector = createSelector(
  [getActivePerspective, getActiveContext],
  (activePerspective, activeContext) => {
    if (isNil(activePerspective) || isNil(activeContext)) {
      return undefined;
    }

    // only return the config if the button is enabled (by configuration) in the active perspective
    const perspectiveLookupKey = getPerspectiveLookupKey(activePerspective);
    const enabledInPerspective = isNil(activeContext?.quickReconBtn)
      ? undefined
      : activeContext.quickReconBtn.inPerspectives.includes(perspectiveLookupKey);
    return enabledInPerspective ? activeContext?.quickReconBtn : undefined;
  }
);
