import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DaysRangeListResponse,
  DimensionConfigResponse,
  DimensionHierTreeResponse,
  FloorsetConfigResponse,
  PlanDatesResponse,
  RangeItem,
  Scope,
  SelectionErrors,
} from 'src/types/Scope';
import { DimensionMap } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.selectors';
import { QuickSelect, ScopeConfigResponse } from 'src/dao/scopeClient';
import { TimeSheetInfo } from 'src/worker/pivotWorker.types';
import { ASSORTMENT_CONTEXT, HINDSIGHTING_CONTEXT } from 'src/utils/Domain/Constants';
export const initialScope: Scope = {
  productLevel: null,
  productMember: null,
  productMemberName: null,
  locationLevel: null,
  locationMember: null,
  locationMemberName: null,
  floorSet: null,
  historyFloorset: null,
  start: null,
  end: null,
  startSales: null,
  endSales: null,
  historyStart: null,
  historyEnd: null,
  valid: false,
};
interface ScopeRefresh {
  scope: Scope;
  updatedAt: number;
}

export interface AssortmentScopeSelectorSlice {
  initialLoad: boolean;
  updatedAt?: number;
  scopeConfig: Record<string, any>;
  scope: Scope;
  scopeLoading: boolean;
  floorsetLoading: boolean;
  scopeSelected: boolean;
  scopeError: boolean | string;
  selections: Scope;
  selectionErrors: SelectionErrors;
  selectionsValid: boolean;
  dimensionConfig: DimensionConfigResponse | null;
  dimensionHierConfig: DimensionHierTreeResponse | null;
  productDimension: DimensionMap | null;
  locationDimension: DimensionMap | null;
  currentProductLevel?: string;
  currentLocationLevel?: string;
  currentAssortmentStartWeek?: string;
  currentAssortmentEndWeek?: string;
  currentFloorSet?: string;
  floorsetConfig: FloorsetConfigResponse;
  quickSelects: QuickSelect[];
  lyFloorsetConfig: FloorsetConfigResponse;
  rangeList: RangeItem[];
  rangeListExtended: RangeItem[];
  pastRangeList: RangeItem[];
  daysRangeList: DaysRangeListResponse;
  daysRangeListExtended: DaysRangeListResponse;
  daysPastRangeList: DaysRangeListResponse;
  planCurrent: any;
  planStart: any;
  planEnd: any;
  timeInfo: TimeSheetInfo;
}
const initialState: AssortmentScopeSelectorSlice = {
  initialLoad: true,
  updatedAt: undefined,
  dimensionConfig: null,
  dimensionHierConfig: null,
  selections: initialScope,
  selectionErrors: {},
  selectionsValid: false,
  productDimension: null,
  locationDimension: null,
  scopeLoading: false,
  floorsetLoading: false,
  scopeSelected: false,
  scopeError: false,
  scopeConfig: {},
  scope: initialScope,
  floorsetConfig: [],
  quickSelects: [],
  lyFloorsetConfig: [],
  rangeList: [],
  rangeListExtended: [],
  pastRangeList: [],
  daysRangeList: {
    start_date: {},
    end_date: {},
  },
  daysRangeListExtended: {
    start_date: {},
    end_date: {},
  },
  daysPastRangeList: {
    start_date: {},
    end_date: {},
  },
  planCurrent: null,
  planStart: null,
  planEnd: null,
  timeInfo: {
    entries: [],
    planCurrent: '',
  },
};

const AssortmentScopeSelectorSlice = createSlice({
  name: 'AssortmentScopeSelector',
  initialState,
  reducers: {
    changeScope(state) {
      state.scopeSelected = false;
    },
    closeScope(state) {
      state.scopeSelected = true;
    },
    requestScopeConfig(state) {
      state.initialLoad = false;
      state.scopeLoading = true;
    },
    receiveScopeConfig(state, action: PayloadAction<ScopeConfigResponse>) {
      const { dimensionConfig, daysList, pastDaysList, pastRangeList, rangeList, timeInfo } = action.payload;
      const { product, location } = dimensionConfig;
      const { scope } = state;
      const initialLevelScopeSelection = {
        productLevel: product.levelInfos.length === 1 ? product.levelInfos[0].level.id : scope.productLevel,
        locationLevel: location.levelInfos.length === 1 ? location.levelInfos[0].level.id : scope.locationLevel,
      };
      return {
        ...state,
        scopeLoading: false,
        rangeList,
        pastRangeList,
        rangeListExtended: action.payload.rangeListExtended,
        daysRangeList: daysList,
        daysPastRangeList: pastDaysList,
        daysRangeListExtended: action.payload.daysListExtended,
        dimensionConfig: dimensionConfig,
        dimensionHierConfig: action.payload.dimensionHierConfig,
        timeInfo,
        selections: {
          ...state.selections,
          ...initialLevelScopeSelection,
        },
      };
    },
    changeScopeSelection(state, action: PayloadAction<Partial<Scope>>) {
      return {
        ...state,
        selections: {
          ...state.selections,
          ...action.payload,
        },
      };
    },
    requestFloorsetConfig(state, _action: PayloadAction<string>) {
      state.floorsetLoading = true;
    },
    clearFloorsetAndQuickSelect(state, action: PayloadAction<string | undefined>) {
      const region = action.payload;
      return {
        ...state,
        floorsetLoading: false,
        floorsetConfig: [],
        selections: {
          ...state.selections,
          floorSet: !region || region == ASSORTMENT_CONTEXT ? null : state.selections.floorSet,
          historyFloorset: !region || region == HINDSIGHTING_CONTEXT ? null : state.selections.floorSet,
        },
      };
    },
    receiveScopeError(state, action: PayloadAction<string>) {
      state.scopeLoading = false;
      state.scopeError = action.payload;
      // if we're in "initialLoad" state and we receive an error, set initialLoad to false,
      // which controls whether the scopeSelector appears or not.  We need this here so that the scope
      // selector can appear in the event the users gets a bad scope
      state.initialLoad = state.initialLoad ? false : state.initialLoad;
    },
    receiveFloorsetConfig(state, action: PayloadAction<FloorsetConfigResponse>) {
      state.floorsetLoading = false;
      state.floorsetConfig = action.payload;
    },
    receiveQuickSelects(state, action: PayloadAction<QuickSelect[]>) {
      state.quickSelects = action.payload;
    },
    requestScope(state) {
      state.scopeLoading = true;
      state.scopeSelected = false;
    },
    receiveScopeRefreshTrigger(state, action: PayloadAction<ScopeRefresh>) {
      state.scope = action.payload.scope;
      state.updatedAt = action.payload.updatedAt;
    },
    receiveScope(state, action: PayloadAction<ScopeRefresh>) {
      const { scope, updatedAt } = action.payload;
      const isScopeSelected = scope.valid;
      return {
        ...state,
        scopeSelected: isScopeSelected,
        initialLoad: false,
        scopeLoading: false,
        selections: scope,
        scope,
        updatedAt,
      };
    },
    sendScope(state) {
      state.scopeLoading = true;
    },
    scopeSent(state) {
      state.scopeLoading = false;
    },
    validateAllSelections(state) {
      const selectionErrors = {} as SelectionErrors;
      let selectionsValid = true;

      if (!state.selections.productLevel) {
        selectionErrors.productLevel = 'Required';
      }

      if (!state.selections.productMember) {
        selectionErrors.productMember = 'Required';
      }

      if (!state.selections.locationLevel) {
        selectionErrors.locationLevel = 'Required';
      }

      if (!state.selections.locationMember) {
        selectionErrors.locationMember = 'Required';
      }

      if (!state.selections.historyEnd) {
        selectionErrors.historyEnd = 'Required';
      }

      if (!state.selections.historyStart) {
        selectionErrors.historyStart = 'Required';
      }

      Object.keys(selectionErrors).forEach((selection) => {
        if (selectionErrors[selection]) {
          selectionsValid = false;
        }
      });

      return {
        ...state,
        selectionsValid,
        selectionErrors,
      };
    },
    getPlanDates(state, action: PayloadAction<PlanDatesResponse>) {
      state.planCurrent = action.payload.planCurrent;
      state.planStart = action.payload.planStart;
      state.planEnd = action.payload.planEnd;
    },
    resetScope() {
      return initialState;
    },
    resetOnlyScope(state) {
      state.scope = initialScope;
    },
  },
});

export const {
  changeScope,
  closeScope,
  requestScopeConfig,
  receiveScopeConfig,
  changeScopeSelection,
  requestFloorsetConfig,
  clearFloorsetAndQuickSelect,
  receiveScopeError,
  receiveFloorsetConfig,
  receiveQuickSelects,
  requestScope,
  receiveScopeRefreshTrigger,
  receiveScope,
  sendScope,
  scopeSent,
  validateAllSelections,
  getPlanDates,
  resetScope,
  resetOnlyScope,
} = AssortmentScopeSelectorSlice.actions;

export default AssortmentScopeSelectorSlice.reducer;
