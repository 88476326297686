import React, { Fragment, useState } from 'react';

import ScopebarOptionModal, { ScopebarOptionModalProps } from './ScopebarOptionModal';
import { isNil } from 'lodash';
import { AnyAction } from 'redux';

type ScopebarOptionProps = {
  dataQa: string,
  text: string,
  iconClass: string,
  isDisabled: boolean,
  optionModalProps: ScopebarOptionModalProps | null,
  itemClass?: string,
  onClick?: () => void | AnyAction | undefined // if undefined, clicking scope option just toggles modal
};

export function useToggle(initialValue: boolean): [boolean, () => void] {
  const [value, setValue] = useState(initialValue);
  const toggleValue = () => setValue(!value);
  return [value, toggleValue];
}

const ScopebarOption = (props: ScopebarOptionProps) => {
  const [isVisible, toggleModal] = useToggle(false);
  const onOptionClick = () => {
    toggleModal();

    if (props.onClick) {
      props.onClick();
    }
  };

  const { dataQa, text, iconClass, isDisabled, optionModalProps } = props;
  let onAction = (id?: number | undefined) => toggleModal();
  let handleCancel = onAction;
  let handleSubmit = onAction;


  // setup parent handlers to also close current modal on submit/cancel
  if (!isNil(optionModalProps)) {
    const { onCancel, onSubmit } = optionModalProps.modalBodyComponentProps;

    if (!isNil(onCancel)) {
      handleCancel = () => {
        onCancel();
        onAction();
      };
    }

    if (!isNil(onSubmit)) {
      handleSubmit = (id) => {
        onSubmit(id);
        onAction();
      };
    }
  }

  // include modal toggle handler in all 'action' functions passed to modal component
  // this allows the modal action buttons to close the modal along with clicking the scopebar button again
  const newModalProps = optionModalProps ? {
    ...optionModalProps,
    modalBodyComponentProps: {
      ...(optionModalProps.modalBodyComponentProps),
      onCancel: handleCancel,
      onSubmit: handleSubmit
    }
  } : undefined;

  return (
    <Fragment>
      <li className={props.itemClass ? `scope-options-item ${props.itemClass}` : 'scope-options-item'}>
        <button
          className={'scope-options-button'}
          data-qa={dataQa}
          onClick={onOptionClick}
          disabled={isDisabled}
          type="button"
        >
          <i className={iconClass} />
          {text}
        </button>
      </li>

      {!isNil(optionModalProps) && newModalProps && <ScopebarOptionModal isVisible={isVisible} {...newModalProps} />}
    </Fragment>
  );
};

export default ScopebarOption;
