import React from 'react';
import cn from 'classnames';
import { ColDef, ProcessCellForExportParams, GridApi, GridReadyEvent, ColumnApi } from '@ag-grid-community/core';

import { Overlay } from 'src/common-ui';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps, Gridable } from 'src/common-ui/components/DataGrid/DataGrid';

import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import Subheader from 'src/components/Subheader/Subheader.container';

import { categorySummaryStyle } from './CategorySummary.styles';
import { StateProjection } from './CategorySummary.selectors';
import { isNumber, isEqual } from 'lodash';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { multiHeaderDecorate } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/NestedHeader';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';
import { ChoiceProductivityAccordion } from 'src/common-ui/components/Macros/ChoiceProductivity/ChoiceProductivityAccordion';
import { KeyFinancialsAccordion } from 'src/common-ui/components/Macros/KeyFinancial/KeyFinancialsAccordion';

export interface PerformanceDetails {
  columnDefs: ColDef[];
  data: Gridable[];
}

interface CategorySummaryProps {
  groupByDefn: string;
}

interface CategorySummaryState {
  keyFinancialsExpanded: boolean;
  choiceProductivityExpanded: boolean;
}

export interface FunctionProps {
  onShowView(): void;
  onConfigUpdate(config: TenantConfigViewData): void;
}

export type Props = StateProjection & CategorySummaryProps & FunctionProps;

export default class CategorySummary extends React.Component<Props, CategorySummaryState> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;

  constructor(props: Props) {
    super(props);

    this.state = {
      keyFinancialsExpanded: true,
      choiceProductivityExpanded: true,
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.gridApi) {
      if (this.props.configLoaded && !isEqual(prevProps.colDefs, this.props.colDefs)) {
        this.gridApi.setColumnDefs(multiHeaderDecorate(this.props.colDefs));
      }
    }
  }

  toggleFinancialsAccordion = () => {
    this.setState((state) => ({
      keyFinancialsExpanded: !state.keyFinancialsExpanded,
    }));
  };

  toggleProductivityAccordion = () => {
    this.setState((state) => ({
      choiceProductivityExpanded: !state.choiceProductivityExpanded,
    }));
  };

  render() {
    if (!this.props.configLoaded) {
      return <Overlay type="loading" visible={true} />;
    }

    const { title, groupByDefn, viewDataState, gridData } = this.props;
    const { keyFinancialsExpanded, choiceProductivityExpanded } = this.state;

    const subheaderProps: SubheaderOwnProps = {
      title,
      showFlowStatus: true,
      showSearch: true,
      groupByDefn,
      viewDataState,
    };

    const {
      keyFinancials,
      colDefs,
      frameworkComponents,
      isPrintMode,
      choiceProductivity,
      unmodifiedViewDefn,
      viewDefn,
      dataLoaded,
      onConfigUpdate,
    } = this.props;

    subheaderProps.viewConfigurator = {
      viewConfig: viewDefn,
      unmodifiedViewDefn,
      updateConfig: onConfigUpdate,
      showPinCheckboxForGrid: true,
      getColumnApi: () => {
        if (this.columnApi) {
          return this.columnApi;
        }
        return;
      },
    };

    const gridOptions: DataGridProps = {
      isPrintMode,
      columnDefs: colDefs,
      frameworkComponents,
      data: gridData,
      loaded: dataLoaded,
      rowHeight: 30,
      treeColumnDefinition: undefined,
      onGridReady: (event: GridReadyEvent) => {
        if (event.api && event.columnApi) {
          this.gridApi = event.api;
          this.columnApi = event.columnApi;
        }
      },
      exportOptions: {
        fileName: title,
        processCellOverride: (params: ProcessCellForExportParams) => {
          // NaN values are presented as strings 'NaN'
          const override = (isNaN(params.value) && isNumber(params.value)) || params.value === 'NaN';
          return !override ? params.value : '0.00';
        },
      },
    };

    return (
      <div className={categorySummaryStyle(keyFinancialsExpanded, choiceProductivityExpanded)}>
        <Subheader {...subheaderProps} />
        <div className="category-summary-container">
          <React.Fragment>
            <KeyFinancialsAccordion
              dataLoaded={dataLoaded}
              macros={keyFinancials}
              onToggleAccordion={this.toggleFinancialsAccordion}
            />
            <ChoiceProductivityAccordion
              dataLoaded={dataLoaded}
              macros={choiceProductivity}
              onToggleAccordion={this.toggleProductivityAccordion}
            />
            <section className={cn('grid-container', { print: isPrintMode })}>
              <StyledAccordion expanded={false} expandable={false} title="Performance Details"></StyledAccordion>
              <ExtendedDataGrid {...gridOptions} />
            </section>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
