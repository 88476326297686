import { flatten as fpFlatten, map as fpMap } from 'lodash/fp';

import { SubheaderDropdownSlice } from '../Subheader/Subheader.slice';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { DIMENSION_PRODUCT } from 'src/utils/Domain/Constants';
import { isEmpty, isEqual, set, concat, some } from 'lodash';

type FlattenAndLabelGroupArgs = {
  items: BasicPivotItem[];
  groupDataIndex: string;
  groupDimension: string;
};

export function flattenAndLabelGroup({ items, groupDataIndex, groupDimension }: FlattenAndLabelGroupArgs) {
  let flatGroup: BasicPivotItem[] = [];

  if (groupDimension !== DIMENSION_PRODUCT) {
    // In each group, add groupby property and value to the group's children and then flatten tree.
    // This swaps out the current data value with a more reader friendly value (i.e. 'A' -> 'Group-A')
    const modifiedLeafs = fpMap((group) => {
      return fpMap((child) => {
        set(child, groupDataIndex, group.name);
        return child;
      }, group.children);
    }, items);

    flatGroup = fpFlatten(modifiedLeafs);
  }
  // check if any items contain children items, then flatten all
  else if (some(items, (item) => !isEmpty(item.children))) {
    items.forEach((item) => {
      const itemChildren = item.children || [];
      flatGroup = concat(flatGroup, itemChildren);
    });
  } else {
    flatGroup = items;
  }

  return flatGroup;
}

export const isGroupByEqual = (lGroup: SubheaderDropdownSlice, rGroup: SubheaderDropdownSlice): boolean => {
  const defSelEquals = lGroup.defaultSelection === rGroup.defaultSelection;
  const selectionEquals = lGroup.selection === rGroup.selection;
  // lodash equals is kinda slow, but this list is usually not super long
  // so I don't believe it should be an issue
  const optionsEqual = isEqual(lGroup.options, rGroup.options);
  return defSelEquals && selectionEquals && optionsEqual;
};
