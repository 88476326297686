import { connect } from 'react-redux';
import AssortmentScopeSelector from './AssortmentScopeSelector';
import { Scope, SelectionErrors } from 'src/types/Scope';
import { AppState, AppThunkDispatch } from 'src/store';
import { getProductMapSelector, getLocationMapSelector } from './AssortmentScopeSelector.selectors';

import { asyncGetScopeConfig, asyncSetScope, asyncGetScope } from './AssortmentScopeSelector.actions';
import {
  AssortmentScopeSelectorSlice,
  changeScopeSelection,
  validateAllSelections,
  closeScope,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { clearCache } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/ValidValuesCache';
import { BoundEnabledPerspective } from 'src/services/configuration/codecs/bindings.types';

interface AssortmentScopeSelectorOwnProps {
  perspective: BoundEnabledPerspective;
}

export interface StateProps extends AssortmentScopeSelectorOwnProps, AssortmentScopeSelectorSlice {}

export interface DispatchProps {
  onSubmitScope(oldScope: Scope): void;
  onRequestScope(): void;
  onRequestDimensionConfig(): void;
  onRequestFloorsetsConfig(): void;
  onChangeScopeSelection(selections: Scope): void;
  onValidateSelections(selectionErrors: SelectionErrors): void;
  onCloseScope(): void;
}

function mapStateToProps(state: AppState, { perspective }: AssortmentScopeSelectorOwnProps): StateProps {
  return {
    ...state.scope,
    productDimension: getProductMapSelector(state),
    locationDimension: getLocationMapSelector(state),
    perspective,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    onRequestDimensionConfig() {
      dispatch(asyncGetScopeConfig());
    },
    onSubmitScope(oldScope: Scope) {
      clearCache(); // clear validValues cache in case dept is changed in scope
      dispatch(asyncSetScope(oldScope));
    },
    onRequestScope() {
      dispatch(asyncGetScope());
    },
    onCloseScope() {
      dispatch(closeScope());
    },
    onRequestFloorsetsConfig() {
      return;
    },
    onChangeScopeSelection(selections: Scope) {
      dispatch(changeScopeSelection(selections));
    },
    onValidateSelections() {
      dispatch(validateAllSelections());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssortmentScopeSelector);
