import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const wrenchStyleButton = style({
  width: 42,
});

const modal = style({
  $debugName: 'modalContainer',
  maxWidth: '80vw',
  margin: '0 auto',
});

const modalPaper = style({
  $debugName: 'modalPaperScroll',
  margin: '0 !important', // importants needed to override matieral styles
  overflow: 'hidden !important', // hides scrollbar during loads
});

const titleContainer = style({
  display: 'flex',
});

const leftTitleContainer = style({
  flexGrow: 1,
});

const rightTitleContainer = style({
  flexGrow: 0,
});

const modalTitle = style({
  $debugName: 'modalTitle',
  backgroundColor: TEAL_PRIMARY,
  padding: '0.5rem 1rem !important',
});

const modalTitleText = style({
  $debugName: 'modalTitleText',
  color: '#ffffff',
  fontSize: '1.5rem',
});

const modalTitleIcon = style({
  $debugName: 'modalTitleIcon',
  $nest: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const modalContent = style({
  $debugName: 'modalContent',
  minHeight: '20vh',
  maxHeight: '80vh',
  overflow: 'visible !important',
});

export {
  modal,
  modalPaper,
  titleContainer,
  leftTitleContainer,
  rightTitleContainer,
  modalTitle,
  modalTitleText,
  modalTitleIcon,
  modalContent,
  wrenchStyleButton,
};
