import { z, ZodTypeAny, AnyZodObject } from 'zod';
import { isEmpty, isNil } from 'lodash';
// import { errorToLoggingPayload } from 'src/services/loggingService';
function validateLevel<T>(schema: ZodTypeAny, input: T, throwOnInvalid: boolean): T | undefined {
  let level;

  try {
    level = schema.parse(input);
    return level;
  } catch (error) {
    if (error instanceof z.ZodError) {
      // eslint-disable-next-line no-console
      console.debug(error.message);
      // console.error(errorToLoggingPayload(error.message)) // eslint-disable-line no-console
      if (throwOnInvalid) {
        throw error;
      }
      return level;
    } else {
      throw error;
    }
  }
}

type ValidateLevelOptions = {
  childLevel?: string;
  /**
   * Determines if during validation to throw an error or just filter out the invalid item.
   * Currently only the top level of the UIConfDefn will throw an error.
   */
  throwOnInvalid?: boolean;
};

export function validateAtLevel(schema: ZodTypeAny, input: any, options: ValidateLevelOptions = {}) {
  const { childLevel = '', throwOnInvalid = false } = options;

  // The following schema/types provide an "allowable" level type (confdefn, tab, section, view) that
  // omit the "children" of that level that may be misconfigured causing the whole level to be invalid.
  // So omit the "children" at that level so the level can pass validation independent of its "children".
  // Need to convert to slightly different zod type because 'omit' doesn't exist on union types (think view level)

  const AllowableSchema = isEmpty(childLevel) ? schema : (schema as AnyZodObject).omit({ [childLevel]: true });
  type AllowableSchemaType = z.infer<typeof AllowableSchema>;

  return validateLevel<AllowableSchemaType>(AllowableSchema, input, throwOnInvalid);
}

export function filterInvalidItem<T>(item: T | undefined): boolean {
  return !isNil(item);
}

export function formatAndLogLocal(errorMessage: string, errors: string[]) {
  /* eslint-disable no-console */
  console.groupCollapsed(`%c${errorMessage}`, 'font-size: 14px');
  console.log(
    `%c${'*** Basic details below. For more detailed errors enable debug/verbose messages in browser console ***'}`,
    'padding: 8px 0; color: #4298db'
  );
  errors.forEach((error) => console.log(`%c${error}`, 'padding: 8px 0'));
  console.groupEnd();
}
