/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react';
import Highcharts, { Point, TooltipFormatterContextObject, TooltipOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem, RegionItem } from 'src/worker/pivotWorker.types';
import { isNil, isUndefined, get, uniq, flatten, find } from 'lodash/fp';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { Grid } from '@material-ui/core';
import { Numbro } from 'numbro';
import { default as styles, colorStops, heatMapBorderColor, heatChartStyle, chartStyle } from './MacroMix.styles';
import { Overlay } from 'src/common-ui';
import { default as Subheader } from 'src/components/Subheader/Subheader.container';
import {
  PointOptions,
  isPointOptions,
  SimplerLineChart,
  ExtendedPointObject,
} from 'src/pages/Hindsighting/MacroTrends/GeoTrends/Charts/SimplerChart';
import { MACRO_TRENDS_WARNING_MESSAGE } from 'src/pages/Hindsighting/MacroTrends/MacroTrends';
import Renderer from 'src/utils/Domain/Renderer';
import numbro from 'numbro';

// Needed
import { Chart, SeriesClickEventObject } from 'highcharts';

import Highstock from 'highcharts/highstock';
import heatmap from 'highcharts/modules/heatmap';
heatmap(Highstock);

type Props = FunctionProps & ValueProps;

export interface FunctionProps {
  onShowView: () => void;
  onUpdateLevel: (selected: TenantConfigViewItem) => void;
  onSelectBox: (selectedPoint: PointOptions) => void;
  onRefetchData: () => void;
  onDestroy: () => void;
}

export type ValueProps = LoadedValueProps | UnloadedValueProps;
export interface UnloadedValueProps {
  loaded: false;
  title: string;
  showFlowStatus: boolean;
  showLookBackPeriod: boolean;
};


export interface LoadedValueProps {
  loaded: true;
  chartDataLoaded: boolean;
  dataLoaded: boolean;
  selectedBox?: PointOptions;
  selectedLevels: TenantConfigViewItem;
  data: BasicPivotItem[];
  regions: RegionItem[];
  chartData?: BasicPivotItem[];
  dropdownConf: TenantConfigViewItem;
  heatmapConf: TenantConfigViewItem;
  leftChartConf: TenantConfigViewItem;
  rightChartConf: TenantConfigViewItem;
  title: string;
  toolTipConf?: TenantConfigViewItem;
  showFlowStatus: boolean;
  showLookBackPeriod: boolean;
};


interface SimpleObj {
  [s: string]: string;
}

function _confGetSection(section: string, conf: TenantConfigViewItem) {
  if (conf.view != null) {
    return find((v: TenantConfigViewItem) => v.xtype === section, conf.view);
  } else {
    return null;
  }
}
interface State {
  // this is partially dupe in redux, watched here for the dropdown index
  selectedMetric?: TenantConfigViewItem;
  selectedMetricInd?: number;
  leftChartSelectedMetric?: number;
  rightChartSelectedMetric?: number;
}

class Heated extends React.Component<Props, State> {
  private heatChartContainerRef: React.RefObject<HTMLDivElement>; // for watching the container DOM
  state: State;
  positions: number[] = [];
  chart!: Chart; // this can end up undef

  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {};
    this.heatChartContainerRef = React.createRef();
  }

  static getDerivedStateFromProps(newProps: Props, state: State) {
    // this is here to set the dropdown on first stateless render
    if (newProps.loaded && isUndefined(state.selectedMetric)) {
      return {
        selectedMetric: newProps.selectedLevels,
        selectedMetricInd: 0,
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentWillUnmount() {
    this.props.onDestroy();
  }

  componentDidUpdate() {
    if (this.props.loaded) {
      const { selectedMetric } = this.state;

      // undef metric is checked elsewhere, repeating it here for extra safety
      if (isUndefined(selectedMetric)) {
        this.updateSelectedMetric(numbro(0));
      }
    }
  }

  updateSelectedMetric = (index: Numbro) => {
    if (this.props.loaded) {
      const { dropdownConf } = this.props;
      if (!isNil(dropdownConf.view)) {
        this.setState({
          selectedMetric: dropdownConf.view[index.value()],
          selectedMetricInd: index.value(),
        });
        this.props.onUpdateLevel(dropdownConf.view[index.value()]);
      }
    }
  };

  handleClickDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.updateSelectedMetric(numbro(event.target.value));
  };

  handleClickLeftDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      leftChartSelectedMetric: parseInt(event.target.value, 10),
    });
  };
  handleClickRightDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      rightChartSelectedMetric: parseInt(event.target.value, 10),
    });
  };

  handleClickBox = (event: SeriesClickEventObject) => {
    const pointOptions = event.point.options;
    if (isNil(event) || !isPointOptions(pointOptions)) {
      throw new Error("MacroMix event fired with an invalid event, this shouldn't happen");
    }
    this.props.onSelectBox(pointOptions);
  };

  dataHandle = (props: LoadedValueProps, state: State): Highcharts.Options => {
    const { dropdownConf, data, regions, heatmapConf, toolTipConf } = props;
    const mapId = get('dataIndex', _confGetSection('display', heatmapConf)) || '';
    const trendId = get('dataIndex', _confGetSection('trend', heatmapConf)) || '';

    let yAxisProp = '',
      xAxisProp = '';
    const mergeDim = (v: TenantConfigViewItem) => `${v.dataIndex}`;
    const selected = state.selectedMetricInd || 0;
    if (dropdownConf.view != null) {
      yAxisProp = mergeDim(get(`view[${selected}].view[0]`, dropdownConf));
      xAxisProp = mergeDim(get(`view[${selected}].view[1]`, dropdownConf));
    }

    const dataWithRegionNames = data.map((item) => {
      // join the regions by ID
      // this is done client side, because the data isn't returned nested
      // and the region level can't be put on the main pivot data
      const currentRegion = regions.find((region) => {
        return region.id === item[xAxisProp];
      });

      // add 'region' in front of all the region props so they don't collide with item props
      // such as  'id' or 'name'
      let currentRegionPrefixed;
      if (currentRegion) {
        currentRegionPrefixed = Object.assign(
          {},
          ...Object.keys(currentRegion).map((key) => ({ [`${xAxisProp}:${key}`]: currentRegion[key] }))
        );
        if (currentRegion && regions.filter((x) => x.name === currentRegion.name).length > 1) {
          currentRegionPrefixed[`${xAxisProp}:name`] =
            currentRegionPrefixed[`${xAxisProp}:name`] + '-' + currentRegionPrefixed[`${xAxisProp}:id`];
        }
      }
      return {
        ...item,
        ...currentRegionPrefixed,
      };
    });
    let yAxis: string[] = [],
      xAxis: string[] = [];

    const firstLevelNames = {};
    const secondLevelNames = {};
    const setLevel = (level: SimpleObj, obj: BasicPivotItem) => {
      // build the axes up from the data
      if (level[obj.name] == null) {
        level[obj.name] = obj.name; // change Name to name
      }
    };

    dataWithRegionNames.forEach((item: BasicPivotItem) => {
      xAxis.push(item[`${xAxisProp}:name`]);
      yAxis.push(item[`member:${yAxisProp}:name`]);
    });

    xAxis = uniq(xAxis).sort();
    yAxis = uniq(yAxis)
      .sort()
      .reverse();

    const heatValues = flatten(
      dataWithRegionNames.map((firstlevel: BasicPivotItem) => {
        setLevel(firstLevelNames, firstlevel);
        setLevel(secondLevelNames, firstlevel);
        const xName = firstlevel[`${xAxisProp}:name`];
        const yName = firstlevel[`member:${yAxisProp}:name`];
        return {
          x: xAxis.indexOf(firstlevel[`${xAxisProp}:name`]),
          y: yAxis.indexOf(firstlevel[`member:${yAxisProp}:name`]),
          value: firstlevel[mapId], // for the color of the box
          trend: firstlevel[trendId], // for the red/green direction arrow
          id: firstlevel[xAxisProp], // for the region id
          mId: firstlevel[yAxisProp], // selected level id, for rendering the mouseover id
          xName, // for rendering title (and maybe tooltip in future?)
          yName, // for rendering title (and maybe tooltip in future?)
        };
      })
    );

    const tooltipConfig: TooltipOptions = {
      formatter: function(this: TooltipFormatterContextObject) {
        // force render string;
        const point = this.point;
        if (true) {
          const tooltipValue =
            toolTipConf && toolTipConf.renderer && toolTipConf.mask
              ? Renderer[toolTipConf.renderer](point, toolTipConf.mask)
              : // @ts-ignore
                `${point.mId} ${point.id}`;
          return tooltipValue;
        }
      },
    };
    // there are two scenarios here:
    // 1. The height of the rows (categories * 60 + margin) is smaller than the dom container of the chart
    // 2. The heigh of the rows is bigger than the dom conatiner height
    // If 1, we tell highCharts to render the height as rows with undef scrollable minHeight
    // If 2, we tell highCharts to render the scroll height as the height of all rows, and the chart height as the dom container height
    // This is due to how highcharts sets the height of heatmap items relative to the height of the chart,
    // and how the scrollable area minHeight is rendered as the larger of the two
    let scrollHeight: number | undefined;
    let chartHeight: number | undefined;
    if (this.heatChartContainerRef.current && heatChartStyle?.marginTop && heatChartStyle?.marginBottom) {
      const heatChartContainerHeight = this.heatChartContainerRef.current.getClientRects()[0].height;
      const heightOfAllRowsWithMargins = yAxis.length * 60 + heatChartStyle.marginTop + heatChartStyle.marginBottom;
      scrollHeight = heightOfAllRowsWithMargins > heatChartContainerHeight ? heightOfAllRowsWithMargins : undefined;
      chartHeight =
        heightOfAllRowsWithMargins > heatChartContainerHeight ? heatChartContainerHeight : heightOfAllRowsWithMargins;
    }

    return {
      chart: {
        type: 'heatmap',
        scrollablePlotArea: {
          minHeight: scrollHeight,
        },
        ...heatChartStyle,
        height: chartHeight,
      },

      title: {
        text: '',
      },

      xAxis: {
        categories: xAxis,
        opposite: true,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },

      yAxis: {
        categories: yAxis,
        // height: heatChartContainerHeight < yAxisHeight ? yAxisHeight : undefined,
        title: undefined,
      },

      colorAxis: {
        min: 0,
        stops: colorStops,
      },

      legend: {
        align: 'right',
        itemMarginTop: heatChartStyle.marginTop,
        layout: 'vertical',
        verticalAlign: 'top',
        symbolHeight: 200,
      },

      credits: {
        enabled: false,
      },

      tooltip: tooltipConfig,
      series: [
        {
          boostThreshold: 1,
          type: 'heatmap',
          cursor: 'pointer',
          events: {
            click: this.handleClickBox,
          },
          rowsize: 1,
          name: '',
          borderWidth: 1,
          borderColor: heatMapBorderColor,
          data: heatValues,
          dataLabels: {
            enabled: true,
            useHTML: true,
            crop: true,
            formatter: function(this: { point: Point }) {
              let arrowClass;
              // @ts-ignore
              if (this.point.trend > 0) {
                arrowClass = styles.trendingUp;
                // @ts-ignore
              } else if (this.point.trend < 0) {
                arrowClass = styles.trendingDown;
              }
              // The ending tag for the span is required by Highcharts to function properly.
              const el = arrowClass ? `<span class="${arrowClass}"></span><br />` : '';
              const finalHtml = this.point.value ? el + Renderer.percent(this.point.value, '0') : el;
              return finalHtml;
            },
            color: '#000000',
          },
          allowPointSelect: true,
          turboThreshold: 0,
        },
      ],
    };
  };

  render() {
    let leftChart;
    let rightChart;
    let leftChartDropdown;
    let rightChartDropdown;
    let heatChartConfig;

    if (this.chart && this.chart.container) {
      // noop
    }
    if (this.props.loaded) {
      const { chartDataLoaded, dataLoaded, leftChartConf, rightChartConf, data, showLookBackPeriod, showFlowStatus } = this.props;

      if (!isUndefined(data) && dataLoaded) {
        heatChartConfig = this.dataHandle(this.props, this.state);
      }

      if (this.props.selectedBox && leftChartConf.view && rightChartConf.view) {
        leftChartDropdown = (
          <SubheaderDropdown
            defaultSelection={0}
            selection={this.state.leftChartSelectedMetric || 0}
            options={leftChartConf.view}
            label={leftChartConf.text}
            handleChangeOnDropdown={this.handleClickLeftDropdown}
          />
        );
        rightChartDropdown = (
          <SubheaderDropdown
            defaultSelection={0}
            selection={this.state.rightChartSelectedMetric || 0}
            options={rightChartConf.view}
            label={rightChartConf.text}
            handleChangeOnDropdown={this.handleClickRightDropdown}
          />
        );
        if (!chartDataLoaded) {
          leftChart = (
            <div style={{ position: 'relative' }} className={styles.chart}>
              <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
            </div>
          );
          rightChart = (
            <div style={{ position: 'relative' }} className={styles.chart}>
              <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
            </div>
          );
        } else if (this.props.chartData) {
          const leftMetric = leftChartConf.view[this.state.leftChartSelectedMetric || 0];
          const rightMetric = rightChartConf.view[this.state.rightChartSelectedMetric || 0];
          // This is a bit jank, but it lets me store reference additional props
          const box = this.props.selectedBox as ExtendedPointObject;
          leftChart = (
            <div style={{ position: 'relative' }} className={styles.chart}>
              <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
              <SimplerLineChart
                title={`${box.xName} - ${box.yName}: ${leftMetric.text}`}
                data={this.props.chartData}
                config={leftChartConf.view[this.state.leftChartSelectedMetric || 0]}
              />
            </div>
          );
          rightChart = (
            <div style={{ position: 'relative' }} className={styles.chart}>
              <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
              <SimplerLineChart
                title={`${box.xName} - ${box.yName}: ${rightMetric.text}`}
                data={this.props.chartData}
                config={rightChartConf.view[this.state.rightChartSelectedMetric || 0]}
              />
            </div>
          );
        }
      }
    }
    return (
      <div className={styles.componentContainer}>
        <Subheader
          title={this.props.title}
          showSearch={false}
          showFlowStatus={this.props.showFlowStatus}
          showLookBackPeriod={this.props.showLookBackPeriod}
          errorCondition={MACRO_TRENDS_WARNING_MESSAGE}
        />
        <div className={'content-container'}>
          <div className={styles.heatChartContainer}>
            {this.props.loaded ? (
              <SubheaderDropdown
                defaultSelection={0}
                selection={this.state.selectedMetricInd || 0}
                options={this.props.dropdownConf.view || []}
                label={'Select Level: '}
                handleChangeOnDropdown={this.handleClickDropdown}
              />
            ) : null}
            <div className={chartStyle} ref={this.heatChartContainerRef}>
              {!this.props.loaded ? (
                <Overlay visible={true} type={'loading'} fitParent={true} />
              ) : (
                <HighchartsReact
                  highcharts={Highstock}
                  options={heatChartConfig}
                  immutable={true}
                  containerProps={{ style: { height: 'calc(100% - 40px)' } }}
                  callback={(chart: Highcharts.Chart) => {
                    this.positions = [];
                    this.chart = chart;
                  }}
                />
              )}
            </div>
          </div>
          <Grid item={true} lg={4} md={12} xs={12} className={styles.gridContainer}>
            <Grid container={true} style={{ height: '100%' }}>
              <Grid item={true} lg={12} className={styles.fullWidthHalfHeightGridItem}>
                {leftChartDropdown}
                {leftChart}
              </Grid>
              <Grid item={true} lg={12} className={styles.fullWidthHalfHeightGridItem}>
                {rightChartDropdown}
                {rightChart}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default Heated;
