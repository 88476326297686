import { createSlice } from '@reduxjs/toolkit';
import { ScopeMemberInfo, AvailableMembers } from 'src/services/Scope.client';
import { Space } from 'src/space';
import { getAvailableScopeMembers, getScope } from '../scope/Scope.actions';

export const API_BASE_URL = '/finplan/api' as const;

export interface ViewConfigSlice {
  availableMembers: AvailableMembers | undefined;
  controlPoints: { [key: string]: Space<ScopeMemberInfo[]> } | undefined;
  inSeason: string | undefined;
  error: string | undefined;
  /** Used to identify the mfp silo and mfp module currently being fetched */
  fetchingMfpId: string | undefined;
  showScopeSelector: boolean;
}

const initialState: ViewConfigSlice = {
  availableMembers: undefined,
  controlPoints: undefined,
  inSeason: undefined,
  error: undefined,
  fetchingMfpId: undefined,
  showScopeSelector: false,
};

const viewConfigSliceReducer = createSlice({
  name: 'viewConfig',
  initialState: initialState,
  reducers: {
    appReady: (state) => state,
    clearAvailableScopes: () => initialState,
    toggleMfpScopeSelector: (state) => {
      state.showScopeSelector = !state.showScopeSelector;
    },
  },
  extraReducers: (builder) => {
    // #region "getAvailableScopeMembers"
    builder.addCase(getAvailableScopeMembers.pending, (state, action) => {
      state.availableMembers = undefined;
      state.inSeason = undefined;
      // we set the requestId equal to siloId + moduleId
      state.fetchingMfpId = action.meta.requestId;
    });
    builder.addCase(getAvailableScopeMembers.fulfilled, (state, action) => {
      if (!state.availableMembers && state.fetchingMfpId === action.meta.requestId) {
        state.availableMembers = action.payload;
        state.controlPoints = action.payload.controlPoints;
        state.inSeason = action.payload.inSeason;
      }
    });
    builder.addCase(getAvailableScopeMembers.rejected, (state) => {
      state = initialState;
      state.fetchingMfpId = undefined;
    });
    // #endregion
    builder.addCase(getScope.fulfilled, (state) => {
      state.showScopeSelector = false;
    });
  },
});

export const { appReady, clearAvailableScopes, toggleMfpScopeSelector } = viewConfigSliceReducer.actions;

export default viewConfigSliceReducer.reducer;
