import { style } from 'typestyle';
import { px, rem, important, rgba } from 'csx';

export const button = style({
  $nest: {
    '& button': {
      backgroundColor: '#00a89b',
      borderColor: '#00a89b',
      borderRadius: important(0),
      $nest: {
        '&:disabled': {
          opacity: 0.6,
        },
        '&:hover': {
          color: '#fff',
          backgroundColor: '#008278',
          borderColor: '#00756c',
        },
        '&:last-child': {
          borderLeft: '1px solid white',
        },
      },
    },
    '& i': {
      marginRight: rem(0.75),
      color: 'white',
    },
    '& div[role="progressbar"]': {
      marginRight: rem(0.75),
    },
    '&:focus-visible': {
      boxShadow: important('none'),
    },
    '.s5-MuiButton-label': {
      color: 'white',
    },
    '.s5-MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
});

export const dropdownMenu = style({
  marginTop: '2.6rem ',
  marginLeft: '-1.2rem',
  padding: rem(1),
  width: px(305),

  $nest: {
    '& div': {
      marginBottom: rem(0.25),
      cursor: 'pointer',
      minHeight: px(30),
    },
    '& div:last-of-type': {
      marginBottom: 0,
    },
    '& div:hover': {
      backgroundColor: rgba(0, 0, 0, 0.075).toString(),
    },
    '& i': {
      marginRight: rem(0.75),
    },
    '& span': {
      fontSize: px(14),
    },
  },
});
