import { style } from 'typestyle';

export const summaryContainer = style({
  $debugName: 'summaryContainer',
});

export const summarySubheader = style({
  $debugName: 'summarySubheader',
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  $nest: {
    '.dropdown': {
      marginLeft: '20px',
    },
  },
});

export const summaryContent = style({
  $debugName: 'summaryContent',
  width: '100%',
  fontSize: '13px', // reset to default font-size
  height: '500px',
  $nest: {
    '.data-grid': {
      fontSize: '0.8rem',
      height: '500px',
    },
  },
});
