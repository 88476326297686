import React from 'react';
import { CustomNoRowsOverlayProps } from '@ag-grid-community/react';
import styles from './NoRowsOverlay.styles';
import { NoRowsOverlayConfig } from 'src/services/configuration/codecs/viewdefns/general';
import { Link } from 'react-router-dom';

export const NoRowsOverlay = () => {
  return <div className={styles.noDataOverlay}>No Data</div>;
};

interface GridNoRowsOverlayProps extends CustomNoRowsOverlayProps {
  noRowsOverlayConfig: NoRowsOverlayConfig;
}

export const GridNoRowsOverlay = ({ noRowsOverlayConfig }: GridNoRowsOverlayProps) => {
  const path = noRowsOverlayConfig?.path ?? '';
  const type = noRowsOverlayConfig?.type ?? 'unset';

  switch (type) {
    case 'EmptyWorklistLink':
      return (
        <div className={styles.noDataOverlay}>
          This view is for your worklist items. Add items to you worklist
          <Link to={path} className={styles.noDataOverlayLink}>
            here
          </Link>
        </div>
      );
    default:
      return <NoRowsOverlay />;
  }
};
