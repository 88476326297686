export const ListIcon = [
  'address-book',
  'address-book',
  'address-card',
  'address-card',
  'adjust',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'ambulance',
  'american-sign-language-interpreting',
  'anchor',
  'angle-double-down',
  'angle-double-left',
  'angle-double-right',
  'angle-double-up',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'archive',
  'arrow-alt-circle-down',
  'arrow-alt-circle-down',
  'arrow-alt-circle-left',
  'arrow-alt-circle-left',
  'arrow-alt-circle-right',
  'arrow-alt-circle-right',
  'arrow-alt-circle-up',
  'arrow-alt-circle-up',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrows-alt',
  'arrows-alt-h',
  'arrows-alt-v',
  'assistive-listening-systems',
  'asterisk',
  'at',
  'audio-description',
  'backward',
  'balance-scale',
  'ban',
  'barcode',
  'bars',
  'bath',
  'battery-empty',
  'battery-full',
  'battery-half',
  'battery-quarter',
  'battery-three-quarters',
  'bed',
  'beer',
  'bell',
  'bell',
  'bell-slash',
  'bell-slash',
  'bicycle',
  'binoculars',
  'birthday-cake',
  'blind',
  'bold',
  'bolt',
  'bomb',
  'book',
  'bookmark',
  'bookmark',
  'braille',
  'briefcase',
  'bug',
  'building',
  'building',
  'bullhorn',
  'bullseye',
  'bus',
  'calculator',
  'calendar',
  'calendar',
  'calendar-alt',
  'calendar-alt',
  'calendar-check',
  'calendar-check',
  'calendar-minus',
  'calendar-minus',
  'calendar-plus',
  'calendar-plus',
  'calendar-times',
  'calendar-times',
  'camera',
  'camera-retro',
  'car',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-square-down',
  'caret-square-down',
  'caret-square-left',
  'caret-square-left',
  'caret-square-right',
  'caret-square-right',
  'caret-square-up',
  'caret-square-up',
  'caret-up',
  'cart-arrow-down',
  'cart-plus',
  'certificate',
  'chart-area',
  'chart-bar',
  'chart-bar',
  'chart-line',
  'chart-pie',
  'check',
  'check-circle',
  'check-circle',
  'check-square',
  'check-square',
  'chevron-circle-down',
  'chevron-circle-left',
  'chevron-circle-right',
  'chevron-circle-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'child',
  'circle',
  'circle',
  'circle-notch',
  'clipboard',
  'clipboard',
  'clock',
  'clock',
  'clone',
  'clone',
  'closed-captioning',
  'closed-captioning',
  'cloud',
  'cloud-download-alt',
  'cloud-upload-alt',
  'code',
  'code-branch',
  'coffee',
  'cog',
  'cogs',
  'columns',
  'comment',
  'comment',
  'comment-alt',
  'comment-alt',
  'comments',
  'comments',
  'compass',
  'compass',
  'compress',
  'copy',
  'copy',
  'copyright',
  'copyright',
  'credit-card',
  'credit-card',
  'crop',
  'crosshairs',
  'cube',
  'cubes',
  'cut',
  'database',
  'deaf',
  'desktop',
  'dollar-sign',
  'dot-circle',
  'dot-circle',
  'download',
  'edit',
  'edit',
  'eject',
  'ellipsis-h',
  'ellipsis-v',
  'envelope',
  'envelope',
  'envelope-open',
  'envelope-open',
  'envelope-square',
  'eraser',
  'euro-sign',
  'exchange-alt',
  'exclamation',
  'exclamation-circle',
  'exclamation-triangle',
  'expand',
  'expand-arrows-alt',
  'external-link-alt',
  'external-link-square-alt',
  'eye',
  'eye-dropper',
  'eye-slash',
  'eye-slash',
  'fast-backward',
  'fast-forward',
  'fax',
  'female',
  'fighter-jet',
  'file',
  'file',
  'file-alt',
  'file-alt',
  'file-archive',
  'file-archive',
  'file-audio',
  'file-audio',
  'file-code',
  'file-code',
  'file-excel',
  'file-excel',
  'file-image',
  'file-image',
  'file-pdf',
  'file-pdf',
  'file-powerpoint',
  'file-powerpoint',
  'file-video',
  'file-video',
  'file-word',
  'file-word',
  'film',
  'filter',
  'fire',
  'fire-extinguisher',
  'flag',
  'flag',
  'flag-checkered',
  'flask',
  'folder',
  'folder',
  'folder-open',
  'folder-open',
  'font',
  'forward',
  'frown',
  'frown',
  'futbol',
  'futbol',
  'gamepad',
  'gavel',
  'gem',
  'gem',
  'genderless',
  'gift',
  'glass-martini',
  'globe',
  'graduation-cap',
  'h-square',
  'hand-lizard',
  'hand-lizard',
  'hand-paper',
  'hand-paper',
  'hand-peace',
  'hand-peace',
  'hand-point-down',
  'hand-point-down',
  'hand-point-left',
  'hand-point-left',
  'hand-point-right',
  'hand-point-right',
  'hand-point-up',
  'hand-point-up',
  'hand-pointer',
  'hand-pointer',
  'hand-rock',
  'hand-rock',
  'hand-scissors',
  'hand-scissors',
  'hand-spock',
  'hand-spock',
  'handshake',
  'handshake',
  'hashtag',
  'hdd',
  'hdd',
  'heading',
  'headphones',
  'heart',
  'heart',
  'heartbeat',
  'history',
  'home',
  'hospital',
  'hospital',
  'hourglass',
  'hourglass',
  'hourglass-end',
  'hourglass-half',
  'hourglass-start',
  'i-cursor',
  'id-badge',
  'id-badge',
  'id-card',
  'id-card',
  'image',
  'image',
  'images',
  'images',
  'inbox',
  'indent',
  'industry',
  'info',
  'info-circle',
  'italic',
  'key',
  'keyboard',
  'keyboard',
  'language',
  'laptop',
  'leaf',
  'lemon',
  'lemon',
  'level-down-alt',
  'level-up-alt',
  'life-ring',
  'life-ring',
  'lightbulb',
  'lightbulb',
  'link',
  'lira-sign',
  'list',
  'list-alt',
  'list-alt',
  'list-ol',
  'list-ul',
  'location-arrow',
  'lock',
  'lock-open',
  'long-arrow-alt-down',
  'long-arrow-alt-left',
  'long-arrow-alt-right',
  'long-arrow-alt-up',
  'low-vision',
  'magic',
  'magnet',
  'male',
  'map',
  'map',
  'map-marker',
  'map-marker-alt',
  'map-pin',
  'map-signs',
  'mars',
  'mars-double',
  'mars-stroke',
  'mars-stroke-h',
  'mars-stroke-v',
  'medkit',
  'meh',
  'meh',
  'mercury',
  'microchip',
  'microphone',
  'microphone-slash',
  'minus',
  'minus-circle',
  'minus-square',
  'minus-square',
  'mobile',
  'mobile-alt',
  'money-bill-alt',
  'money-bill-alt',
  'moon',
  'moon',
  'motorcycle',
  'mouse-pointer',
  'music',
  'neuter',
  'newspaper',
  'newspaper',
  'object-group',
  'object-group',
  'object-ungroup',
  'object-ungroup',
  'outdent',
  'paint-brush',
  'paper-plane',
  'paper-plane',
  'paperclip',
  'paragraph',
  'paste',
  'pause',
  'pause-circle',
  'pause-circle',
  'paw',
  'pen-square',
  'pencil-alt',
  'percent',
  'phone',
  'phone-square',
  'phone-volume',
  'plane',
  'play',
  'play-circle',
  'play-circle',
  'plug',
  'plus',
  'plus-circle',
  'plus-square',
  'plus-square',
  'podcast',
  'pound-sign',
  'power-off',
  'print',
  'puzzle-piece',
  'qrcode',
  'question',
  'question-circle',
  'question-circle',
  'quote-left',
  'quote-right',
  'random',
  'recycle',
  'redo',
  'redo-alt',
  'registered',
  'registered',
  'reply',
  'reply-all',
  'retweet',
  'road',
  'rocket',
  'rss',
  'rss-square',
  'ruble-sign',
  'rupee-sign',
  'save',
  'save',
  'search',
  'search-minus',
  'search-plus',
  'server',
  'share',
  'share-alt',
  'share-alt-square',
  'share-square',
  'share-square',
  'shekel-sign',
  'shield-alt',
  'ship',
  'shopping-bag',
  'shopping-basket',
  'shopping-cart',
  'shower',
  'sign-in-alt',
  'sign-language',
  'sign-out-alt',
  'signal',
  'sitemap',
  'sliders-h',
  'smile',
  'smile',
  'snowflake',
  'snowflake',
  'sort',
  'sort-alpha-down',
  'sort-alpha-up',
  'sort-amount-down',
  'sort-amount-up',
  'sort-down',
  'sort-numeric-down',
  'sort-numeric-up',
  'sort-up',
  'space-shuttle',
  'spinner',
  'square',
  'square',
  'star',
  'star',
  'star-half',
  'star-half',
  'step-backward',
  'step-forward',
  'stethoscope',
  'sticky-note',
  'sticky-note',
  'stop',
  'stop-circle',
  'stop-circle',
  'street-view',
  'strikethrough',
  'subscript',
  'subway',
  'suitcase',
  'sun',
  'sun',
  'superscript',
  'sync',
  'sync-alt',
  'table',
  'tablet',
  'tablet-alt',
  'tachometer-alt',
  'tag',
  'tags',
  'tasks',
  'taxi',
  'terminal',
  'text-height',
  'text-width',
  'th',
  'th-large',
  'th-list',
  'thermometer-empty',
  'thermometer-full',
  'thermometer-half',
  'thermometer-quarter',
  'thermometer-three-quarters',
  'thumbs-down',
  'thumbs-down',
  'thumbs-up',
  'thumbs-up',
  'thumbtack',
  'ticket-alt',
  'times',
  'times-circle',
  'times-circle',
  'tint',
  'toggle-off',
  'toggle-on',
  'trademark',
  'train',
  'transgender',
  'transgender-alt',
  'trash',
  'trash-alt',
  'trash-alt',
  'tree',
  'trophy',
  'truck',
  'tty',
  'tv',
  'umbrella',
  'underline',
  'undo',
  'undo-alt',
  'universal-access',
  'university',
  'unlink',
  'unlock',
  'unlock-alt',
  'upload',
  'user',
  'user',
  'user-circle',
  'user-circle',
  'user-md',
  'user-plus',
  'user-secret',
  'user-times',
  'users',
  'utensil-spoon',
  'utensils',
  'venus',
  'venus-double',
  'venus-mars',
  'video',
  'volume-down',
  'volume-off',
  'volume-up',
  'wheelchair',
  'wifi',
  'window-close',
  'window-close',
  'window-maximize',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'window-restore',
  'won-sign',
  'wrench',
  'yen-sign',
];
