import { connect } from 'react-redux';
import { first, last, defaultTo } from 'lodash';

import AssortmentPeriodForm from './AssortmentPeriodForm';
import { AppState, AppDispatch } from 'src/store';
import { FloorsetConfigResponse, DaysRangeListResponse, SelectionErrors } from 'src/types/Scope';
import {
  changeScopeSelection,
  clearFloorsetAndQuickSelect,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { Perspective } from 'src/services/configuration/codecs/bindings.types';

export interface StateProps {
  allowTo: string;
  allowFrom: string;
  selectionErrors: SelectionErrors;
  floorsetConfig: FloorsetConfigResponse;
  selectedStartWeek: string | null;
  selectedEndWeek: string | null;
  selectedFloorset: string | null;
  daysRangeList: DaysRangeListResponse;
  daysPastRangeList: DaysRangeListResponse;
}

export interface DispatchProps {
  onChangeStartWeek(selectionChanges: ChangeStartWeeks): void;
  onChangeEndWeek(selectionChanges: ChangeEndWeeks): void;
}

export interface RouteProps {
  perspective: Perspective;
}

function mapStateToProps(state: AppState): StateProps {
  const { scope } = state;
  const { selections, selectionErrors, daysRangeList, rangeList } = scope;
  const startRangeList = rangeList.filter((x) => Object.values(daysRangeList.start_date).indexOf(x.id) !== -1);
  const endRangeList = rangeList.filter((x) => Object.values(daysRangeList.end_date).indexOf(x.id) !== -1);
  const allowFrom = defaultTo(first(startRangeList)?.id, '');
  const allowTo = defaultTo(last(endRangeList)?.id, '');
  return {
    allowFrom,
    allowTo,
    selectionErrors,
    selectedStartWeek: selections.start,
    selectedEndWeek: selections.end,
    selectedFloorset: selections.floorSet,
    floorsetConfig: scope.floorsetConfig,
    daysRangeList: scope.daysRangeList,
    daysPastRangeList: scope.daysPastRangeList,
  };
}

interface ChangeStartWeeks {
  start: string;
  historyStart: string;
}
interface ChangeEndWeeks {
  end: string;
  historyEnd: string;
}

function mapDispatchToProps(dispatch: AppDispatch): DispatchProps {
  return {
    onChangeStartWeek(selectionChanges: ChangeStartWeeks) {
      dispatch(changeScopeSelection(selectionChanges));
      dispatch(clearFloorsetAndQuickSelect());
    },
    onChangeEndWeek(selectionChanges: ChangeEndWeeks) {
      dispatch(changeScopeSelection(selectionChanges));
      dispatch(clearFloorsetAndQuickSelect());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssortmentPeriodForm);
