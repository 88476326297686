import { Callable } from 'src/types/Primitive';
import { filter, map } from 'lodash';

export function mergeWithId<T extends Record<string, any>>(
  mergee: Iterable<T>,
  merger: Iterable<T>,
  identify: Callable
) {
  const conflictLookup = new Set(map(merger, identify));
  const retained = filter(mergee, (item: T) => !conflictLookup.has(identify(item)));
  return [...retained, ...merger];
}
