import * as fp from 'lodash/fp';
import { createSelector, OutputSelector } from 'reselect';

import { AppState } from 'src/store';
import { BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { maybeSortPivotItems } from 'src/utils/Pivot/Sort';
import { TopPerformersSlice } from './TopPerformers.slice';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';
import { getDataFromCache } from 'src/services/pivotServiceCache';

type ProcessedDataSelector = OutputSelector<
  AppState,
  CardViewGroup[],
  (res1: BasicPivotItem[], res2: TopPerformersSlice, subh: SubheaderSlice, work: WorklistInfo[]) => CardViewGroup[]
>;

const getView = (state: AppState) => {
  return state.pages.hindsighting.topPerformers;
};

const getStyles = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.topPerformers)?.tree || [];
};

const getSubheader = (state: AppState) => {
  return state.subheader;
};
const getWorklist = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.sharedData.worklist;
};

const sortItems = (subh: SubheaderSlice, list: BasicPivotItem[]) => {
  return fp.flow(
    () => list,
    (viewItems): BasicPivotItem[] => {
      return maybeSortPivotItems(subh.sortBy, viewItems);
    },
    (sortedViewItems) => {
      const max = subh.countLimit;
      return max ? fp.slice(0, max, sortedViewItems) : sortedViewItems;
    }
  )();
};
export const getProcessedData: ProcessedDataSelector = createSelector(
  getStyles,
  getView,
  getSubheader,
  getWorklist,
  (styles, viewConfig, subheader, worklist) => {
    if (styles && viewConfig && subheader) {
      const { lookBackPeriod } = subheader;
      let data = styles;

      if (lookBackPeriod) {
        data = cascadingFilter(styles, makelookBackPredicate(lookBackPeriod));
      }

      const dataInputs: CardViewDataInputs = {
        styles: sortItems(subheader, data),
        subheader,
        defns: {
          view: viewConfig.viewDefn || {},
          rollup: viewConfig.calcViewDefn || {},
        },
      };
      const optionalInputs: CardViewOptionalInputs = {
        worklist,
        filterFlowStatus: false,
      };

      return processDataForCardView(dataInputs, optionalInputs);
    }
    return [];
  }
);
