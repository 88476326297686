import { connect } from 'react-redux';
import { flow, identity } from 'lodash';
import container from 'src/ServiceContainer';

import {
  requestConfig,
  receiveSomeProductDetailsConfig,
  receiveConfig,
  receiveError as receiveProductDetailsError,
} from './ProductDetails.slice';

import MacroGridPair, { Props as MacroGridPairProps } from 'src/components/MacroGridPair/MacroGridPair';
import { AppState } from 'src/store';
import { wrappedDispatch, WrappedThunkDispatch } from 'src/utils/Redux/Dispatch';
import { selectData } from 'src/pages/Hindsighting/AggregateViews/ProductDetails/ProductDetails.selectors';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/Favorites.types';
import { ProductDetailsComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';

export interface ProductDetailsOwnProps extends z.infer<typeof ProductDetailsComponentProps> {}

export interface DispatchProps {
  onShowView(): void;
}

function getProps(
  state: AppState,
  dispatchProps: WrappedThunkDispatch,
  ownProps: ProductDetailsOwnProps
): MacroGridPairProps {
  const { dispatch } = dispatchProps;
  const { defns } = ownProps;

  return {
    ...selectData(state, ownProps),
    onShowView() {
      dispatch(requestConfig());

      const configPromise = container.tenantConfigClient.getTenantViewDefnsWithFavorites({
        defnIds: defns.view,
        appName: ASSORTMENT,
      });

      Promise.all([configPromise])
        .then((responses) => {
          const [config] = responses;
          const unmodifiedViewDefn = config[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (config as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          if (localConfig && localConfig.config) {
            config[0] = localConfig.config;
          }

          dispatch(
            receiveConfig({
              grid: config[0],
              macro: config[1],
              unmodifiedViewDefn,
            })
          );
        })
        .catch((error) => {
          dispatch(
            receiveProductDetailsError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.productDetails,
              issues: error,
              defnId: error.defnId,
            })
          );
        });
    },
    onConfigUpdate(config: TenantConfigViewData) {
      let finalConfig;
      if (config.grid) {
        finalConfig = config.grid.view;
      }
      finalConfig = config.view;
      dispatch(receiveSomeProductDetailsConfig(finalConfig));
    },
  };
}
const sensitiveMacroGridPair = flow(() => MacroGridPair, makePrintSensitive)();
export default connect(identity, wrappedDispatch, getProps)(sensitiveMacroGridPair);
