import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ThunkApi } from 'src/store';
import AdminService from '../../services/Admin.service';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

export const deletePlan = createAsyncThunk<
  Error | AxiosResponse<any>, // return shape
  {
    // Payload shape
    client: AxiosInstance;
    planId: number;
  },
  ThunkApi
>(
  'workingSets/deletePlan',
  // if you type your function argument here
  async (payload, { getState }) => {
    const activeTab = getState().perspective.activeTab;
    const currentModule = getMfpModule(getState());
    if (!currentModule || !activeTab) {
      throw new Error(`User attempted to delete PlanId ${payload.planId} without a perspective set`);
    }
    return await new AdminService(payload.client).deletePlan(
      payload.planId,
      currentModule.pathSlot,
      currentModule.siloId
    );
  }
);

export const clonePlans = createAsyncThunk<
  Error | AxiosResponse<any>, // return shape
  {
    planIds: number[];
    version: string;
  },
  ThunkApi
>(
  'workingSets/clonePlan',
  // if you type your function argument here
  async (payload, { getState, extra }) => {
    const activeTab = getState().perspective.activeTab;
    const currentModule = getMfpModule(getState());
    if (!currentModule || !activeTab) {
      throw new Error(`User attempted to clone PlanId ${payload.planIds} without a perspective set`);
    }
    return await new AdminService(extra.axios).clonePlan(
      payload.planIds,
      payload.version,
      currentModule.pathSlot,
      currentModule.siloId
    );
  }
);

export const clonePlansActualize = createAsyncThunk<
  Error | AxiosResponse<any>, // return shape
  {
    // Payload shape
    planIds: number[];
    version: string;
  },
  ThunkApi
>(
  'workingSets/clonePlanActualize',
  // if you type your function argument here
  async (payload, { getState, extra }) => {
    const activeTab = getState().perspective.activeTab;
    const currentModule = getMfpModule(getState());
    if (!currentModule || !activeTab) {
      throw new Error(`User attempted to clone PlanId ${payload.planIds} without a module set`);
    }
    return await new AdminService(extra.axios).clonePlanActualize(
      payload.planIds,
      payload.version,
      currentModule.pathSlot,
      currentModule.siloId
    );
  }
);
