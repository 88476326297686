import { style } from 'typestyle';
import { important, rem } from 'csx';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

export const addButtonWidth = 7; // rem
export const addButtonMargin = 1.2; // rem
const calculatedMargin = important(rem(addButtonMargin) as number);

// these have !important on them because the CSS classes don't apply correctly, due to them
// being injected into <style> tags in the wrong order, which causes the override classes
// to not have the correct order of precedence when applied to elements
const addColorButton = style({
  marginLeft: calculatedMargin,
  marginRight: calculatedMargin,
  width: rem(addButtonWidth),
  backgroundColor: important('#f4f4f4'),
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  $nest: {
    'i.fas': {
      alignSelf: 'center',
      color: TEAL_PRIMARY,
      borderRadius: rem(1),
      backgroundColor: '#fff',
    },
    span: {
      flexDirection: 'inherit',
    },
  },
});

export const addColorText = style({
  marginTop: '10px',
  color: TEAL_PRIMARY,
});

export default addColorButton;
