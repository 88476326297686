import { Option, chain, map, fromNullable } from 'fp-ts/lib/Option';
import { BoundTab } from 'src/services/configuration/codecs/bindings.types';
import { BoundSectionZipper } from 'src/pages/NavigationShell/NavigationZipper';
import BoundTenantZipper from 'src/pages/NavigationShell/NavigationZipper/BoundTenantZipper';
import { pipe } from 'fp-ts/lib/function';

export default class BoundTabZipper {
  readonly type: 'tab' = 'tab';
  private tab: Readonly<BoundTab>;
  private parent: Readonly<BoundTenantZipper>;

  constructor(parent: Readonly<BoundTenantZipper>, tab: Readonly<BoundTab>) {
    this.tab = tab;
    this.parent = parent;
  }

  public getParent(): Readonly<BoundTenantZipper> {
    return this.parent;
  }

  public getTab(): Readonly<BoundTab> {
    return this.tab;
  }

  public getSection(id: string): Option<Readonly<BoundSectionZipper>> {
    return pipe(
      fromNullable(this.tab.boundSections),
      chain((sections) => fromNullable(sections.find((section) => section.id === id))),
      map((section) => new BoundSectionZipper(this, section))
    );
  }

  public getSections(): readonly BoundSectionZipper[] {
    return (this.tab.boundSections || []).map((section) => new BoundSectionZipper(this, section));
  }
}
