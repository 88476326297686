import { style } from 'typestyle';

const styleCard = style({
  width: '200px',
  minWidth: '200px',
  height: '240px',
});
const imageContainerImg = style({
  width: '100%',
});
const clickables = style({
  position: 'absolute',
  padding: '10px 5px',
  width: '200px',
});
const clickablesSvg = style({
  marginRight: '10px',
});
const leftSide = style({
  display: 'inline-block',
  width: '125px',
});
const rightSide = style({
  display: 'inline-block',
  fontSize: '10px',
  float: 'right',
  width: '75px',
});
const pair = style({
  width: '100%',
  paddingBottom: '10px',
});
const pairName = style({
  display: 'inline-block',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '100%',
});
const pairValue = style({
  textAlign: 'center',
  width: '100%',
});
const details = style({
  fontSize: '12px',
});

export default {
  styleCard,
  imageContainerImg,
  clickables,
  clickablesSvg,
  leftSide,
  rightSide,
  pair,
  pairName,
  pairValue,
  details,
};
