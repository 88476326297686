import { partial, isNumber, isNil, isArray } from 'lodash';
import * as globalMath from 'mathjs';
import { ValueParserParams } from '@ag-grid-community/core';

function applyMath(func: (...a: globalMath.MathType[]) => number, checkNaN: boolean, values: number[]) {
  if (values == null || values.length < 1) {
    return 0;
  } else {
    // @ts-ignore This needs some magic, maybe fix with INT-3238
    const finalValues: number[] = isArray(values) ? values : ((values.values as unknown) as number[]);
    return func.apply(
      globalMath,
      finalValues.map((v) => {
        if (v == null || (checkNaN && isNaN(v)) || v == undefined) {
          return 0;
        } else {
          return v;
        }
      })
    );
  }
}

export const aggFuncs = {
  totalName: function() {
    return 'Summary';
  },
  // @ts-ignore
  sum: partial(applyMath, globalMath.sum, true),
  // @ts-ignore
  add: partial(applyMath, globalMath.sum, true),
  min: partial(applyMath, globalMath.min, true),
  max: partial(applyMath, globalMath.max, true),
  avg: partial(applyMath, globalMath.mean, true),
};

export function isInvalidNumber(val: unknown) {
  return (isNumber(val) && (isNaN(val) || !isFinite(val))) || isNil(val);
}

export function percentParser(params: ValueParserParams) {
  // percents are entered as integers, but stored as regular floats
  return Number(params.newValue) / 100;
}
