import React from 'react';
import { ViewDataState } from 'src/types/Domain';
import { style } from 'typestyle';
import { px } from 'csx';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { Tooltip } from '@material-ui/core';
import { isArray } from 'lodash';

export const iconStyles = style({
  $nest: {
    '& span': {
      fontSize: px(14),
      color: TEAL_PRIMARY,
    },
  },
});

export interface BackgroundDataLoadingProps {
  viewDataState: ViewDataState | ViewDataState[];
}

export const BackgroundDataLoading = ({ viewDataState: viewDataStateTemp }: BackgroundDataLoadingProps) => {
  let viewDataState = viewDataStateTemp;
  // If passed an array of states, set the icon to loading if any are loading
  // If none are loading and at least one is ready, show the ready icon
  if (isArray(viewDataState)) {
    if (viewDataState.indexOf(ViewDataState.liveDataLoadingFoundCache) > -1) {
      viewDataState = ViewDataState.liveDataLoadingFoundCache;
    } else if (viewDataState.indexOf(ViewDataState.liveDataReady) > -1) {
      viewDataState = ViewDataState.liveDataReady;
    }
  }

  switch (viewDataState) {
    case ViewDataState.liveDataLoadingNoCache:
    case ViewDataState.liveDataLoadingFoundCache:
      return (
        <Tooltip title={'Cached data shown, fresh data is loading'}>
          <div className={iconStyles}>
            <span className="fas fa-spinner fa-spin" />
          </div>
        </Tooltip>
      );
    case ViewDataState.liveDataReady:
      return (
        <Tooltip title={'Data is loaded'}>
          <div className={iconStyles}>
            <span className="fas fa-check" />
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
};
