import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import { flattenAndLabelGroup } from 'src/components/StandardCardView/SortAndGroup';

import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';
import { FlowTypeSlice } from './FlowType.slice';
import { BasicPivotItem, WorklistInfo } from 'src/worker/pivotWorker.types';

import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { toSummaries } from 'src/utils/Pivot/RollUp';
import { GetSummaryOutput } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import { filterData } from 'src/utils/Pivot/Filter';
import { generateGroupBy } from '../TopTYvsLY/TopTYvsLY.container';
import { isNil } from 'lodash';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';
import { getDataFromCache } from 'src/services/pivotServiceCache';

const getTreeData = (state: AppState) => {
  return getDataFromCache(state.pages.hindsighting.styleColorReview.flowType)?.tree || [];
};

const getRollup = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.flowType.calcViewDefn;
};
const getView = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.flowType;
};
const getSubheader = (state: AppState) => {
  return state.subheader;
};
const getSearchIndexes = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.flowType.viewDefn.searchIndexes;
};
const getWorklist = (state: AppState) => {
  return state.pages.hindsighting.styleColorReview.sharedData.worklist;
};

export const getFlowStatusList = (state: AppState) => {
  return state.appConfig.tenantConfig.flowStatus.values;
};

export interface ProcessedDataSelector
  extends OutputSelector<
    AppState,
    CardViewGroup[],
    (
      res1: BasicPivotItem[],
      res2: FlowTypeSlice,
      subh: SubheaderSlice,
      flList: { text: string; tooltip: string }[],
      work: WorklistInfo[]
    ) => CardViewGroup[]
  > {}
export const getProcessedData: ProcessedDataSelector = createSelector(
  getTreeData,
  getView,
  getSubheader,
  getFlowStatusList,
  getWorklist,
  (treeData, viewSlice, subheader, flowStatusList, worklist) => {
    if (treeData && viewSlice && viewSlice.groupingInfo && subheader) {
      const groupingInfo = viewSlice.groupingInfo;
      const groupBy = generateGroupBy(groupingInfo.dataIndex);
      const dataInputs: CardViewDataInputs = {
        styles: treeData,
        subheader: { ...subheader, groupBy },
        defns: {
          view: viewSlice.viewDefn || {},
          rollup: viewSlice.calcViewDefn || {},
        },
      };
      const optionalInputs: CardViewOptionalInputs = {
        worklist,
        staticColumns: groupingInfo.staticColumns,
      };

      return processDataForCardView(dataInputs, optionalInputs);
    }
    return [];
  }
);

export const getSummary = createSelector(
  getView,
  getTreeData,
  getRollup,
  getSubheader,
  getSearchIndexes,
  (viewSlice, treeData, rollupConfig, subheader, searchIndexes) => {
    const views = rollupConfig.view;
    const searchFields = searchIndexes ? searchIndexes : externalGridSearchFields;
    const groupDataIndex = getGroupBySelectedOptionProperty(subheader.groupBy, 'dataIndex');
    const groupDimension = getGroupBySelectedOptionProperty(subheader.groupBy, 'dimension');
    const flat = flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension });
    if (isNil(flat) || flat.length === 0 || !Array.isArray(views) || views.length === 0) {
      return [] as GetSummaryOutput[];
    }
    const filteredFlat = filterData(
      flat,
      subheader.search,
      searchFields,
      subheader.flowStatus,
      subheader.pareDown && subheader.pareDown.selections
    );
    return toSummaries(filteredFlat, views, viewSlice.identityProps.idProp);
  }
);
