import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/common-ui/theme';

const summaryBackground = style({
  margin: '0 32px',
  backgroundColor: '#f4f3f4',
  padding: '16px',
  color: '#8c8c8c',
  height: '100%',
});

const summaryContent = style({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  $debugName: 'summary-content',
});

const summaryHeader = style({
  textAlign: 'center',
  marginBottom: 5,
});

const summaryListItems = style({
  margin: '16px 16px 0',
  fontSize: '12px',
});

const summaryListActions = style({
  margin: '32px 0 0 0',
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'column',
});

const summaryActionButtons = style({
  borderRadius: '0px !important',
  margin: '8px 0',
});

const summaryAddButton = classes(
  summaryActionButtons,
  style({
    marginBottom: 10,
  })
);

const cardContainer = style({
  borderBottom: '1px solid rgb(243,243,243)',
});

const cartContainer = style({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const cartHeader = style({
  width: '75%',
  height: 75,
  borderBottom: '1px solid rgb(230, 230, 230)',
  display: 'flex',
  padding: 20,
});

const cartHeaderTextBox = style({
  marginBottom: 20,
  display: 'contents',
});

const summaryContainer = style({
  position: 'absolute',
  right: 0,
  width: '27%',
  maxWidth: 400,
  maxHeight: 250,
});

const flexCenter = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const summaryBody = style({
  display: 'flex',
  flexDirection: 'column',
});

const headerStyleCount = style({
  whiteSpace: 'nowrap',
  left: 'calc(30% - 45px)',
  padding: 0,
});

const headerStyleText = style({
  paddingLeft: '5% !important',
  display: 'flex',
  alignItems: 'center',
});

const headerCountText = style({
  paddingLeft: '8% !important',
});
const disableBtn = style({
  color: '#ddd !important',
  opacity: '1',
  cursor: 'default !important',
  $nest: {
    i: {
      color: '#ddd !important',
      cursor: 'not-allowed !important',
    },
  },
});
const reviewAssortmentRulesContainer = style({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  height: 60,
  justifyContent: 'center',
  fontSize: '.8rem',
  fontWeight: 600,
  textDecoration: 'underline',
  color: TEAL_PRIMARY,
  cursor: 'pointer',
});

export default {
  summaryBackground,
  summaryHeader,
  summaryListItems,
  summaryListActions,
  summaryContent,
  summaryActionButtons,
  cardContainer,
  cartContainer,
  cartHeader,
  summaryContainer,
  flexCenter,
  cartHeaderTextBox,
  headerStyleCount,
  headerStyleText,
  headerCountText,
  summaryAddButton,
  summaryBody,
  disableBtn,
  reviewAssortmentRulesContainer,
  headerContainer: style({
    width: '75%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  buttonContainer: style({}),
  addIcon: style({
    marginTop: 17,
    fontSize: '1.2rem',
    marginLeft: 20,
    cursor: 'pointer',
  }),
};
