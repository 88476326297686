import React, { useState } from 'react';
import { colorSwatchItem, missingSwatchItem } from './ColorSwatch.styles';
import { Tooltip } from '@material-ui/core';

export interface ColorSwatchItemProps extends React.HTMLAttributes<{}> {
  name?: string;
  swatchPath?: string;
  id: string;
  colorId: string;
  selected?: boolean;
  noImageUrl: string;
  renderTooltip?: boolean;
}

const ColorSwatchItem = (props: ColorSwatchItemProps) => {
  const { swatchPath = props.noImageUrl, id, colorId, selected, onClick, style, renderTooltip } = props;
  const [hasError, setHasError] = useState(false);

  const swatch = (
    <div
      defaultValue={id}
      onClick={onClick}
      className={hasError ? missingSwatchItem(selected) : colorSwatchItem(swatchPath, selected)}
      style={{ ...style }}
    >
      {!hasError && (
        <img
          src={swatchPath}
          alt={colorId}
          onError={() => setHasError(true)}
          style={{ display: 'none' }} // Hide the image element but keep it for error handling
        />
      )}
    </div>
  );

  if (!renderTooltip) {
    return swatch;
  }

  return (
    <Tooltip data-qa="swatch-tooltip" title={hasError ? "An error has occurred fetching this swatch": colorId} placement="top" enterDelay={500}>
      {swatch}
    </Tooltip>
  );
};

export default ColorSwatchItem;
