import * as React from 'react';
import { isNil, isArray } from 'lodash/fp';
import { FormGroup } from '@material-ui/core';
import Select, { createFilter } from 'react-select';
import { Props as SelectProps } from 'react-select/lib/Select';
import styles from './InputSuggest.styles';
import { StylesConfig } from 'react-select/lib/styles';
import Option, { OptionProps } from 'react-select/lib/components/Option';
import SingleValue, { SingleValueProps } from 'react-select/lib/components/SingleValue';
import MultiValue, { MultiValueProps } from 'react-select/lib/components/MultiValue';
import { LoadingIndicator, LoadingIconProps } from 'react-select/lib/components/indicators';
import { NoOptionsMessage, NoticeProps } from 'react-select/lib/components/Menu';
import { ValueType } from 'react-select/lib/types';
import { isString } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { classes } from 'typestyle';

const ErrorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(247, 92, 105, 0.97)',
    color: '#FFF',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const QASelectOption = (props: OptionProps<Suggestion>) => {
  const icon = props.data.icon && (
    <ErrorTooltip title="Conflict: Current value not found in valid values.">
      <i className={classes(styles.errorIcon, props.data.icon)} />
    </ErrorTooltip>
  );
  const option = props.data.icon ? (
    <div className={styles.errorContainer}>
      <Option {...props} />
    </div>
  ) : (
    <Option {...props} />
  );
  return (
    <div data-qa={'option-value'}>
      {icon}
      {option}
    </div>
  );
};

const QASelectSingleValue = (props: SingleValueProps<Suggestion>) => {
  const icon = props.data.icon && (
    <ErrorTooltip placement="top" title="Conflict: Attribute not found in valid values.">
      <i className={classes(styles.errorIcon, props.data.icon)} />
    </ErrorTooltip>
  );
  const option = props.data.icon ? (
    <div className={styles.errorContainer}>
      <SingleValue {...props} />
    </div>
  ) : (
    <SingleValue {...props} />
  );
  return (
    <div data-qa={`selected-option`}>
      {icon}
      {option}
    </div>
  );
};

const QASelectMultiValue = (props: MultiValueProps<Suggestion>) => {
  return (
    <div data-qa={`selected-options`}>
      <MultiValue {...props} />
    </div>
  );
};

const InputSuggestLoadingIndicator = (props: LoadingIconProps<Suggestion>) => {
  return (
    <div data-qa="input-suggest-loading-indicator">
      <LoadingIndicator {...props} />
    </div>
  );
};

const InputSuggestNoOptionsMessage = (props: NoticeProps<Suggestion>) => {
  return (
    <div data-qa="input-suggest-no-options">
      <NoOptionsMessage {...props} />
    </div>
  );
};

interface InputSuggestProps extends Partial<SelectProps<Suggestion>> {
  validValues: Suggestion[];
  selected: Suggestion[] | Suggestion | string | undefined;
  multiSelect?: boolean;
  valid?: boolean;
  placeholder?: string;
  onSelect?: (sugg: Suggestion) => void;
  onMultiSelect?: (suggs: ValueType<Suggestion>) => void;
  alwaysShowMenu?: boolean;
  styles?: StylesConfig;
  dataQa?: string;
  disabled?: boolean;
  selectRef?: React.RefObject<Select<Suggestion>>;
}

export interface Suggestion {
  label: string;
  value: string | number | object;
  icon?: string;
}

export default class InputSuggest extends React.Component<InputSuggestProps> {
  static defaultProps = {
    multiSelect: false,
  };

  constructor(props: InputSuggestProps) {
    super(props);
  }

  getSelection() {
    const { selected } = this.props;

    if (isNil(selected)) {
      return [];
    }
    // if it is returning a string, that means it wasn't found and is an error
    if (isString(selected)) {
      const errorSelection: Suggestion = {
        label: selected,
        value: selected,
        icon: 'fal fa-exclamation-triangle',
      };
      return errorSelection;
    }

    return isArray(selected) ? selected : [selected];
  }

  handleSingleSelect(selections: ValueType<Suggestion>) {
    const { onSelect } = this.props;
    let selection;
    if (isArray(selections)) {
      selection = selections[0];
    } else {
      selection = selections;
    }
    if (selection && onSelect) {
      onSelect(selection);
    }
  }

  handleMultiSelect(selections: ValueType<Suggestion>) {
    const { onMultiSelect } = this.props;
    if (onMultiSelect) {
      onMultiSelect(selections);
    }
  }

  onSuggestionsSelected = (selectedSuggestions: ValueType<Suggestion>) => {
    const { multiSelect } = this.props;

    if (multiSelect) {
      this.handleMultiSelect(selectedSuggestions);
    } else {
      this.handleSingleSelect(selectedSuggestions);
    }
  };
  render() {
    const {
      validValues,
      multiSelect,
      placeholder,
      alwaysShowMenu,
      dataQa,
      disabled,
      menuIsOpen,
      menuPortalTarget,
      onBlur,
      onFocus,
      selectRef,
      isLoading,
    } = this.props;

    const selected = this.getSelection();
    const selectProps: SelectProps<Suggestion> = {
      className: styles.selectContainer,
      isMulti: multiSelect,
      components: {
        LoadingIndicator: InputSuggestLoadingIndicator,
        Option: QASelectOption,
        SingleValue: QASelectSingleValue,
        MultiValue: QASelectMultiValue,
        NoOptionsMessage: InputSuggestNoOptionsMessage,
      },
      placeholder: placeholder ?? 'Search...',
      onChange: this.onSuggestionsSelected,
      value: selected,
      options: validValues,
      styles: this.props.styles,
      menuIsOpen: menuIsOpen ?? (alwaysShowMenu || undefined),
      isDisabled: !isNil(disabled) ? disabled : false,
      isSearchable: true,
      filterOption: createFilter({ ignoreAccents: false }),
      menuPortalTarget,
      onBlur,
      onFocus,
      isLoading,
    };

    return (
      <FormGroup className={styles.container}>
        <div className={classes('select-container', selectProps.isDisabled ? 'disabled' : '')} data-qa={dataQa}>
          <Select {...selectProps} ref={selectRef} />
        </div>
      </FormGroup>
    );
  }
}
