import { smallMediumFont } from 'src/utils/Style/Theme';
import { style } from 'typestyle';

const container = style({
  padding: '0 1.5rem',
});

const titleText = style({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const itemsContainer = style({
  height: '100%',
});

const item = style({
  marginBottom: '2rem',
});

const itemTitle = style({
  display: 'flex',
  alignItems: 'center',
});

const searchContainer = style({
  width: '100%',
  padding: '1rem 0',
  $nest: {
    input: {
      width: '100%',
      border: '1px solid #ced4da',
      fontSize: smallMediumFont,
      padding: '0.3rem 0.75rem',
      height: 'calc(1.5em + 0.75rem + 2px)',
      $nest: {
        '&:focus-visible': {
          color: '#495057',
          backgroundColor: '#fff',
          borderColor: '#29ffee',
          outline: 0,
          boxShadow: '0 0 0 0.2rem rgba(0, 168, 155, 0.25)',
        },
      },
    },
  },
});

export default {
  container,
  titleText,
  itemsContainer,
  item,
  itemTitle,
  searchContainer,
};
