import * as React from 'react';
import { HeaderInfo } from './FitViewInterfaces';
import styles from './FitViewGroup.styles';

const FitViewGroup = React.forwardRef((props: HeaderInfo, ref: any) => {
  const TRANSITION = 'left 1.5s, width 1.5s, transform 2s';
  const { header, subheader, isAnimated, maxHeaderHeight } = props;
  const subheaderHtml = subheader.map((item: { name: string; value: string }, index: number) => {
    const className = [styles.subheader];
    if (index === props.subheader.length - 1) {
      className.push(styles.subheaderLast);
    }
    return (
      <div key={`${item.name}${index}`} className={className.join(' ')} data-qa="fitViewGroupSubheader">
        <div className={styles.subheaderName} data-qa="fitViewGroupSubheaderName">
          {item.name}:&nbsp;
        </div>
        <div className={styles.subheaderValue} data-qa="fitViewGroupSubheaderValue">
          {item.value}
        </div>
      </div>
    );
  });
  const transition = isAnimated ? TRANSITION : '';
  const headerStyle = { minHeight: maxHeaderHeight };
  const columnStyle = { transition };
  return (
    <div ref={ref} className={`fitview-group ${styles.FitViewGroup}`} style={columnStyle} data-qa="fitViewGroup">
      <div className={styles.headerContainer} data-qa="headerContainer" style={headerStyle}>
        <div data-qa="headerText">{header}</div>
        <div className={styles.subheaderContainer}>{subheaderHtml}</div>
      </div>
    </div>
  );
});
FitViewGroup.displayName = 'FitViewGroup';
export default FitViewGroup;
