import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const button = style({
  background: 'rgb(246, 246, 247)',
  color: 'black',
  height: 60,
  cursor: 'pointer',
  margin: '0 auto',
  width: 340,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: '.9rem',
  marginBottom: 18,
  marginTop: 8,
});

const styles = {
  container: style({
    width: 500,
  }),
  body: style({
    width: '100%',
    height: '85%',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      'a:hover': {
        textDecoration: 'none',
      },
    },
  }),
  cropperContainer: style({
    height: 500,
    width: '100%',
  }),
  buttonContainer: style({
    bottom: 0,
    width: '100%',
    height: 60,
    marginBottom: 10,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
  }),
  button: classes(
    button,
    style({
      $nest: {
        '&:hover': {
          background: TEAL_PRIMARY,
          color: 'white',
          textDecoration: 'none !important',
        },
      },
    })
  ),
  buttonDisabled: classes(
    button,
    style({
      background: 'rgb(247, 247, 247)',
      color: 'rgb(199,199,199)',
      cursor: 'default',
    })
  ),
  titleContainer: style({
    height: '15%',
    width: '100%',
    padding: 15,
    marginBottom: 10,
  }),
  buttonCancel: style({
    position: 'absolute',
    right: 15,
    top: 15,
    fontWeight: 600,
    cursor: 'pointer',
  }),
  title: style({
    fontSize: '1rem',
    fontWeight: 600,
  }),
  buttonText: style({
    top: 2,
    position: 'relative',
    fontSize: '.9rem',
  }),
  fab: style({
    position: 'absolute',
    bottom: '24px',
    right: '24px',
    color: '#fff',
    backgroundColor: '#1fa67a',
    width: 56,
    height: 56,
    padding: 0,
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    borderRadius: '50%',
    zIndex: 999,
    cursor: 'pointer',
  }),
};

export default styles;
