import { ofType } from 'redux-observable';
import { AppEpic } from 'src/store';
import { mergeMap, map, filter, of } from 'rxjs';
import { receiveTenantConfig, selectGeoLevel } from './GeoTrends.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import {
  ConfDefnComponentType,
  GeoTrendsComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { inputIsNotNullOrUndefined, isScopeDefined } from 'src/utils/Functions/epicsFunctions';
import { asyncGetMapData } from './GeoTrends.container';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';

export const geoTrendsLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      setActivePage.type,
      setActiveSubPage.type,
      receiveTenantConfig.type,
      updateFlowStatus.type,
      selectGeoLevel.type,
      updateSelectedItem.type
    ),
    map(() => {
      return maybeGetComponentProps<GeoTrendsComponent>(state$.value, ConfDefnComponentType.geoTrends);
    }),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap(({ dataApi }) => {
      return of(asyncGetMapData(dataApi));
    })
  );
};
