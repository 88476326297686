import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { isEmpty, isNil, size, debounce, isEqual } from 'lodash';
import { isString } from 'lodash/fp';
import { toast } from 'react-toastify';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { getUrl } from '../../StyleEdit.utils';
import { getValidValues } from '../StyleEditSection.client';
import { getSelectedValidValues } from './RenderUtils';
import ServiceContainer from 'src/ServiceContainer';
export interface ValidValueEditorParams extends ICellRendererParams {
  asCsv?: boolean;
  dataConfig: ClientDataApi;
  ignoreCache?: boolean;
}

interface State {
  validValues: Suggestion[];
  loaded: boolean;
  selectedOptions: Suggestion[];
}
const handleErrorMessage = debounce((err) => {
  toast.error('An error occurred fetching the valid options');
  ServiceContainer.loggingService.error(`An error occurred fetching the valid options: ${err.message}`, err.stack);
}, 1000);
function arrayContainsArray(superset: unknown[], subset: unknown[]) {
  return subset.every(function(value: unknown) {
    return superset.indexOf(value) >= 0;
  });
}

export default class ValidValuesRenderer extends React.Component<ValidValueEditorParams, State> {
  constructor(props: ValidValueEditorParams) {
    super(props);
    this.state = {
      validValues: [],
      loaded: false,
      selectedOptions: [],
    };
  }
  componentDidUpdate(prevProps: ValidValueEditorParams): void {
    if (!isEqual(prevProps.value, this.props.value) && this.state.validValues) {
      const currentValue = this.props.value;
      const selectedOptions = !isNil(currentValue)
        ? getSelectedValidValues(currentValue, this.state.validValues, true)
        : [];
      this.setState({
        selectedOptions,
      });
    }
  }
  componentDidMount() {
    const { value, dataConfig, ignoreCache } = this.props; // value comes in as an array
    const url = getUrl(dataConfig);

    getValidValues(url, false, false, ignoreCache)
      .then((validValues) => {
        const selectedOptions = !isNil(value) ? getSelectedValidValues(value, validValues, true) : [];

        this.setState({
          selectedOptions,
          validValues,
          loaded: true,
        });
      })
      .catch((err) => {
        handleErrorMessage(err);
      });
  }

  render() {
    const { validValues, selectedOptions } = this.state;
    if (isEmpty(selectedOptions)) {
      return <div />;
    }
    const selectedOptionsLabels = selectedOptions.map((option) => option.label).filter((value) => size(value) > 0);
    const validValuesLabels = validValues
      .map((value) => (isString(value) ? value : value.label))
      .filter((value) => size(value) > 0);
    const displayText = arrayContainsArray(selectedOptionsLabels, validValuesLabels)
      ? 'ALL'
      : selectedOptionsLabels.join(', ');

    return <div>{displayText}</div>;
  }
}
