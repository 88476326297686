import * as React from 'react';
import { default as classes } from '../TargetCreation/TargetCreation.styles';
import { Lens, Getter } from 'monocle-ts';
import { default as DatePicker } from '../DatePicking/DatePicker.container';
import { TextField, Button } from '@material-ui/core';
import { SeedPageSlice } from '../TargetCreation/TargetCreation.types';
import { CriteriaOption } from '../Criteria/Criteria.types';
import { default as Criteria } from '../Criteria/Criteria.container';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { AppState } from 'src/store';

export interface OwnProps {
  scopeConfigLens: Getter<AppState, AssortmentScopeSelectorSlice>;
  lens: Lens<AppState, SeedPageSlice>;
  seedText?: string;
  setSeed: () => void;
}
export interface ValueProps {
  amount: number;
  showSeedWeeks: boolean;
}
export interface FunctionProps {
  getSeedOptions: (dataIndex: string) => CriteriaOption[];
  onShowView: () => void;
  onDestroy?: () => void;
  updateAmount?: (value: number) => void;
}
export interface Props extends ValueProps, OwnProps, FunctionProps {}

export class SeedOptions extends React.Component<Props> {
  componentDidMount() {
    if (this.props.onShowView) {
      this.props.onShowView();
    }
  }
  componentWillUnmount() {
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }
  render() {
    let formContent;
    if (this.props.showSeedWeeks) {
      formContent = (
        <DatePicker
          //@ts-ignore
          lens={this.props.lens.compose(Lens.fromProp<SeedPageSlice>()('weeks'))}
          label={'Sales Start And End: '}
          classes={classes.datepicker}
        />
      );
    } else {
      formContent = (
        <TextField
          id="amount"
          className={''}
          type="number"
          value={this.props.amount}
          label={'Input Value'}
          onChange={(event) => {
            if (this.props.updateAmount) {
              this.props.updateAmount(parseFloat(event.target.value));
            }
          }}
        />
      );
    }
    return (
      <article className={classes.container}>
        <header>
          <h3>Update Seed Metric</h3>
          <hr />
        </header>
        <div className={classes.body}>
          <section className={classes.stepping} />
          <p className={classes.seedText}>{this.props.seedText}</p>
          <section className={classes.content}>
            <section className={classes.mainBody}>
              <Criteria
                //@ts-ignore
                lens={this.props.lens.compose(Lens.fromProp<SeedPageSlice>()('criteria'))}
                getOptions={this.props.getSeedOptions}
                titles={['SELECT SEED PLAN', 'SELECT SEED METRIC']}
                datepicker={formContent}
              />
            </section>
            <footer
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ color: 'white' }}
                onClick={() => this.props.setSeed()}
              >
                {'Submit'}
              </Button>
            </footer>
          </section>
        </div>
      </article>
    );
  }
}
