import { classes, style, media, keyframes } from 'typestyle';
import { RED_PRIMARY, TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { important, px } from 'csx';

export const HEADERBAR_HEIGHT = 28;
const NAVBAR_RIGHT_MIN_WIDTH = 175;
const NAVBAR_HORIZONTAL_PADDING = 16;
const spinnerFrames = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});
const headerbarStyle = style(
  media(
    { type: 'print' },
    {
      $nest: {
        '.navbar-left': {
          // all the other stuff except the scope text is hidden in print mode
          maxWidth: '100%',
        },
      },
    }
  ),
  {
    $debugName: 'headerbarStyle',
    padding: `5px ${NAVBAR_HORIZONTAL_PADDING}px`,
    backgroundColor: '#EFF0F0',
    fontSize: '12px',
    height: HEADERBAR_HEIGHT,
    $nest: {
      '.nav': {
        flexDirection: 'row',
      },
      '.navbar-nav': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        $nest: {
          li: {
            paddingLeft: 3,
          },
        },
      },
      '#headerbar-print > i': {
        paddingRight: 5,
      },
      '#headerbar-reconcile > i': {
        paddingRight: 5,
      },
      '.navbar-left': {
        // max the width of the scope text on small screens so it doesn't overflow to the next line
        // +1 so it doesn't overflow the line
        maxWidth: `calc(100% - ${NAVBAR_RIGHT_MIN_WIDTH + 1}px)`,
      },
      '.navbar-right': {
        minWidth: NAVBAR_RIGHT_MIN_WIDTH,
        maxHeight: 18,
      },
    },
  }
);

// importants below are needed to override Material UI Link styles
const iconLink = style(
  media(
    {
      type: 'print',
    },
    {
      display: 'none',
    }
  ),
  {
    $debugName: 'iconLink',
    display: 'flex',
    alignItems: 'center',
    color: important('#3A3A3E'),
    marginLeft: important(px(3)),
    padding: important('0 0.2rem'),
    textUnderlineOffset: px(3),
    $nest: {
      '&:hover': {
        color: important('#005c54'),
      },
      '&.disabled:hover': {
        color: important('gray'),
      },
      '&.clickable': {
        cursor: 'pointer',
      },
      '&.refreshable': {
        color: important(TEAL_PRIMARY),
      },
      '&.indeterminate': {
        cursor: 'not-allowed',
        color: important(RED_PRIMARY),
        textDecoration: 'none',
      },
      '&.disabled': {
        color: important('gray'),
        cursor: 'not-allowed',
      },
    },
  }
);
const printPopover = style({
  $nest: {
    '.s5-MuiPopover-paper': {
      border: '1px solid rgba(0,0,0,.2)',
    },
    '.s5-MuiPaper-elevation8': {
      boxShadow: 'none',
    },
    '.s5-MuiTypography-body1': {
      padding: '0.5rem 0.75rem',
      marginBottom: 0,
      fontSize: '1rem',
      backgroundColor: '#f7f7f7',
      borderBottom: '1px solid #ebebeb',
    },
  },
});

const filterPopover = style({
  pointerEvents: 'none',
  $nest: {
    '.s5-MuiPaper-elevation8': {
      boxShadow: 'none',
    },
    '.s5-MuiPopover-paper': {
      pointerEvents: 'auto',
      maxHeight: `calc(100vh - 150px)`,
      paddingLeft: 0,
      minWidth: 175,
      overflow: 'auto',
      marginTop: '0.45rem',
      border: '1px solid rgba(0,0,0,.2)',
      $nest: {
        '.s5-MuiTypography-body1': {
          padding: '0.5rem 0.75rem',
          marginBottom: 0,
          fontSize: '1rem',
          backgroundColor: '#f7f7f7',
          borderBottom: '1px solid #ebebeb',
          color: 'black',
        },
        ul: {
          marginBottom: 'auto',
          listStyleType: 'disc',
          width: '100%',
          margin: 0,
          color: 'black',
          fontSize: '0.87rem',
          padding: '0.5rem 1.5rem',

          $nest: {
            li: {
              margin: 'auto',
            },
            '.list-popover-container': {
              display: 'flex',
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
  },
});

const iconRightSpace = style({
  $debugName: 'iconRightSpace',
  marginRight: '0.25rem',
});

const selectorDropDown = style({
  listStyleType: 'none',
  width: '100%',
  margin: 0,
  paddingLeft: 0,
  $nest: {
    li: {
      width: '100%',
      height: 30,
      padding: 5,
      cursor: 'pointer',
      $nest: {
        '&:hover': {
          background: '#eee',
        },
      },
    },
  },
});

const smallSpinner = style({
  display: 'inline-block',
  width: 12,
  height: 12,
  $nest: {
    '&::after': {
      content: '" "',
      display: 'block',
      width: 10,
      height: 10,
      margin: '0px',
      border: '2px solid #898989',
      borderRadius: '50%',
      animationName: spinnerFrames,
      animationDuration: '1.2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      borderColor: '#898989 transparent #898989 transparent',
    },
  },
});

export function buildFilterIconClass(shouldFiltersBeEnabled: boolean) {
  let possibleClass;
  if (shouldFiltersBeEnabled) {
    possibleClass = style({
      $debugName: 'activeFilter',
      color: TEAL_PRIMARY,
    });
  }
  return classes('fas fa-filter', iconRightSpace, possibleClass);
}

export default {
  headerbarStyle,
  iconLink,
  filterPopover,
  iconRightSpace,
  selectorDropDown,
  smallSpinner,
  printPopover,
};
