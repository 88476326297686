import { Epic, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { AppEpic } from 'src/store';
import { getScopeReadyData, ServerScope } from 'src/state/scope/Scope.types';
import {
  getAvailableListing,
  getEffeciveGroups,
  getGroupFromConfigItem,
  PivotOptions,
} from 'src/components/Mfp/PivotConfigurator/utils';

import _ from 'lodash';
import PivotConfig from 'src/pivot/PivotConfig';
import PivotManager from 'src/pivot/Pivot.client';
import { AxiosInstance } from 'axios';
import { NEVER, Observable, of } from 'rxjs';
import { AnyAction } from 'redux';
import { SettingsState } from 'src/state/settings/settings.slice';
import { requestRefreshGrid } from 'src/state/scope/Scope.slice';
import { ViewParams } from 'src/state/ViewConfig/ViewConfig.types';
import { setTrendSummaryDataStale } from './TrendSummary.slice';

export const trendSummaryRefresh: AppEpic = (action$, state$): Observable<AnyAction | Observable<never>> => {
  return action$.pipe(
    ofType(requestRefreshGrid.type),
    concatMap(() => {
      const scopeReadyData = getScopeReadyData(state$.value.mfpScope);
      if (scopeReadyData) {
        return of(setTrendSummaryDataStale());
      }
      return NEVER;
    })
  );
};

export function buildChartPivotManager(
  mainConfig: ServerScope,
  settings: SettingsState['entriesByKey'],
  scopeId: string,
  client: AxiosInstance,
  viewParams: ViewParams
) {
  const all = getAvailableListing(mainConfig, settings);
  const rows = [viewParams.summaryMetrics.metrics];
  const columns = [viewParams.summaryMetrics.revisions];
  const viewParam: PivotOptions = {
    rows,
    columns,
  };
  const effective = getEffeciveGroups(all, viewParam);
  const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map((mi) => getGroupFromConfigItem(mi, viewParam, levelsMap));
  const pivotCols = effective.column.map((mi) => getGroupFromConfigItem(mi, viewParam, levelsMap));
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols,
  });
  const pivotManager = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: 'TrendSummaryPivot',
  });
  return pivotManager;
}
