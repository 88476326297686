import {
  FormControl,
  Select,
  Input,
  InputLabel,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { RefComponent, TenantViewConfigurator } from './ConfigEditor.utils';
import { autocompleteStyle, perspectiveSelectStyle } from './ConfigEditor.styles';
import { isEqual } from 'lodash';
import { Perspective } from 'src/services/configuration/codecs/bindings.types';

export interface ConfigEditorMainProps {
  selectedView: TenantViewConfigurator;
  isDefault: boolean;
  onChangeDefault: () => void;
  onChangeView: (view: TenantViewConfigurator) => void;
  configEditorViewDefn: RefComponent[] | undefined;
}

const ConfigEditorMain = (props: ConfigEditorMainProps) => {
  const {
    selectedView: passedView,
    configEditorViewDefn: config = [],
    isDefault,
    onChangeDefault,
    onChangeView,
  } = props;
  const [selectedViewIndex, setSelectedViewIndex] = useState<number>(-1);
  // FIXME: this is no longer valid as implemented.
  // Because the perspectives are no longer hardcoded the perspectives control
  // needs to allow user to enter string value instead.
  const [perspectives, setPerspectives] = useState<Perspective[]>([]);

  useEffect(() => {
    let foundSelectedViewIndex = passedView && config.findIndex((x) => passedView['$componentPropRef'] == x.id);
    // TODO: FALLBACK TO REMOVE; just for default conf without propRefs already
    if (foundSelectedViewIndex == -1) {
      foundSelectedViewIndex = config.findIndex((x) => isEqual(passedView.componentProps, x.properties.componentProps));
    }
    setPerspectives((passedView && passedView.inPerspectives) || []);
    setSelectedViewIndex(foundSelectedViewIndex || -1);
  }, [config, passedView]);

  const getImagePath = (): string => {
    if (config[selectedViewIndex] && config[selectedViewIndex].properties) {
      const properties = config[selectedViewIndex].properties;
      let name = properties.component;
      if (properties.componentProps) {
        // TODO: Fallback for components the share the same component. This isn't the best solution, as if they change the title
        switch (properties.componentProps.title) {
          case 'Receipt View':
            name = 'ReceiptView';
            break;
          case 'DC View':
            name = 'DCView';
            break;
          case 'Financial Reconciliation':
            name = 'FinancialRecon';
            break;
        }
      }

      return `./src/images/ConfigImages/${name}.png`;
    }
    return '';
  };
  const saveView = (index: number) => {
    // FIXME: This is loading the selection in the view state, & passing to the parent. This is not being properly handled
    const newPassedView = Object.assign({}, passedView);
    newPassedView['$componentPropRef'] = config[index].id;
    newPassedView['component'] = config[index].properties.component as TenantViewConfigurator['component'];
    newPassedView['componentProps'] = config[index].properties.componentProps;
    onChangeView(newPassedView);
  };
  const saveViewPerspectives = (perspectives: any[]) => {
    const newPassedView = Object.assign({}, passedView);
    newPassedView.inPerspectives = perspectives;
    onChangeView(newPassedView);
  };

  const imgPath = config[selectedViewIndex] && getImagePath();
  return passedView ? (
    <div style={{ padding: 10, width: '100%' }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 600 }}>{passedView.name}</span>
      <div className={perspectiveSelectStyle}>
        <FormControl>
          <InputLabel>Perspectives</InputLabel>
          {/* FIXME: this Select behavior here needs to allow the user to enter there own values, see AutoComplete instead */}
          <Select
            style={{ width: 200 }}
            multiple
            value={perspectives}
            onChange={(e: any) => {
              if (e.target.value.length > 0) {
                setPerspectives(e.target.value);
                saveViewPerspectives(e.target.value);
              } else {
                setPerspectives([]);
                saveViewPerspectives([]);
              }
            }}
            input={<Input />}
            renderValue={(selected: any) => selected.join(', ')}
          >
            {[].map((persp) => (
              <MenuItem key={persp} value={persp}>
                {persp}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormGroup style={{ marginTop: 10, marginLeft: 20 }}>
          <FormControlLabel
            control={<Checkbox checked={isDefault} onChange={() => onChangeDefault()} />}
            label="Is Default View"
          />
        </FormGroup>
      </div>
      <div className={autocompleteStyle}>
        <Autocomplete
          value={
            selectedViewIndex > -1 && config
              ? config[selectedViewIndex]
              : { title: '', id: '', properties: { component: '', componentProps: [], inPerspectives: [] } }
          }
          fullWidth
          disablePortal
          options={config || []}
          getOptionLabel={(option) => option.title}
          onChange={(_event, newValue) => {
            const newIndex = config.findIndex((x) => x == newValue);
            setSelectedViewIndex(newIndex);
            saveView(newIndex);
          }}
          renderInput={(params) => <TextField {...params} label="View Config" />}
        />
      </div>
      <img style={{ maxWidth: '100%', marginTop: 40 }} src={imgPath || ''} />
    </div>
  ) : (
    <div />
  );
};

export default ConfigEditorMain;
