import React,
{ forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState }
  from 'react';
import { connect } from 'react-redux';
import {
  DropdownItemProps,
  Input,
  Select
} from 'semantic-ui-react';
import LoadingMask from '../../LoadingMask/LoadingMask';
import { getMemberName } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';
import { SettingsEntry } from '../../../services/Settings';
import './_PlanMetadataGrid.scss';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import {
  isScopeNotReady, ServerScope, getScopeId, getScopeReadyData
} from '../../../state/scope/Scope.types';
import { DEFAULT_DIMENSIONS, TIME } from '../../../utils/Domain/Constants';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  ColDef,
  ValueGetterParams,
  ValueFormatterParams,
  ColGroupDef,
  KeyCreatorParams
} from '@ag-grid-community/core';
import ButtonRenderer from 'src/components/AgGrid/ButtonRenderer';
import { getMemberFromValue, HierarchyValueGetter } from 'src/utils/Domain/hierarchy.utils';
import { AvailableMembers } from 'src/services/Scope.client';
import classNames from 'classnames';
import { memberToDropdown } from 'src/components/Mfp/MfpScopeSelector/MfpScopebar.container';
import ServiceContainer from 'src/ServiceContainer';
import { AppState } from 'src/store';
import { MacroSummaryRenderer } from 'src/components/MacroSummaryModal/MacroSummaryRenderer';
import { WorkingSetContext } from 'src/state/workingSets/workingSets.slice';
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import { usePlanMetadata } from './FetchPlanMetadataHook';
import { createNewNonWorkingScope } from 'src/state/workingSets/nonWorkingSets.slice';
import { gridUserDetailsToString } from 'src/state/scope/codecs/UserDetails';
import { RequiredDimension } from 'src/space';
import {
  useHandleChangeFilters,
  useHandleGroupByChange,
  useHandleQuickFilterChange,
  useOnRowDataChanged
} from './PlanMetadataGrid.utils';
import { startCase } from 'lodash';
import { PlansFilters } from 'src/services/Admin.service';
import { MacroSummaryDetailRenderer } from 'src/components/MacroSummaryModal/MacroSummaryDetail';
import DeleteIconRenderer from 'src/components/DeleteIconRenderer/DeleteIconRenderer';
import clonePlanRenderer from 'src/components/Mfp/ClonePlanRenderer/ClonePlanRenderer';
import CommentsCellRenderer from 'src/components/Mfp/Comments/CommentsCellRenderer';
import { loadPlanComments } from 'src/components/Mfp/Comments/Comments.actions';
import Comments from 'src/components/Mfp/Comments/Comments';
import MasterDetailSwitch from 'src/components/Mfp/MasterDetailSwitch/MasterDetailSwitch';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { ContextMfpModule } from 'src/services/configuration/codecs/confdefn';

export const auth0OwnerToName = (params: ValueGetterParams): string => {
  const data = params.data;
  const maybeName = data && data.ownerEmail && typeof data.ownerEmail === 'string' ? data.ownerEmail : data.ownerName;
  return typeof maybeName === 'string' ? maybeName : data.owner;
};

export function mapStateToProps(state: AppState): PlanMetadataValueProps {
  const currentModule = getMfpModule(state)
  return {
    currentModule,
    scopeId: getScopeId(state.mfpScope),
    workingSets: state.workingSets.contexts,
    mainConfig: getScopeReadyData(state.mfpScope)?.mainConfig,
    settingsByKey: state.settings.entriesByKey,
    availableMembers: state.viewConfigSlice.availableMembers?.space
  };
}

export const mapDispatchToProps = {
  createNewNonWorkingScope: createNewNonWorkingScope,
  loadPlanComments: loadPlanComments
};
type PlanMetadataDispatchProps = typeof mapDispatchToProps;

export interface PlanMetadataValueProps {
  currentModule?: ContextMfpModule,
  scopeId?: string,
  workingSets: WorkingSetContext[],
  mainConfig?: ServerScope,
  settingsByKey?: {
    [key: string]: SettingsEntry
  },
  availableMembers?: AvailableMembers['space']
};

interface PlanMetadataGridOwnProps {
  extraColDefs: ColDef[],
  extraFilters?: PlansFilters
}

interface ViewPlansGridProps extends PlanMetadataValueProps,
  PlanMetadataDispatchProps,
  PlanMetadataGridOwnProps { }

const frameworkComponents = {
  buttonRenderer: ButtonRenderer,
  masterDetailSwitch: MasterDetailSwitch,
  commentsCellRenderer: CommentsCellRenderer,
  commentsMasterDetail: Comments,
  masterDetailCellRenderer: MacroSummaryRenderer,
  masterDetail: MacroSummaryDetailRenderer,
  deleteIconRenderer: DeleteIconRenderer,
  clonePlanRenderer: clonePlanRenderer
};

const defaultColDef: ColDef = {
  suppressMovable: true,
  lockPinned: true,
  lockPosition: true,
  resizable: true,
  filter: true,
  sortable: true,
  filterParams: { newRowsAction: 'keep' }
};

const buildColDefs = (
  scopeConfig: ServerScope,
  settingsByKey: { [key: string]: SettingsEntry },
  createNewNonWorkingScope: typeof mapDispatchToProps['createNewNonWorkingScope'],
  loadPlanComments: typeof mapDispatchToProps['loadPlanComments'],
  extraColDefs: ColDef[],
  selectedGroupBy: string
) => {
  const buttonColDefDefaults: ColDef = {
    resizable: false,
    filter: false,
    sortable: false
  };
  if (!scopeConfig || isScopeNotReady(scopeConfig)) { return []; }
  // build the dimension space colDefs dynamically
  const spaceColDefs: ColDef[] = DEFAULT_DIMENSIONS.map(key => {
    const memberName = getMemberName(
      key,
      scopeConfig,
      settingsByKey!
    );

    const colDef: ColDef = {
      colId: key,
      headerName: memberName,
      valueGetter: getMemberFromValue
    };
    return colDef;
  });

  const groupByKeyCreator = (params: KeyCreatorParams<RequiredDimension>) => {
    if (selectedGroupBy === 'scope') {
      return JSON.stringify(params.value);
    } else {
      // ignored here due to string typing from the key
      // @ts-ignore
      return params.value[selectedGroupBy];
    }
  };
  const groupByFormatter = (params: ValueFormatterParams) => {
    if (selectedGroupBy === 'scope') {
      const space = JSON.parse(params.value);
      return Object.values(space).join(' - ');
    } else {
      return params.value;
    }
  };

  const defs: (ColDef | ColGroupDef)[] = [
    {
      field: 'id',
      colId: 'id',
      hide: true
    },
    {
      headerName: 'Group',
      field: selectedGroupBy, // changing this helps ag-grid delta the coldefs correctly
      rowGroup: true,
      hide: true,
      valueGetter: (params: HierarchyValueGetter) => {
        return params.data.space;
      },
      // note that the return of this becomes the params.value of the row for the valueFormatter
      keyCreator: groupByKeyCreator,
      valueFormatter: groupByFormatter
    },
    ...spaceColDefs,
    {
      ...buttonColDefDefaults,
      field: 'view',
      colId: 'view',
      headerName: 'Summary',
      cellRenderer: 'masterDetailCellRenderer',
      valueGetter: (params: ValueGetterParams): PlanMetadata => {
        return params.data;
      },
      cellRendererParams: {
        createNewNonWorkingScope: createNewNonWorkingScope
      }
    },
    {
      ...buttonColDefDefaults,
      field: 'comments',
      colId: 'comments',
      headerName: 'Comments',
      cellRenderer: 'commentsCellRenderer',
      valueGetter: (params: ValueGetterParams): PlanMetadata => {
        return params.data;
      },
      cellRendererParams: {
        loadPlanComments: loadPlanComments
      }
    },
    {
      field: 'authoredBy.uid',
      colId: 'authoredBy',
      headerName: 'Authored By',
      valueGetter: gridUserDetailsToString
    },
    ...extraColDefs
  ];
  return defs;
};
export interface PlanMetadataRef {
  refreshData: () => void
}

const PlanMetadataGrid = forwardRef<PlanMetadataRef, ViewPlansGridProps>(function PlanMetadataGrid(props: ViewPlansGridProps, ref) {
  const {
    createNewNonWorkingScope,
    loadPlanComments,
    extraColDefs,
    extraFilters,
    settingsByKey,
    availableMembers,
    currentModule,
    mainConfig
  } = props;

  const client = ServiceContainer.axios
  const [selectedScopeGroupBy, setSelectedScopeGroupBy] = useState<string>('scope');
  const [selectedTime, setSelectedTime] = useState<string | undefined>(mainConfig && availableMembers ?
    availableMembers?.time.find((s) => s.id === mainConfig?.inSeason)?.id :
    undefined
  );

  // ag-grid api stuff
  const gridRef = useRef<AgGridReact>(null);
  const gridApi = gridRef.current?.api;
  const columnApi = gridRef.current?.columnApi;

  const timeDropdowns = useMemo(() => {
    return availableMembers ? availableMembers.time.map(memberToDropdown) : [];
  }, [availableMembers]);
  const groupByDropDowns: DropdownItemProps[] = useMemo(() => {
    const dds: DropdownItemProps[] = DEFAULT_DIMENSIONS
      .filter(d => d !== TIME) // there's a separate dropdown for time, so exclude it
      .map((d) => { return { text: startCase(d), value: d }; });
    // also add in the magic "all" value called "scope"
    return dds.concat({ text: 'Product & Location', value: 'scope' });
  }, []);

  const [{ plans, loading }, setFilters, forceRefresh] = usePlanMetadata(
    client,
    currentModule!, // TODO: fix this bang
    {
      ...extraFilters,
      time: selectedTime ? [selectedTime] : undefined
    }
  );

  useEffect(() => {
    if (selectedTime) {
      setFilters({
        ...extraFilters,
        time: [selectedTime]
      });
    }
  }, [extraFilters, selectedTime, setFilters]);

  useEffect(() => {
    if (!selectedTime && mainConfig && availableMembers) {
      setSelectedTime(availableMembers.time.find((s) => s.id === mainConfig.inSeason)?.id);
    }
  }, [availableMembers, mainConfig, selectedTime]);

  // clear data when reloading
  useEffect(() => {
    if (gridApi && plans) {
      gridApi.setRowData(plans);
      gridApi.hideOverlay();
      gridApi.forEachNode(node => {
        // this is here because, in some unusual cases, the detail nodes start expanded
        // this appears to be some kind of caching behavior of aggrid, or a bug in aggrid itself
        // check to see if these nodes register as expanded when loading data, and close them if they are
        // otherwise they all render as expanded on load, and we only ever want one to be open at a time
        if (node.detail && node.expanded) {
          node.setExpanded(false);
        }
      });
    } else if (gridApi && !plans) {
      gridApi.setRowData([]);
      gridApi.showLoadingOverlay();
    }
  }, [plans, gridApi]);

  useEffect(() => {
    if (gridApi && loading) {
      gridApi.showLoadingOverlay();
    } else if (gridApi && !loading) {
      gridApi?.hideOverlay;
    }
  }, [gridApi, loading]);

  // create and memoize the coldefs
  useEffect(() => {
    if (
      gridApi &&
      mainConfig &&
      settingsByKey &&
      createNewNonWorkingScope &&
      loadPlanComments
    ) {
      gridApi.setColumnDefs(buildColDefs(
        mainConfig,
        settingsByKey,
        createNewNonWorkingScope,
        loadPlanComments,
        extraColDefs,
        selectedScopeGroupBy
      ));
    }
  }, [gridApi,
    mainConfig,
    settingsByKey,
    createNewNonWorkingScope,
    loadPlanComments,
    extraColDefs,
    selectedScopeGroupBy
  ]);

  useImperativeHandle(ref, () => ({
    refreshData: () => {
      forceRefresh();
    }
  }));

  // various hooks
  const handleUpdateQuickFilter = useHandleQuickFilterChange(gridApi);
  const handleOnRowDataChanged = useOnRowDataChanged(columnApi);
  const handleChangeGroupBy = useHandleGroupByChange(setSelectedScopeGroupBy);
  const handleChangeTime = useHandleChangeFilters(
    setSelectedTime,
    setFilters,
    extraFilters
  );

  // disconnect the event handlers on unmount
  useEffect(() => {
    return () => {
      if (gridApi) {
        gridApi.setRowData([]);
        gridApi.removeEventListener('RowDataChanged', handleOnRowDataChanged);
        gridApi.removeEventListener('GridSizeChanged', handleOnRowDataChanged); // TODO: check if we still need this
      }
    };
  }, [gridApi, handleOnRowDataChanged]);

  const checkIsRowMaster = useCallback((data: any) => {
    return data && typeof data === 'object' && 'mvccCounter' in data;
  }, []);

  return (
    <div
      className={classNames('ag-theme-material', 'planmetadata-grid-container')}
      data-qa={'planmetadata-grid'}
    // style={{ height: '100%' }}
    >
      <div className="planmetadatagrid-controls">
        <div className='left-controls-container'>
          <div className="three-wide-column">
            <label>Selected Time</label>
            <Select
              id={'change-time'}
              placeholder={'Select Time Period'}
              onChange={handleChangeTime}
              value={selectedTime}
              options={timeDropdowns}
            />
          </div>
          <div className="three-wide-column">
            <label>Search</label>
            <Input className={'fluid'} placeholder='Search this view...' onChange={handleUpdateQuickFilter} />
          </div>
        </div>
        <div className="right-controls-container">
          <div className="group-by">
            <label>Group By</label>
            <Select
              id={'group-by'}
              placeholder={'Group By'}
              onChange={handleChangeGroupBy}
              value={selectedScopeGroupBy}
              options={groupByDropDowns}
            />
          </div>
        </div>
      </div>
      <div className={'grid-container'}>
        <AgGridReact
          ref={gridRef}
          suppressMovableColumns={true}
          defaultColDef={defaultColDef}
          components={frameworkComponents}
          enableRangeSelection={true}
          suppressMultiRangeSelection={true}
          context={props.availableMembers}
          scrollbarWidth={10}
          loadingOverlayComponent={LoadingMask}
          groupDisplayType={'groupRows'}
          // masterDetail stuff
          masterDetail={true}
          isRowMaster={checkIsRowMaster}
          detailCellRenderer={'masterDetailSwitch'}
          groupDefaultExpanded={1}
          detailRowAutoHeight={true}
          // events
          onGridSizeChanged={handleOnRowDataChanged}
          onRowDataUpdated={handleOnRowDataChanged}
        />
      </div>
    </div>
  );
});
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PlanMetadataGrid);

