import * as React from 'react';
import { classes } from 'typestyle';
import { isArray, isEqual, isNil } from 'lodash';

import { Checkbox, Divider, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';

import * as dropdownStyles from 'src/utils/Style/Dropdown.styles';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { menuItemStyles } from './SubheaderCheckboxes.styles';
import SubheaderDropdown from './SubheaderDropdown';

export interface SubheaderDropdownMultiProps {
  label: string;
  defaultSelection?: TenantConfigViewItem[];
  selections: TenantConfigViewItem[];
  options: TenantConfigViewItem[];
  qaKey?: string;
  customClass?: string;
  disabled?: boolean;
  handleChangeOnClick: (items: TenantConfigViewItem[]) => void;
}

interface State {
  dropdownOpen: boolean;
  selections: string[];
}

const unknownIsStringArray = (selected: unknown): selected is string[] => {
  // not a great guard tbh
  return Array.isArray(selected);
};

export default class SubheaderDropdownMulti extends React.Component<SubheaderDropdownMultiProps, State> {
  maxCharacterCount = 20;

  constructor(props: SubheaderDropdownMultiProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selections: props.selections.map((x) => x.text),
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidUpdate(prevProps: SubheaderDropdownMultiProps) {
    if (!isEqual(prevProps.selections, this.props.selections)) {
      this.setState({
        selections: this.props.selections.map((x) => x.text),
      });
    }
  }

  handleOnChange(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>, value: unknown) {
    // FIXME: Mark is completely uncertain about this change, but the "Refine" multiselect dd in subheader
    // returns in an array form, so Mark just added, in theory, support for both single val and multi value
    // here.
    if (!SubheaderDropdown.isChangeEventSelect(event) || !SubheaderDropdown.isChangeEventSelectValue(value)) {
      // guards added due to type changes introduced
      throw new Error('Invalid change event fired, this is unexpected');
    }
    if (isArray(event.target.value)) {
      if (event.target.value.indexOf('Select All') >= 0) {
        const selections =
          this.state.selections.length != this.props.options.length ? this.props.options.map((x) => x.text) : [];
        this.setState({
          selections,
        });
        this.props.handleChangeOnClick(this.props.options.filter((x) => selections.indexOf(x.text) != -1));
      } else {
        this.setState({
          selections: event.target.value,
        });
        this.props.handleChangeOnClick(this.props.options.filter((x) => event.target.value.indexOf(x.text) != -1));
      }
    } else if (value.key.indexOf('Select All') >= 0) {
      const selections =
        this.state.selections.length != this.props.options.length ? this.props.options.map((x) => x.text) : [];
      this.setState({
        selections,
      });
      this.props.handleChangeOnClick(this.props.options.filter((x) => selections.indexOf(x.text) != -1));
    } else {
      this.props.handleChangeOnClick(this.props.options.filter((x) => event.target.value.indexOf(x.text) != -1));
      this.setState({
        // Warning: runtime change for mui4 here?
        selections: [event.target.value],
      });
    }
  }

  render() {
    const { label, options, qaKey, customClass } = this.props;
    const optionsJsx = options.map((opt, i) => (
      <MenuItem key={i} value={opt.text} classes={{ selected: menuItemStyles }}>
        <Checkbox checked={this.state.selections.indexOf(opt.text) != -1} />
        {opt.text}
      </MenuItem>
    ));
    return (
      <FormControl
        className={classes('form-control-dropdown', dropdownStyles.form)}
        data-qa-component="SubheaderDropdown"
        data-qa-key={qaKey}
      >
        {label && (
          <InputLabel shrink={true} className={dropdownStyles.formLabel}>
            {label}
          </InputLabel>
        )}

        <Select
          data-qa-action="DropdownClick"
          className={classes(dropdownStyles.dropdown, isNil(customClass) ? '' : customClass)}
          disableUnderline={true}
          value={this.state.selections}
          onChange={this.handleOnChange}
          IconComponent={(props) => <i {...props} className="far fa-chevron-down" />}
          multiple={true}
          MenuProps={{
            getContentAnchorEl: null,
          }}
          renderValue={(selected: unknown) => {
            if (!unknownIsStringArray(selected)) {
              throw new Error('Invalid selection passed to multidropdown');
            }
            return (
              <Tooltip
                title={
                  <div style={{ whiteSpace: 'pre-wrap' }}>{this.state.selections.map((x) => ' - ' + x + '\n')}</div>
                }
              >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{selected && selected.join(', ')}</div>
              </Tooltip>
            );
          }}
        >
          <MenuItem key={'Select All'} value={'Select All'}>
            <Checkbox checked={this.state.selections.length == this.props.options.length} />
            {'Select All'}
          </MenuItem>
          <Divider />
          {optionsJsx}
        </Select>
      </FormControl>
    );
  }
}
