import React, { useCallback } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { RouteLink } from './../../types/RouteLink';
import { navItemText } from './TopNavbar.styles';

const EnabledTopNavLinkItem = ({
  name,
  link,
  fastNavOpen,
  onFastNavToggle,
}: {
  name: string;
  link: string;
  fastNavOpen: boolean;
  onFastNavToggle: (tabName: string) => void;
}) => {
  const toggleFastNav = useCallback(() => {
    onFastNavToggle(name);
  }, [name, onFastNavToggle]);

  return (
    <React.Fragment>
      <Link to={link} className={`nav-link ${navItemText}`}>
        {name}
      </Link>
      <button className={`nav-item-action-button fa fa-caret-${fastNavOpen ? 'down' : 'up'}`} onClick={toggleFastNav} />
    </React.Fragment>
  );
};
const MemoizedEnabledTopNavLinkItem = React.memo(EnabledTopNavLinkItem);

const DisabledTopNavLinkItem = ({ name }: { name: string }) => {
  return <span className={`nav-link disabled ${navItemText}`}>{name}</span>;
};
const MemoizedDisabledTopNavLinkItem = React.memo(DisabledTopNavLinkItem);

// While it would be nice to memoize TopNavLink, this cannot happen the way it is currently implemented.
// In order for the active TopNavLink style to properly be applied, the Route component children must be rerendered every time a route change is detected.
export const TopNavLink = (
  props: RouteLink & {
    fastNavOpen: boolean;
    onFastNavToggle: (tabName: string) => void;
  }
) => {
  const { name, link, highlightPrefix, disabled, fastNavOpen, onFastNavToggle } = props;
  const location = useLocation();
  const match = matchPath(`${highlightPrefix}/*`, location.pathname);
  return (
    <div className={`nav-item${(match && ' bg-primary active') || (fastNavOpen && ' fastNav active') || ''}`}>
      {!disabled ? (
        <MemoizedEnabledTopNavLinkItem
          name={name}
          link={link}
          fastNavOpen={fastNavOpen}
          onFastNavToggle={onFastNavToggle}
        />
      ) : (
        <MemoizedDisabledTopNavLinkItem name={name} />
      )}
    </div>
  );
};
