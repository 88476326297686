import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export interface TenantResponse {
  title: string;
  data: BasicPivotItem[];
}

interface StoreGroupChartSlice {
  viewDefn: TenantConfigViewData;
  allData: TenantResponse[];
  configLoaded: boolean;
  allDataLoaded: boolean;
  selectedItem: BasicPivotItem | null;
}

const initialState: StoreGroupChartSlice = {
  viewDefn: {} as TenantConfigViewData,
  selectedItem: null,
  allData: [],
  configLoaded: false,
  allDataLoaded: false,
};

const storeGroupChartSlice = createSlice({
  name: 'StoreGroup',
  initialState,
  reducers: {
    setSelectedItem(state, action: PayloadAction<BasicPivotItem | null>) {
      state.selectedItem = action.payload;
    },
    receiveStoreGroupTenantConfig(state, action: PayloadAction<TenantConfigViewData>) {
      state.viewDefn = action.payload;
      state.configLoaded = true;
    },
    requestAllData(state) {
      state.allDataLoaded = false;
    },
    receiveAllData(state, action: PayloadAction<TenantResponse[]>) {
      state.allData = action.payload;
      state.allDataLoaded = true;
    },
    receiveDataError(state) {
      state.allData = [];
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  setSelectedItem,
  receiveStoreGroupTenantConfig,
  requestAllData,
  receiveAllData,
  receiveDataError,
  cleanUp,
} = storeGroupChartSlice.actions;

export default storeGroupChartSlice.reducer;
