import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

export class RangePickerRenderer extends React.Component<ICellRendererParams> {
  constructor(props: ICellRendererParams) {
    super(props);
  }

  render() {
    const weekNo = this.props.value ? (this.props.value.weekNo ? this.props.value.weekNo : '') : '';
    return <span>{weekNo}</span>;
  }
}
