import { style } from 'typestyle';
import { createStyles } from '@material-ui/core';
import { positiveTrend, negativeTrend, fadedNeutral } from 'src/common-ui/theme';
import { STYLED_ACCORDION_DETAILS_HORIZONTAL_PADDING } from 'src/components/StyledAccordion/StyledAccordion.styles';
import { smallBreakpoint } from 'src/utils/Style/Theme';
import { rangePx } from 'src/utils/Style/Media';

export const macrosDividerOverrides = createStyles({
  root: {
    height: '1px',
    width: '100%',
  },
});

export const macroSummaryStyle = style({
  padding: '1rem 2rem',
  display: 'flex',
  alignItems: 'center',
  width: `calc(20% - ${STYLED_ACCORDION_DETAILS_HORIZONTAL_PADDING}rem)`,
  $nest: {
    [rangePx(smallBreakpoint)]: {
      // width: '20%',
      // ...borderize(5, 2, 10),
    },
    header: {
      textAlign: 'left',
      fontSize: '0.7rem',
      fontWeight: 600,
    },
    '.left-container': {
      flex: 2,
    },
    '.right-container': {
      flex: 2,
      color: fadedNeutral,
    },
    '.primary-metric': {
      fontWeight: 200,
      marginTop: '0.2rem',
      overflowWrap: 'break-word',
    },
    '.primary-skeleton': {
      height: '25px',
      marginRight: '35px',
    },
    '.icon-skeleton': {
      flex: 0.5,
      margin: '0 0.5rem',
    },
    '.secondary-skeleton': {
      display: 'flex',
      flex: 2,
      padding: 0,
      margin: 0,
    },
    '.variance': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.65rem',
      height: '20px',
    },
    '.metric-name': {
      flex: 1.5,
      margin: 0,
      whiteSpace: 'nowrap',
      textAlign: 'right',
    },
    '.metric-value': {
      flex: 2,
      whiteSpace: 'nowrap',
    },
    '.dir': {
      flex: 1,
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    '.up': {
      color: positiveTrend,
    },
    '.down': {
      color: negativeTrend,
    },
    '.none': {
      color: 'transparent',
    },
  },
});
