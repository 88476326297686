import { style } from 'typestyle';
import { TEAL_PRIMARY, faintBorderColor, mediumBreakpoint } from 'src/utils/Style/Theme';
import { rangePx } from 'src/utils/Style/Media';

const itemHeight = 60;
export const dialogTitle = style({
  color: 'white',
  backgroundColor: TEAL_PRIMARY,
  width: '100%',
  height: '100%',
  padding: '1rem',
  $nest: {
    '& div': {
      display: 'flex',
    },
    '.right-container': {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontSize: '1.8rem',
      cursor: 'pointer',
    },
    '.icon': {
      marginRight: '0.5rem',
      fontSize: '1.5rem',
    },
  },
});
export const dialogContent = style({
  margin: '2rem 4rem',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  $nest: {
    '> main': {
      display: 'flex',
      [rangePx(0, mediumBreakpoint)]: {
        width: 600,
      },
    },
    '*::-webkit-scrollbar': {
      display: 'none',
    },
    '.option-group': {
      height: 500,
      width: 300,
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      [rangePx(0, mediumBreakpoint)]: {
        width: '50%',
      },
      $nest: {
        '& + .option-group': {
          marginLeft: '1rem',
        },
        '> header': {
          fontSize: '1.2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '10%',
        },
        '> ul': {
          flex: 1,
          margin: 0,
          padding: 0,
          overflow: 'auto',
        },
        '&.selectable .option:not(.disabled)': {
          cursor: 'pointer',
        },
        '&:not(.selectable) .overlay': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          opacity: 0.7,
          zIndex: 1,
        },
      },
    },
    '.clear-choice': {
      position: 'absolute',
      right: '10px',
      fontSize: '1.1rem',
      top: '10px',
    },
    '.option': {
      height: itemHeight,
      marginBottom: '1rem',
      backgroundColor: faintBorderColor,
      fontWeight: 300,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      $nest: {
        '&.selected': {
          color: 'white',
          backgroundColor: TEAL_PRIMARY,
          fontWeight: 'normal',
        },
        '&.disabled': {
          color: '#ddd',
        },
      },
    },
    '> footer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '0.4rem',
      paddingTop: '2rem',
      width: '100%',
    },
  },
});
export const modal = style({
  $nest: {
    'button.apply': {
      backgroundColor: TEAL_PRIMARY,
    },
    'button.reset': {
      backgroundColor: '#ddd',
    },
    button: {
      border: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase',
      outline: 'none',
      padding: '0.4rem 1.2rem',
      color: 'white',
      fontSize: '1rem',
      $nest: {
        '> i': {
          marginRight: 5,
        },
      },
    },
    '.instructions': {
      fontSize: '0.9rem',
    },
  },
});
