import React, { useCallback, useState, useRef, useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Link,
} from '@material-ui/core';
import { GridReadyEvent } from '@ag-grid-community/core';
import { connect } from 'react-redux';
import { Report } from 'src/dao/reportClient';

import { AppState, AppThunkDispatch } from 'src/store';
import { useGoBack, useGoForward, WizardActions, WizardSection } from './BulkImport.utils';
import { commitBulkImport, uploadMutationTemplate } from './BulkImport.actions';
import { BulkImportLoadingState, BulkImportNotice } from './BulkImport.slice';
import { isNil } from 'lodash';
import Subheader from 'src/components/Subheader/Subheader.container';
import { BULKIMPORT_API } from './BulkImport.client';
import { bulkImportStyles } from './BulkImport.styles';
import { toast } from 'react-toastify';
// eslint-disable-next-line prettier/prettier
import { makeUpdateAssortmentFabSensitive } from 'src/pages/AssortmentBuild/HigherOrder/UpdateAssortmentFab';
import { getLatestAccessToken } from 'src/services/auth/platform';

import { BulkImportComponent } from 'src/services/configuration/codecs/confdefnComponents';
import { ConfDefnComponentType, maybeGetComponentProps } from 'src/services/configuration/codecs/confdefnComponents';
import { generateReportUrl } from 'src/components/Reporting/ReportCard';

type BulkImportOwnProps = Record<string, unknown>;
type BulkImportValueProps = ReturnType<typeof mapStateToProps>;
interface BulkImportDispatchProps extends ReturnType<typeof mapDispatchToProps> { }

export type BulkImportProps = BulkImportValueProps & BulkImportDispatchProps & BulkImportOwnProps;

const mapStateToProps = (state: AppState) => {
  const { bulkImportLoadingState } = state.bulkImport;
  const cprops = maybeGetComponentProps<BulkImportComponent>(state, ConfDefnComponentType.bulkImport);
  if (!cprops) {
    return {
      bulkImportLoadingState,
      importId: undefined,
      appName: state.perspective.selected?.appType,
      title: '',
      stepper1Label: '',
      stepper2Label: '',
      templateType: '',
    };
  }
  return {
    bulkImportLoadingState,
    importId: cprops.importId,
    appName: state.perspective.selected?.appType,
    stepper1Label: cprops.stepper1Label,
    stepper2Label: cprops.stepper2Label,
    title: cprops?.title,
    templateType: cprops.templateType,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedUploadMutationTemplate: (payload: { importId: string; formData: FormData }) =>
      dispatch(uploadMutationTemplate(payload)),
    dispatchedCommit: (importId: string, txId: string) => dispatch(commitBulkImport({ importId, runId: txId })),
  };
};

const BulkImport = (props: BulkImportProps) => {
  const {
    dispatchedUploadMutationTemplate,
    bulkImportLoadingState,
    dispatchedCommit,
    importId,
    stepper1Label,
    stepper2Label,
    appName,
    title,
    templateType,
  } = props;
  const uploadButtonRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<BulkImportNotice | null>(null);
  const [expanded, setExpanded] = useState(0);
  const [uploadFileName, setUploadFileName] = useState<string | null>(null);

  const downloadTemplateUrl = useMemo(() => {
    if (isNil(importId) || isNil(appName)) return;
    const bearerToken = getLatestAccessToken()!;
    switch (templateType) {
      case 'import':
        return `asst/${BULKIMPORT_API}/${importId}/template?token=${encodeURIComponent(
          bearerToken
        )}&appName=${appName}`;
      case 'report':
        const dummyReport = {
          modelId: importId,
          templateId: importId,
          perspective: appName,
        };
        return window.location.origin + generateReportUrl(dummyReport);
      default:
        return `asst/${BULKIMPORT_API}/${importId}/template?token=${encodeURIComponent(
          bearerToken
        )}&appName=${appName}`;
    }
  }, [appName, importId, templateType]);

  const downloadResultUrl = useMemo(() => {
    if (isNil(importId) || isNil(status)) return;
    const bearerToken = getLatestAccessToken()!;
    return `asst/${BULKIMPORT_API}/${importId}/result/${status.transactionId}?token=${encodeURIComponent(
      bearerToken
    )}&appName=${appName}`;
  }, [appName, importId, status]);

  /* Stepper methods */
  const goForward = useGoForward(setExpanded, 2);
  const goBack = useGoBack(setExpanded);

  /* Dropdown methods*/

  const handleChangeFileUpload = useCallback(
    async (evt: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(null); // reset notices on upload
      const fileList = evt.target.files;
      if (!fileList || !importId) return;
      const excelFile = fileList[0];
      setUploadFileName(excelFile.name);
      const formData = new FormData();

      formData.append('bulkimport-upload', excelFile);
      const mutationUpload = await dispatchedUploadMutationTemplate({ importId, formData });
      if (uploadMutationTemplate.fulfilled.match(mutationUpload)) {
        setStatus(mutationUpload.payload);
        setExpanded(2);
      }
      if (uploadMutationTemplate.rejected.match(mutationUpload)) {
        toast.error('An error occured fetching your upload feedback', {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    },
    [dispatchedUploadMutationTemplate, importId]
  );

  const handleOnClickClearFiles = useCallback(() => {
    // manually clear the file list when the button is clicked
    // so that if the user selects opens the input twice and selects the same file,
    // it will still trigger the onChange
    if (uploadButtonRef.current) {
      uploadButtonRef.current.value = '';
    }
  }, []);

  const handleClickDownloadTemplate = useCallback(() => {
    // the report needs to use window.open() so that the filename goes in correctly
    if (templateType === 'report') {
      // thes are weird and have to window.open
      window.open(downloadTemplateUrl);
    }

    handleOnClickClearFiles();
  }, [downloadTemplateUrl, handleOnClickClearFiles, templateType]);

  const handleOnClickCommit = useCallback(() => {
    if (!status || !importId) {
      return;
    }
    dispatchedCommit(importId, status.transactionId);
  }, [dispatchedCommit, importId, status]);

  // TODO: fix this later
  /* Grid methods */
  // const gridApiRef = useCallback(
  //   (grid) => {
  //     if (grid && grid.api && notices) {
  //       try {
  //         grid.api.setRowData(
  //           'problems' in notices
  //             ? notices.problems.map((problem) => {
  //               return {
  //                 id: problem.rowId,
  //                 message: problem.message,
  //               };
  //             })
  //             : undefined // Dont put data in if there are no errors
  //         );
  //       } catch (error) {
  //         noop();
  //       }
  //       grid.api.hideOverlay();
  //     }
  //   },
  //   [notices]
  // );
  const gridRef = useRef(null);
  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (status && status.validationMsg) {
        event.api.setRowData(status.result.messages);
      }
    },
    [status]
  );

  return (
    <div className={bulkImportStyles}>
      <section className="header-content">
        <Subheader title={title} />
      </section>
      <Stepper activeStep={expanded} className="bulk-import-stepper" orientation="vertical">
        <Step expanded={expanded === WizardSection.select}>
          <StepLabel className={'step-header'}>
            <Typography variant={'h6'}>Download a Template</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <React.Fragment>
              <Typography variant="h6" component="h6" className="bulkimport-label">
                {stepper1Label}
              </Typography>
              <Grid container alignItems="center">
                {templateType === 'report' ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="bulkimport-download"
                    disabled={bulkImportLoadingState !== BulkImportLoadingState.idle}
                    startIcon={<i className={'fa fa-cloud-download'} />}
                    onClick={handleClickDownloadTemplate}
                  >
                    Download
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="bulkimport-download"
                    disabled={bulkImportLoadingState !== BulkImportLoadingState.idle}
                    startIcon={<i className={'fa fa-cloud-download'} />}
                    href={templateType === 'import' && downloadTemplateUrl ? downloadTemplateUrl : ' '}
                    download={templateType === 'import' && downloadTemplateUrl ? downloadTemplateUrl : ' '}
                    onClick={handleClickDownloadTemplate}
                  >
                    Download
                  </Button>
                )}
              </Grid>
            </React.Fragment>
            <WizardActions section={WizardSection.select} onContinue={goForward} onGoBack={goBack} disabled={false} />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.upload}>
          <StepLabel className={'step-header'}>
            <Typography variant={'h6'}>Upload a completed Template</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <React.Fragment>
              <Grid container alignItems="center">
                <input
                  ref={uploadButtonRef}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="bulkimport-upload-btn"
                  multiple={false}
                  disabled={bulkImportLoadingState !== BulkImportLoadingState.idle || !importId}
                  onChange={handleChangeFileUpload}
                  onClick={handleOnClickClearFiles}
                  style={{ display: 'none' }}
                  type="file"
                />
                <Typography variant="h6" component="h6" className="bulkimport-label">
                  {stepper2Label}
                </Typography>
              </Grid>
              <div>
                <label htmlFor="bulkimport-upload-btn">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="bulkimport-upload"
                    disabled={bulkImportLoadingState !== BulkImportLoadingState.idle}
                    component="span"
                    startIcon={<i className={'fa fa-cloud-upload'} />}
                  >
                    Upload
                  </Button>
                </label>
                {uploadFileName && (
                  <span className="bulkimport-chosen-file" onClick={() => uploadButtonRef.current?.click()}>
                    <strong>Chosen file: </strong>
                    <span className="bulkimport-chosen-name">{uploadFileName}</span>
                  </span>
                )}
              </div>
            </React.Fragment>
            <WizardActions
              section={WizardSection.upload}
              onContinue={goForward}
              onGoBack={goBack}
              disabled={isNil(status)}
            />
          </StepContent>
        </Step>
        <Step expanded={expanded === WizardSection.notices}>
          <StepLabel className={'step-header'}>
            <Typography variant={'h6'}>Review Upload</Typography>
          </StepLabel>
          <StepContent classes={{ root: 'details' }}>
            <React.Fragment>
              <Box component="section" display={'flex'} className="bulkimport-results">
                <Typography variant="h6" className="typography">
                  <Box component="section" display={'flex'}>
                    Uploaded: <div>{status?.statistics?.uploaded}</div>
                  </Box>
                </Typography>
                <Typography variant="h6" className="typography">
                  <Box component="section" display={'flex'}>
                    Valid: <div className="validated">{status?.statistics?.validated}</div>
                  </Box>
                </Typography>
                <Typography variant="h6" className="typography">
                  <Box component="section" display={'flex'}>
                    Rejected:{' '}
                    <div className={status?.statistics?.rejected && status?.statistics?.rejected > 0 ? 'rejected' : ''}>
                      {status?.statistics?.rejected}
                    </div>
                  </Box>
                </Typography>
                <Link
                  href={downloadResultUrl ? downloadResultUrl : ''}
                  target="_blank"
                  className={'bulkimport-results-download'}
                >
                  Download Results
                </Link>
              </Box>
              <section className="grid-container ag-theme-material" style={{ height: '350px' }}>
                <AgGridReact
                  ref={gridRef}
                  rowHeight={40}
                  onGridReady={handleGridReady}
                  columnDefs={status?.result.columns.map((col) => ({
                    headerName: col,
                    field: col,
                    resizable: true,
                  }))}
                  defaultColDef={{
                    suppressMovable: true,
                    suppressMenu: true,
                  }}
                />
              </section>
            </React.Fragment>
            <div className="bulkimport-commit-btn">
              <WizardActions
                section={WizardSection.notices}
                onContinue={goForward}
                onGoBack={goBack}
                disabled={false}
              />
              <Button
                variant="contained"
                color="secondary"
                className="bulkimport-commit"
                disabled={bulkImportLoadingState !== BulkImportLoadingState.idle}
                component="button"
                startIcon={<i className={'fas fa-save'} />}
                onClick={handleOnClickCommit}
              >
                Commit
              </Button>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

export default makeUpdateAssortmentFabSensitive(
  connect<BulkImportValueProps, BulkImportDispatchProps, BulkImportOwnProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(BulkImport)
);
