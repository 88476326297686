import { style } from 'typestyle';

const styles = style({
  $debugName: 'exceptions-summary',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const container = style({
  $debugName: 'exceptions-list',
  height: '100%',
  marginTop: '0.25rem',
  marginBottom: '0.25rem',
  $nest: {
    ['.exception-group-header']: {
      justifyContent: 'flex-start',
    },
    ['.exception-group']: {
      fontSize: 15,
      fontWeight: 700,
    },
    ['.exception-child']: {
      paddingLeft: '3em',
      fontSize: 13,
    },
    ['.worklist']: {
      fontSize: 13,
      marginLeft: 55,
    },
    ['.itemcount']: {
      fontSize: 13,
      marginLeft: 55,
    },
  },
});
const gridClass = style({
  height: '100%',
  borderRight: '1px solid #ddd',
  borderBottom: '1px solid #ddd',
  borderLeft: '1px solid #ddd',
});

export { gridClass, container };
export default styles;
