import { ServerScopeResponse, GridAsyncState } from 'src/state/scope/Scope.types';
import { SettingsByKey } from 'src/services/Settings';
import { WorkingSetReport } from 'src/state/workingSets/workingSets.slice';
import { ConfigGroup } from 'src/components/Mfp/PivotConfigurator/PivotConfigurator.types';
import PivotConfig from 'src/pivot/PivotConfig';
import { ViewParams } from 'src/state/ViewConfig/ViewConfig.types';
import { mapDispatchToProps } from './split-view.container';



export enum SplitViewGridType {
  top = 'TOP-GRID',
  bottom = 'BOTTOM-GRID',
}

export type GridProp = 'topGrid' | 'bottomGrid';

export interface SplitViewGridProps {
  gridAsyncState: GridAsyncState;
  pivotName: string;
}

export interface SplitViewValueProps {
  // global props to both grids
  // configurator modal can make viewParams slightly unique per grid,
  // so going to initially read in from props and give each grid own copy
  viewParams?: {
    title?: string;
    topGrid: ViewParams;
    bottomGrid: ViewParams;
  };
  scopeId: string | undefined;
  mainConfig: ServerScopeResponse | undefined;
  initialized: boolean;
  settings?: SettingsByKey;
  groupId: string;
  isFetchingScope: boolean;
  scopeReady: boolean;
  reports: WorkingSetReport[];
  hasEditableRevision: boolean;
  isLockToggled: boolean; // top right lock toggle, not grid cell lock icon in TopBar
  // grid specific
  topGrid: SplitViewGridProps;
  bottomGrid: SplitViewGridProps;
}

export interface SplitViewDispatchProps extends ReturnType<typeof mapDispatchToProps> {}
export interface SplitViewProps extends SplitViewValueProps, SplitViewDispatchProps {}

export interface SplitViewGridState {
  // global to all grids
  allAvailableListing: ConfigGroup[];
  availableGroup: ConfigGroup[];
  contextItems: React.ReactNode;
  pivotConfig?: PivotConfig;
  xlsDownloadStatus?: WorkingSetReport;
  rowGroup: ConfigGroup[];
  colGroup: ConfigGroup[];
  viewParams?: ViewParams;
}
export interface SplitViewState {
  isSaving: boolean;
  isConfigModalOpen: boolean;
  configuringGridType: SplitViewGridType | null; // tracks which grid is being configured
  // grid specific
  topGrid: SplitViewGridState;
  bottomGrid: SplitViewGridState;
}
