import React from 'react';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import {
  GridApi,
  GridReadyEvent,
  IRowNode,
  CellClickedEvent,
  CellEditingStoppedEvent,
  BodyScrollEvent,
  CellEditingStartedEvent,
  SideBarDef,
  ColGroupDef,
  ProcessCellForExportParams,
  ColDef,
  NewColumnsLoadedEvent,
  DisplayedColumnsChangedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  ColumnApi,
  ColumnState,
  ValueGetterParams,
  ExcelStyle,
} from '@ag-grid-community/core';
import '@ag-grid-enterprise/core';

import { ModuleRegistry } from '@ag-grid-community/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';

import { LicenseManager } from '@ag-grid-enterprise/core';

import { KeyVal } from '../../common/object';
import { multiHeaderDecorate } from './NestedHeader';
import styles from './DataGrid.styles';
import { isEmpty, isEqual, isNil, isNumber } from 'lodash';
import {
  getCellFromRowCol,
  getMainMenuItems,
  handleNavigateToNextCell,
  onBodyScroll,
  onCellClicked,
  onCellEditingStarted,
  onCellEditingStopped,
  onCellKeyDown,
  onColumnResized,
  onGridReady,
  onCsvExport,
  onExcelExport,
  onNewColumnsLoaded,
  onDisplayedColumnsChanged,
  onFilterChanged,
  onSortChanged,
  onRowDataUpdated,
} from './DataGrid.events';
import { classes } from 'typestyle';
import { FilterModelType } from 'src/components/Sidenav/SideNav.slice';
import GridLoadingOverlay from 'src/common-ui/components/GridLoadingOverlay/GridLoadingOverlay';
import { Renderer } from './Renderer';
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  MasterDetailModule,
  MenuModule,
  RangeSelectionModule,
  ClipboardModule,
  RowGroupingModule,
  SetFilterModule,
  ColumnsToolPanelModule,
  ViewportRowModelModule,
]);

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-044159 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( S5 Stratos )_is_granted_a_( Single Application )_Developer_License_for_the_application_( S5Stratos )_only_for_( 5 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( S5Stratos )_need_to_be_licensed___( S5Stratos )_has_been_granted_a_Deployment_License_Add-on_for_( 5 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 25 June 2024 )____[v2]_MTcxOTI3MDAwMDAwMA==143c7acbabc441693cfa6492d8b5ccd4'
);

const GRID_HEADER_HEIGHT = 30;

export type GetDataPath = ((x: any) => any) | undefined;

export interface ScrollTo {
  eventId: number;
  where: KeyVal<string, any>;
}

export interface Gridable {
  id: string;
  [key: string]: any;
}

export interface RendererExcelFormat {
  dataType?: string;
  numberFormat: string;
  id: string;
}

export interface RendererExcelFormatObject {
  [s: string]: RendererExcelFormat;
}

export interface DefaultShownValues {
  [dataIndex: string]: string[];
}

export interface ExportOptions {
  customHeader?: string;
  excelRendererObj?: RendererExcelFormatObject;
  fileName?: string;
  processCellOverride?: (params: ProcessCellForExportParams) => string | undefined;
  showGroupTitlesExcel?: boolean;
}

export interface MenuItemDefWithId extends MenuItemDef {
  /**
   * AgGrid default context menu items only have a string identifer (eg, 'expandAll'),
   * but if we want to compare with a MenuItem (which only has .name like 'Expand All'),
   * we need something that matches the existing string identifier in order to override the,
   * default dataGrid context menu items
   */
  _id: string;
}

export interface DataGridProps {
  data: Gridable[] | any[];
  columnDefs: (ColDef | ColGroupDef)[];
  treeColumnDefinition?: ColDef | undefined;
  className?: string;
  rowHeight?: number;
  autoSizeOnReady?: boolean;
  isPrintMode?: boolean;
  frameworkComponents?: any;
  nonFrameworkComponents?: any;
  loaded: boolean;
  scrollTo?: ScrollTo;
  rowClassRules?: any;
  defaultShownValues?: DefaultShownValues;
  singleClickEdit?: boolean;
  sideBar?: SideBarDef | boolean | string;
  exportOptions?: ExportOptions;
  extraAgGridProps?: AgGridReactProps;
  gridFilterModel?: FilterModelType;
  gridSortModel?: ColumnState[];
  headerHeight?: number;
  onCellClicked?(event: CellClickedEvent): void;
  onGridReady?(event: GridReadyEvent<any>): void;
  onNewColumnsLoaded?(event: NewColumnsLoadedEvent): void;
  onDisplayedColumnsChanged?(event: DisplayedColumnsChangedEvent): void;
  onCellEditingStopped?(event: CellEditingStoppedEvent): void;
  onCellEditingStarted?(event: CellEditingStartedEvent): void;
  onBodyScroll?(event: BodyScrollEvent): void;
  getAlternateContextMenu?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[];
  storeGridFilterModel?: (model: FilterModelType) => void;
  storeGridSortModel?: (model: ColumnState[]) => void;
  /**
   * Used to overrride the default behavior of some contextMenu items
   * This can be used, for instance, to override the expander to only expand one level instead of all of them
   */
  overrideDefaultContextMenuActions?: boolean;
}

export interface NoRenderState {
  gridApi?: GridApi;
  columnApi?: ColumnApi;
  lastScrolledTo?: number;
  defaultShownValues?: DefaultShownValues;
  // used for rendering an alternate context menu on left click
  leftOrRightClick: 'left' | 'right';
}

export default class DataGrid extends React.Component<DataGridProps> {
  public noRenderState: NoRenderState;
  public getCellFromRowCol = getCellFromRowCol.bind(this);

  private onGridReady = onGridReady.bind(this);
  private onCsvExport = onCsvExport.bind(this);
  private onExcelExport = onExcelExport.bind(this);
  private getMainMenuItems = getMainMenuItems.bind(this);
  private onCellClicked = onCellClicked.bind(this);
  private onCellEditingStopped = onCellEditingStopped.bind(this);
  private onCellEditingStarted = onCellEditingStarted.bind(this);
  private onBodyScroll = onBodyScroll.bind(this);
  private onColumnResized = onColumnResized.bind(this);
  private onCellKeyDown = onCellKeyDown.bind(this);
  private handleNavigateToNextCell = handleNavigateToNextCell.bind(this);
  private onNewColumnsLoaded = onNewColumnsLoaded.bind(this);
  private onDisplayedColumnsChanged = onDisplayedColumnsChanged.bind(this);
  private onFilterChanged = onFilterChanged.bind(this);
  private onSortChanged = onSortChanged.bind(this);
  private onRowDataUpdated = onRowDataUpdated.bind(this);

  constructor(props: DataGridProps) {
    super(props);

    this.noRenderState = {
      defaultShownValues: props.defaultShownValues,
      leftOrRightClick: 'right',
    };
  }

  componentDidUpdate(prevProps: DataGridProps) {
    if (this.noRenderState.gridApi) {
      if (this.props.isPrintMode) {
        this.noRenderState.gridApi.setDomLayout('print');
      } else if (prevProps.isPrintMode && !this.props.isPrintMode) {
        this.noRenderState.gridApi.setDomLayout('normal');
      }

      if (!isEqual(prevProps.data, this.props.data)) {
        // FIXME: Replace with gridPreDestroyed
        // @ts-ignore
        if (this.noRenderState.gridApi.destroyCalled) {
          setTimeout(() => this.noRenderState.gridApi?.updateGridOptions({ rowData: this.props.data }), 32);
        } else {
          this.noRenderState.gridApi?.updateGridOptions({ rowData: this.props.data });
        }
      }

      // apply correct overlay state - this first condition errors without this check becuase the grid is torn down
      if (!this.props.loaded && isEqual(prevProps.treeColumnDefinition, this.props.treeColumnDefinition)) {
        // we need to check the private destroyCalled here, because there is a aggrid bug that will
        // crash the view when the loading overlay gets mounted while the grid is being destoryed
        // later versions of aggrid add a `gridPreDestroyed` event that can be used to more easily detect
        // grid destruction and reset the `gridApi` instance
        // @ts-ignore
        if (!this.noRenderState.gridApi.destroyCalled) this.noRenderState.gridApi?.showLoadingOverlay();
      } else if (this.props.loaded && isEmpty(this.props.data)) {
        this.noRenderState.gridApi?.showNoRowsOverlay();
      } else {
        this.noRenderState.gridApi?.hideOverlay();
      }
    }
  }
  injectColumnDef = (colDef: any) => {
    const { exportOptions } = this.props;
    if (colDef && exportOptions && exportOptions.excelRendererObj) {
      const cellClassFunction = (params: any, renderer: string) => {
        // INT-4102
        // If we are in a normal grid structure where we are setting renderer on columns
        // allow the cell class through.
        if (params.colDef.renderer === renderer || params.colDef.cellRenderer === renderer) {
          return true;
          // If we are specifically attaching renderers in data (f/e in transposed screens),
          // ensure we aren't looking at the group column before passing cell rules.
        } else if (params.column.getId() !== 'ag-Grid-AutoColumn') {
          return (
            (params.node.data && params.node.data.renderer === renderer) ||
            (params.node.data && params.node.data.formatter === renderer)
          );
        }
        return false;
      };
      // @ts-ignore
      const excelRendererArr: ExcelStyle[] = Object.keys(exportOptions.excelRendererObj).map((key) => {
        if (exportOptions.excelRendererObj) {
          return {
            ...exportOptions.excelRendererObj[key],
            numberFormat: { format: exportOptions.excelRendererObj[key].numberFormat.replace(/"/g, '&quot;') },
          };
        } else {
          //this should never get hit
          return {
            dataType: '',
            numberFormat: '',
          };
        }
      });
      const injectableClassRules = excelRendererArr.reduce((accumeObj, style) => {
        const styleId = style.id;
        if (styleId) {
          accumeObj[styleId] = (params: any) => cellClassFunction(params, styleId);
        }
        return accumeObj;
      }, {});

      const filterConfig = Renderer.columnFilterType(colDef.filterType);
      return {
        ...colDef,
        cellClassRules: {
          ...colDef.cellClassRules,
          ...injectableClassRules,
        },
        ...filterConfig,
        filterValueGetter: function(params: ValueGetterParams) {
          const field = colDef.field || params.colDef.field;
          if (field) {
            const value = params.data[field];
            if (isNumber(value)) {
              return Math.round(value);
            } else {
              return value;
            }
          }
        },
      };
    }
    return colDef;
  };

  decorateColDefsForExcel = (colDefs: (ColDef | ColGroupDef)[]) => {
    if (colDefs && this.props.exportOptions) {
      return colDefs.map((colDef: any) => {
        const returnObj = this.injectColumnDef(colDef);
        if (colDef.children && colDef.children.length > 0) {
          returnObj.children = colDef.children.map((childDef: any) => this.injectColumnDef(childDef));
        }
        return returnObj;
      });
    }

    return colDefs;
  };

  showWorklist = (event: CellClickedEvent) => {
    // there appears to be no public interface that can accomplish this, so we're using the private interface instead
    // we take the event details from the left click, and manually fire it at the right click event
    // Warning: this could break in future releases of ag-grid
    if (!this.noRenderState.gridApi) {
      return; // no clicky too quicky
    }
    // @ts-ignore
    this.noRenderState.gridApi.contextMenuFactory.showMenu(event.node, event.column, event.value, event.event);
  };

  getRowHeight = (): number | undefined => {
    return this.props.rowHeight || this.props.extraAgGridProps?.rowHeight;
  };

  public getContextMenuItems = (params: GetContextMenuItemsParams) => {
    // We 'jiggle' the context menu when we detect a left click from onCellClicked
    // in order to re-use ag-grid's context menu functionality for other purposes
    // I attempted to use onCellContextMenu and some other approaches,
    // but that fires after getContextMenuItems, and everything else was more work

    if (this.props.getAlternateContextMenu && this.noRenderState.leftOrRightClick === 'left') {
      this.noRenderState.leftOrRightClick = 'right'; // reset back to default, then complete the event
      return this.props.getAlternateContextMenu(params);
    }
    // this is the normal context menu, invoked from right click
    // TODO: fix the icons here
    const collapseAll = () => {
      if (this.noRenderState.gridApi) {
        this.noRenderState.gridApi.forEachNode((node) => {
          node.expanded = false;
        });
        this.noRenderState.gridApi.onGroupExpandedOrCollapsed();
      }
    };
    const expandAll = () => {
      if (this.noRenderState.gridApi) {
        this.noRenderState.gridApi.forEachNode((node) => {
          node.expanded = true;
        });
        this.noRenderState.gridApi.onGroupExpandedOrCollapsed();
      }
    };
    let defaultContextMenu: (string | MenuItemDef)[] = [
      {
        name: 'Expand All',
        icon: '<i class="far fa-chevron-double-down" aria-hidden="true"></i>',
        action: () => expandAll(),
      },
      {
        name: 'Collapse All',
        icon: '<i class="far fa-chevron-double-up" aria-hidden="true"></i>',
        action: () => collapseAll(),
      },
      'copy',
      {
        name: 'Reset Columns',
        icon: '<i class="fa fa-repeat" aria-hidden="true"></i>',
        action: () => this.noRenderState.columnApi && this.noRenderState.columnApi.resetColumnState(),
      },
      {
        name: 'CSV Export',
        action: this.onCsvExport,
        icon: '<i class="fa fa-file" aria-hidden="true"></i>',
      },
      {
        name: 'Excel Export',
        action: this.onExcelExport,
        icon: '<i class="fa fa-file-excel" aria-hidden="true"></i>',
      },
    ];

    if (this.props.overrideDefaultContextMenuActions) {
      defaultContextMenu = defaultContextMenu.map((mItem) => {
        if (this.props.extraAgGridProps && this.props.extraAgGridProps.getContextMenuItems) {
          const maybeOverride = this.props.extraAgGridProps.getContextMenuItems(params).find((overrideItem) => {
            const ovItem = overrideItem as MenuItemDefWithId;
            if (
              (typeof overrideItem !== 'string' && typeof mItem === 'string' && mItem === ovItem._id) ||
              (typeof overrideItem !== 'string' && typeof mItem !== 'string' && mItem.name === ovItem._id)
            ) {
              return overrideItem;
            }
            return;
          });
          return maybeOverride ? maybeOverride : mItem;
        }
        return mItem;
      });
    }

    return defaultContextMenu;
  };

  render() {
    const {
      columnDefs,
      className,
      frameworkComponents,
      rowHeight,
      treeColumnDefinition,
      scrollTo,
      rowClassRules,
      defaultShownValues,
      nonFrameworkComponents,
      sideBar,
      extraAgGridProps,
      exportOptions,
    } = this.props;

    if (defaultShownValues) {
      this.noRenderState.defaultShownValues = defaultShownValues;
    }

    const { gridApi, lastScrolledTo, columnApi } = this.noRenderState;

    if (scrollTo !== undefined && gridApi && columnApi && scrollTo.eventId !== lastScrolledTo) {
      const setExpandedAll = (node: IRowNode) => {
        if (node.parent) {
          node.setExpanded(true);
          setExpandedAll(node.parent);
        }
      };
      let foundIndex = -1;
      gridApi.ensureNodeVisible((node: IRowNode) => {
        const firstCol = columnApi.getAllDisplayedColumns()[0];
        if (node.data && node.data[scrollTo.where.key] === scrollTo.where.value && isNumber(node.rowIndex)) {
          gridApi.clearRangeSelection();
          gridApi.setFocusedCell(node.rowIndex, firstCol);
          gridApi.ensureIndexVisible(node.rowIndex, 'middle');
          return true;
        } else {
          if (node && node.allChildrenCount && node.allChildrenCount > 0) {
            foundIndex = -1;
            node.allLeafChildren.forEach((nod) => {
              if (nod.data && nod.data[scrollTo.where.key] === scrollTo.where.value && isNumber(nod.rowIndex)) {
                setExpandedAll(nod);

                gridApi.setFocusedCell(nod.rowIndex, firstCol);
                foundIndex = nod.rowIndex;
              }
            });
            // Don't select more than one item
            if (foundIndex > -1) return true;
          }
        }
        return false;
      });
      // If a node that is a child of multiple parents is selected, use this to ensure scrolling works
      if (foundIndex > -1) {
        gridApi.ensureIndexVisible(foundIndex, 'middle');
      }
      this.noRenderState.lastScrolledTo = scrollTo.eventId;
    }
    let colDefs = columnDefs;
    const isMultiHeader = columnDefs.filter(
      (colDef: ColGroupDef | ColDef) => 'children' in colDef && colDef.children.length
    ).length;

    if (isMultiHeader) {
      colDefs = multiHeaderDecorate(columnDefs as any);
    }

    const uniqueColors = new Map();
    this.props.data.forEach((d) => {
      uniqueColors.set(`compositeColor${d.composite_band}`, {
        id: `compositeColor${d.composite_band}`,
        font: { color: d.composite_band_color },
        interior: {
          color: d.composite_band_color,
          pattern: 'Solid',
        },
      });
    });
    const excelStyles: ExcelStyle[] = Array.from(uniqueColors.values());

    const finalColDefs = this.decorateColDefsForExcel(colDefs);
    const baseGridOptions: AgGridReactProps = {
      //#region Default AgGrid Props
      sideBar: sideBar,
      columnDefs: finalColDefs,
      excludeChildrenWhenTreeDataFiltering: true,
      rowHeight: rowHeight,
      headerHeight: !isNil(extraAgGridProps?.headerHeight) ? extraAgGridProps?.headerHeight : GRID_HEADER_HEIGHT,
      animateRows: false,
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
      },
      suppressPropertyNamesCheck: true,
      singleClickEdit: true,
      components: { ...frameworkComponents, ...nonFrameworkComponents },
      rowBuffer: 0,
      icons: {
        sortAscending: '<i class="fas fa-lg fa-sort-down"></i>',
        sortDescending: '<i class="fas fa-lg fa-sort-up"></i>',
        filter: '<i class="fas fa-filter"></i>',
        clipboardCopy: '<i class="fa fa-clone" aria-hidden="true"></i>',
        resetColumns: '<i class="fa fa-repeat" aria-hidden="true"></i>',
      },
      suppressCopyRowsToClipboard: true,
      suppressScrollWhenPopupsAreOpen: true,
      suppressRowClickSelection: true,
      enableRangeSelection: true,
      suppressMultiRangeSelection: true,
      enterNavigatesVertically: false,
      //#endregion
      //#region Events
      onGridReady: this.onGridReady,
      onRowDataUpdated: this.onRowDataUpdated,
      // FIXME: this is not being called, inadvertently calling extraAgGridProps.onDisplayedColumnsChanged only
      onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
      onNewColumnsLoaded: this.onNewColumnsLoaded,
      getMainMenuItems: this.getMainMenuItems,
      getContextMenuItems: this.getContextMenuItems,
      onCellClicked: this.onCellClicked,
      onCellEditingStopped: this.onCellEditingStopped,
      onCellEditingStarted: this.onCellEditingStarted,
      onBodyScroll: this.onBodyScroll,
      onColumnResized: this.onColumnResized,
      onCellKeyDown: this.onCellKeyDown,
      navigateToNextCell: this.handleNavigateToNextCell,
      onFilterChanged: this.onFilterChanged,
      onSortChanged: this.onSortChanged,
      getRowHeight: this.getRowHeight,
      //#endregion
      // everything else that gets dumped in
      ...extraAgGridProps,
      loadingOverlayComponent: GridLoadingOverlay,
      stopEditingWhenCellsLoseFocus: true,
      onGridPreDestroyed: (event) => {
        // guarantee grid api references are not set before current grid is destroyed
        this.noRenderState.gridApi = undefined;
        this.noRenderState.columnApi = undefined;

        if (!isNil(this.props.extraAgGridProps?.onGridPreDestroyed)) {
          // allow parent to take own actions if function provided
          this.props.extraAgGridProps?.onGridPreDestroyed(event);
        }
      },
    };

    if (rowClassRules) {
      baseGridOptions.rowClassRules = rowClassRules;
    }

    if (baseGridOptions.defaultColDef) {
      baseGridOptions.defaultColDef = this.injectColumnDef(baseGridOptions.defaultColDef);
    }
    if (baseGridOptions.autoGroupColumnDef) {
      baseGridOptions.autoGroupColumnDef = this.injectColumnDef(baseGridOptions.autoGroupColumnDef);
    }
    if (extraAgGridProps && extraAgGridProps.getContextMenuItems && this.props.overrideDefaultContextMenuActions) {
      // this is here if you want to override some of the context menu items, but not all of them
      // such as changing expand behavior, but keeping the xls export of DataGrid
      baseGridOptions.getContextMenuItems = this.getContextMenuItems;
    }

    if (exportOptions) {
      if (exportOptions.excelRendererObj) {
        // @ts-ignore
        const excelRendererArr: ExcelStyle[] = Object.keys(exportOptions.excelRendererObj).map((key) => {
          if (exportOptions.excelRendererObj) {
            return {
              ...exportOptions.excelRendererObj[key],
              numberFormat: { format: exportOptions.excelRendererObj[key].numberFormat },
            };
          } else {
          }
        });
        baseGridOptions.excelStyles = excelRendererArr.concat(excelStyles);
      }
    }

    const treeGrid = !isNil(treeColumnDefinition) ? (
      <AgGridReact
        excelStyles={baseGridOptions.excelStyles}
        gridOptions={{
          autoGroupColumnDef: treeColumnDefinition,
          groupDefaultExpanded: -1,
          treeData: true,
          getDataPath: (item: any) => treeColumnDefinition.field && item[treeColumnDefinition.field],
          ...baseGridOptions,
        }}
      />
    ) : (
      undefined
    );

    const flatGrid = isNil(treeColumnDefinition) ? (
      <AgGridReact gridOptions={baseGridOptions} excelStyles={baseGridOptions.excelStyles} />
    ) : (
      undefined
    );

    return (
      <div
        className={classes(
          `ag-theme-material data-grid ${styles.dataGridStyles}`,
          isMultiHeader ? ' double-header' : '',
          className ? ` ${className}` : ''
        )}
      >
        {treeGrid}
        {flatGrid}
      </div>
    );
  }
}
