/* eslint-disable no-console */
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { z } from 'zod';
const SEVERITY_TRACE = 'TRACE';
const SEVERITY_INFO = 'INFO';
const SEVERITY_DEBUG = 'DEBUG';
const SEVERITY_WARN = 'WARN';
const SEVERITY_ERROR = 'ERROR';

const LoggingSeverities = z.union([
  z.literal(SEVERITY_TRACE),
  z.literal(SEVERITY_INFO),
  z.literal(SEVERITY_DEBUG),
  z.literal(SEVERITY_WARN),
  z.literal(SEVERITY_ERROR)
]);
type LoggingSeverities = z.infer<typeof LoggingSeverities>;


const zLoggingPayload = z.object({
  severity: LoggingSeverities,
  message: z.string(),
  payload: z.string().optional(),
  userId: z.string().optional(),
  sessionId: z.string().optional()
});
type LoggingPayload = z.infer<typeof zLoggingPayload>;

const isAxiosError = (error: object): error is AxiosError => {
  return 'isAxiosError' in error;
};

class LoggingService {
  protected client: AxiosInstance;
  // TODO: pull the userid from the request and pass it in as a constructor param
  // to be send to the server

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  /**
 * Static function used to narrow an unknown error and attempt to output the most information possible from it
 * @constructor
 * @param {unknown} error - The error
 */
  static errorToLoggingPayload(error?: unknown): string {
    const noErrorInformationMessage = 'No additional error information';
    const unknownErrorInformationMessage = 'Unknown Error Occured';

    if (error === undefined || error === null) {
      return noErrorInformationMessage;
    }
    const errorObjectType = typeof error;
    // "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"
    switch (typeof error) {
      case 'function':
      case 'undefined':
        return noErrorInformationMessage;
      case 'bigint':
      case 'number':
      case 'boolean':
      case 'symbol':
        return error.toString();
      case 'string':
        return error;
      case 'object':
        const errorObj = error!;
        if (errorObj instanceof Error) {
          return JSON.stringify(errorObj);
        }
        if (isAxiosError(errorObj)) {
          const serverResponse = errorObj.response ? LoggingService.errorToLoggingPayload(errorObj.response) : null;
          const serverCode = errorObj.code ? errorObj.code : 'No Code Returned';
          return serverResponse ? `HTTP Error: ${serverCode} Response: ${serverResponse}` : `HTTP Error: ${serverCode}`;
        }
        if (error instanceof z.ZodError) {
          return error.flatten().toString();
        }
        return unknownErrorInformationMessage;
      default:
        return unknownErrorInformationMessage;
    }
  }

  public trace(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_TRACE,
      message: message,
      payload: errorInfo
    });
  }
  public info(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_INFO,
      message: message,
      payload: errorInfo
    });
  }
  public debug(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_DEBUG,
      message: message,
      payload: errorInfo
    });
  }
  public warn(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_WARN,
      message: message,
      payload: errorInfo
    });
  }
  public error(message: string, error?: unknown) {
    const errorInfo = LoggingService.errorToLoggingPayload(error);
    return this.postLogs({
      severity: SEVERITY_ERROR,
      message: message,
      payload: errorInfo
    });
  }

  private postLogs(err: LoggingPayload) {
    // remote server takes a list of errors
    // TODO: batch errors and send them in one request
    const encodedPayload = [zLoggingPayload.parse(err)];

    return this.client
      .post<AxiosResponse>(`${API_BASE_URL}/logs`, encodedPayload)
      .then(response => response.data);
  }
}

export default LoggingService;
