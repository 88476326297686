import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { fetchEnhancedOvertimeChartData, fetchEnhancedOvertimeGridData, updateAggBys } from './EnhancedOvertime.slice';
import { fetchEnhancedOvertimeConfigs } from './EnhancedOvertime.actions';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded, topMemInWorklistSelected } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  EnhancedOvertimeComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { isEmpty, isNil, isString } from 'lodash';
import { Option } from '../Configure/ConfigureModal';

export const enhancedOvertimeConfigLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActiveSubPage.type),
    map(() => maybeGetComponentProps<EnhancedOvertimeComponent>(state$.value, ConfDefnComponentType.enhancedOvertime)),
    filter(inputIsNotNullOrUndefined),
    mergeMap(({ defns }) => {
      return of(fetchEnhancedOvertimeConfigs(defns.view));
    })
  );
};

export const enhancedOvertimeDataLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      updateSelectedItem.type,
      updateAggBys.type,
      fetchEnhancedOvertimeConfigs.fulfilled.type
    ),
    map(() => maybeGetComponentProps<EnhancedOvertimeComponent>(state$.value, ConfDefnComponentType.enhancedOvertime)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const viewState = state$.value.pages.assortmentBuild.enhancedOvertime;
      const { chartViewDefn } = viewState;
      const [chartModelDefn, gridModelDefn] = defns.models;

      if (isNil(chartViewDefn) || isEmpty(viewState.aggBys)) {
        return of();
      }

      const topMembers = state$.value.worklist.selectedItem;
      const chartOptions: ExtraPivotOptions = {
        topMembers,
        aggBy: [chartViewDefn?.timeLevel, viewState.aggBys[0]]
          .map((agg: string | Option) => (isString(agg) ? `level:${agg}` : `${agg.type}:${agg.dataIndex}`))
          .join(','),
      };
      const finalChartOptions = organizeListDataOptions(chartOptions);

      const aggBy = [chartViewDefn?.timeLevel, ...viewState.aggBys]
        .map((agg: string | Option) => (isString(agg) ? `level:${agg}` : `${agg.type}:${agg.dataIndex}`))
        .join(',');
      const gridOptions: ExtraPivotOptions = {
        topMembers,
        aggBy,
      };
      const finalGridOptions = organizeListDataOptions(gridOptions);

      return of(
        fetchEnhancedOvertimeChartData(chartModelDefn, finalChartOptions),
        fetchEnhancedOvertimeGridData(gridModelDefn, finalGridOptions)
      );
    })
  );
};
