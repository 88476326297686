import React from 'react';
import { useLocation, useNavigate, NavigateFunction, useSearchParams } from 'react-router-dom';
interface CustomParams {
  tab: string;
  section: string;
  page: string;
  subPage: string;
  type: string;
  targetKey: string;
  version: string;
  perspective: string;
}
/**
 * @deprecated
 * See withRouter function for more details
 */
export interface WithRouterProps {
  routerParams: CustomParams;
  routerLocation: Location;
  routerNavigate: NavigateFunction;
  routerSearch: ReturnType<typeof useSearchParams>;
}
/**
 * Hook for use with react function components
 * @returns
 */
const useAsstRouteParams = () => {
  const { pathname } = useLocation();
  const otherParams = pathname.split('/');

  return {
    perspective: otherParams[1],
    tab: otherParams[2],
    section: otherParams[3],
    page: otherParams[4],
    subPage: otherParams[5],
    type: otherParams[5],
    targetKey: otherParams[6],
    version: otherParams[7],
  };
};

/**
 * @deprecated
 *
 * This wrapper is needed for class components only,
 * if you are using a functional component you can use the router hooks as designed
 * */
export function withRouter<P>(WrappedComponent: React.ComponentType<P>) {
  const RouterWrappedComponent = (props: P & CustomParams & Location) => {
    const params: CustomParams = useAsstRouteParams();
    const location = useLocation();
    const navigate = useNavigate();
    const search = useSearchParams();

    return (
      <WrappedComponent
        {...props}
        routerLocation={location}
        routerNavigate={navigate}
        routerParams={params}
        routerSearch={search}
      />
    );
  };

  RouterWrappedComponent.displayName = 'RouterWrappedComponent';
  return RouterWrappedComponent;
}
