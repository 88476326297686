// // tslint:disable:object-shorthand-properties-first
import { AxiosInstance, AxiosResponse } from 'axios';
import Scope, { AvailableMembers, ScopeCreateRequest } from '../../services/Scope.client';
import { GridAsyncState, isScopeNotReady, getScopeReadyData, getScopeId } from './Scope.types';
import { AppState, AppThunkDispatch, ThunkApi } from 'src/store';
import {
  receivedCreateScope,
  requestScope,
  receivedScope,
  requestCreateScope,
  clearScope,
  requestSeedCurrentScope,
  receivedSeedCurrentScope,
  requestImportVersion,
  updateGridAsyncState,
  requestScopeLockState,
  receivedScopeLockState,
  requestUndoScope,
  receiveUndoScope,
  requestRedoScope,
  receiveRedoScope,
  requestRefreshGrid,
  receiveRefreshGrid,
} from './Scope.slice';

import { PlanId } from './codecs/PlanMetadata';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Command, Commands } from './codecs/Commands';
import { computeViewRouteLinks, getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { ContextMfpModule, UIConfDefn } from 'src/services/configuration/codecs/confdefn';
import { find, isEmpty, isNil, values } from 'lodash';
import { hotSwitchActivePerspective } from 'src/pages/NavigationShell/NavigationShell.slice';
import { Bubble } from 'src/services/configuration/codecs/bindings.types';
import { toggleMfpScopeSelector } from '../ViewConfig/ViewConfig.slice';

export function forceRefreshGrid() {
  return (dispatch: AppThunkDispatch) => {
    dispatch(requestRefreshGrid());
  };
}

export function getScopeLockState(axios: AxiosInstance, scopeId: string) {
  return (dispatch: AppThunkDispatch) => {
    dispatch(requestScopeLockState());
    return new Scope(axios).getScopeLockState(scopeId).then((resp) => dispatch(receivedScopeLockState(resp)));
  };
}

interface MfpScopeError {
  errorMessage: string;
  code: number;
}
export interface MfpGetScopeRequest {
  scopeId: string;
  module?: string;
}
/**
 * fetchs an mfp scope and sets it's return into redux state
 * @description This function fetches a given mfp scope by id and tries to set it's value into state.
 * This function is tightly coupled to `NavigationShell.componentDidUpdate` and `MfpScopeSensitive.tsx`,
 * which both set the scope id into query parameters, or pull from query parameters to send to this function.
 */
export const getScope = createAsyncThunk<void, MfpGetScopeRequest, ThunkApi & MfpScopeError>(
  'scope/getScope',
  async (payload, { rejectWithValue, dispatch, extra }) => {
    // we take in the requested private version, and on success, re-request workflows
    const client = extra.axios;
    const scopeId = payload.scopeId;
    const module = payload.module;

    try {
      dispatch(requestScope({ scopeId }));

      return new Scope(client)
        .getScope(scopeId)
        .then((serverScope) => {
          if (serverScope.scopeReady) {
            dispatch(receivedScope({ ...serverScope }, client));
            dispatch(getScopeLockState(client, scopeId));
            // maybeUpdateScopeHistory(scopeId);
          } else if (!serverScope.scopeReady) {
            // if it isn't ready, fetch the scope again
            // the server will synchronously wait 10 seconds before returning
            // the scope, turning this into a polling action
            dispatch(receivedScope({ ...serverScope }, client));
            dispatch(getScope(payload));

            // maybeUpdateScopeHistory(scopeId);
          }
        })
        .catch(() => {
          // the scope redux state is cleared in scope.slice.extraReducers, so that the promise
          // returns at the same time the scope is cleared
          return rejectWithValue({ errorMessage: 'No scope found', code: 404 });
        });
    } catch (err) {
      return rejectWithValue({ errorMessage: 'An error occured getting the scope', code: 500 });
    }
  },
  {
    condition: (payload) => !!payload.scopeId,
  }
);

const hasTenant = (bubbleView: Bubble['view'][0]): boolean => !isNil(bubbleView.tenant);

/**
 * This simply fetches the first available mfpModule (via searching through contexts) for a given confdefn.
 * @param defn
 * @returns mfpModule info
 */
function getMfpModuleContext(defn?: UIConfDefn) {
  const ctxWithMfpMod = find(defn?.context, (k) => {
    return k.mfpModule != null;
  });
  const silo = ctxWithMfpMod?.mfpModule;
  return silo;
}

export function newScope(create: ScopeCreateRequest) {
  return (dispatch: AppThunkDispatch, getState: () => AppState, extra: ThunkApi['extra']) => {
    const createdWorkflow = create.workflow;
    const currentTenantConf = getState().appConfig.tenantConfig;
    const currentPerspectiveWorkflow = currentTenantConf.perspective?.scopeConfig.workflow;
    const currentModule = getMfpModule(getState());
    dispatch(requestCreateScope(create));
    if (!currentModule) {
      throw new Error("Can't make a scope without a known module");
    }

    return new Scope(extra.axios)
      .createScope(create, currentModule.siloId, currentModule.pathSlot)
      .then((scopeResp) => {
        dispatch(receivedCreateScope(scopeResp));

        if (isScopeNotReady(scopeResp)) {
          dispatch(getScope({ scopeId: scopeResp.id }));
        }

        // need to refresh lock state here, as well as in getScope,
        // because when refreshing a duplicate scope, lock state needs to be refreshed
        dispatch(getScopeLockState(extra.axios, scopeResp.id));
        const currentMfpMod = getMfpModuleContext(currentTenantConf);
        if (createdWorkflow !== currentPerspectiveWorkflow && currentMfpMod != null) {
          // when creating an mfp workflow different from the current workflow,
          // we need to try and find the related other workflow, and 'hot' switch the confdefn and viewlinks
          // to it underneath the user
          const maybePerspective = values(getState().appConfig.confDefns)
            .flatMap((cd) => {
              return cd.perspective.type === 'Bubble' ? cd.perspective.view.map((v) => v) : cd;
            })
            .filter(hasTenant)
            .find((cd) => {
              // This attempts to find another perspective with the same siloId *and* id matching current
              // but with the workflow based on the scope selector's selection. This only works if the first context
              // with an mfpModule in the two perspectives match.
              // *NOTE*: This means you can't currently use this behavior with confs supporting multiple MFPs
              const correctWorkflow = cd.tenant.perspective?.scopeConfig.workflow === createdWorkflow;
              const modInfo = getMfpModuleContext(cd.tenant);
              const correctMfp = currentMfpMod?.id === modInfo?.id && currentMfpMod?.siloId === modInfo?.siloId;
              return correctWorkflow && correctMfp;
            });

          if (maybePerspective) {
            dispatch(
              hotSwitchActivePerspective({
                selected: maybePerspective,
                viewLinks: computeViewRouteLinks(extra.configService.getBindings(maybePerspective.perspective)),
              })
            );
            // Need to close the scope selector after workflow switch
            dispatch(toggleMfpScopeSelector())
          }
        }
      });
  };
}

const createFetchMfpId = (maybeModule: ContextMfpModule | undefined): string => {
  return `${maybeModule?.id}${maybeModule?.siloId}`;
};

export const getAvailableScopeMembers = createAsyncThunk<
  AvailableMembers,
  ContextMfpModule,
  ThunkApi & { rejectValue: string }
>(
  'viewConfig/getAvailableScopeMembers',
  async (currentModule, { rejectWithValue, extra }) => {
    try {
      return await new Scope(extra.axios).getAvailableMembers(currentModule.siloId, currentModule.pathSlot);
    } catch (error) {
      return rejectWithValue('An error occured fetching the available scopes');
    }
  },
  {
    idGenerator: (currentModule) => {
      // used to identify the pending request
      // members are stable, so all id's on a silo/module have the same id,
      // and we can use it to identify duplicate requests
      return createFetchMfpId(currentModule);
    },
    // this controls if the thunk should be run at all
    // returning false will cause the async to NOT run
    condition: (currentModule, { getState }) => {
      const fetchingMfpId = getState().viewConfigSlice.fetchingMfpId;
      const newFetchingMfpId = createFetchMfpId(currentModule);
      // also don't run if trying to fetch the already fetched module
      return fetchingMfpId !== newFetchingMfpId;
    },
  }
);

export function resetScope() {
  return (dispatch: AppThunkDispatch) => {
    return dispatch(clearScope());
  };
}

// TODO: add listener for this
export const seedScope = createAsyncThunk<AxiosResponse, Command['command'], ThunkApi & { rejectValue: string }>(
  'scopeManagement/seedScope',
  async (seed, { getState, rejectWithValue, dispatch, extra }) => {
    dispatch(requestSeedCurrentScope());
    const scopeId = getScopeId(getState().mfpScope);
    if (scopeId) {
      try {
        return await new Scope(extra.axios).seedScope(scopeId, seed);
      } catch (err) {
        return rejectWithValue('No scope or target found when seeding');
      }
    }
    throw new Error('Scope needs to be ready in order to seed');
  }
);

// TODO fix the responses to this with an epic
export const importVersion = createAsyncThunk<AxiosResponse, Command['command'], ThunkApi & { rejectValue: string }>(
  'scopeManagement/importVersion',
  async (importCommand, { getState, rejectWithValue, dispatch, extra }) => {
    dispatch(requestSeedCurrentScope());
    const scopeId = getScopeId(getState().mfpScope);
    if (!scopeId) {
      throw new Error("Import was called without a scopeid, which shouldn't happen");
    }
    try {
      return await new Scope(extra.axios).importVersion(scopeId, importCommand);
    } catch (err) {
      return rejectWithValue('No scope or target found when importing');
    }
  }
);

export function overlayVersion(overlayId: string, applyTo: PlanId) {
  return (dispatch: AppThunkDispatch, getState: () => AppState, extra: ThunkApi['extra']) => {
    const scope = getState().mfpScope;

    const scopeId = getScopeReadyData(scope)?.mainConfig.id;
    if (!scopeId) {
      throw new Error("Import was called without a scopeid, which shouldn't happen");
    }
    dispatch(requestImportVersion());
    return new Scope(extra.axios)
      .postOverlay(scopeId, applyTo, overlayId)
      .then(() => dispatch(receivedSeedCurrentScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function setGridAsyncState(newState: GridAsyncState) {
  return (dispatch: AppThunkDispatch) => {
    dispatch(updateGridAsyncState(newState));
  };
}

export function unlockScopeLockState(axios: AxiosInstance, scopeId: string) {
  return (dispatch: AppThunkDispatch) => {
    return new Scope(axios).unlockScopeLockState(scopeId).then((data) => dispatch(receivedScopeLockState(data)));
  };
}

export function saveVersion(axios: AxiosInstance, scopeId: string, versionName?: string | null) {
  return (dispatch: AppThunkDispatch) => {
    if (!versionName) {
      versionName = null;
    }
    return new Scope(axios).saveVersion(scopeId, versionName);
  };
}

export function optimisticSetLockState(scopeId: string, scopeState: boolean) {
  return (dispatch: AppThunkDispatch) => {
    return dispatch(receivedScopeLockState(scopeState));
  };
}

// TODO fix the responses to this with an epic
// TODO check action/slice name alignment
export const balanceScope = createAsyncThunk<AxiosResponse, Command['command'], ThunkApi & { rejectValue: string }>(
  'scopeManagement/importVersion',
  async (balanceCommand, { getState, rejectWithValue, dispatch, extra }) => {
    dispatch(requestSeedCurrentScope());
    const scopeId = getScopeId(getState().mfpScope);
    if (!scopeId) {
      throw new Error("Balance was called without a scopeid, which shouldn't happen");
    }
    try {
      return await new Scope(extra.axios).balanceScope(scopeId, balanceCommand);
    } catch (err) {
      return rejectWithValue('No scope or target found when importing');
    }
  }
);

export function undoScope(scopeId: string) {
  return (dispatch: AppThunkDispatch, _getState: () => AppState, extra: ThunkApi['extra']) => {
    dispatch(requestUndoScope());
    return new Scope(extra.axios)
      .undoScope(scopeId)
      .then(() => dispatch(receiveUndoScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function redoScope(scopeId: string) {
  return (dispatch: AppThunkDispatch, _getState: () => AppState, extra: ThunkApi['extra']) => {
    dispatch(requestRedoScope());
    return new Scope(extra.axios)
      .redoScope(scopeId)
      .then(() => dispatch(receiveRedoScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function completeRefreshGrid() {
  return (dispatch: AppThunkDispatch) => {
    dispatch(receiveRefreshGrid());
  };
}

export const fetchCommands = createAsyncThunk<Commands, void, ThunkApi & { rejectValue: string }>(
  'scope/fetchCommands',
  async (_payload, { getState, rejectWithValue, extra }) => {
    try {
      const scopeId = getScopeId(getState().mfpScope);
      if (scopeId) {
        const response = await new Scope(extra.axios).getCommands(scopeId);
        return response;
      }
      throw new Error('nope');
    } catch (err) {
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue('Error occured fetching commands');
    }
  }
);

export const requestAddPrivateVersion = createAsyncThunk<
  void,
  { versionName: string; smartSave: boolean },
  ThunkApi & { rejectValue: string }
>('scope/requestAddPrivateVersion', async (payload, { getState, rejectWithValue, extra }) => {
  // we take in the requested private version, and on success, re-request workflows
  const client = extra.axios;
  const nameToAdd = payload.versionName;
  const readyScope = getScopeReadyData(getState().mfpScope);

  try {
    if (readyScope) {
      // eslint-disable-next-line max-len
      const savePromise = await new Scope(client)[payload.smartSave ? 'saveSmartPlanVersion' : 'saveVersion'](
        readyScope.mainConfig.id,
        nameToAdd
      );
      return savePromise;
    }
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
  throw new Error('Scope needs to be ready in order to save a version');
});

export const deleteScope = createAsyncThunk<void, string, ThunkApi & { rejectValue: string }>(
  'scope/deleteScope',
  async (scopeId, { extra, rejectWithValue }) => {
    const client = extra.axios;
    try {
      return await new Scope(client).deleteScope(scopeId);
    } catch (err) {
      return rejectWithValue('An unknown error occured deleting a scope');
    }
  },
  {
    // Only dispatch the thunk when scopeId is not empty
    condition: (scopeId) => !isEmpty(scopeId),
  }
);
