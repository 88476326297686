import { TargetSettingConfiguration, TargetSettingReduxSlice } from '../TargetSetting/TargetSetting.types';
import { TargetSettingNewSlice, createEmptySlice } from '../TargetCreation/TargetCreation.types';
import { LYTargetData } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { ViewRefreshable } from 'src/components/higherOrder/ScopeSensitive';
import { TargetListComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { WithRouterProps } from 'src/components/higherOrder/withRouter';

export interface TargetListReduxSlice {
  rowData: LYTargetData[];
  viewConf: TargetSettingConfiguration | null;
  targetNew: TargetSettingNewSlice;
}

export function createEmptyTargetList(): TargetListReduxSlice {
  return {
    rowData: [],
    viewConf: null,
    targetNew: createEmptySlice(),
  };
}

export interface ValueProps {
  config: TargetSettingConfiguration | null;
  rowData: LYTargetData[];
  title?: string;
}
export interface FunctionProps extends ViewRefreshable {
  onShowView: () => void;
  onRowAction: (action: string, selectedRow: TargetSettingReduxSlice) => void;
}

export interface OwnProps extends z.infer<typeof TargetListComponentProps> {}

export interface Props extends ValueProps, FunctionProps, OwnProps, WithRouterProps {}

export interface State {
  createNewOpen: boolean;
  popperRef: HTMLElement | null | undefined;
  popperOpen: boolean;
  confirmOpen: boolean;
}
