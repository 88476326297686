import { combineReducers } from 'redux';
import categorySummaryReducer, { CategorySummarySlice } from './CategorySummary/CategorySummary.slice';

export interface CategoryRecapSlice {
  categorySummary: CategorySummarySlice;
}

export default combineReducers({
  categorySummary: categorySummaryReducer,
});
