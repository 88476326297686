import { Epic, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { AppEpic } from 'src/store';
import { getScopeReadyData, ServerScope } from 'src/state/scope/Scope.types';
import {
  getAvailableListing,
  DimensionItem,
  getEffeciveGroups,
  getGroupFromConfigItem,
} from 'src/components/Mfp/PivotConfigurator/utils';
import _ from 'lodash';
import PivotConfig from 'src/pivot/PivotConfig';
import PivotManager from 'src/pivot/Pivot.client';
import { AxiosInstance } from 'axios';
import { NEVER, Observable, of } from 'rxjs';
import { AnyAction } from 'redux';
import { SettingsState } from 'src/state/settings/settings.slice';
import { requestRefreshGrid } from 'src/state/scope/Scope.slice';
import { ViewParams } from 'src/state/ViewConfig/ViewConfig.types';
import { setTrendDetailsDataStale } from './TrendDetails.slice';
import { isTypeVariance } from './TrendDetails.types';

export const trendDetailsRefresh: AppEpic = (action$, state$, deps): Observable<AnyAction | Observable<never>> => {
  return action$.pipe(
    ofType(requestRefreshGrid.type),
    concatMap(() => {
      const readyScope = getScopeReadyData(state$.value.mfpScope);
      if (readyScope) {
        return of(setTrendDetailsDataStale());
      }
      return NEVER;
    })
  );
};

export function buildChartPivotManager(
  mainConfig: ServerScope,
  settings: SettingsState['entriesByKey'],
  viewParams: ViewParams,
  scopeId: string,
  client: AxiosInstance
) {
  const config = viewParams.trendDetailsConfig;
  if (!config) {
    return;
  }
  const all = getAvailableListing(mainConfig, settings);

  let metricItems: string[] = [];
  let revisionItems: string[] = [];
  config.forEach((item) => {
    if (item.rendererParams && isTypeVariance(item.rendererParams)) {
      metricItems = metricItems.concat([
        ...item.rendererParams.bar.metrics.items,
        ...item.rendererParams.pills.metrics.items,
      ]);
      revisionItems = revisionItems.concat([
        ...item.rendererParams.bar.revisions.items,
        ...item.rendererParams.pills.revisions.items,
      ]);
    } else if (item.rendererParams) {
      metricItems = metricItems.concat(item.rendererParams.metrics.items);
      revisionItems = revisionItems.concat(item.rendererParams.revisions.items);
    }
  });
  const rows: DimensionItem[] = viewParams.rows;
  const columns: DimensionItem[] = [
    {
      dimension: 'metrics',
      items: [...new Set(metricItems)],
    },
    {
      dimension: 'revisions',
      items: [...new Set(revisionItems)],
    },
  ];

  const viewParam = { rows, columns };
  const effective = getEffeciveGroups(all, viewParam);
  const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
  const pivotRows = effective.row.map((mi) => getGroupFromConfigItem(mi, viewParam, levelsMap));
  const pivotCols = effective.column.map((mi) => getGroupFromConfigItem(mi, viewParam, levelsMap));
  const pivotConfig = new PivotConfig({
    scopeId: scopeId,
    rows: pivotRows,
    columns: pivotCols,
  });
  const pivotManager = new PivotManager({
    config: pivotConfig,
    axios: client,
    pivotName: 'TrendDetailsPivot',
  });
  return { pivotManager, pivotConfig };
}
