import Axios from 'src/services/axios';
import queryString from 'query-string';

import { ObjectDeserializer } from 'src/dao/objectDeserializer';
import { BasicPivotItem, RegionItem, SubmitPayload } from 'src/worker/pivotWorker.types';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

const listDataPath = '/api/pivot3/listData';
const flowSheetPath = '/api/v2/flowsheet';
const pricingPath = '/api/v2/overtime';
const coarseEditPath = '/api/property/update/coarse';
const granularEditPath = '/api/property/update/granular';

export interface EditCoordinates {
  [s: string]: string;
}

// replace MassEditPayload
export interface CoarseEditPayload {
  coordinates: EditCoordinates[];
  [s: string]: unknown;
}
export interface GranularEditPayloadItem {
  coordinates: EditCoordinates;
  [s: string]: unknown;
}

export interface RedecoratePayload {
  coordinates: EditCoordinates[];
  defnId: string;
  nestData: boolean;
  aggBy: string[];
}
export interface PivotClient {
  objectDeserializer: ObjectDeserializer;
  gridData(pivotQuery: Record<string, any>): Promise<Record<string, any>>;
  flowSheetSubmitData(payload: SubmitPayload, query: { appName: AppType }): Promise<boolean>;
  pricingSubmitData(payload: SubmitPayload, query: { appName: AppType }): Promise<boolean>;
  coarseEditSubmitData(payload: CoarseEditPayload): Promise<boolean>;
  granularEditSubmitData(payload: { updateReqs: GranularEditPayloadItem[]; appName: string }): Promise<boolean>;
  asstAddSearchSubmitData(payload: string[], level: string): Promise<RegionItem[]>;
  asstUploadExcelAdd(payload: FormData): Promise<RegionItem[]>;
  redecorate(payload: RedecoratePayload): Promise<BasicPivotItem[]>;
}

export function makePivotClient(objectDeserializer: ObjectDeserializer): PivotClient {
  const pivotClient: PivotClient = {
    gridData(pivotQuery: Record<string, any>) {
      const paramStr = queryString.stringify(pivotQuery);
      return Axios.get(`${listDataPath}?${paramStr}`).then((resp) => {
        return objectDeserializer.deserialize(resp.data);
      });
    },
    flowSheetSubmitData(payload: SubmitPayload, query: { appName: AppType }) {
      const paramStr = queryString.stringify(query);
      const url = `${flowSheetPath}?${paramStr}`;

      return Axios.post<{ success: boolean }>(url, payload).then((resp) => resp.data.success);
    },
    pricingSubmitData(payload: SubmitPayload, query: { appName: AppType }) {
      const paramStr = queryString.stringify(query);
      const url = `${pricingPath}?${paramStr}`;

      return Axios.post<{ success: boolean }>(url, payload).then((resp) => resp.data.success);
    },
    coarseEditSubmitData(payload: CoarseEditPayload) {
      const url = `${coarseEditPath}`;
      return Axios.post(url, payload).then(() => true);
    },
    granularEditSubmitData(payload: { updateReqs: GranularEditPayloadItem[]; appName: string }) {
      const url = `${granularEditPath}`;
      return Axios.post(url, payload).then(() => true);
    },
    asstAddSearchSubmitData(payload: string[], level: string): Promise<RegionItem[]> {
      return Axios.post('/api/search/products?appName=Assortment', {
        level,
        needles: payload,
        appName: 'Assortment',
      }).then((response) => response.data as RegionItem[]);
    },
    asstUploadExcelAdd(payload: FormData): Promise<RegionItem[]> {
      return Axios.post('/api/search/products/upload', payload, {
        headers: { 'content-type': 'multipart/form-data' },
      }).then((response) => response.data as RegionItem[]);
    },
    redecorate(payload: RedecoratePayload) {
      return Axios.post('/api/pivot3/redecorate?appName=Assortment', payload).then(
        (response) => response.data as BasicPivotItem[]
      );
    },
    objectDeserializer: objectDeserializer,
  };
  return pivotClient;
}
