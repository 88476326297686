import fp from 'lodash/fp';
import { connect } from 'react-redux';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { overloadSubheader } from 'src/components/Subheader/Subheader.slice';
import {
  cleanUp,
  receiveError,
  requestPricingOverTimeConfig,
  receivePricingOverTimeConfig,
  submitPayload,
  receivePricingEvents,
  fetchMassEditData,
  fetchCompanionData,
  updateSelectedItem,
  receiveSomePricingOverTimeConfig,
} from './PricingOverTime.slice';
import { AppState, AppThunkDispatch } from 'src/store';
import { projectState } from 'src/components/ListGridPair/ListGridPair.selectors';
import { PricingOverTime } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime';
import Axios from 'src/services/axios';
import { isEmpty, isNil } from 'lodash/fp';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { MassEditConfig } from 'src/components/MassEdit/MassEdit';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { get } from 'lodash';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';
import { OwnProps, PricingEventResponse } from './PricingOverTime.types';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { MassEditDefn, GridViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import {
  CompanionViewDefn,
  GroupByConfig,
  RollupDefn,
  SortByConfig,
} from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { FlowSheetAndPricingDispatchProps, FlowSheetAndPricingMapState } from '../FlowSheet/FlowSheet.types';
import { getfabProps } from '../StyleEdit/StyleEdit.utils';

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): FlowSheetAndPricingDispatchProps {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;
  const [modelDefn, massEditModelDefn] = defns.models;

  return {
    submitPayload(payload: GranularEditPayloadItem[], shouldRefresh = true) {
      return dispatch(submitPayload(payload, shouldRefresh));
    },
    onCompanionItemClick(memberId: string) {
      dispatch(updateSelectedItem(memberId));
    },
    onShowView() {
      Promise.all([
        Axios.get<PricingEventResponse>(`/api/v2/events?appName=${ASSORTMENT}`),
        tenantConfigClient.getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [GridViewDefn, SortByConfig, GroupByConfig, RollupDefn, CompanionViewDefn, MassEditDefn],
        }),
        dispatch(requestPricingOverTimeConfig()),
      ])
        .then((resp) => {
          const [eventsResp, viewConfig] = resp;
          dispatch(receivePricingEvents(eventsResp.data.data));

          const defnStr = defns.view[0];
          const favoritesList = (viewConfig as any)[defns.view.length];
          const unmodifiedViewDefn = viewConfig[0];
          const localConfig = getLocalConfig(defnStr, favoritesList, dispatch, unmodifiedViewDefn);
          dispatch(
            receivePricingOverTimeConfig({
              grid: localConfig?.config || unmodifiedViewDefn,
              listSort: viewConfig[1],
              subheaderRollUp: viewConfig[3],
              list: viewConfig[4],
              listLevelBy: viewConfig[6],
              // FIXME: revisit this type issue
              massEdit: !isNil(viewConfig[5]) ? ((viewConfig[5] as unknown) as MassEditConfig) : undefined,
              unmodifiedViewDefn,
            })
          );
          dispatch(
            overloadSubheader({
              groupByOptions: viewConfig[2],
              showFlowStatus: true,
              showSearch: true,
              title: 'Grid View',
            })
          );
          return viewConfig[4];
        })
        .then((listConfig: TenantConfigViewData) => {
          let model = modelDefn;
          if (listConfig.model) {
            model = listConfig.model;
          }
          dispatch(fetchCompanionData(model));
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.pricing,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );

      // fetch massEdit data separately
      dispatch(fetchMassEditData(massEditModelDefn));
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveSomePricingOverTimeConfig(config));
    },
    onRefetchData() {
      dispatch((_dispatch: AppThunkDispatch, getState: () => AppState): any => {
        const { viewDefns } = getState().pages.assortmentBuild.pricing;
        let model = modelDefn;
        if (viewDefns != null && viewDefns.list.model != null) {
          model = viewDefns.list.model;
        }
        dispatch(fetchCompanionData(model));
      });
      dispatch(fetchMassEditData(massEditModelDefn));
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    updateAssortmentPlan: () => {
      dispatch(updateAssortmentPlan('PlanQueue'));
    },
  };
}

export function mapStateToProps(state: AppState, ownProps: OwnProps): FlowSheetAndPricingMapState {
  const viewState = state.pages.assortmentBuild.pricing;
  const { title, defns, keys, showPopover, fabType = FabType.none, showFlowStatus, showUndoBtn } = ownProps;
  const { rangeList, daysRangeList, timeInfo } = state.scope;
  const allowFrom = !isEmpty(rangeList) ? rangeList[0].id : '';
  const allowTo = !isEmpty(rangeList) ? rangeList[rangeList.length - 1].id : '';
  const fabProps = getfabProps(state, fabType);

  const companionData = getUniqueDataFromCache(viewState, HashType.pricingCompanion)?.tree || [];
  const overTimeData = getUniqueDataFromCache(viewState, HashType.pricingOverTime)?.tree || [];
  const companionDataLoaded = isDataLoaded(viewState.viewDataStateCompanion);
  const overTimeDataLoaded = isDataLoaded(viewState.viewDataStateOverTime);
  const massEditDataLoaded = isDataLoaded(viewState.viewDataStateMassEdit);
  const liveDataReady = viewState.viewDataStateOverTime === ViewDataState.liveDataReady;
  const adornments: AdornmentType[] = get(viewState.viewDefns, 'grid.adornments', []);
  const selectedId = viewState.selectedItem;
  const rowHeight = viewState.viewDefns.grid.main?.rowHeight;
  const groupRowHeight = viewState.viewDefns.grid.main?.groupRowHeight;
  const columnWidth = viewState.viewDefns.grid.main?.columnWidth;
  const configLoaded = !viewState.isConfigLoading;
  const dataLoaded = companionDataLoaded;
  const scopeStart: string | null = get(state, 'scope.scope.start');
  const itemLocation = state.scope.scope.locationMember!;
  const originalState = {
    title,
    identityField: keys.idProp,
    treeData: companionData,
    subheaderState: state.subheader,
    subheaderViewDefns: defns.subheader,
    shouldFilterFlowStatus: true,
    allowFrom,
    allowTo,
    rangeList: daysRangeList,
    showPopover,
    activeTab: state.perspective.activeTab,
    ...viewState,
    timeInfo,
    fabType: fabType || FabType.none,
    showFlowStatus: showFlowStatus === false ? false : true,
    adornments,
    selectedId,
    rowHeight,
    groupRowHeight,
    columnWidth,
    configLoaded,
    dataLoaded,
    showUndoBtn,
    allowWorklistFunctionality: false,
    fab: fabProps,
  };

  const stateProps = {
    ...originalState,
    ...projectState(originalState),
    ...viewState,
    scopeStart,
    overTimeDataLoaded,
    massEditDataLoaded,
    liveDataReady,
    overTimeData,
    itemLocation,
  };
  return stateProps;
}

function mergeProps(stateProps: FlowSheetAndPricingMapState, dispatchProps: FlowSheetAndPricingDispatchProps) {
  return {
    ...stateProps,
    ...dispatchProps,
  };
}
const sensitiveView = fp.flow(
  () => PricingOverTime,
  withFab,
  makeScopeAndFilterSensitive,
  makePrintSensitive,
  makePopoverSensitive
)();

export default connect(mapStateToProps, dispatchToProps, mergeProps)(sensitiveView);
