import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  cleanHistoryStylePaneUp,
  receiveHistoryStylePane,
  requestHistoryStylePane,
  receiveError,
  moveItemToHistory,
  receiveHistoryStylePaneTenantConfig,
} from './HistoryStylePane.actions';
import HistoryStylePane from './HistoryStylePane';
import { ReduxSlice as HistoryStylePaneSlice } from './HistoryStylePane.reducer';
import container from 'src/ServiceContainer';
import { getProcessedCurrentItem } from './HistoryStylePane.selector';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export interface StateProps extends HistoryStylePaneSlice {}

export interface DispatchProps {
  onShowHistoryStylePane(memberId: string, isAssortmentBuild: boolean): void;
  moveItemToHistory(memberId: string, item: BasicPivotItem[]): void;
  onDestroy(): void;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    ...state.historyStylePane,
    currentItem: getProcessedCurrentItem(state),
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  const service = container.pivotService;
  const client = container.tenantConfigClient;
  return {
    onShowHistoryStylePane(memberId: string, isAssortmentBuild: boolean) {
      dispatch(requestHistoryStylePane());
      client
        .getTenantViewDefns({
          defnIds: isAssortmentBuild ? ['AssortmentStyleDetailsPane'] : ['HistoryStylePaneLayout'],
          appName: ASSORTMENT,
        })
        .then((resp) => {
          if (resp !== null && Array.isArray(resp) && resp.length > 0) {
            dispatch(receiveHistoryStylePaneTenantConfig(resp[0]));
            return;
          }
          dispatch(receiveError("Couldn't load configs"));
        });

      const options = {
        aggBy: 'level:channel,level:stylecolor',
        topMembers: memberId,
        nestData: false,
      };
      const dataPromise = isAssortmentBuild
        ? service.getStyleReviewDetails(options)
        : service.getHistoryStylePaneDetails(options);

      dataPromise.then((resp) => {
        if (resp !== null) {
          dispatch(receiveHistoryStylePane(resp));
          return;
        }
        dispatch(receiveError("Couldn't load pivot results"));
      });
    },
    moveItemToHistory(memberId: string, item: BasicPivotItem[]) {
      dispatch(moveItemToHistory(memberId, item));
    },
    onDestroy() {
      dispatch(cleanHistoryStylePaneUp());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(HistoryStylePane);
