import { Memoize } from 'lodash-decorators';
import { Field } from './Field';
import {
  METRICS,
  PERCENT_TO_TOTAL_VERSION,
  PERCENT_TO_GRAND_TOTAL_VERSION,
  DEFAULT_PERCENT_FORMAT,
} from 'src/utils/Domain/Constants';
import { PERCENTAGE_VARTYPE } from 'src/services/Pivot.types';
import { isNil } from 'lodash';

export default class Intersection {
  protected fields: Field[];
  protected measureField: Field | undefined = undefined;

  constructor(fields: Field[]) {
    this.fields = [...fields];
    for (const field of this.fields) {
      if (field.member.level.dimension.id === METRICS) {
        this.measureField = field;
        break;
      }
    }
  }

  public hasMeasure(): boolean {
    return !!this.measureField;
  }
  public maybeGetRevisionFormatString(): string | undefined {
    // there are three ways that a intersection can be alternately formatted, and we need to
    // extract that formatting from a different place on the metric member
    const hasPctVarType = this.fields.findIndex((f) => f.member.varType === PERCENTAGE_VARTYPE) >= 0;
    const hasPctTotal = this.fields.findIndex((f) => f.member.type === PERCENT_TO_TOTAL_VERSION) >= 0;
    const hasPctGrandTotal = this.fields.findIndex((f) => f.member.type === PERCENT_TO_GRAND_TOTAL_VERSION) >= 0;

    if (hasPctVarType) {
      return this.measureField?.member.format?.variancePercentGrid || DEFAULT_PERCENT_FORMAT;
    }
    if (hasPctTotal) {
      return this.measureField?.member.format?.percentTotalGrid || DEFAULT_PERCENT_FORMAT;
    }
    if (hasPctGrandTotal) {
      return this.measureField?.member.format?.percentGrandTotalGrid || DEFAULT_PERCENT_FORMAT;
    }
  }

  public maybeGetRevisionLocation(): 'variancePercentGrid' | 'percentTotalGrid' | 'percentGrandTotalGrid' | undefined {
    // there are three ways that a intersection can be alternately formatted, and we need to
    // extract that formatting from a different place on the metric member
    const hasPctVarType = this.fields.findIndex((f) => f.member.varType === PERCENTAGE_VARTYPE) >= 0;
    const hasPctTotal = this.fields.findIndex((f) => f.member.type === PERCENT_TO_TOTAL_VERSION) >= 0;
    const hasPctGrandTotal = this.fields.findIndex((f) => f.member.type === PERCENT_TO_GRAND_TOTAL_VERSION) >= 0;

    if (hasPctVarType) {
      return 'variancePercentGrid' as const;
    }
    if (hasPctTotal) {
      return 'percentTotalGrid' as const;
    }
    if (hasPctGrandTotal) {
      return 'percentGrandTotalGrid' as const;
    }
  }

  public getFormat(): string | undefined {
    // if it has revision formatting, take that first,
    // otherwise try and get the standard member formatting
    return !isNil(this.maybeGetRevisionFormatString())
      ? this.maybeGetRevisionFormatString()
      : this.measureField?.member.format?.standard;
  }

  public getMeasureField(): Field | undefined {
    return this.measureField;
  }

  @Memoize()
  public getFieldIds(): string[] {
    return this.fields.map((field) => `${field.member.level.id}:${field.member.id}`);
  }
  public getFieldListAsPipeString(): string {
    const idLst = this.fields.reduce((prev, currentField) => {
      return (prev += `${currentField.member.id}|`);
    }, '');
    return idLst.substring(0, idLst.length - 1);
  }

  public getField(index: number): Field {
    return this.fields[index];
  }

  public getLastIndex(): Field {
    return this.fields[this.fields.length - 1];
  }

  public getSecondToLastIndex(): Field {
    return this.fields[this.fields.length - 2];
  }

  public getLineIndex(): Field {
    return this.getSecondToLastIndex() || this.getLastIndex();
  }

  public matches(matchFn: (field: Field) => boolean): boolean {
    for (const field of this.fields) {
      if (matchFn(field)) {
        return true;
      }
    }
    return false;
  }

  public toString() {
    return this.fields.map((f) => f.member.name).join(' ✕ ');
  }
}
