import { style, media } from 'typestyle';
import { SUBHEADER_HEIGHT, SUBHEADER_WIDTH_BREAKPOINT } from 'src/components/Subheader/Subheader.styles';
import { smallMediumFont } from 'src/utils/Style/Theme';
const sectionHeader = 'section-header';
const sectionBody = 'section-body';

export const chartContainer = style(
  media({ minWidth: 0, maxWidth: SUBHEADER_WIDTH_BREAKPOINT }, { height: `calc(100% - ${SUBHEADER_HEIGHT}px)` }),
  {
    $debugName: 'product-mix-chart-container',
    height: `calc(100% - ${SUBHEADER_HEIGHT}px)`,
  }
);

export const styles = {
  section: style({
    height: '50%',
    $nest: {
      [`> .${sectionHeader}`]: {
        height: 28,
        fontSize: smallMediumFont,
      },
      [`> .${sectionBody}`]: {
        height: 'calc(100% - 28px)',
      },
    },
  }),
  sectionHeader: sectionHeader,
  sectionBody: sectionBody,
};
