import { AxiosInstance } from 'axios';
import { AppState, AppThunkDispatch } from 'src/store';
import { FavoritesEntry } from 'src/services/Favorites';
import { createFavorite } from 'src/state/favorites/favorites.slice';
import {
  saveVersion,
  undoScope,
  redoScope,
  unlockScopeLockState,
  forceRefreshGrid,
} from 'src/state/scope/Scope.actions';
import { VisualizeSummaryPivotAg } from './visualize-summary-pivot-ag';
import {
  VisualizeSummaryPivotDispatchProps,
  VisualizeSummaryPivotValueProps,
} from './visualize-summary-pivot-ag.types';
import { updateGridAsyncState } from 'src/state/scope/Scope.slice';
import { getScopeId, getScopeReadyData, GRID_DEFAULT } from 'src/state/scope/Scope.types';
import { extractNavPath } from 'src/pages/NavigationShell/navigationUtils';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { isSome } from 'fp-ts/lib/Option';

export function mapStateToProps(state: AppState): VisualizeSummaryPivotValueProps {
  let tempPivotName = 'pivot';
  const maybeMatchHash = extractNavPath(window.location, 'hash');
  if (isSome(maybeMatchHash)) {
    // should be view id
    // FIXME add mfp support to work list
    tempPivotName = isSome(maybeMatchHash) ? maybeMatchHash.value[3].split('?')[0] : tempPivotName;
  }
  const currentModule = getMfpModule(state)?.id || '';
  return {
    scopeId: getScopeId(state.mfpScope),
    mainConfig: getScopeReadyData(state.mfpScope)?.mainConfig,
    initialized: getScopeReadyData(state.mfpScope)?.initialized || false,
    settings: state.settings.entriesByKey,
    isLockToggled: !!getScopeReadyData(state.mfpScope)?.hasLocks,
    favorites: state.favorites.favorites,
    groupId: currentModule,
    isFetchingScope: getScopeReadyData(state.mfpScope)?.isFetching || false,
    pivotName: tempPivotName,
    gridAsyncState: getScopeReadyData(state.mfpScope)?.gridAsyncState || GRID_DEFAULT,
    hasEditableRevision: getScopeReadyData(state.mfpScope)?.hasEditableRevision || false,
    scopeReady: !!getScopeReadyData(state.mfpScope) || false,
    reports: state.workingSets.reports,
  };
}
export const mapDispatchToProps = (dispatch: AppThunkDispatch): VisualizeSummaryPivotDispatchProps => {
  return {
    onUnlockAllClick: (cmp: VisualizeSummaryPivotAg, axios: AxiosInstance, scopeId: string) => {
      dispatch(unlockScopeLockState(axios, scopeId)).then(() => {
        if (cmp.pivot) {
          cmp.pivot.refreshCells();
          cmp.updateContextMenu();
        }
      });
    },
    addToFavorites: (axios, fav: FavoritesEntry) => dispatch(createFavorite(axios, fav)),
    saveWorkingPlan: (axios, scopeId) => dispatch(saveVersion(axios, scopeId)),
    onClickUndo: (scopeId) => dispatch(undoScope(scopeId)),
    // this was missing so redo on summary wasn't doing anything
    onClickRedo: (scopeId) => dispatch(redoScope(scopeId)),
    onforceRefreshGrid: () => dispatch(forceRefreshGrid()),
    onUpdateGridAsyncState: updateGridAsyncState,
  };
};
