import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';
import { TenantConfigViewData, ViewDefnState } from 'src/dao/tenantConfigClient';
import { AppState, AppThunkDispatch } from 'src/store';
import { ViewDataState } from 'src/types/Domain';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import service from 'src/ServiceContainer';
import Axios from 'src/services/axios';
import { BasicItem, ListDataOptions } from 'src/worker/pivotWorker.types';
import { getSelectedFloorsetIndex } from './FloorsetComparison.selectors';
import { isEmpty, isNil } from 'lodash';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { ComponentErrorType, ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import {
  setFloorsetsQR,
  setSelectedFloorsetQRIndex,
} from 'src/components/Reconcilation/QuickRecapView/QuickReconcile.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';

export interface FloorsetComparisonGroupingInfo {
  dataIndex: string;
  staticColumns: string[];
}

export interface FloorsetComparisonTenantResponse {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  calcViewDefn?: TenantConfigViewData;
  favoritesList?: FavoriteResponseItem[];
}

// Reducer types

export interface FloorsetCmpSlice {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  calcViewDefn: TenantConfigViewData;
  groupingInfo?: FloorsetComparisonGroupingInfo | null;
  floorsets: BasicItem[];
  selectedFloorsetIndex: number;
  floorsetIndexLastUpdated: number;
  favoritesList: FavoriteResponseItem[];
  viewDefnState: ViewDefnState;
  viewDataState: ViewDataState;
  cacheHash: string | null;
}
const initialState: FloorsetCmpSlice = {
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  calcViewDefn: {} as TenantConfigViewData,
  groupingInfo: null,
  floorsets: [],
  selectedFloorsetIndex: -1,
  floorsetIndexLastUpdated: Date.now(),
  favoritesList: [],
  viewDefnState: ViewDefnState.idle,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

const floorsetComparisonReducer = createSlice({
  name: 'FloorsetComparison',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTenantConfig(state, action: PayloadAction<FloorsetComparisonTenantResponse>) {
      state.viewDefnState = ViewDefnState.loaded;
      const payload = action.payload;
      state.viewDefn = payload.viewDefn;
      if (!isNil(payload.unmodifiedViewDefn)) state.unmodifiedViewDefn = payload.unmodifiedViewDefn;
      if (!isNil(payload.calcViewDefn)) state.calcViewDefn = payload.calcViewDefn;
      if (!isNil(payload.favoritesList)) state.favoritesList = payload.favoritesList;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    setFloorsets(state, action: PayloadAction<BasicItem[]>) {
      // FIXME: why is this BasicItem[]? This is a vv return innit?
      state.floorsets = action.payload;
    },
    setSelectedFloorsetIndex(state, action: PayloadAction<number>) {
      state.selectedFloorsetIndex = action.payload;
    },
    setGroupingInfo(state, action: PayloadAction<FloorsetComparisonGroupingInfo>) {
      state.groupingInfo = action.payload;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  setFloorsets,
  setSelectedFloorsetIndex,
  setGroupingInfo,
  receiveError,
  cleanUp,
} = floorsetComparisonReducer.actions;

export function fetchFloorsetComparisonData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export function fetchFloorsetsAndSelect() {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<AnyAction | void> => {
    const productId = getState().scope.scope.productMember;
    if (!productId) {
      throw new Error('Product member should exist but doesnt, this shouldnt happen');
    }
    const url = `/api/assortment/floorsets?appName=${ASSORTMENT}&product=${encodeURIComponent(productId)}`;
    const floorsets = (await Axios.get(url)).data.data;

    if (!isEmpty(floorsets)) {
      dispatch(setFloorsets(floorsets));
      dispatch(setFloorsetsQR(floorsets));
      const selectedIndex = getSelectedFloorsetIndex(getState());
      dispatch(setSelectedFloorsetQRIndex(selectedIndex));
      dispatch(setSelectedFloorsetIndex(selectedIndex));
    } else {
      dispatch(
        receiveError({
          type: ComponentErrorType.data,
          message: 'No floorsets configured for this view',
          name: ConfDefnComponentType.floorsetComparison,
        })
      );
    }
  };
}

export default floorsetComparisonReducer.reducer;
