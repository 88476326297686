import { style, keyframes, classes } from 'typestyle';
const spinnerFrames = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});
export const overlay = style({
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: 'white',
  opacity: 0.6,
  zIndex: 101,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 0,
});

export const overlayFit = classes(
  overlay,
  style({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })
);
export const spinner = style({
  display: 'inline-block',
  width: '64px',
  height: '64px',
  $nest: {
    '&::after': {
      content: '" "',
      display: 'block',
      width: '46px',
      height: '46px',
      margin: '0px',
      border: '5px solid #898989',
      borderRadius: '50%',
      animationName: spinnerFrames,
      animationDuration: '1.2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      borderColor: '#898989 transparent #898989 transparent',
    },
  },
});
