import { deburr, kebabCase } from 'lodash';
import { Perspective, TenantTab, TenantView } from 'src/services/configuration/codecs/bindings.types';

export const CONF_DEFN_NAME = 'AssortmentUiConf';

export interface TenantTabConfigurator extends TenantTab {
  isCustom?: boolean;
}
export type TenantViewConfigurator = TenantView & { ['$componentPropRef']?: string };
export interface RefConfig {
  $schema: 'RefList';
  components: RefComponent[];
}

export interface RefComponent {
  title: string;
  id: string;
  properties: RefProperties;
}

export interface RefProperties {
  component: string;
  componentProps: Record<any, any>;
  inPerspectives: Perspective[];
}

export const makePathSlot = (id: string) => {
  return deburr(kebabCase(id));
};
