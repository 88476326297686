import { style, classes } from 'typestyle';

export const itemBase = (selected: boolean | undefined) =>
  style({
    width: 50,
    height: 50,
    cursor: 'pointer',
    boxShadow: selected ? '0 0 0 0.2rem rgba(69,182,159,.5)' : '',
  });

  export const missingSwatchItem = (selected: boolean | undefined) =>
  classes(
    'fas fa-exclamation',
    itemBase(selected),
    style({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f5f5f5', // Light gray background
      color: '#ff0000', // Red color for the exclamation mark
      fontSize: '16px',
    })
  );

export const colorSwatchItem = (swatchUrl: string, selected = false) =>
  classes(
    style({
      background: `url(${swatchUrl})no-repeat`,
      backgroundSize: 'cover',
      boxShadow: selected ? '0 0 0 0.2rem rgba(69,182,159,.5)' : '',
    }),
    itemBase(selected)
  );