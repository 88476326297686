import * as React from 'react';
import { style } from 'typestyle';

const statusClass = style({
  $debugName: 'status-icon-renderer',
});

export interface Params {
  value?: string;
}

export class StatusIconRenderer extends React.Component<Params> {
  constructor(props: Params) {
    super(props);
  }

  render() {
    const icon = this.props.value;
    if (icon && typeof icon === 'string') {
      const fakeTuple = icon.split(',');
      return <i className={`${statusClass} fa-fw ${fakeTuple[0]}`} style={{ color: fakeTuple[1] }} />;
    }
    return null;
  }
}
