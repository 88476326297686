import { style } from 'typestyle';
import { percent, viewHeight } from 'csx';

const viewSimilarCardContainerOverrides = style({
  $debugName: 'viewSimilarCardContainerOverrides',
  height: viewHeight(60),
  $nest: {
    '& > div': {
      height: percent(100),
    },
  },
});

export default {
  viewSimilarCardContainerOverrides,
};
