import { connect } from 'react-redux';
import { Reporting } from 'src/pages/Reporting/Reporting';
import { AppState, AppThunkDispatch } from 'src/store';
import { requestReportingConfig, receiveReportingConfig, receiveError } from './Reporting.slice';
import serviceContainer from 'src/ServiceContainer';
import { ReportingSlice } from 'src/pages/Reporting/Reporting.slice';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { ZodError } from 'zod';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

export interface StateProps {
  reporting: ReportingSlice;
  perspective?: AppType;
}

export interface DispatchProps {
  onShowView(): void;
}

function stateToProps(state: AppState): StateProps {
  return {
    reporting: state.pages.reporting,
    perspective: state.perspective.selected?.appType,
  };
}

function fetchReportConfig() {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<void> => {
    const { selected } = getState().perspective;
    dispatch(requestReportingConfig());
    try {
      if (selected) {
        const reportConfig = await serviceContainer.reportClient.getReportConfig(selected.appType);
        dispatch(receiveReportingConfig(reportConfig));
      }
    } catch (error) {
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.reporting,
          stack: (error as Error)?.stack,
          issues: error as ZodError,
        })
      );
    }
  };
}

function dispatchToProps(dispatch: AppThunkDispatch) {
  return {
    onShowView() {
      dispatch(requestReportingConfig());
      dispatch(fetchReportConfig());
    },
  };
}

export default connect(stateToProps, dispatchToProps)(Reporting);
