import * as React from 'react';
import { identity, pick, isNil, max } from 'lodash';
import { connect } from 'react-redux';

import { AppState, clearCachedData } from 'src/store';
import { updateFilterSensitivity } from 'src/components/FilterPanel/FilterPanel.slice';
import { wrappedDispatch, WrappedDispatch } from 'src/utils/Redux/Dispatch';
import { Overlay } from 'src/common-ui/index';

export interface ViewRefreshable {
  // TODO: make this something else and refactor all the impls to have an onRefetchData dispatcher
  onShowView?(): void;
  onRefetchData?(): void;
}

export interface InjectedProps<ParentProps> {
  scopeLastUpdated: number;
  filterLastUpdated: number;
  filtersAreLoading: boolean;
  refreshRequestedAt: number;
  parentProps: ParentProps;
  updateSensitivity: (value: boolean) => void;
  onRefetchData?(): void;
}

export interface State {
  scopeLastUpdated: number;
  filterLastUpdated: number;
  refreshRequestedAt: number;
  callLocalFunctions: () => void;
}

export interface Nameable {
  displayName?: string;
  name?: string;
}

function mergeProps<P extends ViewRefreshable>(
  state: AppState,
  _wrapped: WrappedDispatch,
  parentProps: P
): InjectedProps<P> {
  return {
    onRefetchData: parentProps.onRefetchData || parentProps.onShowView || undefined,
    scopeLastUpdated: state.scope.updatedAt || 0,
    filterLastUpdated: state.filters.updatedAt || 0,
    refreshRequestedAt: state.planTracker.refreshRequestedAt || 0,
    filtersAreLoading: state.filters.isFlushing,
    parentProps,
    updateSensitivity(value: boolean) {
      _wrapped.dispatch(updateFilterSensitivity(value));
    },
  };
}

function getDisplayName(nameable: Nameable) {
  return nameable.displayName || nameable.name || 'Component';
}

function vGreaterZero(v: number | null | undefined) {
  return !isNil(v) && v > 0;
}

export function makeScopeAndFilterSensitive<P>(WrappedComponent: React.ComponentType<P>) {
  class ScopeAndFilterSensitive extends React.Component<InjectedProps<P>, State> {
    static getDerivedStateFromProps(nextProps: InjectedProps<P>, state: State) {
      const isFilterStale =
        vGreaterZero(state.filterLastUpdated) && nextProps.filterLastUpdated > state.filterLastUpdated;
      const refreshStale =
        vGreaterZero(nextProps.refreshRequestedAt) && nextProps.refreshRequestedAt > state.refreshRequestedAt;

      if (isFilterStale || refreshStale) {
        // All data is stale, clear the cached data in Style and StyleColor Review.
        clearCachedData();
        if (nextProps.onRefetchData) {
          nextProps.onRefetchData();
        } else if (state.callLocalFunctions) {
          // Call local functions if they exist on the component (so you don't need a redux container)
          state.callLocalFunctions();
        }
      }
      return pick(nextProps, 'scopeLastUpdated', 'filterLastUpdated', 'refreshRequestedAt');
    }

    wrappedComponentRef: React.RefObject<ViewRefreshable> = React.createRef();
    displayName = `ScopeAndFilterSensitive(${getDisplayName(WrappedComponent)})`;
    constructor(props: InjectedProps<P>) {
      super(props);
      this.state = {
        scopeLastUpdated: -1,
        filterLastUpdated: -1,
        refreshRequestedAt: -1,
        callLocalFunctions: () => {
          const ref = this.wrappedComponentRef;
          if (ref && ref.current) {
            if (ref.current.onShowView) {
              ref.current.onShowView();
            }
            if (ref.current.onRefetchData) {
              ref.current.onRefetchData();
            }
          }
        },
      };
    }

    componentDidMount() {
      this.props.updateSensitivity(true);
    }

    render() {
      const { scopeLastUpdated, filterLastUpdated, refreshRequestedAt } = this.state;
      const mostRecentChange = max([scopeLastUpdated, filterLastUpdated, refreshRequestedAt]);
      return (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          <Overlay type="loading" visible={this.props.filtersAreLoading} qaKey="ScopeAndFilterSensitiveOverlay" />
          <WrappedComponent
            ref={this.wrappedComponentRef}
            mostRecentChange={mostRecentChange}
            {...this.props.parentProps}
          />
        </div>
      );
    }
  }

  return connect<AppState, WrappedDispatch, P, InjectedProps<P>>(
    identity,
    wrappedDispatch,
    // @ts-ignore
    mergeProps
  )(ScopeAndFilterSensitive);
}
