import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateGroupBy } from 'src/components/Subheader/Subheader.slice';
import { AssortmentAddSlice, fetchAssortmentAddData, fetchAssortmentAddBySearchData } from './AssortmentAdd.slice';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  AssortmentAddViewComponent,
  AssortmentAddBySearchComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isListDataApi, DataApi } from 'src/services/configuration/codecs/confdefnView';
import {
  getAggBys,
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { LEVEL_STYLE_COLOR } from 'src/utils/Domain/Constants';
import { Lens } from 'monocle-ts';
import { makeCartService } from '../../AssortmentCart/AssortmentCart.service';
import { assortmentAddStylesLens } from 'src/services/lenses/lenses';
import { update } from 'src/services/lenses/Lenses.actions';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { getWeeksFitView } from './AssortmentAddView.utils';

const selectionService = makeCartService(
  assortmentAddStylesLens.compose(Lens.fromProp<AssortmentAddSlice>()('selectedItemsForCart'))
);

export const assortmentAddLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      setActiveSubPage.type,
      updateGroupBy.type
    ),
    map(() =>
      maybeGetComponentProps<AssortmentAddViewComponent>(state$.value, ConfDefnComponentType.assortmentAddView)
    ),
    filter(inputIsNotNullOrUndefined),
    // FIXME: removing for now, state never passed these conditionals never loading the page
    // filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const modelDefn = defns.model;
      const dataApi: DataApi = {
        defnId: modelDefn,
        isListData: true,
        params: {
          aggBy: LEVEL_STYLE_COLOR,
        },
      };
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const aggBy = getAggBys(state$.value.subheader, dataApi);
      const {scopeRange, assortmentRange } = state$.value.pages.assortmentBuild.addStyles;
      const rangeData = getWeeksFitView(scopeRange, assortmentRange);
      const options: ExtraPivotOptions = { aggBy, ...rangeData };
      const finalOptions = organizeListDataOptions(options, dataApi);

      return of(fetchAssortmentAddData(finalModelDefn, finalOptions), update(selectionService.removeAllItemsFromCart));
    })
  );
};

export const assortmentAddSearch: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type
      // requestSearchData.type // <- FIXME: adding this causes infinite loop on this handler
    ),
    map(() =>
      maybeGetComponentProps<AssortmentAddBySearchComponent>(state$.value, ConfDefnComponentType.assortmentAddBySearch)
    ),
    filter(inputIsNotNullOrUndefined),
    mergeMap(() => {
      return of(fetchAssortmentAddBySearchData([]));
      // return of(fetchAssortmentAddBySearchData(finalModelDefn, finalOptions));
    })
  );
};
