import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { _p, _self } from 'src/utils/Style/NestUtils';

const itemHeight = '70px';
const datepickerBox = 'datepicker-box';
const listTitle = 'list-title';
const listTitleText = 'list-title-text';
const innerList = 'inner-list';
const item = 'list-item';
const itemText = 'list-item-text';
const selected = 'selected';
const tmp = _self(selected);
const triangleBox = style({
  width: 70,
  height: '70vh',
  display: 'flex',
  alignItems: 'center',
});
const triangle = style({
  margin: '0 auto',
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '50px 0 50px 40px',
  borderColor: 'transparent transparent transparent #dee2e6',
});
const checkboxTitle = style({
  marginRight: 10,
  top: 2,
  position: 'relative',
  textAlign: 'center',
});
const itemList = style({
  width: '80%',
  border: '1px solid #dee2e6',
  height: '70%',
  position: 'absolute',
  margin: 'auto',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  $nest: {
    [_p(listTitle)]: {
      height: '12%',
      width: '100%',
      display: 'table',
      textAlign: 'center',
      padding: '4%',
      $nest: {
        [_p(listTitleText)]: {
          display: 'table-cell',
          verticalAlign: 'middle',
          fontWeight: 'bold',
          fontSize: '1.2rem',
          marginTop: 10,
        },
      },
    },
    [_p(datepickerBox)]: {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      height: '80%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: '8%',
      $nest: {
        ['&>*']: {
          flexGrow: 1,
          flexBasis: 0,
        },
      },
    },
    [_p(innerList)]: {
      height: '80%',
      width: '100%',
      padding: '8%',
      overflow: 'scroll',
      $nest: {
        [_p(item)]: {
          $nest: {
            [tmp]: {
              backgroundColor: TEAL_PRIMARY,
            },
            [_p(itemText)]: {
              display: 'table-cell',
              verticalAlign: 'middle',
              color: 'white',
            },
            [`&.disabled`]: {
              backgroundColor: '#63636308',
              $nest: {
                [_p(itemText)]: {
                  color: '#a5a5a5',
                },
              },
            },
          },
          display: 'table',
          height: itemHeight,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#dee2e6',
          fontSize: '1rem',
          flexGrow: 1,
          flexBasis: 0,
          marginBottom: 20,
        },
      },
    },
  },
});

const outerBox = 'outerbox';

const container = style({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  flexGrow: 1,
  flexBasis: 0,
  $nest: {
    [_p(outerBox)]: {
      flexGrow: 1,
      position: 'relative',
    },
  },
});

export default {
  container,
  item,
  selected,
  itemList,
  itemHeight,
  outerBox,
  itemText,
  listTitle,
  innerList,
  listTitleText,
  triangle,
  triangleBox,
  checkboxTitle,
  datepickerBox,
};
