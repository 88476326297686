import React, { useCallback } from 'react';
import { AccordionActions, Button } from '@material-ui/core';

export const checkedIcon = 'far fa-check-square';
export const uncheckedIcon = 'far fa-square';

export enum WizardSection {
  select,
  upload,
  notices,
}

type WizardActionsProps = {
  section: number;
  onContinue: (section: number) => void;
  onGoBack: (section: number) => void;
  loading?: boolean;
  disabled?: boolean;
};

export const WizardActions = ({
  section,
  onContinue,
  onGoBack,
  loading = false,
  disabled = false,
}: WizardActionsProps) => {
  // determines continue or submit
  const showContinue = section !== WizardSection.notices;
  const showBackButton = section !== WizardSection.select;
  return (
    <AccordionActions classes={{ root: 'button-container' }}>
      {showBackButton && (
        <Button onClick={() => onGoBack(section)} className="apply" variant="contained">
          Back
        </Button>
      )}
      {showContinue && (
        <Button
          className="continue-btn"
          data-qa={`bulkimport-btn-${showContinue ? 'continue' : 'submit'}`}
          onClick={() => onContinue(section)}
          disabled={disabled || loading}
          variant="contained"
        >
          Continue
        </Button>
      )}
    </AccordionActions>
  );
};

type TogglesSelectAllProps = {
  isChecked: boolean;
  onCheckBoxToggle: () => void;
};

export const TogglesSelectAll = ({ isChecked, onCheckBoxToggle }: TogglesSelectAllProps) => {
  return (
    <div className="toggles-select-all" onClick={onCheckBoxToggle}>
      <span className={'toggles-select-all-text'}>Select All?</span>
      <div className={`toggles-select-all-checkbox ${isChecked ? checkedIcon : uncheckedIcon}`} />
    </div>
  );
};

type TabPanelProps = {
  value: number;
  index: number;
};

export const TabPanel: React.FunctionComponent<TabPanelProps> = ({ value, index, children }) => {
  return <div>{value === index && children}</div>;
};

export const useGoForward = (setExpanded: (value: React.SetStateAction<number>) => void, maxSteps: number) => {
  return useCallback(
    (section: number) => {
      if (section <= maxSteps) {
        setExpanded(section + 1);
      }
    },
    [maxSteps, setExpanded]
  );
};

export const useGoBack = (setExpanded: (value: React.SetStateAction<number>) => void) => {
  return useCallback(
    (section: WizardSection) => {
      if (section === 0) {
        // already at the beginning, don't do anything
        return;
      }

      setExpanded(section - 1);
    },
    [setExpanded]
  );
};
