import React, { useEffect, useState } from 'react';
import { TopMembers } from 'src/services/Scope.client';
import { Checkbox } from 'semantic-ui-react';
import './_AnchorRadioSelect.scss';
import { Space } from 'src/space';
import _, { mapValues } from 'lodash';
import { ServerScopeMember } from 'src/state/scope/Scope.types';

export interface AnchorServerScopeMember extends Space<ServerScopeMember[]> {}
export interface AnchorRadioSelectProps {
  anchor: AnchorServerScopeMember;
  labelDimenion: Space<keyof ServerScopeMember>;
  onUpdateAnchorSelections: (newMembers: TopMembers) => void;
}

export default function AnchorRadioSelectV2(props: AnchorRadioSelectProps) {
  const { anchor, onUpdateAnchorSelections: handleUpdateAnchorSelections, labelDimenion } = props;
  // shallow copy scope and set initialState default to first element of each key in object
  const initialState = mapValues(anchor, (ids) => [ids[0]]);
  //filter any object has array.length > 2
  const multiScopeKeys = Object.keys(anchor).filter((key) => anchor[key].length >= 2);
  const [checked, setCheckedValue] = useState(initialState);
  const [value, setValue] = useState(
    mapValues(anchor, (tree, key) => {
      return [tree[0].id];
    })
  );
  const handleCheckbox = (id: string, key: string, element: any) => {
    setValue({ ...value, [key]: [id] });
    setCheckedValue({ ...checked, [key]: [element] });
  };

  useEffect(() => {
    // this function will pass data up to parent for future use
    handleUpdateAnchorSelections(value);
  }, [checked, handleUpdateAnchorSelections, value]);

  const checkboxes = multiScopeKeys.map((key) => {
    return (
      <div className="checkbox-time-group" key={key}>
        <div className="checkbox-group-label">{key.charAt(0).toUpperCase() + key.substring(1)}</div>

        {anchor[key].map((element, idx) => {
          return (
            <Checkbox
              radio={true}
              key={idx}
              label={element[labelDimenion[key]]}
              value={element.id}
              checked={checked[key][0].id === element.id}
              onChange={() => handleCheckbox(element.id, key, element)}
            />
          );
        })}
      </div>
    );
  });

  return <div className="checkbox-group">{checkboxes}</div>;
}
