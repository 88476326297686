import { style } from 'typestyle';
import { viewWidth, rem, px } from 'csx';

const sectionPanel = style({
  display: 'flex',
  alignItems: 'center',
  $nest: {
    '& .panel-icon': {
      minWidth: px(20),
      marginRight: rem(1.5),
    },
  },
});

const sectionParameter = style({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  maxWidth: viewWidth(50),
  marginLeft: rem(6),
  $nest: {
    '& .text-container': {
      display: 'flex',
      alignItems: 'baseline',
    },
    '& .text': {
      marginRight: rem(1),
    },
  },
});

export const accordionContainer = style({
  $debugName: 'accordion-section-container',
  height: '100%',
  overflowY: 'auto',
  padding: '1px', // added so the container doesn't block the box-shadow of the children
});

const toggleContainer = style({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
})

export default {
  sectionPanel,
  sectionParameter,
  accordionContainer,
  toggleContainer,
};
