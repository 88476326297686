import { style } from 'typestyle';
import { negativeTrend, positiveTrend, neutralTrend, fadedNeutral, primaryTextColor } from 'src/common-ui/theme';
import { mediumBreakpoint } from 'src/utils/Style/Theme';
import { rangePx } from 'src/utils/Style/Media';

export const keyFinancialStyle = style({
  fontWeight: 300,
  color: primaryTextColor,
  backgroundColor: '#fafafa',
  width: '25%',
  minWidth: 260,
  padding: '1rem 2rem',
  $nest: {
    [rangePx(0, mediumBreakpoint)]: {
      width: '50%',
    },
    '> header': {
      fontSize: '0.7rem',
      fontWeight: 600,
    },
    '.bottom-container': {
      display: 'flex',
      alignItems: 'center',
      height: '60px',
    },
    '.right-container': {
      minWidth: '95px',
      flex: 1,
    },
    '.right-container-override': {
      flex: 0,
    },
    '.primary': {
      flex: 3,
      fontSize: '1.7rem',
    },
    '.primary-skeleton': {
      flex: 3,
      height: '75px',
      padding: '0',
      marginRight: '35px',
    },
    '.secondary-skeleton': {
      display: 'flex',
      padding: 0,
      margin: 0,
    },
    '.dir': {
      fontSize: '1.2rem',
      margin: '0 0.3rem 0 0',
    },
    '.up': {
      color: positiveTrend,
    },
    '.down': {
      color: negativeTrend,
    },
    '.neutral': {
      color: neutralTrend,
    },
    '.diff-container': {
      textAlign: 'right',
    },
    '.diff': {
      fontSize: '1.2rem',
    },
    '.diff-skeleton': {
      width: '-webkit-fill-available',
    },
    '.arrow-skeleton': {
      width: '20px',
    },
    '.secondary': {
      fontSize: '0.7rem',
      textAlign: 'end',
      color: fadedNeutral,
      fontWeight: 500,
    },
  },
});
