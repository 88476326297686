import { createSelector, OutputSelector } from 'reselect';
import { get } from 'lodash';
import { AppState } from 'src/store';
import { getDataFromCache } from 'src/services/pivotServiceCache';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { filterData } from 'src/utils/Pivot/Filter';
import { AssortmentPublishViewDefns } from './AssortmentPublish.types';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';

type AssortmentPublishDataSelector = OutputSelector<
  AppState,
  BasicPivotItem[],
  (
    data: BasicPivotItem[],
    viewDefns: AssortmentPublishViewDefns,
    search: string,
    flowStatus: number[]
  ) => BasicPivotItem[]
>;

const getData = (state: AppState) => {
  return getDataFromCache(state.pages.assortmentBuild.assortmentPublish)?.tree || [];
};

const getViewDefns = (state: AppState) => {
  return state.pages.assortmentBuild.assortmentPublish.viewDefns;
};

const getFlowStatus = (state: AppState): number[] => {
  return state.subheader.flowStatus;
};

const getSearch = (state: AppState): string => {
  return state.subheader.search;
};

export const processAssortmentPublishData: AssortmentPublishDataSelector = createSelector(
  getData,
  getViewDefns,
  getSearch,
  getFlowStatus,
  (data, viewDefns, search, flowStatus) => {
    const configSearchOrDefafult: string[] = get(viewDefns, 'grid.searchIndexes', externalGridSearchFields);
    const filteredData = filterData(data, search, configSearchOrDefafult, flowStatus);
    return filteredData;
  }
);
