import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined, isScopeDefined } from 'src/utils/Functions/epicsFunctions';
import { get, isEmpty } from 'lodash';
import {
  ConfDefnComponentType,
  EditableOverTimeGridComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';

import {
  fetchOverTimeData,
  updateSelectedItem,
  refreshOvertimeData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.slice';
import { updateSelectedItem as worklistUpdateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { completeItemUndo } from 'src/pages/AssortmentBuild/Planning.slice';

export const flowSheetByStyleOvertimeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateSelectedItem.type, refreshOvertimeData.type, completeItemUndo.type, worklistUpdateSelectedItem.type),
    map(() => maybeGetComponentProps<EditableOverTimeGridComponent>(state$.value, ConfDefnComponentType.flowSheet)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap(({ dataApi, topMembers, defns }) => {
      const { selectedItem } = state$.value.pages.assortmentBuild.flowSheet;
      const worklistSelectedItem = topMembers;
      const currentSelectedItem = selectedItem || worklistSelectedItem || '';

      // we allow a third element to be passed in from `componentProps.defns.models` in flowsheet/pricing,
      // to override the modeldefn, in order to support different pivot runs
      const maybeThirdModel = defns && defns.models ? get(defns.models, '2', undefined) : undefined;

      if (!isEmpty(currentSelectedItem)) {
        return of(fetchOverTimeData(currentSelectedItem, dataApi, maybeThirdModel));
      }

      return of();
    })
  );
};
