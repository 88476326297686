const validValuesCache: {
  [url: string]: unknown[];
} = {};

export function getItemFromCache(url: string) {
  return validValuesCache[url];
}
export function addItemToCache(url: string, values: unknown[]) {
  validValuesCache[url] = values;
}

const vvPromiseCache: {
  [url: string]: Promise<unknown[]>;
} = {};

export function clearCache() {
  for (const prop in validValuesCache) {
    if (validValuesCache.hasOwnProperty(prop)) {
      delete validValuesCache[prop];
    }
  }
  for (const prop in vvPromiseCache) {
    if (vvPromiseCache.hasOwnProperty(prop)) {
      delete vvPromiseCache[prop];
    }
  }
}

export async function getOrAddItemAsyncCache(url: string, prom: () => Promise<unknown[]>) {
  if (vvPromiseCache.hasOwnProperty(url)) {
    return vvPromiseCache[url];
  } else {
    vvPromiseCache[url] = prom();
    return vvPromiseCache[url];
  }
}
