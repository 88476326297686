import React from 'react';
import { getFrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';

// #region Grid Editors
import { RangePickerEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor';
import TabbedReceiptsAdjCalculator from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TabbedReceiptsAdjCalculator';
import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import { StarEditor } from 'src/components/StarEditor/StarEditor';
import { TextValidationEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import { ValidSizesEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidSizesEditor';
// #endregion

// #region Grid Renderers
import { ColorHeaderRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { IconCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/IconCellRenderer';
import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import LifecycleParametersEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/LifecycleParametersEditor';
import SalesAdjustmentEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/SalesAdjustmentEditor';
import { AdornmentsGridRenderer } from 'src/components/Adornments/AdornmentsGridRenderer';
import ImageRendererWithHover from 'src/components/ImageRendererWithHover/ImageRendererWithHover';
import HeatmapRenderer from '../../HeatmapRenderer/HeatmapRenderer';
import { SeverityRenderer } from '../../SeverityRenderer/SeverityRenderer';
import { StarPercentRenderer } from '../../StarPercentRenderer/StarPercentRenderer';
import { StatusIconRenderer } from '../../StatusIconRenderer/StatusIconRenderer';
import ValidValuesRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidValuesRenderer';
import IconWithPopoverTrigger from '../../IconWithPopoverTrigger/IconWithPopoverTrigger';
// #endregion

// #region combined editors/renderers
import CheckboxCellRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/Checkbox';
import TooltipRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import ValidValuesCheckBoxEditor from '../../ValidValuesCheckboxEditor/ValidValuesCheckboxEditor';
// #endregion

// #region custom headers
import { ValidValuesCheckBoxEditorHeader } from '../../ValidValuesCheckboxEditor/ValidValuesCheckboxEditorHeader';
import CheckboxHeaderRenderer from '../../CheckboxHeaderRenderer/CheckboxHeaderRenderer';
import CustomGroupHeader from '../utils/CustomGroupHeader';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import ConfigurableDataModal from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal';
import ArrowValueRenderer from '../../ArrowValueRenderer/ArrowValueRenderer';
// #endregion

type AsyncValidationErrorProps = {
  initial: string;
  invalidValue: string;
  invalidDescription?: string;
};
export const AsyncValidationErrorMessage = (props: AsyncValidationErrorProps) => {
  return (
    <div>
      <p>{`${props.invalidValue} is not available for use.`}</p>
      {props.invalidDescription && <p>{`${props.invalidDescription}`}</p>}
      <p>{`The value was reset to ${props.initial}.`}</p>
      <p>{`Please try with a different value.`}</p>
    </div>
  );
};

export const nonFrameworkComponents = {
  colorHeaderRenderer: ColorHeaderRenderer,
};

const otherFrameworkComponents = getFrameworkComponents();

export const frameworkComponents = {
  ...otherFrameworkComponents,
  imageCellRenderer: ImageCellRenderer,
  validValuesEditor: ValidValuesEditor,
  validValuesRenderer: ValidValuesRenderer,
  rangePickerRenderer: RangePickerRenderer,
  rangePickerEditor: RangePickerEditor,
  checkboxCellRenderer: CheckboxCellRenderer,
  receiptsAdjCalculator: TabbedReceiptsAdjCalculator,
  customGroupHeader: CustomGroupHeader,
  lifecycleParametersEditor: LifecycleParametersEditor,
  salesAdjustmentEditor: SalesAdjustmentEditor,
  validSizesRenderer: ValidSizesRenderer,
  validSizesEditor: ValidSizesEditor,
  iconCellRenderer: IconCellRenderer,
  textValidationEditor: TextValidationEditor,
  integerEditor: IntegerEditor,
  tooltipRenderer: TooltipRenderer,
  severityRenderer: SeverityRenderer,
  starPercentRenderer: StarPercentRenderer,
  starEditor: StarEditor,
  statusIconRenderer: StatusIconRenderer,
  configurableDataModal: ConfigurableDataModal,
  arrowValueRenderer: ArrowValueRenderer,
  heatmapRenderer: HeatmapRenderer,
  gridHeaderCheckbox: CheckboxHeaderRenderer,
  validValuesCheckbox: ValidValuesCheckBoxEditor,
  validValuesCheckboxHeader: ValidValuesCheckBoxEditorHeader,
  imageRendererWithHover: ImageRendererWithHover,
  iconWithPopoverTrigger: IconWithPopoverTrigger,
  adornmentsGridRenderer: AdornmentsGridRenderer,
};
