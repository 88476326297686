import * as React from 'react';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';
import { ItemData } from './FitViewInterfaces';
import styles from './FitViewItem.styles';
import Adornments from 'src/components/Adornments/Adornments';

export default class FitViewItem extends React.PureComponent<ItemData> {
  static TRANSITION = 'top 1.5s, left 1.5s, width 1.5s, height 1.5s, opacity 2s, border-color 0.2s, transform 2s';
  render() {
    const {
      showBorder,
      height,
      width,
      top,
      left,
      isAnimated,
      useSrc,
      renderedImage,
      uid,
      groupIndex,
      index,
      id,
      adornments,
    } = this.props;
    let backgroundImage = '';
    const border = showBorder ? '1px solid rgba(69,182,159,.75)' : '';
    const transition = isAnimated ? FitViewItem.TRANSITION : '';
    let img: JSX.Element | undefined = undefined;
    if (useSrc) {
      img = (
        <CenteredImage
          src={renderedImage}
          width={width}
          height={height}
          extraImgProps={{ 'data-id': uid, 'data-qa': 'fitViewItemImage' }}
        />
      );
    } else {
      backgroundImage = `url(${renderedImage})`;
    }
    return (
      <div
        className={styles.imageContainer}
        data-id={uid}
        data-qa="fitViewItem"
        data-group-index={groupIndex}
        data-index={index}
        style={{
          left,
          top,
          width,
          height,
          border,
          backgroundImage,
          transition,
        }}
      >
        {adornments && (
          <div className={styles.adornmentStyle}>
            <Adornments adornments={adornments} productId={id} />
          </div>
        )}
        {img}
      </div>
    );
  }
}
