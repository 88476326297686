import { UserDetails } from './UserDetails';
import { Lens } from 'monocle-ts';
import { DayJsFromString } from './DayJsFromString';
import { z } from 'zod';

const VERSION_WP = z.literal('wp');
export const VERSION_SAVED = z.literal('save');
const VERSION_DYNAMIC = z.literal('dynamic');
const VERSION_RP = z.literal('rp');
const VERSION_OP = z.literal('op');
const VERSION_LP = z.literal('lp');
const VERSION_SV = z.literal('sv');
export const VERSION_SMART = z.literal('smart');
// types below here may be removed/changed later, need to be dynamic

const ScopeType = z.union([
  VERSION_WP,
  VERSION_SAVED,
  VERSION_DYNAMIC,
  VERSION_RP,
  VERSION_OP,
  VERSION_LP,
  VERSION_SV,
  VERSION_SMART,
  z.string(), // catch all for new ids
]);

// A unique brand for planId
// this adds a little more type safety when shuffling these around
export const zPlanId = z.number().brand<'PlanId'>();
export type PlanId = z.infer<typeof zPlanId>;

export const zSpace = z.record(z.string(), z.string());
export const PlanMetadata = z.object({
  /** The plan's unique identifier */
  id: zPlanId,
  /** The plan's name, can be the magic string 'unnamed' for system plans */
  name: z.string(),
  /** An incrementing number indicating the number of times the plan has been written,  zero indexed */
  mvccCounter: z.number(),
  /** The plan's module */
  app: z.string(), //  TODO: make this dynamic
  /** The plan's space as string:string of top members, usually product, location, time, prodlife */
  space: zSpace, // TODO: make space strongly typed and dynamic
  /** The plan's scope type (also similar to version), wp, rp, op, etc. */
  version: ScopeType,
  /** The plan's current owner, can be 'system' for published corporate plans, in which email/name is null */
  ownedBy: UserDetails,
  /** The plan's original author, should always have a defined email/name */
  authoredBy: UserDetails,
  /** The plan's most recent modifier, usually the user that approved it */
  modifiedBy: UserDetails,
  /** The plan id that this plan was created from, if any */
  createdFrom: z.union([zPlanId, z.null()]),
  /** The timestamp of the fx rate the plan is currently using.
   * This represents the time at which the fx rate was applied to the plan */
  exchangeRateTimestamp: z.union([DayJsFromString, z.null()]),
  /** The timestamp of when the plan was created */
  createdAt: DayJsFromString,
});

export interface PlanMetadata extends z.infer<typeof PlanMetadata> {}
export interface PlanMetadataNarrow extends Pick<PlanMetadata, 'id' | 'version' | 'name'> {}
export const scopeTypeLens = Lens.fromProp<PlanMetadata>()('version');
