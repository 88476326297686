import * as React from 'react';
import * as styles from './Reconciliation.style';

import QuickReconcile from './QuickRecapView/QuickReconcile.container';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { classes } from 'typestyle';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { QuickReconButton } from 'src/services/configuration/codecs/confdefn';

export interface Option {
  dataIndex: string;
  text: string;
  renderer?: string;
}

export interface OptionGroup {
  text: string;
  options: Option[];
}

export type ToggleAction = 'close' | 'apply';

export interface EnabledProps {
  enabled: true;
  quickReconButtonConfig?: QuickReconButton;
  isOpen: boolean | undefined;
  onToggleModal(): void;
  onReset(): void;
}

export interface DisabledProps {
  enabled: false;
}
export interface ConfigureModalProps extends EnabledProps {}

export interface Selection {
  dataIndex: string;
  selections: Option[];
  text: string;
}

export default class ReconcileModal extends React.Component<ConfigureModalProps> {
  constructor(props: ConfigureModalProps) {
    super(props);
  }

  render() {
    if (!this.props.enabled || !this.props.quickReconButtonConfig) {
      return null;
    }

    const { isOpen, onToggleModal, quickReconButtonConfig } = this.props;
    const floorsetApi = {
      url: '/api/assortment/gettrimmedfloorsets',
      params: {
        appName: ASSORTMENT,
        defnId: '',
        aggBy: '',
      },
    };

    return (
      <Dialog
        open={isOpen || false}
        onClose={onToggleModal}
        fullWidth={true}
        scroll={'body'}
        maxWidth={'xl'}
        classes={{
          root: styles.modal,
          paperScrollBody: styles.modalPaper,
        }}
      >
        <DialogTitle classes={{ root: styles.modalTitle }} disableTypography={true}>
          <div className={styles.titleContainer}>
            <div className={classes(styles.leftTitleContainer, styles.modalTitleText)}>Quick Reconcile</div>
            <div className={classes(styles.rightTitleContainer, styles.modalTitleText)}>
              <i className={classes('far fa-times', styles.modalTitleIcon)} onClick={() => onToggleModal()} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: styles.modalContent }}>
          <QuickReconcile quickReconButtonConfig={quickReconButtonConfig} floorsetApi={floorsetApi} />
        </DialogContent>
      </Dialog>
    );
  }
}
