import { ICellRendererParams } from '@ag-grid-community/core';
import { Renderer } from 'src/utils/Domain/Renderer';
import { isNil } from 'lodash';

interface FrameworkS5RendererParams extends ICellRendererParams {
  renderer: keyof typeof Renderer;
}

const NonFrameWorkToS5Renderer = ({ value, renderer }: FrameworkS5RendererParams) => {
  // yeah I know, inputType = renderer here
  if (renderer != null && !isNil(value)) {
    const nonFrameWorkRenderer = Renderer[renderer] as (value: any) => any;
    if (nonFrameWorkRenderer) {
      // If we can't render with thousand, we can't render with any of them.
      // FIXME: STOP COMBINING RENDERERS AND FORMATTERS IN THE SAME OBJECT. PLEASE. PLS.
      // -LOVE Mark. (I have no way of handling non-number renderer at this time. Don't use them).
      const baseRenderCase = Renderer.thousand(value);
      return baseRenderCase.toLowerCase() !== 'nan' ? nonFrameWorkRenderer(value) : '';
    }
  }

  return value;
};

export default NonFrameWorkToS5Renderer;
