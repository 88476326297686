import { isNaN, isNil, isNumber, has, reduce, flow, map, mapValues } from 'lodash';
import {
  ColDef,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { CellRendererSelectorResult } from '@ag-grid-community/core';
import { Renderer } from 'src/utils/Domain/Renderer';
import { ColDefAccumulator, OvertimeColDef } from './OvertimeView.types';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { processApiParams } from '../StyleEdit/StyleEdit.utils';

function createColumnDef({ id, header }: { id: string; header: string }): ColDef {
  return {
    field: id,
    headerName: header,
    valueFormatter: (params: ValueFormatterParams) => {
      if (isNaN(params.value) || isNil(params.data)) {
        return '';
      }
      if (has(params, 'data.formatter')) {
        if (isNil(Renderer[params.data.formatter])) {
          return params.value;
        } else {
          const data = params.data[id];
          const finalData = !isNumber(data) || isNaN(data) ? 0 : data;
          return Renderer[params.data.formatter](finalData);
        }
      }
    },
    valueGetter: (params: ValueGetterParams) => {
      if (params.node?.group) {
        return '';
      } else if (isNil(params.data[id])) {
        return params.data.value;
      } else if (isNumber(params.data[id])) {
        return Number.parseFloat(params.data[id]);
      } else {
        return params.data[id];
      }
    },
    editable: (params) => {
      const parent = params.node.parent;
      if (isNil(parent)) {
        return false;
      }
      return params.node.data.editable;
    },
    cellRendererSelector: (params: ICellRendererParams): CellRendererSelectorResult => {
      if (!params || params.node == null || isNil(params.data)) {
        return (null as unknown) as CellRendererSelectorResult;
      }

      const row: IRowNode = params.node;
      const colInfo = params.colDef as OvertimeColDef;
      const renderer = params.data.formatter;

      switch (renderer) {
        case 'image':
          return {
            component: 'imageCellRenderer',
          };
        case 'imageWithHover':
          return {
            component: 'imageRendererWithHover',
          };

        case 'validValuesCheckbox':
          const availableSelections = colInfo.options ? colInfo.options.map((c) => c.value) : [];
          // If SSG is present, disable checkboxes
          // const ssg = coalesce(this.gridApi?.getValue(ATTR_SSG, row), get(row.data, ATTR_SSG));
          const isEditable = false; // !(ssg && isArray(ssg) && ssg.length > 0);
          return {
            component: 'validValuesCheckbox',
            params: {
              isEditable,
              availableSelections,
              optionsApi: colInfo.dataApi,
              validValuesCache: [], //this.props.validValuesCache,
            },
          };
        case 'icon':
          let value = params.value;
          value = value && value[0] && value[0].value ? value[0].value.id : value;
          const icon = params.data.rendererIcon;

          const rendererParams = {
            icon,
            value,
          };

          return {
            component: 'iconCellRenderer',
            params: rendererParams,
          };
        case 'iconWithPopoverTrigger': {
          return {
            component: 'iconWithPopoverTrigger',
            params: {
              onItemClicked: (item: BasicPivotItem) => {
                // if (this.props.onItemClicked) {
                //   this.props.onItemClicked(item);
                // }
              },
              icon: params.data.rendererIcon,
              dataQa: 'StylePaneTrigger',
            },
          };
        }
        case 'checkbox':
          return {
            component: 'checkboxCellRenderer',
            params: {
              isEditable: true,
            },
          };
        case 'range_picker':
          return {
            component: 'rangePickerRenderer',
            params: colInfo,
          };
        case 'validSizes':
          return {
            component: 'validSizesRenderer',
            params: {
              dataConfig: {
                url: colInfo.dataApi.url,
                params: mapValues(colInfo.dataApi.params, (_v, k) => {
                  return row[k];
                }),
                headers: colInfo.dataApi.headers,
              },
            },
          };
        case 'validValuesRenderer':
          // FIXME: EAS-607, fix configs to be consistent so we only target dataApi and not configApi.
          const api = isNil(colInfo.configApi) ? colInfo.dataApi : colInfo.configApi;
          const dataConfig = !isNil(api) ? processApiParams(api, row) : null;
          return {
            component: 'validValuesRenderer',
            params: {
              dataConfig,
            },
          };
        case 'tooltipRenderer':
          return {
            component: 'tooltipRenderer',
          };
        case 'severityRender':
          return {
            component: 'severityRender',
          };
        case 'starPercentRenderer':
          return {
            component: 'starPercentRenderer',
          };
        case 'starEditor':
          return {
            component: 'starEditor',
          };
        case 'statusIconRenderer':
          return {
            component: 'statusIconRenderer',
          };
        default:
          return (null as unknown) as CellRendererSelectorResult;
      }
    },
  };
}

export function generateColDefs(levels: string[], dataLevel: string, treeData: BasicPivotItem[]): ColDef[] {
  const [topLevelName, bottomLevelName] = levels;
  const accumulator: ColDefAccumulator = {};
  const columnDefs = flow(
    () => treeData,
    (tree) => {
      return reduce(
        tree,
        (acc, i) => {
          if (has(acc, i[topLevelName])) {
            acc[i[topLevelName]].push({ id: i[dataLevel], header: i[bottomLevelName] });
          } else {
            acc[i[topLevelName]] = [{ id: i[dataLevel], header: i[bottomLevelName] }];
          }
          return acc;
        },
        accumulator
      );
    },
    (topLevelObj) => {
      return map(topLevelObj, (weeks, month) => {
        if (isNil(bottomLevelName)) {
          return {
            headerName: month,
            ...createColumnDef({ id: month, header: month }),
          };
        } else {
          return {
            headerName: month,
            children: weeks.map(createColumnDef),
          };
        }
      });
    }
  )();

  return columnDefs;
}
