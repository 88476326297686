/* eslint-disable @typescript-eslint/ban-types */
import { Predicate } from 'src/types/Primitive';
import { get } from 'lodash';

function recurDown<T extends {}>(node: T, parent: T, childrenKey: string, predicate: Predicate<T>) {
  const children = node[childrenKey];
  const clone = { ...(node as any) };
  clone[childrenKey] = [];
  const peers = parent[childrenKey] || [];

  if (children && children.length) {
    const retained = children.filter(predicate);
    let filteredCount = children.length - retained.length;

    for (const child of retained) {
      if (!recurDown(child, clone, childrenKey, predicate)) {
        filteredCount++;
      }
    }
    const shouldRetain = filteredCount !== children.length;

    if (shouldRetain) {
      peers.push(clone);
      parent[childrenKey] = peers;
    }
    return shouldRetain;
  }
  // This node represents a leaf
  peers.push(clone);
  parent[childrenKey] = peers;
  return true;
}

// This walks object-array trees where one and only one of the object's fields represents children.
// Non-leafs are considered "groupings" and will be filtered out if their children are filtered to 0,
// thereby potentially cascading up the tree
export function cascadingFilter<T extends {}, K extends keyof T>(
  root: T,
  predicate: Predicate<T>,
  childrenKey: K = 'children' as any
): T {
  const virtualRoot: T = {} as any;

  if (Array.isArray(root)) {
    // @ts-ignore
    recurDown({ [childrenKey]: root }, virtualRoot, childrenKey as string, predicate);
    // @ts-ignore
    return get(virtualRoot, childrenKey + '[0]' + childrenKey);
  }
  recurDown(root, virtualRoot, childrenKey as string, predicate);
  // @ts-ignore
  return get(virtualRoot, childrenKey + '[0]');
}

type KeyVal = { key: string; value: any };

type Result =
  | ({ type: 'set' } & KeyVal)
  | { type: 'delete' }
  | { type: 'keep' }
  | ({ type: 'setAndKeep' } & KeyVal)
  | undefined;

type OnKeyVal<T> = (key: string, val: T, node: any, path: string[]) => Result;

// Create a new object-array tree from an existing tree, without changing the object-array associations
export function transform<T>(json: any, onKeyVal: OnKeyVal<T>) {
  const root = Array.isArray(json) ? [] : {};
  const stack = [[json, root, []]];
  let next;

  while ((next = stack.pop())) {
    const [node, clone, parentPath] = next;
    const keys = Object.keys(node);
    const sets: KeyVal[] = [];

    for (const key of keys) {
      const val = node[key];
      const path = [...parentPath, key];

      if (typeof val === 'object' && val !== null) {
        const newNode = Array.isArray(val) ? [] : {};
        clone[key] = newNode;
        stack.push([val, newNode, path]);
      } else {
        const result = onKeyVal(key, val, node, path);

        if (result) {
          switch (result.type) {
            case 'set': {
              sets.push(result);
              break;
            }
            // @ts-ignore
            case 'setAndKeep': {
              sets.push(result);
            }
            case 'keep':
              clone[key] = val;
              break;
            case 'delete':
            default:
          }
        } else {
          // if onKeyVal returns undefined just keep the key val as is
          clone[key] = val;
        }
      }
    }
    sets.forEach((keyVal) => (clone[keyVal.key] = keyVal.value));
  }
  return root;
}
