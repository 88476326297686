import React from 'react';
import './_ReportCard.scss';
import { ReportItem } from 'src/services/Reports.types';
import { defaultTo } from 'lodash';
import classNames from 'classnames';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';

export interface ReportItemProps {
  item: ReportItem;
  onDownloadReport: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export default function ReportCard(props: ReportItemProps) {
  const item = props.item;
  const loading = defaultTo(item.loading, false);
  // TODO add a loading spinner and prop
  // TODO improve card styling
  // TODO maybe add some kind of short desc of what's in the report?
  return (
    <div className={classNames('report-card-item', loading ? 'report-loading' : '')} id={`rep-${item.id}`}>
      {loading ? <LoadingMask /> : null}
      <div className="report-card-title">{item.name}</div>
      <div className="report-card-desc">{item.description}</div>
      <div className="report-card-buttons">
        <span className="report-card-button" id={item.id} onClick={props.onDownloadReport}>
          <i className="fas fa-download" />
        </span>
      </div>
    </div>
  );
}
