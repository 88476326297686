import React, { useRef, useState, useCallback } from 'react';
import { isNil } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';
import { generateLineGraphConfig } from './TimeChart.utils';
import { timeChartStyles } from './TimeChart.styles';
import { GraphsOptions, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { visualizeButtonContainer } from 'src/components/Visualize/Visualize.styles';
import { viewDefnToButton } from '../Visualize/MetricLineGraph/MetricLineGraph';

type HighChartsRefObject = {
  /**
   * Chart reference
   */
  chart: Highcharts.Chart;

  /**
   * React reference
   */
  container: React.RefObject<HTMLDivElement>;
};

export interface TimeChartConfig {
  timeLevel: string;
  defn: string;
  graphs: GraphsOptions;
}

interface TimeChartProps {
  viewDefn: TimeChartConfig;
  data: BasicPivotItem[];
  aggBy: string;
}

export const TimeChart = ({ viewDefn, data, aggBy }: TimeChartProps) => {
  const chartRef = useRef<HighChartsRefObject | null>(null);
  const [selectedMetricIndex, setSelectedMetricIndex] = useState(0);

  const onMetricClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event.currentTarget.dataset.index) {
        setSelectedMetricIndex(parseFloat(event.currentTarget.dataset.index));
      }
    },
    [setSelectedMetricIndex]
  );

  if (isNil(viewDefn)) {
    return <div />;
  }

  const chartOptions: Highcharts.Options = generateLineGraphConfig(aggBy, viewDefn, data, selectedMetricIndex);

  return (
    <section className={timeChartStyles}>
      <div className={visualizeButtonContainer}>
        {viewDefn.graphs.primary.map((graphConfig: TenantConfigViewItem, index: number) => {
          return viewDefnToButton(onMetricClick, selectedMetricIndex, graphConfig, index);
        })}
      </div>
      <div className="chart-container">
        <AutoSizer style={{ height: '100%', width: '100%' }}>
          {({ width, height: _height }) => (
            <HighchartsReact options={{ ...chartOptions, chart: { ...chartOptions.chart, width } }} ref={chartRef} />
          )}
        </AutoSizer>
      </div>
    </section>
  );
};
