import { AxiosResponse, AxiosError } from 'axios';
import { toast } from 'react-toastify';

export function serverSuccessInterceptor(response: AxiosResponse<Record<string, any>>) {
  return response;
}

export function serverErrorInterceptor(error: AxiosError): Promise<Record<string, any>> {
  if (error.response && error.response.status === 500) {
    const message: string = 'An error was encountered.' + (error.message ? ` Details: ${error.message}` : '');

    toast.error(message, {
      position: toast.POSITION.TOP_LEFT,
    });
  }
  return Promise.reject(error);
}
