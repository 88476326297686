import { DropdownItemProps } from 'semantic-ui-react';
import { AvailableMembers, TopMembers } from '../../../services/Scope.client';
import { SettingsEntry } from '../../../services/Settings';
import { ServerScope, ServerScopeMember } from '../../../state/scope/Scope.types';
import { WorkingSetContext } from 'src/state/workingSets/workingSets.slice';
import { mapDispatchToProps } from './MfpScopebar.container';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import { Space } from 'src/space';
import { CommandDetail } from 'src/state/scope/codecs/Commands';
import type { ServiceContainer } from 'src/ServiceContainer';
import { WithRouterProps } from 'src/components/higherOrder/withRouter';

export interface ScopebarState {
  loading: boolean;
  isScopeVisible: boolean;
  prevScopeId?: string;
  scopeError: boolean;
}

export type SeedInfoSelections = 'copyPeriod' | 'copyVersion';

export interface SeedInfo {
  copyPeriod?: string;
  copyVersion?: PlanId | string; // PlanId or time member id as string
}

export interface ScopebarProps extends ScopebarDispatch, ScopebarValueProps, WithRouterProps { }


export interface ScopebarValueProps {
  selectedMembers: TopMembers | undefined;
  scopeId: string | undefined;
  availableMembers: AvailableMembers | undefined;
  inSeason: string | undefined;
  module?: string;
  moduleName?:  string;
  scopeConfig: ServerScope | undefined;
  balanceOptions: Record<number, CommandDetail[]>;
  timeMembers?: DropdownItemProps[];
  initialized: boolean;
  settingsByKey: {
    [key: string]: SettingsEntry;
  };
  isFetchingScope: boolean;
  hasEditableRevision: boolean;
  scopeReady: boolean;
  scopeContexts: WorkingSetContext[];
  commentsOpen: boolean;
  anyPlansUnititialized: boolean;
  pendingWrites: number;
  labelDimensions?: Space<keyof ServerScopeMember>;
}

export interface ScopebarDispatch extends ReturnType<typeof mapDispatchToProps> { }

// testing utils
export const openSubmitQaSelector = 'submit-copy-btn-open';
export const openCopyQaSelector = 'eop-copy-btn-open';
export const openVersionQaSelector = 'version-save-btn-open';
export const openImportQaSelector = 'version-import-btn-open';
export const openReseedQaSelector = 'reseed-reseed-btn-open';
export const openCommentsQaSelector = 'scope-comments-btn-open';
export const openScopeSelectQaSelector = 'scope-select-btn-open';
export const titledModalQaSelector = 'scopebar-option-titled-modal';

export type ScopebarModalQaAttributes =
  | typeof openVersionQaSelector
  | typeof openImportQaSelector
  | typeof openReseedQaSelector
  | typeof openScopeSelectQaSelector;
