import React from 'react';
import HighchartsReact from 'highcharts-react-official';

import { MasterDetailChartConfig } from 'src/components/MasterDetailRenderer/MasterDetailRenderer';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { generateMasterDetailChartConfig } from './MasterDetailChart.utils';

type MasterDetailChartProps = {
  parentId: string;
  config: MasterDetailChartConfig;
  data: BasicPivotItem[];
};

export const MasterDetailChart = (props: MasterDetailChartProps) => {
  const { parentId, config, data } = props;
  const chartConfig = generateMasterDetailChartConfig(parentId, config, data);

  return (
    <div style={{ border: '1px solid #dee2e6' }}>
      <HighchartsReact options={chartConfig} />
    </div>
  );
};
