import * as React from 'react';
import { default as Criteria } from '../Criteria/Criteria.container';
import { classes as mergeClasses } from 'typestyle';
import { TextField } from '@material-ui/core';
import { default as classes } from './TargetCreation.styles';
import { default as DatePicker } from '../DatePicking/DatePicker.container';
import { Lens, Prism, Getter } from 'monocle-ts';
import { default as FloorsetSelector } from './FloorsetSelector.container';
import { Props, SelectedTypes, TargetSettingNewSlice } from './TargetCreation.types';
import { targetListLens } from 'src/services/lenses/lenses';
import { PeriodFormReduxSlice } from '../DatePicking/PeriodForm.types';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import Wizard from '../Wizard/Wizard';
import { WizardPage } from '../Wizard/Wizard.types';
import { CriteriaReduxSlice } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { get, isNaN } from 'lodash';
import { TargetListReduxSlice } from '../TargetList/TargetList.types';
import { AppState } from 'src/store';

type State = {
  page: number;
};

const PAGECAP = 3;

export class TargetCreation extends React.Component<Props, State> {
  state: State;
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  componentDidMount() {
    if (this.props.onShowView) {
      this.props.onShowView();
    }
    if (this.props.showOnlySeed) {
      this.setState({
        page: PAGECAP,
      });
    }
  }

  getSteps = () => {
    return ['Select Target Period', 'Select Top Level Criteria', 'Select Target Criteria', 'Select Seed Basis'];
  };

  getStepContent = (pageNum: number) => {
    let innerPage;
    const lens = targetListLens.compose(Lens.fromProp<TargetListReduxSlice>()('targetNew'));
    const { scopeLens } = this.props;
    const loadedPFSlicePrism = Prism.fromPredicate<PeriodFormReduxSlice>(
      (v: PeriodFormReduxSlice) => v.loaded === true
    );

    let parentIdLens: Getter<AppState, string | null>;
    let parentLevelLens: Getter<AppState, string | null>;

    switch (pageNum) {
      case 0: {
        const targetReceipt = lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetReceipt'));
        const targetSales = lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetSales'));
        const lyReceipt = lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lyReceipt'));
        const lySales = lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySales'));
        const targetSelectedDates = lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetSelectedDates'));
        const lySelectedDates = lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySelectedDates'));

        innerPage = (
          <div className={mergeClasses(classes.timeContainer, classes.mainBody)}>
            <div className={classes.timeBox}>
              <div className={classes.innerBox}>
                <header>Select Target Time Period</header>
                <div className={classes.floorsetWrapper}>
                  <span>Select FloorSet: </span>
                  <div className={classes.selectBox}>
                    <FloorsetSelector
                      floorsetsLens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('floorsetConfig'))}
                      lens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetSelectedFloorset'))}
                      productIdLens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('productId'))}
                      salesLens={targetSales.composePrism(loadedPFSlicePrism)}
                      receiptLens={targetReceipt.composePrism(loadedPFSlicePrism)}
                      selectedLens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetSelectedDates'))}
                    />
                  </div>
                </div>
                <fieldset disabled={true} className={classes.disabledFieldset}>
                  <DatePicker
                    lens={targetReceipt}
                    label={'Receipt Start And End: '}
                    classes={classes.datepicker}
                    selectedLens={targetSelectedDates.compose(Lens.fromProp<SelectedTypes>()('receipt'))}
                  />
                </fieldset>
                <fieldset disabled={true} className={classes.disabledFieldset}>
                  <DatePicker
                    lens={targetSales}
                    label={'Sales Start And End: '}
                    classes={classes.datepicker}
                    selectedLens={targetSelectedDates.compose(Lens.fromProp<SelectedTypes>()('sales'))}
                  />
                </fieldset>
              </div>
            </div>
            <div className={classes.timeBox}>
              <div className={classes.innerBox}>
                <header>Select Reference Time Period</header>
                <div className={classes.floorsetWrapper}>
                  <span>Select FloorSet: </span>
                  <div className={classes.selectBox}>
                    <FloorsetSelector
                      floorsetsLens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lyFloorsetConfig'))}
                      lens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySelectedFloorset'))}
                      productIdLens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('productId'))}
                      salesLens={lySales.composePrism(loadedPFSlicePrism)}
                      receiptLens={lyReceipt.composePrism(loadedPFSlicePrism)}
                      selectedLens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySelectedDates'))}
                    />
                  </div>
                </div>
                <DatePicker
                  lens={lyReceipt}
                  label={'Receipt Start And End: '}
                  classes={classes.datepicker}
                  selectedLens={lySelectedDates.compose(Lens.fromProp<SelectedTypes>()('receipt'))}
                  onChange={() =>
                    this.props.voidFloorsetDropdown(
                      lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySelectedFloorset'))
                    )
                  }
                />
                <DatePicker
                  lens={lySales}
                  label={'Sales Start And End: '}
                  classes={classes.datepicker}
                  selectedLens={lySelectedDates.compose(Lens.fromProp<SelectedTypes>()('sales'))}
                  onChange={() =>
                    this.props.voidFloorsetDropdown(
                      lens.compose(Lens.fromProp<TargetSettingNewSlice>()('lySelectedFloorset'))
                    )
                  }
                />
              </div>
            </div>
          </div>
        );
        break;
      }
      case 1: {
        parentIdLens = scopeLens.composeLens(Lens.fromPath<AssortmentScopeSelectorSlice>()(['scope', 'productMember']));
        parentLevelLens = scopeLens.composeLens(
          Lens.fromPath<AssortmentScopeSelectorSlice>()(['scope', 'productLevel'])
        );
        innerPage = (
          <section className={classes.mainBody} key={this.state.page}>
            <Criteria
              key={'topLevelCriteria'}
              scopeLens={scopeLens}
              parentIdLens={parentIdLens}
              parentLevelLens={parentLevelLens}
              lens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('topLevelCriteria'))}
              titles={['SELECT TOP LEVEL', 'SELECT MEMBER']}
            />
          </section>
        );
        break;
      }
      case 2: {
        if (this.props.targetType === 'Product') {
          const parentLevelLensGetter = new Getter<CriteriaReduxSlice, string | null>((s) =>
            get(s, 'selections[0].dataIndex')
          );
          const parentIdLensGetter = new Getter<CriteriaReduxSlice, string | null>((s) =>
            get(s, 'selections[1].dataIndex')
          );
          parentLevelLens = lens
            .compose(Lens.fromProp<TargetSettingNewSlice>()('topLevelCriteria'))
            .composeGetter(parentLevelLensGetter);
          parentIdLens = lens
            .compose(Lens.fromProp<TargetSettingNewSlice>()('topLevelCriteria'))
            .composeGetter(parentIdLensGetter);
        } else {
          parentIdLens = scopeLens.composeLens(
            Lens.fromPath<AssortmentScopeSelectorSlice>()(['scope', 'locationMember'])
          );
          parentLevelLens = scopeLens.composeLens(
            Lens.fromPath<AssortmentScopeSelectorSlice>()(['scope', 'locationLevel'])
          );
        }
        innerPage = (
          <section className={classes.mainBody} key={this.state.page}>
            <Criteria
              key={'targetLevelCriteria'}
              scopeLens={scopeLens}
              parentIdLens={parentIdLens}
              parentLevelLens={parentLevelLens}
              filterByHierarchy={this.props.targetType === 'Product'}
              lens={lens.compose(Lens.fromProp<TargetSettingNewSlice>()('targetLevelCriteria'))}
              titles={['SELECT TARGET LEVEL CRITERIA', 'SELECT TARGET MEMEBERS']}
              showCheckAll={true}
            />
          </section>
        );
        break;
      }
      case 3: {
        let formContent;
        if (this.props.showSeedWeeks) {
          formContent = (
            <DatePicker
              lens={lens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage', 'weeks']))}
              label={'Sales Start And End: '}
              classes={classes.datepicker}
            />
          );
        } else {
          formContent = (
            <TextField
              id="amount"
              className={''}
              value={this.props.amount}
              label={'Input Value'}
              onChange={(event) => {
                if (this.props.updateAmount) {
                  this.props.amount;
                  const parsedValue = parseFloat(event.target.value);
                  const finalValue = isNaN(parsedValue) ? undefined : parsedValue;
                  this.props.updateAmount(finalValue);
                }
              }}
            />
          );
        }
        innerPage = (
          <section className={classes.mainBody} key={this.state.page}>
            <Criteria
              lens={lens.compose(Lens.fromPath<TargetSettingNewSlice>()(['seedPage', 'criteria']))}
              getOptions={this.props.getSeedOptions}
              titles={['SELECT SEED PLAN', 'SELECT SEED METRIC']}
              datepicker={formContent}
            />
          </section>
        );
        break;
      }
      default:
        innerPage = (
          <div className={classes.mainBody}>
            {' '}
            You&apos;ve reached this page in error. Please complain to John Griffing.
          </div>
        );
    }
    return innerPage;
  };

  validationTargetPeriod = () => {
    const { targetReceiptStartWeek, lyReceiptStartWeek, targetSalesStartWeek, lySalesStartWeek } = this.props;

    if (targetReceiptStartWeek && lyReceiptStartWeek && targetSalesStartWeek && lySalesStartWeek) {
      return true;
    } else {
      return false;
    }
  };

  validationTopLevelCriteria = () => {
    const { topLevelSelections } = this.props;

    if (Array.isArray(topLevelSelections)) {
      if (topLevelSelections.length >= 2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  validationTargetLevelCriteria = () => {
    const { targetLevelSelections } = this.props;

    if (Array.isArray(targetLevelSelections)) {
      if (targetLevelSelections.length >= 2) {
        if (Array.isArray(targetLevelSelections[1])) {
          const selections: any = targetLevelSelections[1];
          if (selections.length === 0) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  nextButtonFunc = () => {
    if (this.props.setConfigurations) {
      this.props.setConfigurations(this.props.targetType);
    }
  };

  modalFunc = () => {
    if (this.props.checkIfTargetExists) {
      const key = `${this.props.topLevelSelections[0].dataIndex}_${this.props.topLevelSelections[1].dataIndex}_${this.props.targetLevelSelections[0].dataIndex}_${this.props.selectedFloorset.selectedFloorset}`;
      return this.props.checkIfTargetExists(key, 'WP', this.props.targetType);
    } else {
      return undefined;
    }
  };

  render() {
    const wizardOptions: WizardPage[] = [
      {
        title: 'Select Target Period',
        content: this.getStepContent(0),
        validation: this.validationTargetPeriod,
      },
      {
        title: 'Select Top Level Criteria',
        content: this.getStepContent(1),
        validation: this.validationTopLevelCriteria,
      },
      {
        title: 'Select Target Criteria',
        content: this.getStepContent(2),
        validation: this.validationTargetLevelCriteria,
      },
      {
        title: 'Select Seed Basis',
        content: this.getStepContent(3),
      },
    ];
    const modal = {
      showModalFunc: this.modalFunc,
    };

    return (
      <Wizard
        closeModal={this.props.closeModal}
        header={'Create New Target'}
        pages={wizardOptions}
        submitFunc={this.nextButtonFunc}
        modal={modal}
      />
    );
  }
}

export default TargetCreation;
