import { style } from 'typestyle';

export const MINIMUM_GROUP_BOX_WIDTH = 95;
const FitViewGroup = style({
  $debugName: 'FitViewGroup',
  display: 'block',
  position: 'relative',
  top: 0,
  left: 0,
  height: '100%',
  borderLeft: '1px solid #ddd',
  marginLeft: '1px',
  flexGrow: 1,
  flexBasis: 0,
  minWidth: 72,
  $nest: {
    '&:last-child': {
      borderRight: '1px solid #ddd',
    },
  },
});
const headerContainer = style({
  $debugName: 'headerContainer',
  textAlign: 'center',
  padding: '10px',
  fontSize: '12px',
  margin: 'auto',
  borderTop: '#ddd 1px solid',
});

const header = style({});

const subheaderContainer = style({
  $debugName: 'subheaderContainer',
  display: 'inline-block',
});

const subheader = style({
  $debugName: 'subheader',
  display: 'inline-block',
  margin: '3px 0',
  padding: '0 5px',
});

const subheaderLast = style({
  $debugName: 'subheaderLast',
});

const subheaderName = style({
  $debugName: 'subheaderName',
  display: 'inline-block',
  fontWeight: 600,
});

const subheaderValue = style({
  $debugName: 'subheaderValue',
  display: 'inline-block',
});

export default {
  FitViewGroup,
  header,
  headerContainer,
  subheader,
  subheaderLast,
  subheaderName,
  subheaderValue,
  subheaderContainer,
};
