import { Dispatch } from 'redux';
import Favorites, { FavoritesEntry } from '../../services/Favorites';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { AppDispatch, AppState, AppThunkDispatch } from 'src/store';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { isNil } from 'lodash';

export interface FavoritesState {
  loaded: boolean;
  favorites: FavoritesEntry[];
}

export const favoritesSlice = createSlice({
  name: 'favorites_state',
  initialState: { favorites: [], loaded: false } as FavoritesState,
  reducers: {
    REQUEST_FAVORITES: (state) => ({ ...state, favorites: [], loaded: false }),
    // eslint-disable-next-line max-len
    RECEIVED_FAVORITES: (state, action: PayloadAction<FavoritesEntry[]>) => ({
      ...state,
      favorites: action.payload,
      loaded: true,
    }),
  },
});
export const { REQUEST_FAVORITES, RECEIVED_FAVORITES } = favoritesSlice.actions;

export function loadFavorites(axios: AxiosInstance) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const currentModule = getMfpModule(getState())?.siloId;
    if (currentModule) {
      dispatch(REQUEST_FAVORITES());
      return new Favorites(axios).ajaxLoad(currentModule).then((entries) => {
        return dispatch(RECEIVED_FAVORITES(entries));
      });
    }
  };
}

export function createFavorite(axios: AxiosInstance, fav: FavoritesEntry) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const currentModule = getMfpModule(getState())?.siloId;
    if (currentModule) {
      return new Favorites(axios).create(fav, currentModule).then((updatedFavorites) => {
        dispatch(RECEIVED_FAVORITES(updatedFavorites));
      });
    }
  };
}

export function mutateFavorite(axios: AxiosInstance, fav: FavoritesEntry) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const currentModule = getMfpModule(getState())?.siloId;
    if (currentModule) {
      return new Favorites(axios).updateFavorite(fav, currentModule).then((updatedFavorites) => {
        dispatch(RECEIVED_FAVORITES(updatedFavorites));
      });
    }
  };
}

export function deleteFavorite(axios: AxiosInstance, key: string) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const currentModule = getMfpModule(getState())?.siloId;
    if (currentModule) {
      return new Favorites(axios).delete(key, currentModule).then((updatedFavorites) => {
        dispatch(RECEIVED_FAVORITES(updatedFavorites));
      });
    }
  };
}

export default favoritesSlice.reducer;
