import { AppState, AppThunkDispatch } from 'src/store';
import { optimisticSetLockState, forceRefreshGrid } from '../../../state/scope/Scope.actions';
import { AgPivotValueProps, AgPivotOwnProps } from './AgPivot.types';
import { MapStateToProps } from 'react-redux';
import { receiveRefreshGrid, updateGridAsyncState } from 'src/state/scope/Scope.slice';
import { GridAsyncState, getScopeReadyData } from 'src/state/scope/Scope.types';
import { extractNavPath } from 'src/pages/NavigationShell/navigationUtils';
import { isSome } from 'fp-ts/lib/Option';

export const mapStateToProps: MapStateToProps<AgPivotValueProps, AgPivotOwnProps, AppState> = (
  state: AppState,
  ownProps
): AgPivotValueProps => {
  let tempPivotName = 'pivot';
  const maybeMatchHash = extractNavPath(window.location, 'hash');
  if (isSome(maybeMatchHash)) {
    // should be view id
    // FIXME add mfp support to work list
    tempPivotName = isSome(maybeMatchHash) ?  maybeMatchHash.value[3].split('?')[0] : tempPivotName;
  }
  const maybeScopeReadyData = getScopeReadyData(state.mfpScope);

  return {
    cellAdornments: state.settings.cellAdornments,
    settings: state.settings.entriesByKey,
    advisoryClasses: state.settings.advisoryClasses,
    // TODO: Revisit this once refactoring isn't different
    // If we are not in a readyish state, we cannot force a refresh
    forceRefreshGrid: maybeScopeReadyData?.forceRefreshGrid || false,
    levels: maybeScopeReadyData?.mainConfig.levels,
    // this is here as a signal to redux that two scope states are non-equal
    // so that in the event the scope flickers to a busy state, the force refresh won't get eaten
    scopeStatus: ownProps.alternateScopeStatus ? ownProps.alternateScopeStatus : state.mfpScope,
    pivotName: tempPivotName,
  };
};

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    onDataModification: () => {
      // TODO: add a new  "gridDirty" action that other components can listen to
      return dispatch(forceRefreshGrid());
    },
    updateLockState: (scopeId: string, newLockState: boolean) => {
      return dispatch(optimisticSetLockState(scopeId, newLockState));
    },
    onCompleteRefreshGrid: () => {
      dispatch(receiveRefreshGrid());
    },
    onUpdateGridAsyncState: (newState: GridAsyncState) => {
      dispatch(updateGridAsyncState(newState));
    },
  };
};
