import * as React from 'react';
import { Stepper, Step, StepLabel, StepContent, DialogTitle } from '@material-ui/core';
import { default as classes } from './Wizard.styles';
import { WizardProps, WizardState } from './Wizard.types';
import Modal from '@material-ui/core/Modal';
import * as _ from 'lodash';
import * as styles from 'src/components/Reconcilation/Reconciliation.style';
import { classes as tclasses } from 'typestyle';

export default class Wizard extends React.Component<WizardProps, WizardState> {
  constructor(props: WizardProps) {
    super(props);
    this.state = {
      page: 0,
      modalOpen: false,
      modalText: '',
    };

    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  isPageValid() {
    const page = this.props.pages[this.state.page];
    if (page.validation) {
      // @ts-ignore ????
      if (page.validation()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  onSubmitClick() {
    if (this.props.modal && this.props.modal.showModalFunc) {
      const showModal = this.props.modal.showModalFunc();
      if (showModal) {
        showModal.then((value) => {
          if (_.isString(value)) {
            this.toggleModal(value);
          } else {
            this.props.submitFunc();
          }
        });
      }
    } else {
      this.props.submitFunc();
    }
  }

  toggleModal(modalText?: string) {
    const newState = {
      modalOpen: !this.state.modalOpen,
      modalText: '',
    };
    if (modalText) {
      newState.modalText = modalText;
    }
    this.setState(newState);
  }

  render() {
    let disableNext = true;
    if (this.isPageValid()) {
      disableNext = false;
    }
    const modal = this.props.modal ? (
      <Modal aria-labelledby="wizard-modal" open={this.state.modalOpen}>
        <div className={classes.modalContainer}>
          <div className={classes.modalBody}>
            <p style={{ fontSize: 24 }}>{this.state.modalText}</p>
            <footer className={classes.modalFooter}>
              <div className={classes.wizardButtonGroup}>
                <button className={classes.wizardButton} onClick={() => this.toggleModal()}>
                  No
                </button>
                <button className={classes.wizardButton} onClick={() => this.props.submitFunc()}>
                  Yes
                </button>
              </div>
            </footer>
          </div>
        </div>
      </Modal>
    ) : (
      undefined
    );

    return (
      <article className={classes.container}>
        <DialogTitle classes={{ root: styles.modalTitle }} disableTypography={true}>
          <div className={styles.titleContainer}>
            <div className={tclasses(styles.leftTitleContainer, styles.modalTitleText)}>Create New Target</div>
            <div className={tclasses(styles.rightTitleContainer, styles.modalTitleText)}>
              {this.props.modal && (
                <i
                  className={tclasses('far fa-times', styles.modalTitleIcon)}
                  onClick={() => this.props.closeModal()}
                />
              )}
            </div>
          </div>
        </DialogTitle>
        <div className={classes.body}>
          <section className={classes.stepping}>
            <Stepper style={{ display: 'inline-block' }} activeStep={this.state.page} orientation="vertical">
              {this.props.pages.map((page, pageNum) => {
                return (
                  <Step key={pageNum}>
                    <StepLabel>{page.title}</StepLabel>
                    <StepContent>
                      <div />
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          </section>
          <section className={classes.content}>
            {this.props.pages[this.state.page].content}
            <footer
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-end',
              }}
            >
              <div className={classes.wizardButtonGroup}>
                <button
                  className={classes.wizardButton}
                  hidden={this.state.page < 1} // || this.props.showOnlySeed}
                  onClick={() =>
                    this.setState({
                      page: this.state.page - 1,
                    })
                  }
                >
                  {'BACK'}
                </button>
                <button
                  className={tclasses(classes.wizardButton, 'primary')}
                  hidden={this.state.page >= this.props.pages.length - 1}
                  onClick={() =>
                    this.setState({
                      page: this.state.page + 1,
                    })
                  }
                  disabled={disableNext}
                >
                  {'APPLY'}
                </button>
                <button
                  className={tclasses(classes.wizardButton, 'primary')}
                  hidden={this.state.page < this.props.pages.length - 1}
                  onClick={() => this.onSubmitClick()}
                >
                  {'SUBMIT'}
                </button>
              </div>
            </footer>
          </section>
          {modal}
        </div>
      </article>
    );
  }
}
