import { TenantConfigViewData } from 'src/dao/tenantConfigClient';

/**
 * @deprecated
 *
 * please use src/components/Configure.ts:parseConfigureConfig. It allows for generic columns of any size and name
 * */
export function defaultsToAggBys(viewData: TenantConfigViewData) {
  if (viewData.defaults) {
    // Derp
    const { first, second, third, fourth } = viewData.defaults;
    return [first, second, third, fourth].filter((i) => i);
  }
  return [];
}
