import React, { useCallback, useRef, useState } from 'react';
import { FavoritesButton, FavoritesList } from './FavoritesMenu';
import { SaveConfirmationModal } from 'src/components/Modals/SaveConfirmationModal';
import { DeleteType, DeleteConfirmationModal } from 'src/components/Modals/DeleteConfirmationModal';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { findIndex, isNil, isEmpty, set } from 'lodash';
import {
  clearActiveAssortmentFavorite,
  clearFavoriteActives,
  deleteFavorite,
  postAssortmentFavorite,
  postFavorite,
  setActiveAssortmentFavorite,
  setFavoriteActive,
} from './Favorites.client';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { GroupBySlice, PareDownSlice, SortBySlice } from '../Subheader.slice';
import { BasicItem } from 'src/types/Scope';
import { Validator } from 'src/utils/Validators/Validator';
import {
  getFavoriteConfigOriginalKeys,
  getFavoriteConfigWithoutKeys,
} from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { FilterModelType } from 'src/components/Sidenav/SideNav.slice';
import { ColumnState } from '@ag-grid-community/core';
import {
  FavoriteResponseItem,
  FavoriteListItemStorage,
  FavoriteListItem,
  DEFAULT_FAVORITE_ID,
  AssortmentFavorite,
  FavoritableComponent,
  ValidatedFavoriteResponse,
  ValidatedAssortmentFavorite,
  ApplyFavoriteOptions,
  FavoriteSubheader,
} from 'src/components/Subheader/Favorites/Favorites.types';
import {
  getCardFavorite,
  getCompanionFavorite,
  getGridFavorite,
  getSubheaderDropdownSelection,
} from 'src/components/Subheader/Favorites/Favorites.utils';
import { BasicViewItem } from 'src/services/configuration/codecs/viewdefns/general';

type SubheaderControlHandlers = {
  setGroupBySelection(value: number | null | undefined): void;
  setSortBySelection(value: number | null | undefined): void;
  setSortByDirection(): void;
  setPareDownSelections?(value: TenantConfigViewItem[]): void;
  setCountLimit(value: number): void;
};

export type SubheaderControlValues = {
  groupBy: GroupBySlice;
  sortBy: SortBySlice;
  pareDown: PareDownSlice;
  showCountLimit: boolean | undefined;
  countLimit: number | undefined;
  countLimitOptions: number[] | undefined;
  countLimitDefault: number | undefined;
  groupByDefn: TenantConfigViewData | undefined;
  sortByDefn: TenantConfigViewData | undefined;
  pareDownDefn: TenantConfigViewData | undefined;
  flowStatus: number[];
  altFlowStatus: number[];
  search: string | undefined;
  altSearch: string | undefined;
};

interface FavoritesProps extends FavoritableComponent {
  viewConfigurator: ViewConfiguratorModalProps;
  getFavorites: (defnId: string, validVersion: number) => void;
  setFavorites: (list: ValidatedFavoriteResponse[]) => void;
  subheaderValues: SubheaderControlValues;
  gridFilterModel: FilterModelType;
  gridColumnState: ColumnState[];
  resetGridModel: () => void;

  /** @deprecated */
  favoritesList: FavoriteResponseItem[] | undefined;
  /** @deprecated */
  favoritesSaveOverride: FavoriteListItemStorage | undefined;
  /** @deprecated */
  getFavoritesList: (defnId: string) => void;
  /** @deprecated */
  setFavoritesList: (list: FavoriteResponseItem[]) => void;
  /** @deprecated */
  subheaderHandlers: SubheaderControlHandlers;
  /** @deprecated */
  gridSortModel: ColumnState[];
  /** @deprecated */
  onFavoriteSave: () => Promise<TenantConfigViewData>;
}

export const Favorites = ({
  favoriteType,
  favoriteDefnId,
  favoriteOverrides,
  favoriteViewDefnUpdater,
  getFavorites,
  viewConfigurator,
  favoritesList = [],
  getFavoritesList,
  setFavoritesList,
  favoritesSaveOverride,
  subheaderHandlers,
  subheaderValues,
  gridFilterModel,
  gridSortModel,
  gridColumnState,
  onFavoriteSave,
}: FavoritesProps) => {
  const favoritesButtonRef = useRef();

  const [listOpen, setListOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState<FavoriteListItem>({} as FavoriteListItem);
  const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
  const validVersion = (viewConfigurator.unmodifiedViewDefn as { version: number })?.version || 1;

  const onFavoritesClick = useCallback(
    (elem) => {
      favoritesButtonRef.current = elem;
      setListOpen(!listOpen);
    },
    [listOpen]
  );

  const onListClose = useCallback(() => setListOpen(false), []);

  const onListSave = useCallback(() => {
    setSaveModalOpen(true);
    setListOpen(false);
  }, []);

  const onListDelete = useCallback((favItem: FavoriteListItem) => {
    setListOpen(false);
    setDeleteModalOpen(true);
    setDeleteItem(favItem);
  }, []);

  const onApply = useCallback(
    async (favItem: ValidatedAssortmentFavorite) => {
      const options: ApplyFavoriteOptions = {
        validVersion,
      };

      if (favItem.id === DEFAULT_FAVORITE_ID) {
        const { pareDown, countLimitDefault, sortBy, groupBy } = subheaderValues;

        // keep server in sync with favorite change
        await clearActiveAssortmentFavorite(favoriteDefnId);
        const defaultSubheader: FavoriteSubheader = {
          groupByDataIndexSelection: getSubheaderDropdownSelection(
            groupBy.defaultSelection,
            groupBy.options as BasicViewItem[]
          ),
          configureDataIndexSelections: viewConfigurator.defaultConfigureSelections?.map((cs) => cs.dataIndex) || [],
          sortByDataIndexSelection: getSubheaderDropdownSelection(
            sortBy.defaultSelection,
            sortBy.options as BasicViewItem[]
          ),
          sortByDirection: 'desc',
          pareDownValueSelections: pareDown.defaultSelections.map((pd) => pd.value || pd.dataIndex),
          flowStatusSelections: [],
          limitSelection: countLimitDefault || null,
        };

        // TODO: remove conditional, will not be optional once all components favorite's are updated
        if (!isNil(favoriteViewDefnUpdater)) {
          favoriteViewDefnUpdater({
            ...options,
            isDefault: true,
            defaultSubheader,
          });
        }
      } else {
        // keep server in sync with favorite change
        await setActiveAssortmentFavorite(favoriteDefnId, favItem.id);

        // TODO: remove conditional, will not be optional once all components favorite's are updated
        if (!isNil(favoriteViewDefnUpdater)) {
          favoriteViewDefnUpdater(options);
        }
      }
      setListOpen(false);
    },
    [favoriteDefnId, favoriteViewDefnUpdater, subheaderValues, viewConfigurator, validVersion]
  );

  const onApplyFavorite = useCallback(
    async (favItem: FavoriteListItem, key: string) => {
      const {
        setGroupBySelection,
        setSortBySelection,
        setSortByDirection,
        setPareDownSelections,
        setCountLimit,
      } = subheaderHandlers;
      const {
        pareDown,
        pareDownDefn,
        showCountLimit,
        countLimitDefault,
        countLimitOptions,
        sortBy,
        sortByDefn,
        groupBy,
        groupByDefn,
      } = subheaderValues;

      if (favItem.config) {
        const finalConfig = getFavoriteConfigOriginalKeys(favItem.config, viewConfigurator.unmodifiedViewDefn as any);
        set(favItem, 'config', finalConfig);
        set(favItem, 'config.isDefault', favItem.id === DEFAULT_FAVORITE_ID);
      }

      if (favItem.id === DEFAULT_FAVORITE_ID) {
        // Check if applying 'Default View' and add page specific defaults
        if (pareDownDefn && pareDown && pareDown.defaultSelections) {
          set(favItem, 'pareDownSelections', pareDown.defaultSelections);
        }
        if (viewConfigurator.defaultConfigureSelections) {
          set(favItem, 'configurationSelections', viewConfigurator.defaultConfigureSelections);
        }
        if (countLimitDefault) {
          set(favItem, 'limitSelection', countLimitDefault);
        }
        if (sortByDefn && sortBy && sortBy.defaultSelection) {
          set(favItem, 'sortBySelection', sortBy.defaultSelection);
        }
        if (groupByDefn && groupBy && groupBy.defaultSelection) {
          set(favItem, 'groupBySelection', groupBy.defaultSelection);
        }
        const favId = viewConfigurator.viewConfig.$id || viewConfigurator.viewConfig.id;
        await clearFavoriteActives(favId);
        // Cheating instead of getting new favorites again
        setFavoritesList(favoritesList.map((x) => ({ ...x, active: false })));
      } else {
        await setFavoriteActive(viewConfigurator, favItem);
        // Cheat the new active in, as the endpoint doesn't return anything but we know it worked
        const index = favoritesList.findIndex((x) => x.key == key);
        const newList = favoritesList.map((x, i) => ({ ...x, active: i == index }));
        setFavoritesList(newList);
      }

      if (viewConfigurator && viewConfigurator.updateConfig) {
        if (favoritesSaveOverride) {
          viewConfigurator.updateConfig({
            ...favItem.config,
            ...favItem,
          } as TenantConfigViewData);
        } else {
          viewConfigurator.updateConfig(favItem.config as TenantConfigViewData);
        }
      }

      if (viewConfigurator && viewConfigurator.updateConfiguration && favItem.configurationSelections) {
        viewConfigurator.updateConfiguration(favItem.configurationSelections);
      }

      if (groupByDefn && !isEmpty(favItem.groupByDataIndexSelection)) {
        const selection = findIndex(
          groupBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === favItem.groupByDataIndexSelection
        );
        set(favItem, 'groupBySelection', selection);
        setGroupBySelection(favItem.groupBySelection);
      } else if (groupByDefn && !isNil(favItem.groupBySelection) && favItem.groupBySelection > -1) {
        setGroupBySelection(favItem.groupBySelection);
      } else if (groupByDefn) {
        const selection = findIndex(
          groupBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === groupByDefn.default
        );
        set(favItem, 'groupBySelection', selection);
        setGroupBySelection(favItem.groupBySelection != -1 ? favItem.groupBySelection : null);
      }

      if (sortByDefn && !isEmpty(favItem.sortByDataIndexSelection)) {
        const selection = findIndex(
          sortBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === favItem.sortByDataIndexSelection
        );
        set(favItem, 'sortBySelection', selection);
        setSortBySelection(selection);
      } else if (sortByDefn && !isNil(favItem.sortBySelection) && favItem.sortBySelection > -1) {
        setSortBySelection(favItem.sortBySelection);
      } else if (sortByDefn) {
        const selection = findIndex(
          sortBy.options,
          (option: TenantConfigViewItem) => option.dataIndex === sortByDefn.default
        );
        set(favItem, 'sortBySelection', selection);
        setSortBySelection(favItem.sortBySelection != -1 ? selection : null);
      }

      if (!isNil(favItem.sortByDirection) && favItem.sortByDirection != sortBy.direction) {
        setSortByDirection();
      }

      if (pareDownDefn && favItem.pareDownSelections && setPareDownSelections) {
        setPareDownSelections(favItem.pareDownSelections);
      }

      if (showCountLimit) {
        if (favItem.limitSelection) {
          setCountLimit(favItem.limitSelection);
        } else {
          const defaultIndex = (countLimitOptions || []).findIndex((option) => option === countLimitDefault);
          setCountLimit((countLimitOptions || [])[defaultIndex]);
        }
      }

      setListOpen(false);
    },
    [favoritesList, favoritesSaveOverride, setFavoritesList, subheaderHandlers, subheaderValues, viewConfigurator]
  );

  const onSave = useCallback(
    async ({ id, name }: BasicItem) => {
      const propViewConfig = viewConfigurator.viewConfig;
      const orderedGridConfig = await onFavoriteSave();
      const mergedConfig: TenantConfigViewData = set(
        propViewConfig,
        'view',
        // Need a fallback here for views that do not have a view configurator
        !isNil(orderedGridConfig) ? (orderedGridConfig as unknown) as TenantConfigViewItem[] : propViewConfig.view
      );
      const config = getFavoriteConfigWithoutKeys(mergedConfig);
      const { sortBy, groupBy } = subheaderValues;

      let newFavItem: FavoriteListItem = {
        id,
        name,
        config,
        sortBySelection:
          subheaderValues.sortBy && !isNil(subheaderValues.sortBy.selection)
            ? subheaderValues.sortBy.selection
            : undefined,
        groupBySelection:
          subheaderValues.groupBy && !isNil(subheaderValues.groupBy.selection)
            ? subheaderValues.groupBy.selection
            : undefined,
        sortByDataIndexSelection: sortBy.selection ? sortBy.options[sortBy.selection].dataIndex : undefined,
        groupByDataIndexSelection: groupBy.selection
          ? groupBy.options[groupBy.selection]?.dataIndex || undefined
          : undefined,
        sortByDirection:
          subheaderValues.sortBy && subheaderValues.sortBy.direction ? subheaderValues.sortBy.direction : undefined,
        configurationSelections: viewConfigurator.configureSelections,
        pareDownSelections: subheaderValues.pareDown && subheaderValues.pareDown.selections,
        limitSelection: subheaderValues.countLimit,
        companionData: viewConfigurator.companionData,
        gridFilterModel,
        gridSortModel,
      };

      newFavItem = {
        ...newFavItem,
        ...favoritesSaveOverride,
      };

      await postFavorite(viewConfigurator, newFavItem, true);
      getFavoritesList(favId || '');
      setSaveModalOpen(false);
      return;

      // TODO: skipping until ready to turn on new favorites logic
      const { pareDown, countLimit = null, flowStatus } = subheaderValues;

      const groupByDataIndexSelection = getSubheaderDropdownSelection(
        groupBy.selection,
        groupBy.options as BasicViewItem[]
      );
      const sortByDataIndexSelection = getSubheaderDropdownSelection(
        sortBy.selection,
        sortBy.options as BasicViewItem[]
      );
      const sortByDirection = !isNil(sortBy.direction) ? sortBy.direction : 'desc';
      const pareDownValueSelections = pareDown.selections.map((pd) => pd.value || pd.dataIndex);
      const limitSelection = countLimit || null;

      const nextFavorite: AssortmentFavorite = {
        type: favoriteType,
        version: validVersion,
        id,
        name,
        subheader: {
          groupByDataIndexSelection,
          configureDataIndexSelections:
            viewConfigurator?.configureSelections?.map((selection) => selection.dataIndex) || [],
          sortByDataIndexSelection,
          sortByDirection,
          flowStatusSelections: flowStatus,
          limitSelection,
          pareDownValueSelections,
          ...favoriteOverrides?.subheader,
        },
        card: getCardFavorite(favoriteType, viewConfigurator.viewConfig),
        grid: getGridFavorite(favoriteType, viewConfigurator.viewConfig, gridColumnState, gridFilterModel),
        companion: getCompanionFavorite(favoriteType, favoriteOverrides?.companion, subheaderValues),
      };

      await postAssortmentFavorite(favoriteDefnId, nextFavorite);
      getFavorites(favoriteDefnId, validVersion);
      setSaveModalOpen(false);
    },
    [
      favId,
      favoriteDefnId,
      favoriteOverrides?.companion,
      favoriteOverrides?.subheader,
      favoriteType,
      favoritesSaveOverride,
      getFavorites,
      getFavoritesList,
      gridColumnState,
      gridFilterModel,
      gridSortModel,
      onFavoriteSave,
      subheaderValues,
      validVersion,
      viewConfigurator,
    ]
  );

  const onDelete = useCallback(
    async (fav: FavoriteListItem) => {
      const key = favoritesList.find((x) => x.favorite_name == fav.id)?.key;
      if (isNil(key) || isNil(favId)) {
        return;
      }
      await deleteFavorite(key);
      getFavoritesList(favId);
      setDeleteModalOpen(false);
    },
    [favId, favoritesList, getFavoritesList]
  );

  const isFavoriteActive = favoritesList.some((fav) => fav.active);

  return (
    <React.Fragment>
      <FavoritesButton isFavoriteActive={isFavoriteActive} onClick={onFavoritesClick} />
      {listOpen && (
        <FavoritesList
          unmodifiedViewDefn={viewConfigurator.unmodifiedViewDefn as TenantConfigViewData}
          defaultCompanionData={viewConfigurator.defaultCompanionData}
          favItems={favoritesList}
          onClose={onListClose}
          onClickSave={onListSave}
          onClickDelete={onListDelete}
          onApplyFavorite={onApplyFavorite}
          open={listOpen}
          anchorEl={favoritesButtonRef.current}
        />
      )}
      {saveModalOpen && (
        <SaveConfirmationModal
          isOpen={saveModalOpen}
          onSave={onSave}
          onClose={() => setSaveModalOpen(false)}
          nameValidator={Validator.name}
        />
      )}
      {deleteModalOpen && (
        <DeleteConfirmationModal
          item={{ favorite: deleteItem }}
          isOpen={deleteModalOpen}
          onDelete={(fav?: FavoriteListItem) => fav && onDelete(fav)}
          onClose={() => setDeleteModalOpen(false)}
          deleteType={DeleteType.favorite}
        />
      )}
    </React.Fragment>
  );
};
