import { style } from 'typestyle';

const DEFAULT_ROW_HEIGHT = 80;

const trendDetailsContainer = style({
  $debugName: 'trend-details-container',
  height: 'calc(100% - 25px)',
  $nest: {
    '& .ag-cell': {
      height: '100%',
    },
    '& .ag-row-group': {
      fontWeight: 'bold',
    },
  },
});

const variance = (rowHeight: number, pillCount: number) =>
  style({
    display: 'flex',
    justifyContent: 'space-between',
    height: 'inherit',
    width: 'inherit',
    $nest: {
      '& .pills': {
        display: 'flex',
        flexDirection: 'column',
        width: 'inherit',
        columnGap: '1rem',
      },
      '& .pill-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: `calc((${rowHeight}px / ${pillCount}) - 0.1rem)`,
        alignItems: 'center',
        justifyItems: 'center',
        $nest: {
          '& .tinynumber': {
            justifySelf: 'start',
          },
        },
      },
      '& .pillpercent': {
        height: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        borderRadius: 8,
        textAlign: 'center',
        color: 'white',
        fontSize: 10,
        gap: 5,
        $nest: {
          '& .fas': {
            fontSize: 10,
          },
        },
      },
      '& .bullet-chart-container': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  });

const verticalStack = (rowHeight: number, stackCount: number) =>
  style({
    height: 'inherit',
    width: 'inherit',
    $nest: {
      '& span': {
        display: 'grid',
        gridTemplateRows: `calc((${rowHeight}px / ${stackCount}) - 0.1rem)`,
        alignItems: 'center',
        justifyItems: 'center',
      },
    },
  });

export default {
  DEFAULT_ROW_HEIGHT,
  trendDetailsContainer,
  variance,
  verticalStack,
};
