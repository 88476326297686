import { style } from 'typestyle';
import { STRIPHEIGHT } from 'src/components/views/ParetoAnalysis/DetailsStrip.styles';
import { smallBreakpoint } from 'src/utils/Style/Theme';
const graphMargin = window.innerWidth > smallBreakpoint ? 200 : 10;
export default {
  graphContainer: style({
    marginLeft: graphMargin,
    marginRight: graphMargin,
  }),
  graphContainerFitScreen: style({
    height: `calc(100% - ${STRIPHEIGHT}px)`,
  }),
  graphClass: style({
    height: '100%',
    width: '100%',
  }),
};
