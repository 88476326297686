import { style } from 'typestyle/lib';
import { primaryTextColor } from 'src/utils/Style/Theme';
import {
  TREND_UP_COLOR,
  TREND_DOWN_COLOR,
  NO_TREND_COLOR,
  MAP_BUBBLE_MIN_SIZE,
  MAP_BUBBLE_MAX_SIZE,
} from 'src/pages/Hindsighting/MacroTrends/GeoTrends/GeoTrends.styles';
import { SUBHEADER_HEIGHT } from 'src/components/Subheader/Subheader.styles';
import {
  KEY_FINANCIAL_EXPANDED_HEIGHT,
  ACCORDION_HEADER_HEIGHT,
  CHOICE_PRODUCTIVITY_EXPANDED_HEIGHT,
} from 'src/pages/Hindsighting/CategoryRecap/CategorySummary/CategorySummary.styles';

const TREND_ANALYSIS_EXPANDED_HEIGHT = 400;

// Global Region Volume and Trend styles.
export const geoMapStyles = {
  bubbleMaxSize: MAP_BUBBLE_MAX_SIZE,
  bubbleMinSize: MAP_BUBBLE_MIN_SIZE,
  noTrendColor: NO_TREND_COLOR,
  trendDownColor: TREND_DOWN_COLOR,
  trendUpColor: TREND_UP_COLOR,
};

export function summaryStyle(financialsOpen: boolean, productivityOpen: boolean, trendsAnalysisOpen: boolean) {
  const financialsOffset = financialsOpen ? KEY_FINANCIAL_EXPANDED_HEIGHT : ACCORDION_HEADER_HEIGHT;
  const productivityOffset = productivityOpen ? CHOICE_PRODUCTIVITY_EXPANDED_HEIGHT : ACCORDION_HEADER_HEIGHT;
  const trendsOffset = trendsAnalysisOpen ? TREND_ANALYSIS_EXPANDED_HEIGHT : ACCORDION_HEADER_HEIGHT;
  const offset = SUBHEADER_HEIGHT + financialsOffset + productivityOffset + trendsOffset;

  return style({
    height: '100%',
    overflow: 'auto',
    $nest: {
      '.summary-container': {
        height: `calc(100% - calc(${offset}px + 0.15rem))`,
      },
      // this should be an exact match to what `CategorySummary.styles.ts` contains, they are the same component
      '.choice-productivity-container': {
        display: 'flex',
        height: '100%',
        $nest: {
          '&.print': {
            height: 'auto',
          },
          '.fin-wrapper': {
            display: 'flex',
            width: '20%',
            alignItems: 'center',
            minWidth: 220,
            // override key financial item styles when rendered in choice productivity
            $nest: {
              '.key-financial-item': {
                backgroundColor: 'white',
              },
              header: {
                fontWeight: 300,
              },
              '.primary': {
                color: primaryTextColor,
                flex: 'none',
                marginRight: '1rem',
              },
            },
          },
          '.choice-wrapper': {
            flex: 1,
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
          },
        },
      },
      '.macro-trend-analysis-items-container': {
        display: 'flex',
        width: '100%',
        $nest: {
          '& .trend-analysis-skeleton': {
            minHeight: '400px',
          },
        },
      },
    },
  });
}
