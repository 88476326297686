import React from 'react';
import { ConfigGroup } from '../Mfp/PivotConfigurator/PivotConfigurator.types';
import { VisualizeSummaryPivotValueProps } from 'src/pages/Mfp/MfpGrid/visualize-summary-pivot-ag.types';
import { getCellStateFromCells } from 'src/pivot/PivotCells.utils';
import { GridAsyncState, GRID_DEFAULT } from 'src/state/scope/Scope.types';
import { createDimensionList } from 'src/utils/Component/visualize';
import { Popup, Button, Icon } from 'semantic-ui-react';

export interface VisualizeContextMenuProps extends Pick<VisualizeSummaryPivotValueProps, 'hasEditableRevision'> {
  cellState: ReturnType<typeof getCellStateFromCells>;
  gridAsyncState: GridAsyncState;
  availableGroup: ConfigGroup[];
  favoritesLoading: boolean;
  handleClickUndo: () => void;
  handleClickRedo: () => void;
  handleClickLayoutManagerOpen: () => void;
  handleClickDownload: () => void;
  freezeCells: () => void;
  lockCells: () => void;
  relaxCells: () => void;
}

/**
 * Conditionally renders the icons above and to the right of the grid
 * Return order is important, as it determines the order the icons are rendered in
 */
export const VisualizeContextMenu = (props: VisualizeContextMenuProps): JSX.Element => {
  const { cellState, hasEditableRevision } = props;
  const { relaxCells, lockCells, freezeCells } = props;
  const { favoritesLoading } = props;
  const { handleClickDownload, handleClickLayoutManagerOpen, handleClickRedo, handleClickUndo } = props;

  // Initializes the context menu with the text that shows the current dimensions
  // This is always rendered to the left
  const contextItems = [
    <div key="dimension-list" className="left-item">
      <span>Reviewing: </span>
      {createDimensionList(props.availableGroup)}
      <div className={'dim-item'} key={'saving'}>
        {props.gridAsyncState !== GRID_DEFAULT ? `(${props.gridAsyncState})` : null}
      </div>
    </div>,
  ];

  if (cellState.isUnlockable && hasEditableRevision) {
    contextItems.push(
      <Popup
        key={'unconstrain'}
        content={'Unlock selected cell(s)'}
        trigger={
          <Button
            data-qa={'unlock-btn-unlock'}
            icon={<Icon className={'far fa-unlock'} />}
            onClick={relaxCells}
            attached={'top'}
          />
        }
      />
    );
  }

  if (cellState.isUnFreezeable && hasEditableRevision) {
    contextItems.push(
      <Popup
        key={'unfreeze'}
        content={'Unfreeze selected cell(s)'}
        trigger={
          <Button
            data-qa={'unfreeze-btn-unfreeze'}
            icon={<Icon className={'far fa-fire-alt'} />}
            data={{ freezeOrThaw: 'relaxCells' }}
            onClick={relaxCells}
            attached={'top'}
          />
        }
      />
    );
  }

  if (cellState.isRelaxed && hasEditableRevision) {
    contextItems.push(
      <Popup
        key={'lock'}
        content={'Lock selected cell(s)'}
        trigger={
          <Button
            data-qa={'lock-btn-lock'}
            icon={<Icon className={'far fa-lock'} />}
            data={{ constrainOrRelax: 'constrainCells' }}
            onClick={lockCells}
            attached={'top'}
          />
        }
      />
    );
    contextItems.push(
      <Popup
        key={'freeze'}
        content={'Freeze selected cell(s)'}
        trigger={
          <Button
            data-qa={'freeze-btn-freeze'}
            icon={<Icon className={'far fa-snowflake'} />}
            data={{ freezeOrThaw: 'lockCells' }}
            onClick={freezeCells}
            attached={'top'}
          />
        }
      />
    );
  }

  if (hasEditableRevision) {
    contextItems.push(
      <Button
        key={'undo'}
        content={'Undo'}
        data-qa={'undo-btn-undo'}
        icon={<Icon className={'fas fa-undo-alt'} />}
        onClick={handleClickUndo}
        attached={'top'}
      />
    );
    contextItems.push(
      <Button
        key={'redo'}
        content={'Redo'}
        data-qa={'redo-btn-redo'}
        icon={<Icon className={'fas fa-redo-alt'} />}
        onClick={handleClickRedo}
        attached={'top'}
      />
    );
  }

  contextItems.push(
    <Button
      key={'configure'}
      content={'Configure'}
      data-qa={'configure-btn-open'}
      icon={<Icon className={'fas fa-cog'} />}
      onClick={handleClickLayoutManagerOpen}
      attached={'top'}
    />
  );

  contextItems.push(
    <Popup
      key={'popup_download'}
      content={'Download data as XLSX'}
      trigger={
        <Button
          key={'download'}
          data-qa={'down-btn-download'}
          className="down-btn-download"
          icon={<Icon className={'far fa-cloud-download'} />}
          onClick={handleClickDownload}
          loading={favoritesLoading}
          attached={'top'}
        />
      }
    />
  );

  return <React.Fragment>{contextItems}</React.Fragment>;
};
