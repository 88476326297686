import { style, media } from 'typestyle';
import { _p } from 'src/utils/Style/NestUtils';
import { smallFont, TEAL_PRIMARY } from 'src/utils/Style/Theme';

export const selectorHeight = '40px';
const dropdown = 'std-dropdown';
const formLabel = 'std-title';
const autocompleteTitle = 'autocomplete-title';
const menuItem = {};
const CARETSIZE = 6;

const form = style(
  media(
    { maxWidth: 1025 },
    // -100px for padding on small screens
    {
      width: '100px',
      $nest: {
        i: {
          display: 'none',
        },
      },
    }
  ),
  {
    $debugName: 'std-form',
    width: 150,
    height: selectorHeight,
    margin: 5,
    marginLeft: 2,
    marginRight: 2,
    $nest: {
      [_p(formLabel)]: {
        $debugName: formLabel,
        fontSize: smallFont,
      },
      [_p(dropdown)]: {
        $debugName: dropdown,
        fontSize: smallFont,
        color: TEAL_PRIMARY,
        cursor: 'pointer',
        $nest: {
          i: {
            top: `calc(50% - ${CARETSIZE}px)`, // size of caret
            right: 0,
            position: 'absolute',
            pointerEvents: 'none',
            color: TEAL_PRIMARY,
          },
        },
      },
    },
  }
);

export { formLabel, autocompleteTitle, form, dropdown, menuItem };
