// this sets up a global evaluate object with some of the more dangerous functions disabled
// First load objects and functions you want
// @ts-ignore
import { create, all } from 'mathjs';

export const mathScope: Record<string, number> = {
  K: 1000,
  k: 1000,
  M: 1000000,
  m: 1000000,
  B: 1e9,
  b: 1e9,
  T: 1e12,
  t: 1e12,
};

const config = {
  number: 'number',
};

const math = create(all, config);
const limitedEvaluate = math.evaluate;

// null guard, because the type thinks these are nullable
if (!limitedEvaluate || !math || !math.import) {
  throw new Error('Unable to start the math evaluator');
}

// these force MathJS to throw if a user tried to enter any of these functions in a gridcell
math.import(
  {
    import: function () {
      throw new Error('Function import is disabled');
    },
    createUnit: function () {
      throw new Error('Function createUnit is disabled');
    },
    evaluate: function () {
      throw new Error('Function evaluate is disabled');
    },
    parse: function () {
      throw new Error('Function parse is disabled');
    },
    simplify: function () {
      throw new Error('Function simplify is disabled');
    },
    derivative: function () {
      throw new Error('Function derivative is disabled');
    },
  },
  { override: true }
);

//allowable
export const validCharactersRegex = /[^0-9kKmMbBtT%^\.\*\-\+/]/g;

export default limitedEvaluate;
