import { style, classes } from 'typestyle';
import { _p } from 'src/utils/Style/NestUtils';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const mainBody = 'main-body';

export default {
  wizardButtonGroup: style({
    $nest: {
      ['& button']: {
        margin: 5,
      },
    },
  }),
  wizardButton: style({
    width: 100,
    height: 50,
    color: 'black',
    backgroundColor: '#d6d6d6',
    $nest: {
      '&.primary': {
        color: 'white',
        backgroundColor: TEAL_PRIMARY,
      },
      '&:disabled': {
        color: 'grey',
        backgroundColor: '#d6d6d6',
      },
    },
    borderStyle: 'none',
    cursor: 'pointer',
  }),
  container: style({
    height: '90vh',
    width: '90vw',
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      '> header': {
        width: '100%',
        padding: '0.5rem 0.75rem',
        marginBottom: 0,
        fontSize: '1rem',
        color: 'inherit',
        backgroundColor: '#f7f7f7',
        borderBottom: '1px solid #ebebeb',
      },
    },
  }),
  body: style({
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'row',
  }),
  content: style({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: '0.5rem 0.75rem',
    $nest: {
      [_p(mainBody)]: {
        flexGrow: 1,
      },
    },
  }),
  stepping: classes(
    style({
      background: '#f4f3f4',
      paddingLeft: '0.5rem',
    }),
    '.border-right'
  ),
  modalContainer: style({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  modalBody: style({
    width: '40%',
    height: '30%',
    border: '1px solid #ebebeb',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
  }),
  modalFooter: style({
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    width: '100%',
  }),
};
