import { flow, isNil, get } from 'lodash';
import { connect } from 'react-redux';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import CanvasView from 'src/components/StandardCardView/StandardCardView';
import { DispatchProps, ValueProps, IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { update } from 'src/services/lenses/Lenses.actions';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';

import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { isAssortmentAddSlice } from '../../../AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import {
  receiveError as receiveCanvasViewError,
  requestTenantConfig,
  setCanvasViewTenantConfig,
} from './CanvasView.slice';
import { combineSummaryInputs, getGroupedData } from './CanvasView.selectors';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';
import { canvasViewLens, subheaderLens, perspectiveLens } from 'src/services/lenses/lenses';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/Favorites.types';
import { BackgroundDataLoadingProps } from 'src/components/BackgroundDataLoading/BackgroundDataLoading';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { AppState, AppThunkDispatch } from 'src/store';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { CanvasViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { CanvasViewRollUpConfig } from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { CanvasViewOwnProps } from 'src/services/configuration/codecs/ownProps';
import { getfabProps } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';

function mapStateToProps(state: AppState, ownProps: CanvasViewOwnProps): ValueProps & BackgroundDataLoadingProps {
  const { fabType = FabType.none } = ownProps;
  const viewState = canvasViewLens.get(state);
  const subheader = subheaderLens.get(state);
  const perspective = perspectiveLens.get(state);
  const worklist = state.pages.hindsighting.styleColorReview.sharedData.worklist;
  const fabProps = getfabProps(state, fabType);

  let selectedItems: BasicPivotItem[] = [];
  if (isAssortmentAddSlice(viewState)) {
    selectedItems = viewState.selectedItemsForCart;
  }
  const filteredStylesCache = getDataFromCache(viewState);
  const filteredStyles = !isNil(filteredStylesCache) ? filteredStylesCache.tree : [];
  const loaded = !viewState.tenantConfigLoading && isDataLoaded(viewState.viewDataState);
  const adornments: AdornmentType[] = get(viewState.viewDefn, 'adornments', []);

  return {
    popoverTitle: viewState.popoverTitle || '',
    loaded,
    config: viewState.viewDefn,
    sortBy: subheader.sortBy,
    groupBy: subheader.groupBy,
    subheaderViewDefns: ownProps.defns.subheader,
    filteredStyles,
    groupedStyles: getGroupedData(filteredStyles, viewState, subheader, selectedItems, worklist),
    summary: combineSummaryInputs(
      viewState.identityPropsConfig.id,
      filteredStyles,
      viewState.calcViewDefn,
      subheader,
      viewState.viewDefn.searchIndexes
    ),
    identityPropsConfig: viewState.identityPropsConfig,
    currentTab: perspective.activeTab,
    unmodifiedViewDefn: viewState.unmodifiedViewDefn,
    fabType,
    fab: fabProps,
    isFabDisabled: false,
    downloadLink: ownProps.subheader?.downloadLink,
    viewDataState: viewState.viewDataState,
    adornments,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, { defns }: CanvasViewOwnProps): DispatchProps {
  return {
    onShowView() {
      const client = container.tenantConfigClient;
      dispatch(requestTenantConfig());
      client
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [CanvasViewDefn, CanvasViewRollUpConfig],
        })
        .then((resp: (TenantConfigViewData & { identityProps?: IdentityPropsConfig })[]) => {
          const config = resp[0];
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          const storedConfig = localConfig && localConfig.config ? localConfig.config : resp[0];
          // FIXME, this should be in the epic, please don't let this survive a regular weekday merge
          const identityPropsConfig = retrieveIdentityPropsConfig(config)!;

          dispatch(
            update(
              canvasViewLens.composeSetter(setCanvasViewTenantConfig).set({
                viewDefn: storedConfig,
                unmodifiedViewDefn: unmodifiedViewDefn,
                modelDefn: storedConfig.model || defns.model,
                calcViewDefn: resp[1] as TenantConfigViewData,
                tenantConfigLoading: false,
                identityPropsConfig,
              }),
              'CanvasView: Config loaded'
            )
          );
        })
        .catch((error) =>
          dispatch(
            receiveCanvasViewError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.canvasView,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    onItemClicked(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    showStylePane(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
  };
}

const wrappedView = flow(() => CanvasView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
