import { style } from 'typestyle';

// this entire stlye basically just overrides some of the parent ag-grid styles so it renders in the popover
const worklistStyle = style({
  $debugName: 'worklist-context-menu-react',
  $nest: {
    ['.ag-menu']: {
      background: 'unset',
      borderRadius: 'unset',
      boxShadow: 'unset',
      padding: '0.5rem 0.75rem',
      zIndex: 'unset',
      maxHeight: 'unset',
      overflowY: 'unset',
      position: 'unset',
      border: 'unset',
      $nest: {
        ['.ag-menu-list']: {
          marginBottom: 'unset',
          marginTop: 'unset',
          $nest: {
            ['.ag-menu-option.ag-menu-option-active']: {
              backgroundColor: 'unset',
            },
            '.ag-menu-option': {
              cursor: 'pointer',
            },
            '.ag-menu-option:hover': {
              backgroundColor: 'rgb(0,0,0,0.075)',
            },
            '.ag-menu-option.ag-menu-option-disabled': {
              cursor: 'not-allowed',
            },
          },
        },
      },
    },
  },
});

export default worklistStyle;
