import { CancelToken } from 'axios';
import { WorklistType } from 'src/components/WorklistIcon/WorklistIcon';

export type AppType = 'Assortment' | 'TDAnalysis' | 'Configurator' | 'Allocation' | 'FinancialPlanning';
export const TOP_DOWN_IDENTIFIER = 'TD_';
export const TOP_DOWN: AppType = 'TDAnalysis';

export interface BasicItem {
  id: string;
  description: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface BasicPivotItem extends BasicItem {
  '@path': string;
  children: BasicPivotItem[];
  image: string;
  attributevaluerank: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ObjectDeserializer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deserialize(responseBody: string): Promise<any[]>;
}

export interface PivotQuery {
  defnId: string;
  appName: AppType;
  groupBy: string;
  sortBy: string;
  flowStatus: string;
  cancelToken?: CancelToken;
}
export type ListFitViewOptions = Record<string, any> & {} & Omit<PivotQuery, 'defnId' | 'appName'>;

export interface HistoryStylePaneQuery {
  defnId: string;
  appName: AppType;
  memberId: string;
}

export const lookBackPeriodProp = 'ytd_key';
export interface ListDataPivotQuery {
  defnId: string;
  appName: AppType;
  sortBy?: string;
  aggBy?: string;
  floorset?: string;
  channel?: string;
  department?: string;
  flowStatus?: string;
  topMembers?: string;
  [lookBackPeriodProp]?: string;
  nestData?: boolean;
}

export interface ValidMembersQuery {
  appName: AppType;
  level: string;
  parent?: string;
}

export interface RegionItem extends BasicItem {
  index: number;
  root: boolean;
  ordered: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dimension: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shortestPathToRoot: Record<string, any>;
  levelId: string;
}

export interface AuthorizedMembersQuery extends ValidMembersQuery {
  parent: string;
}

/** @deprecated see AssortmentResponse, this will be removed after favorites improvements */
export interface Response<T> {
  data: T;
  success: boolean;
  info: PayloadObject;
}

export interface AssortmentResponse<T> {
  data: T;
  info: PayloadObject;
  message: string;
  status: string;
  success: boolean;
}

export enum OverTimeType {
  flowsheet,
  pricing,
}

export interface FlowSheetByIndexQuery {
  defnId: string;
  appName: AppType;
  memberIds: string[];
  start: string;
  end: string;
  //sortBy=product%2Ctime
}

export interface OverTimeQuery {
  defnId: string;
  appName: AppType;
  groupBy?: string;
  sortBy?: string;
  aggBy?: string;
  memberId: string;
}

export interface TimeQuery {
  appName: AppType;
  parentLevel: string;
}

export type TimeEntry = {
  id: string;
  indx: number;
  name: string;
  description: string;
  levelId: string;
  readOnly: boolean;
  children: TimeEntry[];
};

export interface TimeSheetInfo {
  entries: TimeEntry[];
  planCurrent: string;
}

export interface WorklistInfo {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: string;
  type: WorklistType;
  product: string;
}

export interface SubmitPayload {
  keyField: string[];
  create: PayloadObject[];
  update: PayloadObject[];
  delete: PayloadObject[];
}

export interface PayloadObject {
  [key: string]: PayloadObject[] | PayloadObject | number | boolean | string | null;
}

export type TreePivot = BasicPivotItem[];
export type FlatPivot = BasicPivotItem[];

export interface ListDataOptions {
  ignoreAncestors?: boolean;
  sortBy?: string;
  flowStatus?: string;
  aggBy?: string;
  topMembers?: string;
  [lookBackPeriodProp]?: string;
  nestData?: boolean;
  floorset?: string;
  channel?: string;
  department?: string;
}

export interface FitViewOptions {
  groupBy?: string;
  sortBy?: string;
  flowStatus?: string;
}

export interface Pivot {
  flat: FlatPivot;
  tree: TreePivot;
}

export interface BasicChildren {
  children: BasicChildren[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export enum PivotServiceType {
  app,
  styleEdit,
}
