import { style } from 'typestyle';

export const fabBtn = style({
  position: 'fixed',
  bottom: '24px',
  right: '24px',
  $nest: {
    '.fas': {
      fontSize: '1rem',
    },
  },
  zIndex: 100,
});

export const exitBtn = style({
  position: 'absolute',
  top: 0,
  right: 0,
});
