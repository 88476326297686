import React from 'react';
import { classes } from 'typestyle';
import Checkbox from '@material-ui/core/Checkbox';
import { default as styles } from 'src/components/MassEdit/MassEdit.styles';
import { MassEditSelectItemData } from './MassEdit';
import { filterAndSortPivotItems, FilterSortType } from 'src/utils/Pivot/Filter';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export interface MassEditMultiSelectProps {
  items: MassEditSelectItemData[];
  handleItemSelection: (selections: MassEditSelectItemData[]) => void;
  disabled?: boolean;
}

export interface MassEditMultiSelectState {
  searchValue: string;
  allSelected: boolean;
  selectedItems: MassEditSelectItemData[];
  filteredItems: MassEditSelectItemData[];
}

export class MassEditMultiSelect extends React.Component<MassEditMultiSelectProps, MassEditMultiSelectState> {
  state: MassEditMultiSelectState;

  constructor(props: MassEditMultiSelectProps) {
    super(props);

    this.state = {
      searchValue: '',
      allSelected: false,
      selectedItems: [],
      filteredItems: props.items,
    };
  }

  selectAllItems = () => {
    const { allSelected, filteredItems } = this.state;
    const updatedAllSelected = !allSelected;
    const selectedItems = updatedAllSelected ? [...filteredItems] : [];

    this.setState(
      {
        allSelected: updatedAllSelected,
        selectedItems,
      },
      () => this.props.handleItemSelection(selectedItems)
    );
  };

  handleSelection = (selection: MassEditSelectItemData) => {
    if (this.props.disabled) return;
    const { selectedItems } = this.state;
    const itemIndex = selectedItems.findIndex((item) => item.value === selection.value);
    const updatedSelections =
      itemIndex < 0
        ? [...selectedItems, selection] // add item to selections
        : selectedItems.filter((item) => item.value !== selection.value); // remove item from selections

    this.setState(
      {
        selectedItems: updatedSelections,
      },
      () => this.props.handleItemSelection(updatedSelections)
    );
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    const { items } = this.props;
    const sortBy: FilterSortType = {
      dataIndex: 'label',
      direction: 'asc',
    };
    const filteredItems = filterAndSortPivotItems(
      searchValue,
      sortBy,
      ['label', 'value'],
      [],
      (items as unknown) as BasicPivotItem[]
    );

    this.setState({
      searchValue,
      filteredItems: (filteredItems as unknown) as MassEditSelectItemData[],
    });
  };

  renderItems(): JSX.Element {
    const { filteredItems, selectedItems } = this.state;
    const renderedItems = filteredItems.map((item, index) => {
      const isSelected = selectedItems.indexOf(item) >= 0;
      return (
        <div
          key={`${item.value}-${index}`}
          data-qa={`mass-edit-multiselect-${index}`}
          className={classes(styles.multiSelectItemContainer, isSelected && styles.enabled)}
          onClick={() => this.handleSelection(item)}
        >
          {item.label}
        </div>
      );
    });

    return <div className={styles.multiSelectItemsContainer}>{renderedItems}</div>;
  }

  render() {
    const { searchValue, allSelected, filteredItems, selectedItems } = this.state;
    return (
      <React.Fragment>
        <div className={styles.multiSelectSearchContainer}>
          <input
            type="text"
            className="form-control"
            placeholder="Search this list..."
            aria-label="Search this list"
            onChange={this.handleSearchChange}
            value={searchValue}
            data-qa="MassEditItemsSearchInput"
          />
        </div>
        {!this.props.disabled ? (
          <div className={styles.multiSelectDetailsContainer}>
            <div>
              <Checkbox className={styles.selectAllCheckbox} checked={allSelected} onClick={this.selectAllItems} />
              <span>Select All</span>
              <span className={styles.itemCount}>( {`${filteredItems.length}`} )</span>
            </div>
            <div>
              Selected: {selectedItems.length} of {filteredItems.length}
            </div>
          </div>
        ) : null}
        {this.renderItems()}
      </React.Fragment>
    );
  }
}
