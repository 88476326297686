import { style } from 'typestyle';
import { TEAL_PRIMARY, borderHighlightColor } from 'src/utils/Style/Theme';

export const reportingStyle = style({
  marginTop: 20,
  $nest: {
    '.items': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '.report-card': {
      flexDirection: 'column',
      padding: '1rem',
      display: 'flex',
      boxShadow: '0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12), 0 3px 5px -1px rgba(0,0,0,.3)',
      margin: '1.5rem',
      height: '15rem',
      width: '15rem',
      border: '4px solid white',
      $nest: {
        i: {
          color: TEAL_PRIMARY,
          alignSelf: 'flex-end',
          marginRight: '0.5rem',
          $nest: {
            '&:hover': {
              cursor: 'pointer',
            },
            '& + &': {
              marginRight: 0,
            },
          },
        },
        header: {
          borderBottom: '2px solid ' + TEAL_PRIMARY,
          fontWeight: 600,
        },
        main: {
          fontSize: '0.9rem',
          marginTop: '0.5rem',
        },
        footer: {
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
        },
        '&:hover': {
          border: '4px solid ' + borderHighlightColor,
        },
      },
    },
  },
});
