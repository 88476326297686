import * as React from 'react';
import { isNil, isEmpty } from 'lodash';
import styles from './InputGeneric.styles';
import { classes } from 'typestyle';
import * as validator from 'validator';

export interface InputGenericProps {
  onChange?: (text: string | number) => void;
  onBlur?: (prop: string) => void;
  editable?: boolean;
  valid?: boolean;
  value?: string;
  autoFocus?: boolean;
  className?: string;
  whitelist?: InputCharacterWhitelist;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  dataQa?: string;
}

export interface InputGenericState {
  textValue: string;
  isBlurred: boolean;
}

export enum InputCharacterWhitelist {
  allowAll = '[^]',
  alphaNumericOnly = '[A-Za-z0-9]',
  alphaNumeric = '\\w',
  alphaNumericSpaces = '\\w\\s',
  alphaNumericSeparators = '\\w-',
  alphaNumericSpacesSeparators = '\\w\\s-',
  basicMath = '\\d\\+\\-\\*\\/\\^',
}

export default class InputGeneric extends React.Component<InputGenericProps, InputGenericState> {
  inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: InputGenericProps) {
    super(props);

    this.inputRef = React.createRef();
    this.state = {
      textValue: !isNil(props.value) ? props.value : '',
      isBlurred: false,
    };
  }

  componentDidUpdate(prevProps: InputGenericProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        textValue: !isNil(this.props.value) ? this.props.value : '',
      });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textValue = event.target.value;
    const { whitelist, maxLength } = this.props;
    let value = isNil(whitelist) ? textValue : validator.whitelist(textValue, whitelist);

    if (!isNil(maxLength) && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }

    this.setState({ textValue: value }, () => {
      if (this.props.onChange && this.props.editable) {
        this.props.onChange(value);
      }
    });
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.state.isBlurred) {
      return;
    }

    const textValue = event.target.value;

    if (isEmpty(textValue)) {
      // require user to fix value before leaving
      return;
    }

    this.setState(
      (prevState) => ({
        textValue: prevState.textValue.trim(),
      }),
      () => {
        if (this.props.onBlur && this.props.editable) {
          this.props.onBlur(this.state.textValue);
        }
      }
    );
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const { textValue } = this.state;

      if (isEmpty(textValue)) {
        // require user to fix value before leaving
        return;
      }

      this.setState(
        (prevState) => ({
          textValue: prevState.textValue.trim(),
          isBlurred: true,
        }),
        () => {
          if (this.props.onBlur && this.props.editable && this.inputRef && this.inputRef.current) {
            this.inputRef.current.blur();
            this.props.onBlur(this.state.textValue);
          }
        }
      );
    }
  };

  handleFocus = () => {
    this.setState({
      isBlurred: false,
    });
  };

  render() {
    const { valid, editable, autoFocus, className, required, dataQa } = this.props;
    const { textValue } = this.state;
    const fullyValid = valid && (required ? !isEmpty(textValue) : true); // text expected if required to be valid
    const inputStyles = isNil(className)
      ? classes('form-control', styles.buildInputStyle(fullyValid))
      : classes('form-control', styles.buildInputStyle(fullyValid), className);

    let content;
    if (!editable) {
      content = (
        <div>
          <p>{textValue}</p>
        </div>
      );
    } else {
      const { disabled } = this.props;
      content = (
        <div style={{ width: '100%' }}>
          <div className={classes(styles.inputWrapper, !fullyValid && styles.invalidWrapper)}>
            <input
              type="text"
              ref={this.inputRef}
              className={inputStyles}
              value={textValue}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyPress={this.handleKeyDown}
              autoFocus={isNil(autoFocus) ? true : autoFocus}
              required={required}
              disabled={!isNil(disabled) ? disabled : false}
            />
          </div>
        </div>
      );
    }

    return <div data-qa={dataQa}>{content}</div>;
  }
}
