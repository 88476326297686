import { style } from 'typestyle';

export default {
  optionContainer: style({
    marginBottom: 20,
    height: 'auto',
  }),
  summariesContainer: style({
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    $nest: {
      '.variances .metric-value': {
        width: 'initial',
        paddingRight: 5,
      },
    },
  }),
  macroSummaryContainer: style({
    display: 'flex',
    flexWrap: 'wrap',
    height: 200,
    overflow: 'auto',
    $nest: {
      '&.collapsed': {
        display: 'none',
      },
      '& .variances': {
        paddingRight: 10,
      },
    },
  }),
};
