import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewParams } from 'src/state/ViewConfig/ViewConfig.types';

export interface TrendDetailsState {
  loaded: boolean,
  forceRefreshQuickTrends: boolean
}

const initState: TrendDetailsState = {
  loaded: false,
  forceRefreshQuickTrends: false
};

const trendDetailsSlice = createSlice({
  name: 'trendDetails',
  initialState: initState,
  reducers: {
    setTrendDetailsDataStale: (state) => {
      state.forceRefreshQuickTrends = true;
      return state;
    },
    completeRefreshTrendDetails: (state) => {
      state.forceRefreshQuickTrends = false;
      return state;
    }
  }
});

export const {
  completeRefreshTrendDetails,
  setTrendDetailsDataStale
} = trendDetailsSlice.actions;
export default trendDetailsSlice.reducer;
