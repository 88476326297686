import { createStyles } from '@material-ui/core';

export const STYLED_ACCORDION_SUMMARY_HEIGHT = 35;
export const STYLED_ACCORDION_DETAILS_HORIZONTAL_PADDING = 0.35;

export const accordionOverrides = createStyles({
  root: {
    margin: 0,
    // uses material-ui's $rulename feature to reference and override `expanded` rule in same style sheet
    // empty expanded rule is necessary for style to be applied properly, see following link for details
    // https://v4.mui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
    '&$expanded': {
      margin: 0,
    },
    // hides separator line when stacking multiple collapsed accordions
    '&:before': {
      height: 0,
    },
  },
  expanded: {},
});

export const accordionSummaryOverrides = createStyles({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    height: `${STYLED_ACCORDION_SUMMARY_HEIGHT}px`,
    minHeight: `${STYLED_ACCORDION_SUMMARY_HEIGHT}px`,
    '&$expanded': {
      height: `${STYLED_ACCORDION_SUMMARY_HEIGHT}px`,
      minHeight: `${STYLED_ACCORDION_SUMMARY_HEIGHT}px`,
    },
  },
  expanded: {},
});

export const accordionSummaryDisabledOverrides = createStyles({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    height: `${STYLED_ACCORDION_SUMMARY_HEIGHT}px`,
    minHeight: `${STYLED_ACCORDION_SUMMARY_HEIGHT}px`,
    pointerEvents: 'none',
  },
});

export const accordionDetailsOverrides = createStyles({
  root: {
    padding: `0 ${STYLED_ACCORDION_DETAILS_HORIZONTAL_PADDING}rem`,
  },
});

export const accordionSummaryTopographyOverrides = createStyles({
  root: {
    fontFamily: 'inherit',
  },
});
