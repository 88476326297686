// @ts-ignore
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import '@trendmicro/react-toggle-switch/dist/react-toggle-switch.css';
import React from 'react';
import classNames from 'classnames';

interface ToggleProps {
  checked: boolean,
  locked: boolean,
  onToggleClick?: () => void
}

interface ToggleState {
  checkedState?: boolean
}

export default class PivotToggle extends React.Component<
ToggleProps,
ToggleState
> {
  public static getDerivedStateFromProps(
    nextProps: ToggleProps,
    _prevState: ToggleState
  ) {
    return {
      checkedState: nextProps.checked
    };
  }

  constructor(props: ToggleProps) {
    super(props);
    this.state = {
      checkedState: props.checked
    };
  }
  private static defaultProps = {
    locked: false
  }

  public shouldComponentUpdate(nextProps: ToggleProps, nextState: ToggleState) {
    const { checked: lastChecked } = this.props;
    const { checked: nextChecked } = nextProps;

    const { checkedState: lastCheckedState } = this.state;
    const { checkedState: nextCheckedState } = nextState;

    return lastChecked !== nextChecked || lastCheckedState !== nextCheckedState;
  }

  public handleChange() {
    this.setState({
      checkedState: !this.state.checkedState
    });
    if (this.props.onToggleClick) {
      this.props.onToggleClick();
    }
  }

  public render() {
    return (
      <ToggleSwitch
        size="small"
        className={classNames('pivot-config-toggle-switch', (this.props.locked ? 'locked' : 'unlocked'))}
        checked={this.state.checkedState}
        disabled={this.props.locked}
        onChange={() => {
          this.handleChange();
        }}
      />
    );
  }
}
