import React from 'react';
import numbro from 'numbro';
import { isNil, isEqual } from 'lodash';
import { checkboxesStyle } from './SubheaderCheckboxes.styles';
import { FlowStatus } from 'src/services/configuration/codecs/bindings.types';
import { Tooltip } from '@material-ui/core';

type Props = {
  flowStatus: number[];
  flowStatusOptions: FlowStatus;
  ignoreDefaultFlowStatus?: boolean;
  handleValuesUpdate: (values: number[]) => void;
  qaPrefix?: string;
  show?: boolean;
};

type State = {
  flowStatusSelection: number[];
};

function shouldIgnoreDefaults(ignoreDefaultFlowStatus?: boolean) {
  return !isNil(ignoreDefaultFlowStatus) ? ignoreDefaultFlowStatus : true;
}

export default class SubheaderCheckboxes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      flowStatusSelection: props.flowStatus,
    };
  }

  componentDidMount() {
    const { ignoreDefaultFlowStatus, flowStatus, flowStatusOptions } = this.props;
    const ignoreDefaults = shouldIgnoreDefaults(ignoreDefaultFlowStatus);
    const initialSelection = ignoreDefaults ? flowStatus : flowStatusOptions.defaultSelection;

    this.setState({
      flowStatusSelection: initialSelection,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!isEqual(prevProps.flowStatus, this.props.flowStatus)) {
      this.setState({
        flowStatusSelection: this.props.flowStatus,
      });
    }

    if (!isEqual(prevState.flowStatusSelection, this.state.flowStatusSelection)) {
      this.props.handleValuesUpdate(this.state.flowStatusSelection);
    }
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const position = event.currentTarget.dataset.position;
    if (!isNil(position)) {
      const numericValue = numbro(position).value();

      let newFlowStatus: number[] = [];
      const { flowStatusSelection } = this.state;

      if (numericValue > -1) {
        newFlowStatus = flowStatusSelection.filter((value) => value !== numericValue);

        if (newFlowStatus.length === flowStatusSelection.length) {
          newFlowStatus = [...flowStatusSelection, numericValue];
        }
        if (newFlowStatus.length === this.props.flowStatusOptions.values.length) {
          newFlowStatus = [];
        }
      }

      this.setState({
        flowStatusSelection: newFlowStatus,
      });
    }
  };

  determineIsChecked(index: number, flowStatus: number[]) {
    return flowStatus.find((status) => status === index) !== undefined;
  }

  render() {
    const { qaPrefix, show } = this.props;
    const { flowStatusSelection } = this.state;
    const empty = () => false;
    const flowStatusOptions = this.props.flowStatusOptions.values;
    let allJsx = <div />;

    if (flowStatusOptions && flowStatusOptions.length > 0) {
      const isChecked = flowStatusSelection.length === 0;

      allJsx = (
        <label className={`check-label${isChecked ? ' selected' : ''}`} data-qa={`${qaPrefix}-label-all`}>
          <input
            aria-label="all"
            checked={isChecked}
            className="check-input"
            data-position="-1"
            data-qa={`${qaPrefix}-checkbox-all`}
            onChange={empty}
            onClick={this.handleClick}
            type="checkbox"
          />
          All
        </label>
      );
    }
    const checkboxesJsx = flowStatusOptions ? (
      flowStatusOptions.map((statusOption, index) => {
        const isChecked = this.determineIsChecked(index, flowStatusSelection);
        const slug: string = statusOption.text.toLowerCase();
        return (
          <Tooltip title={statusOption.tooltip} key={slug}>
            <label
              className={`check-label${isChecked ? ' selected' : ''}`}
              data-qa={`${qaPrefix}-label-${slug}`}
              key={slug}
            >
              <input
                aria-label={statusOption.text}
                checked={isChecked}
                className="check-input"
                data-position={index}
                data-qa={`${qaPrefix}-checkbox-${slug}`}
                onChange={empty}
                onClick={this.handleClick}
                type="checkbox"
              />
              {statusOption.text}
            </label>
          </Tooltip>
        );
      })
    ) : (
      <div />
    );

    return (
      <fieldset className={`flow-status-checkboxes ${checkboxesStyle}`} hidden={!show}>
        <div className="flow-status-layout">
          {allJsx}
          {checkboxesJsx}
        </div>
      </fieldset>
    );
  }
}
