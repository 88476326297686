import { style } from 'typestyle';
import { secondaryNeutral } from '../../theme';

export const styles = {
  card: style({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: secondaryNeutral,
    transition: 'all .3s ease-in-out;',
    $nest: {
      '.title': {
        fontSize: '1rem',
        color: '#989898',
      },
      '.primary': {
        fontSize: '1.3rem',
      },
      '.left-pane': {
        display: 'flex',
        padding: 10,
        flexGrow: 1,
        justifyContent: 'center',
        flexDirection: 'column',
      },
      '.right-pane': {
        display: 'flex',
        alignItems: 'center',
        padding: '.5rem',
        flexDirection: 'column',
        fontSize: '.8rem',
        justifyContent: 'center',
        $nest: {
          '.right-desc': {
            textAlign: 'right',
            paddingRight: 8,
            color: '#989898',
          },
          '.right-value': {
            textAlign: 'right',
          },
        },
      },
    },
  }),
};
