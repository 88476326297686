import React from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import type { PlanId, PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import { useCallback } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from 'src/store';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from '../../RightContainer/RightContainer';


const mapDispatchToProps = {
  setRightContainerPayload
};
export type CommentsCellRender = ICellRendererParams & typeof mapDispatchToProps & {
  data: PlanMetadata,
  loadPlanComments: AsyncThunk<void, PlanId, ThunkApi>
};


const CommentsCellRenderer = (props: CommentsCellRender) => {
  const { node, api, data: planData,
    loadPlanComments,
    setRightContainerPayload
  } = props;
  const expand = useCallback(() => {
    loadPlanComments(planData.id);
    setRightContainerPayload({ type: RightContainerPayloadType.Comments });
  }, [loadPlanComments, planData.id, setRightContainerPayload]);

  return (
    <Button
      icon="fal fa-comments"
      onClick={expand}
    />
  );
};

export default connect(undefined, mapDispatchToProps)(CommentsCellRenderer);
