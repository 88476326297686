import { connect } from 'react-redux';
import { ValueProps, FunctionProps } from './StoreGroupChart';
import { AnyAction as BaseAction } from 'redux';
import { requestAllData, receiveAllData, receiveDataError } from './StoreGroupChart.slice';
import serviceContainer from 'src/ServiceContainer';
import { ASSORTMENT } from '../../../../utils/Domain/Constants';
import { MainViewConfig, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import StoreGroupChart from './StoreGroupChart';
import { AppState, AppThunkDispatch } from 'src/store';
import { DataApi } from 'src/services/configuration/codecs/confdefnView';
import { isNil } from 'lodash';
import Renderer from 'src/utils/Domain/Renderer';

interface StoreGroupOwnProps {
  graphDataApi?: DataApi;
}
function mapStateToProps(state: AppState): ValueProps {
  const { storeGroupChart } = state.pages.hindsighting;
  // const dataLookup = storeGroupChart.viewDefn.main;
  const mainConfig: Pick<MainViewConfig, 'title' | 'image' | 'keys'> | undefined = storeGroupChart.viewDefn.main;
  const dataLookup = {
    title: mainConfig?.title || '{name} - {description}',
    image: mainConfig?.image || 'attribute:img:id',
    keys: mainConfig?.keys?.idProps || 'id',
  };
  let title = '';
  let img = '';
  let itemId = '';
  if (!isNil(storeGroupChart.selectedItem) && dataLookup) {
    title = Renderer.template(storeGroupChart.selectedItem, dataLookup.title || '');
    img = storeGroupChart.selectedItem[dataLookup.image || ''];
    itemId = storeGroupChart.selectedItem[dataLookup.keys ? dataLookup.keys : ''];
  }
  const view = storeGroupChart.viewDefn && storeGroupChart.viewDefn.view[1];
  return {
    itemId,
    view: view || ({ dataIndex: '', text: '', view: [] } as TenantConfigViewItem),
    tlData: storeGroupChart.allData,
    title,
    isLoading: !storeGroupChart.allDataLoaded || !storeGroupChart.configLoaded,
    img,
  };
}

function asyncGetData(graphDataApi?: DataApi) {
  const service = serviceContainer.pivotService;
  return (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction> | void => {
    if (!graphDataApi || !graphDataApi.isListData) return;
    const { selectedItem, viewDefn } = getState().pages.hindsighting.storeGroupChart;
    const graphAggs: TenantConfigViewItem[] | undefined = viewDefn.view[0].view;
    if (selectedItem != null && graphAggs != null) {
      dispatch(requestAllData());
      return Promise.all(
        graphAggs.map((item) => {
          return service
            .listData(graphDataApi.defnId, ASSORTMENT, {
              aggBy: item.dataIndex,
              topMembers: selectedItem.id,
            })
            .then((resp) => ({
              title: item.text,
              data: resp.tree,
            }));
        })
      )
        .then((all) => dispatch(receiveAllData(all)))
        .catch((_error) => dispatch(receiveDataError()));
    }
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: StoreGroupOwnProps): FunctionProps {
  return {
    onItemUpdate() {
      dispatch(asyncGetData(ownProps.graphDataApi || undefined));
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(StoreGroupChart);
