import { z } from 'zod';
import {
  BasicViewItem,
  NestedColumnsBasicViewItem,
  NestedOptionsBasicViewItem,
  NestedViewBasicViewItem,
  zCompanionView,
  ConfigRefs,
  ConfigurableGridActions,
  zConfigurableGridGraph,
  MainView,
  MassEditConfig,
  SalesAdjustment,
  SearchIndexes,
  SubheaderDropdown,
  WorklistTab,
  ParetoSection,
  StyleEditHeader,
  StyleEditSection,
  TimeLevels,
  MainViewDefnConfig,
  GridMainViewDefn,
  CompanionViewDefn,
  zConfigurablePostAction,
  zTopAttribute,
  ConfigureViewDefn,
  zTransposedColumns,
  zMassEditCoordinateMap,
  zNoRowsOverlay,
} from 'viewdefns/general';
import { zAdornments } from 'viewdefns/literals';
import { IdentifierProps, zClientDataApi } from '../confdefnView';

export const HasAdornments = z.object({ adornments: z.array(zAdornments).default([]) });
const hasCardWidth = z.object({
  numberCardsWide: z
    .number()
    .optional()
    .default(2),
});

// Adding to this validator type will allow certain features to be configured for all views.
// This just means the component will support adornments, it will still need to be configured to render in the card/grid items
const BaseViewDefn = HasAdornments;

const zConfigurableGridTopAttributes = z
  .object({
    title: z.string().optional(),
    expanded: z.boolean().optional(),
    left: z.array(zTopAttribute),
    right: z.array(zTopAttribute),
  })
  .optional();

export const zSubheaderDropdowns = z.object({
  groupBy: SubheaderDropdown.optional(),
  topMember: z
    .object({
      label: z.string(),
      dataApi: zClientDataApi,
    })
    .optional(),
});
export type SubheaderDropdowns = z.infer<typeof zSubheaderDropdowns>;

export const ConfigurableGridViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    // TODO: can this shape be converted to match zCompanionView
    // TODO: update name in viewdefn to companionList,
    // it's configured as CompanionGrid but really represents the companion list's config
    companionGrid: z.object({
      label: z.string(),
      sortConfig: z.object({
        defaults: z.string().optional(),
        view: z.array(BasicViewItem),
      }),
    }),
    subheaderDropdowns: zSubheaderDropdowns,
    columns: z.array(BasicViewItem),
    main: GridMainViewDefn.optional(),
    actions: ConfigurableGridActions.optional(),
    $configRefs: ConfigRefs.optional(),
    salesAdjustment: SalesAdjustment,
    showPublish: z.boolean().optional(),
    hideUnpublish: z.boolean().optional(),
    publishText: z.string().optional(),
    publishAttribute: z.string().optional(),
    massEditConfig: MassEditConfig.optional(),
    updateCoordinateMap: zMassEditCoordinateMap.optional(),
    topAttributes: zConfigurableGridTopAttributes,
    configurablePostAction: zConfigurablePostAction.optional(),
    redecorateMap: z.record(z.string()).optional(),
    configure: ConfigureViewDefn.optional(),
    graph: zConfigurableGridGraph.optional(),
    searchIndexes: z.array(z.string()).optional(),
    noRowsOverlay: zNoRowsOverlay.optional(),
  })
);

export interface ConfigurableGridViewDefn extends z.infer<typeof ConfigurableGridViewDefn> { }

export const zWorklistViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    defaultTab: z.string(),
    tabs: z.array(WorklistTab),
    companionView: zCompanionView,
  })
);

export interface WorklistViewDefn extends z.infer<typeof zWorklistViewDefn> { }

export const NestedGridViewDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    type: z.literal('grid'),
    main: GridMainViewDefn,
    // This is a legacy property used for AA Receipt Grid, but now we used the 0th entry of the componentProps.defns.view array as this value
    // This functionality should always pull from componentProps.defns.model and the model property in the viewDefn should be removed later
    // INT-2616 tracks the need for this migration
    model: z.string().optional(),
    view: z.array(NestedColumnsBasicViewItem.or(BasicViewItem)),
  })
);

export const ParetoSummaryViewDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    view: z.array(ParetoSection),
  })
);
export const StoreGroupViewDefn = BaseViewDefn.merge(
  z.object({
    view: z.array(
      z.object({
        text: z.string(),
        view: z.array(BasicViewItem),
      })
    ),
    main: z
      .object({
        image: z.string(),
        title: z.string(),
      })
      .merge(IdentifierProps),
  })
);

export const ProductMixViewDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    defaults: z.string(),
    view: z.array(NestedViewBasicViewItem).or(BasicViewItem),
  })
);

export const ProductivityViewDefn = BaseViewDefn.merge(
  z.object({
    view: z.array(NestedViewBasicViewItem).or(BasicViewItem),
  })
);

export const StyleEditViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    searchIndexes: SearchIndexes,
    sectionsViewDefns: z.array(z.string()),
    companionView: zCompanionView,
  })
);

export const StyleEditSectionsViewDefn = BaseViewDefn.merge(
  z.object({
    header: StyleEditHeader,
    sections: z
      .array(StyleEditSection)
      .optional()
      .default([]),
  })
);

export const NestedOvertimeViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    main: GridMainViewDefn.optional(),
    columns: z.array(BasicViewItem),
    // TODO: needs to match the other configure view defn shape 'ConfigureViewDefn' in general.ts
    configure: z.object({
      title: z.string(),
      defaults: z.array(z.string()),
      view: z.array(NestedOptionsBasicViewItem),
      transposedColumns: zTransposedColumns,
    }),
    timeLevels: TimeLevels.optional(),
  })
);

export const NestedStyleOvertimeViewDefn = BaseViewDefn.merge(
  z.object({
    title: z.string(),
    main: GridMainViewDefn,
    columns: z.array(BasicViewItem),
    companionGrid: z.object({
      label: z.string(),
      sortConfig: z.object({
        defaultSelection: z.string(),
        options: z.array(BasicViewItem),
      }),
    }),
    timeLevels: TimeLevels.optional(),
  })
);

export const CanvasViewDefn = BaseViewDefn.merge(
  z.object({
    main: MainViewDefnConfig,
    model: z.string().optional(),
    view: z.array(BasicViewItem),
    text: z.string().optional(),
    assortmentModel: z.string().optional(),
  })
);

export const SummaryViewDefn = BaseViewDefn.merge(
  z.object({
    main: MainViewDefnConfig,
    view: z.array(BasicViewItem),
  })
);

export const GridViewDefn = BaseViewDefn.merge(
  z.object({
    type: z.literal('grid'),
    main: GridMainViewDefn,
    view: z.array(BasicViewItem.or(NestedColumnsBasicViewItem)),
    searchIndexes: z.array(z.string()).optional(),
  })
);

export const FlowTypeViewDefn = BaseViewDefn.merge(
  z.object({
    searchIndexes: z.array(z.string()).optional(),
    main: MainViewDefnConfig,
    view: z.array(BasicViewItem),
  })
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TopTYvsLYViewDefn = BaseViewDefn.merge(
  z.object({
    searchIndexes: z.array(z.string()).optional(),
    main: MainViewDefnConfig,
    view: z.array(BasicViewItem),
  })
);

export const CollectionViewDefn = BaseViewDefn.merge(
  z.object({
    type: z.literal('rollUp'),
    searchIndexes: z.array(z.string()).optional(),
    view: z.array(BasicViewItem),
  })
);

export const FloorsetComparisonViewDefn = BaseViewDefn.merge(
  z.object({
    main: MainView,
    view: z.array(BasicViewItem),
  })
);

export const zFlowSheetGridDefn = BaseViewDefn.merge(
  z.object({
    inputType: z.literal('grid'),
    model: z.string(),
    main: GridMainViewDefn,
    view: z.array(BasicViewItem),
    timeLevels: TimeLevels.optional(),
  })
);

export interface FlowSheetGridDefn extends z.infer<typeof zFlowSheetGridDefn> { }

const MassEditViewDefn = z.union([
  z.object({
    title: z.string(),
    modifierTypes: z.array(BasicViewItem).optional(),
    dataIndex: z.string().optional(),
  }),
  z.object({
    dataIndex: z.string(),
    editor: z.string(),
    title: z.string(),
  }),
]);

export const MassEditDefn = BaseViewDefn.merge(
  z.object({
    hideEmpty: z.boolean().optional(),
    views: z.array(MassEditViewDefn),
  })
);

// this PricingOverTime and GridViewDefn are exactly same shape, but only 1 minor differrent view and views.
export const PricingOverTimeDefn = BaseViewDefn.merge(
  z.object({
    type: z.literal('grid'),
    main: GridMainViewDefn,
    views: z.array(BasicViewItem),
    searchIndexes: z.array(z.string()).optional(),
  })
);
export const zMacroMixViewDefn = z.object({
  type: z.literal('screen'),
  view: z.array(
    z.object({
      xtype: z.string(),
      text: z.string().optional(),
      type: z.string().optional(),
      dataIndex: z.string().optional(),
      renderer: z.string().optional(),
      mask: z.string().optional(),
      view: z.array(
        z.object({
          xtype: z.string().optional(),
          text: z.string().optional(),
          dataIndex: z.string().optional(),
          renderer: z.string().optional(),
          modelId: z.string().optional(),
          mask: z.string().optional(),
          view: z
            .array(
              z.object({
                dataIndex: z.string(),
                dimension: z.string().optional(),
                text: z.string().optional(),
                renderer: z.string().optional(),
                bubble: z
                  .object({
                    dataIndex: z.string(),
                    renderer: z.string(),
                    text: z.string(),
                  })
                  .optional(),
              })
            )
            .optional(),
        })
      ),
    })
  ),
});
export interface MacroMixViewDefn extends z.infer<typeof zMacroMixViewDefn> { }

export const SizeEligibilityListGridDefn = BaseViewDefn.merge(
  z.object({
    id: z.string(),
    type: z.string(),
    main: z.object({
      title: z.string(),
    }),
    companionApi: CompanionViewDefn,
    update: z.object({
      type: z.string(),
      keys: z.object({
        product: z.string(),
        cluster: z.string(),
      }),
    }),
    columns: z.array(BasicViewItem),
  })
);
// This type below for ParameterToggle, but I think it's already validated with the type,
// So I still keep it here in case we need to use it. At the end, if we don't need to use this,
// then I will delete it later
// const ParameterTogglesFields = z.object({
//   text: z.string(),
//   editor: z.string(),
//   radioFields: z.array(BasicViewItem),
// });

// const ParameterTogglesSections = z.object({
//   title: z.string(),
//   icon: z.string(),
//   fields: z.array(ParameterTogglesFields),
// });

// export const ParameterTogglesDefn = z.object({
//   title: z.string(),
//   sections: z.array(ParameterTogglesSections),
//   allocPlanModel: z.string(),
// });

export const PublishAllocationGridDefn = BaseViewDefn.merge(
  z.object({
    dataApi: z.object({
      isListData: z.boolean(),
      defnId: z.string(),
      params: z.object({
        aggBy: z.string(),
      }),
    }),
    main: GridMainViewDefn,
    title: z.string(),
    view: z.array(BasicViewItem),
  })
);

const zChartTooltipConfig = z.object({
  dataIndex: z.string(),
  renderer: z.string(),
  text: z.string(),
});

export interface zChartTooltipConfig extends z.infer<typeof zChartTooltipConfig> { }
export const zGeoTrendsMapDefn = BaseViewDefn.merge(
  z.object({
    defaultAggBy: z.string(),
    default: z.string(),
    mapUri: z.string(),
    bubble: zChartTooltipConfig,
    map: BasicViewItem.merge(
      z.object({
        minSize: z.string().or(z.number()),
        maxSize: z.string().or(z.number()),
        type: z.string(),
        trendDataIndex: z.string(),
      })
    ),
    view: z.array(
      BasicViewItem.merge(
        z.object({
          groupingKey: z.string(),
          geoLocationKeys: z.array(z.string()),
        })
      )
    ),
    trends: z.array(
      z.object({
        text: z.string(),
        color: z.string(),
        trendValueMap: z.string().or(z.number()),
      })
    ),
  })
);
export interface GeoTrendsMapDefn extends z.infer<typeof zGeoTrendsMapDefn> { }

export const zGeoTrendsChartsDefn = BaseViewDefn.merge(
  z.object({
    view: z.array(
      BasicViewItem.merge(
        z.object({
          view: z.array(
            BasicViewItem.merge(
              z.object({
                bubble: zChartTooltipConfig,
              })
            )
          ),
        })
      )
    ),
  })
);
export interface GeoTrendsChartsDefn extends z.infer<typeof zGeoTrendsChartsDefn> { }

export const zTrendCardType = z.enum(['metric', 'prediction']);
export type TrendCardType = z.infer<typeof zTrendCardType>;

export const zVariancePillConfig = z.object({
  valueDataIndex: z.string(),
  varianceDataIndex: z.string().or(z.null()),
  text: z.string(),
});
export interface VariancePillConfig extends z.infer<typeof zVariancePillConfig> { }

const zTrendSummaryCardConfig = z.object({
  trendCardType: zTrendCardType,
  title: z.string(),
  trendDataIndex: z.string(),
  trendRenderer: z.string(),
  trendSummary: z.string(),
  currentDataIndex: z.string(),
  currentRenderer: z.string(),
  pills: z.array(zVariancePillConfig),
});
export interface TrendSummaryCardConfig extends z.infer<typeof zTrendSummaryCardConfig> { }

const zTrendSummaryConfig = z
  .object({
    type: z
      .literal('trendCards')
      .optional()
      .default('trendCards'),
    cards: z.array(zTrendSummaryCardConfig),
  })
  .merge(hasCardWidth);
export interface TrendSummaryConfig extends z.infer<typeof zTrendSummaryConfig> { }
export interface TrendSummaryConfig extends z.infer<typeof zTrendSummaryConfig> { }

const zTrendBubblesConfig = z
  .object({
    type: z
      .literal('trendBubbles')
      .optional()
      .default('trendBubbles'),
    graph: z.object({
      dataIndex: z.string(),
      groupDataIndex: z.string(),
      nameDataIndex: z.string(),
    }),
    chartOptions: z.object({
      chart: z.object({
        type: z.literal('packedbubble'),
      }),
      // taken from geotrends to match the existing bubble config we already have,
      // otherwise this is a straight copy of the highcharts config
      bubble: zChartTooltipConfig,
      // `plotOptions` here is intended to be close to a 1:1 replica of the highcharts `packedbubble` chart type
      plotOptions: z.object({
        packedbubble: z.object({
          minSize: z.string().optional(),
          maxSize: z.string().optional(),
          zMin: z.number().optional(),
          zMax: z.number().optional(),
          layoutAlgorithm: z.object({
            gravitationalConstant: z.number().optional(),
            splitSeries: z.boolean().optional(),
            seriesInteraction: z.boolean().optional(),
            dragBetweenSeries: z.boolean().optional(),
            parentNodeLimit: z.boolean().optional(),
          }),
          dataLabels: z.object({
            enabled: z
              .boolean()
              .optional()
              .default(true),
            format: zChartTooltipConfig,
            filter: z.object({
              property: z.string().optional(),
              operator: z.string().optional(),
              value: z.number().optional(),
            }),
            style: z.object({
              color: z.string().optional(),
              textOutline: z.string().optional(),
              fontWeight: z.string().optional(),
            }),
          }),
        }),
      }),
    }),
  })
  .merge(hasCardWidth);
export interface TrendBubblesConfig extends z.infer<typeof zTrendBubblesConfig> { }
