import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import styles from './ComboGraph.styles';
import { GraphData, ComboGraphProps, ComboRowObject } from './ComboGraph.types';
import { ComboGraph } from '../TargetSetting.types';
import Renderer from 'src/utils/Domain/Renderer';

export default class ComboGraphView extends React.Component<ComboGraphProps> {
  constructor(props: ComboGraphProps) {
    super(props);
  }

  compileData(config: ComboGraph, rowData: ComboRowObject[]): GraphData {
    const title = config.title;
    const renderer = config.renderer;
    const categories: string[] = [];
    const columnData: number[] = [];
    const column2Data: number[] = [];

    rowData.forEach((row) => {
      // Changed row.name to row.description, as it would change "Miscellaneous" to "Balance"
      categories.push(row.description);
      columnData.push(row[config.targetProperty]);
      column2Data.push(row[config.lyProperty]);
    });

    return {
      title,
      categories,
      columnData,
      column2Data,
      renderer,
    };
  }

  getGraph(data: GraphData): JSX.Element {
    const config = {
      title: {
        text: data.title,
        style: {
          fontSize: '14px',
          margin: 5,
        },
      },
      xAxis: {
        categories: data.categories,
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          formatter: function(this: { value: number }) {
            return data.renderer ? Renderer[data.renderer](this.value) : this.value;
          },
        },
      },
      credits: false,
      chart: {
        height: '300px',
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      series: [
        {
          type: 'column',
          data: data.columnData,
          name: 'Target ' + data.title,
        },
        {
          type: 'column',
          data: data.column2Data,
          name: 'LY ' + data.title,
        },
      ],
      tooltip: {
        formatter: function(this: { series: { name: string; index: number }; point: { y: number; category: string } }) {
          const value = data.renderer ? Renderer[data.renderer](this.point.y) : this.point.y;
          return `<span style="font-size: 10px">${this.point.category}</span><br/>${this.series.name}: <b>${value}</b>`;
        },
      },
    };
    return <HighchartsReact options={config} />;
  }
  render() {
    const { rowData, config } = this.props;
    const allData: GraphData[] = [];
    if (!config || !rowData) {
      return <div />;
    }

    config.forEach((conf) => {
      allData.push(this.compileData(conf, rowData));
    });

    return (
      <div className={styles.mainBody}>
        <div className={styles.childrenContainer}>
          {allData
            .slice()
            .splice(0, Math.ceil(allData.length / 2))
            .map((data) => {
              return (
                <div className={styles.childContainer} key={data.title}>
                  {this.getGraph(data)}
                </div>
              );
            })}
        </div>
        <div className={styles.childrenContainer}>
          {allData
            .slice()
            .splice(Math.ceil(allData.length / 2), allData.length)
            .map((data) => {
              return (
                <div className={styles.childContainer} key={data.title}>
                  {this.getGraph(data)}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
