import { isEmpty } from 'lodash';
import { BindAll } from 'lodash-decorators';
import React from 'react';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import './_PivotConfiguratorFilter.scss';

interface FilterProps {
  onChange: (value: string) => void
}

interface FilterState {
  // leave this here so the text box changes immediately,
  // but we can debounce the filter computation itself
  value: string
}

@BindAll()
class PivotConfiguratorFilter extends React.Component<
  FilterProps,
  FilterState
  > {
  public constructor(props: FilterProps) {
    super(props);

    this.state = {
      value: ''
    };
  }

  public handleChange(e: React.SyntheticEvent<HTMLInputElement, Event>, data: InputOnChangeData) {
    const { onChange } = this.props;
    const value = data.value;

    if (onChange) {
      onChange(value);
    }
    this.setState({
      value
    });
  }

  public handleClearFilter() {
    const { onChange } = this.props;
    const clear = '';

    if (onChange) {
      onChange(clear);
    }
    this.setState({
      value: clear
    });
  }

  public render() {
    const { value } = this.state;
    const clearVisible = isEmpty(value) ? 'hidden' : 'visible';

    return (
      <div className="search-box">
        <div className={'fuzzy-search'}>
          Filter Items
          <Input
            type="text"
            onChange={this.handleChange}
            autoFocus={false}
            value={value}
          />
          <i
            className={'far fa-fw fa-lg fa-times clear-icon'}
            onClick={this.handleClearFilter}
            style={{ visibility: clearVisible }}
          />
          <i className={'far fa-fw fa-filter search-icon'} />
        </div>
      </div>
    );
  }
}

export default PivotConfiguratorFilter;
