import { isNil, isEmpty, findIndex } from 'lodash';
import { createSelector } from 'reselect';
import {
  getFloorsetData,
  getSelectedFloorset,
  SelectorSubheaderDropdownProps,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.selectors';
import { parseFloorsetDropdownData } from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';
import { AppState } from 'src/store';
import { ParameterTogglesConfig } from './ParameterToggles.types';

const getViewDefn = (state: AppState) => {
  return state.pages.allocation.parameterToggles.viewDefn;
};
export const getTopMemberDropdownProps = createSelector(
  getFloorsetData,
  getSelectedFloorset,
  getViewDefn,
  (floorsets, selectedFloorset, viewDefn) => {
    if (isEmpty(floorsets)) {
      return;
    }

    const options = parseFloorsetDropdownData(floorsets);
    const selection = isNil(selectedFloorset) ? 0 : findIndex(options, (option) => option.text === selectedFloorset);
    const label = viewDefn?.subheaderDropdowns?.topMember?.label || 'Floorset';
    const floorsetDropdownProps: SelectorSubheaderDropdownProps = {
      label,
      defaultSelection: 0,
      selection,
      options,
    };

    return floorsetDropdownProps;
  }
);
