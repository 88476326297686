import { SyntheticEvent } from 'react';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

export interface FitViewState {
  hoverId: string;
  loadingImages: boolean;
  headerWidth?: number;
}

export enum PositionStatus {
  ENTERING = 0,
  POSITIONED = 1,
  EXITING = 2,
}
export interface ExtrinsicItemData {
  uid: string;
  groupIndex: number;
  index: number;
  id: string;
  image: string;
  positionStatus: PositionStatus;
}

export interface IntrinsicItemData {
  width: number;
  height: number;
  left: number;
  top: number;
  showBorder?: boolean;
  isAnimated: boolean;
  renderedImage: string;
  useSrc?: boolean;
}

export interface ItemData extends IntrinsicItemData, ExtrinsicItemData {
  adornments?: AdornmentType[]
}

export interface FitViewItemPayload {
  id: string;
  description: string;
  image: string;
}

export interface HeaderInfo {
  groupIndex?: number;
  maxHeaderHeight?: number;
  width?: number;
  isAnimated?: boolean;
  header: string;
  subheader: { name: string; value: string }[];
  ref?: React.ReactNode;
}

export interface FitViewGroupPayload extends HeaderInfo {
  items: FitViewItemPayload[];
}

export interface FitViewData {
  height: number;
  width: number;
  useSrc?: boolean;
  isOverloaded?: boolean;
  noImageUrl: string;
  onItemClick?: (item: ItemData, event?: SyntheticEvent<HTMLDivElement>) => void;
  groups: FitViewGroupPayload[];
  adornments?: AdornmentType[]
}
