import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Observable, Subscription, catchError, from } from 'rxjs';
import AdminService, { PlansFilters } from 'src/services/Admin.service';
import { ContextMfpModule } from 'src/services/configuration/codecs/confdefn';
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';

interface PlanState {
  plans: PlanMetadata[] | undefined;
  loading: boolean;
}

function onEmit<T>(source$: Observable<T>, nextFn: (value: T) => void): Subscription {
  return source$.subscribe(nextFn, window.console.error);
}

/**
 * Custom hook to manage PlanMetadata
 */
// TODO add setPerspective and setFilters that refetches when they change
export function usePlanMetadata(
  client: AxiosInstance,
  module: ContextMfpModule,
  filters?: PlansFilters
): [PlanState, (newFilters: PlansFilters) => void, () => void] {
  const [_filters, setFilters] = useState<PlansFilters | undefined>(filters);
  const [currentModule] = useState<ContextMfpModule>(module);
  const [state, setState] = useState<PlanState>({ plans: undefined, loading: false });
  const _forceRefresh = useCallback(() => {
    // spread this to force a re-render
    setFilters({ ..._filters });
  }, [_filters]);

  /**
   * Load all plans and send state mutations on emit
   */
  useEffect(() => {
    if (_filters && _filters.time && _filters.version && _filters.version.length > 0) {
      const obs = from(new AdminService(client).getPlans(currentModule.pathSlot, currentModule.siloId, _filters)).pipe(
        catchError(() => {
          setState((state) => ({ ...state, plans: [], loading: true }));
          return [];
        })
      );
      setState({ plans: state.plans, loading: true });

      const subscriptions: Subscription[] = [
        onEmit<PlanMetadata[]>(obs, (plans) => {
          setState((state) => {
            return { ...state, plans, loading: false };
          });
        }),
      ];
      return () => {
        subscriptions.map((it) => it.unsubscribe());
      };
    }
    // dep checks here disabled as the subscription is stored in the closure,
    // and unmounting the hook clears the promise return after setting loading flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_filters, currentModule.pathSlot, currentModule.siloId, client]);

  return [state, setFilters, _forceRefresh];
}
