import { style } from 'typestyle';
import { important } from 'csx';

export default style({
  $nest: {
    '&>*:first-child': {
      $nest: {
        '& .ag-root-wrapper': {
          $nest: {
            '& .ag-root-wrapper-body': {
              $nest: {
                '& .ag-root': {
                  $nest: {
                    // We hide the header entirely since the popover grids don't use the grid headers (instead they build their own)
                    '& .ag-header-viewport,& .ag-header': {
                      display: 'none'
                    },
                    '& .ag-body-viewport': {
                      $nest: {
                        // Left/title column
                        '& .ag-pinned-left-cols-container': {
                          width: important('120px'),
                          minWidth: important('100px'),
                          $nest: {
                            '& .ag-row': {
                              border: 0,
                              background: 'white',
                              $nest: {
                                '& .ag-cell': {
                                  fontSize: important('0.7rem'),
                                  textAlign: 'right',
                                  display: 'inline-block',
                                  width: important('120px'),
                                  minWidth: important('100px'),
                                  paddingLeft: important('0'),
                                },
                              },
                            },
                          },
                        },
                        // Main body / right side
                        '& .ag-center-cols-viewport': {
                          overflow: 'hidden',
                          $nest: {
                            '& .ag-center-cols-container': {
                              $nest: {
                                '& .ag-row': {
                                  border: 0,
                                  background: 'white',
                                  $nest: {
                                    '& .ag-cell': {
                                      fontSize: important('0.7rem'),
                                      color: '#00a89b',
                                      textAlign: 'left',
                                      display: 'inline-block',
                                    },
                                    '& .ag-cell-inline-editing': {
                                      height: '100%',
                                      padding: '0 24px 10px 24px',
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
