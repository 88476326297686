import { ofType } from 'redux-observable';
import { mergeMap, filter } from 'rxjs';
import { AppEpic, AppThunkDispatch } from 'src/store';
import {
  GridDefnConfig,
  onShowQuickReconcile,
  receiveError,
  receiveQuickReconcileConfigs,
  receiveQuickReconcileViewDefns,
  requestQuickReconcileConfigs,
  updateQuickReconcileGroupBy,
} from './QuickReconcile.slice';
import { of } from 'rxjs';
import { cloneDeep, isNil, isEqual } from 'lodash';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import { AnyAction as BaseAction } from 'redux';
import container from 'src/ServiceContainer';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { QuickReconButton } from 'src/services/configuration/codecs/confdefn';
import { getActivePerspectiveQuickReconButtonConfig } from 'src/pages/PerspectiveSelection/PerspectiveSelection.selectors';

function fetchQuickReconcileConfig(quickReconButtonConfig: QuickReconButton, groupBy: GroupBySlice) {
  return async (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    const { defns } = quickReconButtonConfig;
    const { tenantConfigClient } = container;
    const tenantParams = {
      defnIds: defns.view,
      appName: ASSORTMENT,
    };
    dispatch(requestQuickReconcileConfigs());
    Promise.all([tenantConfigClient.getTenantViewDefns<GridDefnConfig>(tenantParams)])
      .then(([configs]) => {
        const defaultGroupByConf = configs[0].subheaderDropdowns;
        const currentGroupBy = cloneDeep(groupBy);
        dispatch(
          receiveQuickReconcileViewDefns({
            groupBy: defaultGroupByConf,
          })
        );
        dispatch(receiveQuickReconcileConfigs({ gridDefn: configs[0] }));

        if (!isNil(currentGroupBy.selection)) {
          if (!isEqual(defaultGroupByConf.view, currentGroupBy.options)) {
            const currentGroupBySelection = currentGroupBy.options[currentGroupBy.selection];
            const newGroupByIndex = defaultGroupByConf.view.findIndex(
              (opt) => opt.dataIndex === currentGroupBySelection.dataIndex
            );
            if (newGroupByIndex >= 0) {
              dispatch(updateQuickReconcileGroupBy(newGroupByIndex));
            }
          } else {
            dispatch(updateQuickReconcileGroupBy(currentGroupBy.selection));
          }
        }
      })
      .catch(() => {
        dispatch(receiveError());
      });
  };
}

const showQuickReconcileEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(onShowQuickReconcile.type),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(() => {
      const groupBy = state$.value.quickReconcile.groupBy;
      const quickReconButtonConfig = getActivePerspectiveQuickReconButtonConfig(state$.value);
      if (isNil(quickReconButtonConfig)) return [];
      return of(fetchQuickReconcileConfig(quickReconButtonConfig, groupBy));
    })
  );
};

export default showQuickReconcileEpic;
