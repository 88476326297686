import { createAsyncThunk } from '@reduxjs/toolkit';
import { WorklistClient } from './BulkImport.client';
import { ThunkApi } from 'src/store';
import { BulkImportMutation, BulkImportNotice } from './BulkImport.slice';

export const getMutations = createAsyncThunk<BulkImportMutation[], undefined, ThunkApi & { rejectValue: string }>(
  'BulkImport/getMutations',
  async (_none, { rejectWithValue }) => {
    try {
      return WorklistClient().getMutations();
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);

export const uploadMutationTemplate = createAsyncThunk<
  BulkImportNotice,
  {
    importId: string;
    formData: FormData;
  },
  ThunkApi & { rejectValue: string }
>('BulkImport/uploadMutationTemplate', async (payload, { rejectWithValue, getState }) => {
  const appName = getState().perspective.selected?.appType;
  if (!appName) {
    return rejectWithValue("AppName not found, this shouldn't happen");
  }
  try {
    return await WorklistClient().uploadMutationTemplate(payload.importId, payload.formData, appName);
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});

export const getNotices = createAsyncThunk<BulkImportNotice[], string, ThunkApi & { rejectValue: string }>(
  'BulkImport/getNotices',
  async (runId: string, { rejectWithValue }) => {
    try {
      return WorklistClient().getNotices(runId);
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);
export const commitBulkImport = createAsyncThunk<
  void,
  { importId: string; runId: string },
  ThunkApi & { rejectValue: string }
>('BulkImport/getNotices', async (payload: { importId: string; runId: string }, { rejectWithValue }) => {
  try {
    return WorklistClient().commitBulkImport(payload.importId, payload.runId);
  } catch (err) {
    return rejectWithValue((err as Error).message);
  }
});
