import * as React from 'react';
import { AppState } from 'src/store';
import { wrappedThunkDispatch, WrappedThunkDispatch } from 'src/utils/Redux/Dispatch';
import { Fab, Tooltip } from '@material-ui/core';
import { fabBtn } from 'src/pages/AssortmentStrategy/TargetSetting/TargetList/TargetList.styles';
import { classes } from 'typestyle';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';
import { connect } from 'react-redux';
import { identity } from 'src/utils/Primitive/Function';

type InjectedProps<ParentProps> = {
  updateAssortmentPlan: () => void;
  isPrintMode: boolean;
  parentProps: ParentProps;
};

/**
 * @deprecated
 *
 * The planning Fab logic is now available with the rest of the Fab logic setup in `withFab.tsx`.
 * This HOC is still here for only for Functional Components to use. All class components have been converted to use `withFab`.
 * */
export function makeUpdateAssortmentFabSensitive<P>(WrappedComponent: React.ComponentType<P>) {
  function mergeProps(state: AppState, wrapped: WrappedThunkDispatch, parentProps: P) {
    const props: Record<string, any> = {
      isPrintMode: state.print.isPrintMode,
      updateAssortmentPlan: () => {
        wrapped.dispatch(updateAssortmentPlan('PlanQueue'));
      },
    };
    return {
      ...props,
      parentProps,
    };
  }

  class UpdateAssortmentFab extends React.Component<InjectedProps<P>> {
    render() {
      if (this.props.isPrintMode) {
        // @ts-ignore
        return <WrappedComponent {...this.props.parentProps} />;
      }

      return (
        <React.Fragment>
          <section className={fabBtn}>
            <Tooltip title="Replan pending items" enterDelay={0} arrow>
              <Fab
                color="secondary"
                aria-label="Update Plan"
                onClick={() => {
                  this.props.updateAssortmentPlan();
                }}
              >
                <i style={{ color: 'white' }} className={classes('far fa-lg fa-paper-plane')} />
              </Fab>
            </Tooltip>
          </section>
          {/* @ts-ignore */}
          <WrappedComponent {...this.props.parentProps} />
        </React.Fragment>
      );
    }
  }
  // @ts-ignore
  return connect(identity, wrappedThunkDispatch, mergeProps)(UpdateAssortmentFab);
}
