import React from 'react';
import ReportCard from '../../../components/ReportCard/ReportCard';
import ReportGroup from '../../../components/ReportCard/ReportGroup';
import { ReportConfig, ReportItem, ReportCollection } from 'src/services/Reports.types';
import { AppState } from 'src/store';
import { connect } from 'react-redux';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';
import ReportClient from 'src/services/Reports.client';
import { ServerScopeResponse, isScopeReady, getScopeReadyData, getScopeId } from 'src/state/scope/Scope.types';
import { SettingsByKey } from 'src/services/Settings';
import { getAvailableListing } from 'src/components/Mfp/PivotConfigurator/utils';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { WorkingSetReport } from 'src/state/workingSets/workingSets.slice';
import { CONTEXT_READY, CONTEXT_PENDING } from 'src/state/workingSets/workingSets.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { isNil } from 'lodash';
import serviceContainer from 'src/ServiceContainer';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

export interface ReportingState {
  reportConfig?: ReportConfig;
  reportsMap?: Map<string, ReportItem>;
  isLoading: boolean;
  reportUrl: null | string;
  currentReportId: string | null;
}

interface ReportingProps {
  mainConfig: ServerScopeResponse | undefined;
  settings: SettingsByKey;
  scopeId: string | undefined;
  reports: WorkingSetReport[];
  currentModule: string | undefined;
  siloId: string | undefined;
}

function mapStateToProps(state: AppState): ReportingProps {
  const currentModule = getMfpModule(state);
  const currentModuleId = currentModule?.id;
  const currentModuleSiloId = currentModule?.siloId;

  return {
    currentModule: currentModuleId,
    siloId: currentModuleSiloId,
    mainConfig: getScopeReadyData(state.mfpScope)?.mainConfig,
    settings: state.settings.entriesByKey,
    scopeId: getScopeId(state.mfpScope),
    reports: state.workingSets.reports,
  };
}

class Reporting extends React.Component<ReportingProps, ReportingState> {
  private reportClient: ReportClient | undefined;
  private perspective: ReportingProps['currentModule'] = undefined;
  private filterReportsByPerspective = (g: ReportCollection) => {
    return g.perspective === this.perspective;
  };
  public reportToken: {
    token: string | null;
  } = {
    token: null, // default
  };

  constructor(props: ReportingProps) {
    super(props);
    this.reportToken.token = serviceContainer.lastBearerToken();
    this.handleClickReport = this.handleClickReport.bind(this);
    this.perspective = props.currentModule;

    this.state = {
      isLoading: true,
      reportUrl: null,
      currentReportId: null,
    };
  }

  private reportIdToUri(id: string) {
    if (!this.reportToken.token) {
      throw new Error('No Authentication Token Found');
    }
    return `${API_BASE_URL}/drop/${id}?${queryString.stringify(this.reportToken)}`;
  }

  componentDidMount() {
    const { siloId } = this.props;
    if (siloId) {
      serviceContainer.config
        .getReportConfig(siloId)
        .then((maybeReportConfig) => {
          const reportsMap = new Map<string, ReportItem>();
          maybeReportConfig.reportGroups.filter(this.filterReportsByPerspective).forEach((grp) => {
            grp.items.forEach((itm) => {
              reportsMap.set(itm.id, itm);
            });
          });

          this.setState({
            reportConfig: maybeReportConfig,
            reportsMap,
            isLoading: false,
          });
        })
        .catch((e) => {
          toast.error('An error occured fetching the reports list');
        });
    }
  }

  private catchReportFail = (reason: any) => {
    window.console.log(reason);
    this.setState(
      {
        currentReportId: null,
      },
      () => {
        toast.error('An error has occued generating your report', {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    );
  };

  componentDidUpdate(_prevProps: ReportingProps, _prevState: ReportingState) {
    const { scopeId, mainConfig, settings, reports } = this.props;
    const { reportsMap } = this.state;

    if (reportsMap) {
      // this controls the download mechanism, since it's async and reads from redux
      // report status' are reported in redux, but pending download is reported in local component state
      const pendingReports = Array.from(reportsMap.values())
        .filter((maybePending) => !isNil(maybePending.pendingReports))
        .map((rep) => rep);
      const readyReports = reports.filter((maybeReady) => maybeReady.status === CONTEXT_READY).map((fRep) => fRep.id);

      // TODO: only allow one instance of a specific report to download at a time,
      // then clean this up a little so we can display multilpe spinners for each report
      pendingReports.forEach((pRep) => {
        readyReports.forEach((rRep) => {
          if (!isNil(pRep.pendingReports![rRep])) {
            window.location.href = this.reportIdToUri(rRep);
            delete pRep.pendingReports![rRep];
            this.setState({
              currentReportId: null,
            });
          }
        });
      });
    }

    if (mainConfig && scopeId && settings && !this.reportClient) {
      // this is just for building the report client
      const { mainConfig, settings, scopeId } = this.props;
      if (isScopeReady(mainConfig) && scopeId && this.reportToken.token) {
        const allAvailableListing = getAvailableListing(mainConfig!, settings);
        // TODO: Can we break this out into some kind of factory?
        this.reportClient = new ReportClient({
          client: serviceContainer.axios,
          allAvailableListing: allAvailableListing,
          mainConfig: mainConfig,
          scopeId: scopeId,
          token: this.reportToken.token,
        });
      }
    }
  }

  handleClickReport(this: Reporting, e: React.MouseEvent<HTMLSpanElement>) {
    const currentReportId = e.currentTarget.id;

    this.setState(
      {
        currentReportId: e.currentTarget.id, // for the loading spinner
      },
      () => {
        if (!this.state.reportsMap) {
          throw new Error('The reports config should exist');
        }
        const reportItem = this.state.reportsMap.get(currentReportId);
        // TODO create and pass a loading flag for the card down while we generate the context
        if (reportItem && reportItem.type === 'report' && this.reportClient) {
          const requestCreateReportPromise = this.reportClient.requestCreateReport(reportItem);
          requestCreateReportPromise.catch(this.catchReportFail);
          requestCreateReportPromise.then((reportRequest) => {
            const reportId = reportRequest.data.id;
            if (reportItem.pendingReports) {
              reportItem.pendingReports[reportId] = reportRequest.statusText;
            } else {
              reportItem.pendingReports = { [reportId]: CONTEXT_PENDING };
            }
          });
        }
      }
    );
  }

  public render() {
    const { reportsMap, isLoading } = this.state;
    return (
      <div className="report-card-container">
        {isLoading ? <LoadingMask /> : null}
        {this.state.reportConfig &&
          this.state.reportConfig.reportGroups.filter(this.filterReportsByPerspective).map((group, groupIndex) => {
            return !reportsMap ? null : (
              <ReportGroup key={groupIndex} title={group.name}>
                {group.items
                  .map((item) => {
                    if (item.id === this.state.currentReportId) {
                      return {
                        ...item,
                        loading: true,
                      };
                    }
                    return item;
                  })
                  .map((item, i) => (
                    <ReportCard key={i} item={item} onDownloadReport={this.handleClickReport} />
                  ))}
              </ReportGroup>
            );
          })}
      </div>
    );
  }
}
export default connect(mapStateToProps)(Reporting);
