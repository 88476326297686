import React from 'react';
import { makeObjectDeserializer } from 'src/dao/objectDeserializer';
import { makePivotClient } from 'src/dao/pivotClient';
import { makePivotService, PivotService } from 'src/services/pivotService';
import { PivotServiceType } from 'src/worker/pivotWorker.types';

// This context is only used by StyleEdit, which needs its own separate cache

const pivotService = makePivotService(PivotServiceType.styleEdit, makePivotClient(makeObjectDeserializer()));
export const LocalPivotServiceContext = React.createContext<PivotService>(pivotService);

export const UserProvider = LocalPivotServiceContext.Provider;
export const UserConsumer = LocalPivotServiceContext.Consumer;

export default LocalPivotServiceContext;
