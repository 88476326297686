import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateFlowStatus } from 'src/components/Subheader/Subheader.slice';
import { updateAggBys, fetchNestedAttributeData } from './NestedAttribute.slice';
import { inputIsNotNullOrUndefined, isScopeDefined } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  NestedAttributeComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { DataApi, isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import {
  ExtraPivotOptions,
  getConfigureModalAggBys,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { LEVEL_PROD_ROOT } from 'src/utils/Domain/Constants';
import {
  receiveScopeConfig,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';

export const nestedAttributeLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      // This view's initial navigation data load is not triggered by setActivePage
      // because the updateAggBys action is triggered in the onShowView container method
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      updateFlowStatus.type,
      updateAggBys.type,
      receiveScopeConfig.type
    ),
    map(() => maybeGetComponentProps<NestedAttributeComponent>(state$.value, ConfDefnComponentType.nestedAttribute)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope)),
    mergeMap(({ defns, topMembers }) => {
      const aggBys = state$.value.pages.hindsighting.aggregateViews.nestedAttribute.aggBys;
      const flowStatus = state$.value.subheader.flowStatus.join(',');

      const { model: modelDefn } = defns;
      // TODO: move this to config when ready
      const dataApi: DataApi = {
        defnId: modelDefn || defns.view[0],
        isListData: true,
        params: {
          aggBy: '', // no bottom aggBy currently needed for this view
          topAggBy: LEVEL_PROD_ROOT,
          topMembers,
        },
      };
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const aggBy = getConfigureModalAggBys(aggBys, dataApi);
      const options: ExtraPivotOptions = { flowStatus, aggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      return of(fetchNestedAttributeData(finalModelDefn, finalOptions));
    })
  );
};
