import React from 'react';
import { StateProps, DispatchProps } from './AssortmentScopeSelector.container';
import styles from './AssortmentScopeSelector.styles';
import DimensionForm from './DimensionForm/DimensionForm.container';
import AssortmentPeriodForm from './AssortmentPeriodForm/AssortmentPeriodForm.container';
import HindsightingPeriodForm from './HindsightingPeriodForm/HindsightingPeriodForm.container';
import { Overlay } from 'src/common-ui';
import Modal from '@material-ui/core/Modal';
import { Navigate } from 'react-router-dom';
import { classes } from 'typestyle';
import { WithRouterProps } from 'src/components/higherOrder/withRouter';
import { getPerspectiveLookupKey } from 'src/services/configuration/service';
import { Perspective } from 'src/services/configuration/codecs/bindings.types';

export interface ScopeSelectorProps extends StateProps, DispatchProps, Pick<WithRouterProps, 'routerLocation'> {}

export interface State {}

function parseHashSearchQuery(hash: string) {
  const searchInHash = hash.indexOf('?') > -1 ? hash.substr(hash.indexOf('?') + 1) : '';
  const params = new URLSearchParams(searchInHash);
  return params;
}

class AssortmentScopeSelector extends React.Component<ScopeSelectorProps, State> {
  constructor(props: ScopeSelectorProps) {
    super(props);
  }

  componentDidMount() {
    this.props.onRequestScope();
  }

  onSubmitClicked = (_event: React.MouseEvent<HTMLElement>) => {
    this.props.onSubmitScope(this.props.scope);
  };

  onCloseModal = () => {
    const { start, historyStart, productMember, locationMember } = this.props.selections;
    // gate this a little bit here so it doesnt fire so much
    if (start || historyStart || productMember || locationMember) {
      // SPIKE: this runs whenever the backdrop is clicked.  Does it need to?
      this.props.onRequestScope();
    }

    if (this.props.scope.valid) {
      this.props.onCloseScope();
    }
  };

  render() {
    const { scopeSelected, initialLoad, scopeLoading, routerLocation, perspective } = this.props;
    if (scopeSelected) {
      const params = parseHashSearchQuery(routerLocation.hash);
      const usher = params.get('usher');
      if (usher) {
        return <Navigate to={usher} />;
      }
    }

    if (initialLoad) {
      return null;
    }

    const perspectiveLookupKey = getPerspectiveLookupKey(perspective) as Perspective;
    return (
      <Modal
        className={styles.scopeOverlayAsst}
        open={!scopeSelected}
        onClose={this.onCloseModal}
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          top: '40px'
        }}
      >
        <div className={styles.scopeSelector}>
          <div className="scopeArrow" />
          <div className="scopeArrowLoading" style={{ display: scopeLoading ? 'inherit' : 'none' }} />
          <Overlay visible={scopeLoading} type="loading" />
          <header>Select Scope</header>
          <div className={classes(styles.formDiv, styles.scopeSelector)}>
            <DimensionForm perspective={perspectiveLookupKey} />
            <AssortmentPeriodForm perspective={perspectiveLookupKey} />
            <HindsightingPeriodForm />
            <div className={styles.scopeColumn}>
              <button
                onClick={this.onSubmitClicked}
                className={classes('btn btn-primary', styles.selectScopeButton)}
                data-qa="button-scopeselectorsubmit"
              >
                <i className="far fa-arrow-alt-circle-right" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AssortmentScopeSelector;
