import * as React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import styles from './MultiSelect.styles';

export default class MultiSelect extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedOptions: [],
      areAllSelected: false,
    };
  }

  handleChange = (event: any) => {
    this.setState({
      selectedOptions: event.target.value,
    });
  };

  render() {
    const selectedValue = this.state.selectedOptions;

    return (
      <div className={styles.root}>
        <FormControl className={styles.formControl}>
          <Select
            classes={styles.select}
            disableUnderline={true}
            input={<Input />}
            multiple={true}
            onChange={this.handleChange}
            value={selectedValue}
          >
            {this.props.options.map((value: any) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}
