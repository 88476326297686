import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateAppConfig } from './AppConfig.slice';
import { updateFlowStatus, updateFlowStatusConfig } from 'src/components/Subheader/Subheader.slice';
import { tenantTabHasPathSlot } from './codecs/bindings.types';

export const setupAppConfigFlowStatus: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateAppConfig),
    mergeMap(() => {
      const activeTenantConfig = state$.value.appConfig.tenantConfig;
      const activeTab = state$.value.perspective.activeTab;
      let flowStatusSelections = activeTenantConfig.flowStatus.defaultSelection;
      let flowStatusConfig = activeTenantConfig.flowStatus;

      if (activeTenantConfig && activeTab) {
        const tab = activeTenantConfig.tabs.find((tab) => tenantTabHasPathSlot(tab) && tab.pathSlot == activeTab);
        if (tab && tenantTabHasPathSlot(tab) && tab.flowStatusOverride && tab.flowStatusOverride.values) {
          flowStatusConfig = tab.flowStatusOverride;
          flowStatusSelections = tab.flowStatusOverride.defaultSelection;
        }
      }

      if (flowStatusSelections.length === activeTenantConfig.flowStatus.values.length) {
        flowStatusSelections = [];
      }

      return of(updateFlowStatus(flowStatusSelections), updateFlowStatusConfig(flowStatusConfig));
    })
  );
};
