import React, { useCallback, useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { getFrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { TrendingDown, TrendingFlat, TrendingUp } from '@material-ui/icons/';
import { Icon } from '@material-ui/core/';
import { get, isNil } from 'lodash';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import {
  generateColumnDefs,
  generatePillRendererProps,
  getChartOptions,
  getPillVisualAttributes,
} from 'src/components/TrendDetails/TrendDetails.utils';
import styles from './TrendDetails.styles';
import Renderer from 'src/utils/Domain/Renderer';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';

import Highcharts from 'highcharts';
import highchartsBullet from 'highcharts/modules/bullet';
import HighchartsReact from 'highcharts-react-official';
import { GRAY } from 'src/utils/Style/Theme';
import { classes } from 'typestyle';
highchartsBullet(Highcharts);

const VerticalStackRenderer = ({ data, colDef, node }: ICellRendererParams) => {
  if (isNil(data)) {
    return null;
  }
  const calculatedRowHeight = node.rowHeight ?? styles.DEFAULT_ROW_HEIGHT;
  const cellRendererParams = get(colDef, 'cellRendererParams', null);
  const subRenderer = get(cellRendererParams, 'subRenderer');
  const stacksConfig = get(cellRendererParams, 'stacks');
  const stacks: string[] = stacksConfig.map((stack: { dataIndex: string }) => {
    return Number.isFinite(data[stack.dataIndex]) ? Renderer[subRenderer](data[stack.dataIndex]) : '\u00A0';
  });

  return (
    <section className={styles.verticalStack(calculatedRowHeight, stacks.length)}>
      {stacks.map((s) => (
        <span key={s}>{s}</span>
      ))}
    </section>
  );
};

export const TrendRenderer = ({ value }: ICellRendererParams) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
      {(() => {
        switch (value) {
          case 'positive': {
            return (
              <React.Fragment>
                <TrendingUp style={{ color: '#6BB08B' }} />
              </React.Fragment>
            );
          }
          case 'negative': {
            return (
              <React.Fragment>
                <TrendingDown style={{ color: '#D04B32' }} />
              </React.Fragment>
            );
          }
          default: {
            return (
              <React.Fragment>
                <TrendingFlat style={{ color: GRAY }} />
                <span>{'Steady'}</span>
              </React.Fragment>
            );
          }
        }
      })()}
    </div>
  );
};

export interface VariancePillVisualAttributes {
  color: string;
  icon: string;
}

export interface VariancePillProps {
  text: string;
  value: number;
  valueText: string;
  variance: number | null;
  varianceText: string;
}
export const VariancePillRenderer = (props: { pills: VariancePillProps[] }) => {
  return (
    <div className="pills">
      {props.pills.map((pill, idx) => {
        const pillVisual = getPillVisualAttributes(pill.variance);
        return (
          <div className="pill-group" key={idx}>
            <div className="tinynumber">
              <span>
                {pill.text} {pill.valueText}
              </span>
            </div>
            {!isNil(pillVisual) ? (
              <div style={{ background: pillVisual.color }} className="pillpercent">
                <Icon className={pillVisual.icon} />
                <span>{pill.varianceText}</span>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

interface VarianceBarProps {
  value: number;
  target: number;
}
export interface VarianceBarTooltipProps {
  header: string;
  value: string;
  target: string;
  renderer: string;
  dataSuffix: string;
}
interface VarianceBarRendererProps {
  columnWidth: number | undefined;
  rowHeight: number;
  tooltip: VarianceBarTooltipProps;
  bars: VarianceBarProps[];
}
const VarianceBarRenderer = ({ bars, tooltip, rowHeight, columnWidth }: VarianceBarRendererProps) => {
  const data = bars.map((bar) => {
    return {
      target: bar.target,
      y: bar.value,
    };
  });
  return (
    <div className="bullet-chart-container">
      <HighchartsReact highcharts={Highcharts} options={getChartOptions(data, tooltip, rowHeight, columnWidth)} />
    </div>
  );
};

const VarianceRenderer = ({ data, colDef, node, column }: ICellRendererParams) => {
  const columnWidth = column?.getActualWidth();
  const calculatedRowHeight = node.rowHeight ?? styles.DEFAULT_ROW_HEIGHT;
  const cellRendererParams = get(colDef, 'cellRendererParams', null);
  const pills = get(cellRendererParams, 'pills');
  const bar = get(cellRendererParams, 'bar');
  const pillProps = generatePillRendererProps(data, pills);
  const barProps: VarianceBarRendererProps = {
    columnWidth,
    rowHeight: calculatedRowHeight,
    tooltip: bar.tooltip,
    bars: bar.bars.map((b: any) => {
      return {
        value: get(data, b.valueDataIndex, 0),
        target: get(data, b.targetDataIndex, 0),
      };
    }),
  };

  return (
    <div className={styles.variance(calculatedRowHeight, pills.length)}>
      <VariancePillRenderer pills={pillProps} />
      <VarianceBarRenderer {...barProps} />
    </div>
  );
};
interface TrendDetailsProps {
  config: TenantConfigViewData;
  data: BasicPivotItem[];
}

export const TrendDetails = ({ config, data }: TrendDetailsProps) => {
  const colDefs = useMemo(() => {
    const columnsConfig: TenantConfigViewItem[] = get(config, 'grid.columns', []);
    return generateColumnDefs(columnsConfig);
  }, [config]);

  const maybeVarianceColumn = useMemo(() => {
    return colDefs.find((col) => col.cellRenderer === 'variance');
  }, [colDefs]);

  const configRowHeight = useMemo(() => {
    return config.main?.rowHeight ? config.main.rowHeight : styles.DEFAULT_ROW_HEIGHT;
  }, [config.main?.rowHeight]);

  const getRowHeight = useCallback(
    // so the group row isn't really tall
    (params: any): number => {
      const pillsLength: number = maybeVarianceColumn ? maybeVarianceColumn.cellRendererParams.pills.length : 1;
      const barLength: number = maybeVarianceColumn ? maybeVarianceColumn.cellRendererParams.bar.bars.length : 1;
      const lengthOfArray: number = Math.max(pillsLength, barLength);
      const calculatedRowHeight = params.node.group ? configRowHeight / lengthOfArray : configRowHeight;
      return calculatedRowHeight;
    },
    [configRowHeight, maybeVarianceColumn]
  );

  return (
    <React.Fragment>
      <div style={{ marginBottom: '1rem' }}>Trend Details</div>
      <section className={classes('grid-container ag-theme-material data-grid', styles.trendDetailsContainer)}>
        <ExtendedDataGrid
          data={data}
          columnDefs={colDefs}
          loaded={true}
          frameworkComponents={{
            ...getFrameworkComponents({ wrapRenderer: false }),
            trend: TrendRenderer,
            variance: VarianceRenderer,
            verticalStack: VerticalStackRenderer,
          }}
          extraAgGridProps={{
            groupDisplayType: 'groupRows',
            suppressMovableColumns: true,
            getRowHeight: getRowHeight,
            groupDefaultExpanded: -1,
            enableRangeSelection: false,
            suppressMultiRangeSelection: true,
            autoGroupColumnDef: {
              autoHeight: true,
            },
            groupRowRendererParams: {
              suppressCount: true,
            },
          }}
        />
      </section>
    </React.Fragment>
  );
};
