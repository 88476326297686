import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { CartItem, ColorSwatchItem } from './AssortmentCart.types';
import { CC_COLOR } from 'src/utils/Domain/Constants';
import { isNil } from 'lodash';
import { checkNameConflict } from '../AssortmentBuild/StyleEdit/StyleEdit.client';

export function mapCartItems(items: BasicPivotItem[]): CartItem[] {
  return items.map(
    (item): CartItem => {
      return {
        // TODO figure out what the right keys to use here, and how to pass them in correctly
        id: item.id as string,
        _id: item._id,
        name: item.name as string,
        imgSrc: item['attribute:img:id'],
        description: item['description'],
        type: item.type,
        activePowerdriverIndex: !isNil(item.activePowerdriverIndex) ? item.activePowerdriverIndex : undefined,
        swatches: item.children
          ? item.children
              .sort((item1, item2) => {
                return item2['slsu'] - item1['slsu'];
              })
              .map(
                (styleColorItem): ColorSwatchItem => {
                  return {
                    // tslint:disable-next-line:no-any
                    ...(styleColorItem as any),
                    colorId: styleColorItem[CC_COLOR],
                    swatchPath: '',
                  };
                }
              )
              .filter((styleColorItem) => {
                // if styleColorItem exists in item.assortmentChildren, don't return as swatch
                if (item.assortmentChildren) {
                  const assortmentChildren = item.assortmentChildren.map(
                    (child: BasicPivotItem): string[] => child[CC_COLOR]
                  );
                  return !assortmentChildren.includes(styleColorItem.colorId);
                }
                return true;
              })
          : [],
        valid: checkNameConflict(item, items),
        currentAssortmentChildren: item.assortmentChildren
          ? item.assortmentChildren.map(
              (styleColorItem: BasicPivotItem): ColorSwatchItem => {
                return {
                  ...(styleColorItem as any),
                  colorId: styleColorItem[CC_COLOR],
                  swatchPath: '',
                };
              }
            )
          : [],
        currentChildren: item.currentChildren
          ? item.currentChildren.map(
              (styleColorItem: BasicPivotItem): ColorSwatchItem => {
                return {
                  ...(styleColorItem as any),
                  colorId: styleColorItem[CC_COLOR],
                  swatchPath: '',
                };
              }
            )
          : [],
      };
    }
  );
}
