import { TEAL_PRIMARY } from 'src/common-ui/theme';
import { style } from 'typestyle';

const buttonWidth = '9rem';

export const bulkImportStyles = style({
  $debugName: 'bulkimport',
  $nest: {
    '.bulk-import-stepper': {
      margin: '0 auto 0 auto',
      maxWidth: '75vw',
      $nest: {
        '.s5-MuiStepIcon-active .s5-MuiStepIcon-text': {
          fill: 'white',
        },
        '.bulkimport-upload.s5-Mui-disabled': {
          pointerEvents: 'none',
        },
        '.s5-MuiStepContent-root': {
          overflowX: 'auto',
        },
        ' .success': {
          width: 500,
          marginBottom: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        },
      },
    },
    '.details': {
      $nest: {
        '.s5-MuiCollapse-wrapper': {
          marginTop: '1rem',
        },
        // 'label[for="bulkimport-upload-btn"]': {
        //   marginTop: '1rem',
        // },
        '.bulkimport-label': {
          marginBottom: '1rem',
        },
        '.bulkimport-chosen-file': {
          marginLeft: '1rem',
          $nest: {
            '.bulkimport-chosen-name': {
              color: TEAL_PRIMARY,
              cursor: 'pointer',
            },
          },
        },
        '.bulkimport-results': {
          $nest: {
            '.s5-MuiBox-root div': {
              marginLeft: '0.5rem',
            },
            '.rejected': {
              color: 'red',
            },
            '.validated': {
              color: TEAL_PRIMARY,
            },
            '.bulkimport-results-download': {
              color: TEAL_PRIMARY,
              marginLeft: '2rem',
              fontWeight: 500,
              lineHeight: 1.6,
            },
          },
        },
      },
    },
    '.step-header': {
      backgroundColor: '#f3f1f5',
      height: '3rem',
      padding: '1rem',
    },
    '.button-container': {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 20,
      $nest: {
        button: {
          width: buttonWidth,
        },
        '.continue-btn': {
          borderRadius: 5,
          width: buttonWidth,
          fontWeight: 'normal',
          border: 'none',
          $nest: {
            '&.loading': {
              top: '0px !important',
              cursor: 'not-allowed',
            },
            '&:disabled': {
              cursor: 'not-allowed',
              // color: '#dee2e6',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          },
        },
        '.apply': {
          borderRadius: 5,
          fontWeight: 'normal',
          border: 'none',
        },
      },
    },
    '.s5-MuiButton-containedSecondary': {
      color: 'white',
      marginLeft: 10,
      width: buttonWidth,
    },

    ['.dropdown-group']: {
      paddingTop: '2em',
      paddingBottom: '2em',
      $nest: {
        '.dropdown-group-label': {
          paddingRight: '1em',
        },
        '.dropdown': {
          minWidth: '15rem',
          height: '2em',
          fontSize: '1.2em',
          padding: '0.5em',
          backgroundColor: '#f3f1f5',
          borderRadius: '0.4em',
          marginRight: '3rem',
        },
      },
    },
    '.grid-container .ag-center-cols-viewport': {
      overflowX: 'hidden', // overrides a default dataGrid class
    },
    '.typography': {
      padding: '0 10px',
      fontSize: '1rem',
      fontWeight: '600',
    },
    '.view-result': {
      width: '250px',
      marginBottom: '10px',
      marginLeft: 10,
    },
    '.bulkimport-commit-btn': {
      display: 'flex',
      alignItems: 'center',
      $nest: {
        '.bulkimport-commit': {
          marginTop: 20,
        },
      },
    },
  },
});
