import { zPivotOptions } from 'src/components/Mfp/PivotConfigurator/utils';
import { z } from 'zod';
// eslint-disable-next-line no-useless-escape
export const ILLEGAL_XLS_SHEET_CHARACTERS = /[\\\/\*\[\]\:\,\?]/gm;

export const ioExportOptions = z
  .object({
    splitCells: z.boolean(),
    idAsHeader: z.boolean(),
  })
  .optional();
export type ExportOptions = z.infer<typeof ioExportOptions>;

export const zReportItem = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  type: z.literal('report'),
  value: z.array(zPivotOptions),
  loading: z.boolean().optional(),
  paramGroup: z.string().optional(),
  exportOptions: ioExportOptions,
  templateId: z.string().optional(),
  pendingReports: z.record(z.string(), z.string()).optional(),
});
export interface ReportItem extends z.infer<typeof zReportItem> {}

export const zReportCollection = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  perspective: z.string(),
  items: z.array(zReportItem),
});
export interface ReportCollection extends z.infer<typeof zReportCollection> {}

export const zReportConfig = z.object({
  $schema: z.string(),
  reportGroups: z.array(zReportCollection),
});
export interface ReportConfig extends z.infer<typeof zReportConfig> {}
