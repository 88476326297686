import { style } from 'typestyle';
import { rgba } from 'csx/lib/color';

const getSeverityStyle = (): string => {
  //'goodTrend' | 'badTrend'
  return style({
    $debugName: 'severity-renderer',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    $nest: {
      ['.severity-section']: {
        height: '1.5em',
        flexGrow: 1,
        padding: '0.1em',
        textAlign: 'center',
        $nest: {
          ['&.severity-good']: {
            backgroundColor: rgba(0, 200, 0, 50).toHexString(),
          },
          ['&.severity-bad']: {
            backgroundColor: rgba(200, 0, 0, 50).toHexString(),
          },
        },
      },
    },
  });
};

export { getSeverityStyle };
