import { style } from 'typestyle';

const container = style({
  position: 'relative',
  width: '100%',
  $nest: {
    '& .select-container.disabled': {
      cursor: 'not-allowed',
    },
  },
});

const selectContainer = style({
  position: 'relative',
  minWidth: '200px',
  width: '100%',
  maxWidth: '400px',
});

const errorIcon = style({
  color: 'darkred',
  fontSize: '1.2rem',
  marginTop: 8,
});

const errorContainer = style({
  marginLeft: 24,
});

export default {
  container,
  selectContainer,
  errorIcon,
  errorContainer,
};
