// Colors
export const highlight = 'rgba(0, 168, 155, 1)';
export const sliderOverlayHighlight = 'rgba(0, 168, 155, 0.5)';
export const subtleHighlight = 'rgba(0, 168, 155, 0.2)';
export const positiveTrend = '#59a02d';
export const negativeTrend = '#d60a2e';
export const neutralTrend = '#a5a5a5';

export const secondaryNeutral = '#f9f9f9';

// Colors > Text
export const primaryTextColor = '#555';
export const fadedNeutral = '#888';

export const faintBorderColor = '#eee';
export const mediumBorderColor = '#ddd';

// Classes
export const upArrowClass = 'fa-caret-up';
export const downArrowClass = 'fa-caret-down';
export const neutralArrowClass = 'fa-minus';

export const TEAL_PRIMARY = '#00a89b';

// Fonts
export const smallMediumFont = '0.775rem'; // 14px
