import React from 'react';
import { Popup } from 'semantic-ui-react';
import { openScopeSelectQaSelector } from '../MfpScopebar.types';
import { Button } from 'semantic-ui-react';
import {
  CONTEXT_PENDING,
  CONTEXT_READY,
  CONTEXT_FAILED,
  CONTEXT_STATUSES,
  CONTEXT_BUSY,
} from 'src/state/workingSets/workingSets.types';
import { WorkingSetContext } from 'src/state/workingSets/workingSets.slice';
import './_ScopeStatusPopover.scss';
import { DEFAULT_DIMENSIONS } from 'src/utils/Domain/Constants';
import ScopeText from '../MfpScopeText';
import { SCOPECREATE_WITHOUT_WP } from 'src/services/Scope.client';
import { Space } from 'src/space';
import { ServerScopeMember } from 'src/state/scope/Scope.types';
import { isNil } from 'lodash';

type ScopeStatusPopoverProps = {
  labelDimension: Space<keyof ServerScopeMember> | undefined;
  scopeId: string | undefined;
  pendingWrites: number;
  scopeContexts: WorkingSetContext[];
  onScopeClick: () => void;
  handleUpdateScopeInfo: (scopeId: string, module: string) => void;
  deleteScope: (scopeId: string, contexts: WorkingSetContext[]) => void;
};

const ScopeStatusPopover = (props: ScopeStatusPopoverProps) => {
  const {
    scopeId,
    scopeContexts,
    onScopeClick,
    handleUpdateScopeInfo,
    labelDimension,
    pendingWrites,
    deleteScope,
  } = props;
  // we have to spread this as a copy, because the source array is ReadOnly and can't be modified
  const sortedContexts = [...scopeContexts].sort(sortByScopeCreationTime);

  return (
    <li className="scope-options-item scope-status-popover">
      <Popup
        trigger={
          <button
            className="scope-options-button"
            data-qa={openScopeSelectQaSelector}
            onClick={onScopeClick}
            type="button"
          >
            {(() => {
              const currentScope = sortedContexts.find((scope) => scope.id === scopeId);
              const scopeStatus = currentScope ? currentScope.status : undefined;
              if (pendingWrites > 0) {
                // if writes are pending to the database, display that status first
                return <i className="far fa-fw fa-spinner-third fa-spin"></i>;
              }
              switch (scopeStatus) {
                case 'busy':
                  return <i className="fa fa-fw fa-circle-notch fa-spin"></i>;
                case 'pending':
                  return <i className="fa fa-fw fa-spinner fa-spin"></i>;
                case 'failed':
                  return <i className="far fa-fw fa-exclamation-triangle"></i>;
                default:
                  return <i className="far fa-fw fa-crosshairs" />;
              }
            })()}
            Scope
          </button>
        }
        wide="very"
        position="bottom right"
        className="scope-options-popup"
        on="hover"
        hoverable
      >
        <div className="scope-popover-container">
          <h5>Your current scopes: </h5>
          <Button.Group basic={true} vertical={true} className="scope-button-group">
            {sortedContexts.map((context, idx) => {
              const scopeText = DEFAULT_DIMENSIONS.map((dim) => {
                //convert object into array
                const array = Object.values(context.anchor[dim]);
                return array.map((x) => (!isNil(labelDimension) ? ` ${x[labelDimension[dim]]}` : ` ${x['id']}`));
              }).join(' | ');
              if (context.initParams.type === SCOPECREATE_WITHOUT_WP) {
                return <div key={idx} />;
              }
              return (
                <div className={`scope-status-row ${context.id === scopeId ? 'current' : ''}`} key={context.id}>
                  <Button
                    className={'scope-status-button'}
                    key={context.id}
                    onClick={(_evt) => handleUpdateScopeInfo(context.id, context.module)}
                  >
                    {contextStateToIcon(context.status)}
                    <ScopeText content={scopeText} />
                  </Button>
                  <Popup
                    size="mini"
                    content="Remove this scope from your session (data will not be lost)"
                    trigger={
                      <Button
                        className="scope-status-delete"
                        icon="close"
                        onClick={() => deleteScope(context.id, sortedContexts)}
                      />
                    }
                    on="hover"
                  />
                </div>
              );
            })}
          </Button.Group>
        </div>
      </Popup>
    </li>
  );
};
export default ScopeStatusPopover;

const statusToIconMap = {
  [CONTEXT_READY]: 'far fa-check-circle',
  [CONTEXT_PENDING]: 'fas fa-spinner fa-spin',
  [CONTEXT_BUSY]: 'fas fa-spinner fa-spin',
  [CONTEXT_FAILED]: 'far fa-exclamation-triangle',
};

const contextStateToIcon = (contextState: CONTEXT_STATUSES): JSX.Element => {
  return <i className={`scope-status-icon ${statusToIconMap[contextState]}`} />;
};

const sortByScopeCreationTime = (lScope: WorkingSetContext, rScope: WorkingSetContext) => {
  return lScope.contextCreationTime.unix() - rScope.contextCreationTime.unix();
};
