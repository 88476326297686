import { style } from 'typestyle';
export const vvCheckboxStyles = 'vv-cell-checkbox';
export const styles = style({
  $debugName: 'valid-values-checkbox-renderer',
  display: 'flex',
  flexDirection: 'row',
  borderTop: '1px solid #dee2e6',
  width: '100%',
  $nest: {
    '.ag-header-cell-text': {
      lineHeight: '36px',
      justifyContent: 'center',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    // TODO: This doesn't actually work...I have no idea why :/
    '.ag-header-cell-text:not(:last-child)': {
      borderRight: '1px solid #dee2e6',
    },
    [`.${vvCheckboxStyles}`]: {
      width: '42px',
      justifyContent: 'center',
    },
  },
});

export const disabledStyles = style({
  cursor: 'not-allowed',
});
export const cellStyles = style({
  $debugName: 'valid-values-checkbox-renderer-container',
  display: 'flex',
  // these two lines exist to counteract the ag-grid global cell style that sets
  // all cells to have 10px of left/right padding'
  // and the width is to make the whole column the right size
  width: 'calc(100% + 20px)',
  justifyContent: 'space-around',
  position: 'relative',
  left: '-10px',
  $nest: {
    '.vv-cell-checkbox': {
      flexGrow: 1,
      textAlign: 'center',
    },
  },
});
export const totalCheckboxStyle = style({
  display: 'inline-block',
  padding: 9,
  minWidth: 42,
  textAlign: 'center',
});
