import React from 'react';
import { Button } from 'semantic-ui-react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { PlanMetadata } from 'src/state/scope/codecs/PlanMetadata';
import { useCallback, useState } from 'react';
import { CreateNonWorkingScopeRequest } from 'src/state/workingSets/nonWorkingSets.slice';
import { mapValues, noop } from 'lodash';
import { DETAIL_SUMMARY } from 'src/components/Mfp/MasterDetailSwitch/MasterDetailSwitch';
import { TopMembers } from 'src/services/Scope.client';

export interface MacroSummaryRendererProps extends ICellRendererParams {
  data: PlanMetadata,
  createNewNonWorkingScope: (createRequest: CreateNonWorkingScopeRequest) => void
};

export const MacroSummaryRenderer = (props: MacroSummaryRendererProps) => {
  const { node, createNewNonWorkingScope, api, data: planData } = props;

  // 'detailNode' is the agGrid property that stores the data for the masterDetail on the parent node
  // if it exists, that indicates that the detail node is open, and we use that to detect open state
  const [expanded, setExpanded] = useState('detailNode' in node && node.expanded);

  const closeAll = useCallback(() => {
    api.forEachNode((n) => {
      !n.group && !n.detail ? n.setExpanded(false) : noop();
    });
  }, [api]);

  const expand = useCallback(() => {
    closeAll();
    if (!expanded || (expanded && node.data.detailType !== DETAIL_SUMMARY)) {
      node.setData({
        ...planData,
        detailType: DETAIL_SUMMARY
      });
      node.setExpanded(true);
      setExpanded(true);

      // asserted here because the source type isn't strong enough
      const anchor = mapValues(planData.space, (s) => [s]) as TopMembers;

      createNewNonWorkingScope({
        initParams: {
          type: 'without-wp'
        },
        anchor,
        workflow: 'in-season',
        planId: planData.id
      });
    } else {
      node.setExpanded(false);
      setExpanded(false);
    }
  }, [closeAll, createNewNonWorkingScope, expanded, node, planData]);

  return (<Button
    icon="eye"
    onClick={expand}
  />);

};
