import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

export default {
  container: style({
    $debugName: 'c-lifecycle',
    width: '100%',
    height: '100%',
    $nest: {
      '.ag-body-horizontal-scroll': {
        marginTop: 10, // Scroll bar is too high on windows. This fixes it
      },
      '.ag-row-focus:not(.ag-row-hover)': {
        color: 'black !important',
      },
      '.ag-pinned-left-cols-container .ag-row .ag-cell':{
        right: '0 !important',
        left: 'unset !important',
      },
      '.ag-horizontal-left-spacer': {
        overflow: 'unset',
      }
    },
  }),

  headersContainer: style({
    $debugName: 'headers',
    height: 400,
    width: '50%',
  }),
  gridClass: style({
    height: '100%',
  }),
  columnsContainer: style({
    $debugName: 'columns',
    display: 'flex',
  }),
  valueText: style({
    color: TEAL_PRIMARY,
  }),
  column: style({
    $debugName: 'column',
    padding: '5px 10px',
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    textAlign: 'center',
    $nest: {
      '& .left-key': {
        color: '#8c8c8c',
        flex: 1,
        textAlign: 'right',
        padding: '5px',
      },
      '& .right-value': {
        flex: 1,
        textAlign: 'left',
        padding: '5px',
      },
    },
  }),
};
