import * as React from 'react';
import { flatCard, IMG_WIDTH, imgContainer } from './FlatCard.styles';
import StarRatings from 'react-star-ratings';
import CenteredImage from 'src/components/CenteredImage/CenteredImage';

export interface FlatCardProps {
  index: number;
  className?: string;
  id: string;
  title?: string;
  name: string;
  stars: null | number;
  imageUri?: string;
  noImageUrl?: string;
  hoverElement?: JSX.Element;
  cardHeight: number;
  onClick?(id: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export default function FlatCard(props: FlatCardProps) {
  const className = props.className ? flatCard + ' ' + props.className : flatCard;

  return (
    <div
      className={className + ' flat-card'}
      key={props.index}
      onClick={(evt) => {
        props.onClick ? props.onClick(props.id, evt) : undefined;
      }}
    >
      {props.hoverElement && <div className="hoverElement">{props.hoverElement}</div>}
      <span className="left-pane">
        <span className="id">{props.title || props.id}</span>
        <span className="name">{props.name}</span>
        {props.stars !== null ? (
          <StarRatings
            rating={props.stars || 0}
            className="stars"
            numberOfStars={5}
            starDimension={'1rem'}
            starSpacing={'0.1rem'}
            starRatedColor={'#ff8802'}
          />
        ) : null}
      </span>
      {(props.imageUri || props.noImageUrl) && (
        <CenteredImage
          src={props.imageUri || props.noImageUrl}
          width={IMG_WIDTH}
          height={props.cardHeight}
          extraContainerClass={imgContainer}
        />
      )}
    </div>
  );
}
