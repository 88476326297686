import * as React from 'react';
import { noop, flatMap } from 'lodash';
import { isNil, get } from 'lodash/fp';

import { FilterValue } from 'src/common-ui/components/Filters/Filters.interfaces';
import { Filters, Overlay } from 'src/common-ui';
import { SelectionOverride } from 'src/common-ui/components/Filters/Filters';

import { Filter, FilterGroup } from 'src/types/Scope';
import { Props } from './FilterPanel.container';
import { toast } from 'react-toastify';

type State = {
  triggerFuse: boolean;
};

export default class FilterPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      triggerFuse: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.containsInvalid !== this.props.containsInvalid && this.props.containsInvalid) {
      toast.info('Invalid filters have been removed, and data is being refreshed.', {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  private onFilterToggled = (updatedFilter: SelectionOverride): void => {
    this.props.updateSelectionOverrides([updatedFilter]);
  };

  private onRangeFiltersUpdated = (id: string, label: string[]) => {
    const rangeSelectionOverrides = label.map((l) => ({
      id: l,
      filterDefnId: id,
      value: true,
      selected: true,
    }));

    this.props.overwriteSelectionOverridesByDefnId(id, rangeSelectionOverrides);
  };

  // Clear filters by queueing an override for everything
  private onFiltersCleared = (): void => {
    if (!this.props.isFlushing) {
      const overrides = flatMap(this.props.state, (group) => {
        return flatMap(group.filters, (section) =>
          (section.filterValues || [])
            .filter((filterItem: FilterValue) => filterItem.selected)
            .map((selectedFilter: FilterValue) => ({
              ...selectedFilter,
              value: false,
            }))
        );
      });
      this.props.updateSelectionOverrides(overrides);
      this.props.submitSelectionOverrides();
    }
  };

  // TODO: this method isn't even called right now so passing empty string to getAvailableSelections to compile
  private onFilterSectionCleared = (tab: number): void => {
    if (!this.props.isFlushing && !isNil(tab)) {
      const tabToBeCleared = get(`props.state[${tab}]`, this) as FilterGroup;

      const clearTabFilters = flatMap(tabToBeCleared.filters, (section) =>
        (section.filterValues || [])
          .filter((filterItem: FilterValue) => filterItem.selected)
          .map((selectedFilter: FilterValue) => ({
            ...selectedFilter,
            value: false,
          }))
      );
      this.props.updateSelectionOverrides(clearTabFilters);
      //this.triggerFlushSelectionOverrides();
      this.props.getAvailableFilterSelections('');
    }
  };

  onFilterSubmit = () => {
    this.props.submitSelectionOverrides();
  };

  onToggleSection = (filter: Filter) => {
    if (filter.inputType !== 'rangepicker') {
      this.props.getAvailableFilterSelections(filter.id);
    }
  };

  onClearSection = (sectionId: string, isOpen: boolean) => {
    if (isOpen) {
      this.props.getAvailableFilterSelections(sectionId);
    }
  };

  render() {
    return (
      <div style={{ padding: '10px 0 0 20px' }}>
        <Overlay type="loading" visible={this.props.isFlushing || this.props.isLoading} />
        <Filters
          data={this.props.state}
          lockedNavigation={this.state.triggerFuse}
          onViewUpdate={noop}
          selectionOverrides={this.props.selectionOverrides}
          selectionsChanged={this.props.selectionsChanged}
          hasFiltersSelected={this.props.lastSelections.length > 0}
          onRangeFiltersUpdated={this.onRangeFiltersUpdated}
          onFilterToggled={(selection, state) => this.onFilterToggled({ ...selection, value: state })}
          onFiltersCleared={this.onFiltersCleared}
          onFilterSectionCleared={this.onFilterSectionCleared}
          onFiltersSubmit={this.onFilterSubmit}
          onToggleSection={this.onToggleSection}
          onClearSection={this.onClearSection}
          allowFrom={this.props.allowFrom}
          allowTo={this.props.allowTo}
          daysRangeList={this.props.daysRangeList}
        />
      </div>
    );
  }
}
