import { style } from 'typestyle';

export default style({
  $nest: {
    '&>*:first-child': {
      $nest: {
        '& .ag-root-wrapper': {
          $nest: {
            '& .ag-root-wrapper-body': {
              $nest: {
                '& .ag-root': {
                  $nest: {
                    '& .ag-header': {
                      $nest: {
                        '& .ag-header-viewport': {
                          $nest: {
                            '& .ag-header-container': {
                              $nest: {
                                '& .ag-header-row': {
                                  $nest: {
                                    '& .ag-header-cell': {
                                      $nest: {
                                        '& .ag-cell-label-container': {
                                          $nest: {
                                            '& .ag-header-cell-label': {
                                              overflow: 'visible',
                                              $nest: {
                                                '& .ag-header-cell-text': {
                                                  whiteSpace: 'normal',
                                                  lineHeight: '20px',
                                                  overflow: 'visible',
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    '& .ag-body-viewport': {
                      $nest: {
                        '& .ag-pinned-left-cols-container': {
                          $nest: {
                            '& .ag-row': {
                              overflow: 'hidden',
                              $nest: {
                                '& .ag-cell': {},
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
