import React from 'react';
import { classes } from 'typestyle';
import Skeleton from '@material-ui/lab/Skeleton';
import { macroSummaryStyle } from 'src/common-ui/components/Macros/MacroSummary/MacroSummary.styles';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { getDirectionClasses } from 'src/common-ui/components/Macros/Macros.utils';

export default function MacroSummary({ dataLoaded, metrics, extraClasses }: MacrosProps) {
  const { primary, directional, secondary } = metrics;
  const containerClass = classes('macro-summary', macroSummaryStyle, extraClasses ? extraClasses : '');

  if (!dataLoaded) {
    return (
      <div className={containerClass}>
        <div className="left-container">
          <header>{primary.label}</header>
          <Skeleton className="primary-skeleton" variant="text" animation="wave" />
        </div>
        <div className="right-container">
          <section className="variance">
            <label className="metric-name">{secondary.label}</label>
            <Skeleton className="icon-skeleton" variant="text" animation="wave" />
            <Skeleton className="secondary-skeleton" variant="text" animation="wave" />
          </section>
          <section className="variance">
            <label className="metric-name">{directional.label}</label>
            <Skeleton className="icon-skeleton" variant="text" animation="wave" />
            <Skeleton className="secondary-skeleton" variant="text" animation="wave" />
          </section>
        </div>
      </div>
    );
  }

  const { iconClass } = getDirectionClasses(directional.direction);
  const hiddenIconClass = classes('none', iconClass);
  return (
    <div className={containerClass}>
      <div className="left-container">
        <header>{primary.label}</header>
        <div className="primary-metric">{primary.rendered}</div>
      </div>
      <div className="right-container">
        <section className="variance">
          <label className="metric-name">{secondary.label}</label>
          <i className={hiddenIconClass} />
          <span className="metric-value">{secondary.rendered}</span>
        </section>
        <section className="variance">
          <label className="metric-name">{directional.label}</label>
          <i className={iconClass} />
          <span className="metric-value">{directional.rendered}</span>
        </section>
      </div>
    </div>
  );
}
