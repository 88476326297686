import Modal from '@trendmicro/react-modal';
import classNames from 'classnames';
import React from 'react';
import { Transition } from 'react-transition-group';
import './_Modal.scss';

interface TitledModalProps {
  title: string;
  show: boolean;
  onClose?: () => void;
  className?: string;
  modalTimeout: number;
  //needs this property to using `esc` key to close modal
  //somehow using `onClose` it navigates to homePage
  closeModal?: () => void;
}
interface TitledModalState {
  isVisible: boolean;
}
export default class TitledModal extends React.Component<TitledModalProps, TitledModalState> {
  private timeoutIndex: number | undefined;
  static defaultProps = {
    modalTimeout: 500,
  };

  public constructor(props: TitledModalProps) {
    super(props);
    this.state = {
      isVisible: this.props.show,
    };
  }

  public setModalVisibility(isVisible: boolean) {
    this.setState({
      isVisible,
    });
  }
  componentDidMount() {
    window.addEventListener('keydown', this.handleEscKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEscKeyDown);
  }

  handleEscKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this.props.closeModal) {
      this.props.closeModal();
    }
  };
  public render() {
    const { modalTimeout } = this.props;
    return (
      <Transition
        in={this.props.show}
        timeout={0}
        mountOnEnter={true}
        unmountOnExit={true}
        onEntering={() => {
          this.setModalVisibility(true);
          window.clearTimeout(this.timeoutIndex);
        }}
        onExiting={() => {
          this.timeoutIndex = window.setTimeout(() => {
            this.setModalVisibility(false);
          }, modalTimeout);
        }}
      >
        {(transitionState) => (
          <Modal
            onClose={this.props.onClose}
            className={classNames('titled-modal', 'titled-modal-' + transitionState, this.props.className)}
            overlayClassName={'titled-modal-' + transitionState}
            show={this.state.isVisible}
          >
            <div className={'titled-modal-header'}>{this.props.title}</div>
            {this.props.children}
          </Modal>
        )}
      </Transition>
    );
  }
}
