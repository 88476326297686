import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import RightContainer, {
  RightContainerProps,
  RightContainerPayloadType,
} from 'src/components/RightContainer/RightContainer';
import { RightContainerSlice, ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import {
  setRightContainerPayload,
  cleanUpRightContainer,
  clearAndCloseRightContainer,
} from 'src/components/RightContainer/RightContainer.slice';
import { getFilterSelections } from '../FilterPanel/FilterPanel.container';
import { getActivePerspectiveEditStyleButtonConfig } from 'src/pages/PerspectiveSelection/PerspectiveSelection.selectors';

export interface StateProps extends RightContainerSlice {}

export interface DispatchProps {
  setRightContainerPayload(payload: ContainerPayload): void;
  clearAndCloseWindow(payload?: ContainerPayload): void;
  onDestroy(): void;
}

function mapStateToProps(state: AppState): RightContainerProps {
  const editStyleBtnConf = getActivePerspectiveEditStyleButtonConfig(state);
  return {
    ...state.rightContainer,
    editStyleBtnConf,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    setRightContainerPayload(payload: ContainerPayload) {
      dispatch(setRightContainerPayload(payload));
    },
    clearAndCloseWindow(payload?: ContainerPayload) {
      dispatch(clearAndCloseRightContainer());
      if (payload && payload.type === RightContainerPayloadType.Filter) {
        dispatch(getFilterSelections());
      }
    },
    onDestroy() {
      dispatch(cleanUpRightContainer());
    },
  };
}

export default connect(mapStateToProps, dispatchToProps)(RightContainer);
