import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { preSetActivePage, preSetActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { resetPages } from 'src/pages/Pages.reducer';
import { resetGridModel } from 'src/components/Sidenav/SideNav.slice';

export const cleanUpEntirePageSlice: AppEpic = (action$) => {
  return action$.pipe(
    ofType(preSetActivePage.type, preSetActiveSubPage.type),
    mergeMap(() => {
      return of(resetPages(), resetGridModel());
    })
  );
};
