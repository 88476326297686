import { z } from 'zod';

const SMARTPLAN_TOGGLE = z.literal('toggle');
const SMARTPLAN_PERCENT = z.literal('inputPercent');
const SMARTPLAN_SLIDER = z.literal('slider');

const SMARTPLAN_INPUTS = z.union([SMARTPLAN_TOGGLE, SMARTPLAN_PERCENT, SMARTPLAN_SLIDER]);

export const zSmartPlanTarget = z.object({
  /* Basic text label for the input */
  text: z.string(),
  /* Popover text input for the string, HTML not allowed */
  infoText: z.string(),
  /* MetricId tied to the input */
  dataIndex: z.string(),
  /* What editor will be used for the input */
  editor: SMARTPLAN_INPUTS,
});
export interface SmartPlanTarget extends z.infer<typeof zSmartPlanTarget> {}
