import Axios from 'src/services/axios';

const SIZE_ELIGIBILITY_API = 'api/sizeeligibility/';
const ATTR_UPSERT = 'api/attribute/upsert';
const ATTR_UPSERT_ALL = 'api/attribute/upsertAll';

// mock action types
const addSize = 'add';
const removeSize = 'remove';
const changeSizeMin = 'changeSizeMin';
const changeMinWeeks = 'changeMinWeeks';
const changeSizeMinStrategy = 'changeSizeMinStrategy';
const changeSubSizeRange = 'changeSubSizeRange';

type SizeEligibilityApiPayload = {
  type:
    | typeof addSize
    | typeof removeSize
    | typeof changeSizeMin
    | typeof changeMinWeeks
    | typeof changeSizeMinStrategy
    | typeof changeSubSizeRange;
  location: string;
}[];

// would rather the return type be the type in this case
/* eslint-disable-next-line */
export function getSizeEligibilityClient() {
  const client = Axios;
  return {
    updateAttributes(keys: any, data: any) {
      return client.post<undefined, any>(
        ATTR_UPSERT,
        {
          ...data,
          ...keys,
        },
        {
          params: {
            appName: 'Assortment',
          },
        }
      );
    },
    updateAttributesAll(data: any[]) {
      return client.post<undefined, any>(ATTR_UPSERT_ALL, data, {
        params: {
          appName: 'Assortment',
        },
      });
    },
    addToSizeEligibility(location: string, size: string) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, size, type: addSize },
      ]);
    },
    removeFromSizeEligibility(location: string, size: string) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, size, type: removeSize },
      ]);
    },
    changeSizeMin(location: string, sizeMin: string) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, sizeMin, type: changeSizeMin },
      ]);
    },
    changeMinWeeks(location: string, minWeeks: string) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, minWeeks, type: changeMinWeeks },
      ]);
    },
    changeSizeMinStrategy(location: string, sizeMinStrategy: string) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, sizeMinStrategy, type: changeSizeMinStrategy },
      ]);
    },
    changeSubSizeRange(location: string, subSizeRange: string) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, subSizeRange, type: changeSubSizeRange },
      ]);
    },
    changeAllocation(location: string, allocate: boolean) {
      return client.post<undefined, SizeEligibilityApiPayload>(SIZE_ELIGIBILITY_API, [
        { location, allocate, type: changeSubSizeRange },
      ]);
    },
  };
}
