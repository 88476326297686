import * as React from 'react';
import { overlay, overlayFit, spinner } from './Overlay.styles';

export interface Props {
  visible: boolean;
  type: 'loading' | 'error';
  text?: string;
  qaKey?: string;
  fitParent?: boolean; // requires the parent be position: relative
}

export default function Overlay(props: Props) {
  const { visible, qaKey, fitParent } = props;
  return (
    <div
      hidden={!visible}
      className={!fitParent ? overlay : overlayFit}
      data-qa-component={'Overlay'}
      data-qa-key={qaKey}
    >
      <div className={spinner} />
    </div>
  );
}
