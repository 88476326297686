import { AppState, AppThunkDispatch } from 'src/store';
import { ReviewCorporatePlansValueProps } from './review-corporate-plans';
import { createNewNonWorkingScope } from 'src/state/workingSets/nonWorkingSets.slice';
import { deletePlan } from 'src/state/workingSets/workingSets.actions';
import { AxiosInstance } from 'axios';
import { getPublishVersions } from 'src/state/scope/ScopeManagement.actions';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { isEmpty } from 'lodash';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

export function mapStateToProps(state: AppState): ReviewCorporatePlansValueProps {
  const readyScope = getScopeReadyData(state.mfpScope);
  const currentModule = getMfpModule(state);
  if (readyScope && !isEmpty(readyScope.mainConfig.initializedPlans)) {
    return {
      // TODO: this should pull from the selected publish plan id
      // but using this as a hack for now
      currentModule,
      currentPlanId: readyScope.mainConfig.initializedPlans[0].id,
      publishVersions: state.scopeManagement.publishVersions,
    };
  }
  return {
    currentModule,
    currentPlanId: null,
    publishVersions: [],
  };
}

export const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    createNewNonWorkingScope,
    deletePlan: (payload: { client: AxiosInstance; planId: number }) => dispatch(deletePlan(payload)),
    getPublishVersions: (selectedPlanId: PlanId) => dispatch(getPublishVersions(selectedPlanId)),
  };
};

export interface ReviewCorporatePlansDispatchProps extends ReturnType<typeof mapDispatchToProps> { }
