import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { style } from 'typestyle';

const buttonText = style({
  color: '#ffffff !important',
});

const container = style({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const contentWrapper = style({
  padding: '4rem',
  border: `2px solid ${TEAL_PRIMARY}`,
  borderRadius: '10px',
  maxWidth: 1000,
});

const textWrapper = style({
  marginBottom: '2rem',
  $nest: {
    '& .warning': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      marginBottom: '1.5rem',
    },
  },
});
const errorDropdown = style({
  paddingBottom: '2rem',
  $nest: {
    '.error-message-list': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 250,
      overflow: 'auto',
    },
  },
});
const textSpacer = style({ marginBottom: '0.5rem' });
const textItem = style({ fontSize: '0.9rem' });

export default { container, buttonText, contentWrapper, textWrapper, textSpacer, textItem, errorDropdown };
