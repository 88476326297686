import { style } from 'typestyle';
import { important, rem } from 'csx';

const buttonSize = 2.2; // rem
const calculatedButtonSize = important(rem(buttonSize) as number);

const rejectButton = style({
  width: calculatedButtonSize,
  minWidth: calculatedButtonSize,
  height: calculatedButtonSize,
  backgroundColor: important('white'),
  color: important('rgba(0,0,0,0.54)'),
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: important(0),
});

export default rejectButton;
