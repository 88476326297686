import PerspectiveSelection from './PerspectiveSelection';
import { resetScope } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { AppThunkDispatch } from 'src/store';
import { connect } from 'react-redux';
import serviceContainer from 'src/ServiceContainer';

export type StateProps = Record<string, unknown>;

export interface DispatchProps {
  resetScope: () => void;
}
export interface Props extends StateProps, DispatchProps {}

function mapStateToProps(): StateProps {
  return {};
}

function mapDispatchToProps(dispatch: AppThunkDispatch): DispatchProps {
  return {
    resetScope() {
      dispatch(resetScope());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PerspectiveSelection(serviceContainer.configService));
