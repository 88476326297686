import { createSelector } from 'reselect';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import 'highcharts/modules/map';

import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { GeoChartProps } from 'src/common-ui/components/Charts/GeoChart/GeoChart';

import { AppState } from 'src/store';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { Renderer } from 'src/utils/Domain/Renderer';
import {
  TenantConfigViewItem,
  BasicTenantConfigViewItem,
  GeoTrendsConfigData,
  isViewDefnLoaded,
} from 'src/dao/tenantConfigClient';
import { identity } from 'fp-ts/lib/function';
import { SummarySlice } from './Summary.slice';
import { geoMapStyles as geo } from './Summary.styles';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import { TenantConfig } from 'src/services/configuration/codecs/bindings.types';
import { mapNameToDefaultUri } from 'src/utils/Component/GeoChart';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { isNil, get, isNaN, sortBy } from 'lodash';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';
import { SummaryViewTopDownOwnProps } from 'src/services/configuration/codecs/ownProps';

interface StateSelection extends SummarySlice {
  subheader: SubheaderSlice;
  tenantConfig: TenantConfig;
  configLoaded: boolean;
  dataLoaded: boolean;
  viewDataState: ViewDataState;
  title: string;
  showFlowStatus: boolean;
  showLookBackPeriod: boolean;
}

export interface StateProjection {
  title: SubheaderOwnProps['title'];
  configLoaded: boolean;
  dataLoaded: boolean;
  viewDataState: ViewDataState;
  lookBackPeriod: string;
  flowStatus: SubheaderSlice['flowStatus'];
  choiceProductivity: MacrosProps[];
  globalRegionVolAndTrend: GeoChartProps;
  keyFinancials: MacrosProps[];
  productMixAndTrend: Highcharts.SeriesMapOptions;
  trendAnalysis: Highcharts.SeriesMapOptions;
  showFlowStatus: boolean;
  showLookBackPeriod: boolean;
}

function selectState(state: AppState, ownProps: SummaryViewTopDownOwnProps): StateSelection {
  const viewState = state.pages.hindsighting.summary;
  const { viewDefnState, viewDataState } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);

  return {
    title: ownProps.title,
    showFlowStatus: ownProps.showFlowStatus,
    showLookBackPeriod: ownProps.showLookBackPeriod,
    subheader: state.subheader,
    tenantConfig: state.appConfig.tenantConfig,
    configLoaded,
    dataLoaded,
    ...viewState,
  };
}

function defnToRenderer(defn: TenantConfigViewItem) {
  return defn.renderer ? Renderer[defn.renderer] : identity;
}

// TODO: Move to a shared folder because Category Summary is doing the exact same thing.
function viewItemToFinancialProps(viewItem: TenantConfigViewItem, data: BasicPivotItem): MacrosProps {
  if (viewItem.view) {
    const secondaryDefVariant = viewItem.view[0];
    const secondaryDefMetric = viewItem.view[1];

    const primaryRenderer = defnToRenderer(viewItem);
    const variantRenderer = defnToRenderer(secondaryDefVariant);
    const secondaryRenderer = defnToRenderer(secondaryDefMetric);

    const primaryValue = data && data[viewItem.dataIndex];
    const variantValue = data && data[secondaryDefVariant.dataIndex];
    const secondaryValue = data && data[secondaryDefMetric.dataIndex];
    const direction = variantValue > 0 ? 'up' : 'down';

    return {
      metrics: {
        primary: { rendered: primaryRenderer(primaryValue), label: viewItem.text },
        directional: { rendered: variantRenderer(variantValue), direction },
        secondary: { rendered: secondaryRenderer(secondaryValue), label: secondaryDefMetric.text },
      },
      extraClasses: 'none',
      dataLoaded: true,
    };
  } else {
    return {
      metrics: {
        primary: { rendered: '', label: '' },
        directional: { rendered: '', direction: 'neutral' },
        secondary: { rendered: '', label: '' },
      },
      extraClasses: '',
      dataLoaded: false,
    };
  }
}

// TODO: TrendAnalysisProps and prodMixProps are almost identical, need refactor.
function trendAnalysisProps(
  view: TenantConfigViewItem[],
  rawData: BasicPivotItem[],
  yAxisLabel = 'Sales $',
  xAxisLabelLookUp: string,
  sortIndex?: string
) {
  // Sort the data array
  const sortedData = sortIndex ? sortBy(rawData, sortIndex) : rawData;

  const colors = ['#6C70FF', '#FFA592'];
  const trendAnalysisConf = {
    title: { text: 'Trend Analysis' },
    chart: {
      type: 'line',
    },
    xAxis: {
      title: {
        text: 'Month',
      },
      categories: sortedData.map((data) => data[xAxisLabelLookUp]),
    },
    yAxis: {
      title: {
        text: yAxisLabel,
      },
    },
    series: view.map((item: BasicTenantConfigViewItem, i: number) => ({
      name: item.text,
      data: rawData.map((entry: BasicPivotItem) => entry[item.dataIndex]),
      color: colors[i],
    })),
    credits: { enabled: false },
    tooltip: {
      formatter: function(this: { series: { name: string; index: number }; point: { y: number; category: string } }) {
        const index = this.series.index;
        const renderer = view[index].renderer;
        const value = renderer ? Renderer[renderer](this.point.y) : this.point.y;
        return `<span style="font-size: 10px">${this.point.category}</span><br/>${this.series.name}: <b>${value}</b>`;
      },
    },
  };
  return trendAnalysisConf;
}

function prodMixProps(view: TenantConfigViewItem[], rawData: BasicPivotItem[]) {
  const colors = ['#0071b1', '#ff8229'];
  const productMixConf = {
    title: { text: 'Product Mix And Trend' },
    chart: {
      type: 'bar',
    },
    xAxis: {
      title: { text: '' },
      categories: [...rawData].map((entry: BasicPivotItem) => entry.name),
    },
    yAxis: { visible: false },
    series: view.map((item: BasicTenantConfigViewItem, i: number) => ({
      name: item.text,
      data: rawData.map((entry: BasicPivotItem) => entry[item.dataIndex]),
      color: colors[i],
    })),
    credits: { enabled: false },
    tooltip: {
      formatter: function(this: { series: { name: string; index: number }; point: { y: number; category: string } }) {
        const index = this.series.index;
        const renderer = view[index] ? view[index].renderer : '';
        const value = renderer ? Renderer[renderer](this.point.y) : this.point.y;
        return `<span style="font-size: 10px">${this.point.category}</span><br/>${this.series.name}: <b>${value}</b>`;
      },
    },
  };
  return productMixConf;
}

function globalRegionProps(rawData: BasicPivotItem[] | undefined, viewDefn: GeoTrendsConfigData): GeoChartProps {
  const trendDown: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[] = [];
  const trendZero: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[] = [];
  const trendUp: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[] = [];
  const trends: Record<string, (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[]> = {
    '-1': trendDown,
    '0': trendZero,
    '1': trendUp,
  };
  const defaultMapSeriesOptions: Highcharts.SeriesMapbubbleOptions = {
    maxSize: geo.bubbleMaxSize,
    minSize: geo.bubbleMinSize,
    sizeBy: 'width',
    sizeByAbsoluteValue: true,
    type: 'mapbubble',
  };
  const latProp = get(viewDefn, 'geoLocationKeys[0]', '');
  const longProp = get(viewDefn, 'geoLocationKeys[1]', '');
  const mapDataIndex = viewDefn.bubble ? viewDefn.bubble.dataIndex : 'slsr';
  let highestValue = -1;

  rawData &&
    // @ts-ignore
    rawData.reduce((trendsAcc: Record<string, unknown[]>, item: BasicPivotItem) => {
      const name = item.store || item.channel || item.globalregion;
      const lat = parseFloat(item[latProp]);
      const lon = parseFloat(item[longProp]);
      const region = item.name || '';
      const value = get(item, mapDataIndex);
      highestValue = Math.max(highestValue, value);

      if (!isNaN(lat) && !isNaN(lon)) {
        trendsAcc[item.trend].push({
          lat,
          lon,
          name,
          value,
          z: value,
          departmentData: item.children,
          region,
        });
      } else {
        console.error(`Global Region: ${name} has an invalid latitude or longitude. Ignoring.`); // eslint-disable-line no-console
      }
      return trendsAcc;
    }, trends);

  const mapSeries = [
    {
      ...defaultMapSeriesOptions,
      name: 'Trending Up (> 10%)',
      data: trendUp,
      color: geo.trendUpColor,
    },
    {
      ...defaultMapSeriesOptions,
      data: trendDown,
      name: 'Trending Down (< 10%)',
      color: geo.trendDownColor,
    },
    {
      ...defaultMapSeriesOptions,
      data: trendZero,
      name: 'No Trend (+/- 0-10%)',
      color: geo.noTrendColor,
    },
  ] as (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[];

  return {
    geoConfig: {
      plotOptions: {
        mapbubble: {
          zMax: highestValue,
        },
      },
      tooltip: {
        formatter: function(this: TooltipFormatterContextObject) {
          // region is a custom property inserted onto the point type
          const pointRegion = ((this.point as unknown) as { region: string }).region;
          const renderer = viewDefn.bubble ? viewDefn.bubble.renderer : 'usMoneyNoCents';
          return `${pointRegion}<br/>${this.point.series.name}<br/>Sales: <b>${Renderer[renderer](
            this.point.value
          )}</b>`;
        },
      },
    },
    mapSeries,
    title: 'Global Region Volume and Trend',
    mapUri: (viewDefn.mapUri && mapNameToDefaultUri(viewDefn.mapUri)) || '',
  };
}

export function projectState(stateSelection: StateSelection) {
  const {
    configLoaded,
    dataLoaded,
    viewDataState,
    viewData,
    viewDefns,
    subheader,
    title,
    showFlowStatus,
    showLookBackPeriod,
  } = stateSelection;
  const lookBackPredicate = makelookBackPredicate(subheader.lookBackPeriod);

  const keyFinancialsData = cascadingFilter(viewData.keyFinancials, lookBackPredicate);
  const choiceProductivityData = cascadingFilter(viewData.choiceProductivity, lookBackPredicate);
  const productMixAndTrendData = !isNil(get(keyFinancialsData, '0')) ? keyFinancialsData[0].children : [];
  const globalRegionVolumeAndTrendData = cascadingFilter(viewData.globalRegionVolumeAndTrend, lookBackPredicate);

  const keyFinancialsDefn = viewDefns.keyFinancials.view || [];
  const choiceProductivityDefn = viewDefns.choiceProductivity.view || [];
  const trendAnalysisDefn = viewDefns.trendAnalysis.view || [];
  const productMixAndTrendDefn = viewDefns.productMixAndTrend.view || [];

  const trendAnalysisLabelLookUp = viewDefns.trendAnalysis.dataIndex || 'id';
  const sortIndex = viewDefns.trendAnalysis.sortIndex;

  const keyFinancials = keyFinancialsDefn.map((viewItem: TenantConfigViewItem) =>
    viewItemToFinancialProps(viewItem, get(keyFinancialsData, '0', {} as BasicPivotItem))
  );
  const choiceProductivity = choiceProductivityDefn.map((viewItem: TenantConfigViewItem) =>
    viewItemToFinancialProps(viewItem, get(choiceProductivityData, '0', {} as BasicPivotItem))
  );

  // we re-use the label for the first keyFinancial macro object as
  // the y-axis label of the Trend Analysis graph
  // it is possible that in the future these labels will need to diverge,
  // at which point the sharing of this label will need to be reworked
  const firstKeyFinancialsLabel = get(keyFinancials, 'view[0].text', '');

  const trendAnalysis = trendAnalysisProps(
    trendAnalysisDefn,
    viewData.trendAnalysis,
    firstKeyFinancialsLabel,
    trendAnalysisLabelLookUp,
    sortIndex
  );
  const productMixAndTrend = prodMixProps(productMixAndTrendDefn, productMixAndTrendData);
  const globalRegionVolAndTrend = globalRegionProps(
    globalRegionVolumeAndTrendData,
    viewDefns.globalRegionVolumeAndTrend
  );

  return {
    title,
    flowStatus: subheader.flowStatus,
    lookBackPeriod: subheader.lookBackPeriod,
    showFlowStatus,
    showLookBackPeriod,
    loaded: true,
    keyFinancials,
    choiceProductivity,
    trendAnalysis,
    productMixAndTrend,
    globalRegionVolAndTrend,
    configLoaded,
    dataLoaded,
    viewDataState,
  };
}

// @ts-ignore
export default createSelector(selectState, projectState);
