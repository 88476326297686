import React from 'react';
import { componentToName } from 'src/utils/React/Component';
import { isNil } from 'lodash';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { connect } from 'react-redux';
import { wrappedDispatch, WrappedDispatch } from 'src/utils/Redux/Dispatch';
import { AppState } from 'src/store';
import { STYLE_ID, HINDSIGHTING_CONTEXT } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';

export interface StyleDetailsPopoverProps extends Object {
  onItemClicked?: (item: Record<string, any>, eventTarget?: HTMLElement) => void;
  showPopover?: boolean;
  activeTab?: string;
}

type HocProps = {
  showDataInRightContainer: (id: string, parentId: string, activeTab: string | undefined) => void;
};

export function makePopoverSensitive<P extends StyleDetailsPopoverProps>(WrappedComponent: React.ComponentType<P>) {
  function mergeProps(_state: AppState, wrapped: WrappedDispatch, parentProps: P) {
    const props: HocProps = {
      showDataInRightContainer: (id: string, parentId: string, activeTab?: string) => {
        if (activeTab && IS_HINDSIGHTING(activeTab)) {
          wrapped.dispatch(
            setRightContainerPayload({
              type: RightContainerPayloadType.History,
              id,
              parentId,
            })
          );
        } else {
          wrapped.dispatch(
            setRightContainerPayload({
              type: RightContainerPayloadType.Assortment,
              id,
              parentId,
              isAssortmentBuild: true,
            })
          );
        }
      },
    };
    return {
      ...parentProps,
      ...props,
    };
  }

  function PopoverSensitive(props: StyleDetailsPopoverProps & P & HocProps) {
    const handleItemClickEvent = (item: BasicPivotItem, eventTarget?: HTMLElement, activeTab?: string) => {
      const { showPopover, showDataInRightContainer, onItemClicked } = props;

      if (isNil(item.id)) {
        return;
      }

      if (showPopover) {
        const parentId = item.parentId || item[STYLE_ID] || item.id;
        if (item.type && item.type === RightContainerPayloadType.History) {
          activeTab = HINDSIGHTING_CONTEXT;
        }
        showDataInRightContainer(item.id, parentId, activeTab);
      }

      if (onItemClicked && !showPopover) {
        onItemClicked(item, eventTarget);
      }
    };

    return <WrappedComponent {...props} onItemClicked={handleItemClickEvent} />;
  }

  PopoverSensitive.displayName = `PopoverSensitive(${componentToName(WrappedComponent)})`;
  return connect(null, wrappedDispatch, mergeProps)(PopoverSensitive);
}
