import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Button, Dialog, Tooltip } from '@material-ui/core';
import styles from './ExcelUploadModal.styles';
import { isNil } from 'lodash';

export interface ModalButton {
  onClick: (click: React.MouseEvent<HTMLDivElement>) => void;
  path: null | string;
  label: string;
  disabled?: boolean;
}

type Props = {
  title?: string;
  tooltipString?: string;
  onExcelFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onExcelUpload: () => void;
  selectedFile: File | null;
};

const ExcelUploadModal = ({
  title = 'Upload an Excel File',
  tooltipString,
  onExcelFileChange,
  onExcelUpload,
  selectedFile
}: Props) => {
  const excelButtonRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const fabClick = () => {
    setModalOpen(true);
  };

  const handleOnClickClearFiles = useCallback(() => {
    // manually clear the file list if you clicks into select a file again
    if (excelButtonRef.current) {
      excelButtonRef.current.value = '';
    }
  }, []);

  const onClickUpload = () => {
    onExcelUpload();
    handleCancel();
  };

  let fabButton = (
    <Button
      variant="outlined"
      onClick={fabClick} // open a modal
      classes={{ root: styles.excelButton }}
    >
      Upload Excel File
    </Button>
  );
  if (tooltipString) {
    fabButton = (
      <Tooltip title={tooltipString} arrow>
        {fabButton}
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      {fabButton}
      <Dialog open={isModalOpen}>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
            <span className={styles.buttonCancel} onClick={handleCancel}>
              X
            </span>
          </div>
          <div className={styles.body}>
            <input
              ref={excelButtonRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id="excel-upload-btn"
              multiple={false}
              className={styles.input}
              onChange={onExcelFileChange}
              onClick={handleOnClickClearFiles}
              type="file"
            />
            <Button
              variant="contained"
              color="secondary"
              className={styles.button}
              component="span"
              disabled={isNil(selectedFile)}
              startIcon={<i className={'fa fa-cloud-upload'} />}
              onClick={onClickUpload}
            >
              Upload
            </Button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default ExcelUploadModal;
