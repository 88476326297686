import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { ThunkApi } from 'src/store';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';

import type { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import type { Comment, ProtoComment } from './Comments.types';


export const addComment = createAsyncThunk<
  Comment[],
  ProtoComment,
  ThunkApi & { rejectValue: string }>(
    'comments/addComment',
    async (newComment, { getState, rejectWithValue, extra }) => {
      const commentsService = extra.comments;
      const currentModule = getMfpModule(getState());

      const readyScope = getScopeReadyData(getState().mfpScope);
      if (readyScope && currentModule) {
        try {
          await commentsService.addComment(currentModule.siloId, newComment.planId, newComment);
          return await commentsService.getPlanComments(currentModule.siloId, newComment.planId);
        }
        catch (err) {
          return rejectWithValue((err as Error).message);
        }
      }
      throw new Error('Scope needs to be ready to get comments');
    }
  );

export const loadScopeComments = createAsyncThunk<
  Comment[],
  undefined,
  ThunkApi & { rejectValue: string }>(
    'comments/loadScopeComments',
    async (_payload, { getState, rejectWithValue, extra }) => {
      const commentsService = extra.comments;
      const currentModule = getMfpModule(getState());
      const { mfpScope } = getState();

      const maybeScopeData = getScopeReadyData(mfpScope);
      if (maybeScopeData && currentModule) {
        try {
          const planIds = [
            ...maybeScopeData.mainConfig.initializedPlans,
            ...maybeScopeData.mainConfig.uninitializedPlans
          ].map((p) => p.id);

          const commentsPromises = planIds.map((id) => {
            return commentsService.getPlanComments(currentModule.siloId, id);
          });
          const commentsWithData = await Promise.all(commentsPromises);
          return commentsWithData.flatMap(c => c);
        }
        catch (err) {
          return rejectWithValue((err as Error).message);
        }
      }
      throw new Error('Scope needs to be ready to get comments');
    }
  );
type CommentsWithPlanId = [PlanId, Comment[]]
export const loadPlanComments = createAsyncThunk<
  CommentsWithPlanId,
  PlanId,
  ThunkApi & { rejectValue: string }>(
    'comments/loadPlanComments',
    async (commentsPlanId, { getState, rejectWithValue, extra }) => {
      const commentsService = extra.comments;
      const currentModule = getMfpModule(getState());

      if (currentModule) {
        try {
          const commentsPromises = [commentsPlanId].map((id) => {
            return commentsService.getPlanComments(currentModule!.siloId, id);
          });
          const commentsWithData = await (await Promise.all(commentsPromises)).flatMap(c => c);
          return [commentsPlanId, commentsWithData];
        }
        catch (err) {
          return rejectWithValue((err as Error).message);
        }
      }
      return rejectWithValue('Need an siloId to get comments');
    }
  );

export const updateComment = createAsyncThunk<
  AxiosResponse,
  Comment,
  ThunkApi & { rejectValue: string }>(
    'comments/updateComment',
    async (commentToUpdate, { getState, rejectWithValue, extra }) => {
      const commentsService = extra.comments;
      const currentModule = getMfpModule(getState());

      const readyScope = getScopeReadyData(getState().mfpScope);
      if (readyScope && currentModule) {
        try {
          return await commentsService.updateComment(currentModule.siloId, commentToUpdate.commentId);
        }
        catch (err) {
          return rejectWithValue((err as Error).message);
        }
      }
      throw new Error('Scope needs to be ready to get comments');
    }
  );

export const deleteComment = createAsyncThunk<
  void,
  string,
  ThunkApi & { rejectValue: string }
>(
  'comments/deleteComment',
  async (commentIdToDelete, { getState, extra, rejectWithValue }) => {
    const commentsService = extra.comments;
    const currentModule = getMfpModule(getState());

    const readyScope = getScopeReadyData(getState().mfpScope);
    if (readyScope && currentModule) {
      try {
        return await commentsService.deleteComment(currentModule.siloId, commentIdToDelete);
      }
      catch (err) {
        return rejectWithValue((err as Error).message);
      }
    }
    return rejectWithValue('Scope isnt ready, cant delete comments');
  }
);
