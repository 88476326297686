import { AppState } from 'src/store';
import { createSelector } from 'reselect';
import { get } from 'lodash';

import { projectState, StateSelection } from 'src/components/ListGridPair/ListGridPair.selectors';
import { FabType } from 'src/components/higherOrder/withFab';
import { GridViewOwnProps } from './GridView.container';
import { getDataFromCache, isDataLoaded } from 'src/services/pivotServiceCache';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { getfabProps } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';

// TODO: why is nothing using selectors and memoized?

function mapStateToProps(state: AppState, ownProps: GridViewOwnProps): StateSelection {
  const { gridView: viewState } = state.pages.hindsighting.styleColorReview;
  const {
    defns,
    title,
    allowWorklistFunctionality = false,
    fabType = FabType.none,
    hideTitle,
    hideCompanion,
    showFlowStatus,
  } = ownProps;

  const treeData = getDataFromCache(viewState)?.tree || [];
  const configLoaded = !viewState.isConfigLoading;
  const dataLoaded = isDataLoaded(viewState.viewDataState);
  const fabProps = getfabProps(state, fabType);
  const adornments: AdornmentType[] = get(viewState.viewDefns.grid, 'adornments', []);

  return {
    fabType,
    fab: fabProps,
    isPrintMode: state.print.isPrintMode,
    identityField: viewState.viewDefns.identityPropsConfig?.id || '',
    configLoaded,
    dataLoaded,
    treeData,
    subheaderState: state.subheader,
    title,
    subheaderViewDefns: defns.subheader,
    viewDefns: viewState.viewDefns,
    isConfigLoading: viewState.isConfigLoading,
    shouldFilterFlowStatus: showFlowStatus,
    allowWorklistFunctionality,
    adornments,
    activeTab: state.perspective.activeTab,
    downloadLink: ownProps.subheader?.downloadLink,
    viewDataState: viewState.viewDataState,
    hideTitle,
    hideCompanion,
    showFlowStatus,
  };
}

export const selectState = createSelector(mapStateToProps, projectState);
