import { createTypeStyle, TypeStyle } from 'typestyle';
import { zPrintSize } from 'src/services/configuration/codecs/general';
import { z } from 'zod';

export const printMargin = 0.5;
export interface PrintProps {
  isViewPrintable?: boolean;
  isPrintMode?: boolean;
  printWidth?: string;
  printHeight?: string;
}

export interface PrintSize extends z.infer<typeof zPrintSize> {}

let printTypeStyle: TypeStyle;
let styleDoc: HTMLStyleElement | null;
type SizeSetting = 'letter' | 'ledger' | 'tabloid' | '';

function maybeInitPrintStylesheet() {
  if (styleDoc && styleDoc.nextSibling && styleDoc.parentNode) {
    styleDoc.parentNode.removeChild(styleDoc);
    styleDoc = null;
  }
  if (!styleDoc) {
    styleDoc = document.createElement('style');
    document.head.appendChild(styleDoc);
    printTypeStyle = createTypeStyle(styleDoc);
  }
}

export function getPaperTypeFromWidth(width: number): SizeSetting {
  switch (true) {
    case width <= 8.5:
      return 'letter';
    case width <= 11:
      return 'letter';
    case width <= 14:
      return 'letter';
    case width <= 30:
      return 'ledger';
    default:
      return '';
  }
}

export function setPrintSize(width: number, height: number) {
  maybeInitPrintStylesheet();
  const { cssRaw: printCssRaw, reinit: printReinit } = printTypeStyle;
  const orientation = width > height ? 'landscape' : 'portrait';
  const subheaderBreakStyle =
    orientation === 'portrait' &&
    `.top-bar { grid-template-columns: auto 1fr !important; }
    .controls { grid-row: 2 !important; grid-column: 1 / -1 !important; }`;
  printReinit();
  printCssRaw(`
    @media print {
      @page {
        margin: ${0}in;
        size: ${getPaperTypeFromWidth(width + printMargin)} ${orientation};
      }
      * {
        transition: none !important;
      }
    }
    ${subheaderBreakStyle}
  `);
}
