import * as React from 'react';
import styles from 'src/components/HistoryStylePane/HistoryStylePane.styles';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';

type Props = {
  indexValue: number;
  viewDefnSlice: TenantConfigViewItem;
};
type State = {
  collapsed: boolean | undefined;
};

export default class HistoryStylePaneCollapsableGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: undefined,
    };

    this.handleClickOnTitleOrToggle = this.handleClickOnTitleOrToggle.bind(this);
  }

  handleClickOnTitleOrToggle() {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  }

  render() {
    const { collapsed } = this.state;
    const { indexValue, viewDefnSlice } = this.props;

    let isCollapsed = collapsed;
    if (isCollapsed === undefined) {
      isCollapsed = viewDefnSlice.collapsed;
    }

    // TODO: Go Pro with fontawesome so we can use these.
    // const CHEVRON_CIRCLE_UP = 'fal fa-chevron-circle-up';
    // const CHEVRON_CIRCLE_DOWN = 'fal fa-chevron-circle-down';
    const CHEVRON_CIRCLE_UP = 'fas fa-chevron-up';
    const CHEVRON_CIRCLE_DOWN = 'fas fa-chevron-down';

    let chevronClass = CHEVRON_CIRCLE_UP;
    let content: JSX.Element | null = (
      <div className={styles.historyStylePaneGroupContent} data-qa={`${viewDefnSlice.text}-subcontent`}>
        {this.props.children}
      </div>
    );

    if (isCollapsed) {
      chevronClass = CHEVRON_CIRCLE_DOWN;
      content = null;
    }

    let sectionStyles: string = styles.historyStylePaneGroup;
    // If no title is given, do not apply container styling.
    if (!viewDefnSlice.text) {
      sectionStyles = '';
    }

    return (
      <div key={indexValue} className={sectionStyles} data-qa={viewDefnSlice.text}>
        <div className={styles.historyStylePaneGroupTitleContainer} onClick={this.handleClickOnTitleOrToggle}>
          <div className={styles.historyStylePaneGroupTitle}>{viewDefnSlice.text || ''}</div>
          <div className={styles.historyStylePaneGroupToggle}>
            <i className={chevronClass} />
          </div>
        </div>
        {content}
      </div>
    );
  }
}
