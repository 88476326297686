import type {
  GridApi,
  IViewportDatasource,
  IViewportDatasourceParams
} from '@ag-grid-community/core';
import { isNil, last, tail } from 'lodash';
import { Field } from '../../../pivot/Field';
import PivotManager from '../../../pivot/Pivot.client';
import { PivotCell } from '../../../pivot/PivotCell';
import { FAUX_CELL, GROUP_DEPTH, S5_ROW, S5_ROW_COUNTER, TAG_DEPTH } from 'src/utils/interface/PivotCell.tags';

interface ViewportDataSourceOptions {
  manager: PivotManager,
  gridApi: GridApi,
  initialColumnIndicies: [number, number], // this is updated as a side-effect from other ag-pivot events
  imperativeAsyncRefreshOnly: boolean
}

export default class ViewportDatasource implements IViewportDatasource {
  protected manager: PivotManager;
  protected gridApi: GridApi;
  protected viewportParams: IViewportDatasourceParams | undefined;
  private imperativeAsyncRefreshOnly = false;
  public currentColumnIndicies: [number, number] = [0, 1];

  constructor(options: ViewportDataSourceOptions) {
    this.manager = options.manager;
    this.gridApi = options.gridApi;
    this.currentColumnIndicies = options.initialColumnIndicies;
    this.imperativeAsyncRefreshOnly = options.imperativeAsyncRefreshOnly;
  }

  public init(params: IViewportDatasourceParams) {
    // called by ag-grid when datasource is assigned
    this.viewportParams = params;
  }

  public onRowCountChanged() {
    // call this whenever the row count changes,
    // such as when the grid config changes
    const rowCount = this.manager.getRowCount();
    if (this.viewportParams) {
      this.viewportParams.setRowCount(rowCount);
    }
  }

  public updateManager(newManager: PivotManager) {
    this.manager = newManager;

    // update the row count when a new manager comes in
    // note that this triggers a data refresh from ag-grid
    this.onRowCountChanged();
  }

  public updateColumnIndicies(newColIndicies: [number, number], newRowIndicies: [number, number]): void {
    const [firstRowIndex, lastRowIndex] = newRowIndicies;
    this.currentColumnIndicies = newColIndicies;
    this.setViewportRange(firstRowIndex, lastRowIndex);
  }

  private fieldToFauxPivotCell = (
    field: Field,
    fieldIdx: number,
    groupIdx: number,
    emptyDisplay: boolean,
    rowDuplicateCount: number | undefined
  ) => {
    const memberIndex = field.group.getVisible().findIndex(g => g.member.id === field.member.id);
    return new PivotCell({
      row: fieldIdx,
      col: groupIdx,
      metricId: ' ',
      value: field.member.name,
      displayValue: emptyDisplay ? '' : field.member.name,
      tags: [
        FAUX_CELL,
        `${TAG_DEPTH}${field.depth}`,
        `${GROUP_DEPTH}${groupIdx}`,
        `${S5_ROW}${memberIndex}`,
        `${S5_ROW_COUNTER}${rowDuplicateCount}`
      ],
      advisoryTags: [],
      currencyId: null,
      formatKey: '',
      coreVer: []
    });
  };

  private mutateNewRowData(
    viewportLastRow: number
  ) {
    const rows: {
      [key: number]: PivotCell[]
    } = {};
    const manager = this.manager;

    // in order to correctly float the rows in the grid, each faux cell needs to have a css class
    // indicating which copy of a group it is, when a member is nested beneath another dimension
    // that way we can move the correct dom element in the grid when multiple copies of the same
    // member are present in view a the same time
    let rowMemberToDuplicateCellIndexMap: Record<string, number[]> = {};

    const rowGroupOffsets = this.manager.config.getRowGroups().map(group => {
      return group.getVisible().length;
    });

    for (let index = 0; index <= viewportLastRow; index++) {
      if (isNil(rows[index])) {
        // put an empty array in so we can push() to it
        rows[index] = [];
      }
      for (let rowGroupIndex = 0; rowGroupIndex < rowGroupOffsets.length; rowGroupIndex++) {
        let skipCount = 1;
        if (rowGroupOffsets.length > 1 && rowGroupIndex !== rowGroupOffsets.length - 1) {
          skipCount = rowGroupOffsets
            .slice(rowGroupIndex + 1)
            .reduce((prev, next) => prev * next);
        }

        const emptyRow = index % skipCount === 0 ? false : true;
        const headerField = manager.getRowHeaderField(index, rowGroupIndex);

        if (headerField && !emptyRow) {
          // hydrate the map if it doesn't exist, or push the newest index
          !rowMemberToDuplicateCellIndexMap[headerField.member.id] ?
            rowMemberToDuplicateCellIndexMap[headerField.member.id] = [index] :
            rowMemberToDuplicateCellIndexMap[headerField.member.id].push(index);
        }

        if (headerField) {
          const fauxCell = this.fieldToFauxPivotCell(
            headerField,
            index,
            rowGroupIndex,
            emptyRow,
            last(rowMemberToDuplicateCellIndexMap[headerField.member.id])
          );
          rows[index].push(fauxCell);
        }
      }
      rows[index] = rows[index].concat(this.manager.getRow(index));
    }

    return rows;
  }

  public changeAsyncHandling(to: boolean) {
    this.imperativeAsyncRefreshOnly = to;
  }

  public setViewportRange(firstRow: number, lastRow: number) {
    // currentColumnIndicies is updated as a side-effect from other ag-pivot events
    const [firstCol, lastCol] = this.currentColumnIndicies;
    const viewportFirstRow = firstRow;
    let viewportLastRow = lastRow;

    // this travesty fores the grid to *not* update data from remote, and only do so on command
    // I pray that this doesn't break the usual grid logic
    if (!this.imperativeAsyncRefreshOnly) {
      return this.manager
        .loadMoreCells({
          startRow: viewportFirstRow,
          endRow: viewportLastRow,
          startColumn: firstCol,
          endColumn: lastCol
        })
        .then(() => {
          this.viewportParams!.setRowData(this.mutateNewRowData(viewportLastRow));
          // viewportDataSource row changes don't trigger ag-grid events correctly, so do it here manually
          // note that this is a custom event type
          this.gridApi.dispatchEvent({ type: 'cellsReturned' });
        });
    }
    this.viewportParams!.setRowData(this.mutateNewRowData(viewportLastRow));
    // viewportDataSource row changes don't trigger ag-grid events correctly, so do it here manually
    // note that this is a custom event type
    this.gridApi.dispatchEvent({ type: 'cellsReturned' });
    return new Promise((resolve) => {
      resolve(undefined);
    });

  }
  public getRow(rowIndex: number) {
    this.viewportParams!.getRow(rowIndex);
  }
  public setRowData(rowData: {
    [key: number]: any
  }) {
    // this is just a passthrough to the viewport api
    this.viewportParams!.setRowData(rowData);
  }
}
