import { style } from 'typestyle';
import { rem } from 'csx';

export const MIN_COL_WIDTH = 5;

export const listPairStyle = style({
  $debugName: 'list-grid-pair-container',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.unloaded-main-content-container': {
      position: 'inherit',
    },
    // stolen from ConfigurableGrid.styles 
    // FIXME (this should be part of the "semi-global" assortment grid styling)
    [`.ag-row.ag-row-focus:not(.ag-row-hover) .editable-cell`]: {
      color: 'white',
    },
    [`.ag-row.ag-row-focus:not(.ag-row-hover) .s5-MuiCheckbox-root`]: {
      color: 'white',
    },
    [`.ag-row.ag-row-focus:not(.ag-row-hover) svg.s5-MuiSvgIcon-root`]: {
      fill: 'white',
    },
    [`.loading svg.s5-MuiSvgIcon-root`]: {
      fill: 'gray',
    },
    [`.redecorating svg.s5-MuiSvgIcon-root`]: {
      fill: 'gray',
    },
    '.header-row': {
      fontWeight: 700,
    },
    '.data-container': {
      overflow: 'auto',
      display: 'flex',
      flex: 1,
      minHeight: 1,
      height: 'inherit',
      $nest: {
        '.content-container': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    '.flow-sheet-container': {
      position: 'relative',
      width: '100%',
    },
    '.pricing-grid-container': {
      width: '100%',
      position: 'relative',
    },
    '.data-grid': {
      fontSize: '0.8rem',
      height: '100%',
    },
    '.ag-header': {
      zIndex: 1,
      background: 'white',
    },
    '.ag-header-cell.selectAllCheckbox[role="presentation"]': {
      paddingLeft: rem(0.6),

      $nest: {
        '& .ag-cell-label-container': {
          display: 'none',
        },
      },
    },
  },
});

export const gridActionsContainer = style({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: rem(0.5),
  marginRight: rem(1),
});
