import { style } from 'typestyle';

const FitView = style({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const getGroupsContainer = (width: number, height: number) =>
  style({
    width: width,
    height: height,
    display: 'flex',
    borderBottom: '1px solid #ddd',
    borderRight: '1px solid #ddd',
  });

const itemsContainer = style({
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

const horizontalLine = style({
  position: 'absolute',
  borderBottom: '#ddd 1px solid',
});

export default { FitView, getGroupsContainer, itemsContainer, horizontalLine };
