// Domain Quirks
// 'level:{levelId}' => 'member:{levelId}:id'
// 'attribute:{attributeId}' => '{attributeId}'

export function aggByToDataIndex(aggBy: string, nameOverride = false) {
  const [type, id] = aggBy.split(':');
  const lowType = type.toLocaleLowerCase();
  const lowId = id.toLocaleLowerCase();

  if (lowType === 'level') {
    return !nameOverride ? 'member:' + lowId + ':id' : 'member:' + lowId + ':name';
  } else if (lowType === 'attribute') {
    return lowId;
  } else {
    throw new Error(`Assertion Error, unable to parse aggBy "${aggBy}"`);
  }
}
