import React, { useState, useRef } from 'react';
import { Popover, Typography } from '@material-ui/core';
import { classes } from 'typestyle';
import { ReduxSlice as FilterSlice } from 'src/components/FilterPanel/FilterPanel.container';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import styles, { buildFilterIconClass } from 'src/components/Headerbar/Headerbar.styles';

interface FiltersLinkProps {
  filterSlice: FilterSlice;
  onFiltersOpen: () => void;
}

export const FiltersLink = ({ filterSlice, onFiltersOpen }: FiltersLinkProps) => {
  const isFiltersEnabled = Boolean(filterSlice.isFilterSensitive && filterSlice.lastSelections.length > 0);
  const selections = filterSlice.lastSelections;
  const [isOpen, setPopoverOpen] = useState(false);
  const anchorElement = useRef(null);

  const onLinkClick = () => {
    if (filterSlice.isFilterSensitive) {
      onFiltersOpen();
    }
    setPopoverOpen(true);
  };

  const handleMouseOver = () => setPopoverOpen(true);
  const handleMouseLeave = () => setPopoverOpen(false);

  return (
    <React.Fragment>
      <IconLink
        id="FiltersLink"
        iconClass={buildFilterIconClass(isFiltersEnabled)}
        linkClass={classes(`${filterSlice.isFilterSensitive ? '' : 'disabled'}`)}
        text="Filters"
        onClick={onLinkClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={anchorElement}
        data-qa="headerbar-filter-icon"
      />
      {filterSlice.isFilterSensitive && (
        <Popover
          open={isOpen}
          anchorEl={anchorElement.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          classes={{ root: styles.filterPopover }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus={true}
        >
          <Typography>Selected Filters ({selections.length}):</Typography>
          <ul>
            {selections.map((filterSelection) => {
              return <li key={filterSelection.id}>{filterSelection.id}</li>;
            })}
          </ul>
        </Popover>
      )}
    </React.Fragment>
  );
};
