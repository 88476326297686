import { filter, isNil, isArray } from 'lodash';

export function noCaseExactMatch(searchee: string | null, term: string) {
  return !isNil(searchee) && searchee.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1;
}

export function arrayStringToArray(value: string, origIfNotMatch?: boolean) {
  if (isNil(value)) {
    return [];
  } else if (isArray(value)) {
    return value;
  }

  const matched = value.match(/{(.*)}/);
  if (matched) {
    return filter(matched[1].split(','), (val) => val.trim().length >= 1);
  } else if (!origIfNotMatch) {
    return [];
  }

  return value;
}

export const lowerAndReplaceTabTitle = (tabTitle: string) => tabTitle.toLowerCase().replace(' ', '-');
