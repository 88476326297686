import React, { Fragment } from 'react';
import { isEmpty, isNil, isNumber, debounce, has, noop } from 'lodash';
import { InputInteger } from 'src/common-ui';
import {
  ConfigurableDataSectionHeaderProps,
  ConfigurableDataSectionRowCellProps,
  ConfigurableDataSectionTotalRowProps,
  ConfigurableDataSectionRowProps,
  ConfigurableDataSectionRowsProps,
  ConfigurableDataSectionProps,
  ConfigurableDataModalTabsProps,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.types';
import { isCellEditable } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.utils';
import { FINAL_QTY } from 'src/utils/Domain/Constants';
import styles from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal.styles';

import coalesce from 'src/utils/Functions/Coalesce';
import { isInvalidNumber } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/TargetSetting.utils';
import { Tabs } from '@material-ui/core';
import * as globalMath from 'mathjs';

const ConfigurableDataSectionHeader = ({ headers }: ConfigurableDataSectionHeaderProps) => {
  return (
    <div className={styles.tableRow}>
      {headers.map((header, index) => {
        const className = index > 0 ? styles.tableCellHeader : styles.tableCellHeaderFirst;
        return (
          <div className={className} key={`${header}-${index}`}>
            {header}
          </div>
        );
      })}
    </div>
  );
};

const ConfigurableDataSectionRowCell = ({
  cellConfig,
  cellClassName,
  cellValue,
  highlight,
  onCellEdit,
}: ConfigurableDataSectionRowCellProps) => {
  const { rowHeaderDataIndexValue, dataIndex, editable } = cellConfig;

  if (!editable) {
    if (!highlight) {
      return <div className={cellClassName}>{cellValue}</div>;
    }

    // only want to highlight final column in receipts view
    return (
      <div className={cellClassName} style={{ color: dataIndex !== FINAL_QTY ? 'inherit' : '#ff0000' }}>
        {cellValue}
      </div>
    );
  }

  return (
    <div className={styles.tableCellInput}>
      <InputInteger
        onChange={(value) => {
          const parsedValue = isNumber(value) ? value : parseInt(value || '');
          onCellEdit(rowHeaderDataIndexValue, dataIndex, parsedValue);
        }}
        editable={editable}
        valid={true}
        value={cellValue}
        nullable={true}
      />
    </div>
  );
};
interface CreateCalculationParams {
  calculation: string;
  rowData: { [s: string]: number | string | null | boolean };
}

const createCalculation = ({ calculation, rowData }: CreateCalculationParams) => {
  const calc = globalMath.parse(calculation);
  if (rowData == null) {
    return '';
  }
  const vars: { [s: string]: number | string | null | boolean | undefined } = {};
  calc
    .filter((node) => node.isSymbolNode)
    .forEach((node) => {
      const id = node.name || '';
      if (!has(vars, id) && !has(globalMath, id)) {
        // FIXME: Right now, we use $ to represent `:` in var names as `:` is the range
        // operator. This is where we hotswap back. Yes...I know it's stupid. But so is
        // our `attribute:<name>:name` stuff so...vOv
        const key = id.replace(/\$/g, ':');
        vars[id] = coalesce(rowData[key], '');
      }
    });
  let result;
  try {
    result = globalMath.evaluate(calculation, vars);
  } catch (e) {
    // TODO: Mark has been naughty here...
    noop();
  }
  if (isInvalidNumber(result)) {
    result = 0;
  }
  return result;
};

// TODO: see about combining component with regular DataSectionRow below, very minor differences I believe
const ConfigurableDataSectionTotalRow = ({
  sectionConfig,
  totalRowData,
  highlight,
}: ConfigurableDataSectionTotalRowProps) => {
  const { modalEditsEnabled, dataRules, columns, onCellEdit } = sectionConfig;
  return (
    <div className={styles.tableRow}>
      {columns.map((column, index) => {
        const { dataIndex, editable, editableByCalc } = column;
        const className = index > 0 ? styles.tableCellBody : styles.tableCellBodyFirst;
        let value: string | number | null;
        if (column.calculation) {
          value = createCalculation({ calculation: column.calculation, rowData: totalRowData });
        } else {
          value = index > 0 ? totalRowData[dataIndex] : 'Total';
        }
        const isEditable = !modalEditsEnabled
          ? false
          : isCellEditable({ dataIndex, editable, editableByCalc }, dataRules, totalRowData);

        return (
          <ConfigurableDataSectionRowCell
            key={`${dataIndex}-${index}`}
            cellConfig={{ rowHeaderDataIndexValue: 'Total', dataIndex, editable: isEditable }}
            cellClassName={className}
            cellValue={value}
            highlight={highlight}
            onCellEdit={onCellEdit}
          />
        );
      })}
    </div>
  );
};

const ConfigurableDataSectionRow = ({
  sectionConfig,
  rowData,
  highlight,
  totalRowData,
}: ConfigurableDataSectionRowProps) => {
  if (isEmpty(rowData)) {
    return <div className={styles.tableRow} />;
  }

  const { modalEditsEnabled, dataRules, columns, rowHeaderDataIndex, onCellEdit } = sectionConfig;
  return (
    <Fragment>
      {rowData.map((data, index) => {
        return (
          <div className={styles.tableRow} key={`data-${index}`}>
            {columns.map((column, index) => {
              const { dataIndex, editable, editableByCalc } = column;
              const className = index > 0 ? styles.tableCellBody : styles.tableCellBodyFirst;
              let value: string | number | null;
              if (column.calculation) {
                value = createCalculation({ calculation: column.calculation, rowData: data });
              } else {
                // First column was reutrning undefined in modals without rowHeaderData
                value = index === 0 && !isNil(data[rowHeaderDataIndex]) ? data[rowHeaderDataIndex] : data[dataIndex];
              }
              const isEditable = !modalEditsEnabled
                ? false
                : isCellEditable({ dataIndex, editable, editableByCalc }, dataRules, totalRowData);

              return (
                <ConfigurableDataSectionRowCell
                  key={`${dataIndex}-${index}`}
                  cellConfig={{
                    rowHeaderDataIndexValue: data[rowHeaderDataIndex] as string,
                    dataIndex,
                    editable: isEditable,
                  }}
                  cellClassName={className}
                  cellValue={value as string}
                  highlight={highlight}
                  onCellEdit={onCellEdit}
                />
              );
            })}
          </div>
        );
      })}
    </Fragment>
  );
};

const ConfigurableDataSectionRows = ({
  renderSizeRowData,
  rowData,
  totalRowData,
  sectionConfig,
  highlight,
}: ConfigurableDataSectionRowsProps) => {
  return (
    <Fragment>
      {renderSizeRowData ? (
        <ConfigurableDataSectionRow
          sectionConfig={sectionConfig}
          rowData={rowData}
          highlight={highlight}
          totalRowData={totalRowData}
        />
      ) : (
        <ConfigurableDataSectionTotalRow
          sectionConfig={sectionConfig}
          totalRowData={totalRowData}
          highlight={highlight}
        />
      )}
    </Fragment>
  );
};

export const ConfigurableDataSection = ({
  sectionHeader,
  sectionConfig,
  totalRowData,
  rowData,
  highlight,
}: ConfigurableDataSectionProps) => {
  const { columns } = sectionConfig;
  const headers = columns.map((c) => c.text);

  return (
    <div className={styles.receiptContainer}>
      <div className={styles.headerSection}>
        <span className={styles.sectionText}>{sectionHeader}</span>
      </div>
      <div className={styles.tableSection}>
        <ConfigurableDataSectionHeader headers={headers} />
        <ConfigurableDataSectionRows
          renderSizeRowData={!isNil(rowData)}
          rowData={!isNil(rowData) ? rowData : []}
          totalRowData={totalRowData}
          sectionConfig={sectionConfig}
          highlight={highlight}
        />
      </div>
    </div>
  );
};

export const ConfigurableDataModalTabs = ({ tabs, activeTabIndex, onTabChange }: ConfigurableDataModalTabsProps) => {
  return (
    <Tabs
      value={activeTabIndex}
      variant="scrollable"
      scrollButtons="on"
      aria-label="scrollable auto tabs"
      style={{ minHeight: 30 }}
      className={styles.tabContainer}
    >
      {tabs.map((tab, index) => {
        const tabClass = activeTabIndex === index ? styles.tabButtonActive : styles.tabButton;
        const handleTabChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation();
          onTabChange(index);
        };
        return (
          <button className={tabClass} onClick={handleTabChange} key={`${tab}-${index}`}>
            {tab}
          </button>
        );
      })}
    </Tabs>
  );
};
