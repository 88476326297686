import { connect } from 'react-redux';
import { first, last, defaultTo } from 'lodash';

import HindsightingPeriodForm from './HindsightingPeriodForm';
import { AppState, AppDispatch } from 'src/store';
import { changeScopeSelection, clearFloorsetAndQuickSelect } from '../AssortmentScopeSelector.slice';
import { BasicItem, DaysRangeListResponse, SelectionErrors } from 'src/types/Scope';

export interface StateProps {
  allowFrom: string;
  allowTo: string;
  selectionErrors: SelectionErrors;
  selectedStartWeek: string | null;
  selectedEndWeek: string | null;
  rangeList: BasicItem[];
  daysRangeList: DaysRangeListResponse;
}

export interface DispatchProps {
  onChangeStartWeek(historyStart: string): void;
  onChangeEndWeek(historyEnd: string): void;
}

function mapStateToProps(state: AppState): StateProps {
  const { scope } = state;
  const { selections, selectionErrors } = scope;

  const startRangeList = scope.pastRangeList.filter(
    (x) => Object.values(scope.daysPastRangeList.start_date).indexOf(x.id) !== -1
  );
  const endRangeList = scope.pastRangeList.filter(
    (x) => Object.values(scope.daysPastRangeList.end_date).indexOf(x.id) !== -1
  );
  const allowFrom = defaultTo(first(startRangeList)?.id, '');
  const allowTo = defaultTo(last(endRangeList)?.id, '');

  return {
    allowFrom,
    allowTo,
    selectionErrors,
    selectedStartWeek: selections.historyStart,
    selectedEndWeek: selections.historyEnd,
    rangeList: scope.pastRangeList,
    daysRangeList: scope.daysPastRangeList,
  };
}

function mapDispatchToProps(dispatch: AppDispatch): DispatchProps {
  return {
    onChangeStartWeek(historyStart: string) {
      dispatch(changeScopeSelection({ historyStart }));
      dispatch(clearFloorsetAndQuickSelect());
    },
    onChangeEndWeek(historyEnd: string) {
      dispatch(changeScopeSelection({ historyEnd }));
      dispatch(clearFloorsetAndQuickSelect());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HindsightingPeriodForm);
