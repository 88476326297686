import { createSlice } from '@reduxjs/toolkit';
import { receiveError as canvasViewError } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.slice';
import { receiveError as gridViewError } from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.slice';
import { receiveError as flowTypeError } from 'src/pages/Hindsighting/StyleColorReview/FlowType/FlowType.slice';
import { receiveError as paretoAnalysisError } from 'src/pages/Hindsighting/Performance/ParetoAnalysis/ParetoAnalysis.slice';
import { receiveError as topPerformersError } from 'src/pages/Hindsighting/MacroTrends/TopPerformers/TopPerformers.slice';
import { receiveError as quickTrendsError } from 'src/pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends.slice';
import { receiveError as nestedViewError } from 'src/pages/Hindsighting/HistoryGrid/NestedView/NestedView.slice';
import { receiveError as listViewError } from 'src/pages/Hindsighting/HistoryGrid/ListView/ListView.slice';
import { receiveError as productMixError } from 'src/pages/Hindsighting/CategoryRecap/ProductMix/ProductMix.slice';
import { receiveError as productivityError } from 'src/pages/Hindsighting/CategoryRecap/Productivity/Productivity.slice';
import { receiveError as categorySummaryError } from 'src/pages/Hindsighting/CategoryRecap/CategorySummary/CategorySummary.slice';
import { receiveError as productDetailsError } from 'src/pages/Hindsighting/AggregateViews/ProductDetails/ProductDetails.slice';
import { receiveError as nestedAttributeError } from 'src/pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.slice';
import { receiveError as pricingOverTimeError } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';
import { receiveError as flowSheetByStyleError } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.slice';
import { receiveError as floorsetComparisonError } from 'src/pages/AssortmentBuild/FloorsetComparison/FloorsetComparison.slice';
import { receiveError as assortmentPublishError } from 'src/pages/AssortmentBuild/AssortmentPublish/AssortmentPublish.slice';
import { receiveError as assortmentCartError } from 'src/pages/AssortmentCart/AssortmentCart.slice';
import { receiveError as assortmentAddError } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAdd.slice';
import { receiveError as sizeEligibilityListGridError } from 'src/pages/Allocation/SizeEligibilityListGrid/SizeEligibilityListGrid.slice';
import { receiveError as parameterTogglesError } from 'src/pages/Allocation/ParameterToggles/ParemeterToggles.slice';
import { receiveError as topTYvsLYError } from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.slice';
import { receiveError as summaryViewError } from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.slice';
import { receiveError as collectionViewError } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.slice';
import { receiveError as reportingViewError } from 'src/pages/Reporting/Reporting.slice';
import { receiveError as summaryError } from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.slice';
import { receiveError as macroMixError } from 'src/pages/Hindsighting/MacroTrends/MacroMix/MacroMix.slice';
import { receiveError as geoTrendsError } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/GeoTrends.slice';
import { receiveError as configurableGridError } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { receiveError as shareDataError } from 'src/pages/Hindsighting/StyleColorReview/SharedData.slice';
import { receiveWorklistError as workListError } from 'src/pages/Worklist/Worklist.slice';
import { receiveError as styleEditError } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.slice';
import { receiveError as nestedOverTime } from 'src/pages/AssortmentBuild/OvertimeView/NestedOvertime/NestedOvertime.slice';
import { receiveError as visualize } from 'src/components/Visualize/Visualize.slice';
import { z } from 'zod';
import { AxiosError } from 'axios';
export enum ComponentErrorType {
  /** An error occurred while retrieving/parsing the view's viewDefn */
  config = 'config',
  /** An error occurred while retrieving/parsing the view's data */
  data = 'data',
  /** An error occurred during rendering of the view that can crash the app */
  view = 'view',
}

export interface ErrorBoundaryComponentError {
  type: ComponentErrorType;
  issues?: z.ZodError | AxiosError | unknown;
  name: ConfDefnComponentType;
  stack?: string;
  code?: number;
  message: string;
  defnId?: string;
}

interface ErrorSlice {
  currentError: ErrorBoundaryComponentError | null;
}

const initialState: ErrorSlice = {
  currentError: null,
};

const handleError = (state: ErrorSlice, error: ErrorBoundaryComponentError) => {
  state.currentError = error;
};

const errorBoundaryReducer = createSlice({
  name: 'ErrorBoundary',
  initialState,
  reducers: {
    resetError(state) {
      state.currentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gridViewError, (state, action) => handleError(state, action.payload))
      .addCase(canvasViewError, (state, action) => handleError(state, action.payload))
      .addCase(flowTypeError, (state, action) => handleError(state, action.payload))
      .addCase(paretoAnalysisError, (state, action) => handleError(state, action.payload))
      .addCase(topPerformersError, (state, action) => handleError(state, action.payload))
      .addCase(quickTrendsError, (state, action) => handleError(state, action.payload))
      .addCase(nestedViewError, (state, action) => handleError(state, action.payload))
      .addCase(listViewError, (state, action) => handleError(state, action.payload))
      .addCase(productMixError, (state, action) => handleError(state, action.payload))
      .addCase(productivityError, (state, action) => handleError(state, action.payload))
      .addCase(categorySummaryError, (state, action) => handleError(state, action.payload))
      .addCase(productDetailsError, (state, action) => handleError(state, action.payload))
      .addCase(nestedAttributeError, (state, action) => handleError(state, action.payload))
      .addCase(pricingOverTimeError, (state, action) => handleError(state, action.payload))
      .addCase(flowSheetByStyleError, (state, action) => handleError(state, action.payload))
      .addCase(floorsetComparisonError, (state, action) => handleError(state, action.payload))
      .addCase(assortmentPublishError, (state, action) => handleError(state, action.payload))
      .addCase(assortmentCartError, (state, action) => handleError(state, action.payload))
      .addCase(assortmentAddError, (state, action) => handleError(state, action.payload))
      .addCase(sizeEligibilityListGridError, (state, action) => handleError(state, action.payload))
      .addCase(parameterTogglesError, (state, action) => handleError(state, action.payload))
      .addCase(topTYvsLYError, (state, action) => handleError(state, action.payload))
      .addCase(summaryViewError, (state, action) => handleError(state, action.payload))
      .addCase(collectionViewError, (state, action) => handleError(state, action.payload))
      .addCase(macroMixError, (state, action) => handleError(state, action.payload))
      .addCase(reportingViewError, (state, action) => handleError(state, action.payload))
      .addCase(summaryError, (state, action) => handleError(state, action.payload))
      .addCase(geoTrendsError, (state, action) => handleError(state, action.payload))
      .addCase(configurableGridError, (state, action) => handleError(state, action.payload))
      .addCase(shareDataError, (state, action) => handleError(state, action.payload))
      .addCase(workListError, (state, action) => handleError(state, action.payload))
      .addCase(styleEditError, (state, action) => handleError(state, action.payload))
      .addCase(nestedOverTime, (state, action) => handleError(state, action.payload))
      .addCase(visualize, (state, action) => handleError(state, action.payload));
  },
});

export default errorBoundaryReducer.reducer;
