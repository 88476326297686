import { fromPredicate, snoc, Traversable } from 'fp-ts/lib/Array';
import { concat, has, isArray, set, uniqBy, get, assign } from 'lodash';
import { without } from 'lodash/fp';
import { fromTraversable, Lens, Prism } from 'monocle-ts';
import { createId } from '@paralleldrive/cuid2';
import { flow, pipe } from 'fp-ts/lib/function';
import { getOrElse, map, none, some, toNullable } from 'fp-ts/lib/Option';
import { compact as arrayCompact, map as arrayMap, filterMap } from 'fp-ts/lib/Array';
import { option } from 'fp-ts';

export interface HasId {
  id: string;
  type?: string;
  name: string;
}

export interface HasChildren extends HasId {
  children: HasId[];
}

function isHasChildren(item: HasId & unknown): item is HasChildren {
  return has(item, 'children') && isArray((item as HasChildren).children);
}

export function makeCartService<S, T extends HasId>(lens: Lens<S, T[]>, idKey: keyof T = 'id') {
  function getItemPrism(item: T) {
    return lens.composeTraversal(fromTraversable(Traversable)<T>()).composePrism(
      Prism.fromPredicate((it) => {
        return it[idKey] === item[idKey];
      })
    );
  }

  function findItemOption(item: T) {
    return lens
      .composeTraversal(fromTraversable(Traversable)<T>())
      .asFold()
      .find((it) => it[idKey] === item[idKey]);
  }
  const fullItemsLens = lens;

  const service = {
    addItemsToCart: function addItemsToCart(items: T[], state: S): S {
      return fullItemsLens.modify((its) => {
        return concat(its, items);
      })(state);
    },
    addItemToCart: function addItemToCart(item: T, state: S): S {
      return fullItemsLens.modify((items) => {
        return snoc(items, item);
      })(state);
    },
    duplicateItemInCart: function duplicateItem(item: T, state: S): S {
      return pipe(
        findItemOption(item)(state),
        map((foundItem: T) => {
          return assign({}, foundItem, {
            [idKey]: createId(),
            type: 'similar',
            name: foundItem.type === 'existing' ? `S5_${foundItem.name}` : foundItem.name,
          });
        }),
        map((newItem) => {
          return service.addItemToCart(newItem, state);
        }),
        getOrElse(() => state)
      );
    },
    removeItemFromCart: function removeItemFromCart(item: T, state: S): S {
      return pipe(
        findItemOption(item)(state),
        map((newItem) => {
          return fullItemsLens.modify(without([newItem]))(state);
        }),
        getOrElse(() => state)
      );
    },
    removeAllItemsFromCart: function removeAllItemsFromCart(state: S): S {
      return fullItemsLens.set([])(state);
    },
    toggleItem: function toggleItem(item: T, state: S): S {
      return pipe(
        findItemOption(item)(state),
        map((it) => {
          return service.removeItemFromCart(it, state);
        }),
        getOrElse(() => {
          // TODO: Remove toggle item logic, figure out smarter way.
          return service.addItemToCart(item, state);
        })
      );
    },
    // TODO: This function should be moved to a higher-order;
    concatChildToItem: function concatChildToItem(item: T, children: HasId[], type: string, state: S): S {
      const findOption = lens
        .composeTraversal(fromTraversable(Traversable)<T>())
        .asFold()
        .find((it) => it.id === item.id && get(it, 'type') === type)(state);

      return pipe(
        findOption,
        map((cartItem) => {
          let newChildren: unknown[];
          if (isHasChildren(cartItem)) {
            newChildren = uniqBy(concat(cartItem.children, children), 'id');
          } else {
            newChildren = children;
          }
          // FIXME: need to fix so there is no type coercion
          return service.updateItemKeyFromCart(
            'children' as keyof T,
            (newChildren as unknown) as T[keyof T],
            cartItem,
            state
          );
        }),
        getOrElse(() => {
          const newItem = set(item, 'children', children);
          return service.addItemToCart(newItem, state);
        })
      );
    },
    updateItemKeyFromCart: function updateItemKeyFromCart(key: keyof T, value: T[keyof T], item: T, state: S) {
      return getItemPrism(item)
        .composeLens(Lens.fromProp<T>()(key))
        .set(value)(state);
    },
    fetchItem: function fetchItemFromCart(item: T, state: S): T | null {
      return pipe(
        findItemOption(item)(state),
        map((foundItem: T) => {
          return foundItem;
        }),
        toNullable
      );
    },
    clearCart: function clearCart(state: S) {
      return lens.set([])(state);
    },
  };
  // wrapping all functions in the `update local storage` function
  return service;
}
