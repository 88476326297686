import { combineReducers } from 'redux';
import nestedViewReducer, { NestedViewSlice } from './NestedView/NestedView.slice';
import listViewReducer, { ListViewSlice } from './ListView/ListView.slice';

export interface HistoryGridSlice {
  nestedView: NestedViewSlice;
  listView: ListViewSlice;
}

export default combineReducers({
  nestedView: nestedViewReducer,
  listView: listViewReducer,
});
