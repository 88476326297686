import { AxiosInstance } from 'axios';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from 'src/store';
import { FavoritesEntry } from 'src/services/Favorites';
import { createFavorite } from 'src/state/favorites/favorites.slice';
import {
  redoScope,
  saveVersion,
  undoScope,
  unlockScopeLockState,
  forceRefreshGrid,
} from 'src/state/scope/Scope.actions';
import { updateGridAsyncState } from 'src/state/scope/Scope.slice';
import { getScopeId, getScopeReadyData, GRID_DEFAULT } from 'src/state/scope/Scope.types';
import { SplitViewValueProps, SplitViewGridProps } from './split-view.types';
import { SplitView } from './split-view';
import { extractNavPath } from 'src/pages/NavigationShell/navigationUtils';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { isSome } from 'fp-ts/lib/Option';
export function mapStateToProps(state: AppState): SplitViewValueProps {
  let tempPivotName = 'pivot';
  const maybeMatchHash = extractNavPath(window.location, 'hash');
  if (isSome(maybeMatchHash)) {
    // should be view id
    // FIXME add mfp support to work list
    tempPivotName = isSome(maybeMatchHash) ? maybeMatchHash.value[3].split('?')[0] : 'pivot';
  }
  const scopeReadyData = getScopeReadyData(state.mfpScope);

  // viewParams will be loaded into each grid's state in cmp getDerivedStateFromProps
  const initialGridProps: SplitViewGridProps = {
    gridAsyncState: scopeReadyData?.gridAsyncState || GRID_DEFAULT,
    pivotName: tempPivotName,
  };
  // FIXME this view expects scope/module to be ready at mount, but it isn't anymore
  const currentModule = getMfpModule(state)?.id || '';

  return {
    // global to both grids
    scopeId: getScopeId(state.mfpScope),
    mainConfig: scopeReadyData?.mainConfig,
    initialized: scopeReadyData?.initialized || false,
    settings: state.settings.entriesByKey,
    groupId: currentModule,
    isFetchingScope: scopeReadyData?.isFetching || false,
    scopeReady: !!scopeReadyData || false,
    reports: state.workingSets.reports,
    isLockToggled: !!scopeReadyData?.hasLocks,
    hasEditableRevision: scopeReadyData?.hasEditableRevision || false,
    // grid specific
    topGrid: { ...initialGridProps, pivotName: `${tempPivotName}-top` },
    bottomGrid: { ...initialGridProps, pivotName: `${tempPivotName}-bottom` },
  };
}
export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, any, Action<any>>) => {
  return {
    onUnlockAllClick: (cmp: SplitView, axios: AxiosInstance, scopeId: string) => {
      // @ts-ignore
      dispatch(unlockScopeLockState(axios, scopeId)).then(() => {
        if (cmp.topPivot && cmp.bottomPivot) {
          cmp.topPivot.refreshCells();
          cmp.bottomPivot.refreshCells();
        }

        cmp.updateContextMenus();
      });
    },
    addToFavorites: (axios: AxiosInstance, fav: FavoritesEntry) => dispatch(createFavorite(axios, fav)),
    saveWorkingPlan: (axios: AxiosInstance, scopeId: string) => dispatch(saveVersion(axios, scopeId)),
    onClickUndo: (scopeId: string) => dispatch(undoScope(scopeId)),
    onClickRedo: (scopeId: string) => dispatch(redoScope(scopeId)),
    onforceRefreshGrid: () => dispatch(forceRefreshGrid()),
    onUpdateGridAsyncState: updateGridAsyncState,
  };
};
