import { clone, get, isEmpty, isEqual, isNil, isString } from 'lodash';
import { BindAll } from 'lodash-decorators';
import React, { ReactNode } from 'react';
import { Button, Dropdown, Segment, DropdownItemProps } from 'semantic-ui-react';
import Modal from '@trendmicro/react-modal';
import { ScopeCreateRequest, TopMembers } from '../../../services/Scope.client';
import { getScopeId, getScopeReadyData, getTopMembers, isScopeReady } from '../../../state/scope/Scope.types';
import { DEFAULT_DIMENSIONS, TIME } from '../../../utils/Domain/Constants';
import './_ScopeSelection.scss';
import { getPrimary } from 'src/components/Mfp/PivotConfigurator/utils';
import { Space } from 'src/space';
import { AppState, AppThunkDispatch } from 'src/store';
import { connect } from 'react-redux';
import { getScope, newScope } from 'src/state/scope/Scope.actions';
import { narrowWorkflow } from './MfpScopebar.container';
import { Transition } from 'react-transition-group';
import FloatingBackdrop from '../../FloatingBackdrop/FloatingBackdrop';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { toggleMfpScopeSelector } from 'src/state/ViewConfig/ViewConfig.slice';
import ServiceContainer from 'src/ServiceContainer';

const mapStateToProps = (state: AppState) => {
  const { uiPose } = state;
  const currentModule = getMfpModule(state)?.pathSlot;
  const maybeLastScopeCreated = get(uiPose.lastScopeCreated, currentModule || '', undefined);
  const labelDimensions = state.settings.dimensionLabelProperty;
  const readyScope = getScopeReadyData(state.mfpScope);
  const scopeId = getScopeId(state.mfpScope);
  const availableMembers = state.viewConfigSlice.availableMembers;

  const isFetchingScope = get(state.mfpScope, 'isFetching', false);
  const showScopeSelector = state.viewConfigSlice.showScopeSelector || (!scopeId && !!availableMembers);

  if (!readyScope) {
    return {
      isFetchingScope,
      showScopeSelector,
      availableMembers,
      scopeId: undefined,
      labelDimension: labelDimensions,
      selectedMembers: undefined,
      lastScopeCreated: maybeLastScopeCreated,
      inSeason: state.viewConfigSlice.inSeason,
      scopeConfig: undefined,
      settingsByKey: state.settings.entriesByKey,
    };
  }

  return {
    isFetchingScope,
    showScopeSelector,
    availableMembers,
    scopeId: scopeId,
    labelDimension: labelDimensions,
    selectedMembers: getTopMembers(readyScope),
    lastScopeCreated: maybeLastScopeCreated,
    inSeason: state.viewConfigSlice.inSeason,
    scopeConfig: readyScope.mainConfig,
    settingsByKey: state.settings.entriesByKey,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    onAcceptScope: (topMembers: TopMembers, workflow: string) => {
      const request: ScopeCreateRequest = {
        initParams: { type: 'with-wp' },
        workflow: narrowWorkflow(workflow),
        anchor: topMembers,
      };
      return dispatch(() => {
        return dispatch<Promise<unknown>>(newScope(request));
      });
    },
    updateScopeInfo: (scopeId: string, module: string) => {
      return dispatch(getScope({ scopeId, module }));
    },
    handleToggleMfpScopeSelector: () => dispatch(toggleMfpScopeSelector()),
  };
};

interface ScopeSelectorOwnProps {
  loading: boolean;
}
type ScopeSelectorValueProps = ReturnType<typeof mapStateToProps>;
interface ScopeSelectorDispatchProps extends ReturnType<typeof mapDispatchToProps> { }
type ScopeSelectorProps = ScopeSelectorOwnProps & ScopeSelectorDispatchProps & ScopeSelectorValueProps;

interface OptionEntry {
  value: string;
  text: string;
  disabled?: boolean;
}

interface GroupOption {
  levels: OptionEntry[];
  members: OptionEntry[];
  dimensionId: string;
  dimensionName: string;
  maxSelections: number;
  icon?: ReactNode;
}
interface ScopeState {
  scopeMap: TopMembers | undefined;
  workflow: string | undefined;
  groupOptions: GroupOption[];
  workflowOptions: OptionEntry[];
  isUserSelectedWorkflow: boolean;
  initialDefaultsHaveBeenApplied: boolean;
  loading: boolean;
  scopeError: boolean;
  isModalAttached: boolean;
}

const WORKFLOW_OPTIONS = [
  {
    text: 'In-Season',
    value: 'in-season',
  },
  {
    text: 'Pre-Season',
    value: 'pre-season',
  },
];

const EMPTY_GROUP: GroupOption = {
  levels: [
    {
      text: '',
      value: '',
    },
  ],
  members: [],
  dimensionId: '',
  dimensionName: '',
  maxSelections: 1,
};

@BindAll()
export class MfpScopeSelector extends React.Component<ScopeSelectorProps, ScopeState> {
  constructor(props: ScopeSelectorProps) {
    super(props);
    const scopeConfig = props.scopeConfig;
    this.state = {
      groupOptions: [],
      workflow: scopeConfig && scopeConfig.scopeReady ? (scopeConfig.workflow as string) : 'pre-season',
      scopeMap: undefined,
      workflowOptions: WORKFLOW_OPTIONS,
      isUserSelectedWorkflow: false,
      initialDefaultsHaveBeenApplied: false,
      loading: false,
      scopeError: false,
      isModalAttached: false,
    };
  }

  public componentDidMount() {
    if (!this.props.scopeId && this.props.isFetchingScope) {
      // no scope, so bring up the scope screen
      // fetching is handled by an epic
      this.setState({
        loading: false,
        scopeError: true,
      });
    }
  }

  public static getDerivedStateFromProps(props: ScopeSelectorProps, state: ScopeState) {
    if (!props.availableMembers) {
      return state;
    }
    // TODO fix the types here
    const availableMembersSpace = props.availableMembers.space;
    const keys = (Object.keys(availableMembersSpace || {}) as unknown) as typeof DEFAULT_DIMENSIONS;
    const groups: Record<string, any> = {};
    const scopeMap = { ...state.scopeMap } as Space<string[]>;
    const scopeConfig = props.scopeConfig;
    // eslint-disable-next-line

    // TODO: actually use labelDimensions here, as text should be the configured property,
    // and not hardcoded to name
    function getMemberOptions(members: readonly any[]): DropdownItemProps[] {
      return members.map((member) => {
        return {
          text: member.name,
          value: member.id,
        };
      });
    }
    keys.forEach((key) => {
      const members = props.availableMembers?.space[key];
      if (!members) {
        return;
      }
      const levelsMap: Record<string, any> = {};
      const levels: DropdownItemProps[] = [];
      members.forEach((member) => {
        if (!levelsMap[member.level]) {
          levelsMap[member.level] = 1;
          levels.push({
            text: member.level,
            value: member.level,
          });
        }
      });
      const g = {
        levels,
        members: getMemberOptions(members),
        dimensionId: key,
        dimensionName: key,
        maxSelections: props.availableMembers?.maxSelections[key],
      };
      groups[g.dimensionId] = g;

      // Below we try and set the default selected members from various sources

      // First, if they already have an active scope, set the selections from it
      // get default from selected scope
      if (scopeConfig && isScopeReady(scopeConfig) && !scopeMap![g.dimensionId]) {
        const membersTrees = scopeConfig.memberTrees;
        if (membersTrees && membersTrees[g.dimensionId]) {
          // @ts-ignore
          scopeMap![g.dimensionId] = getPrimary(membersTrees[g.dimensionId]).map((mem) => mem.v.id);
        }
      }

      // If no active scope, try and set from whatever scope the last created
      // try and set from lastScopeCreated
      if (!scopeConfig && !isScopeReady(scopeConfig) && !scopeMap![g.dimensionId]) {
        // TODO: check if it's in availableMembers
        const validPreviousMembers =
          props.lastScopeCreated && props.lastScopeCreated[g.dimensionId]
            ? props.lastScopeCreated[g.dimensionId].filter((lastMemId) => members.find((m) => m.id === lastMemId))
            : [];
        if (!isEmpty(validPreviousMembers)) {
          scopeMap![g.dimensionId] = validPreviousMembers;
        }
      }

      // failing the above, set from the 0th members
      // fallback, get default from first in array
      if (!scopeMap![g.dimensionId] && g.members[0] && isString(g.members[0].value)) {
        if (g.dimensionId === TIME && availableMembersSpace && availableMembersSpace[TIME]) {
          // specific code to set the selected time member to current time when no time is set
          const timeMember = availableMembersSpace[TIME].find((member) => member.id === props.inSeason);
          if (timeMember) {
            // TODO maybe default to timeMember + 1 and preseason here
            if (!state.initialDefaultsHaveBeenApplied) {
              scopeMap!.time = [timeMember.id];
            }
          }
        } else {
          scopeMap[g.dimensionId] = [g.members[0].value];
        }
      }

      if (
        !props.showScopeSelector &&
        props.lastScopeCreated &&
        !isEqual(props.lastScopeCreated[g.dimensionId], scopeMap[g.dimensionId])
      ) {
        scopeMap[g.dimensionId] = props.lastScopeCreated[g.dimensionId];
      }
    });

    const groupOptions: GroupOption[] = [];
    DEFAULT_DIMENSIONS.forEach((dimension, idx) => {
      const g = groups[dimension];
      if (g) {
        groupOptions.push(g);
        if (props.settingsByKey) {
          // @ts-ignore
          g.levels.forEach((level) => {
            const lookupKey = `level.${level.value}.display`;
            const settingsEntry = props.settingsByKey[lookupKey];
            if (settingsEntry) {
              const name = settingsEntry.value;
              level.text = name;
              level.value = name;
            }
          });
        }
      } else {
        // these are here to render empty drop downs ahead of receiving scope
        const dummyGroup = clone(EMPTY_GROUP);
        dummyGroup.dimensionId = `dummyDim${idx.toString()}`;
        dummyGroup.dimensionName = dimension;
        groupOptions.push(dummyGroup);
      }
    });

    // setup as default in case workflow is not set yet
    let workflow: string = scopeConfig && isScopeReady(scopeConfig) ? (scopeConfig.workflow as string) : 'pre-season';
    // conditions for when selector is open to take whatever user selects
    if (props.showScopeSelector) {
      workflow = state.workflow ? state.workflow : 'pre-season';
    }

    return {
      scopeMap,
      groupOptions,
      workflowOptions: WORKFLOW_OPTIONS,
      workflow,
      isUserSelectedWorkflow: state.isUserSelectedWorkflow,
      initialDefaultsHaveBeenApplied: !isEmpty(scopeMap),
    };
  }

  public onScopeAccept() {
    if (this.props.onAcceptScope && this.scopeValid(this.state.groupOptions)) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          await this.props
            .onAcceptScope(this.state.scopeMap!, this.state.workflow || 'pre-season')
            .then(() => {
              this.setState({
                // isScopeVisible: false,
                loading: false,
                scopeError: false,
              });
            })
            .catch((e) => {
              toast.error('An error has occured while creating your scope', {
                position: toast.POSITION.TOP_LEFT,
              });
              ServiceContainer.loggingService.error(`An error has occured while creating scope ${JSON.stringify(this.state.scopeMap)}`,
              e.stack)
              this.setState({
                // isScopeVisible: true,
                loading: false,
                scopeError: true,
              });
            });
        }
      );
    }
  }

  public onMemberChange(dimensionId: string, memberId: string | string[]) {
    const copyMap = clone(this.state.scopeMap);
    if (!copyMap) {
      return;
    }
    copyMap[dimensionId] = isString(memberId) ? [memberId] : memberId;
    const newState: Pick<ScopeState, 'scopeMap' | 'isUserSelectedWorkflow' | 'workflow'> = {
      scopeMap: copyMap,
      isUserSelectedWorkflow: this.state.isUserSelectedWorkflow,
      workflow: this.state.workflow,
    };

    if (dimensionId === 'workflow' && isString(memberId)) {
      newState.isUserSelectedWorkflow = true;
      newState.workflow = memberId;
    }
    this.setState(newState);
  }

  public scopeValid(groups: GroupOption[]) {
    // all groups should have levels
    return (
      groups.filter((group) => group.levels.length > 0).length === groups.length &&
      groups.filter((group) => group.members.length > 0).length === groups.length
    );
  }

  public render() {
    const scopeValid = this.scopeValid(this.state.groupOptions);
    const invisibleIcon = <i className="chevron far fa-chevron-down" style={{ display: 'none' }} />;
    const areAllSingle = this.state.groupOptions.every((g) => g.maxSelections === 1);
    const speed = this.state.loading ? 2 : 20;
    const factor = this.state.loading ? 50 : 15;
    const intervalTime = this.state.loading ? 2000 : 1000;
    const loadingClass = this.state.loading ? 'scope-loading' : '';

    return (
      <div className="scope-options">
        <Transition in={false || this.props.showScopeSelector} timeout={0} mountOnEnter={true} unmountOnExit={false}>
          <Modal
            onClose={this.props.handleToggleMfpScopeSelector}
            className={classNames(`scope-select-modal scope-select-modal-entered`, loadingClass)}
            show={this.props.showScopeSelector}
            overlayClassName={classNames(
              'scope-overlay',
              // `scope-overlay scope-overlay-${state}`,
              'scope-overlay-header-space',
              loadingClass
            )}
          >
            <FloatingBackdrop numElements={30} speed={speed} factor={factor} intervalTime={intervalTime} />
            <Modal.Body padding={false}>
              <div className="scope-select-title" style={{}}>
                <i className="far fa-crosshairs" />
                {/* <span className="scope-select-title-text">{facetName}</span> */}
                <span className="scope-select-title-text"></span>
              </div>
              <React.Fragment>
                <div className="scope-select">
                  <span className="scope-select-group-container">
                    {this.state.groupOptions.map((g) => {
                      const noMembers = isEmpty(g.members);
                      const noLevels = isEmpty(g.levels);
                      const onlyOneLevel = !noLevels && g.levels.length === 1;
                      const errorPopup =
                        g.levels.length === 0 ? (
                          <React.Fragment>
                            <Segment className={'error-container'}>
                              <div className={'scope-error'}>
                                <h4>You do not have access to any {g.dimensionName || g.dimensionId}s</h4>
                                <p>
                                  This may be an error.
                                  <br /> You&apos;ll need to contact an administartor.
                                </p>
                              </div>
                            </Segment>
                          </React.Fragment>
                        ) : null;

                      const maxSelections = g.maxSelections;
                      const isMultiple: boolean = maxSelections !== 1 && g.members.length > 1;

                      // If it's a multiple dropdown and dimension 'time', restrict selectable values
                      if (isMultiple && g.dimensionId === TIME) {
                        const indexesToEnable: number[] = [];
                        g.members = g.members.map((x, i) => {
                          if (this.state.scopeMap![g.dimensionId].indexOf(x.value) > -1) {
                            if (i - 1 >= 0) indexesToEnable.push(i - 1);
                            if (i + 1 < g.members.length) indexesToEnable.push(i + 1);
                          }
                          if (this.state.scopeMap![g.dimensionId].length > 0) {
                            return { ...x, disabled: true };
                          }
                          return x;
                        });
                        // Disable options for time that are not adjacent to selected time
                        if (maxSelections === 0 || maxSelections > this.state.scopeMap![g.dimensionId].length) {
                          indexesToEnable.forEach((x) => (g.members[x] = { ...g.members[x], disabled: false }));
                        }
                      } else if (
                        isMultiple &&
                        maxSelections !== 0 &&
                        maxSelections <= this.state.scopeMap![g.dimensionId].length
                      ) {
                        // If maxSelections > 1 and maxSelections have been made, disable all options
                        g.members = g.members.map((x) => ({ ...x, disabled: true }));
                      }

                      const dropdownClass = isMultiple ? 'multi' : 'single';
                      const dropdownIcon = <i className={`chevron far fa-chevron-down ${dropdownClass}`} />;

                      return (
                        <div key={g.dimensionId} id={g.dimensionId} className="scope-select-dimension-group">
                          <Dropdown
                            key="level"
                            open={false}
                            className="scope-select-dropdown-level"
                            icon={onlyOneLevel ? invisibleIcon : dropdownIcon}
                            scrolling={true}
                            fluid={true}
                            value={!noLevels ? g.levels[0].value : 'Error'}
                            disabled={noLevels}
                            options={!noLevels ? g.levels : [{ text: 'Error', value: 'Error' }]}
                          />
                          <Dropdown
                            key="member"
                            className={`scope-select-dropdown-member scale ${!areAllSingle ? 'multi' : ''}`}
                            icon={dropdownIcon}
                            scrolling={true}
                            placeholder={noMembers ? 'Error' : ''}
                            fluid={true}
                            disabled={noMembers}
                            value={
                              !noMembers
                                ? isMultiple
                                  ? this.state.scopeMap![g.dimensionId]
                                  : this.state.scopeMap![g.dimensionId][0]
                                : 'Error'
                            }
                            multiple={isMultiple}
                            options={g.members}
                            onChange={(__, data) => {
                              this.onMemberChange(g.dimensionId, data.value as any);
                            }}
                          />
                          {errorPopup}
                        </div>
                      );
                    })}
                    <div key={'workflow'} className="scope-select-dimension-group">
                      <Dropdown
                        key="level"
                        open={false}
                        className="scope-select-dropdown-level"
                        icon={invisibleIcon}
                        scrolling={true}
                        fluid={true}
                        options={[{ text: 'Workflow', value: 'workflow' }]}
                        value={'workflow'}
                      />
                      <Dropdown
                        key="member"
                        className={`scope-select-dropdown-member scale ${!areAllSingle ? 'multi' : ''}`}
                        placeholder="Select choice"
                        icon={<i className="chevron far fa-chevron-down single" />}
                        scrolling={true}
                        fluid={true}
                        value={this.state.workflow}
                        options={this.state.workflowOptions}
                        onChange={(__, data) => this.onMemberChange('workflow', data.value as string)}
                      />
                    </div>
                    <Button
                      className={`accept-button ${!areAllSingle ? 'multi' : ''}`}
                      loading={this.props.loading}
                      icon={<i className="far fa-arrow-alt-circle-right" />}
                      onClick={this.onScopeAccept}
                      disabled={!scopeValid}
                    />
                  </span>
                </div>
              </React.Fragment>
            </Modal.Body>
          </Modal>
        </Transition>
      </div>
    );
  }
}
// redux types don't work with a valueProps union
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MfpScopeSelector);
