import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export type Action =
  | { type: 'REQUEST_HISTORY_STYLE_PANE' }
  | { type: 'RECEIVE_HISTORY_STYLE_PANE'; data: BasicPivotItem[] }
  | { type: 'RECEIVE_HISTORY_STYLE_PANE_TENANT_CONFIG'; config: TenantConfigViewData }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: 'MOVE_ITEM_TO_HISTORY'; memberId: string; item: Record<string, any> }
  | { type: 'RECEIVE_HISTORY_STYLE_PANE_ERROR'; message: string }
  | { type: 'CLEAN_HISTORY_STYLE_PANE_UP' };

export const REQUEST_HISTORY_STYLE_PANE = 'REQUEST_HISTORY_STYLE_PANE';
export function requestHistoryStylePane(): Action {
  return {
    type: REQUEST_HISTORY_STYLE_PANE,
  };
}
export const RECEIVE_HISTORY_STYLE_PANE = 'RECEIVE_HISTORY_STYLE_PANE';
export function receiveHistoryStylePane(data: BasicPivotItem[]): Action {
  return {
    type: RECEIVE_HISTORY_STYLE_PANE,
    data,
  };
}
export const RECEIVE_HISTORY_STYLE_PANE_TENANT_CONFIG = 'RECEIVE_HISTORY_STYLE_PANE_TENANT_CONFIG';
export function receiveHistoryStylePaneTenantConfig(config: TenantConfigViewData): Action {
  return {
    type: RECEIVE_HISTORY_STYLE_PANE_TENANT_CONFIG,
    config,
  };
}
export const MOVE_ITEM_TO_HISTORY = 'MOVE_ITEM_TO_HISTORY';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function moveItemToHistory(memberId: string, item: Record<string, any>): Action {
  return {
    type: MOVE_ITEM_TO_HISTORY,
    memberId,
    item,
  };
}
export const RECEIVE_HISTORY_STYLE_PANE_ERROR = 'RECEIVE_HISTORY_STYLE_PANE_ERROR';
export function receiveError(message: string): Action {
  return {
    type: RECEIVE_HISTORY_STYLE_PANE_ERROR,
    message,
  };
}
export const CLEAN_HISTORY_STYLE_PANE_UP = 'CLEAN_HISTORY_STYLE_PANE_UP';
export function cleanHistoryStylePaneUp(): Action {
  return {
    type: CLEAN_HISTORY_STYLE_PANE_UP,
  };
}
