import { ofType } from 'redux-observable';
import { filter, mergeMap, of, map } from 'rxjs';
import {
  maybeGetComponentProps,
  ConfDefnComponentType,
  StyleEditComponent,
} from 'src/services/configuration/codecs/confdefnComponents';
import { AppEpic } from 'src/store';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded } from 'src/utils/Functions/epicsFunctions';
import { fetchStyleEditData, receiveStyleEditConfigs } from './StyleEdit.slice';
import { receiveScopeConfig, receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { DefnProps } from 'src/services/configuration/codecs/confdefnComponentProps';

export const styleEditViewLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type, setActiveSubPage.type, receiveScopeRefreshTrigger.type, receiveStyleEditConfigs.type, receiveScopeConfig.type),
    map(() => maybeGetComponentProps<StyleEditComponent>(state$.value, ConfDefnComponentType.styleEdit)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }: { defns: DefnProps }) => {
      const { model } = defns;

      return of(fetchStyleEditData(model));
    })
  );
};
