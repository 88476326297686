import type { ValueGetterParams, ValueGetterFunc } from '@ag-grid-community/core';
import type { PlanMetadata } from './PlanMetadata';
import { isEmpty, isNil } from 'lodash';
import { z } from 'zod';
export const UserDetails = z.object({
  /** The auth0 uid (or magic string 'system' in the case of system owned plans) of UserDetails */
  uid: z.string(),
  /** Possibly the auth0 name of the user */
  name: z.union([z.string(), z.null()]),
  /** Possibly the auth0 email of the user */
  email: z.union([z.string(), z.null()])
});
export interface UserDetails extends z.infer<typeof UserDetails> {}

export const gridUserDetailsToString: ValueGetterFunc<PlanMetadata | undefined, any> = (params) => {
  return planMetadatToUserString('authoredBy', params);
};
export const gridUserModifiedByToString: ValueGetterFunc<PlanMetadata | undefined, any> = (params) => {
  return planMetadatToUserString('modifiedBy', params);
};

const planMetadatToUserString = (
  key: 'modifiedBy' | 'authoredBy', // slighty different implementations of the same function
  params: ValueGetterParams<PlanMetadata | undefined>): string => {
  // rowGroups have undef but still come through here, guard them somewhere so we don't have
  // to deal with it here
  if (!params.data) { return ''; }
  const userDetails = params.data[key];
  if ('name' in userDetails && !isNil(userDetails.name) && !isEmpty(userDetails.name)) {
    return userDetails.name;
  } else if ('email' in userDetails && !isNil(userDetails.email) && !isEmpty(userDetails.email)) {
    return userDetails.email;
  } else if ('uid' in userDetails && !isNil(userDetails.uid) && !isEmpty(userDetails.uid)) {
    return userDetails.uid;
  }
  return '';
};
