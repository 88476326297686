import {
  Action,
  CLEAN_HISTORY_STYLE_PANE_UP,
  REQUEST_HISTORY_STYLE_PANE,
  RECEIVE_HISTORY_STYLE_PANE,
  MOVE_ITEM_TO_HISTORY,
  RECEIVE_HISTORY_STYLE_PANE_TENANT_CONFIG,
} from './HistoryStylePane.actions';
import { TenantConfigViewData, defaultViewDefnData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

export const HISTORY_STYLE_PANE_HISTORY_MAX = 5;

interface HistoryStylePaneItem {
  memberId: string;
  item: Record<string, any>;
}
export interface ReduxSlice {
  viewDefn: TenantConfigViewData;
  currentItem: BasicPivotItem[] | null;
  history: HistoryStylePaneItem[];
  loading: boolean;
}

const initialState = {
  ...defaultViewDefnData,
  currentItem: null,
  history: [],
  loading: false,
};

export default function historyStylePaneReducer(state: ReduxSlice = initialState, action: Action): ReduxSlice {
  switch (action.type) {
    case REQUEST_HISTORY_STYLE_PANE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RECEIVE_HISTORY_STYLE_PANE: {
      return {
        ...state,
        currentItem: action.data,
        loading: false,
      };
    }
    case RECEIVE_HISTORY_STYLE_PANE_TENANT_CONFIG: {
      return {
        ...state,
        viewDefn: action.config,
      };
    }
    case MOVE_ITEM_TO_HISTORY: {
      const historyItem: HistoryStylePaneItem = {
        memberId: action.memberId,
        item: action.item,
      };
      const filteredHistory = state.history.filter((item) => item.memberId !== action.memberId);
      const newHistory = [historyItem, ...filteredHistory.slice(0, HISTORY_STYLE_PANE_HISTORY_MAX)];
      return {
        ...state,
        history: newHistory,
        currentItem: null,
      };
    }
    case CLEAN_HISTORY_STYLE_PANE_UP: {
      return initialState;
    }
    default:
      return state;
  }
}
