import { style } from 'typestyle';
import { calc } from 'csx';
import { DEFAULT_TIMECHART_HEIGHT } from 'src/components/TimeChart/TimeChart.utils';

const enhancedOvertimeContainer = style({
  $debugName: 'EnhancedOvertimeContainer',
  display: 'flex',
  flexDirection: 'column',
  height: '85%',
  $nest: {
    '& data-container': {
      minHeight: calc(`100%-${DEFAULT_TIMECHART_HEIGHT}`),
    },
  },
});

export default {
  enhancedOvertimeContainer,
};
