import React from 'react';
import './_LoadingMask.scss';
import { defaultTo } from 'lodash';

type LoadingMaskProps = {
  coverAll?: boolean
}

const LoadingMask: React.FC<LoadingMaskProps> = (props: LoadingMaskProps) => {
  const cover = defaultTo(props.coverAll, true); // used to partially reveal the mask sometimes
  return (
    <div className={`loading-mask ${cover ? '' : 'header-space'}`}>
      <div className="loading-mask-spinner" />
    </div>
  );
};
export default LoadingMask;
