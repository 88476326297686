import { makePivotClient } from './dao/pivotClient';
import { makeScopeClient, ScopeClient } from 'src/dao/scopeClient';
import { makeObjectDeserializer } from 'src/dao/objectDeserializer';
import { makePivotService, PivotService } from 'src/services/pivotService';
import { makeTenantConfigClient, TenantConfigClient } from 'src/dao/tenantConfigClient';
import { makeFilterClient, FilterClient } from 'src/dao/filterClient';

import configServiceFactory, { ConfigurationService } from 'src/services/configuration';
import { makePrintService, PrintService } from 'src/services/Print/PrintService';
import { makeReportClient, ReportClient } from 'src/dao/reportClient';
import LoggingService from './services/loggingService';
import { PivotServiceType } from './worker/pivotWorker.types';
import SettingsService from 'src/services/Settings';
import numeral from 'src/utils/numbro';
import { AxiosInstance } from 'axios';
import Axios from 'src/services/axios';
import PivotManager from 'src/pivot/Pivot.client';

import ConfigService from 'src/services/Config.service';
import AdminService from 'src/services/Admin.service';
import CommentsService from 'src/components/Mfp/Comments/Comments.service';
import React from 'react';
import { Lazy } from 'fp-ts/es6/function';

export interface ServiceContainer {
  pivotService: PivotService;
  scopeClient: ScopeClient;
  tenantConfigClient: TenantConfigClient;
  filterClient: FilterClient;
  configService: ConfigurationService;
  printService: PrintService;
  reportClient: ReportClient;
  loggingService: LoggingService;
  settings: SettingsService;
  localization: typeof numeral;
  axios: AxiosInstance; // note this is the axios instance for mfp! during dev its different,
  macroData: Record<string, PivotManager>;
  admin: AdminService;
  comments: CommentsService;
  config: ConfigService;
  lastBearerToken: () => string;
}

export interface AuthContextPayload {
  serviceEnv: ServiceContainer;
  lastBearerToken: () => string | null;
  logout: Lazy<void>;
}

// <Main> is not mounted until we have a full AuthContext, but <Index> can go through a few renders
// before we have that.  This context won't be reliable above main, but otherwise should be safe
// therefore we assert here to avoid having to ! the context in every useage
// Note: this doesn't work with class component static contextType for reasons that are unclear to me

export function makeServiceContainer(): ServiceContainer {
  const pivotClient = makePivotClient(makeObjectDeserializer());

  return {
    pivotService: makePivotService(PivotServiceType.app, pivotClient),
    scopeClient: makeScopeClient(),
    filterClient: makeFilterClient(),
    tenantConfigClient: makeTenantConfigClient(),
    configService: configServiceFactory(),
    printService: makePrintService(),
    reportClient: makeReportClient(),
    loggingService: new LoggingService(),
    settings: new SettingsService(Axios),
    localization: numeral,
    axios: Axios,
    admin: new AdminService(Axios),
    comments: new CommentsService(Axios),
    macroData: {},
    config: new ConfigService(Axios),
    lastBearerToken: () => {
      return Axios.defaults.headers.common.Authorization as string;
    },
  };
}

export default makeServiceContainer();
