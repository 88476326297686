import { AppState } from 'src/store';
import { createSelector, OutputSelector } from 'reselect';
import {
  CardViewDataInputs,
  CardViewGroup,
  CardViewOptionalInputs,
} from 'src/components/StandardCardView/UIData.types';

import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import processDataForCardView from 'src/components/StandardCardView/CardViewDataProcessor';
import { CartItem, CartItemType } from '../../../AssortmentCart/AssortmentCart.types';
import { STYLE_ID } from 'src/utils/Domain/Constants';

const getSearchData = (state: AppState) => {
  return state.pages.assortmentBuild.addStyles.searchData;
};

const getLevel = (state: AppState) => {
  return state.pages.assortmentBuild.addStyles.level;
};

const getCartItems = (state: AppState) => {
  return state.cart.cartItems;
};

const getCartItemType = (state: AppState) => {
  return state.pages.assortmentBuild.addStyles.cartItemType;
};

const getViewConfig = (state: AppState) => {
  return state.pages.assortmentBuild.addStyles.viewDefn;
};

const getSubheader = (state: AppState) => {
  return state.subheader;
};

export const getSelectedItems = (state: AppState) => {
  return state.pages.assortmentBuild.addStyles.selectedItemsForCart;
};

export const getAssortmentCartLink = (state: AppState) => {
  return state.perspective.viewLinks.find((view) => view.name === 'Assortment Cart')?.link;
};

export function getGroupedData(
  level: string,
  cartItemType: CartItemType,
  cartItems: CartItem[],
  styles: BasicPivotItem[],
  config: TenantConfigViewData,
  subheader: SubheaderSlice,
  selectedItems: BasicPivotItem[]
) {
  if (styles && config && subheader) {
    const filteredStyles = styles || [];
    if (level === 'styles') {
      filteredStyles.filter((style) => {
        return cartItems.findIndex((cartItem) => cartItem.id === style[STYLE_ID] && cartItem.type === cartItemType) < 0;
      });
    } else if (level === 'stylecolors') {
      // FIXME: update logic to work with stylecolors
      // filteredStyles.filter((style) => {
      //   const foundCartItem = cartItems.find(
      //     (cartItem) => cartItem.id === style[STYLE_ID] && cartItem.type === cartItemType
      //   );
      //   if (!isNil(foundCartItem)) {
      //     return (
      //       foundCartItem.findIndex((childItem) => {
      //         return childItem.id === style.id && childItem.type === cartItemType;
      //       }) < 0
      //     );
      //   }
      //   return true;
      // });
    }

    const dataInputs: CardViewDataInputs = {
      styles,
      subheader,
      defns: {
        view: config,
        rollup: {} as TenantConfigViewData,
      },
    };
    const optionalInputs: CardViewOptionalInputs = {
      selectedItems,
    };
    return processDataForCardView(dataInputs, optionalInputs);
  }
  return [];
}

export interface ProcessedDataSelector
  extends OutputSelector<
    AppState,
    CardViewGroup[],
    (
      level: string,
      cartItemType: CartItemType,
      cartItems: CartItem[],
      styles: BasicPivotItem[],
      config: TenantConfigViewData,
      subheader: SubheaderSlice,
      selectedItems: BasicPivotItem[]
    ) => CardViewGroup[]
  > {}
export const getProcessedData: ProcessedDataSelector = createSelector(
  getLevel,
  getCartItemType,
  getCartItems,
  getSearchData,
  getViewConfig,
  getSubheader,
  getSelectedItems,
  getGroupedData
);
