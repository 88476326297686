import React from 'react';
import { MenuItemDef } from '@ag-grid-community/core';
import { Button, Tooltip } from '@material-ui/core';
import { default as styles } from 'src/components/MassEdit/MassEdit.styles';
import { classes } from 'typestyle';
import { noop } from 'lodash';

export interface ConfigurablePostActionProps {
  action: MenuItemDef;
  dataLoading: boolean;
}

export const ConfigurablePostAction = ({ action, dataLoading }: ConfigurablePostActionProps) => {
  const { action: onClick = noop, icon, name, tooltip = '' } = action;
  return (
    <Tooltip title={tooltip} placement="bottom-end" enterDelay={0}>
      <div className={styles.actionButtonGroup} style={{ cursor: !dataLoading ? 'pointer' : 'not-allowed' }}>
        <Button color={'secondary'} className={styles.actionButtonButton} disabled={dataLoading} onClick={onClick}>
          <i className={classes(`${icon}`, styles.actionButtonIcon)} />
          <span>{name}</span>
        </Button>
      </div>
    </Tooltip>
  );
};
