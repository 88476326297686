import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { cacheCheckFetchPivotData } from '../StyleColorReview.slice';
import service from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface GroupingInfo {
  dataIndex: string;
  staticColumns: string[];
}

interface TenantResponse {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  calcViewDefn?: TenantConfigViewData;
}

export interface TopTYvsLYSlice {
  viewDefn: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
  calcViewDefn: TenantConfigViewData;
  tenantConfigLoading: boolean;
  requiredViewDefns: string[];
  groupingInfo?: GroupingInfo | null;
  cacheHash: string | null;
  viewDataState: ViewDataState;
}

const initialState: TopTYvsLYSlice = {
  viewDefn: {} as TenantConfigViewData,
  unmodifiedViewDefn: {} as TenantConfigViewData,
  calcViewDefn: {} as TenantConfigViewData,
  requiredViewDefns: [],
  tenantConfigLoading: false,
  groupingInfo: null,
  viewDataState: ViewDataState.idle,
  cacheHash: null,
};

const topTyLyReducer = createSlice({
  name: 'TopTYvsLY',
  initialState: initialState,
  reducers: {
    requestTenantConfig(state) {
      state.tenantConfigLoading = true;
    },
    receiveTenantConfig(state, action: PayloadAction<TenantResponse>) {
      state.viewDefn = action.payload.viewDefn;
      state.tenantConfigLoading = false;
      state.calcViewDefn = action.payload.calcViewDefn || state.calcViewDefn;
      state.unmodifiedViewDefn = action.payload.unmodifiedViewDefn || state.unmodifiedViewDefn;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveCacheHash(state, action: PayloadAction<string>) {
      state.cacheHash = action.payload;
    },
    receiveCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.cacheHash) {
        state.viewDataState = ViewDataState.liveDataReady;
      }
    },
    setGroupingInfo(state, action: PayloadAction<GroupingInfo>) {
      state.groupingInfo = action.payload;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  requestData,
  receiveCacheHash,
  receiveCachedData,
  receiveLiveData,
  setGroupingInfo,
  receiveError,
  cleanUp,
} = topTyLyReducer.actions;

export function fetchTopTyLyData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    service.pivotService.listDataCacheCheck(modelDefn, options),
    requestData,
    receiveCacheHash,
    receiveCachedData,
    receiveLiveData
  );
}

export default topTyLyReducer.reducer;
