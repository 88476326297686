import * as React from 'react';
import { MenuItemDef } from '@ag-grid-community/core';
import worklistStyle from './WorklistContextMenuReact.styles';
import { noop } from 'lodash';

// this re-uses some of the ag-grid css classes and construction, so that the two components follow the same styling and functionality
// this reduces duplication, but binds this component to the ag-grid implementation

const contextMenuItem = (item: (MenuItemDef & { hidden?: boolean }) | string, idx: number) => {
  if (typeof item === 'string') {
    return (
      <div className="ag-menu-separator" key={idx}>
        <span className="ag-menu-separator-cell"></span>
        <span className="ag-menu-separator-cell"></span>
        <span className="ag-menu-separator-cell"></span>
        <span className="ag-menu-separator-cell"></span>
      </div>
    );
  }
  if (item.hidden) return;
  const iconClass = item.icon instanceof HTMLElement ? item.icon.className : '';
  const action = item.action ? item.action : noop;
  return (
    <div
      className={`ag-menu-option ${item.disabled ? 'ag-menu-option-disabled' : 'ag-menu-option-active'}`}
      title={item.tooltip}
      onClick={action}
      key={idx}
    >
      <span id="eIcon" className="ag-menu-option-icon">
        <i className={iconClass} />
      </span>
      <span id="eName" className="ag-menu-option-text">
        {item.name}
      </span>
      <span id="eShortcut" className="ag-menu-option-shortcut"></span>
      <span id="ePopupPointer" className="ag-menu-option-popup-pointer">
        &nbsp;
      </span>
    </div>
  );
};

export const WorklistContextMenu = (props: { worklistContextMenuItems: (MenuItemDef | string)[] }) => {
  return (
    <div className={`ag-theme-material ${worklistStyle}`} id="worklist-context-menu-react">
      <div className="ag-menu">
        <div className="ag-menu-list">{props.worklistContextMenuItems.map(contextMenuItem)}</div>
      </div>
    </div>
  );
};
