import { isNil } from 'lodash';
import {
  AssortmentFavoriteType,
  FavoriteCard,
  FavoriteCompanion,
  FavoriteGrid,
} from 'src/components/Subheader/Favorites/Favorites.types';
import { FilterModelType } from 'src/components/Sidenav/SideNav.slice';
import { ColumnState } from '@ag-grid-community/core';
import { SubheaderControlValues } from 'src/components/Subheader/Favorites/Favorites';
import { BasicViewItem } from 'src/services/configuration/codecs/viewdefns/general';

export const getSubheaderDropdownSelection = (
  selectedIndex: number | null,
  options: BasicViewItem[]
): string | null => {
  return !isNil(selectedIndex) ? options[selectedIndex]?.dataIndex : null;
};

export const getCardFavorite = (type: AssortmentFavoriteType, config: unknown): FavoriteCard => {
  return type === AssortmentFavoriteType.card
    ? { columns: (config as { view: BasicViewItem[] }).view.map((col) => col.dataIndex) }
    : { columns: [] };
};

export const getGridFavorite = (
  type: AssortmentFavoriteType,
  config: unknown,
  colState: ColumnState[],
  filterModel: FilterModelType
): FavoriteGrid => {
  return type === AssortmentFavoriteType.grid || type === AssortmentFavoriteType.gridCompanion
    ? {
        columns: (config as {
          view: (BasicViewItem & { pinned: boolean | string | undefined; visible: boolean })[];
        }).view.map(({ dataIndex, pinned, visible }) => ({
          dataIndex,
          pinned,
          visible,
        })),
        gridColumnState: colState,
        gridFilterModel: filterModel,
      }
    : {
        columns: [],
        gridColumnState: [],
        gridFilterModel: null,
      };
};

export const getCompanionFavorite = (
  type: AssortmentFavoriteType,
  companionData: Partial<FavoriteCompanion> | undefined,
  subheaderValues: SubheaderControlValues
): FavoriteCompanion => {
  return type === AssortmentFavoriteType.gridCompanion
    ? {
        collapsed: companionData?.collapsed || false,
        sortByDataIndexSelection: companionData?.sortByDataIndexSelection || null,
        sortByDirection: companionData?.sortByDirection || 'desc',
        // TODO: need to get the actual level by value from companion
        levelByDataIndexSelection: null,
        flowStatusSelections: subheaderValues.altFlowStatus,
      }
    : {
        collapsed: false,
        sortByDataIndexSelection: null,
        sortByDirection: 'desc',
        levelByDataIndexSelection: null,
        flowStatusSelections: [],
      };
};
