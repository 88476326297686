import {
  PRODUCT,
  TIME,
  LOCATION,
  PRODLIFE,
  VARIANCE_VERSION,
  SINGLE_VERSION,
  COLUMNS,
  PERCENT_TO_TOTAL_VERSION,
  PERCENT_TO_GRAND_TOTAL_VERSION,
} from 'src/utils/Domain/Constants';
import { ROWS } from '../utils/Domain/Constants';
import { z } from 'zod';
export const DIMENSION_TYPE = 'Dimension';
export const METRIC_TYPE = 'Metric';
export const VERSION_TYPE = 'Version';

export const DELTA_VARTYPE = 'delta';
export const PERCENTAGE_VARTYPE = 'percentage';

export const zDimensionPivotMap = z.object({
  type: z.literal(DIMENSION_TYPE),
  on: z.union([z.literal(PRODUCT), z.literal(TIME), z.literal(LOCATION), z.literal(PRODLIFE)]),
  members: z.array(z.string()),
});
export interface DimensionPivotMap extends z.infer<typeof zDimensionPivotMap> {}

export const zMetricPivotMap = z.object({
  type: z.literal(METRIC_TYPE),
  members: z.array(z.string()),
});
export interface MetricPivotMap extends z.infer<typeof zMetricPivotMap> {}

const zTypeSingleVersion = z.object({
  [SINGLE_VERSION]: z.null(),
  [PERCENT_TO_TOTAL_VERSION]: z.null(),
  [PERCENT_TO_GRAND_TOTAL_VERSION]: z.null(),
});
export const zSingleVersionType = z.object({
  type: zTypeSingleVersion.keyof(),
  hidden: z.boolean(),
  version: z.string(),
});
const zVarType = z.object({
  [DELTA_VARTYPE]: z.null(),
  [PERCENTAGE_VARTYPE]: z.null(),
});
export const zVarianceType = z.object({
  type: z.literal(VARIANCE_VERSION),
  hidden: z.boolean(),
  version: z.string(),
  against: z.string(),
  varType: zVarType.keyof(),
});

const zVersionPivotMapMembers = z.array(z.union([zSingleVersionType, zVarianceType]));
export const zVersionPivotMap = z.object({
  type: z.literal(VERSION_TYPE),
  members: zVersionPivotMapMembers,
});

export interface VersionMembers extends z.infer<typeof zVersionPivotMapMembers> {}
export interface VersionPivotMap extends z.infer<typeof zVersionPivotMap> {}

export const zPivotMap = z.union([zVersionPivotMap, zMetricPivotMap, zDimensionPivotMap]);
export type PivotMap = z.infer<typeof zPivotMap>;

export const zPivotPayload = z.object({
  rows: z.array(zPivotMap),
  columns: z.array(zPivotMap),
  pivotName: z.string().optional(),
  relocalizeCurrency: z.boolean().optional(),
});
export interface PivotPayload extends z.infer<typeof zPivotPayload> {}

export interface PivotResponsePayload {
  pivotName: string;
  [ROWS]: any;
  [COLUMNS]: any;
  rowAxes: string[]; // dimesion ids
  colAxes: string[]; // dimension ids
}

// export interface MetricPivotMap {
//   type: 'Metric',
//   members: string[]
// }

// export interface VersionPivotMap {
//   type: 'Version',
//   members: (VersionGroup | VarianceVersionGroup)[]
// }

// export interface VarianceVersionGroup {
//   type: 'VarianceVersion',
//   version: string,
//   against: string,
//   varType: 'delta' | 'percentage'
// }

// export interface VersionGroup {
//   type: 'SingleVersion',
//   version: string
// }

export interface LockState {
  cellLocked: boolean;
  globalLocked: boolean;
}

export interface MockCell {
  row: Position['r'];
  col: Position['c'];
  value?: string;
  isLocked: boolean;
  isDisabled: boolean;
  isAlwaysDisabled: boolean;
  isConstrained: boolean;
  lockState: string;
  isLockable: boolean;
}

export interface CellPosition {
  position: Position;
  cell: MockCell;
}

export interface Position {
  r: number;
  c: number;
}
