import { ofType } from 'redux-observable';
import { filter, map, mergeMap, of } from 'rxjs';
import { AppEpic } from 'src/store';
import { fetchUndoItems, undoUpdate } from './Planning.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';

export const undoItemUpdate: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(undoUpdate.type, setRightContainerPayload.type),
    map(() => state$.value.rightContainer),
    filter((rc) => rc.open && !!rc.payload && rc.payload.type === RightContainerPayloadType.Undo),
    mergeMap(() => {
      return of(fetchUndoItems());
    })
  );
};
