import { isNil } from 'lodash';
import { z } from 'zod';
import {
  ConfigurableGridApiProps,
  BaseDefnsComponentProps,
  BaseMultiDefnsComponentProps,
  TargetListComponentProps,
  zBulkImportComponentProps,
  zMfpSummaryGridComponentProps,
} from './confdefnComponentProps';
import { Perspective } from 'literals';
import { AppType, BoundView } from 'bindings.types';

export enum FabType {
  none = 'none',
  buttonModal = 'buttonModal',
  worklist = 'worklist',
  cart = 'cart',
  planning = 'planning',
  postTextModal = 'postTextModal',
  pivot = 'pivot',
}

export const HasSubheaderDownloadLink = z.object({ subheader: z.object({ downloadLink: z.string() }).optional() });
export const HasTopMembers = z.object({ topMembers: z.string().optional() });
export const HasWorklistFunctionality = z.object({ allowWorklistFunctionality: z.boolean().optional() });
// TODO: should really be called HasStylePane with a prop of showStylePane to be more clear
export const HasPopover = z.object({ showPopover: z.boolean().optional() });
export const HasFab = z.object({ fabType: z.string().optional() });
export interface HasFab extends z.infer<typeof HasFab> { }
export const HasRangeSelector = z.object({ showRangeSelector: z.boolean().optional() });
export const HasTitle = (title: string) => {
  return z.object({
    title: z
      .string()
      .optional()
      .default(title),
    hideTitle: z.boolean().optional(),
  });
};

export interface HasTitle extends z.infer<ReturnType<typeof HasTitle>> { }
export const HasCountLimit = z.object({ showCountLimit: z.boolean().optional() });
export const HasHideableCompanion = z.object({ hideCompanion: z.boolean().optional() });
export const HasFlowStatus = z.object({
  showFlowStatus: z
    .boolean()
    .optional()
    .default(true),
});
export interface HasFlowStatus extends z.infer<typeof HasFlowStatus> { }
export const HasLookBackPeriod = z.object({
  showLookBackPeriod: z
    .boolean()
    .optional()
    .default(true),
});
export const HasUndoButton = z.object({
  showUndoBtn: z
    .boolean()
    .optional()
    .default(false),
});
export const HasSubheaderErrorText = z.object({ subheaderErrorText: z.string().optional() });
export const HasConfigure = z.object({ showConfigure: z.boolean().optional() });
export const HasFullHeight = z.object({ fullHeight: z.boolean().optional() });
export const HasSummaries = z.object({ hideSummaries: z.boolean().optional() });

export const hasBaseDefns = (
  props: Record<string, any>
): props is { componentProps: z.infer<ReturnType<typeof BaseDefnsComponentProps>> } => {
  return 'defns' in props.componentProps && 'model' in props.componentProps.defns;
};

export const hasTargetListType = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof TargetListComponentProps> } => {
  return 'type' in props.componentProps;
};
export const hasImportId = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof zBulkImportComponentProps> } => {
  return 'importId' in props.componentProps;
};

export const hasBaseMultiDefns = (
  props: Record<string, any>
): props is { componentProps: z.infer<ReturnType<typeof BaseMultiDefnsComponentProps>> } => {
  return 'defns' in props.componentProps && 'models' in props.componentProps.defns;
};

export const hasApiDefns = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof ConfigurableGridApiProps> } => {
  return (
    'dataApi' in props.componentProps && 'configApi' in props.componentProps && 'planningApi' in props.componentProps
  );
};
export const hasViewParams = (
  props: Record<string, any>
): props is { componentProps: z.infer<typeof zMfpSummaryGridComponentProps> } => {
  return 'viewParams' in props.componentProps;
};

export const viewRequiresMfpScope = (view: BoundView): boolean => {
  const viewType = view.boundViewComponentType;
  // NOTE: New mfp components must be added to this array if they need to detect their disabled state based on
  // the `mfpModule` context object property `onlyIndirectMfpScoping`
  return ['MfpSummaryGrid', 'MfpFavorite', 'MfpSplitView', 'MfpReviewPlans', 'MfpReviewPrivatePlans'].includes(
    viewType
  );
};

export const IdentifierProps = z.object({
  keys: z.object({
    idProp: z.string(),
    descProp: z.optional(z.string()),
    styleId: z.optional(z.string()),
    leafIdProp: z.optional(z.string()),
  }),
});

export const OptionalIdentifierProps = IdentifierProps.partial();

export const GroupingInfo = z.object({
  groupingInfo: z.object({
    dataIndex: z.string(),
    staticColumns: z.array(z.string()),
  }),
});

const zConfigApiParams = z.object({
  appName: z.union([z.literal(AppType.Assortment), z.literal(AppType.TDAnalysis)]),
  defnId: z.string().optional(),
  aggBy: z.string().optional(),
});

export const zConfigApi = z.object({
  url: z.string(),
  params: zConfigApiParams,
});

export interface ConfigApi extends z.infer<typeof zConfigApi> { }

export const zConfigApiV2 = z.object({
  type: z.literal('viewdefnv2'), // allow migration
  defnId: z.string(),
  allowCache: z.boolean().optional(),
});

export interface ConfigApiV2 extends z.infer<typeof zConfigApiV2> { }

export const zClientDataApi = z.object({
  url: z.string(),
  params: z.optional(z.optional(z.record(z.string()))),
  headers: z.optional(z.optional(z.record(z.string()))),
  isListData: z.optional(z.nullable(z.literal(false))),
  clientHandler: z.optional(z.string()),
  clientHandlerParams: z.optional(z.string()),
  email: z.optional(z.string()),
});

export const zListDataApi = z.object({
  defnId: z.string(),
  isListData: z.literal(true),
  params: z
    .object({
      /** The default bottom level to potentially be appended to existing aggBys from the view */
      aggBy: z.string(),
      /** If present, the default top level to potentially be prepended to existing aggBys from the view*/
      topAggBy: z.string().optional(),
      nestData: z.boolean().optional(),
      ignoreAncestors: z.boolean().optional(),
      includeLevelBy: z.boolean().optional(),
    })
    // in this case, allow any extra params to pass through,
    // so that we support arbitrary params being passed down and through to the pivot
    .catchall(z.any()),
});

export const zRouteToLocationDataApi = z.object({
  url: z.string(),
  params: z.object({
    facet: z.string(),
    product: z.string(),
    location: z.string(),
    time: z.string(),
    prodlife: z.string(),
  }),
});

export const zDataApi = zClientDataApi.or(zListDataApi);

export interface ClientDataApi extends z.infer<typeof zClientDataApi> { }
export interface ListDataApi extends z.infer<typeof zListDataApi> { }
export type DataApi = z.infer<typeof zDataApi>;

export const isListDataApi = (dataApi: DataApi | undefined): dataApi is ListDataApi => {
  return (
    !isNil(dataApi) &&
    'isListData' in dataApi &&
    dataApi.isListData === true &&
    (dataApi as ListDataApi).defnId !== undefined
  );
};

// component property is merged in at the component level for a more narrow type (see confdefnComponents.ts)
export const BaseSectionView = z.object({
  id: z.string(),
  name: z.string(),
  icon: z.string(),
  pathSlot: z.string(),
  inPerspectives: z.array(Perspective),
  disabled: z.boolean().optional(),
  hidden: z.boolean().optional(),
  overflow: z.string().optional(),
  tooltip: z.string().optional(),
});
