import { findIndex, isNil } from 'lodash';

/**
 * @param array - Array to be left null filled
 * Replaces all nulls with item from left.
 * @example - _LNullFill([1,null,3]) => [1,1,3]
 * @example - _LNullFill([null,2,null]) => [null,2,2]
 */
function _lNullFill<T>(array: T[]) {
  return array.reduce((acc, item, index) => {
    if (index === 0) {
      return [item];
    } else if (item == null) {
      acc.push(acc[index - 1]);
      return acc;
    } else {
      acc.push(item);
      return acc;
    }
  }, [] as T[]);
}
/**
 * @param array
 * Replaces all nulls with neighbor in array by priority Right -> Left
 * @example - fillNulls([null,2,null,4]) => [2,2,4,4]
 * @example - fillNulls([2,null,null]) => [2,2,2]
 * @example - fillNulls([null, null]) => []
 */
export function fillNulls<T>(array: T[]) {
  if (array == null || array.length === 0 || findIndex(array, (i) => !isNil(i)) < 0) {
    return [];
  }
  const revArray = array.slice().reverse();
  const res = _lNullFill(_lNullFill(revArray).reverse());
  return res;
}
