import { AxiosError, AxiosResponse } from 'axios';
import Axios from 'src/services/axios';
import { BulkImportMutation, BulkImportNotice } from './BulkImport.slice';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
export const BULKIMPORT_API = 'api/bulk/imports';

// TODO: add logger
/* eslint-disable-next-line */
export function WorklistClient() {
  const client = Axios;
  return {
    getMutations() {
      return client
        .get<BulkImportMutation[]>(BULKIMPORT_API)
        .then((resp) => {
          return resp.data;
        })
        .catch(() => {
          throw new Error('An error occured fetching the bulk import operations');
        });
    },
    uploadMutationTemplate(importId: string, formData: FormData, appName: string): Promise<BulkImportNotice> {
      return client
        .post<BulkImportNotice, AxiosResponse<BulkImportNotice>, FormData>(
          `${BULKIMPORT_API}/${importId}/stage?appName=${appName}`,
          formData
        )
        .then((resp) => {
          return resp.data;
        })
        .catch((e) => {
          const reponseStatus = e.response?.status;
          const responseData = e.response?.data;
          if (!reponseStatus || !responseData) throw new Error('An error occured fetching your import feedback');
          if (responseData) {
            toast.error('An error occured uploading the template');
            ServiceContainer.loggingService.error(
              `An error occured uploading the template: ${JSON.stringify(responseData)}`
            );
            throw new Error('An error occured fetching your import feedback');
          }
          throw new Error('An error occured fetching your import feedback');
        });
    },
    getNotices(runId: string) {
      return client
        .get<BulkImportNotice[]>(`${BULKIMPORT_API}/${runId}`)
        .then((resp) => {
          return resp.data;
        })
        .catch(() => {
          throw new Error('An error occured fetching your notices');
        });
    },
    commitBulkImport(importId: string, txId: string) {
      return client
        .post(`${BULKIMPORT_API}/${importId}/commit`, { transactionId: txId })
        .then((resp) => {
          return resp.data;
        })
        .catch(() => {
          throw new Error('An error occured fetching your notices');
        });
    },
  };
}
