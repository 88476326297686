import React from 'react';
import { isNil, isEmpty } from 'lodash';

import styles from './RightContainer.styles';
import { DispatchProps, StateProps } from 'src/components/RightContainer/RightContainer.container';
import HistoryStylePane from 'src/components/HistoryStylePane/HistoryStylePane.container';
import FilterPanel from 'src/components/FilterPanel/FilterPanel.container';
import { processApiParams, getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { NavigationButton } from 'src/components/NavigationButton/NavigationButton';
import PlanQueue from 'src/components/PlanQueue/PlanQueue';
import UndoPanel from 'src/components/UndoPanel/UndoPanel';
import { EditStyleButton } from 'src/services/configuration/codecs/confdefn';
import Comments from 'src/components/Mfp/Comments/Comments';

interface ExitProps {
  onClick: () => void;
}

const ExitButton = (props: ExitProps) => {
  const { onClick } = props;
  return (
    <div className={styles.rightContainerCloseIcon} onClick={onClick} data-qa="rightContainerCloseIcon">
      <i className="far fa-times-circle" />
    </div>
  );
};

export enum RightContainerPayloadType {
  Collapsing = 'Collapsing',
  // only difference between history/asst is edit style button presence
  History = 'History',
  Assortment = 'Assortment',
  Filter = 'Filter',
  PlanQueue = 'PlanQueue',
  Undo = 'Undo',
  Comments = 'Comments',
}

export interface RightContainerProps extends StateProps {
  editStyleBtnConf: EditStyleButton | undefined;
}

interface Props extends StateProps, DispatchProps, RightContainerProps {}

interface State {
  description: string;
  title: string;
  hideEditStyleButton: boolean;
}

export default class RightContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      description: '',
      title: '',
      hideEditStyleButton: false,
    };
  }

  handleClickOnCloseIcon = () => {
    const { payload, clearAndCloseWindow } = this.props;
    clearAndCloseWindow(payload);
  };

  updateTitle = (title: string) => {
    if (this.state.title !== title) {
      this.setState({
        title,
      });
    }
  };

  updateDescription = (desc: string) => {
    const { description } = this.state;
    if (desc !== description) {
      this.setState({
        description: desc,
      });
    }
  };

  handleDataLoaded = (data: BasicPivotItem[]) => {
    this.setState({
      hideEditStyleButton: isEmpty(data),
    });
  };

  handleEditStyle = (): void => {
    const { payload, editStyleBtnConf } = this.props;

    if (isNil(editStyleBtnConf)) {
      return;
    }

    const dataApi = {
      url: editStyleBtnConf.path,
      params: {
        member: 'id',
      },
    };
    const id =
      !isNil(payload) &&
      (payload.type === RightContainerPayloadType.History || payload.type === RightContainerPayloadType.Assortment)
        ? payload.parentId
        : '';
    const processedDataApi = processApiParams(dataApi, { id });
    const url = getUrl(processedDataApi);
    window.location.hash = url;
  };

  // TODO: abstract some of this to smaller components for better readability

  render() {
    const { payload, editStyleBtnConf } = this.props;
    const { description, title, hideEditStyleButton } = this.state;
    let innerItem: JSX.Element | Element | null = null;

    let pane: JSX.Element | null = null;
    let actions: JSX.Element = (
      <div className={styles.styleHeaderContainer(false)} data-qa="StylePaneActionsContainer">
        <ExitButton onClick={this.handleClickOnCloseIcon} />
      </div>
    );

    if (payload) {
      if (payload.type === RightContainerPayloadType.History || payload.type === RightContainerPayloadType.Assortment) {
        innerItem = (
          <HistoryStylePane
            styleColorId={payload.id}
            dataId={payload.parentId}
            styleColorName={payload.styleColorName}
            isAssortmentBuild={!isNil(payload.isAssortmentBuild) ? payload.isAssortmentBuild : false}
            onDescriptionReady={this.updateDescription}
            onTitleReady={this.updateTitle}
            onDataReady={this.handleDataLoaded}
          />
        );
        actions = (
          <div className={styles.styleHeaderContainer(true)} data-qa="StylePaneActionsContainer">
            <React.Fragment>
              <div className={styles.headerText}>
                <div className="title" style={{ color: TEAL_PRIMARY }} data-qa="StylePaneItemTitle">
                  {title}
                </div>
                <div className="description">{description}</div>
              </div>
              <div className={styles.headerActions}>
                {editStyleBtnConf && !hideEditStyleButton && (
                  <NavigationButton
                    dataQa={'StylePaneEditStyleNavButton'}
                    buttonText={editStyleBtnConf.text || 'Edit Style'}
                    onClick={this.handleEditStyle}
                  />
                )}
                <ExitButton onClick={this.handleClickOnCloseIcon} />
              </div>
            </React.Fragment>
          </div>
        );
      } else if (payload.type === RightContainerPayloadType.Filter) {
        innerItem = <FilterPanel />;
      } else if (payload.type === RightContainerPayloadType.PlanQueue) {
        innerItem = <PlanQueue />;
      } else if (payload.type === RightContainerPayloadType.Undo) {
        innerItem = <UndoPanel />;
      } else if (payload.type === RightContainerPayloadType.Comments) {
        innerItem = <Comments />;
      } else {
        innerItem = <span>Unknown payload type</span>;
      }

      const isStyleInfo =
        payload.type === RightContainerPayloadType.History || payload.type === RightContainerPayloadType.Assortment;
      pane = (
        <React.Fragment>
          <div className={styles.stylePane} data-qa="StylePaneContainer">
            {actions}
            {<div className={styles.styleHeaderSpacer(isStyleInfo)} />}
            {innerItem}
          </div>
        </React.Fragment>
      );
    }

    return <div className={`${styles.rightContainer(payload?.type)}`}>{pane}</div>;
  }
}
