import * as React from 'react';
import { CSSProperties } from 'react';
import { isNil, isEmpty, forEach } from 'lodash';

import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getValidValues } from '../StyleEditSection/StyleEditSection.client';
import { getUrl } from '../StyleEdit.utils';
import InputSuggest, { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { DividedColumnDetailsSectionInputParams } from './DividedColumnDetails.types';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
export interface DropdownProps {
  value: string;
  multiSelect?: boolean;
  asCsv?: boolean;
  styleId: string;
  dataApi: ClientDataApi;
  dataQa: string;
  allowEmptyOption?: boolean;
  concatOptionValues?: boolean;
  renderIdOnly?: boolean;
  disabled?: boolean;
  inputParams?: DividedColumnDetailsSectionInputParams;
  handleDropdownChange: (selection: any) => void;
}

const explicitProps = [
  'value',
  'multiSelect',
  'asCsv',
  'styleId',
  'dataApi',
  'dataQa',
  'allowEmptyOption',
  'concatOptionValues',
  'renderIdOnly',
  'handleDropdownChange',
  'inputParams',
];

// TODO: took the easy way out...is there a 'TypeScripty' way to exclude current props and keep parent props only?
function getFilteredProps(props: DropdownProps) {
  const filteredProps = {};
  forEach(props, (value, key) => {
    if (explicitProps.indexOf(key) < 0) {
      filteredProps[key] = value;
    }
  });
  return filteredProps;
}

interface SuggestionString {
  label: string;
  value: string;
}

export interface DropdownState {
  selectedIndex: number | number[];
  selected?: SuggestionString[] | SuggestionString | string;
  options: SuggestionString[];
  loading: boolean;
}

export default class DividedColumnDetailsDropdown extends React.Component<DropdownProps, DropdownState> {
  constructor(props: DropdownProps) {
    super(props);

    this.state = {
      options: [],
      selectedIndex: -1,
      loading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: DropdownProps) {
    if (prevProps.styleId !== this.props.styleId) {
      this.getData();
    }

    if (!prevProps.value && this.props.value && this.state.options.length > 0) {
      this.setState({
        selected: this.getSelected(this.state.options),
      });
    }
  }

  getData() {
    const { dataApi, allowEmptyOption, concatOptionValues, renderIdOnly } = this.props;
    const url = getUrl(dataApi);

    this.setState({
      loading: true,
    });

    getValidValues(url, allowEmptyOption, concatOptionValues, false, renderIdOnly)
      .then((validValues) => {
        let selected;
        if (this.props.value) {
          selected = this.getSelected(validValues);
        }
        this.setState({
          options: validValues,
          selected,
        });
      })
      .catch((err: any) => {
        toast.error('An error occurred fetching the options for a dropdown');
        ServiceContainer.loggingService.error(`An error occurred: ${errorToLoggingPayload(err)}`, err.stack);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getSelected = (options: SuggestionString[]): SuggestionString | string | SuggestionString[] | undefined => {
    const { value } = this.props;
    // TODO: We should be accepting value as array in cases of asCsv = false, but for now
    // value as csv false just means output is array, input is still csv. I'm sorry - </3 Mark
    if (this.props.multiSelect) {
      const matched = value.match(/{(.*)}/);
      let values: string[];
      if (matched) {
        const match = matched[1];
        // need to explicitly set empty selection to empty array instead of trying to split
        values = isEmpty(match) ? [] : matched[1].split(',');
      } else {
        values = value.split(',');
      }

      const selectedOptions = options.filter((opt) => values.indexOf(opt.value) >= 0);

      if (selectedOptions.length > 0) {
        return selectedOptions;
      }

      // in the case of a value of 'Undefined' we want to render the stripped value,
      // which is matched[1], instead of the raw value '{Undefined}' received from the server
      return matched && matched[0] === value ? matched[1] : value;
    }
    return options.find((option) => option.value === value) || value;
  };

  handleMultiSelection = (selection: SuggestionString[]) => {
    this.setState(
      {
        selected: selection,
      },
      () => {
        if (this.props.asCsv && this.props.multiSelect) {
          const selections = (selection as unknown) as Suggestion[];
          const final = {
            label: selections.map((s) => s.label).join(','),
            value: `{${selections.map((s) => s.value).join(',')}}`,
          };
          this.props.handleDropdownChange(final);
          return;
        }
        this.props.handleDropdownChange(selection);
      }
    );
  };

  handleSelection = (selection: SuggestionString) => {
    this.setState(
      {
        selected: selection,
      },
      () => {
        this.props.handleDropdownChange(selection);
      }
    );
  };

  render() {
    const { multiSelect = false, dataQa, disabled, inputParams } = this.props;
    const { loading, options, selected } = this.state;
    const dropdownOptions = isNil(options) || isEmpty(options) ? [] : options;
    const selectedValue = !isEmpty(dropdownOptions) ? selected : undefined;
    // since parent may be a tooltip, need to forward props (minus current props) for ref purposes
    const filteredProps = getFilteredProps(this.props);

    return (
      <div {...filteredProps} style={inputParams && inputParams.height ? { height: inputParams.height } : undefined}>
        <InputSuggest
          validValues={dropdownOptions}
          multiSelect={multiSelect}
          selected={isEmpty(selectedValue) ? undefined : selectedValue}
          disabled={!isNil(disabled) ? disabled : false}
          // @ts-ignore
          onSelect={this.handleSelection}
          // @ts-ignore
          onMultiSelect={this.handleMultiSelection}
          styles={{
            valueContainer: (base: CSSProperties) => {
              return {
                ...base,
                height: inputParams && inputParams.height ? inputParams.height - 8 : 48,
                overflowY: multiSelect ? 'auto' : 'hidden',
                alignItems: multiSelect ? 'flex-start' : 'center',
              };
            },
            clearIndicator: (base: CSSProperties) => {
              return {
                ...base,
                display: 'none',
              };
            },
            multiValue: (base: CSSProperties) => {
              return {
                ...base,
                maxWidth: '113px',
                margin: '1px',
              };
            },
          }}
          dataQa={dataQa}
          isLoading={loading}
        />
      </div>
    );
  }
}
