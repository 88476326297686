import { connect } from 'react-redux';
import * as React from 'react';
import { AppState } from 'src/store';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { RangePickerEditor } from './RangePickerEditor';
import { ICellEditorParams, GridApi } from '@ag-grid-community/core';
import { NumberedWeek } from 'src/common-ui/components/WeekRange/WeekRangePicker.interface';
import { merge, isNil } from 'lodash';
import { DaysRangeListResponse } from 'src/types/Scope';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { MultiRangeEditorDataIndices } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

interface ExtraProps {
  nodeKey: string;
  mergedRangeList: DaysRangeListResponse;
  onCalendarCancel: () => void;
  onNewWeeksSelectedMulti: (range: NumberedWeek[]) => void;
  styleOverrides?: { [key: string]: CSSProperties };
  makeModal?: boolean;
  multiRangeDataIndices: MultiRangeEditorDataIndices;
  checkboxIndices?: MultiRangeEditorDataIndices;
  disableOverlap: boolean;
  startWithPlanCurrent?: boolean;
  lifecycleData?: BasicPivotItem;
  col?: string;
  updateLifecycleData?: (styleColorId: string, newValue: { [s: string]: any }) => void;
}

interface StateProps extends AssortmentScopeSelectorSlice, ICellEditorParams {}
export interface PickerProps extends StateProps, ExtraProps {}

interface MappedRangeWeek {
  [key: string]: string;
}

function mapStateToProps(state: AppState, ownProps: ICellEditorParams): StateProps {
  return {
    ...state.scope,
    ...ownProps,
  };
}

function mapDispatchToProps(_ig: unknown, ownProps: Record<string, any>) {
  return {
    ...ownProps,
  };
}

function mapRanges(dataIndices: string[], range: NumberedWeek[]): MappedRangeWeek {
  const mappedValues = {};

  dataIndices.forEach((dataIndex, index) => {
    mappedValues[dataIndex] = range[index].weekNo;
  });

  return mappedValues;
}

function handleUpdates(colId: string, gridApi: GridApi, dataIndices: string[], range: NumberedWeek[]) {
  if (range.some((weekObj) => isNil(weekObj.weekNo))) {
    return;
  }

  const mappedRanges = mapRanges(dataIndices, range);
  dataIndices.forEach((dataIndex) => {
    const node = gridApi.getRowNode(dataIndex);
    const key = node?.data.refId ? node?.data.refId : node?.data.dataKey;
    node?.setDataValue(colId, mappedRanges[key]);
  });
}

export default class AGGridConnectWrapper extends React.Component<PickerProps> {
  constructor(props: PickerProps) {
    super(props);
    this.onNewWeeksSelectedMulti = this.onNewWeeksSelectedMulti.bind(this);
  }

  isPopup(): boolean {
    return true;
  }

  getValue() {
    return this.props.value;
  }

  onNewWeeksSelectedMulti(range: NumberedWeek[]) {
    const { api, column, multiRangeDataIndices } = this.props;

    if (isNil(api)) {
      return;
    } else if (isNil(range) || range.some((value) => isNil(value))) {
      api.stopEditing(true);
      return;
    }

    const colId = column.getColId();
    handleUpdates(colId, api, Object.keys(multiRangeDataIndices), range);
    api.refreshCells();
    api.stopEditing(true);
  }

  onCancel = () => {
    if (this.props.api) {
      this.props.api.stopEditing(true);
    }
  };

  render() {
    const onNewWeeksSelectedMulti = this.onNewWeeksSelectedMulti;
    const onCalendarCancel = this.onCancel;
    const props = this.props;

    function mergeProps(state: PickerProps, dispatch: Record<string, any>) {
      const { daysRangeListExtended: daysRangeList, daysPastRangeList } = state;
      const mergedRangeList = {
        start_date: merge(daysRangeList.start_date, daysPastRangeList.start_date),
        end_date: merge(daysRangeList.end_date, daysPastRangeList.end_date),
      };
      return {
        ...state,
        ...dispatch,
        ...props,
        mergedRangeList,
        onCalendarCancel: onCalendarCancel,
        onNewWeeksSelectedMulti,
      };
    }
    // @ts-ignore
    const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps)(RangePickerEditor);
    //@ts-ignore
    return <ConnectedComponent />;
  }
}
