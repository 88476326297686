import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { receivedWorkingSets, WorkingSetContext } from './workingSets.slice';
import { GRID_DEFAULT, scopePending, scopeReady } from 'src/state/scope/Scope.types';
import type { ScopeStateUnion, ServerScopeResponse } from 'src/state/scope/Scope.types';
import { SCOPECREATE_SMARTPLAN, SCOPECREATE_WITHOUT_WP } from 'src/services/Scope.client';
import type { ScopeCreateRequest } from 'src/services/Scope.client';
import { anchorIdsFromMemberTrees } from 'src/state/scope/Scope.slice';


// narrow this for filtering
export interface NonWorkingSetContext extends WorkingSetContext {
  userCreatedWithoutWP: true
};

// have to parse the datetime on ajax load
export interface NonWorkingSetContextPayload extends Pick<NonWorkingSetContext, 'id' | 'status' | 'anchor' | 'module'> { creationTime: string };

// export type NonWorkingScopeStateUnion = Omit<ScopeReady,'inSeason'|'initialized'|'gridAsyncState'>

export interface NonWorkingSetsState {
  contexts: NonWorkingSetContext[],
  activeNonWorkingScope: ScopeStateUnion | undefined,
  activeNonWorkingPlanId: number | undefined
}
const initNonWorkingsetState: NonWorkingSetsState = {
  contexts: [],
  activeNonWorkingScope: undefined,
  activeNonWorkingPlanId: undefined
};

export interface CreateNonWorkingScopeRequest extends ScopeCreateRequest {
  initParams: { type: 'without-wp' },
  planId: number
}

export const nonWorkingSetsSlice = createSlice({
  name: 'nonWorkingSets',
  initialState: initNonWorkingsetState,
  reducers: {
    createNewNonWorkingScope: (state, action: PayloadAction<CreateNonWorkingScopeRequest>) => {
      // we don't use the rest of the action here, but in the listener,
      // in order to create the new scope on the server
      // the rest of the action gets saved once the scope is created
      state.activeNonWorkingPlanId = action.payload.planId;
    },
    setActiveNonWorkingScope: (state, action: PayloadAction<ServerScopeResponse>) => {
      if (action.payload.type === 'ScopeReady') {
        return {
          ...state,
          activeNonWorkingScope: scopeReady(action.payload.id, {
            mainConfig: action.payload,
            isFetching: false,
            forceRefreshGrid: false,
            hasEditableRevision: false,
            currentAnchors: anchorIdsFromMemberTrees(action.payload.memberTrees),
            isMultiScope: false,
            inSeason: 'unused-for-non-working-sets', // TODO: finish an omit that excludes these unused keys
            initialized: true,
            gridAsyncState: GRID_DEFAULT,
            pendingWrites: 0,
            hasLocks: false,
            balanceOptions: {},
            seedOptions: {},
            importOptions: {},
            overlayOptions: {},
            commands: [],
            persistErrorStatus: undefined,
            message: undefined
          })
        };
      } else {
        return {
          ...state,
          activeNonWorkingScope: scopePending(action.payload.id)
        };
      }
    },
    resetNonWorkingSets: () => initNonWorkingsetState
  },
  extraReducers: (builder) => {
    builder.addCase(receivedWorkingSets, (state, action) => {
      state.contexts = action.payload.filter(isNonWorkingSet);
    });
  }
});

export const isNonWorkingSet = (context: WorkingSetContext): context is NonWorkingSetContext => {
  return context.initParams.type === SCOPECREATE_WITHOUT_WP || context.initParams.type === SCOPECREATE_SMARTPLAN;
};
export const {
  setActiveNonWorkingScope,
  createNewNonWorkingScope,
  resetNonWorkingSets
} = nonWorkingSetsSlice.actions;

export default nonWorkingSetsSlice.reducer;
