import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic, AppState } from 'src/store';
import { of } from 'rxjs';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import {
  mfpModuleReady,
  setActivePage,
  setActivePageComponentPropsConf,
  setActivePerspective,
  setActiveTab,
} from './NavigationShell.slice';
import { extractNavPathFromString, getMfpModule } from './navigationUtils';
import { isEnabledTab } from 'src/services/configuration/codecs/confdefn';
import { isNone } from 'fp-ts/lib/Option';
import { updateFilterSensitivity } from 'src/components/FilterPanel/FilterPanel.slice';
import { isNil } from 'lodash';
import { AppThunk } from 'src/store';
import { concatMap, take, tap } from 'rxjs/operators';
import { appReady } from 'src/state/ViewConfig/ViewConfig.slice';
import { Observable } from 'rxjs/internal/Observable';
import { getSettings } from 'src/state/settings/Settings.actions';
import { checkIfHasMfpModule } from 'src/components/higherOrder/MfpScopeSensitive';
import { getAvailableScopeMembers } from 'src/state/scope/Scope.actions';

export const setActivePageComponentPropsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => [state$.value.appConfig.tenantConfig, state$.value.perspective.activePage] as const), // const here makes it a 2 elem tuple
    filter(inputIsNotNullOrUndefined),
    mergeMap((stateTuple) => {
      const navPath = extractNavPathFromString(stateTuple[1]);
      if (isNone(navPath)) {
        return of(setActivePageComponentPropsConf(undefined));
      }

      const maybeView = stateTuple[0].tabs
        .filter(isEnabledTab)
        .find((t) => t.pathSlot === navPath.value[1])
        ?.leftNavSections?.find((i) => i.pathSlot === navPath.value[2])
        ?.views.find((v) => v.pathSlot === navPath.value[3]);

      if (maybeView) {
        return of(setActivePageComponentPropsConf(maybeView));
      } else {
        return of(setActivePageComponentPropsConf(undefined));
      }
    })
  );
};

/**
 * This epic is responsible for setting the page filter sensitivity on any page navigation.
 * It assumes all pages are filter sensitive except a small known set that are hardcoded.
 *
 * Unfortunately, it currently relies on the configured pathSlot which has potential to deviate from the confdefn values.
 *
 * TODO: We may consider creating specific zod literals for hardcoded these values so we can assure that they match what the confdefn is configured with.
 */
export const setActivePageFilterSensitivityEpic: AppEpic = (action$, _state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    mergeMap((action) => {
      // returns the text after the final slash in the page path (if any)
      const pathSlot = action.payload.match(/[\w-]+$/g);
      const unformattedPathSlot = !isNil(pathSlot) ? pathSlot[0].toLowerCase().replace('-', '') : '';

      switch (unformattedPathSlot) {
        case 'topdown':
        case 'bottomup':
        case 'macromix':
        case 'geotrend':
        case 'topperformers':
        case 'summary':
        case 'parametertoggles':
        case 'cart':
        case 'targetlist':
          return of(updateFilterSensitivity(false));
        default:
          return of(updateFilterSensitivity(true));
      }
    })
  );
};

export const checkIfMfpModuleIsReady: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(appReady.type, setActivePerspective.type, setActiveTab.type),
    filter(() => checkIfHasMfpModule(state$.value.appConfig.tenantConfig)),
    concatMap((_incoming) => {
      return of(mfpModuleReady());
    })
  );
};

const needsMfpSettings = (state: AppState): boolean => {
  return (
    checkIfHasMfpModule(state.appConfig.tenantConfig) ||
    state.appConfig.tenantConfig.perspective?.scopeConfig?.type === 'MfpScopeSelector'
  );
};

export const setupSettings: AppEpic = (action$, state$, deps): Observable<AppThunk> => {
  const settingsService = deps.settings;
  return action$.pipe(
    ofType(appReady.type, mfpModuleReady.type),
    filter(() => needsMfpSettings(state$.value)),
    concatMap((_incoming) => {
      return of(getSettings(settingsService));
    })
  );
};

export const getMfpScopeMembers: AppEpic = (action$, state$, deps): Observable<AppThunk> => {
  const settingsService = deps.settings;
  return action$.pipe(
    ofType(appReady.type, mfpModuleReady.type),
    filter(() => needsMfpSettings(state$.value)),
    map(() => getMfpModule(state$.value)),
    filter(inputIsNotNullOrUndefined),
    concatMap((mfpModule) => of(getAvailableScopeMembers(mfpModule)))
  );
};

export const setLocalization: AppEpic = (action$, _state$, deps) => {
  const localizationService = deps.localization;
  return action$.pipe(
    ofType(getSettings.fulfilled.type),
    take(1),
    tap((action) => {
      // WARNING: here by side effects!
      localizationService.setCulture(action.payload.localization.baseLocale);
    })
  );
};
