import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { WorklistInfo } from 'src/worker/pivotWorker.types';


export interface SharedDataSlice {
  worklist: WorklistInfo[];
}

const initialState: SharedDataSlice = {
  worklist: [],
};

const SharedDataReducer = createSlice({
  name: 'SharedData',
  initialState,
  reducers: {
    receiveWorklist(state, action: PayloadAction<WorklistInfo[]>) {
      state.worklist = action.payload;
      return state;
    },
    receiveError(_state, _action) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});
export const { receiveWorklist, receiveError, cleanUp } = SharedDataReducer.actions;
export default SharedDataReducer.reducer;

