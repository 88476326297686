import { SplitViewState, SplitViewGridType, GridProp } from './split-view.types';
import { ViewParams } from 'src/state/ViewConfig/ViewConfig.types';
import { getEffeciveGroups } from 'src/components/Mfp/PivotConfigurator/utils';

export function getGridTypeProp(gridType: SplitViewGridType): GridProp {
  return gridType === SplitViewGridType.top ? 'topGrid' : 'bottomGrid';
}

export function generateSplitViewState(
  nextState: SplitViewState,
  currentState: SplitViewState,
  viewParams: {
    topGrid: ViewParams,
    bottomGrid: ViewParams
  }
): SplitViewState {
  let newState = { ...nextState };

  (['topGrid', 'bottomGrid'] as const).forEach((grid) => {
    if (newState[grid].viewParams) {
      const viewParams = newState[grid].viewParams;

      if (currentState[grid].pivotConfig) {
        newState[grid].availableGroup = currentState[grid].availableGroup;
        newState[grid].rowGroup = currentState[grid].rowGroup;
        newState[grid].colGroup = currentState[grid].colGroup;
      } else {
        const effective = getEffeciveGroups(
          newState[grid].allAvailableListing,
          viewParams!
        );
        newState[grid].availableGroup = effective.available;
        newState[grid].rowGroup = effective.row;
        newState[grid].colGroup = effective.column;
      }
    } else {
      newState[grid].availableGroup = newState[grid].allAvailableListing;
      newState[grid].rowGroup = [];
      newState[grid].colGroup = [];
    }
  });

  return newState;
}
