import React from 'react';
import PerformanceChart, { DataItem } from 'src/common-ui/components/Charts/PerformanceChart';
import DetailsStrip from 'src/components/views/ParetoAnalysis/DetailsStrip';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { Option } from 'src/components/Configure/ConfigureModal';
import { default as styles } from './ParetoGraph.styles';
import { STRIPHEIGHT } from './DetailsStrip.styles';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { classes } from 'typestyle';
import { resolvePath } from 'src/cdn';
import Renderer from 'src/utils/Domain/Renderer';
import { FlatPivot } from 'src/worker/pivotWorker.types';

import noImagePath from 'src/common-ui/images/noimage.jpg';

const noImage = resolvePath(noImagePath);

const ITEM_WIDTH_SIZE = 40;

const idProperty = 'member:stylecolor:id';
const displayNameProperty = 'member:stylecolor:description';
const imageProperty = 'attribute:img:id';

export interface DisplayModel {
  topTitle: string;
  bottomTitle: string;
  bar1Property: Option;
  bar2Property: Option;
  spline1Property: Option;
  spline2Property: Option;
  cumulativeProperty: Option;
  bar1BottomProperty: Option;
  bar2BottomProperty: Option;
  spline1BottomProperty: Option;
}

export interface ParetoGraphSlice extends PrintProps {
  flat: FlatPivot;
  stripLayout: TenantConfigViewData;
  displayModel: DisplayModel;
  openStore?: (item: DataItem) => void;
}

interface ParetoGraphProps extends ParetoGraphSlice {}
interface ParetoGraphState {
  selectedItem: DataItem | undefined;
}

export default class ParetoGraph extends React.Component<ParetoGraphProps, ParetoGraphState> {
  constructor(props: ParetoGraphProps) {
    super(props);
    this.state = {
      selectedItem: undefined,
    };
    this.handleClickOnItem = this.handleClickOnItem.bind(this);
  }

  configGenerator(items: FlatPivot, onClickEvent: (item: DataItem, i: number) => void) {
    const {
      topTitle,
      bottomTitle,
      bar1BottomProperty,
      bar1Property,
      bar2BottomProperty,
      bar2Property,
      spline1Property,
      spline2Property,
      cumulativeProperty,
      spline1BottomProperty,
    } = this.props.displayModel;
    const cfg = {
      className: styles.graphClass,
      restrictHeight: true,
      idProperty,
      displayNameProperty,
      summaryProperty: bar1Property.dataIndex,
      cumulativeProperty: cumulativeProperty.dataIndex,
      topTitle,
      bottomTitle,
      topSeries: [
        {
          displayName: bar1Property.text,
          propertyName: bar1Property.dataIndex,
          renderer: (val: number) => (bar1Property.renderer != null ? Renderer[bar1Property.renderer](val) : val),
        },
        {
          displayName: bar2Property.text,
          propertyName: bar2Property.dataIndex,
          renderer: (val: number) => (bar2Property.renderer != null ? Renderer[bar2Property.renderer](val) : val),
        },
        {
          displayName: spline1Property.text,
          propertyName: spline1Property.dataIndex,
          renderer: (val: number) => (spline1Property.renderer != null ? Renderer[spline1Property.renderer](val) : val),
        },
        {
          displayName: spline2Property.text,
          propertyName: spline2Property.dataIndex,
          renderer: (val: number) => (spline2Property.renderer != null ? Renderer[spline2Property.renderer](val) : val),
        },
      ],
      bottomSeries: [
        {
          displayName: bar1BottomProperty.text,
          propertyName: bar1BottomProperty.dataIndex,
          renderer: (val: number) =>
            bar1BottomProperty.renderer != null ? Renderer[bar1BottomProperty.renderer](val) : val,
        },
        {
          displayName: bar2BottomProperty.text,
          propertyName: bar2BottomProperty.dataIndex,
          renderer: (val: number) =>
            bar2BottomProperty.renderer != null ? Renderer[bar2BottomProperty.renderer](val) : val,
        },
        {
          displayName: spline1BottomProperty.text,
          propertyName: spline1BottomProperty.dataIndex,
          renderer: (val: number) =>
            spline1BottomProperty.renderer != null ? Renderer[spline1BottomProperty.renderer](val) : val,
        },
      ],
      data: items as DataItem[],
      itemWidth: ITEM_WIDTH_SIZE,
      onItemSelect: onClickEvent,
      defaultIndex: 0,
    };

    return cfg;
  }

  handleClickOnItem(item: DataItem) {
    this.setState({
      selectedItem: item as DataItem,
    });
  }

  render() {
    const { flat, stripLayout, isPrintMode } = this.props;
    const { selectedItem } = this.state;
    const config = this.configGenerator(flat, this.handleClickOnItem);
    if (isPrintMode) {
      config.className = '';
    }
    const containerClass = classes(styles.graphContainerFitScreen, styles.graphContainer);
    return (
      <React.Fragment>
        <div className={containerClass}>
          <PerformanceChart {...config}>
            {(item) => {
              return (
                <div
                  style={{
                    textAlign: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img src={`${item[imageProperty] || resolvePath(noImage)}`} style={{ height: '100%' }} />
                </div>
              );
            }}
          </PerformanceChart>
        </div>
        <div style={{ height: STRIPHEIGHT }}>
          <DetailsStrip sections={stripLayout} item={selectedItem} openStore={this.props.openStore} />
        </div>
      </React.Fragment>
    );
  }
}
