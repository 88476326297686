import { getDateFromWeek } from 'src/common-ui/components/WeekRange/WeekRangePicker.utils';
import { DaysRangeListResponse } from 'src/types/Scope';
import { isString, isNil } from 'lodash';

export function addEventListenerForEditors(element: HTMLElement) {
  element.addEventListener('keydown', function(event: KeyboardEvent) {
    const KEY_LEFT = 37;
    const KEY_UP = 38;
    const KEY_RIGHT = 39;
    const KEY_DOWN = 40;
    const KEY_PAGE_UP = 33;
    const KEY_PAGE_DOWN = 34;
    const KEY_PAGE_HOME = 36;
    const KEY_PAGE_END = 35;
    const TAB = 9;
    const keyCode = event.keyCode;

    const isNavigationKey =
      keyCode === KEY_LEFT ||
      keyCode === KEY_RIGHT ||
      keyCode === KEY_UP ||
      keyCode === KEY_DOWN ||
      keyCode === KEY_PAGE_DOWN ||
      keyCode === KEY_PAGE_UP ||
      keyCode === KEY_PAGE_HOME ||
      keyCode === KEY_PAGE_END ||
      keyCode === TAB;

    if (isNavigationKey) {
      event.stopPropagation();
    }
  });
}

export function getDate(weekNo: string, mergedRangeList: DaysRangeListResponse) {
  return getDateFromWeek(weekNo, mergedRangeList.start_date) || getDateFromWeek(weekNo, mergedRangeList.end_date);
}

export function createRowSelectionObj(rowValue: any, mergedRangeList: DaysRangeListResponse) {
  if (isNil(rowValue)) {
    return null;
  } else if (isString(rowValue)) {
    return {
      weekNo: rowValue,
      date: getDate(rowValue, mergedRangeList),
    };
  } else if (isNil(rowValue.date)) {
    // for objs, set dates if none available
    return {
      weekNo: rowValue.weekNo,
      date: getDate(rowValue.weekNo, mergedRangeList),
    };
  }

  return {
    ...rowValue,
  };
}
