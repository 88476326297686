import { connect } from 'react-redux';
import { AppThunkDispatch, AppState } from 'src/store';
import coalesce from 'src/utils/Functions/Coalesce';
import {
  FunctionProps,
  ValueProps,
  OwnProps,
  default as FloorsetSelector,
  FloorsetReduxSlice,
} from './FloorsetSelector';
import { asyncGetFloorsetConfig } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.actions';
import { get } from 'lodash/fp';
import container from 'src/ServiceContainer';
import { update } from 'src/services/lenses/Lenses.actions';
import { scopeConfigLens } from 'src/services/lenses/lenses';
import { Lens } from 'monocle-ts';
import { Action } from 'redux';
import {
  AssortmentScopeSelectorSlice,
  receiveScopeError,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { PeriodFormReduxSlice } from '../DatePicking/PeriodForm.types';
import { SelectedTypes } from './TargetCreation.types';

function mapStateToProps(state: AppState, ownProps: OwnProps): ValueProps {
  const { selectedFloorset } = ownProps.lens.get(state);
  const { selectionErrors, floorsetLoading } = get('scope', state);
  const floorsetConfig = ownProps.floorsetsLens.get(state);

  return {
    floorsetConfig: floorsetConfig,
    selectedFloorset: coalesce(selectedFloorset),
    selectedProductMember: ownProps.productIdLens.get(state),
    invalid: selectionErrors.floorSet,
    isLoading: floorsetLoading,
    locked: false,
  };
}

function asyncGetLyFloorsetConfig(productId: string) {
  return (dispatch: AppThunkDispatch, _getState: () => AppState): Promise<Action> => {
    const lens = scopeConfigLens.compose(Lens.fromProps<AssortmentScopeSelectorSlice>()(['lyFloorsetConfig']));

    return container.scopeClient
      .getLyFloorsets(productId)
      .then((response) => {
        return dispatch(
          update(
            lens.set({
              lyFloorsetConfig: response,
            })
          )
        );
      })
      .catch((error) => {
        return dispatch(receiveScopeError(error));
      });
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): FunctionProps {
  const selFloorSetLens = ownProps.lens.compose(Lens.fromProp<FloorsetReduxSlice>()('selectedFloorset'));
  const datesLens = Lens.fromProps<PeriodFormReduxSlice>()(['selectedStartWeek', 'selectedEndWeek']);
  return {
    onChangeFloorset(floorSet: string): void {
      dispatch((dispatcher: AppThunkDispatch, getState: () => AppState): Promise<Action> | void => {
        return container.scopeClient
          .getFloorsetAttributes(floorSet, ownProps.productIdLens.get(getState()))
          .then((response) => {
            if (ownProps.salesLens) {
              dispatcher(
                update(
                  ownProps.salesLens.composeLens(datesLens).set({
                    selectedStartWeek: response.slsstart,
                    selectedEndWeek: response.slsend,
                  })
                )
              );
              if (ownProps.selectedLens) {
                dispatcher(
                  update(
                    ownProps.selectedLens.compose(Lens.fromProp<SelectedTypes>()('sales')).set({
                      start: response.slsstart,
                      end: response.slsend,
                    })
                  )
                );
              }
            }
            if (ownProps.receiptLens) {
              dispatcher(
                update(
                  ownProps.receiptLens.composeLens(datesLens).set({
                    selectedStartWeek: response.rcptstart,
                    selectedEndWeek: response.rcptend,
                  })
                )
              );
              if (ownProps.selectedLens) {
                dispatcher(
                  update(
                    ownProps.selectedLens.compose(Lens.fromProp<SelectedTypes>()('receipt')).set({
                      start: response.rcptstart,
                      end: response.rcptend,
                    })
                  )
                );
              }
            }
            return dispatcher(update(selFloorSetLens.set(floorSet)));
          });
      });
    },
    onClickGetFloorset(productMember: string): void {
      dispatch(asyncGetFloorsetConfig(productMember));
      dispatch(asyncGetLyFloorsetConfig(productMember));
    },
  };
}

export const FloorsetSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(FloorsetSelector);

export default FloorsetSelectorContainer;
