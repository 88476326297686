import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SCOPECREATE_WITH_WP, ScopeMemberInfo } from 'src/services/Scope.client';
import { Dayjs } from 'dayjs';
import type { ScopeCreateRequest } from 'src/services/Scope.client';
import type { Space } from 'src/space';
import { CONTEXT_STATUSES } from './workingSets.types';
import { deleteScope } from '../scope/Scope.actions';

export interface WorkingSetContext {
  id: string,
  status: CONTEXT_STATUSES,
  anchor: Space<ScopeMemberInfo[]>,
  module: string,
  contextCreationTime: Dayjs,
  initParams: {} & ScopeCreateRequest['initParams']
}

// have to parse the datetime on ajax load
export interface WorkingSetContextPayload extends Pick<WorkingSetContext, 'id' | 'status' | 'anchor' | 'module'> { creationTime: string };

export interface WorkingSetReport extends Pick<WorkingSetContext, 'id' | 'status'> { }

export interface PublishEventPayload {
  process: number,
  channel: string,
  payload: {
    id: number,
    version: string,
    authored_by: string
  }
}

export interface WorkingSetsState {
  contexts: WorkingSetContext[],
  reports: WorkingSetReport[], // Idk if this is needed but I just left it in
  // TODO: remove last/latest once backend update is deployed, they are not present in the new shape anymore
  latestPublishTime: string | null,
  lastPublishTime: string | null,
  latestPublish: PublishEventPayload | null,
  actualsUpdated: boolean
}

const initWorkingsetState: WorkingSetsState = {
  contexts: [],
  reports: [],
  latestPublishTime: null,
  lastPublishTime: null,
  latestPublish: null,
  actualsUpdated: false
};

export const workingSetsSlice = createSlice({
  name: 'workingSets',
  initialState: initWorkingsetState,
  reducers: {
    requestWorkingSets: state => ({ ...state, contexts: [] }),
    receivedWorkingSets: (state, action: PayloadAction<WorkingSetContext[]>) => ({
      ...state,
      contexts: action.payload.filter(s => s.initParams.type === SCOPECREATE_WITH_WP)
    }),
    receivedReports: (state, action: PayloadAction<WorkingSetReport[]>) => {
      return {
        ...state,
        reports: action.payload
      };
    },
    setLastPublishTime: (state, action: PayloadAction<string>) => {
      state.lastPublishTime = state.latestPublishTime;
      state.latestPublishTime = action.payload;
    },
    receivedPublish: (state, action: PayloadAction<PublishEventPayload>) => {
      state.latestPublish = action.payload;
    },
    resetPublish: (state) => {
      state.latestPublish = null;
    },
    receivedActualsUpdate: (state) => {
      state.actualsUpdated = true;
    },
    resetActuals: (state) => {
      state.actualsUpdated = false;
    }
  },
  extraReducers: (builder) => {
    // #region "deleteScope"
    builder.addCase(deleteScope.fulfilled, (state, action) => {
      // imperatively remove the deleted scope from the contexts
      // this should happen automatically when the sse sends,
      // but doing it here is faster
      const deletedScopeId = action.meta.arg;
      const remainingScopes = state.contexts.filter((item) => item.id !== deletedScopeId);
      state.contexts = remainingScopes;
    });
    // #endregion
  }
});
export const {
  requestWorkingSets,
  receivedWorkingSets,
  receivedReports,
  setLastPublishTime,
  receivedPublish,
  resetPublish,
  receivedActualsUpdate,
  resetActuals
} = workingSetsSlice.actions;

export default workingSetsSlice.reducer;
