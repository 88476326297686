import React, { Component } from 'react';
import { isNil } from 'lodash';
import { ICellRendererParams } from '@ag-grid-community/core';

import { Overlay } from 'src/common-ui';
import container from 'src/ServiceContainer';
import { MasterDetailConfig } from 'src/utils/Component/AgGrid/AgConfigParse';
import { BasicTenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

import { MasterDetailChart } from 'src/components/MasterDetailRenderer/MasterDetailChart';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import ServiceContainer from 'src/ServiceContainer';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';

export enum MasterDetailRendererType {
  chart = 'chart',
}

export interface MasterDetailChartMetrics {
  left: BasicTenantConfigViewItem[];
  right?: BasicTenantConfigViewItem[];
}

export interface MasterDetailChartConfig {
  chartTitle: string;
  renderer: MasterDetailRendererType;
  xAxisDataIndex: string;
  metrics: MasterDetailChartMetrics;
}

interface MasterDetailRendererProps extends ICellRendererParams, MasterDetailConfig {}

interface MasterDetailRendererState {
  isLoading: boolean;
  config: MasterDetailChartConfig | null;
  data: BasicPivotItem[];
}

export class MasterDetailRenderer extends Component<MasterDetailRendererProps, MasterDetailRendererState> {
  constructor(props: MasterDetailRendererProps) {
    super(props);

    this.state = {
      isLoading: true,
      config: null,
      data: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const configParams = this.props.configApi.params;
    const config = await container.tenantConfigClient.getTenantViewDefn<MasterDetailChartConfig>({
      defnId: !isNil(configParams) ? configParams.defnId : '',
      appName: !isNil(configParams) ? (configParams.appName as AppType) : ASSORTMENT,
    });

    const dataApi = this.props.dataApi;
    const processedApi = processApiParams(dataApi, this.props.data);
    let data: BasicPivotItem[] = [];

    if (isListDataApi(processedApi)) {
      data = await ServiceContainer.pivotService
        .listData(processedApi.defnId, AppType.Assortment, processedApi.params)
        .then((resp) => {
          return resp.flat;
        });
    } else {
      // TODO: handle non-list data call here
    }

    this.setState({
      isLoading: false,
      config,
      data,
    });
  }

  render() {
    const { isLoading, config, data } = this.state;

    if (isLoading || isNil(config)) {
      return <Overlay visible={true} type={'loading'} fitParent={true} />;
    }

    const parentId = !isNil(this.props.dataApi.params) ? this.props.data[this.props.dataApi.params.style] : '';
    switch (config.renderer) {
      case MasterDetailRendererType.chart:
        return <MasterDetailChart parentId={parentId} config={config} data={data} />;
      default:
        return <span>Unknown custom renderer for master detail.</span>;
    }
  }
}
