import container from 'src/ServiceContainer';
import { Pivot, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ASSORTMENT, STYLE_ID, STYLE_NAME, STYLE_DESCRIPTION } from 'src/utils/Domain/Constants';

export function remapStyleToStyleColor(flatData: BasicPivotItem[]): BasicPivotItem[] {
  return flatData.map((style) => {
    const stylecolorData = {
      ...style,
    };

    // remap id, name, description to style if available
    if (style[STYLE_ID]) {
      stylecolorData['id'] = style[STYLE_ID];
    }
    if (style[STYLE_NAME]) {
      stylecolorData['name'] = style[STYLE_NAME];
    }
    if (style[STYLE_DESCRIPTION]) {
      stylecolorData['description'] = style[STYLE_DESCRIPTION];
    }
    return stylecolorData;
  });
}

export async function fetchCompanionListData(modelDefn: string, remapStyleData = true): Promise<Pivot> {
  const responseData = await container.pivotService.listFitViewData(modelDefn, ASSORTMENT);

  if (!remapStyleData) {
    return responseData;
  }

  return responseData;
}
