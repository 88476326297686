import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, get, cloneDeep } from 'lodash';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { TenantConfigViewItem, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { FloorsetViewItem } from './QuickReconcile';
export interface ViewData {
  gridData: BasicPivotItem[];
}

export interface DropdownConfigViewData extends TenantConfigViewData {
  hideEmptyRow?: boolean;
}
export interface GridDefnConfig extends TenantConfigViewData {
  subheaderDropdowns: TenantConfigViewData;
  floorsetDefn?: TenantConfigViewData;
}
export interface SubheaderDefns {
  groupBy: DropdownConfigViewData;
  sortBy?: DropdownConfigViewData;
  countLimit?: DropdownConfigViewData;
}

export interface ViewDefns {
  gridDefn: GridDefnConfig;
}

export interface SubheaderDropdownSlice {
  defaultSelection: number;
  selection: number | null;
  options: TenantConfigViewItem[];
}

const emptyItem = {
  dataIndex: '',
  text: '',
  dimension: 'product',
};

export const initialGroupBy = {
  defaultSelection: 0,
  selection: 0,
  options: [emptyItem],
};

export interface QuickReconcileSlice {
  floorset: string;
  areViewDefnsLoading: boolean;
  isViewDataLoading?: boolean;
  viewData?: ViewData;
  viewDefns?: ViewDefns;
  groupByUpdated: boolean;
  selectedFloorsetIndex: number;
  groupBy: GroupBySlice;
  floorsetsData: FloorsetViewItem[];
  floorsetUpdated: boolean;
  // FIXME: remove, this state does should be local to `ReconcileLink` only
  // also it should be named isOpen or something similar
  // to not be confused with the QR button visiblity based on perspective
  showQuickReconcile: boolean;
  error: boolean;
}

const initialState: QuickReconcileSlice = {
  areViewDefnsLoading: true,
  isViewDataLoading: false,
  groupByUpdated: false,
  floorsetUpdated: false,
  floorsetsData: [],
  groupBy: initialGroupBy,
  selectedFloorsetIndex: 0,
  floorset: '',
  showQuickReconcile: false,
  error: false,
};

function _handleDropdownExtras(
  ddSlice: SubheaderDropdownSlice,
  viewDefn: DropdownConfigViewData,
  currentSelection: TenantConfigViewItem | null
) {
  ddSlice.options = viewDefn.view;
  if (viewDefn.hideEmptyRow !== true) {
    ddSlice.options.unshift(emptyItem);
  }
  if (viewDefn.default) {
    const defaultSelection = ddSlice.options.findIndex((opt) => opt.dataIndex === viewDefn.default);
    ddSlice.defaultSelection = defaultSelection;
  }
  const newSelection = ddSlice.options.findIndex((opt) => isEqual(opt, currentSelection));
  if (newSelection >= 0) {
    ddSlice.selection = newSelection;
  } else {
    ddSlice.selection = 0;
  }
  return ddSlice;
}

const QuickReconcileSlice = createSlice({
  name: 'QuickReconcile',
  initialState,
  reducers: {
    requestQuickReconcileConfigs(state) {
      state.areViewDefnsLoading = true;
    },
    receiveQuickReconcileConfigs(state, action: PayloadAction<ViewDefns>) {
      state.areViewDefnsLoading = false;
      state.viewDefns = action.payload;
    },
    onShowQuickReconcile(state) {
      state.showQuickReconcile = true;
    },
    receiveQuickReconcileViewDefns(state, action: PayloadAction<SubheaderDefns>) {
      let groupBy: GroupBySlice = cloneDeep(initialGroupBy);
      const groupByDefn = action.payload.groupBy;
      if (groupByDefn) {
        const curSel = get(state.groupBy.options, `[${state.groupBy.selection}]`, null);
        groupBy = {
          ...state.groupBy,
          ..._handleDropdownExtras(groupBy, groupByDefn, curSel),
        };
      }
      state.groupBy = groupBy;
    },
    setFloorsetsQR(state, action: PayloadAction<FloorsetViewItem[]>) {
      state.floorsetsData = action.payload;
    },
    setSelectedFloorsetQRIndex(state, action: PayloadAction<number>) {
      state.floorsetUpdated = true;
      state.selectedFloorsetIndex = action.payload;
    },
    requestQuickReconcileData(state) {
      state.groupByUpdated = false;
      state.floorsetUpdated = false;
      state.isViewDataLoading = true;
    },
    receiveQuickReconcileData(state, action: PayloadAction<ViewData>) {
      state.isViewDataLoading = false;
      state.viewData = action.payload;
    },
    receiveQuickReconcileDataError(state) {
      state.groupByUpdated = false;
      state.floorsetUpdated = false;
    },
    updateQuickReconcileGroupBy(state, action: PayloadAction<number>) {
      (state.groupByUpdated = true), state.groupBy;
      state.groupBy.selection = action.payload;
    },
    updateQuickReconcileFloorsetBy(state, action: PayloadAction<string>) {
      state.floorsetUpdated = true;
      state.floorset = action.payload;
    },
    receiveError() {
      return {
        ...initialState,
        error: true,
        showQuickReconcile: true,
      };
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestQuickReconcileConfigs,
  receiveQuickReconcileConfigs,
  receiveQuickReconcileViewDefns,
  requestQuickReconcileData,
  receiveQuickReconcileData,
  receiveQuickReconcileDataError,
  onShowQuickReconcile,
  updateQuickReconcileGroupBy,
  updateQuickReconcileFloorsetBy,
  setSelectedFloorsetQRIndex,
  receiveError,
  setFloorsetsQR,
  cleanUp,
} = QuickReconcileSlice.actions;

export default QuickReconcileSlice.reducer;
