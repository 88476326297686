import React from 'react';
import { connect } from 'react-redux';
import { Overlay } from 'src/common-ui/index';
import { listPairStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import SubheaderContainer from 'src/components/Subheader/Subheader.container';
import { OvertimeView } from '../OvertimeView';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ASSORTMENT_BUILD_FILTER_ALL_WARNING } from 'src/utils/Domain/Constants';
import ConfigureModal, { ConfigureModalProps, Option, OptionGroup } from 'src/components/Configure/ConfigureModal';
import { ConfigureOvertimeConfig } from '../OvertimeView.types';
import container from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { getHeaderTextFromState } from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { NestedOvertimeComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { z } from 'zod';
import { NestedOvertimeViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import {
  receiveError,
  receiveTenantConfig,
  requestTenantConfig,
  setConfigureSelections,
  setTopMember,
  setTransposedSelections,
} from './NestedOvertime.slice';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { TransposedColumns } from 'src/services/configuration/codecs/viewdefns/general';
import { isNil } from 'lodash';

export interface NestedOvertimeOwnProps extends z.infer<typeof NestedOvertimeComponentProps> {}

export interface NestedOvertimeDispatchProps {
  onShowView: () => void;
  updateConfigureSelections: (selections: Option[], transposedSelections?: Option[]) => void;
  onRefresh: (selectedId: string) => void;
  onError: (error: any) => void;
}
interface NestedOvertimeValueProps extends NestedOvertimeOwnProps {
  customHeader: string;
  data: BasicPivotItem[];
  viewConfig: ConfigureOvertimeConfig;
  topMember?: string;
  configureSelections: Option[];
  defaultConfigureSelections: Option[];
  configureOptions?: OptionGroup[];
  transposedColumns?: TransposedColumns;
  transposedSelections?: Option[];
  configLoaded: boolean;
  dataLoaded: boolean;
  rowHeight: number;
}
export interface NestedOvertimeProps extends NestedOvertimeValueProps, NestedOvertimeDispatchProps {}

type AllStates = {
  configureIsOpen: boolean;
  uncommitedConfigureSelections: Option[];
  uncommittedTransposedSelections?: Option[];
  search?: string;
};
interface State extends AllStates {}

class NestedOvertimeContainer extends React.Component<NestedOvertimeProps, State> {
  constructor(props: NestedOvertimeProps) {
    super(props);
    this.state = {
      configureIsOpen: false,
      search: '',
      uncommitedConfigureSelections: this.props.defaultConfigureSelections,
      uncommittedTransposedSelections: this.props.transposedSelections,
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  renderConfigureModal = () => {
    const configureModalProps: ConfigureModalProps = {
      enabled: true,
      isOpen: this.state.configureIsOpen,
      optionGroups: this.props.configureOptions || [],
      transposedColumns: this.props.transposedColumns,
      transposedSelections: this.state.uncommittedTransposedSelections || this.props.transposedSelections,
      selections: this.state.uncommitedConfigureSelections || [],
      instructions: 'Select Aggregation Levels',
      onReset: () => {
        this.setState({
          uncommitedConfigureSelections: this.props.defaultConfigureSelections,
        });
      },
      onToggleModal: (action) => {
        const nextState: Partial<State> = {
          configureIsOpen: !this.state.configureIsOpen,
        };
        switch (action) {
          case 'apply': {
            if (this.state.uncommitedConfigureSelections) {
              this.setState({
                configureIsOpen: false,
              });
              if(this.state.uncommittedTransposedSelections){
                this.props.updateConfigureSelections(this.state.uncommitedConfigureSelections, this.state.uncommittedTransposedSelections);
              } else this.props.updateConfigureSelections(this.state.uncommitedConfigureSelections);
            }
            break;
          }
          default: {
            this.setState({
              configureIsOpen: false,
              uncommitedConfigureSelections: this.props.configureSelections,
            });
          }
        }
        // TODO: There has to be a better way... :/
        this.setState(nextState as any);
      },
      selectionUpdate: (selections: Option[], transposedSelections?: Option[]) => {
        if (!isNil(transposedSelections)) {
          this.setState({
            uncommitedConfigureSelections: selections,
            uncommittedTransposedSelections: transposedSelections,
          });
        } else {
          this.setState({
            uncommitedConfigureSelections: selections,
          });
        }
      },
    };
    return <ConfigureModal {...configureModalProps} />;
  };

  render() {
    if (!this.props.configLoaded) {
      return <Overlay visible={true} type={'loading'} qaKey={'overtime-loading'} />;
    }

    const { title } = this.props;
    return (
      <div className={listPairStyle}>
        <SubheaderContainer
          title={title}
          showSearch={true}
          showFlowStatus={false}
          errorCondition={ASSORTMENT_BUILD_FILTER_ALL_WARNING}
          configureOptions={{
            type: 'enabled',
            onConfigureClick: () => {
              this.setState({
                configureIsOpen: true,
                uncommitedConfigureSelections: this.props.configureSelections,
              });
            },
          }}
          searchReturn={(newVal: string) => {
            this.setState({
              search: newVal,
            });
          }}
        />
        <div className={'data-container'}>
          <React.Fragment>
            <OvertimeView
              loaded={this.props.dataLoaded}
              viewConfig={this.props.viewConfig}
              rowHeight={this.props.rowHeight}
              aggBys={(this.props.configureSelections || []).map((sel) => sel.dataIndex)}
              transposedSelections={this.props.transposedSelections}
              treeData={this.props.data}
              search={this.state.search}
              exportOptions={{
                fileName: title,
                customHeader: this.props.customHeader,
              }}
            />
          </React.Fragment>
          {this.renderConfigureModal()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: NestedOvertimeOwnProps): NestedOvertimeValueProps {
  const customHeader = getHeaderTextFromState(state, true);
  const viewState = state.pages.hindsighting.nestedOvertime;
  const {
    viewDataState,
    viewDefnState,
    data,
    viewDefn,
    topMember,
    configureSelections,
    defaultConfigureSelections,
    transposedColumns,
    transposedSelections,
    configureOptions,
    rowHeight,
  } = viewState;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);
  return {
    ...ownProps,
    data,
    viewConfig: viewDefn,
    topMember,
    configureSelections,
    defaultConfigureSelections,
    configureOptions,
    transposedColumns,
    transposedSelections,
    configLoaded,
    dataLoaded,
    customHeader,
    rowHeight,
  };
}

function mapDispatchToProps(
  dispatch: AppThunkDispatch,
  { defns }: NestedOvertimeOwnProps
): NestedOvertimeDispatchProps {
  const client = container.tenantConfigClient;

  return {
    async onShowView() {
      dispatch(requestTenantConfig());
      await client
        .getTenantViewDefn<ConfigureOvertimeConfig>({
          defnId: defns.view[0],
          appName: AppType.Assortment,
          validationSchema: NestedOvertimeViewDefn,
        })
        .then((resp) => {
          const defaultConfigureSelections = resp.configure.defaults.map((key, ind) => {
            const options = resp.configure.view[ind].options;
            return options.find((i) => i.dataIndex === key) || options[0];
          });
          const configureOptions = resp.configure.view;
          const configureSelections = resp.configure.defaults.map((key, ind) => {
            const options = resp.configure.view[ind].options;
            return options.find((i) => i.dataIndex === key) || options[0];
          });
          const transposedColumns = resp.configure.transposedColumns;
          const transposedSelections = transposedColumns && resp.timeLevels?.colDef.map((key, ind) => {
            const options = ind === 0 ? transposedColumns.topLevel : ind === 1 ? transposedColumns.bottomLevel : [];
            return options?.find((i) => i.dataIndex === key || i.colDef === key) || options[1];
          });
          const rowHeight = resp.main && resp.main.rowHeight ? resp.main.rowHeight : 30;

          dispatch(
            receiveTenantConfig({
              defaultConfigureSelections,
              configureOptions,
              configureSelections,
              viewDefn: resp,
              rowHeight,
              transposedColumns,
              transposedSelections
            })
          );
        })
        .catch((error) => {
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.nestedOvertime,
              issues: error,
              defnId: error.defnId,
            })
          );
        });
    },
    updateConfigureSelections(uncommittedSelections: Option[], uncommittedTransposedSelections?: Option[]) {
      if(!isNil(uncommittedTransposedSelections)){
        dispatch(setTransposedSelections(uncommittedTransposedSelections));
      }
      dispatch(setConfigureSelections(uncommittedSelections));
    },
    onRefresh(selectedId: string) {
      dispatch(setTopMember(selectedId));
    },
    onError(error: any) {
      dispatch(
        receiveError({
          type: ComponentErrorType.config,
          message: (error as Error)?.message,
          name: ConfDefnComponentType.nestedOvertime,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NestedOvertimeContainer);
