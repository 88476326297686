import { createSelector } from 'reselect';
import { AppState } from 'src/store';
import { MetricInfo } from 'src/components/Mfp/MacroSummary/MacroSummary';
import { get, mapValues, pickBy } from 'lodash';
import type { SettingsEntry } from 'src/services/Settings';
import type { VersionsToSummarize } from './MacroSummaries.slice';
import { ServerMetric } from 'src/state/scope/Scope.types';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { SelectedSpace } from 'src/components/AnchorRadioMultiSelect/AnchorCheckboxSelect';

// selectors
export const getMetricsToSummarize = (state: AppState) => state.macroSummaries.metricsToSummarize;
export const getVersionsToSummarize = (state: AppState) => state.macroSummaries.versionsToSummarize;
export const getSelectedMacroAnchors = (state: AppState) => state.macroSummaries.selectedMacroAnchors;
export const getCurrentAnchors = (state: AppState) => getScopeReadyData(state.mfpScope) ?
  getScopeReadyData(state.mfpScope)?.currentAnchors :
  undefined;
function getSummaryData(state: AppState) {
  return state.macroSummaries.summaryData;
}
function getSettings(state: AppState) {
  return state.settings.entriesByKey;
}

function getMetricConfig(state: AppState) {
  const readyData = getScopeReadyData(state.mfpScope);
  return readyData?.mainConfig.metrics;
}

// final combination of state data for output to the component
const mapSummariesToMetricInfo = (
  metricsToSummarize: string[],
  versionsToSummarize: VersionsToSummarize,
  selectedMacroAnchors: SelectedSpace,
  settings: { [key: string]: SettingsEntry },
  metricsConfig: ReadonlyArray<ServerMetric> | undefined,
  summaryData: ReturnType<typeof getSummaryData>
): ReadonlyArray<MetricInfo> => {
  return metricsToSummarize.map((met) => {
    const maybePrimaryData = summaryData?.find(cell => cell.row === 0 && cell.metricId === met)?.value;
    const maybeSecondaryData = summaryData?.find(cell => cell.row === 1 && cell.metricId === met)?.value;
    const maybeDeltaData = summaryData?.find(cell => cell.row === 2 && cell.metricId === met)?.value;

    const metricConfig = metricsConfig?.find((m) => m.id === met);
    const secondaryLabel = get(settings, `revision.${versionsToSummarize.secondary}.display`)?.value ||
      versionsToSummarize.secondary;
    const labelledVersions: VersionsToSummarize = {
      primary: get(settings, `revision.${versionsToSummarize.primary}.display`)?.value || versionsToSummarize.primary,
      secondary: secondaryLabel,
      delta: `VAR ${secondaryLabel}`
    };

    if (metricConfig) {
      return {
        metricLabel: metricConfig.name,
        versionLabels: labelledVersions,
        value: {
          primary: maybePrimaryData !== undefined ? parseFloat(maybePrimaryData) : undefined,
          secondary: maybeSecondaryData !== undefined ? parseFloat(maybeSecondaryData) : undefined,
          delta: maybeDeltaData !== undefined ? parseFloat(maybeDeltaData) : undefined
        },
        summaryFormat: metricConfig.formats!.summary
      };
    }
    return {
      metricLabel: null,
      versionLabels: null,
      value: null,
      summaryFormat: null
    };
  });
};

const emptyMetric: MetricInfo = {
  metricLabel: null,
  versionLabels: null,
  value: null,
  summaryFormat: null
};

export const getMappedSummaries = createSelector(
  [
    getMetricsToSummarize,
    getVersionsToSummarize,
    getSelectedMacroAnchors,
    getCurrentAnchors,
    getSettings,
    getMetricConfig,
    getSummaryData
  ],
  (
    metricsToSummarize,
    versionsToSummarize,
    selectedMacroAnchors,
    currentAnchors,
    settings,
    metricConfig,
    summaryData
  ) => {
    if (metricsToSummarize && versionsToSummarize && selectedMacroAnchors && currentAnchors) {
      const multiScopeDims = pickBy(currentAnchors, (ids) => ids.length >= 2);
      const selectedSpace = mapValues(multiScopeDims, (ids, dimension) => {
        return ids.map((id) => {
          return selectedMacroAnchors[dimension].includes(id) ? { id, selected: true } : { id, selected: false };
        });
      });

      // primary code path
      return mapSummariesToMetricInfo(
        metricsToSummarize,
        versionsToSummarize,
        selectedSpace as SelectedSpace,
        settings,
        metricConfig,
        summaryData
      );
    }
    // in the event that we aren't ready to start rendering,
    // go ahead and render a bunch of empty metrics to start building the dom
    // TODO: try and determine the number of summary metrics from the view config
    // so we can render that number of empty macro summaries before view config/data loads
    return [emptyMetric, emptyMetric, emptyMetric, emptyMetric, emptyMetric, emptyMetric, emptyMetric, emptyMetric];
  }
);
