import { classes } from 'typestyle';
import { downArrowClass, upArrowClass, neutralArrowClass } from 'src/common-ui/theme';
import { DirectionalMetric } from 'src/common-ui/components/Macros/Macros.types';

export function getDirectionClasses(direction: DirectionalMetric['direction']) {
  let iconClass = '';
  let middleContainerClass = '';

  switch (direction) {
    case 'up':
      iconClass = classes('up', upArrowClass);
      middleContainerClass = 'positive';
      break;
    case 'down':
      iconClass = classes('down', downArrowClass);
      middleContainerClass = 'negative';
      break;
    case 'neutral':
      iconClass = classes('neutral', neutralArrowClass);
      middleContainerClass = 'zero';
      break;
  }

  return {
    iconClass: classes('dir fa fas', iconClass),
    middleContainerClass: classes('middle-container', middleContainerClass),
  };
}
