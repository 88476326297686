import React, { useCallback, useState } from 'react';
import type { AppState, AppThunkDispatch } from 'src/store';
import { commentsSelectors } from './Comments.slice';
import { connect } from 'react-redux';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ServiceContainer from 'src/ServiceContainer';
import { IconButton, ListItemIcon } from '@material-ui/core';
import { deleteComment } from './Comments.actions';

interface CommentOwnProps {
  commentId: string,
  index: number
}

const mapStateToProps = (state: AppState, ownProps: CommentOwnProps) => {
  const comment = commentsSelectors.selectById(state.comments, ownProps.commentId)!;
  return { comment, commentsAsyncState: state.comments.commentsAsyncState };
};
type CommentValueProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToDrops = (dispatch: AppThunkDispatch) => {
  return {
    dispatchedDeleteComment: (commentId: string) => dispatch(deleteComment(commentId))
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: 'inline'
    },
    center: {
      justifyContent: 'center'
    },
    // this isn't an official key, this object takes any key and makes a class out of it
    MuiListItemIcon: {
      minWidth: 'unset'
    },
    commentsRoot: {
      // allows newlines to be rendered correctly in comments
      whiteSpace: 'pre-line'
    }
  })
);

type CommentProps = CommentValueProps & ReturnType<typeof mapDispatchToDrops> & CommentOwnProps
const Comment = (props: CommentProps) => {
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const { commentId, dispatchedDeleteComment, commentsAsyncState } = props;
  const classes = useStyles();

  const handleClickDeleteComment = useCallback(async () => {
    if (!deleteDisabled) { // block multiple concurrent deletes of the same comment
      setDeleteDisabled(true);
      await dispatchedDeleteComment(commentId);
      setDeleteDisabled(false);
    }
  }, [commentId, deleteDisabled, dispatchedDeleteComment]);

  let idElem = (<React.Fragment />);
  if (props.comment.author.email) {
    idElem = (
      <Typography
        component="span"
        variant="body2"
        className={classes.inline}
        color="textPrimary">
        <a href={'mailto:' + props.comment.author.email}>{props.comment.author.name || props.comment.author.email}</a>
      </Typography>);
  } else if (props.comment.author.name) {
    idElem = (<Typography
      component="span"
      variant="body2"
      className={classes.inline}
      color="textPrimary">
      {props.comment.author.name}
    </Typography>);
  }

  return (
    <>
      {props.index > 0 ? <Divider variant="inset" component="li" /> : null}
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" />
        </ListItemAvatar>

        <ListItemText
          classes={{
            root: classes.commentsRoot
          }}
          primary={
            <React.Fragment>
              {props.comment.content} <br />
              — {idElem}
            </React.Fragment>
          }
        />
        {commentsAsyncState === 'loadedScope' ?
          <ListItemIcon className={classes.MuiListItemIcon} onClick={handleClickDeleteComment}>
            <IconButton aria-label="delete" size={'small'} disabled={deleteDisabled}>
              <i className="fal fa-fw fa-trash" />
            </IconButton>
          </ListItemIcon> : null}
      </ListItem>
    </>
  );
};


export const DummyComment = () => {
  const classes = useStyles();
  return (
    <ListItem alignItems="flex-start" className={classes.center}>
      <Typography
        component="span"
        variant="body2"
        className={classes.inline}
        color="textPrimary"
      >
        No Comments
      </Typography>
    </ListItem>
  );
};

export default connect(mapStateToProps, mapDispatchToDrops)(Comment);
