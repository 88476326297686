import { style } from 'typestyle';

export default style({
  $nest: {
    '&>*:first-child': {
      $nest: {
        '& .ag-root-wrapper': {
          $nest: {
            '& .ag-status-bar': {
              border: 0,
            },
            '& .ag-root-wrapper-body': {
              $nest: {
                '& .ag-root': {
                  $nest: {
                    '& .ag-header': {
                      border: 0,
                      $nest: {
                        '& .ag-header-viewport': {
                          $nest: {
                            '& .ag-header-container': {
                              $nest: {
                                '& .ag-header-row': {
                                  $nest: {
                                    '& .ag-header-cell': {
                                      $nest: {
                                        '& .ag-cell-label-container': {
                                          $nest: {
                                            '& .ag-header-cell-label': {
                                              $nest: {
                                                '& .ag-header-cell-text': {},
                                              },
                                            },
                                            '& .ag-header-icon': {
                                              $nest: {
                                                '& .ag-icon': {},
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    '& .ag-body-viewport': {
                      $nest: {
                        '& .ag-pinned-left-cols-container': {
                          $nest: {
                            '& .ag-row': {
                              $nest: {
                                '& .ag-cell': {
                                  border: 0,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
