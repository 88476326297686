import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
export const STRIPHEIGHT = 250;

const carouselBtn = style({
  float: 'right',
  fontSize: '1em',
  color: TEAL_PRIMARY,
  textDecoration: 'underline',
  paddingRight: 3,
  cursor: 'pointer',
});
const imgWidth = 100;
const imgHeight = 150;
const detailsStrip = style({
  $debugName: 'detailsStrip',
  height: 250,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  $nest: {
    '.imageContainer .image': {
      display: 'block',
      marginLeft: 'calc(50% - 50px)',
    },

    '.imageContainer': {
      width: imgWidth,
    },

    '.sectionsContainer': {
      paddingLeft: '20px',
      height: '100%',
      width: `calc(100% - ${imgWidth}px)`,
      paddingTop: '10px',
    },

    '.sectionTile': {
      height: '33%',
      float: 'left',
      width: '20%',
      fontSize: '12px',
      backgroundColor: '#f1f1f1',
      transition: 'all 0.2s ease',
      border: '2px solid #fff',
    },

    '.tileTitle': {
      fontWeight: 300,
      fontSize: '12px',
      padding: '5px 10px 0 10px',
      color: '#656565',
    },

    '.tileText': {
      fontSize: '18px',
      fontWeight: 400,
      paddingLeft: '10px',
      paddingTop: '2px',
      color: '#292929',
    },
    '.detailStripTitle': {
      height: '15%',
    },
  },
});

export default {
  detailsStrip,
  carouselBtn,
  imgWidth,
  imgHeight,
};
