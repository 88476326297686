import { isEmpty, pick } from 'lodash';
import { createSelector, OutputSelector } from 'reselect';
import { remapStyleToStyleColor } from 'src/components/CompanionList/CompanionList.client';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { combineSummaryInputs } from 'src/pages/Hindsighting/StyleColorReview/CanvasView/CanvasView.selectors';
import { styleEditLens } from 'src/services/lenses/lenses';
import { getDataToRender } from 'src/services/pivotServiceCache';
import { AppState } from 'src/store';
import { externalGridSearchFields, ID } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { Pivot } from 'src/worker/pivotWorker.types';
import { StyleEditSlice } from './StyleEdit.slice';

const getPivotData = (state: AppState) => {
  const styleEdit = styleEditLens.get(state);

  return getDataToRender(styleEdit);
};

const getViewDefn = (state: AppState) => {
  const styleEdit = styleEditLens.get(state);
  return styleEdit.viewDefn;
};

const getRollupDefn = (state: AppState) => {
  const styleEdit = styleEditLens.get(state);
  return styleEdit.rollupViewDefn;
};

const getSubheader = (state: AppState) => {
  return state.subheader;
};

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export function getGroupedAndSummary(
  styles: Pivot,
  viewDefn: PropType<StyleEditSlice, 'viewDefn'>,
  rollupViewDefn: PropType<StyleEditSlice, 'rollupViewDefn'>,
  subheader: SubheaderSlice
) {
  const summary = combineSummaryInputs(ID, styles.tree, rollupViewDefn, subheader);
  const configuredSearchKeys = !isEmpty(viewDefn) ? viewDefn.searchIndexes : [];
  const searchKeys = [...configuredSearchKeys, ...externalGridSearchFields];

  const filteredStyles = filterData(
    remapStyleToStyleColor(styles.flat),
    subheader.search,
    searchKeys,
    subheader.flowStatus
  );

  return {
    filteredStyles,
    summary,
    searchKeys,
    viewDefn,
    companionFilters: pick(subheader, ['search', 'flowStatus']),
    previewFilters: pick(subheader, ['altSearch', 'altFlowStatus', 'sortBy']),
  };
}
export interface ProcessedDataSelector
  extends OutputSelector<AppState, ReturnType<typeof getGroupedAndSummary>, typeof getGroupedAndSummary> {}

export const getProcessedData: ProcessedDataSelector = createSelector(
  getPivotData,
  getViewDefn,
  getRollupDefn,
  getSubheader,
  getGroupedAndSummary
);
