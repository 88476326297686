import { createSelector } from 'reselect';
import { parseGridConfig, FrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { AppState } from 'src/store';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { filterData } from 'src/utils/Pivot/Filter';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { QuickReconcileSlice } from './QuickReconcile.slice';
import { pick } from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { ColDef } from '@ag-grid-community/core';
import { listDataTreeToAgFlatTree } from 'src/utils/Component/AgGrid/AgDataFormat';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { FloorsetViewItem } from './QuickReconcile';

export interface StateSelection extends QuickReconcileSlice {}

function selectState(state: AppState): StateSelection {
  return {
    ...state.quickReconcile,
  };
}

export interface LoadingProjection {
  error: boolean;
  loaded: false;
  groupBy: GroupBySlice;
  floorset: string;
  isViewDataLoading: boolean | undefined;
  floorsetEnabled?: boolean;
  floorSetsData: FloorsetViewItem[];
  selectedFloorsetIndex: number;
}

export interface LoadedProjection {
  error: boolean;
  loaded: true;
  rowHeight?: number;
  groupBy: GroupBySlice;
  floorset: string;
  treeColumnDefinition: ColDef | undefined;
  gridData: BasicPivotItem[];
  colDefs: ColDef[];
  frameworkComponents: FrameworkComponents;
  isPrintMode?: boolean;
  isViewDataLoading: boolean | undefined;
  floorsetEnabled?: boolean;
  floorSetsData: FloorsetViewItem[];
  selectedFloorsetIndex: number;
}

export type StateProjection = LoadedProjection | LoadingProjection;

export function projectState(stateSelection: StateSelection): StateProjection {
  const {
    groupBy,
    floorset,
    areViewDefnsLoading,
    isViewDataLoading,
    viewData,
    viewDefns,
    floorsetsData,
    selectedFloorsetIndex,
    error,
  } = stateSelection;
  const search = '';
  if (!areViewDefnsLoading && !isViewDataLoading && viewData && viewDefns) {
    const parseResult = pick(parseGridConfig(viewDefns.gridDefn), 'colDefs', 'frameworkComponents', 'rowHeight');

    let gridItems;
    let treeColumnDefinition;

    if (groupBy.selection) {
      const groupingField = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
      const result = listDataTreeToAgFlatTree([groupingField], search, [], viewData.gridData);
      treeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else {
      // No Group By
      gridItems = filterData(viewData.gridData, search, externalGridSearchFields, []);
    }
    return {
      error,
      loaded: true,
      groupBy: groupBy,
      floorset: floorset,
      floorSetsData: floorsetsData,
      treeColumnDefinition,
      gridData: gridItems,
      selectedFloorsetIndex,
      isViewDataLoading: isViewDataLoading,
      floorsetEnabled: viewDefns.gridDefn.floorsetDefn && viewDefns.gridDefn.floorsetDefn.enabled,
      ...parseResult,
    };
  }

  return {
    error,
    loaded: false,
    groupBy: groupBy,
    isViewDataLoading: isViewDataLoading,
    floorset: floorset,
    floorSetsData: floorsetsData,
    selectedFloorsetIndex,
  };
}

export default createSelector(selectState, projectState);
