import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from 'src/components/TopNavbar/TopNavbar.styles';
import {
  BoundEnabledPerspective,
  BoundPerspective,
  DisabledBubble,
  RouteBubble,
  Bubble,
  Perspective,
} from 'src/services/configuration/codecs/bindings.types';
import { resolvePath } from 'src/cdn';
import {
  containerStyle,
  disabledStyle,
  brandLogoCentered,
} from 'src/pages/PerspectiveSelection/PerspectiveSelection.styles';
import { isNil } from 'lodash';
import RestoreSession from 'src/components/RestoreSession/RestoreSession.container';
import {
  getSessionPage,
  getSessionPerspective,
  removeSessionAll,
  setSessionPerspective,
} from 'src/components/RestoreSession/RestoreSession.utils';
import blackTextLogo from 'src/images/S5Logo-Black-Inovation-Centered.png';
import brandLogoPath from 'src/images/S5Logo-White-NoInnovation-Centered.png';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { viewPath } from 'src/dao/tenantConfigClient';
import Axios from 'src/services/axios';
import TopNavIconLink from 'src/components/TopNavbar/TopNavIconLink';
import { logout } from 'src/services/auth/platform';
import { Props } from './PerspectiveSelection.container';
import TopNavIconPopover, { TopNavIconPopoverMenuItem } from 'src/components/TopNavbar/TopNavIconPopover';
import serviceContainer from '../../ServiceContainer';
import { useDispatch } from 'react-redux';
import { setActivePerspective } from '../NavigationShell/NavigationShell.slice';
import { HasPerspectives, HasBindings, getPerspectiveLookupKey } from 'src/services/configuration/service';
import { Icon, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { style } from 'typestyle';
import { computePerspectiveRoute } from '../NavigationShell/navigationUtils';

const BrandLogo = resolvePath(brandLogoPath);

export const isPerspective = (value: BoundPerspective): value is BoundPerspective => {
  return value.type && ['Perspective', 'DisabledBubble', 'Bubble', 'RouteBubble'].includes(value.type);
};
export const isEnabledPerspective = (value: BoundPerspective): value is BoundEnabledPerspective => {
  return value.type && ['Perspective'].includes(value.type);
};
export const isEnabledPerspectiveOrBubble = (value: BoundPerspective): value is BoundEnabledPerspective => {
  return value.type && ['Perspective', 'Bubble'].includes(value.type);
};
export interface PerspectiveConfig {
  type: string;
  view: BoundPerspective[];
}

export const getPerspectiveConfig = () => {
  return Axios.get<PerspectiveConfig>(`${viewPath}/${ASSORTMENT}/Perspectives`);
};

const RoutePerspective = (perspective: RouteBubble, index: number): JSX.Element => {
  return (
    <Link onClick={removeSessionAll} key={index} data-id={perspective.title} to={perspective.route}>
      <div>
        <span className={'fa-stack fa-3x'}>
          <i className={perspective.iconSrc} />
        </span>
      </div>
      <label>{perspective.title}</label>
    </Link>
  );
};

const PerspectiveWithChildren = (bubble: Bubble) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <section data-id="perspectiveWithChildren" onClick={() => setExpanded(!expanded)}>
      <div>
        <span className={'fa-stack fa-3x'}>
          <i className={bubble.iconSrc} />
        </span>
      </div>
      <ListItem dense={true} style={{ alignItems: 'normal', justifyContent: 'center' }}>
        <label style={{ marginRight: '0.4em' }}>{bubble.title}</label>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expanded}>
        <List dense={true} disablePadding={true}>
          {bubble.view.map((b) => {
            const enabledPerspective = b.perspective as BoundEnabledPerspective;
            return (
              <NavLink
                to={computePerspectiveRoute(serviceContainer.configService.getBindings(enabledPerspective))}
                key={enabledPerspective.title}
                style={{ margin: 0 }}
              >
                <ListItem key={enabledPerspective.title} dense={true} disableGutters={true}>
                  <ListItemIcon style={{ alignItems: 'center' }}>
                    <Icon
                      className={enabledPerspective.iconSrc}
                      classes={{ root: style({ border: 'none !important', width: '30px', height: 'auto' }) }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={enabledPerspective.title} disableTypography />
                </ListItem>
              </NavLink>
            );
          })}
        </List>
      </Collapse>
    </section>
  );
};

const DisabledPerspective = (perspective: DisabledBubble, index: number): JSX.Element => {
  return (
    <Link onClick={removeSessionAll} key={index} to={'/'}>
      <div className={disabledStyle}>
        <span className={'fa-stack fa-3x disabled'}>
          <i className={perspective.iconSrc} />
        </span>
      </div>
      <label className={disabledStyle}>{perspective.title}</label>
    </Link>
  );
};

const EnabledPerspective = (perspective: BoundEnabledPerspective, index: number): JSX.Element => {
  const dispatch = useDispatch();
  const perspectiveLookupKey = getPerspectiveLookupKey(perspective) as Perspective;
  return (
    <Link
      onClick={() => {
        removeSessionAll();
        dispatch(setActivePerspective(perspective));
        setSessionPerspective(perspectiveLookupKey);
      }}
      key={index}
      data-id={perspectiveLookupKey}
      to={computePerspectiveRoute(serviceContainer.configService.getBindings(perspective))}
    >
      <div>
        <span className={'fa-stack fa-3x'}>
          <i className={perspective.iconSrc} />
        </span>
      </div>
      <label>{perspective.title}</label>
    </Link>
  );
};

const BubbleToChildBubble = (bubble: BoundPerspective, index: number): JSX.Element => {
  switch (bubble.type) {
    case 'Bubble':
      return PerspectiveWithChildren(bubble);
    case 'RouteBubble':
      return RoutePerspective(bubble, index);
    case 'DisabledBubble':
      return DisabledPerspective(bubble, index);
    case 'Perspective':
      return EnabledPerspective(bubble, index);
    default:
      return <div>Invalid bubble config</div>;
  }
};

/* eslint-disable */
const PerspectiveSelection = (config: HasPerspectives & HasBindings) => (props: Props) => {
  const config = serviceContainer.configService;
  const sessionPage = getSessionPage();
  const sessionPerspective = getSessionPerspective();
  const hasLocalStorage = !isNil(sessionPage) && !isNil(sessionPerspective);
  const perspectives = config.getAllPerspectives();

  useEffect(() => {
    props.resetScope();
  }, [props]);

  const userDropdownMenuItems: TopNavIconPopoverMenuItem[] = [
    {
      text: 'Logout',
      qaString: 'Logout',
      onClick: logout,
    },
  ];

  return (
    <div style={{ textAlign: 'center' }}>
      <nav className={`bg-dark ${styles.mainNavBar}`} data-qa="TopNav">
        <span className="logo-container">
          <Link to="/">
            <img className="logo" src={BrandLogo} alt="" data-qa="TopNavHomeIcon" />
          </Link>
        </span>
        <div className={styles.topNav}>
          <span className="buttons">
            <TopNavIconLink
              iconClass="fas fa-question-circle"
              href="http://s5stratoshelp.helpdocsonline.com/home/"
              target="_blank"
              data-qa="TopNavHelp"
            />
            <span className="text-light">
              <TopNavIconPopover
                icon={'fas fa-user user'}
                iconQaString={'User'}
                dropdownItems={userDropdownMenuItems}
              />
            </span>
          </span>
        </div>
      </nav>
      <img className={brandLogoCentered} src={blackTextLogo} alt="" />
      <div className={containerStyle}>
        {perspectives.map((persp, i) => !persp.hidden && BubbleToChildBubble(persp, i))}
      </div>
      {/* @ts-ignore fix router types later */}
      {hasLocalStorage && <RestoreSession />}
    </div>
  );
};

export default PerspectiveSelection;
