import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TopMembers } from 'src/services/Scope.client';

export interface SettingsState {
  sidebarExpanded: boolean;
  lastScopeCreated: Record<string, TopMembers>;
}

const initialState: SettingsState = {
  sidebarExpanded: true,
  lastScopeCreated: {} as Record<string, TopMembers>,
};

const uiPoseSlice = createSlice({
  name: 'uiPose',
  initialState: initialState,
  reducers: {
    setSidebarExpanded: (state) => {
      state.sidebarExpanded = true;
    },
    setSidebarNotExpanded: (state) => {
      state.sidebarExpanded = false;
    },
    toggleSidebarExpanded: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    setLastScopeCreated: (state, action: PayloadAction<[string, TopMembers]>) => {
      state.lastScopeCreated[action.payload[0]] = action.payload[1];
    },
  },
});

export const {
  setSidebarExpanded,
  setSidebarNotExpanded,
  toggleSidebarExpanded,
  setLastScopeCreated,
} = uiPoseSlice.actions;

export default uiPoseSlice.reducer;
