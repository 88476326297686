import React from 'react';
import { noop } from 'lodash';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import ReconcileModal from 'src/components/Reconcilation/ReconcileModal';
import { QuickReconButton } from 'src/services/configuration/codecs/confdefn';

interface ReconcileLinkProps {
  title: string;
  conf: QuickReconButton;
  onOpenQuickReconcile(): void;
  showQuickReconcile?: boolean;
  onCloseQuickReconcile(): void;
}

export const ReconcileLink = ({
  conf,
  title,
  onOpenQuickReconcile,
  showQuickReconcile,
  onCloseQuickReconcile,
}: ReconcileLinkProps) => {
  const handleOpen = () => {
    onOpenQuickReconcile();
  };
  const handleOnClose = () => {
    onCloseQuickReconcile();
  };
  return (
    <React.Fragment>
      <IconLink id="ReconcileLink" iconClass="fa fa-dollar-sign" onClick={handleOpen} text={title} />
      <ReconcileModal
        onToggleModal={handleOnClose}
        onReset={noop}
        isOpen={showQuickReconcile}
        enabled={true}
        quickReconButtonConfig={conf}
      />
    </React.Fragment>
  );
};
