import React from 'react';
import { Card, CardActions, CardContent, makeStyles, Slider, Switch, TextField, Typography } from '@material-ui/core';
import '@ag-grid-community/styles/ag-grid.css';
import { SmartPlanTarget } from '../../../state/scope/codecs/SmartPlan/SmartPlanTarget';

import 'src/pages/Mfp/Administration/_submission-wizard.scss';

const useStyles = makeStyles({
  cardRoot: {
    minHeight: '100%',
    minWidth: 200,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
});

// This component was started but left incomplete due to a change in requirements
// Left here in the event it is resurrected
export default function SmartPlanTargetEditor(target: SmartPlanTarget) {
  const classes = useStyles();
  switch (target.editor) {
    case 'toggle':
      // TODO: implement this
      const toggleValue = false;
      return (
        <Card className={classes.cardRoot} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              {target.text}
            </Typography>
            <Typography variant="body2" component="p">
              {target.infoText}
            </Typography>
          </CardContent>
          <CardActions>
            <Switch />
          </CardActions>
        </Card>
      );
    case 'slider':
      return (
        <Card className={classes.cardRoot} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              {target.text}
            </Typography>
            <Typography variant="body2" component="p">
              {target.infoText}
            </Typography>
          </CardContent>
          <CardActions>
            <Slider
              defaultValue={30}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks={true}
              min={-5}
              max={5}
            />
          </CardActions>
        </Card>
      );
    case 'inputPercent':
      return (
        <Card className={classes.cardRoot} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              {target.text}
            </Typography>
            <Typography variant="body2" component="p">
              {target.infoText}
            </Typography>
          </CardContent>
          <CardActions>
            <TextField
              id="standard-number"
              label="Percent"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CardActions>
        </Card>
      );
    default:
      return <p>input Not Found, likely a view config error</p>;
  }
}
