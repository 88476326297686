import { createSelector, OutputSelector } from 'reselect';
import { AppState } from 'src/store';
import { MemberItem, LevelItem, DimensionConfigResponse, LevelInfo } from 'src/types/Scope';
import { AssortmentScopeSelectorSlice } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';

export interface DimensionMap {
  [key: string]: LevelItem & {
    members: MemberItem[];
  };
}

export interface DimensionSelector
  extends OutputSelector<AppState, DimensionMap, (res: DimensionConfigResponse | null) => DimensionMap> {}

export interface LevelSelector
  extends OutputSelector<
    AppState,
    LevelItem[],
    (res: DimensionConfigResponse | null, scopeSlice?: AssortmentScopeSelectorSlice) => LevelItem[]
  > {}

const getDimensionConfigResponse = (state: AppState) => {
  return state.scope.dimensionConfig;
};

function createDimensionSelector(levelInfos: LevelInfo[]) {
  const pds = {} as DimensionMap;
  levelInfos.forEach((levelInfo) => {
    const { level, members } = levelInfo;
    pds[levelInfo.level.id] = {
      ...level,
      members,
    };
  });
  return pds;
}

export const getProductMapSelector: DimensionSelector = createSelector(getDimensionConfigResponse, (config) => {
  if (config) {
    return createDimensionSelector(config.product.levelInfos);
  }
  return {};
});

export const getLocationMapSelector: DimensionSelector = createSelector(getDimensionConfigResponse, (config) => {
  if (config) {
    return createDimensionSelector(config.location.levelInfos);
  }
  return {};
});

export const getProductLevels: LevelSelector = createSelector(getDimensionConfigResponse, (config) => {
  let levels: LevelItem[] = [];
  if (config) {
    levels = config.product.levelInfos.map((levelInfo: LevelInfo) => {
      return levelInfo.level;
    });
  }
  return levels;
});

export const getLocationLevels: LevelSelector = createSelector(getDimensionConfigResponse, (config) => {
  let levels: LevelItem[] = [];
  if (config) {
    levels = config.location.levelInfos.map((levelInfo: LevelInfo) => {
      return levelInfo.level;
    });
  }
  return levels;
});

export const getLocationMembers: LevelSelector = createSelector(getDimensionConfigResponse, (config) => {
  let levels: LevelItem[] = [];
  if (config) {
    levels = config.location.levelInfos.map((levelInfo: LevelInfo) => {
      return levelInfo.level;
    });
  }
  return levels;
});
