import { isEmpty, isNil, set } from 'lodash';
import {
  ApplyFavoriteOptions,
  FavoriteResponse,
  FavoriteSubheader,
  ValidatedFavoriteResponse,
} from 'src/components/Subheader/Favorites/Favorites.types';
import { AppState, AppThunkDispatch } from 'src/store';
import {
  maybeUpdateSortByDirection,
  updateConfigureSelections,
  updateCountLimit,
  updateFavorites,
  updateFlowStatus,
  updateGroupBy,
  updatePareDown,
  updateSortBy,
} from 'src/components/Subheader/Subheader.slice';
import { clearActiveAssortmentFavorite, getAssortmentFavoriteByDefnId } from './Favorites.client';
import { toastAndLog } from 'src/services/loggingService';

export function validateFavorites(favorites: FavoriteResponse[], validVersion: number): ValidatedFavoriteResponse[] {
  // inject valid flag onto each viewDefn
  return favorites.map((favResp) =>
    set<ValidatedFavoriteResponse>(favResp, 'jsonBlob.isValid', favResp.jsonBlob?.version === validVersion)
  );
}

export function applyFavoriteToSubheader({
  groupByDataIndexSelection,
  configureDataIndexSelections,
  sortByDataIndexSelection,
  sortByDirection,
  flowStatusSelections,
  pareDownValueSelections,
  limitSelection,
}: FavoriteSubheader) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const subheader = getState().subheader;
    const { groupBy, sortBy, configureSelections = [], pareDown } = subheader;

    if (!isNil(groupByDataIndexSelection)) {
      const selection = groupBy.options.findIndex((opt) => opt.dataIndex === groupByDataIndexSelection);
      dispatch(updateGroupBy(selection));
    }

    if (!isEmpty(configureDataIndexSelections)) {
      const selections = configureSelections.filter((opt) => configureDataIndexSelections.includes(opt.dataIndex));
      dispatch(updateConfigureSelections(selections));
    }

    if (!isNil(sortByDataIndexSelection)) {
      const selection = sortBy.options.findIndex((x) => x.dataIndex === sortByDataIndexSelection);
      dispatch(updateSortBy(selection));
    }

    dispatch(maybeUpdateSortByDirection(sortByDirection));

    if (!isEmpty(pareDownValueSelections)) {
      const selections = pareDown.options.filter((pdOpt) =>
        pareDownValueSelections.includes(pdOpt.value || pdOpt.dataIndex)
      );
      dispatch(updatePareDown(selections));
    }

    if (!isEmpty(flowStatusSelections)) {
      dispatch(updateFlowStatus(flowStatusSelections));
    }

    if (!isNil(limitSelection)) {
      dispatch(updateCountLimit(limitSelection));
    }
  };
}

/**
 * This function handles validating favorites & syncing the redux store favorites state with the server state.
 * It then attempts to setup the favorite values in global state (just subheader for now)
 * */
export function applyFavoriteToGlobals(options: ApplyFavoriteOptions) {
  const { defaultSubheader = null, favoriteDefnId = '_MISSINGDEFNID_', isDefault, validVersion } = options;

  // FIXME: instead of being passed favorites, which were cheated and modified on the client in Favorite cmp onApply handler
  // just retrieve the favorites from the server here, validate and return the active favorite (or null) from this function
  // this let's the component who invoked this fcn, do any cmp specific overriding logic at that point after the global state has been set
  // could rename this fcn to applyFavoriteToGlobals
  // sliceViewDefnUpdater could be renamed to applyFavoriteTo[Cmp]ViewDefn

  return async (dispatch: AppThunkDispatch, _getState: () => AppState) => {
    // retrieve favorites from server after applying a new favorite
    // to validate and keep store in sync with server state
    const favorites = await getAssortmentFavoriteByDefnId(favoriteDefnId);
    const validatedFavorites = validateFavorites(favorites, validVersion);
    const activeFavorite = validatedFavorites.find((x) => x.active);
    const isMissingActiveFavorite = isNil(activeFavorite) || isNil(activeFavorite.jsonBlob);
    const isDefaultSelected = !isNil(isDefault) ? isDefault : isMissingActiveFavorite;
    const isDefaultSubheaderPresent = !isNil(defaultSubheader);
    const isValidFavorite = activeFavorite?.jsonBlob.isValid;

    if (isMissingActiveFavorite || !isValidFavorite) {
      // make sure server is in sync with client state in the case of invalid favorite trying to be loaded
      if (!isValidFavorite && !isDefaultSelected) {
        const msg = `Unabled to load favorite, setting up defaults.`;
        toastAndLog(msg, msg);
        await clearActiveAssortmentFavorite(favoriteDefnId);
      }
      const syncedFavorites = await getAssortmentFavoriteByDefnId(favoriteDefnId);
      const validatedSyncedFavorites = validateFavorites(syncedFavorites, validVersion);
      dispatch(updateFavorites(validatedSyncedFavorites));
      if (isDefaultSelected && isDefaultSubheaderPresent) {
        dispatch(applyFavoriteToSubheader(defaultSubheader));
      }
      return;
    }

    const subheader = isNil(defaultSubheader) ? activeFavorite.jsonBlob.subheader : defaultSubheader;
    dispatch(updateFavorites(validatedFavorites));
    dispatch(applyFavoriteToSubheader(subheader));
  };
}
