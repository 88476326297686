import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';

const headerName = style({
  cursor: 'pointer',
  $nest: {
    ['&.level-1']: {
      fontWeight: 'bold',
      paddingLeft: 0,
    },
    ['.level-1']: {
      fontWeight: 'bold',
      paddingLeft: 0,
    },
    ['&.indent']: {
      paddingLeft: 45,
    },
    ['&.level-2:not(.indent)']: {
      paddingLeft: 25,
    },
    ['&.bold']: {
      fontWeight: 'bold',
    },
    ['&.italic']: {
      fontStyle: 'italic',
    },
    svg: {
      fontSize: '1rem',
      marginRight: '5px',
    },
    ['.worklistAdornment']: {
      fontSize: '1rem',
      marginRight: '5px',
    },
  },
});

const isOverride = style({
  color: '#ba0000',
  $nest: {
    '&.icon-disabled': {
      color: '#db958f',
    },
  },
});

const dataGridStyle = style({
  $debugName: 'data-grid-style',
  flex: 1,
  $nest: {
    ['.ag-cell.depth-1-last']: {
      borderRight: '1px solid #DDD',
    },
    ['.ag-cell-wrapper.ag-row-group']: {
      alignItems: 'inherit',
    },
    ['.ag-pinned-left-cols-container .ag-cell']: {
      justifyContent: 'left',
    },
    '.ag-cell': {
      textAlign: 'center',
      border: 0,
      justifyContent: 'center',
      overflow: 'visible',
      padding: 0, // Required to reset theme overrides
      $nest: {
        '& .flowsheet-cell-renderer': {
          overflowX: 'hidden',
        },
      },
    },
    // center overlay due to custom styles below on `.ag-react-container` in flowsheet/pricing
    '.ag-overlay .ag-react-container': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.ag-react-container': {
      margin: 'auto',
      width: '100%',
      height: '100%', // required when no data in editable cell, allows background to be displayed
    },
    '.ag-tool-panel': {
      display: 'none',
    },
    '*:focus-visible': {
      outline: 'none',
    },
    '.anchor-field': {
      backgroundColor: 'rgba(255,193,7,0.2)',
    },
    '.ag-row-group-indent-0': {
      display: 'flex',
      flexDirection: 'row',
      width: '98%',
      $nest: {
        '.ag-icon': {
          margin: 0,
          paddingLeft: 15,
          paddingRight: 15,
        },
        '> span': {
          flex: 'none',
        },
        '> span.ag-group-value': {
          flex: 1,
          textAlign: 'left',
          paddingLeft: 5,
        },
      },
    },
    '.ag-group-checkbox.ag-invisible': {
      display: 'none',
    },
    [`.ag-row:not(.ag-row-focus) .${headerName}.level-1`]: {
      color: TEAL_PRIMARY,
    },
    [`.ag-row:not(.ag-row-focus) .${headerName} .level-1`]: {
      color: TEAL_PRIMARY,
    },
    ['.ag-ltr .ag-group-expanded']: {
      marginRight: 0,
    },
    ['.ag-ltr .ag-group-contracted']: {
      marginRight: 0,
    },
  },
});

export const MUIStyles: any = {
  IconButton: {
    root: style({
      padding: 8.5,
      fontSize: 'unset !important',
      $nest: {
        '& *': {
          fontSize: 'unset !important',
          overflow: 'visible !important',
        },
      },
    }),
  },
};

export default {
  headerName,
  isOverride,
  dataGridStyle,
};
