import * as React from 'react';

export interface Props {
  hasFiltersSelected: boolean;
  areFiltersChanged: boolean;
  onClearAllFilters(): void;
  onSubmitFilters(): void;
}

export interface State {
  filters: {};
}

export default class FiltersHeader extends React.PureComponent<Props, State> {
  state: State = {
    filters: {},
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onClearAllFilters, onSubmitFilters, hasFiltersSelected, areFiltersChanged } = this.props;
    const clearClassNames = 'filter-button clear' + (hasFiltersSelected ? ' active' : '');
    const submitClassNames = 'filter-button submit' + (areFiltersChanged ? ' active' : '');
    return (
      <React.Fragment>
        <header className="d-flex flex-row justify-content-between">
          <div className="title-bar">
            <p className="title">Filters</p>
          </div>
        </header>
        <div className="main-action-container">
          <button
            onClick={onClearAllFilters}
            className={clearClassNames}
            disabled={!hasFiltersSelected}
            data-qa="clear-button"
          >
            <i className="far fa-ban" />
            Clear
          </button>
          <button onClick={onSubmitFilters} className={submitClassNames} data-qa="submit-button">
            <i className="far fa-check" />
            Submit
          </button>
        </div>
      </React.Fragment>
    );
  }
}
