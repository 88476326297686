import * as fp from 'lodash/fp';
import { debounce, isNil, size } from 'lodash';
import { connect } from 'react-redux';
import QuickReconcileView from './QuickReconcile';
import container from 'src/ServiceContainer';
import selectAndProjectState from './QuickReconcile.selectors';
import { ASSORTMENT, LEVEL_PROD_ROOT } from 'src/utils/Domain/Constants';
import { AnyAction as BaseAction } from 'redux';
import {
  receiveQuickReconcileData,
  requestQuickReconcileData,
  receiveError,
  cleanUp,
  setSelectedFloorsetQRIndex,
} from './QuickReconcile.slice';
import { AppState, AppThunkDispatch } from 'src/store';
import { StateProjection } from './QuickReconcile.selectors';
import { WrappedThunkDispatch, wrappedDispatch } from 'src/utils/Redux/Dispatch';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { updateQuickReconcileGroupBy, updateQuickReconcileFloorsetBy, setFloorsetsQR } from './QuickReconcile.slice';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { fetchFloorsetsAndSelect } from 'src/pages/AssortmentBuild/FloorsetComparison/FloorsetComparison.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { FloorsetViewItem } from './QuickReconcile';
import { QuickReconButton } from 'src/services/configuration/codecs/confdefn';

type QuickReconcileContainerProps = {
  floorsetApi: ClientDataApi;
  quickReconButtonConfig: QuickReconButton;
};

function mergeProps(
  mappedState: StateProjection,
  // wrapped dispatch that has thunks instead of regular actions
  dispatchProps: WrappedThunkDispatch,
  ownProps: QuickReconcileContainerProps
) {
  const { dispatch } = dispatchProps;
  const { floorsetApi, quickReconButtonConfig } = ownProps;
  const { defns, configApi } = quickReconButtonConfig;
  async function getData(_dispatch: AppThunkDispatch, getState: () => AppState) {
    const { floorsetsData, selectedFloorsetIndex } = getState().quickReconcile;
    if (size(floorsetsData) <= 0) {
      await dispatch(fetchFloorsetsAndSelect());
    }
    let selectedIndex = selectedFloorsetIndex;
    if (isNil(selectedIndex) || selectedIndex < 0) {
      selectedIndex = 0;
    }
    if (size(floorsetsData) <= 0) {
      return;
    }
    let floorsetId = '';
    floorsetId = floorsetsData[selectedIndex].id;
    const { pivotService } = container;
    const { groupBy, floorset } = getState().quickReconcile;

    // we are not using flow status on QR modal
    dispatch(requestQuickReconcileData());

    const dataOptions: ListDataOptions = {
      aggBy: LEVEL_PROD_ROOT,
    };
    if (groupBy) {
      dataOptions.aggBy = `${dataOptions.aggBy},${getGroupBySelectedOptionProperty(groupBy, 'dataIndex')}`;
    }

    pivotService
      .listData(defns.model, ASSORTMENT, {
        ...dataOptions,
        topMembers: floorset || floorsetId,
      })
      .then((data) => {
        return dispatch(
          receiveQuickReconcileData({
            gridData: data.tree[0].children,
          })
        );
      })
      .catch(() => dispatch(receiveError()));
  }

  async function maybeFetchData(_dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> {
    const state = getState();
    const { floorsetUpdated, groupByUpdated } = state.quickReconcile;

    if (floorsetUpdated || groupByUpdated) {
      getData(dispatch, getState);
    }
  }
  const debouncedFetch = debounce(() => dispatch(maybeFetchData), 200);
  return {
    ...mappedState,
    floorsetApi,
    configApi,
    onUpdateView() {
      // TODO fix this properly, subheader update + onShowView makes multiple calls shortly after mount
      debouncedFetch();
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    onRefetchData() {
      dispatch(getData);
    },
    onUpdateGroupBy(newGroup: number) {
      dispatch(updateQuickReconcileGroupBy(newGroup));
    },
    onUpdateFloorset(newFloorset: string, selectedIndex: number) {
      dispatch(updateQuickReconcileFloorsetBy(newFloorset));
      dispatch(setSelectedFloorsetQRIndex(selectedIndex));
    },
    onReceiveFloorSetQR(floorSetsData: FloorsetViewItem[]) {
      dispatch(setFloorsetsQR(floorSetsData));
    },
  };
}

const sensitiveView = fp.flow(() => QuickReconcileView, makePrintSensitive)();

export default connect(selectAndProjectState, wrappedDispatch, mergeProps)(sensitiveView);
