import { AppState } from 'src/store';
import { completeRefreshChart, requestChart, setChartDataStale } from './EditableChart.slice';
import { getScopeReadyData } from 'src/state/scope/Scope.types';
import { EditableChartOwnProps, EditableChartValueProps } from './EditableChart.types';

export const mapStateToProps = (state: AppState, _ownProps: EditableChartOwnProps): EditableChartValueProps => {
  const readyScope = getScopeReadyData(state.mfpScope);
  if (readyScope) {
    return {
      type: 'EditableChartReady',
      scopeId: readyScope.mainConfig.id,
      mainConfig: readyScope.mainConfig,
      chartDimensionConfig: state.chart.chartDimensionConfig,
      forceRefreshChart: state.chart.forceRefreshChart,
      settings: state.settings.entriesByKey,
    };
  }
  return;
};

export const mapDispatchToProps = {
  onCompleteRefreshChart: completeRefreshChart,
  setChartDataStale,
  requestChart: requestChart,
};
