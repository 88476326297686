import { ICrudEntry, ICrudInterface } from './ICrudInterface';

export abstract class AbstractInMemoryCrud<Entry extends ICrudEntry>
implements ICrudInterface<Entry> {
  protected entries: Entry[] = [];
  protected entriesMap: Map<string, Entry> = new Map();

  public load(entries: Entry[]): Promise<string[]> {
    this.entries = [];
    this.entriesMap = new Map();
    const promises = entries.map(entry => this.create(entry));
    return Promise.all(promises);
  }

  public create(e: Entry): Promise<string> {
    const index = this.entries.length;
    if (!e.key) {
      e.key = '' + index;
    }
    this.entries.push(e);
    this.entriesMap.set(e.key, e);
    return Promise.resolve(e.key);
  }

  public delete(key: string): Promise<boolean> {
    const entry = this.entriesMap.get(key);
    if (!entry) {
      return Promise.reject('Entry doesn\'t exist');
    }
    this.entriesMap.delete(key);
    const entryIndex = this.entries.indexOf(entry);
    if (entryIndex < 0) {
      return Promise.reject('Entry doesn\'t exist');
    }
    this.entries.splice(entryIndex, 1);
    return Promise.resolve(true);
  }

  public list(): Promise<Entry[]> {
    return Promise.resolve(this.entries);
  }

  public read(key: string): Promise<Entry> {
    return Promise.resolve(this.entriesMap.get(key)!);
  }

  public update(e: Entry): Promise<Entry> {
    if (!e.key) {
      return Promise.reject('Entry has no key');
    }
    const index = this.entries.findIndex(that => that.key === e.key);
    if (index < 0) {
      return Promise.reject('Entry key not found');
    }
    this.entries[index] = e;
    this.entriesMap.set(e.key, e);
    return Promise.resolve(e);
  }
}
