import { combineReducers } from 'redux';

import nestedAttributeReducer, {
  NestedAttributeSlice,
} from 'src/pages/Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.slice';

import productDetailsReducer, {
  ProductDetailsSlice,
} from 'src/pages/Hindsighting/AggregateViews/ProductDetails/ProductDetails.slice';

export interface AggregateViewsSlice {
  nestedAttribute: NestedAttributeSlice;
  productDetails: ProductDetailsSlice;
}

export default combineReducers({
  nestedAttribute: nestedAttributeReducer,
  productDetails: productDetailsReducer,
});
