import { isNil } from 'lodash';
import { resolvePath } from 'src/cdn';
import store from 'src/store';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import noImagePath from 'src/common-ui/images/noimage.jpg';
const noImage = resolvePath(noImagePath);
// TODO add types for this
// @ts-ignore
import watermark from 'watermarkjs';

function loadUrl(url: string, init?: (img: HTMLImageElement) => void) {
  const img = new Image();
  typeof init === 'function' && init(img);
  return new Promise(function(resolve, reject) {
    img.crossOrigin = 'anonymous';
    img.onload = function() {
      return resolve(img);
    };
    img.onerror = function() {
      return reject('Failed to load image.');
    };
    img.src = url;
  });
}
const rotate = (target: HTMLCanvasElement) => {
  const state = store.getState();
  const placeholderConfig = state.appConfig.tenantConfig.placeholder;
  const context = target.getContext('2d');
  if (context == null) {
    throw 'Somehow canvas does not have context?!';
  }
  const text = placeholderConfig.text;
  const M = 'M';
  let tooBig = true;
  let fontSize = 48;
  let x = 0,
    y = 0;
  while (tooBig || fontSize <= 2) {
    context.fillStyle = 'black';
    context.font = `${fontSize}px Open Sans`;
    const heightMetric = context.measureText(M).width;
    const widthMetric = context.measureText(text).width;
    x = target.width / 2 - ((widthMetric / 2) * 0.33 + (heightMetric / 2) * 0.66);
    y = target.height / 2 + ((widthMetric / 2) * 0.66 + (heightMetric / 2) * 0.33);
    if (widthMetric <= target.width && heightMetric <= target.height) {
      tooBig = false;
    } else {
      fontSize -= 2;
    }
  }

  context.translate(x, y);
  context.globalAlpha = 0.9;
  context.rotate((-60 * Math.PI) / 180);

  const drawStroked = (t: string, a: number, b: number) => {
    context.strokeStyle = 'white';
    context.lineWidth = 8;
    context.strokeText(t, a, b);
    context.fillStyle = 'black';
    context.fillText(t, a, b);
  };
  drawStroked(text, 0, 0);
  return target;
};

export interface ImageBlobMeta {
  blob: Blob;
  mime: string;
}

export function dataURItoBlob(dataURI: string): ImageBlobMeta {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const imgAsArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    imgAsArray[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  const blob = new Blob([dataView], { type: mimeString });
  return {
    blob,
    mime: mimeString.split('/')[1],
  };
}

export const watermarkImageAsDataURI = async (img: string): Promise<string | null> => {
  // const text = watermark.text;
  return new Promise((resolve, reject) => {
    try {
      const image = Promise.all([loadUrl(img)]);
      image.then(
        (res) => {
          watermark(res)
            .dataUrl(rotate)
            .then((res: string) => resolve(res));
        },
        (_err) => {
          reject(null);
        }
      );
    } catch (e: any) {
      toast.error(`An error occured loading image`);
      ServiceContainer.loggingService.error(`An error occured: ${e.message}`);
      resolve(null);
    }
  });
};

export const watermarkBlob = async (blob: Blob): Promise<string | null> => {
  // const text = watermark.text;
  const imgOrNoImg = isNil(blob) ? noImage : blob;
  return new Promise((resolve, reject) => {
    try {
      watermark([imgOrNoImg], {
        init: (imgSetting: { crossOrigin: string }) => {
          imgSetting.crossOrigin = 'anonymous';
        },
      })
        .dataUrl(rotate)
        .then((res: string) => resolve(res));
    } catch (e) {
      reject(null);
    }
  });
};

export const watermarkImageAsBlob = async (img: string): Promise<ImageBlobMeta | null> => {
  const str: string | null = await watermarkImageAsDataURI(img)
    .catch((_er) => {
      return watermarkImageAsDataURI(noImage);
    })
    .catch((_er) => null);
  return new Promise((resolve, reject) => {
    try {
      if (str == null) {
        reject(null);
        return;
      }
      resolve(dataURItoBlob(str));
    } catch (e) {
      reject(null);
    }
  });
};

export const imageAsDataUri = async (img: string): Promise<string | null> => {
  const imgOrNoImg = isNil(img) || img === '' ? noImage : img;
  return new Promise((resolve, reject) => {
    try {
      watermark([imgOrNoImg], {
        init: (imgSetting: { crossOrigin: string }) => {
          imgSetting.crossOrigin = 'anonymous';
        },
      })
        .dataUrl((target: string) => target)
        .then((res: string) => resolve(res));
    } catch (e) {
      reject(null);
    }
  });
};

export const imageAsBlob = async (img: string): Promise<ImageBlobMeta> => {
  const str = await imageAsDataUri(img);
  return new Promise((resolve, reject) => {
    try {
      if (str == null) {
        reject(null);
        return;
      }
      resolve(dataURItoBlob(str));
    } catch (e) {
      reject(null);
    }
  });
};
