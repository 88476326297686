import { style } from 'typestyle';

const mainBody = style({
  height: 600,
  width: '100%',
});
const childrenContainer = style({
  height: '50%',
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'space-around',
});
const childContainer = style({
  width: '33.3%',
});

export default {
  mainBody,
  childrenContainer,
  childContainer,
};
