import * as React from 'react';
import { dialogContent, dialogTitle, modal } from './ConfirmationModal.styles';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
export interface ConfirmationModalProps {
  isOpen: boolean;
  descriptionText: string;
  onConfirm?(): void;
  onCancel?(): void;
}

export default class ConfirmationModal extends React.Component<ConfirmationModalProps> {
  constructor(props: ConfirmationModalProps) {
    super(props);
  }

  onModalClose(confirmed: boolean) {
    if (confirmed) {
      if (this.props.onConfirm) {
        this.props.onConfirm();
      }
    } else {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    }
  }

  render() {
    const { isOpen, descriptionText } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={() => this.onModalClose(false)}
        classes={{ root: modal + ' configure-modal' }}
        maxWidth={'lg'}
      >
        <DialogTitle classes={{ root: dialogTitle }}>
          <div>
            <span className="left-container">
              <i className="fal fa-question-circle icon" />
              {'Confirm'}
            </span>
            <span className="right-container">
              <i className="far fa-times" onClick={() => this.onModalClose(false)} />
            </span>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: dialogContent }}>
          <p className="instructions">{descriptionText}</p>
          <footer>
            <button onClick={() => this.onModalClose(true)} className="apply">
              <i className="far fa-check" />
              Apply
            </button>
            <button onClick={() => this.onModalClose(false)} className="reset">
              <i className="fas fa-ban" />
              Cancel
            </button>
          </footer>
        </DialogContent>
      </Dialog>
    );
  }
}
