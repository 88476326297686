import React from 'react';
import styles, {
  gridClass,
  container,
  companionStyles,
  sizeRangeTitle,
  highlightedCell,
} from './SizeEligibilityListGrid.styles';
import '@ag-grid-enterprise/core';
import { SeverityRenderer } from 'src/components/SeverityRenderer/SeverityRenderer';
import { StarPercentRenderer } from 'src/components/StarPercentRenderer/StarPercentRenderer';
import Subheader from 'src/components/Subheader/Subheader.container';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import {
  ColDef,
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ValueSetterParams,
  CellValueChangedEvent,
  ValueFormatterParams,
  IRowNode,
} from '@ag-grid-community/core';
import {
  ProjectedState,
  SizeEligibilityGridRow,
  SizeEligibilityListGridDispatchProps,
} from './SizeEligibilityListGrid.container';
import CheckboxCellRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/SafeCheckbox';
import { CompanionListView, Overlay } from 'src/common-ui/index';
import { Props as CompanionProps } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { noImage } from 'src/pages/AssortmentBuild/AssortmentAdd/AssortmentAddView.container';
import { getSizeEligibilityClient } from 'src/dao/SizeEligibility.client';
import CheckboxHeaderRenderer from 'src/components/CheckboxHeaderRenderer/CheckboxHeaderRenderer';
import {
  isEqual,
  indexOf,
  without,
  union,
  isNil,
  findIndex,
  slice,
  assign,
  concat,
  max,
  mapValues,
  forEach,
  merge,
  debounce,
  isArray,
  size,
  isEmpty,
  isUndefined,
} from 'lodash';
import { editableCell, headerCell } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.styles';
import { produce, createDraft } from 'immer';
import ValidValuesEditor, {
  ValidValuesEditorProps,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import { classes, style } from 'typestyle';
import { ConfigurableGridConfigItem } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import IntegerEditor, {
  IntegerEditorProps,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import { SubheaderDropdownProps } from 'src/components/Subheader/SubheaderDropdown';
import { companionDataParse } from 'src/components/ListGridPair/ListGridPair.utils';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { Renderer } from 'src/utils/Domain/Renderer';
import { gridContainerStyle, listPairStyle } from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { filterData } from 'src/utils/Pivot/Filter';
import TooltipRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import ArrowValueRenderer from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import { logError } from 'src/services/loggingService';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export interface SizeEligibilityListGridProps extends ProjectedState, SizeEligibilityListGridDispatchProps {}

interface SizeEligibilityListGridState {
  companionSortDirection: SortByDirection;
  companionSortField: string;
  sizeHeaderStatus: boolean;
  gridDataLoaded: boolean;
  dropdownSelection: number;
  companionSearch: string;
}

interface StringObject {
  [k: string]: string;
}

const defaultColDef: ColDef & { flex: number } = {
  // suppressMovable: true,
  lockPinned: true,
  lockPosition: true,
  // resizable: false,
  filter: true,
  sortable: true,
  flex: 1,
};

const frameworkComponents = {
  severityRenderer: SeverityRenderer,
  starRenderer: StarPercentRenderer,
  cboxRenderer: CheckboxCellRenderer,
  gridHeaderCheckbox: CheckboxHeaderRenderer,
  validValuesEditor: ValidValuesEditor,
  integerEditor: IntegerEditor,
  floatEditor: IntegerEditor,
  tooltipRenderer: TooltipRenderer,
  arrowValueRenderer: ArrowValueRenderer,
};

const sizeHeaderColIdMagicString = 'sizeCheckbox_';

function disaggregateHeaderValue(eventData: CellValueChangedEvent, updateKeys: StringObject) {
  const { column, newValue, node, api, colDef } = eventData;
  const dataIndex = column.getColId();
  let parsedValue: unknown;
  if (colDef.cellEditor === 'integerEditor') {
    parsedValue = parseInt(newValue);
    if (isNaN(parsedValue as number)) return;
  } else if (colDef.cellEditor === 'floatEditor') {
    parsedValue = parseInt(newValue);
    if (isNaN(parsedValue as number)) return;
  } else {
    parsedValue = newValue;
  }
  if (isNil(parsedValue)) return;
  // should be node.allLeafChildren
  forEach(node.childrenAfterGroup, (child: IRowNode) => {
    child.setDataValue(dataIndex, parsedValue);
    // const field = colDef.field!;
    // const value = eventData[field];
    const strippedField = column
      .getColId()
      .replace('attribute:', '')
      .replace(':id', '')
      .replace(':name', '');
    getSizeEligibilityClient().updateAttributes(
      mapValues(updateKeys, (val) => {
        return child.data[val];
      }),
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        [strippedField]: parsedValue,
      }
    );
  });
  node.setDataValue(dataIndex, undefined);
  api?.redrawRows();
}
interface CompanionListSearchProps {
  search: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const CompanionListSearch = ({ search, onChange }: CompanionListSearchProps) => {
  return (
    <input
      type="text"
      className="form-control"
      placeholder="Search list..."
      aria-label="Search list"
      onChange={onChange}
      defaultValue={search}
      data-qa="CompanionListSearchInput"
    />
  );
};
export class SizeEligibilityListGrid extends React.Component<
  SizeEligibilityListGridProps & SizeEligibilityListGridDispatchProps,
  SizeEligibilityListGridState
> {
  gridApi?: GridApi;
  columnApi: ColumnApi | undefined = undefined;
  colDefs: ColDef[] | undefined = undefined;
  sizeEligibilityClient = getSizeEligibilityClient();

  debounceSearchUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  constructor(props: SizeEligibilityListGridProps & SizeEligibilityListGridDispatchProps) {
    super(props);
    // setAutoFreeze(false);
    this.state = {
      companionSortDirection: 'desc',
      companionSortField: '',
      sizeHeaderStatus: false,
      gridDataLoaded: false,
      dropdownSelection: 0,
      companionSearch: '',
    };
    this.debounceSearchUpdate = debounce(this.onCompanionSearch, 300);
    // this.handleChangeGroup = this.handleChangeGroup.bind(this);
  }

  componentDidMount(): void {
    this.props.onShowView(this.props.topProduct);
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  onCompanionSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      companionSearch: event.currentTarget.value,
    });
  };

  getAllRows() {
    // TODO
    const rowData: BasicPivotItem[] = [];
    this.gridApi?.forEachNode((node) => rowData.push(node.data));
    return rowData;
  }

  // useCurrentGridData should always be false, except when we need to recalculate the sizeheader states
  // after an in-grid edit, when we don't get fresh data back from props, and instead need to pull that data
  // from the current grid rows
  processSizeHeaderStati = (useCurrentGridData = false) => {
    if (!useCurrentGridData && isNil(this.props.gridData)) {
      return;
    }
    const gridData = !useCurrentGridData ? this.props.gridData : this.getAllRows();
    const { sizeNames } = this.props;
    if (isNil(gridData) || isNil(this.columnApi) || isNil(sizeNames)) return;
    const sizeHeaderStatus = sizeNames
      .map((size, _idx) => {
        // count all the 1s for a given size
        return gridData?.reduce((prev, curr) => {
          const hasSize = indexOf(curr.size_eligibility, size) >= 0 ? 1 : 0;
          return prev + hasSize;
        }, 0);
      })
      .map((num) => {
        // compare the count of 1s to the length of locations
        if (num === 0) {
          // no sizes, no check
          return 'off';
        } else if (num === this.props.gridData?.length) {
          return 'on';
        }
        return 'indeterminate';
      });

    // need to update the columns now
    const allColDefs = this.columnApi.getColumns()?.map((c) => c.getColDef());
    let startingInd = 0;
    allColDefs?.forEach((col, idx) => {
      if (!!col.headerComponentParams) {
        // idx is wrong here, need to filter to size cols then splice back into the coldef array
        if (col.headerComponentParams && col.refData) {
          col.headerComponentParams.checkedStatus = sizeHeaderStatus[idx - startingInd];
        }
      } else {
        startingInd++;
      }
    });
    // splice out the old cols and replace with the updated ones
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    try {
      this.gridApi?.refreshHeader();
    } catch (e) {
      // skip while we delay fix the data retention between views issue
    }
  };

  componentDidUpdate(prevProps: SizeEligibilityListGridProps): void {
    const { gridData, companionData } = this.props;
    const { sizeHeaderStatus } = this.state;
    if (gridData && !isEqual(gridData, prevProps.gridData)) {
      this.setState({
        gridDataLoaded: true,
      });
    }
    if (
      isUndefined(prevProps.companionData) &&
      isEqual(prevProps.companionData, companionData) &&
      isArray(companionData) &&
      size(companionData) > 0
    ) {
      const { selection, options } = this.props.subheader.groupBy;
      const maybeGroupBy =
        selection && options ? this.props.subheader.groupBy.options[selection].groupingKey : undefined;
      this.props.handleChangeSizeRange(
        { value: companionData[0].id, label: companionData[0].name },
        this.getSelectedFloorsetId(),
        maybeGroupBy
      );
    }
    /*if (!isEqual(prevProps.companionData, companionData) && companionData && companionData[0]) {
      this.props.handleChangeSizeRange(
        { value: companionData[0].id, label: companionData[0].name },
        this.getSelectedFloorsetId()
      );
    }*/
    if (this.props.gridData && this.gridApi && this.columnApi && !sizeHeaderStatus && !this.props.dataLoading) {
      // need to determine the checkbox status for the headers
      // note that status is a triple state
      this.processSizeHeaderStati();
      this.setState({ sizeHeaderStatus: true }); // TODO: reset this when change size ranges
    }
    if (!isEqual(this.props.sizeNames, prevProps.sizeNames) && !isEmpty(this.props.sizeNames)) {
      this.colDefs = this.genColDefs(this.props.sizeNames);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.gridApi?.setColumnDefs([]);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.gridApi?.setColumnDefs(this.colDefs);
    }

    if (
      prevProps.subheader &&
      this.props.subheader.groupBy !== null &&
      this.props.subheader.groupBy.selection !== prevProps.subheader.groupBy.selection &&
      isArray(companionData) &&
      size(companionData) > 0
    ) {
      const { selection, options } = this.props.subheader.groupBy;
      const maybeGroupBy =
        selection && options ? this.props.subheader.groupBy.options[selection].groupingKey : undefined;
      this.props.handleChangeSizeRange(
        { value: companionData[0].id, label: companionData[0].name },
        this.getSelectedFloorsetId(),
        maybeGroupBy
      );
    }
  }

  createSizeColDefs = (sizes: string[]) => {
    const longestSizeName = this.props.sizeNames.reduce((prev, curr) => {
      return curr.length > prev.length ? curr : prev;
    }, '');
    const sizeNamesWidth = max([longestSizeName.length * 10, 45]);
    return sizes.map((size, idx) => {
      const col: ColDef = {
        field: size,
        colId: `${sizeHeaderColIdMagicString}${size}`, // warning: spaces
        refData: {
          // this is where we store the size string for referencing later
          size,
          sizeIdx: idx.toString(),
        },
        headerName: size,
        width: sizeNamesWidth,
        cellClass: 'checkbox-size-cell',
        valueGetter: (params) => {
          const hasSize = indexOf(params.node?.data.size_eligibility, size) >= 0;
          return hasSize;
        },
        cellRenderer: 'cboxRenderer',
        valueSetter: this.handleClickSize,
        headerComponent: 'gridHeaderCheckbox',
        headerComponentParams: {
          onChange: this.handleClickSizeHeader,
          checkedStatus: 'indeterminate',
        },
      };
      return col;
    });
  };

  createColDef = (col: ConfigurableGridConfigItem, updateKeys: StringObject) => {
    const cellWithHighlight = classes(editableCell, highlightedCell);
    const shouldCenter = style({
      justifyContent: 'center',
    });
    const agColDef: ColDef = {
      field: col.dataIndex,
      headerName: col.text,
      // cellClass: shouldCenter,
      headerClass: classes(shouldCenter, headerCell),
      pinned: col.pinned,
      editable: col.editable === true,
      width: col.width,
      resizable: true,
      ...(col.highlightColumn && { cellClass: cellWithHighlight }),
    };
    if (col.editable === true) {
      let editorInfo: Partial<ColDef> = {
        // @ts-ignore
        onCellValueChanged: (event: CellValueChangedEvent) => {
          if (isEqual(event.newValue, event.oldValue)) return;
          if (event.node != null && size(event.node.childrenAfterGroup) > 0) {
            disaggregateHeaderValue(event, updateKeys);
          } else {
            const { colDef, data: eventData } = event;

            const field = colDef.field;
            if (field) {
              const value = eventData[field];
              const strippedField = field
                .replace('attribute:', '')
                .replace(':id', '')
                .replace(':name', '');
              this.sizeEligibilityClient.updateAttributes(
                mapValues(updateKeys, (val) => {
                  return eventData[val];
                }),
                {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  [strippedField]: value,
                }
              );
            }
          }
        },
      };
      switch (col.inputType) {
        case 'validValues': {
          agColDef.cellEditorPopup = true;

          editorInfo = {
            cellEditor: 'validValuesEditor',
            cellEditorParams: (params: any): Partial<ValidValuesEditorProps> => {
              if (col.dataApi) {
                return {
                  dataConfig: processApiParams(col.dataApi, params.data),
                  allowEmptyOption: col.allowEmptyOption !== false,
                  returnSelectionObject: false,
                };
              } else {
                return {
                  options: col.options ? col.options.map((opt) => opt.value) : [],
                };
              }
            },
            ...editorInfo,
          };
          break;
        }
        case 'integer': {
          editorInfo = {
            cellEditor: 'integerEditor',
            cellEditorParams: (params: any): Partial<IntegerEditorProps> => {
              return {
                passedInt: params.data[col.dataIndex],
                inputParams: { ...col.inputParams },
              };
            },
            ...editorInfo,
          };
          break;
        }
        case 'float': {
          editorInfo = {
            cellEditor: 'floatEditor',
            cellEditorParams: (params: any): Partial<IntegerEditorProps> => {
              return {
                passedInt: params.data[col.dataIndex],
                inputParams: { ...col.inputParams },
              };
            },
            ...editorInfo,
          };
          break;
        }
      }
      assign(agColDef, editorInfo);
    }
    if (col.renderer) {
      switch (col.renderer) {
        case 'statusIconRenderer':
        case 'icon':
          logError(`${col.renderer} not currently supported in SizeEligibilityListGrid`, col);
          break;
        case 'tooltipRenderer':
        case 'severityRender':
        case 'starPercentRenderer':
          agColDef.cellRenderer = col.renderer;
          break;
        default:
          agColDef.valueFormatter = (params: ValueFormatterParams) => {
            return Renderer.renderJustValue(params.value, col);
          };
          break;
      }
    }
    return agColDef;
  };

  genColDefs = (sizes: string[]) => {
    let coldefs: ColDef[] = [];
    if (isNil(this.props.gridData)) return coldefs;
    const sizeCols = this.createSizeColDefs(sizes);
    if (this.props.viewDefns) {
      const defnColDefs = this.props.viewDefns.columns;
      const updateKeys = this.props.viewDefns.update.keys;
      const sizeInd = findIndex(defnColDefs, (def) => {
        return def.renderer == 'size_array';
      });
      if (sizeInd >= 0) {
        const left = slice(defnColDefs, 0, sizeInd).map((col) => this.createColDef(col, updateKeys));
        const right = slice(defnColDefs, sizeInd + 1).map((col) => this.createColDef(col, updateKeys));
        coldefs = concat(left, sizeCols, right);
      } else {
        coldefs = defnColDefs.map((col) => this.createColDef(col, updateKeys));
      }
    } else {
    }
    return coldefs;
  };

  onSearchReturn = (s: string): void => {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(s);
    }
    return;
  };

  handleGridReady = (params: GridReadyEvent): void => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    if (this.colDefs && this.props.gridData) {
      this.processSizeHeaderStati();
    }
  };

  handleClickSize = (params: ValueSetterParams): boolean => {
    if (params.colDef.refData && this.props.viewDefns) {
      const size = params.colDef.refData.size;

      const newData = produce<SizeEligibilityGridRow>(params.data, (draft: SizeEligibilityGridRow) => {
        if (params.newValue === 1 || params.newValue === true) {
          draft['size_eligibility'] = union(draft['size_eligibility'], [size]);
        } else {
          draft['size_eligibility'] = without(draft['size_eligibility'], size);
        }
      });
      this.sizeEligibilityClient.updateAttributes(
        mapValues(this.props.viewDefns.update.keys, (val) => {
          return newData[val];
        }),
        {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          selected_sizes: newData['size_eligibility'],
        }
      );
      params.node?.setData(createDraft(newData));
      this.processSizeHeaderStati(true);
      return true;
    }
    return false;
  };

  handleClickSizeHeader = (checked: boolean, size: string): void => {
    if (this.props.gridData == null || !this.props.viewDefns) return;
    const updatedData = produce(this.props.gridData, (gridDraft) => {
      for (let i = 0; i < gridDraft.length; i++) {
        gridDraft[i].size_eligibility = checked
          ? union(gridDraft[i].size_eligibility, [size])
          : without(gridDraft[i].size_eligibility, size);
      }
    });
    const allData = updatedData?.map((location) => {
      return {
        ...mapValues(this.props.viewDefns?.update.keys, (val) => {
          return location[val];
        }),
        selected_sizes: location['size_eligibility'],
      };
    });
    if (allData && updatedData) {
      // updating this in state immedaitely for now, to make the ui appear to update faster
      // but will need to revert if the endpoint fails
      this.props.updateData(updatedData);

      this.sizeEligibilityClient
        .updateAttributesAll(allData)
        .then(() => {
          // TODO: replace with redux or something idk
          // toast.info('Sizes eligibility updated');
        })
        .catch((_e) => {
          // TODO: CHANGE THIS ONCE THE REAL API EXISTS!
          // toast.info('Sizes eligibility updated');
          // ServiceContainer.loggingService.error('An error occured adding sizes', e.stack); // uncomment this out once endpoints exist
          // this.props.updateData(updatedData);
        });
    }
  };

  getSelectedFloorsetId = () => {
    const { floorsetData } = this.props;
    const { dropdownSelection } = this.state;
    if (floorsetData && dropdownSelection >= 0 && floorsetData[dropdownSelection]) {
      return floorsetData[dropdownSelection].id;
    }
    return '';
  };

  // handleChangeGroup() {
  //   if (this.props.subheader.groupBy.refreshOnChange && this.props.floorsetData) {
  //     const floorset = this.props.floorsetData[this.state.dropdownSelection];
  //     const { selection, options } = this.props.subheader.groupBy;
  //     const maybeGroupBy =
  //       selection && options ? this.props.subheader.groupBy.options[selection].groupingKey : undefined;
  //     this.props.onRefetchData(this.props.topProduct, floorset.id, maybeGroupBy);
  //   }
  // }

  render() {
    const { companionData, viewDefns, companionLoading, floorsetData, groupByDefn, viewProperty } = this.props;
    const { companionSortDirection, companionSortField, dropdownSelection } = this.state;

    const title = viewProperty === 'Size Range' ? 'Size Eligibility' : 'Store Info';
    let sizeRangeHdr = `No ${viewProperty} selected.`;
    let selectedIndex = 0;
    if (!isNil(this.props.selectedSizeRange)) {
      const sizerange = this.props.selectedSizeRange.label;
      sizeRangeHdr = `Showing ${title} for ${viewProperty} ${sizerange}`;
      const maybeNewSelectedIndex =
        this.props.companionData &&
        this.props.companionData.findIndex((d) => d.id === this.props.selectedSizeRange?.value);
      selectedIndex = maybeNewSelectedIndex && maybeNewSelectedIndex !== -1 ? maybeNewSelectedIndex : selectedIndex;
    }
    if (!this.colDefs && this.props.viewDefns && !isEmpty(this.props.sizeNames)) {
      const sizes = this.props.sizeNames;
      const coldefs: ColDef[] = this.genColDefs(sizes);
      this.colDefs = coldefs;
    }

    let companionView: null | JSX.Element = null;
    if (viewDefns && viewDefns.companionApi) {
      const companionDataLookup =
        viewDefns && viewDefns.companionApi && parseCompanionListViewConfig(viewDefns.companionApi);

      // eslint-disable-next-line prettier/prettier
      const searchKeys: string[] =
        viewDefns && viewDefns.companionApi
          ? ([
              viewDefns.companionApi.main.title,
              viewDefns.companionApi.main.displayTitle,
              viewDefns.companionApi?.main.body,
            ].filter((i) => i != null) as string[])
          : [];
      const compData =
        companionData &&
        companionDataLookup &&
        companionDataParse(
          filterData(companionData, this.state.companionSearch, searchKeys, []),
          companionDataLookup,
          companionSortDirection,
          companionSortField
        );

      const companionProps: CompanionProps = {
        defaultSelection: 0,
        sortOptions: [
          {
            text: viewProperty,
            dataIndex: 'value',
          },
        ],
        isDataloaded: this.props.companionLoading,
        label: `Select ${viewProperty}`,
        selectedIndex: selectedIndex,
        className: companionStyles,
        data: !companionLoading ? compData || [] : [],
        noImageUrl: viewDefns.companionApi.main.image != null ? noImage : undefined,
        initialSortDirection: this.state.companionSortDirection,
        dataLookup: companionDataLookup,
        onListItemClicked: (identityValue) => {
          const newSizeRange = this.props.sizeRanges.find((sz) => sz.value === identityValue);
          const { selection, options } = this.props.subheader.groupBy;
          const maybeGroupBy =
            selection && options ? this.props.subheader.groupBy.options[selection].groupingKey : undefined;
          if (newSizeRange) {
            this.props.handleChangeSizeRange(newSizeRange, this.getSelectedFloorsetId(), maybeGroupBy);
          } else if (this.props.companionData) {
            const item = this.props.companionData.find((x) => x.id === identityValue);
            item &&
              this.props.handleChangeSizeRange(
                { value: item.id, label: item.name },
                this.getSelectedFloorsetId(),
                maybeGroupBy
              );
          }
        },
        onSortSelection: (selection) => {
          this.setState({
            companionSortField: selection.dataIndex,
          });
        },
        onChangeDirection: (direction) => {
          this.setState({ companionSortDirection: direction });
        },
        searchComponent: CompanionListSearch({
          search: this.state.companionSearch,
          onChange: this.onCompanionSearch,
        }),
      };
      companionView = <CompanionListView {...companionProps} />;
    }
    const dropdownProps: SubheaderDropdownProps[] = [];
    if (floorsetData) {
      dropdownProps.push({
        label: 'Floorset',
        selection: this.state.dropdownSelection,
        options:
          floorsetData.map((floorset) => ({
            ...floorset,
            dataIndex: floorset.id,
            text: floorset.name,
          })) || [],
        qaKey: 'Floorset-Dropdown',
        customClass: '',
        disabled: false,
        handleChangeOnDropdown: (event: React.ChangeEvent<HTMLSelectElement>) => {
          const index = +event.target.value;
          let selectedFloorset = '';
          if (floorsetData[index]) {
            selectedFloorset = floorsetData[index].id;
          } else {
            // selectedFloorset = floorsetData[0].id;
          }

          if (index !== dropdownSelection) {
            const { selection, options } = this.props.subheader.groupBy;
            const maybeGroupBy =
              selection && options ? this.props.subheader.groupBy.options[selection].groupingKey : undefined;
            this.props.onRefetchData(this.props.topProduct, selectedFloorset, maybeGroupBy);
          }
          this.setState({
            dropdownSelection: index,
          });
          return;
        },
      });
    }
    const treeColDef = this.props.treeColumnDefinition
      ? merge({ resizable: true }, this.props.treeColumnDefinition)
      : undefined;
    const gridData = createDraft(this.props.gridData || []);
    return (
      <div className={styles}>
        <Overlay type="loading" visible={this.props.dataLoading || this.props.companionLoading} />
        <Subheader
          title={(viewDefns && viewDefns.main.title) || title} // replace from config
          showSearch={true}
          searchReturn={this.onSearchReturn}
          extraDropdowns={dropdownProps}
          groupByDefn={groupByDefn}
          // onChangeGroup={this.handleChangeGroup}
          showAlternateSearch={false}
          downloadLink={this.props.downloadLink}
        />
        <div className={classes(container, listPairStyle)}>
          <div className="subcont-flexible">
            {companionView}
            <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
              <h3 className={sizeRangeTitle}>{sizeRangeHdr}</h3>
              <div className={gridContainerStyle} style={{ height: 'calc(100% - 38px)' }}>
                {this.colDefs ? (
                  <ExtendedDataGrid
                    columnDefs={this.colDefs}
                    data={gridData}
                    className={gridClass}
                    loaded={true}
                    frameworkComponents={frameworkComponents}
                    // events
                    onGridReady={this.handleGridReady}
                    rowHeight={30}
                    treeColumnDefinition={treeColDef}
                    extraAgGridProps={{
                      headerHeight: 65,
                      // suppressMovableColumns: true,
                      groupDefaultExpanded: -1,
                      defaultColDef: defaultColDef,
                      enableRangeSelection: false,
                      suppressMultiRangeSelection: true,
                      suppressRowClickSelection: true,
                      suppressColumnVirtualisation: true,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
