import { WorklistClient } from '../../dao/Worklist.client';

// would rather the return type be the type in this case
/* eslint-disable-next-line */
export function WorklistService() {
  const worklistClient = WorklistClient();
  return {
    getWorklistItems() {
      return worklistClient.getWorklistItems();
    },
    addToWorklist(product: string) {
      return worklistClient.addToWorklist(product);
    },
    addItemsToWorklist(products: string[]) {
      return worklistClient.addItemsToWorklist(products);
    },
    removeFromWorklist(product: string) {
      return worklistClient.removeFromWorklist(product);
    },
    removeItemsFromWorklist(products: string[]) {
      return worklistClient.removeItemsFromWorklist(products);
    },
    snoozeOneDay(product: string) {
      return worklistClient.snoozeOneDay(product);
    },
    snoozeItemsOneDay(products: string[]) {
      return worklistClient.snoozeItemsOneDay(products);
    },
    snoozeOneWeek(product: string) {
      return worklistClient.snoozeOneWeek(product);
    },
    snoozeItemsOneWeek(products: string[]) {
      return worklistClient.snoozeItemsOneWeek(products);
    },
    markAsReviewed(product: string) {
      return worklistClient.markAsReviewed(product);
    },
    markItemsAsReviewed(products: string[]) {
      return worklistClient.markItemsAsReviewed(products);
    },
  };
}
