import { connect } from 'react-redux';

import CardView from 'src/components/StandardCardView/StandardCardView';
import container from 'src/ServiceContainer';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { DispatchProps, IdentityPropsConfig, ValueProps } from 'src/components/StandardCardView/StandardCardView.types';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';

import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { getProcessedData, getSummary, getFilteredFlatData } from './SummaryView.selectors';
import { AppState, AppThunkDispatch } from 'src/store';
import { receiveTenantConfig, receiveError, requestTenantConfig } from './SummaryView.slice';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';
import { withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { flow, get } from 'lodash';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/Favorites.types';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { SummaryViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { RollupDefn } from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { SummaryViewOwnProps } from 'src/services/configuration/codecs/ownProps';
import { BackgroundDataLoadingProps } from 'src/components/BackgroundDataLoading/BackgroundDataLoading';
import { FabType } from 'src/services/configuration/codecs/confdefnView';
import { getfabProps } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';

function mapStateToProps(state: AppState, ownProps: SummaryViewOwnProps): ValueProps & BackgroundDataLoadingProps {
  const { summaryView } = state.pages.hindsighting.styleColorReview;
  const { defns, fabType = FabType.none } = ownProps;
  const loaded = !summaryView.tenantConfigLoading && isDataLoaded(summaryView.viewDataState);
  const adornments: AdornmentType[] = get(summaryView.viewDefn, 'adornments', []);
  const cardType = get(summaryView.unmodifiedViewDefn.main, 'cardType', undefined);
  const fabProps = getfabProps(state, fabType);

  return {
    loaded,
    config: summaryView.viewDefn,
    sortBy: state.subheader.sortBy,
    groupBy: state.subheader.groupBy,
    subheaderViewDefns: defns.subheader,
    filteredStyles: getFilteredFlatData(state),
    groupedStyles: getProcessedData(state),
    summary: getSummary(state),
    identityPropsConfig: summaryView.identityPropsConfig,
    currentTab: state.perspective.activeTab,
    unmodifiedViewDefn: summaryView.unmodifiedViewDefn,
    adornments,
    fabType,
    fab: fabProps,
    downloadLink: ownProps.subheader?.downloadLink,
    viewDataState: summaryView.viewDataState,
    cardType,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: SummaryViewOwnProps): DispatchProps {
  const { defns } = ownProps;

  return {
    onShowView() {
      const { tenantConfigClient } = container;
      dispatch(requestTenantConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: AppType.Assortment,
          validationSchemas: [SummaryViewDefn, RollupDefn],
        })
        .then((resp: (TenantConfigViewData & { identityProps?: IdentityPropsConfig })[]) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          const storedConfig = localConfig && localConfig.config ? localConfig.config : resp[0];
          const identityPropsConfig = retrieveIdentityPropsConfig(resp[0]);

          dispatch(
            receiveTenantConfig({
              viewDefn: storedConfig,
              calcViewDefn: resp[1],
              unmodifiedViewDefn: unmodifiedViewDefn,
              identityPropsConfig,
            })
          );
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.summaryView,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    showStylePane(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onItemClicked(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
        })
      );
    },
  };
}

const wrappedView = flow(() => CardView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
