import { style } from 'typestyle';

const makeModalOverlay = (hideBackground = false) => {
  const overlay = {
    position: 'fixed' as any,
    background: 'rgba(0, 0, 0, .5)',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  if (hideBackground) {
    overlay['$nest'] = {
      '&>div': {
        background: 'none',
      },
    };
  }
  return style(overlay);
};

const multilineTextEditor = style({
  border: 'none',
  marginTop: '-10px',
  lineHeight: '12px',
  height: '55px',
  padding: '20px 10px',
  marginLeft: '-10px',
});

export { makeModalOverlay, multilineTextEditor };
