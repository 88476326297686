import React, { useEffect, useState } from 'react';
import { Dialog, Tooltip } from '@material-ui/core';
import styles from './ButtonModal.styles';
import { getDefaultTitle } from './ButtonModal.default';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';

export interface ModalButton {
  onClick: (click: React.MouseEvent<HTMLDivElement>) => void;
  path: null | string;
  label: string;
  disabled?: boolean;
}

type Props = {
  title?: string;
  tooltipString?: string;
  defn?: string;
};
type DefaultButtons = {
  defaultButtons: {
    label: string;
    path: string;
    disabled: boolean;
  }[];
};

const ButtonModal = ({ defn, title = getDefaultTitle(), tooltipString }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [defaultButtons, setDefaultButtons] = useState<DefaultButtons['defaultButtons']>([]);

  useEffect(() => {
    const fetchConfig = async () => {
      if (isNil(defn)) {
        container.loggingService.warn('ButtonModal config missing');
        return;
      }

      const resp = await container.tenantConfigClient.getTenantViewDefn<DefaultButtons>({
        defnId: defn,
        appName: ASSORTMENT,
      });

      setDefaultButtons(resp.defaultButtons);
    };

    fetchConfig();
  }, [defn]);

  const handleCancel = () => {
    setModalOpen(false);
  };

  const fabClick = () => {
    setModalOpen(true);
  };

  let fabButton = (
    <button className={styles.fab} onClick={fabClick}>
      <i className="fas fa-plus" aria-hidden="true" />
    </button>
  );
  if (tooltipString) {
    fabButton = (
      <Tooltip title={tooltipString} arrow>
        {fabButton}
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      {fabButton}
      <Dialog
        open={isModalOpen}
        onClose={(_event, reason) => {
          if (reason === 'escapeKeyDown') {
            handleCancel();
          }
        }}
      >
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
            <span className={styles.buttonCancel} onClick={handleCancel}>
              X
            </span>
          </div>
          <div className={styles.body}>
            {defaultButtons.map((button) => {
              const className = button.disabled ? styles.buttonDisabled : styles.button;
              const ret = (
                <Link key={button.label} to={button.path}>
                  <div className={className}>
                    <span>{button.label}</span>
                  </div>
                </Link>
              );

              return ret;
            })}
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default ButtonModal;
