// @ts-ignore
import { Keys } from 'react-keydown';
const { UP, DOWN, LEFT, RIGHT, TAB, ENTER, w, a, s, d } = Keys;

export const MOVE_DIRECTION_LOOKUP = {
  // gets row, column change based on arrow direction
  [UP]: [-1, 0],
  [DOWN]: [1, 0],
  [LEFT]: [0, -1],
  [RIGHT]: [0, 1],
  [w]: [-1, 0],
  [s]: [1, 0],
  [a]: [0, -1],
  [d]: [0, 1],
  [TAB]: [0, 1],
  [ENTER]: [1, 0]
};
