import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { isEmpty, isEqual } from 'lodash';
import { updateSortBy, updateFlowStatus, receiveViewDefns } from 'src/components/Subheader/Subheader.slice';
import { fetchTopPerformersData } from './TopPerformers.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded, topMemInWorklistSelected } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  TopPerformersComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import { isListDataApi } from 'src/services/configuration/codecs/confdefnView';
import {
  ExtraPivotOptions,
  getSortBy,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { getTopMembers } from 'src/pages/Hindsighting/MacroTrends/Summary/Summary.container';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';

export const topPerformersLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveViewDefns.type,
      // the above actions are only needed since they are potentially dispatched after
      // setActivePage is dispatched and top performers data fetch
      // needs sort and scope info that may not be ready yet.
      receiveScopeRefreshTrigger.type,
      setActivePage.type,
      updateSortBy.type,
      updateFlowStatus.type
    ),
    map(() => maybeGetComponentProps<TopPerformersComponent>(state$.value, ConfDefnComponentType.topPerformers)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns }) => {
      const { dataApi, model: modelDefn } = defns;
      const finalModelDefn = isListDataApi(dataApi) ? dataApi.defnId : modelDefn;

      const flowStatus = state$.value.subheader.flowStatus.join(',');
      const sortBy = getSortBy(state$.value.subheader);
      const topMembers = getTopMembers(state$.value.scope.scope);
      const options: ExtraPivotOptions = { flowStatus, sortBy, topMembers };
      const finalOptions = organizeListDataOptions(options, dataApi);

      if (isEmpty(sortBy) || isEqual(topMembers, ',')) {
        return of();
      }

      return of(fetchTopPerformersData(finalModelDefn, finalOptions));
    })
  );
};
