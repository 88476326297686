/**
 *
 * @param o A record<string, unknown> to check for null keys
 * @param keys The list of keys to check for nullishness
 * @returns A boolean indidcating whether any of the keys are null
 */
export function anyNull<T extends Record<string, unknown>>(o: T, keys: string[]) {
  // some() early returns on the first false
  return keys.some((k) => {
    if (o[k] == null) {
      return true;
    }
    return false;
  });
}
