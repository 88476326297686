import { classes, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { mediumGutterWidth } from 'src/utils/Style/Theme';
import { faintBorderColor } from 'src/common-ui/theme';

const leftRightStyle: NestedCSSProperties = {
  paddingLeft: mediumGutterWidth,
  alignContent: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: '170px',
  $nest: {
    '> div': {
      marginBottom: mediumGutterWidth,
    },
  },
};

export function fullCircleWithColor(value: number) {
  let selectedColor = 'orange';
  if (value > 0) {
    selectedColor = 'green';
  } else if (value < 0) {
    selectedColor = 'red';
  }
  return classes('full-circle', selectedColor);
}

const paretoSummary = style({
  $debugName: 'paretoSummary',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  $nest: {
    '.section-title': {
      display: 'inline-block',
      backgroundColor: '#444444',
      padding: '5px 20px',
      color: '#ffffff',
      fontWeight: 'bold',
    },
    '.detailsContainer .circle-desc, .detailsContainer .circle-container': {
      width: 'auto',
    },
    '.detailsContainer .circle-desc': {
      paddingLeft: '10px',
    },
    '.info-graph-widget': {
      height: `calc(100% - ${mediumGutterWidth}px)`,
      width: '60%',
      minWidth: 780,
      backgroundColor: 'rgb(252,252,252)',
      display: 'flex',
      flexDirection: 'column',
    },
    '.info-graph-bottom': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '.title-container': {
          padding: `${mediumGutterWidth}px 35px`,
        },
      },
    },
    '.title-container .title, .key-statistics .main, .section-piece.main': {
      fontSize: '14px',
      paddingBottom: '1px',
      fontWeight: 500,
    },
    '.section-column-names': {
      display: 'flex',
    },
    '.detail-row': {
      display: 'flex',
      alignItems: 'center',
      height: '100px',
      $nest: {
        '&.highlight': {
          backgroundColor: faintBorderColor,
        },
      },
    },
    '.title-container .sectionSummary': {
      fontSize: '10px',
    },
    '.detailsContainer .circle-desc .bottom-text': {
      textAlign: 'left',
    },
    '.title-container .summaryPiece': {
      display: 'inline-block',
      marginRight: '3px',
    },
    '.details .circle-container .full-circle': {
      width: '75px',
      height: '75px',
      borderRadius: '50%',
      paddingTop: '15px',
      fontSize: '22px',
    },
    '.details .circle-container .full-circle.green': {
      borderColor: '#419d22',
    },
    '.details .circle-container .full-circle.orange': {
      borderColor: '#ff8137',
    },
    '.details .circle-container .full-circle.red': {
      borderColor: '#e22819',
    },
    '.secondary': {
      fontSize: '10px',
    },
    '.key-statistics': {
      $nest: {
        '.right': leftRightStyle,
        '.left': leftRightStyle,
        '.center': {},
        '.bottom': {
          display: 'flex',
        },
      },
    },
    '.section-piece.percentage': {
      fontSize: '22px',
      padding: '10px',
    },
    '.percentage': {
      fontSize: '22px',
      height: '100%',
      verticalAlign: 'middle',
    },
    '.section-piece.combo': {
      float: 'left',
      height: '40px',
      padding: '5px 10px',
    },
    '.section-piece.combo.percent': {
      width: '70px',
      textAlign: 'right',
    },
    '.circle-desc': {
      display: 'inline-block',
      width: '135px',
      textAlign: 'right',
      marginRight: '25px',
      verticalAlign: 'middle',
      fontFamily: '"Open Sans", Arial, sans-serif',
      fontSize: '11px',
      whiteSpace: 'normal',
      fontWeight: 'normal',
    },
    '.circle-container': {
      color: '#2f2f2f',
      verticalAlign: 'middle',
      fontSize: '1rem',
      float: 'left',
      whiteSpace: 'nowrap',
    },
    '.circle-container .full-circle': {
      border: '5px solid #c30d09',
      display: 'inline-block',
      fontWeight: 'normal',
      width: '50px',
      height: '50px',
      borderRadius: '25px',
      textAlign: 'center',
      paddingTop: '12px',
      fontFamily: '"Open Sans", Arial, sans-serif',
    },
    '.circle-container .side-metrics': {
      textAlign: 'right',
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingLeft: '10px',
    },
    '.circle-container .side-metrics .trending-text': {
      paddingTop: '4px',
      font: '1.4em "Open Sans", Arial, sans-serif',
      fontWeight: 'lighter',
    },
    '.circle-container .side-metrics .bottom-text': {
      font: '0.9em "Open Sans", Arial, sans-serif',
      fontWeight: 'lighter',
    },
    '.quintiles': {
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '.left': {
          paddingLeft: mediumGutterWidth,
          flex: 3,
        },
        '.column': {
          flex: 2,
        },
        '.right': {
          flex: 1,
        },
      },
    },
    '.section-summary': {
      $nest: {
        '.secondary + .secondary': {
          marginLeft: '0.5rem',
        },
      },
    },
    '.row-container': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.trending-arrow': {
      display: 'inline-block',
      borderLeft: 'solid 6px transparent',
      borderRight: 'solid 6px transparent',
      marginRight: '1px',
      top: '50%',
    },
    '.trending-arrow.arrow-up': {
      borderBottom: 'solid 8px #318310',
      color: '#e07d31',
    },
    '.trending-arrow.arrow-down': {
      borderTop: 'solid 8px #c20c09',
    },
    '.trending-arrow.arrow-none': {
      visibility: 'hidden',
    },
  },
});

export default {
  paretoSummary,
};
