import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  GridList,
  GridListTile,
  makeStyles,
  Typography
} from '@material-ui/core';
import '@ag-grid-community/styles/ag-grid.css';
import { Dropdown, DropdownItemProps, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { AppState, AppThunkDispatch } from 'src/store';
import ServiceContainer from 'src/ServiceContainer';
import { useGoBack, useGoForward, WizardActions } from 'src/pages/Mfp/Administration/submission-wizard.utils';
import { SmartPlanSection, SmartPlanStep } from 'src/state/scope/codecs/SmartPlan/SmartPlanSection';
import 'src/pages/Mfp/Administration/_submission-wizard.scss';
import ConfigService from 'src/services/Config.service';
import { useHandleChangeStringOrNumberVersion } from 'src/utils/Component/hooks/hooks';
import { GRID_DEFAULT, isReady, scopeEmpty, scopeReady, scopePending, ScopeStateUnion } from 'src/state/scope/Scope.types';
import { isEmpty, mapValues, noop } from 'lodash';
import { memberToDropdown } from 'src/components/Mfp/MfpScopeSelector/MfpScopebar.container';
import { METRICS, TIME } from 'src/utils/Domain/Constants';
import { SeedInfoSelections } from 'src/components/Mfp/MfpScopeSelector/MfpScopebar.types';
import { createPivoteConfig } from './smartplan.utils';
import { SettingsEntry } from 'src/services/Settings';
import AgPivot from 'src/components/Mfp/Pivot/AgPivot';
import type { AgPivot as AgPivotClass } from 'src/components/Mfp/Pivot/AgPivot';
import SmartPlanTargetEditor from 'src/components/Mfp/SmartPlan/SmartPlanTargetEditor';
import Scope, { SCOPECREATE_SMARTPLAN } from 'src/services/Scope.client';
import PivotConfig from 'src/pivot/PivotConfig';
import SaveVersion from 'src/components/Mfp/SaveVersion/SaveVersion';
import ScopebarOptionModal from 'src/components/Mfp/MfpScopebarOption/ScopebarOptionModal';
import { getLastTimeMember } from 'src/components/Mfp/MfpScopeSelector/MfpScopeUtils';
import { WorkingSetContext } from 'src/state/workingSets/workingSets.slice';
import { CONTEXT_READY } from 'src/state/workingSets/workingSets.types';
import { NonWorkingSetContext } from 'src/state/workingSets/nonWorkingSets.slice';
import { fetchCommands } from 'src/state/scope/Scope.actions';
import { CommandDetail } from 'src/state/scope/codecs/Commands';
import { getMfpModule } from 'src/pages/NavigationShell/navigationUtils';
import { ContextMfpModule } from 'src/services/configuration/codecs/confdefn';
import MfpSubheader from 'src/components/Mfp/MfpSubheader/MfpSubheader';

interface SmartPlanWizardOwnProps { };
type SmartPlanWizardValueProps = SmartPlanReadyProps | SmartPlanNotReady;
interface SmartPlanWizardDispatchProps extends ReturnType<typeof mapDispatchToProps> { }

export type SmartPlanWizardProps = SmartPlanWizardValueProps &
  SmartPlanWizardDispatchProps &
  SmartPlanWizardOwnProps;

interface SmartPlanReadyProps {
  currentModule: ContextMfpModule | undefined,
  balanceOptions: CommandDetail[],
  timeMembers: DropdownItemProps[] | undefined,
  settings: {
    [key: string]: SettingsEntry
  },
  baseScopeAnchor: WorkingSetContext['anchor'] | undefined,
  smartScopes: NonWorkingSetContext[]
}
interface SmartPlanNotReady {
  currentModule: ContextMfpModule | undefined,
  balanceOptions: undefined,
  timeMembers: undefined,
  settings: undefined,
  baseScopeAnchor: undefined,
  smartScopes: NonWorkingSetContext[]
}

const mapStateToProps = (state: AppState, _own: {}): SmartPlanWizardValueProps => {
  const availableMembers = state.viewConfigSlice.availableMembers;
  const currentModule = getMfpModule(state);

  if (isReady(state.mfpScope) && state.mfpScope.balanceOptions) {
    const baseScopeId = state.mfpScope.id;
    const activeWs = state.workingSets.contexts.find(ws => ws.id === baseScopeId);
    const smartScopes = state.nonWorkingSets.contexts.filter((ws) => ws.initParams.type === SCOPECREATE_SMARTPLAN);
    const scopePlanId = state.mfpScope.mainConfig.initializedPlans[0];

    return {
      currentModule,
      balanceOptions: state.mfpScope.balanceOptions[Number(scopePlanId)],
      timeMembers: availableMembers ? availableMembers.space[TIME].map(memberToDropdown) : undefined,
      settings: state.settings.entriesByKey,
      baseScopeAnchor: activeWs?.anchor,
      smartScopes
    };
  }
  return {
    currentModule,
    balanceOptions: undefined,
    timeMembers: undefined,
    settings: undefined,
    baseScopeAnchor: undefined,
    smartScopes: []
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    requestCommands: () => dispatch(fetchCommands())
  };
};

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  gridList: {
    width: '100%',
    height: 'fit-content',
    margin: '5px !important'
  },
  gridListTile: {
    minHeight: 160
  },
  cardRoot: {
    minHeight: '100%',
    minWidth: 200,
    padding: 10,
    display: 'flex',
    flexDirection: 'column'
  }
});

const SmartPlanWizard = (props: SmartPlanWizardProps) => {
  const {
    balanceOptions,
    timeMembers,
    settings,
    requestCommands: requestWorkflows,
    currentModule,
    baseScopeAnchor,
    smartScopes
  } = props;
  const classes = useStyles();
  const client = ServiceContainer.axios;

  const gridRef = useRef<AgPivotClass | null>(null);
  const [gridRefreshed, setGridRefreshed] = useState(false);

  const [steps, setSteps] = useState<SmartPlanStep[] | undefined>(undefined);
  const [expanded, setExpanded] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isSaveModalVisible, setSaveModalVisible] = useState(false);

  // config tuple is used to check the step # against it's corresponding config object
  const [pivotConfig, setPivotConfig] = useState<[PivotConfig, number] | undefined>(undefined);
  const [scope, setScope] = useState<ScopeStateUnion>(scopeEmpty(false));

  // stores plan.Id for the selected plan or the string time member id if actuals
  const [balanceVersion, setBalanceVersion] = useState<number | string | undefined>(undefined);

  /* Accordion methods */
  const goForward = useGoForward(setExpanded, steps ? steps.length - 1 : 1);
  const goBack = useGoBack(setExpanded);

  const onBalanceChange = (field: SeedInfoSelections, value?: string | number | undefined) => {
    !!value ? setBalanceVersion(value) : noop();
  };
  const handleChangeCopyOptions = useHandleChangeStringOrNumberVersion(onBalanceChange);

  const balanceTime = useMemo(() => {
    return baseScopeAnchor && timeMembers ? getLastTimeMember(timeMembers, baseScopeAnchor[TIME][0].id) : undefined;
  }, [baseScopeAnchor, timeMembers]);

  const copyVersionDropdowns = useMemo(() => {
    // FIXME: this is super wrong
    return [];
  }, [balanceOptions]);

  useEffect(() => {
    if (currentModule && currentModule.siloId) {
      new ConfigService(client).getSmartPlanWizardConfig(currentModule.siloId).then((sp) => {
        setSteps(sp.steps);
      });
    }
  }, [client, currentModule]);

  useEffect(() => {
    requestWorkflows();
  }, [requestWorkflows]);

  const refreshCells = useCallback(() => {
    // this is essentially a re-implementation of logic the grid is currently handling through global state flags
    if (gridRef.current && gridRef.current.gridApi && 'refreshCells' in gridRef.current) {
      gridRef.current.refreshCells();
      setGridRefreshed(true);
      gridRef.current.gridApi.removeEventListener('cellsReturned', refreshCells);
    }
  }, [gridRef, setGridRefreshed]);

  useEffect(() => {
    if (!isEmpty(smartScopes) && scope && isReady(scope)) {
      const currentSmartScopeContext = smartScopes.find((ws) => ws.id === scope.id);
      if (currentSmartScopeContext &&
        currentSmartScopeContext.status === CONTEXT_READY &&
        gridRef && gridRef.current &&
        gridRef.current.gridApi &&
        !gridRefreshed
      ) {
        // when the smart scope comes back ready, and other stars are aligned,
        // attack the refresh handler and let it fire once
        // then set the gridRefreshed flag so we don't imperatively refresh in a loop
        // This first event fires when the first pivot returns, so we should be able to safely refresh once at that time
        gridRef.current.gridApi.addEventListener('cellsReturned', refreshCells);
      }
    }
  }, [smartScopes, scope, gridRefreshed, refreshCells]);

  const targetMetrics = useMemo(() => {
    const firstGrid = steps
      ?.find(t => t.component === 'Grid');
    return firstGrid ? firstGrid.viewParams?.rows.find(r => r.dimension === METRICS)?.items.map(m => m)
      : undefined;
  }, [steps]);

  const useGoForwardAndCreateScope = useCallback((nextIdx: number) => {
    // TODO: delta the balance and only create scope if different
    if (steps && baseScopeAnchor && currentModule) {
      setLoading(true);
      const scope = new Scope(client);
      scope.createScope({
        initParams: {
          type: 'with-smart-plan'
        },
        anchor: mapValues(baseScopeAnchor, (s) => [s[0].id]),
        workflow: 'in-season'

      }, currentModule.siloId, currentModule.pathSlot).then((scopeResp) => {
        if (scopeResp.type === 'ScopeReady') {
          setScope(scopeReady(scopeResp.id, {
            mainConfig: scopeResp,
            isFetching: false,
            forceRefreshGrid: false,
            // TODO: Some of these things should be removed from the payload
            hasEditableRevision: scopeResp.revisions.findIndex(r => r.version === 'smart') > -1 || false,
            inSeason: scopeResp.inSeason,
            currentAnchors: undefined,
            isMultiScope: false,
            initialized: scopeResp.initialized,
            gridAsyncState: GRID_DEFAULT,
            pendingWrites: 0,
            hasLocks: false,
            balanceOptions: {},
            seedOptions: {},
            importOptions: {},
            overlayOptions: {},
            commands: [],
            persistErrorStatus: undefined,
            message: undefined
          }));
          // TODO: replace this with magic import from chetan's version
          const smartPlanScopePlanId = scopeResp.uninitializedPlans[0].id;
          scope.postOverlay(scopeResp.id, smartPlanScopePlanId, 'smart_plan').then(() => {
            setLoading(false);
          });

        } else {
          setScope(scopePending(scopeResp.id));
        }
        goForward(nextIdx);
      });
    }
  }, [baseScopeAnchor, client, currentModule, goForward, steps]);

  useEffect(() => {
    const maybeCurrentViewParams = steps?.find((_step, idx) => idx === expanded)?.viewParams;
    if (settings && isReady(scope) && expanded > 0 && isReady(scope) && maybeCurrentViewParams && !isLoading) {
      const newPivotConfig = createPivoteConfig(
        settings,
        scope,
        maybeCurrentViewParams.rows,
        maybeCurrentViewParams.columns,
        maybeCurrentViewParams.readOnly
      );
      if (pivotConfig && newPivotConfig && !pivotConfig[0].equals(newPivotConfig)) {
        setPivotConfig([newPivotConfig, expanded]);
        setGridRefreshed(false);
      } else if (!pivotConfig && newPivotConfig) {
        setPivotConfig([newPivotConfig, expanded]);
        setGridRefreshed(false);
      }
    }
  }, [expanded, isLoading, pivotConfig, scope, settings, steps, targetMetrics]);

  const useSubmitSavePlan = useCallback(() => {
    setLoading(true);
    if (isReady(scope)) {
      // TODO check that this is working correctly
      setLoading(false);
      setExpanded(0);
      setSaveModalVisible(false);
    }
  }, [scope]);

  const saveModalProps = useMemo(() => {
    // TODO: this pulls save versions list automatically
    return {
      modalTitle: 'Save Smart Plan',
      modalClassName: 'save-version-modal',
      modalDataQa: 'save-version-modal',
      modalBodyComponent: SaveVersion,
      modalBodyComponentProps: {
        loading: isLoading,
        onSubmit: useSubmitSavePlan,
        onCancel: () => setSaveModalVisible(false),
        onItemChange: noop,
        onOpen: noop,
        text: 'Smart Plan',
        smartSave: true
      }
    };
  }, [isLoading, useSubmitSavePlan]);

  const renderSteps = (step: SmartPlanStep, stepIndex: number) => {
    switch (step.component) {
      case 'Grid':
        return <Accordion expanded={expanded === stepIndex} key={`smart-plan-step-${stepIndex}`}>
          <AccordionSummary classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>{step.title}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'details-ignore-width' }} style={{ height: '500px', width: '100%' }}>
            {/* This ternary needs to look like this, becase as soon as the one step is expanded,
            the new grid will render but with the old config, causing the new grid to render the wrong columns.
            The pivotConfig[1] tuple contains the step id, which keeps the new grid from rendering until it's config
            is ready, which comes 1 frame after it is expanded.  If it renders too fast,
            handleGridReady hasn't fired yet, so the new component can't render the new config.
            */}
            {(pivotConfig && expanded === stepIndex && pivotConfig[1] === stepIndex) ? (<AgPivot
              config={pivotConfig[0]}
              pivotName={`smart-plan-step-${stepIndex}`}
              ref={gridRef}
              // the below makes the grid use the local state scope instead of global for refreshing
              alternateScopeStatus={scope}
              bulkUpdateOnly={false}
            />) : null}
          </AccordionDetails>
          <WizardActions
            section={stepIndex}
            onContinue={goForward}
            onGoBack={goBack}
          />
        </Accordion>;
      case 'Targets':
        return <Accordion expanded={expanded === stepIndex}>
          <AccordionSummary classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>{step.title}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'details' }}>
            {step.sections ? step.sections.map(renderSections) : null}
          </AccordionDetails>
          <WizardActions
            section={stepIndex}
            onContinue={goForward}
            onGoBack={goBack}
          />
        </Accordion>;
      case 'Description':
        return <Accordion expanded={expanded === stepIndex} key={stepIndex}>
          <AccordionSummary classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>{step.title}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'details' }}>
            <Typography variant={'h6'}>{step.description}</Typography>
          </AccordionDetails>
          <WizardActions
            section={stepIndex}
            onContinue={useGoForwardAndCreateScope}
            onGoBack={goBack}
          />
        </Accordion>;
      case 'Balance':
        return <Accordion expanded={expanded === stepIndex} key={stepIndex}>
          <AccordionSummary classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>{step.title}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'details' }}>
            <div className="dropdown-group">
              Select the Plan period and version to to copy End of Plan data as a starting point for your Smart Plan
              <Dropdown
                fluid={true}
                loading={false}
                data-qa="eop-period-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={balanceTime}
                value={balanceTime ? balanceTime[0].value : undefined}
              />
            </div>
            <div className="dropdown-group">
              <div className="dropdown-group-label">
                Select the Plan Version
              </div>
              <Dropdown
                fluid={true}
                loading={false}
                data-qa="eop-version-dropdown"
                icon={<i className="chevron icon far fa-chevron-down" />}
                options={copyVersionDropdowns}
                onChange={handleChangeCopyOptions}
                value={balanceVersion}
              />
            </div>
          </AccordionDetails>
          <WizardActions
            section={stepIndex}
            onContinue={useGoForwardAndCreateScope}
            onGoBack={goBack}
          />
        </Accordion>;
      case 'Summary':
        return <Accordion expanded={expanded === stepIndex} key={stepIndex}>
          <AccordionSummary classes={{ root: 'summary' }}>
            <Typography variant={'h6'}>{step.title}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'details-ignore-width' }} style={{ height: '500px', width: '100%' }}>
            {/* This ternary needs to look like this, becase as soon as the one step is expanded,
            the new grid will render but with the old config, causing the new grid to render the wrong columns.
            The pivotConfig[1] tuple contains the step id, which keeps the new grid from rendering until it's config
            is ready, which comes 1 frame after it is expanded.  If it renders too fast,
            handleGridReady hasn't fired yet, so the new component can't render the new config.
            */}
            {pivotConfig && expanded === stepIndex && pivotConfig[1] === stepIndex ? (<AgPivot
              config={pivotConfig[0]}
              ref={gridRef}
              pivotName={'smart-plan-summary'}
              alternateScopeStatus={scope}
              bulkUpdateOnly={false}
            />) : null}
          </AccordionDetails>
          <WizardActions
            section={stepIndex}
            onContinue={() => setSaveModalVisible(true)}
            onGoBack={goBack}
          />
        </Accordion>;
      default:
        return <p>Component Not Found, likely a view config error</p>;
    }
  };

  const renderSections = (section: SmartPlanSection, idx: number, sectionArray: SmartPlanSection[]) => {
    const targets = section.targets.map((target, idx) => (
      <GridListTile key={idx} cols={1} rows={1} className={classes.gridListTile}>
        <SmartPlanTargetEditor {...target} />
      </GridListTile>
    ));

    return sectionArray.length > 1 ? (
      <React.Fragment key={idx}>
        <GridList cellHeight={'auto'} className={classes.gridList} cols={2} spacing={20}>
          {targets}
        </GridList>
        <Divider />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <GridList cellHeight={'auto'} className={classes.gridList} cols={2} spacing={20}>
          {targets}
        </GridList>
      </React.Fragment>
    );
  };

  return (
    <div className="visualize-summary-pivot">
      <MfpSubheader title={'Create Smart Plan'} />
      <section className="submission-wizard-sections">
        {steps ? steps.map(renderSteps) : null}
      </section>
      <ScopebarOptionModal isVisible={isSaveModalVisible} {...saveModalProps} />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(SmartPlanWizard);
