import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewDefnState } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { fetchListData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { BasicPivotItem, ListDataOptions, Pivot } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfigureOvertimeConfig } from '../OvertimeView.types';
import { Option, OptionGroup } from 'src/components/Configure/ConfigureModal';
import { TransposedColumns } from 'src/services/configuration/codecs/viewdefns/general';

export interface nestedOvertimeSlice {
  viewDataState: ViewDataState;
  viewDefnState: ViewDefnState;
  viewDefn: ConfigureOvertimeConfig;
  data: BasicPivotItem[];
  configureSelections: Option[];
  defaultConfigureSelections: Option[];
  configureIsOpen: boolean;
  uncommitedConfigureSelections?: Option[];
  configureOptions?: OptionGroup[];
  search?: string;
  topMember?: string;
  rowHeight: number;
  transposedColumns?: TransposedColumns;
  transposedSelections?: Option[];
};

const initialState: nestedOvertimeSlice = {
  viewDataState: ViewDataState.idle,
  viewDefnState: ViewDefnState.idle,
  viewDefn: {} as ConfigureOvertimeConfig,
  data: [],
  configureSelections: [],
  defaultConfigureSelections: [],
  uncommitedConfigureSelections: [],
  configureOptions: [],
  configureIsOpen: false,
  topMember: '',
  rowHeight: 30,
};

const nestedOvertimeSlice = createSlice({
  name: 'NestedOvertime',
  initialState,
  reducers: {
    requestTenantConfig(state) {
      state.viewDefnState = ViewDefnState.loading;
    },
    receiveTenantConfig(
      state,
      action: PayloadAction<{
        defaultConfigureSelections: Option[];
        configureOptions: OptionGroup[];
        configureSelections: Option[];
        viewDefn: ConfigureOvertimeConfig;
        rowHeight: number;
        topMember?: string;
        transposedColumns?: TransposedColumns;
        transposedSelections?: Option[];
      }>
    ) {
      const {
        viewDefn,
        defaultConfigureSelections,
        configureOptions,
        configureSelections,
        rowHeight,
        topMember,
        transposedColumns,
        transposedSelections
      } = action.payload;
      state.viewDefn = viewDefn;
      state.configureOptions = configureOptions;
      state.viewDefnState = ViewDefnState.loaded;
      state.defaultConfigureSelections = defaultConfigureSelections;
      state.configureSelections = configureSelections;
      state.rowHeight = rowHeight;
      if(transposedColumns){
        state.transposedColumns = transposedColumns;
        state.transposedSelections = transposedSelections;
      }
      if (topMember) {
        state.topMember = topMember;
      }
    },
    updateTenantConfig(state, action: PayloadAction<ConfigureOvertimeConfig>) {
      state.viewDefn = action.payload;
    },
    requestData(state) {
      state.viewDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveLiveData(state, action: PayloadAction<Pivot>) {
      state.viewDataState = ViewDataState.liveDataReady;
      state.data = action.payload.tree;
    },
    setTopMember(state, action: PayloadAction<string>) {
      state.topMember = action.payload;
    },
    setConfigureSelections(state, action: PayloadAction<Option[]>) {
      state.configureSelections = action.payload;
    },
    setTransposedSelections(state, action: PayloadAction<Option[]>) {
      state.transposedSelections = action.payload;
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestTenantConfig,
  receiveTenantConfig,
  updateTenantConfig,
  requestData,
  receiveLiveData,
  setTopMember,
  setConfigureSelections,
  setTransposedSelections,
  receiveError,
  cleanUp,
} = nestedOvertimeSlice.actions;

export function fetchNestedOvertimeData(nestedOvertimeDefn: string, options: ListDataOptions) {
  return fetchListData(nestedOvertimeDefn, requestData, receiveLiveData, options);
}
export default nestedOvertimeSlice.reducer;
