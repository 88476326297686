import { style } from 'typestyle';
import { important } from 'csx';

const planogramSimpleContainer = style({
  $debugName: 'planogram-simple',
  width: '100%',
  height: '100%',
  display: 'flex',
  $nest: {
    ['.companion']: {
      display: 'flex',
      paddingTop: '10px',
      border: '1px solid #cecece',
      flexDirection: 'column',
      $nest: {
        ['.companion-list-view']: {
          height: '100%',
        },
        ['& > span']: {
          margin: 'auto',
          fontWeight: 'bold',
          fontSize: '.9rem',
          paddingRight: 10,
        },
      },
    },
  },
});
const planogramHeader = style({
  $debugName: 'planogram-header',
  display: 'flex',
  $nest: {
    ['& > span']: {
      marginLeft: 'auto',
      marginRight: '3em',
    },
  },
});
const planogramButton = style({
  color: important('white'),
});
export { planogramSimpleContainer, planogramHeader, planogramButton };
