import { noop } from 'lodash';
import React from 'react';
import { loading } from 'src/components/Loading';
import ImportFromVersion from '../ImportFromVersion/ImportFromVersion';
import EopToBopModal from '../MfpScopeSelector/EopToBopModal';
import {
  openSubmitQaSelector,
  openCopyQaSelector,
  openVersionQaSelector,
  openImportQaSelector,
  openReseedQaSelector,
  openCommentsQaSelector,
} from '../MfpScopeSelector/MfpScopebar.types';
import ScopebarOption from '../MfpScopebarOption/ScopebarOption';
import ReseedPlanModal from '../Reseed/ReseedPlanModal';
import SaveVersion from '../SaveVersion/SaveVersion';
import SubmitPlan from '../SubmitPlan/SubmitPlan';

import { MfpSubheaderDispatchProps, mapDispatchToProps, mapStateToProps } from './MfpSubheader.container';
import { connect } from 'react-redux';
import LockToggle, { LockToggleProps } from 'src/components/LockToggle/LockToggle';
import './_MfpSubheader.scss';
import ServiceContainer from 'src/ServiceContainer';
export interface MfpSubheaderValueProps {
  activeExtraBtns?: string[];
  hasEditableRevision: boolean;
  commentsOpen: boolean;
}
interface MfpSubheaderProps extends MfpSubheaderValueProps, MfpSubheaderDispatchProps {
  title: string;
  lockToggleParams?: LockToggleProps;
}
const MfpSubheader = ({
  activeExtraBtns,
  hasEditableRevision,
  commentsOpen,
  title,
  lockToggleParams,
  toggleRightContainer
}: MfpSubheaderProps) => {
  const scopebarOptions = {
    submit: {
      text: 'Submit',
      dataQa: openSubmitQaSelector,
      itemClass: 'submit-button',
      iconClass: 'fas fa-fw fa-paper-plane',
      isDisabled: !hasEditableRevision,
      optionModalProps: {
        modalDataQa: 'submit-plan-modal',
        modalTitle: 'Submit Plan',
        modalClassName: 'import-version-modal',
        modalBodyComponent: SubmitPlan,
        modalBodyComponentProps: {
          onItemChange: noop,
          onSubmit: noop,
          loading: loading,
        },
      },
    },
    eoptobop: {
      text: 'Copy EOP to BOP',
      dataQa: openCopyQaSelector,
      iconClass: 'far fa-fw fa-copy',
      isDisabled: !hasEditableRevision,
      optionModalProps: {
        modalDataQa: undefined,
        modalTitle: 'Copy EOP to BOP',
        modalClassName: 'import-version-modal',
        modalBodyComponent: EopToBopModal,
        modalBodyComponentProps: {
          copyPeriod: undefined,
          copyVersionOptions: undefined,
          copyVersion: undefined,
          onItemChange: noop,
          onSubmit: undefined,
          loading: loading,
        },
      },
    },
    save: {
      text: 'Save As...',
      dataQa: openVersionQaSelector,
      iconClass: 'far fa-fw fa-save',
      isDisabled: !hasEditableRevision,
      optionModalProps: {
        modalTitle: 'Save as Version',
        modalClassName: 'save-version-modal',
        modalDataQa: 'save-version-modal',
        modalBodyComponent: SaveVersion,
        modalBodyComponentProps: {
          loading: loading,
          onSubmit: noop,
          onItemChange: noop,
        },
      },
    },
    import: {
      text: 'Import from Version',
      dataQa: openImportQaSelector,
      iconClass: 'far fa-fw fa-file-import',
      isDisabled: !hasEditableRevision,
      optionModalProps: {
        modalTitle: 'Import Version from',
        modalClassName: 'import-version-modal',
        modalDataQa: 'import-version-modal',
        modalBodyComponent: ImportFromVersion,
        modalBodyComponentProps: {
          loading,
          onSubmit: undefined,
          onItemChange: noop,
        },
      },
    },
    reseed: {
      text: 'Reseed',
      dataQa: openReseedQaSelector,
      iconClass: 'fas fa-fw fa-sync',
      isDisabled: !hasEditableRevision,
      optionModalProps: {
        modalTitle: 'Reseed Working Plan',
        modalClassName: 'import-version-modal',
        modalDataQa: 'reseed-modal',
        modalBodyComponent: ReseedPlanModal,
        modalBodyComponentProps: {
          onItemChange: noop,
          onSubmit: undefined,
          loading: loading,
        },
      },
    },
    comment: {
      text: 'Comment',
      dataQa: openCommentsQaSelector,
      isDisabled: !hasEditableRevision,
      iconClass: 'fal fa-fw fa-pencil',
      itemClass: commentsOpen ? 'open' : 'closed',
      onClick: toggleRightContainer
    },
  };

  return (
    <div className="header-content">
      <h1 className="title">{title}</h1>

      <div className="header-buttons">
        {activeExtraBtns &&
          activeExtraBtns.map((subheaderBtn, index) => {
            const buttonProps = scopebarOptions[subheaderBtn.toLowerCase()];
            if (!buttonProps) {
              ServiceContainer.loggingService.error(
                `Warning: Subheader button ${subheaderBtn} was configured but not a valid option`
              );
              return;
            }
            return <ScopebarOption key={index} {...buttonProps} />;
          })}
        {lockToggleParams && <LockToggle {...lockToggleParams} />}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MfpSubheader);
