import React from 'react';
import Renderer from 'src/utils/Domain/Renderer';
import * as AgGrid from '@ag-grid-community/core';
import { create, all, parse, evaluate, round, MathNode } from 'mathjs';
import type { EditableCallbackParams, ColDef, ColGroupDef, ValueGetterParams } from '@ag-grid-community/core';
import { onGridExport, GridExportType, isGridApiReady } from '../TargetList/TargetList.utils';
// take this line out if you do not want to use ag-Grid-Enterprise
import '@ag-grid-enterprise/core';
import { map, find, flatten, set, noop, flow, has, isNil, cloneDeep } from 'lodash/fp';
import { toast } from 'react-toastify';
import { TargetSettingConfigColumn, TrueColDef, Props, GridField, State, StyleIProps } from './TargetSetting.types';
import * as TargetSettingStyles from './TargetSetting.styles';
import { aggFuncs, percentParser, isInvalidNumber } from './TargetSetting.utils';
import coalesce from 'src/utils/Functions/Coalesce';
import Subheader from 'src/components/Subheader/Subheader.container';
import { fabBtn } from '../TargetList/TargetList.styles';
import { withStyles, createStyles, Theme, Button, Fab } from '@material-ui/core';
import { forEach as forEachWithKey, sum, delay, filter, isNumber, debounce, defaultTo } from 'lodash';
import Modal from '@material-ui/core/Modal';
import { default as SeedOptions } from '../SeedOptions/SeedOptions.container';
import { Lens } from 'monocle-ts';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { classes } from 'typestyle';
import MacroSummaryGrid from '../MacroSummaryGrid/MacroSummaryGrid';
import { TargetSettingConfiguration } from './TargetSetting.types';
import { Overlay } from 'src/common-ui';
import { multiHeaderDecorate } from './NestedHeader';
import ComboGraph from './ComboGraph/ComboGraph';
import { ComboRowObject } from './ComboGraph/ComboGraph.types';
import { LYTargetData } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { TargetSettingNewSlice } from '../TargetCreation/TargetCreation.types';
import TargetSettingReconcileModal from './TargetSettingReconcileModal';
import TooltipRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import {
  viewDefnWhitelistToNarrowedCharacterWhitelist,
  TextValidationEditor,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import container from 'src/ServiceContainer';
import { getFrameworkComponents } from 'src/utils/Component/AgGrid/AgConfigParse';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import ServiceContainer from 'src/ServiceContainer';
import { CellEditingStartedEvent, ColumnApi, IRowNode } from '@ag-grid-community/core';
import { MacrosProps } from 'src/common-ui/components/Macros/Macros.types';
import { defaultRowHeight } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.styles';

const math = create(all)

const frameworkComponents = {
  tooltipRenderer: TooltipRenderer,
  textValidationEditor: TextValidationEditor,
  ...getFrameworkComponents(),
};

export const styles = ({ spacing }: Theme) =>
  createStyles({
    button: {
      margin: spacing(1),
    },
    input: {
      display: 'none',
    },
  });

export class TargetSetting extends React.Component<StyleIProps, State> {
  state: State;
  gridApi: AgGrid.GridApi | null = null;
  gridColumnApi: ColumnApi | null = null;

  constructor(props: StyleIProps) {
    super(props);
    this.state = {
      gridReady: false,
      loadTargetOpen: false,
      seedOpen: false,
      visualizeOpen: false,
      mockPlaceholderLoading: false,
    };

    const getColAgg = (args: MathNode[], _math: unknown, _scope: Record<string, any>) => {
      const str = args.map((arg) => {
        if (arg.value) {
          return arg.value as string;
        } else if (arg.name) {
          return arg.name as string;
        } else {
          return '';
        }
      });
      const api = this.gridApi;
      if (str.length <= 0 || isNil(api) || isNil(this.props.config)) {
        return 0;
      }

      const dataIndex = str[0];
      const col = this.gridColumnApi?.getColumn(dataIndex) || null;
      if (!col) {
        return 0;
      }
      const aggFunc = col.getAggFunc();
      const aggName = !isNil(aggFunc) ? aggFunc.toString() : '';
      const values: IRowNode[] = [];
      api.forEachLeafNode((node) => {
        // using api variable instead of this.gridApi because not sure what fallback values are valid
        // ex: undefined vs. null vs. ""
        const nodeVal = api.getValue(col, node)
          values.push(nodeVal);
      });
      if ((api as any).aggFuncService.aggFuncsMap[aggName] == null) {
        return this.getAggFunc(col)();
      } else {
        return (api as any).aggFuncService.aggFuncsMap[aggName](values);
      }
    };
    (getColAgg as any).rawArgs = true;
    math.import?.(
      {
        AGG: getColAgg,
        isNil: (arg: unknown) => {
          return isNil(arg) || arg === '';
        },
      },
      {
        override: true,
      }
    );
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.config !== this.props.config) {
      this.updateAggFuncs();
    }
    if (oldProps.seedValue !== this.props.seedValue && isGridApiReady(this.gridApi)) {
      this.gridApi?.refreshCells({
        force: true,
      });
      this.gridApi?.refreshClientSideRowModel('aggregate');
      this.forceUpdate();
    }
    if (oldProps.rowData !== this.props.rowData && isGridApiReady(this.gridApi)) {
      this.updateTotalCol();
    }
  }

  componentWillUnmount() {
    this.props.onDestroy();
  }

  createColumnDefs = (columnInfo: TargetSettingConfigColumn): TrueColDef => {
    const options: TrueColDef = {
      headerName: columnInfo.text,
      colId: columnInfo.dataIndex,
      pinned: columnInfo.pinned,
      children: [],
      hide: columnInfo.hidden,
      cellClass: TargetSettingStyles.cell,
    };
    if (columnInfo.width) {
      options.width = columnInfo.width;
    }
    // TODO: Correct this type scheme (it's **SORT OF** of duplicate, except we change the children)
    const tmpColumn: any = cloneDeep(columnInfo);
    if (tmpColumn.children) {
      for (const i in tmpColumn.children) {
        if (tmpColumn.children[i].children) {
          tmpColumn.children[i] = this.createColumnDefs(tmpColumn.children[i]);
        } else {
          tmpColumn.children[i] = this.prepareField(tmpColumn.children[i]);
        }
      }
      return {
        ...options,
        children: tmpColumn.children,
      };
    }
    return this.prepareField(tmpColumn);
  };

  prepareField = (columnInfo: any) => {
    if (columnInfo.headerName) {
      return columnInfo;
    }
    const options: TrueColDef = {
      headerName: columnInfo.text,
      colId: columnInfo.dataIndex,
      pinned: columnInfo.pinned,
      cellClass: TargetSettingStyles.cell,
      renderer: columnInfo.renderer,
      hide: columnInfo.hidden,
      width: columnInfo.width,
    };
    const params = this.props.routerParams;
    const version = params.version;
    if (columnInfo.editable === true && version === 'WP') {
      const isEditable:AgGrid.EditableCallback =(params: EditableCallbackParams) => {
        return isNil(params.node.rowPinned);
      }
      options.editable = isEditable;
      const cellClassFunc: AgGrid.CellClassFunc = (params) => {
        return isNil(params.node.rowPinned)
          ? [TargetSettingStyles.cell, TargetSettingStyles.editableColumn]
          : [TargetSettingStyles.cell];
      }
      options.cellClass = cellClassFunc;
      if (columnInfo.renderer === 'percent') {
        options.valueParser = percentParser;
      }
    }
    if (columnInfo.calculation != null) {
      const calculation = columnInfo.calculation;
      // @ts-ignore columns and the grid have value and data arguments that we haven't added anywhere
      options.valueGetter = this.createCalculation(calculation);
    } else {
      options.field = columnInfo.dataIndex;
    }
    if (columnInfo.aggregatorFunction) {
      options.aggFunc = columnInfo.aggregatorFunction;
      if (isGridApiReady(this.gridApi)) {
        if (Object.keys(aggFuncs).find((key) => key === columnInfo.aggregatorFunction) == null) {
          this.addAggFunc(columnInfo);
        }
      }
    } else if (columnInfo.aggregator) {
      options.aggFunc = columnInfo.aggregator;
    } else if (columnInfo.dataIndex === 'name') {
      options.footerValueGetter = () => 'Total';
    }
    if (columnInfo.renderer) {
      let isRenderedObject = false;
      if (Renderer[columnInfo.renderer]) {
        // If the renderer is an object, it needs a cellRenderer and NO valueFormatter
        // Determine the value and see if the result is an object or string
        const renderedValue = Renderer[columnInfo.renderer](0);
        isRenderedObject = typeof renderedValue == 'object';
        if (isRenderedObject) {
          options.cellRendererSelector = () => {
            return {
              component: columnInfo.renderer, ///'arrowPercentTwoDecimal',
            };
          };
        }
      }
      // Use valueFormatter for strings, not objects
      if (!isRenderedObject) {
        options.valueFormatter = (params) => {
          if (isNil(params.value) || isNaN(params.value as number) || params.value === 'NaN' || params.value === '') {
            return '';
          } else if (!isNil(columnInfo.renderer)) {
            if (Renderer[columnInfo.renderer]) {
              return Renderer[columnInfo.renderer](params.value);
            }
          }
          return params.value;
        };
      }
      if (columnInfo.renderer === 'tooltipRenderer') {
        options.cellRendererSelector = () => {
          return {
            component: 'tooltipRenderer',
          };
        };
      }
    }

    if (columnInfo.inputType === 'textValidator') {
      options.cellEditorSelector = () => {
        const inputParams = columnInfo.inputParams;
        const whitelist = viewDefnWhitelistToNarrowedCharacterWhitelist(inputParams.whitelist);

        return {
          component: 'textValidationEditor',
          params: {
            validateAsync: false,
            ...inputParams,
            whitelist,
          },
        };
      };
    }

    return { ...options };
  };

  createCalculation = (calculation: string) => {
    return (params: ValueGetterParams) => {
      const calc = parse(calculation);
      if (params.data == null) {
        return '';
      }
      const vars = {};
      calc
        .filter((node) => node.isSymbolNode)
        .forEach((node) => {
          const id = node.name || '';
          if (!has(id, vars) && !has(id, math)) {
            vars[id] = coalesce(params.getValue(id), params.data[id], '');
          }
        });
      let result;
      try {
        result = evaluate(calculation, {
          ...vars,
          seedval: this.props.seedValue,
        });
      } catch (e) {
        noop();
      }
      if (isInvalidNumber(result)) {
        result = 0;
      }
      return result;
    };
  };

  getValues = (dataIndex: string) => {
    if (isNil(this.gridColumnApi)) {
      return [];
    }

    const col = this.gridColumnApi.getColumn(dataIndex);
    if (col == null) {
      console.warn(`Column ${dataIndex} does not exist in grid.`);// eslint-disable-line no-console
      return [];
    }

    const values: unknown[] = [];
    this.gridApi?.forEachLeafNode((node) => {
      const columnValue = this.gridApi?.getValue(col.getId(), node);
      values.push(columnValue);
    });

    return values;
  };

  onTargetSettingExport = (exportType: GridExportType) => {
    const columnConfigs = !isNil(this.props.config) ? this.props.config.grid.columns : [];
    onGridExport(exportType, this.gridApi, columnConfigs);
  };

  saveTarget = () => {
    const rowsData: LYTargetData[] = [];
    this.gridApi?.forEachLeafNode((node) => {
      const row = node.data;
      const columns = this.gridColumnApi?.getColumns();
      // Override initial values with post-calculated values.
      if (!isNil(columns)) {
        columns.forEach((col: AgGrid.Column) => {
          row[col.getColId()] = this.getRowValue(node, col.getColId());
        });
        rowsData.push(row);
      }
    });
    this.props.saveTarget(rowsData);
  };

  getRowValue = (node: IRowNode, columnName: string) => {
    const col = this.gridColumnApi?.getColumn(columnName) || null;
    if (col == null) {
      console.warn(`${columnName} is not a column in grid.`);// eslint-disable-line no-console
      return 0;
    }
    return this.gridApi?.getValue(col, node);
  };

  getComboRowData = () => {
    const rowData: ComboRowObject[] = [];
    if (this.gridApi && this.props.config != null && this.props.config.comboGraph != null) {
      const { comboGraph } = this.props.config;
      this.gridApi.forEachLeafNode((node) => {
        let rowItem: ComboRowObject = { name: node.data.name, description: node.data.description };
        comboGraph.forEach((graphItem) => {
          // @ts-ignore
          rowItem = flow<ComboRowObject, ComboRowObject, ComboRowObject>(
            () => rowItem,
            set(graphItem.targetProperty, parseFloat(this.getRowValue(node, graphItem.targetProperty)) || 0),
            // @ts-ignore
            set(graphItem.lyProperty, parseFloat(this.getRowValue(node, graphItem.lyProperty)) || 0)
          )();
        });
        rowData.push(rowItem);
      });
    }
    return rowData;
  };

  publishTarget = () => {
    const rowData: LYTargetData[] = [];
    this.gridApi?.forEachLeafNode((node) => {
      rowData.push(node.data);
    });
    this.props.publishTarget(rowData);
  };

  getAggFunc = (column: any) => {
    let calc: MathNode;
    if (!column) {
      return () => 0;
    }

    if (column.aggregatorFunction) {
      calc = parse(column.aggregatorFunction);
    } else if (column.aggFunc) {
      calc = parse(column.aggFunc);
    } else {
      if (isNil(this.gridApi) || isNil(this.gridColumnApi)) {
        return () => 0;
      } else if (column.aggregator) {
        const values = this.getValues(column.dataIndex);
        const aggregatorFn = (this.gridApi as any).aggFuncService.aggFuncsMap[column.aggregator];
        return () => aggregatorFn(values);
      }

      // shouldn't reach this but fallback to sum just in case
      const values = this.getValues(column.dataIndex);
      return () => sum(values);
    }

    const globalVars = ['seedval'];
    return () => {
      const vars = {};
      calc
        .filter((node) => node.isSymbolNode)
        .forEach((node) => {
          const id = node.name || '';
          if (!has(id, vars) && !has(id, math) && globalVars.indexOf(id) < 0) {
            const vals = this.getValues(id).map((v) => {
              if (!isNumber(v)) {
                return parseFloat(v as string) || 0;
              }
              return v;
            });
            vars[id] = coalesce(vals, []);
          }
        });
      let result;
      try {
        result = calc.evaluate({
          ...vars,
          seedval: this.props.seedValue,
        });
      } catch (e) {
        debounce(() => {
          toast.error(`An error calculating aggregation`);
          ServiceContainer.loggingService.error(`An error calculating aggregation`);
        }, 1000)
      }
      if (isInvalidNumber(result)) {
        result = 0;
      }
      return result;
    };
  };

  addAggFunc = (column: TargetSettingConfigColumn) => {
    if (column.aggregatorFunction) {
      this.gridApi?.addAggFunc(column.aggregatorFunction, this.getAggFunc(column));
    }
  };

  updateAggFuncs = () => {
    if (!isGridApiReady(this.gridApi)) {
      return;
    }
    // TODO: need to merge mathjs & ag-grid types for func somehow
    // @ts-ignore
    forEachWithKey(aggFuncs, (func: (input: unknown[]) => unknown, key: string) => this.gridApi.addAggFunc(key, func));
    this.gridApi?.refreshCells({
      force: true,
    });
    this.gridApi?.refreshClientSideRowModel('aggregate');
  };

  initialize = () => {
    this.props.reinitTarget();
  };

  seedOptionsModal = () => {
    const { summary } = this.props,
      modalText = summary ? summary : '';
    return (
      <Modal
        open={this.state.seedOpen}
        onClose={() =>
          this.setState({
            seedOpen: false,
          })
        }
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '90vh',
            minWidth: '700px',
            backgroundColor: 'white',
          }}
        >
          <SeedOptions
            scopeConfigLens={this.props.scopeConfigLens}
            lens={this.props.targetCreationLens.compose(Lens.fromProp<TargetSettingNewSlice>()('seedPage'))}
            seedText={modalText}
            setSeed={() => {
              this.props.setSeed();
              this.setState({
                seedOpen: false,
              });
            }}
          />
        </div>
      </Modal>
    );
  };

  visualizeGraphsModal = () => {
    const config = this.props.config ? this.props.config.comboGraph : undefined;
    return (
      <Modal
        open={this.state.visualizeOpen}
        onClose={() =>
          this.setState({
            visualizeOpen: false,
          })
        }
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '90vw',
            height: '90vh',
            minWidth: '700px',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              width: '100%',
              height: 50,
              backgroundColor: TEAL_PRIMARY,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ padding: 10, color: 'white', fontSize: '1.2rem' }}>VISUALIZE</span>
            <i
              className={'fas fa-times'}
              style={{ padding: 10 }}
              onClick={() =>
                this.setState({
                  visualizeOpen: false,
                })
              }
            />
          </div>
          <div style={{ padding: 40 }}>
            {this.state.visualizeOpen ? <ComboGraph config={config} rowData={this.getComboRowData()} /> : null}
          </div>
        </div>
      </Modal>
    );
  };

  loadExistingTargetModal = () => {
    return (
      <Modal
        open={this.state.loadTargetOpen}
        onClose={() =>
          this.setState({
            loadTargetOpen: false,
          })
        }
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <article
          style={{
            maxWidth: 600,
            width: '60vw',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <header
            style={{
              backgroundColor: `${TEAL_PRIMARY}`,
              color: 'white',
            }}
          >
            Import From:
          </header>
          <section
            style={{
              flex: 1,
              display: 'flex',
              backgroundColor: 'white',
              flexDirection: 'column',
            }}
          >
            <Button
              style={{ height: 60 }}
              onClick={() => {
                this.props.import ? this.props.import('OP') : noop();
                this.setState({
                  loadTargetOpen: false,
                });
              }}
            >
              Original Plan
            </Button>
            <Button
              style={{ height: 60 }}
              onClick={() => {
                this.props.import ? this.props.import('RP') : noop();
                this.setState({
                  loadTargetOpen: false,
                });
              }}
            >
              {' '}
              Revised Plan{' '}
            </Button>
          </section>
        </article>
      </Modal>
    );
  };

  createGridSummary = (config: TargetSettingConfiguration) => {
    const getAgg = (index: string) => {
      return (math as any).AGG([{ value: index }]);
    };
    if (config.summary == null) {
      return <div />;
    }
    const props: MacrosProps[] = config.summary.items.map((item) => {
      const getRenderer = (colId: string) => {
        const column = flow(
          () => config.grid.columns,
          map((aColumn: TargetSettingConfigColumn) => {
            if (aColumn.children) {
              return aColumn.children;
            } else {
              return aColumn;
            }
          }),
          flatten,
          find((aColumn: GridField) => {
            if (aColumn.colId && aColumn.colId !== undefined) {
              return aColumn.colId === colId;
            } else if (aColumn.dataIndex && aColumn.dataIndex !== undefined) {
              return aColumn.dataIndex === colId;
            } else {
              return false;
            }
          })
        )();
        if (column != null && column.renderer) {
          return Renderer[column.renderer];
        } else {
          return (i: unknown) => i;
        }
      };

      const secondaryVal: string = defaultTo(getAgg(item.secondary), 0).toString();
      const primaryVal: string = defaultTo(getAgg(item.primary), 0).toString();
      const varianceVal: string = defaultTo(getAgg(item.variance), 0).toString();
      const roundedVarianceVal = round(Number(varianceVal), 2);
      const direction: 'up' | 'down' = isNumber(roundedVarianceVal) && roundedVarianceVal > 0 ? 'up' : 'down';
      const secondaryRendered = getRenderer(item.secondary)(secondaryVal),
        varianceRendered = getRenderer(item.variance)(varianceVal),
        primaryRendered = getRenderer(item.primary)(primaryVal);

      return {
        dataLoaded: true,
        metrics: {
          primary: {
            rendered: primaryRendered,
            label: item.text,
          },
          secondary: {
            rendered: secondaryRendered,
            label: 'LY',
            direction: undefined,
          },
          directional: {
            rendered: varianceRendered,
            label: 'Var LY',
            direction: direction,
          },
        },
        extraClasses: '',
      };
    });
    return <MacroSummaryGrid loaded={true} summaries={props} />;
  };

  renderLoadingScreen = () => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Overlay visible={true} type={'loading'} fitParent={true} />
      </div>
    );
  };

  resizeCols = () => {
    if (this.props.config) {
      const cols = this.props.config.grid.columns;
      cols.forEach((col) => {
        if (col.hidden) {
          this.gridColumnApi?.setColumnVisible(col.dataIndex, false);
        }
      });
      const allColumnIds: AgGrid.Column[] = [];
      this.gridColumnApi?.getColumns()?.forEach((column: AgGrid.Column) => {
        const childrenCols = flatten(cols.map((x) => x.children));
        const colConfig =
          cols.find((col) => col.dataIndex === column.getColId()) ||
          childrenCols.find((col) => (col ? col.dataIndex === column.getColId() : false));
        if (colConfig && colConfig.width) {
          return;
        }
        allColumnIds.push(column);
      });
      this.gridColumnApi?.autoSizeColumns(allColumnIds);
    }
  };

  updateTotalCol = () => {
      this.gridApi?.setRowData(
        this.props.rowData.map((row) => {
          row['TOTAL_GROUP'] = 'Total';
          return row;
        })
      );
      this.updateAggFuncs();
      delay(() => {
        this.resizeCols();
      }, 10);
  };

  render() {
    const { config, rowData, summary, reconcileConfig, routerParams } = this.props;

    const version = routerParams.version;
    if (config == null || rowData == null) {
      return this.renderLoadingScreen();
    }
    // TODO: use config for generating versions
    const HIDEVERSION = false;
    // TODO: use config to determine "group"-ing column
    const columnDefs: TrueColDef[] = filter(config.grid.columns, (v) => {
      return v.dataIndex != 'description';
    }).map((info: TargetSettingConfigColumn) => this.createColumnDefs(info));
    columnDefs.unshift({
      headerName: 'Group',
      colId: 'TOTAL_GROUP', // TODO: make a const
      field: 'TOTAL_GROUP',
      rowGroup: true,
      hide: true,
      cellRenderer: function() {
        return 'Total'; // TODO: pull from the "group"ing column def?
      },
    });
    let colDefs = columnDefs;
    const isMultiHeader = columnDefs.filter((colDef) => colDef.children && colDef.children.length).length;
    if (isMultiHeader) {
      colDefs = multiHeaderDecorate(columnDefs);
    }

    let reconcileColDefs;
    if (reconcileConfig) {
      const reconcileColumnDefs: TrueColDef[] = reconcileConfig.grid.columns.map((info) => this.createColumnDefs(info));
      const rIsMultiHeader = columnDefs.filter((colDef) => colDef.children && colDef.children.length).length;
      if (rIsMultiHeader) {
        reconcileColDefs = multiHeaderDecorate(reconcileColumnDefs);
      }
    }
    const gridOptions: DataGridProps = {
      data: rowData,
      isPrintMode: false,
      columnDefs: colDefs as (ColDef | ColGroupDef)[],
      loaded: true,
      singleClickEdit: true,
      rowHeight: defaultRowHeight,
      onGridReady: (params: AgGrid.GridReadyEvent) => {
        if (params.api && params.columnApi) {
          this.gridApi = params.api;
          this.gridColumnApi = params.columnApi;
          this.updateTotalCol();
          this.setState({
            gridReady: true,
          });

          delay(() => {
            this.gridApi?.refreshClientSideRowModel('aggregate');
            this.gridApi?.refreshCells();
            this.setState({
              gridReady: true,
            });
          }, 10);
        }
      },
      onCellEditingStarted: (params: CellEditingStartedEvent) => {
        const { colDef, value } = params;
        if (colDef.valueParser === percentParser) {
          const inputEl: HTMLInputElement = document.querySelector(
            `[col-id=${colDef.colId}] input`
          ) as HTMLInputElement;
          if (inputEl) {
            inputEl.value = `${value * 100}`;
            inputEl.select();
          }
        }
      },
      extraAgGridProps: {
        groupIncludeTotalFooter: false,
        rowBuffer: 50,
        headerHeight: 40,
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: false,
        },
        suppressColumnVirtualisation: true,
        suppressNoRowsOverlay: false,
        onCellValueChanged: () => {
          this.forceUpdate();
        },
        onCellEditingStopped: () => {
          this.forceUpdate();
        },
        suppressRowClickSelection: true,
        rowSelection: 'single',
        stopEditingWhenCellsLoseFocus: true,
        autoGroupColumnDef: {
          // TODO: pull from flagged column in config
          headerName: 'Description',
          field: 'description',
          pinned: true,
        },
        suppressAggFuncInHeader: true,
        groupDefaultExpanded: -1,
      },
    };
    return (
      <div className={TargetSettingStyles.container}>
        <Overlay type={'loading'} visible={this.props.isLoading || !this.state.gridReady} />
        <Subheader title={config.text || ''} summary={summary} />
        <div className={TargetSettingStyles.actionButtonGroup}>
          {this.props.config?.demoMode ? (
            <Button
              color="secondary"
              className={TargetSettingStyles.majorActionButton}
              style={{
                backgroundColor: TEAL_PRIMARY,
                color: 'white',
              }}
              hidden={version !== 'WP'}
              disabled={this.state.mockPlaceholderLoading}
              onClick={() =>
                this.setState(
                  {
                    mockPlaceholderLoading: true,
                  },
                  () => {
                    // Dear Mark,
                    // Please forgive me my trespasses
                    // Your friend,
                    // TODO
                    let redirectUrl = this.props.config?.createPlaceholders?.redirectUrl;
                    const dataApi = this.props.config?.createPlaceholders?.dataApi.isListData
                      ? this.props.config.createPlaceholders.dataApi
                      : undefined;
                    if (!dataApi) {
                      return;
                    }
                    const maybeParams: ListDataOptions = !isNil(this.props.floorset)
                      ? {
                          ...dataApi.params,
                          topMembers: this.props.floorset,
                        }
                      : dataApi.params;
                    container.pivotService
                      .listData(dataApi.defnId, ASSORTMENT, maybeParams)
                      .then(() => {
                        function onClickPlaceholders() {
                          // Url should be formatted like this Ex. /bottom-up/*** or bottom-up/
                          if (redirectUrl && redirectUrl[0] == '/') {
                            redirectUrl = redirectUrl?.substring(1, redirectUrl.length);
                          }
                          window.location.hash = `#/${redirectUrl}`;
                        }
                        toast(
                          <div onClick={onClickPlaceholders}>
                            Placeholder creation complete, click here to view placeholders.
                          </div>,
                          {
                            position: toast.POSITION.TOP_RIGHT,
                            closeOnClick: false,
                            autoClose: false,
                            draggable: false,
                            type: 'info',
                            toastId: 'goto-placeholders',
                          }
                        );
                        this.setState({
                          mockPlaceholderLoading: false,
                        });
                      })
                      .catch(() => {
                        toast.error('Error creating placeholders.');
                        this.setState({
                          mockPlaceholderLoading: false,
                        });
                      });
                  }
                )
              }
            >
              <i className={classes('fas fa-plus', TargetSettingStyles.majorActionButtonIcon)} />
              <span>Create Placeholders</span>
            </Button>
          ) : null}
          <Button
            color="secondary"
            className={TargetSettingStyles.actionButton}
            hidden={version !== 'WP'}
            onClick={() =>
              this.setState({
                seedOpen: true,
              })
            }
          >
            <i className={classes('fas fa-object-group', TargetSettingStyles.actionButtonIcon)} />
            <span>Reseed</span>
          </Button>
          <Button
            color="secondary"
            className={TargetSettingStyles.actionButton}
            hidden={version !== 'WP'}
            onClick={this.initialize}
          >
            <i className={classes('fas fa-redo', TargetSettingStyles.actionButtonIcon)} />
            <span>Reinitialize</span>
          </Button>
          <Button
            color="secondary"
            className={TargetSettingStyles.actionButton}
            hidden={HIDEVERSION || version !== 'WP'}
            onClick={this.publishTarget}
          >
            <i className={classes('fas fa-file-signature', TargetSettingStyles.actionButtonIcon)} />
            <span>Publish Target (OP/RP)</span>
          </Button>
          <Button
            color="secondary"
            className={TargetSettingStyles.actionButton}
            hidden={HIDEVERSION || version !== 'WP'}
            onClick={() => {
              this.setState({
                loadTargetOpen: true,
              });
            }}
          >
            <i className={classes('fas fa-file-import', TargetSettingStyles.actionButtonIcon)} />
            <span>Import</span>
          </Button>
          <Button
            color="secondary"
            className={TargetSettingStyles.actionButton}
            onClick={() =>
              this.setState({
                visualizeOpen: true,
              })
            }
          >
            <i className={classes('fas fa-chart-line', TargetSettingStyles.actionButtonIcon)} />
            <span>Visualize</span>
          </Button>
          {this.props.floorset && this.props.topCriteria ? (
            <TargetSettingReconcileModal
              rowData={rowData}
              colDefs={reconcileColDefs}
              floorset={this.props.floorset}
              topCriteria={this.props.topCriteria}
              frameworkComponents={frameworkComponents}
            />
          ) : (
            undefined
          )}
        </div>
        <section className="Summary">{this.createGridSummary(config)}</section>
          <ExtendedDataGrid {...gridOptions} frameworkComponents={frameworkComponents} className={TargetSettingStyles.dataGrid}/>
        <section className={fabBtn} hidden={version !== 'WP'}>
          <Fab color="secondary" aria-label="Create New Target" onClick={this.saveTarget}>
            <i className={classes('fas fa-save', TargetSettingStyles.addButton)} />
          </Fab>
        </section>
        {this.seedOptionsModal()}
        {this.visualizeGraphsModal()}
        {this.loadExistingTargetModal()}
      </div>
    );
  }
}

export default withStyles(styles)(TargetSetting);
