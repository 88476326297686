import { style } from 'typestyle';

export const tabStyle: any = {
  textTransform: 'initial',
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  width: 240,
};
export const tabClass = style({
  height: 60,
  $nest: {
    '& .s5-MuiTab-wrapper': {
      flexDirection: 'row-reverse',
      $nest: {
        '& .fa': {
          marginLeft: 7,
          marginTop: 7,
        },
      },
    },
  },
});
export const addNewTabClass = style({
  height: 60,
  $nest: {
    '& .s5-MuiTab-wrapper': {
      flexDirection: 'initial',
      $nest: {
        '& .fal': {
          marginRight: 5,
          marginTop: 5,
        },
      },
    },
  },
});
