import { Action, combineReducers } from 'redux';

import pages from 'src/pages/Pages.reducer';
import scope from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import subheader from 'src/components/Subheader/Subheader.slice';
import rightContainer from 'src/components/RightContainer/RightContainer.slice';
import filters from 'src/components/FilterPanel/FilterPanel.slice';
import sideNavReducer from 'src/components/Sidenav/SideNav.slice';
import print from 'src/components/higherOrder/Print/Print.slice';
import historyStylePane from 'src/components/HistoryStylePane/HistoryStylePane.reducer';
import planTracker from 'src/pages/AssortmentBuild/Planning.slice';
import appConfig from 'src/services/configuration/AppConfig.slice';
import quickReconcile from 'src/components/Reconcilation/QuickRecapView/QuickReconcile.slice';
import NavigationShellReducer from 'src/pages/NavigationShell/NavigationShell.slice';
import bulkImport from 'src/pages/Reporting/BulkImport/BulkImport.slice';
import worklist from 'src/pages/Worklist/Worklist.slice';
import cart from 'src/pages/AssortmentCart/AssortmentCart.slice';
import viewConfigReducer from 'src/state/ViewConfig/ViewConfig.slice';
import macroSummariesReducer from './components/Mfp/MacroSummaries/MacroSummaries.slice';
import favoritesReducer from './state/favorites/favorites.slice';
import scopeReducer from './state/scope/Scope.slice';
import settingsReducer from './state/settings/settings.slice';
import workingSetsReducer from './state/workingSets/workingSets.slice';
import chartReducer from 'src/components/Mfp/EditableChart/EditableChart.slice';
import scopeManagementReducer from './state/scope/ScopeManagement.slice';
import nonWorkingSetsReducer from 'src/state/workingSets/nonWorkingSets.slice';
import commentsReducer from 'src/components/Mfp/Comments/Comments.slice';
// old mfp rightcontainer
// import rightContainerReducer from 'src/components/RightContainer/RightContainer.slice';
import uiPoseReducer from 'src/state/uipose/UiPose.slice';
import TrendSummarySlice from 'src/components/Mfp/QuickTrends/TrendSummary/TrendSummary.slice';
import trendDetailsReducer from 'src/components/Mfp/QuickTrends/TrendDetails/TrendDetails.slice';

import { AppState } from './store';
import { isStepAction, StepAction } from './services/lenses/Lenses.actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import error from './components/ErrorBoundary/ErrorBoundary.slice';

const sidenavPersistConfig = {
  key: 'persistSideNav',
  storage,
  whitelist: ['collapsed'],
};
const uiPosePersistConfig = {
  key: 'persistUIPose',
  storage,
  whitelist: ['lastScopeCreated'],
};

const persistedSidenavReducer = persistReducer(sidenavPersistConfig, sideNavReducer);
const persistedUIPose = persistReducer(uiPosePersistConfig, uiPoseReducer);
export const appReducer = combineReducers({
  appConfig,
  scope,
  historyStylePane,
  rightContainer,
  pages,
  print,
  subheader,
  filters,
  bulkImport,
  error,
  sidenav: persistedSidenavReducer,
  perspective: NavigationShellReducer,
  quickReconcile,
  planTracker,
  worklist,
  cart,
  viewConfigSlice: viewConfigReducer,
  mfpScope: scopeReducer,
  scopeManagement: scopeManagementReducer,
  settings: settingsReducer,
  favorites: favoritesReducer,
  workingSets: workingSetsReducer,
  macroSummaries: macroSummariesReducer,
  nonWorkingSets: nonWorkingSetsReducer,
  comments: commentsReducer,
  uiPose: persistedUIPose,
  chart: chartReducer,
  trendDetails: trendDetailsReducer,
  trendSummary: TrendSummarySlice,
});

export const rootReducer = (state: AppState | undefined, action: Action): ReturnType<typeof appReducer> => {
  // this exists a fall back to resetting initial state
  // doesnt actually work though, because the scope is immediately refetched
  if (action.type === 'RESET_THE_WORLD') {
    return appReducer(window.__debug.bootState, action);
  }
  if (isStepAction(action) && state) {
    return (action as StepAction<AppState>).step(state);
  }

  return appReducer(state, action);
};
