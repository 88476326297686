import { connect } from 'react-redux';
import { flow, isNil, get } from 'lodash';
import { z } from 'zod';

import CardView from 'src/components/StandardCardView/StandardCardView';
import { DispatchProps, IdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.types';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { MACRO_TRENDS_WARNING_MESSAGE } from 'src/pages/Hindsighting/MacroTrends/MacroTrends';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { requestTenantConfig, receiveTenantConfig, receiveError } from './TopPerformers.slice';
import { getProcessedData } from './TopPerformers.selectors';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { retrieveIdentityPropsConfig } from 'src/components/StandardCardView/StandardCardView.utils';
import { FabType, withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { AppState, AppThunkDispatch } from 'src/store';
import { TopPerformersComponentProps } from 'src/services/configuration/codecs/confdefnComponentProps';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { getfabProps } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';

export interface TopPerformersOwnProps extends z.infer<typeof TopPerformersComponentProps> {}

function mapStateToProps(state: AppState, { title, defns, showFlowStatus }: TopPerformersOwnProps) {
  const { viewDefnState, viewDataState, viewDefn, identityPropsConfig } = state.pages.hindsighting.topPerformers;
  const loaded = isViewDefnLoaded(viewDefnState) && isDataLoaded(viewDataState);
  const adornments: AdornmentType[] = get(viewDefn, 'adornments', []);
  // currently does not support a fab
  const fabProps = getfabProps(state, FabType.none);

  return {
    title,
    loaded,
    config: viewDefn,
    showLookBackPeriod: true,
    sortBy: state.subheader.sortBy,
    groupBy: state.subheader.groupBy,
    subheaderViewDefns: defns.subheader,
    filteredStyles: [], // no fab logic, so not necessary
    groupedStyles: getProcessedData(state),
    summary: [],
    identityPropsConfig,
    cardType: 'TopCard',
    errorCondition: MACRO_TRENDS_WARNING_MESSAGE,
    flowStatus: state.subheader.flowStatus,
    showLimit: !isNil(defns.subheader.countLimit),
    fabType: FabType.none,
    fab: fabProps,
    viewDataState,
    showFlowStatus,
    adornments,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, { defns }: TopPerformersOwnProps): DispatchProps {
  return {
    onShowView() {
      const client = container.tenantConfigClient;
      dispatch(requestTenantConfig());
      client
        .getTenantViewDefns({
          defnIds: defns.view,
          appName: ASSORTMENT,
        })
        .then((resp: (TenantConfigViewData & { identityProps?: IdentityPropsConfig })[]) => {
          // FIXME, this should be in the epic, please don't let this survive a regular weekday merge
          const identityPropsConfig = retrieveIdentityPropsConfig(resp[0])!;

          return dispatch(
            receiveTenantConfig({
              calcViewDefn: resp[0].view[0],
              viewDefn: resp[0].view[1],
              identityPropsConfig,
            })
          );
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.topPerformers,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    showStylePane(_item: ContainerPayload) {
      // do nothing
    },
    onItemClicked(_item: ContainerPayload) {
      // do nothing
    },
    onConfigUpdate(_config: TenantConfigViewData) {
      // do nothing
    },
  };
}

const wrappedView = flow(() => CardView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);
