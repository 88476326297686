import { AxisLabelsFormatterContextObject, AxisOptions, Point, SeriesLineOptions } from 'highcharts';
import { concat, flatten, flatMap, isEmpty, isNil, reduce, uniq } from 'lodash';
import { GRAY } from 'src/utils/Style/Theme';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TimeChartConfig } from './TimeChart';
import { px } from 'csx';

export const DEFAULT_TIMECHART_HEIGHT = 300;

export const axesDefaults: AxisOptions = {
  title: undefined,
  minorGridLineWidth: 0,
  lineColor: 'transparent',
  minorTickLength: 0,
  tickLength: 0,
  labels: {
    style: {
      fontSize: '14px',
    },
  },
};

export function generateLineGraphConfig(
  aggBy: string,
  config: TimeChartConfig,
  data: BasicPivotItem[],
  selectedMetricIndex: number
): Highcharts.Options {
  const xAxisLabels = uniq(data.map((x) => x[config.timeLevel]));
  const seriesLabels: string[] = uniq(
    flatten(
      data.map((dataItem) => {
        return isEmpty(dataItem.children) ? dataItem[aggBy] : dataItem.children.map((child) => child[aggBy]);
      })
    )
  ).sort();
  const graphConfigs = config.graphs.primary;
  const selectedGraphConfig = graphConfigs[selectedMetricIndex].left;
  const flattenedLeafNodes = flatMap(data, (dataItem) =>
    !isEmpty(dataItem.children) ? [...dataItem.children] : dataItem
  );

  const series: SeriesLineOptions[] = seriesLabels.map((seriesLabel) => {
    // iterate each xAxis label, finding the value for that series at that timeLevel (xAxisLabel)
    // if a value for that series at that timeLevel does not exist insert a zero value (this is an assumption for now)
    const seriesData = xAxisLabels.map((xAxisLabel) => {
      const node = flattenedLeafNodes.find(
        (node) => node[config.timeLevel] === xAxisLabel && node[aggBy] === seriesLabel
      );
      return node ? node[selectedGraphConfig?.dataIndex || ''] : 0;
    });

    return {
      type: 'line',
      description: seriesLabel,
      name: seriesLabel,
      data: seriesData,
      tooltip: {
        pointFormatter: function(this: Point) {
          return `${this.series.name} : <b>${this.y}</b> <br />`;
        },
      },
    };
  });

  const totalSeries: SeriesLineOptions = {
    type: 'line',
    name: 'Total',
    description: 'Total of all series',
    data: reduce(
      series,
      (sums, currentSeries) => {
        const seriesData = currentSeries.data || [];
        return seriesData.map((data, index) => {
          return isNil(sums[index]) ? data : sums[index] + data;
        });
      },
      new Array(series.length)
    ),
    color: GRAY,
    tooltip: {
      pointFormatter: function(this: Point) {
        return `${this.series.name} : <b>${this.y}</b>`;
      },
    },
    dashStyle: 'ShortDash',
  };

  return {
    legend: {
      enabled: true,
    },
    credits: { enabled: false },
    chart: {
      height: px(DEFAULT_TIMECHART_HEIGHT),
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      categories: xAxisLabels,
      title: {
        text: null,
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        formatter: function(this: AxisLabelsFormatterContextObject<number>) {
          return `<span style="font-style: italic;">${this.value}</span>`;
        },
      },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: concat(series, totalSeries),
    tooltip: {
      split: false,
      shared: true,
      headerFormat: undefined, // this hides the xAxis tooltip when hovering over a series
      hideDelay: 0,
      outside: true,
    },
  };
}

export const defaultChartOptions: Highcharts.Options = {
  legend: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  chart: {
    height: 200,
  },
  yAxis: [
    {
      minorTicks: false,
      plotLines: [
        {
          // highlight y-axis zero
          color: '#696969',
          id: 'plotLineZero',
          width: 2,
          value: 0.0,
        },
      ],
    },
    {
      minorTicks: false,
    },
  ],
  xAxis: {
    minorTicks: false,
    crosshair: true,
  },
  plotOptions: {
    series: {
      lineWidth: 2,
      marker: {
        symbol: 'circle',
        lineWidth: 1,
      },
    },
  },
  tooltip: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
    borderWidth: 2,
    style: {
      color: '#000000',
    },
    padding: 5,
    split: true,
  },
};
