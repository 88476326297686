import { BasicPivotItem } from 'src/worker/pivotWorker.types';

function step(node: BasicPivotItem, acc: BasicPivotItem[]) {
  const children = node.children;

  if (children && children.length) {
    children.forEach((child) => step({ ...child }, acc));
  } else {
    acc.push(node);
  }
}

export function flattenToLeaves(treeData: BasicPivotItem[]) {
  const flat: BasicPivotItem[] = [];
  const firstIterable = Array.isArray(treeData) ? treeData : [treeData];
  firstIterable.forEach((item) => step(item, flat));
  return flat;
}
