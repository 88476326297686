import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, StrictDropdownItemProps } from 'semantic-ui-react';
import _, { mapValues } from 'lodash';
import { AnchorRadioSelectProps } from 'src/components/AnchorRadioSelect/AnchorRadioSelect';
import './_AnchorDropdownSelect.scss';
import '../AnchorRadioSelect/_AnchorRadioSelect.scss';


export default function AnchorDropdownSelect(props: AnchorRadioSelectProps) {
  const { anchor, onUpdateAnchorSelections, labelDimenion } = props;
  // shallow copy scope and set initialState default to first element of each key in object
  let initialState = mapValues(anchor, (ids) => [ids[0].id]);
  //filter any object has array.length > 2
  const multiScopeKeys = Object.keys(anchor).filter(key => anchor[key].length >= 2);
  const [selections, setSelections] = useState(initialState);

  useEffect(() => {
    // fire onChange on first render to set defualts as well as when selections change
    onUpdateAnchorSelections(selections);
  }, [onUpdateAnchorSelections, selections]);

  const handleOnChange = useCallback((_evt: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const newSelections = { ...selections, [data.id]: [data.value] };
    setSelections(newSelections);
  }, [selections]);

  const checkboxes = multiScopeKeys.map(key => {
    const dropdownOptions = anchor[key].map((anch) => {
      return {
        value: anch.id,
        text: anch[labelDimenion[key]],
        dimension: key
      };
    });
    return <div key={key} className='anchor-select'>
      <div className='checkbox-group-label'>
        {key.charAt(0).toUpperCase() + key.substring(1)}
      </div>

      <Dropdown
        placeholder={`Select ${key}`}
        id={key}
        selection={true}
        value={selections[key][0]}
        fluid={true}
        options={dropdownOptions}
        onChange={handleOnChange}
      />
    </div>;
  });

  return (<div className='checkbox-group'>
    {checkboxes}
  </div>

  );
}
