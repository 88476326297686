import { ICellEditorParams } from '@ag-grid-community/core';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';

export interface ConfigurableDataColumn {
  text: string;
  dataIndex: string;
  editable: boolean;
  renderer: string;
  groupingKey: string;
  calculation?: string;
  editableByCalc?: string;
}

export interface ConfigurableDataRuleEditDependencies {
  [attribute: string]: string;
}

// TODO: add additional types here that require specific logic
// e.g. for handling logic for receipts or sales adjustment
export enum ConfigurableDataModalClientHandler {
  receipts = 'receipts',
}
export interface ConfigurableDataRules {
  clientHandler: ConfigurableDataModalClientHandler;
  percentage: {
    referenceColumns: Record<string, string>;
  };
}

export enum ConfigurableDataModalDataHandler {
  receipts = 'receipts',
  pivot = 'pivot',
  attribute = 'attribute',
  granular = 'granular',
}

export interface ReceiptDataHandler {
  type: ConfigurableDataModalDataHandler.receipts;
  defnId: string;
  url: string;
  apiIdDataIndex: string;
}
export interface GranularDataHandler {
  type: ConfigurableDataModalDataHandler.granular;
  url: string;
  coordinateMap: { [k: string]: string };
}
export interface PivotDataHandler {
  type: ConfigurableDataModalDataHandler.pivot;
  defnId: string;
  params: { [k: string]: string };
}

export interface AttributeDataHandler {
  type: ConfigurableDataModalDataHandler.attribute;
  url: string;
  keys: { [k: string]: string };
}

export interface ConfigurableDataConfig {
  title: string;
  dataHandlers: {
    fetch: ReceiptDataHandler | PivotDataHandler;
    update: ReceiptDataHandler | AttributeDataHandler | GranularDataHandler;
  };
  columns: ConfigurableDataColumn[];
  dataSectionHeaders: {
    top: string;
    bottom: string;
    bottomRowsDataIndex: string;
  };
  rowSortByDataIndex: string;
  dataRules: ConfigurableDataRules;
  // consumers of modal editors may expect certain response props from getValue
  valueHandlerPropMappings?: Record<string, string>;
  showTotalRow?: boolean; // SPIKE should this be part of a new larger top/bottom or section config?
}

export interface FormattedSizingData {
  [week: string]: {
    items: ViewSizingData[];
  };
}

export interface ConfigurableDataModalProps extends ICellEditorParams {
  configApi: ClientDataApi;
  floorset: string;
  isEditable: boolean; // certain views will not allow edits at all
  cellDataIndex: string; // used when returning after edit changes canceled
  renderTabs?: boolean;
}

export interface ConfigurableDataModalState {
  isLoading: boolean;
  editsSaved: boolean;
  config: ConfigurableDataConfig | null;
  data: FormattedSizingData;
  activeTabIndex: number;
  width: number | null;
  height: number | null;
}

export enum RenderSectionType {
  top = 'top',
  bottom = 'bottom',
}

export interface ServerSizingData {
  id: string;
  week: string;
  stylecolor: string;
  stylecolorsize: string;
  channel: string;
  [key: string]: string | number | null;
}

export type ViewSizingData = ServerSizingData & {
  editPercentages?: Record<string, number>; // percentage weight of edits
};

// Types following are specifically for rendering just the section rows (including totals row)

export interface ConfigurableDataSectionHeaderProps {
  headers: string[];
}

export interface ConfigurableDataSectionRowCellConfig {
  rowHeaderDataIndexValue: string;
  dataIndex: string;
  editable: boolean;
  editableByCalc?: string;
}
export interface ConfigurableDataSectionRowCellProps {
  cellConfig: ConfigurableDataSectionRowCellConfig;
  cellClassName: string;
  cellValue: string | number | null;
  highlight: boolean;
  onCellEdit: (rowDataIndexValue: string, colDataIndex: string, value: number) => void;
}

export interface ConfigurableDataSectionTotalRowProps {
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  totalRowData: ConfigurableDataSectionRowTotal;
  highlight: boolean;
}

export interface ConfigurableDataSectionRowProps {
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  rowData: ViewSizingData[];
  highlight: boolean;
  totalRowData: ConfigurableDataSectionRowTotal;
}

export interface ConfigurableDataSectionRowsProps {
  renderSizeRowData: boolean;
  rowData: ViewSizingData[];
  totalRowData: ConfigurableDataSectionRowTotal;
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  highlight: boolean;
}

export interface ConfigurableDataSectionRowTotal {
  [key: string]: number | null;
}

export interface ConfigurableDataSectionColumnConfigs {
  modalEditsEnabled: boolean;
  dataRules: ConfigurableDataRules;
  columns: ConfigurableDataColumn[];
  rowHeaderDataIndex: string;
  onCellEdit: (rowDataIndexValue: string, colDataIndex: string, value: number) => void;
}

export interface ConfigurableDataSectionProps {
  sectionHeader: string;
  sectionConfig: ConfigurableDataSectionColumnConfigs;
  totalRowData: ConfigurableDataSectionRowTotal;
  rowData?: ViewSizingData[];
  highlight: boolean;
}

export interface ConfigurableDataModalTabsProps {
  tabs: string[];
  activeTabIndex: number;
  onTabChange: (index: number) => void;
}

export interface ConfigurableDataModalCellEditParams {
  rowDataIndexValue: string;
  colDataIndex: string;
  value: number | null;
  activeTabIndex: number;
  data: FormattedSizingData;
  config: ConfigurableDataConfig;
}
