import { AxiosInstance } from 'axios';
import {
  zServerScopeResponse
} from '../state/scope/Scope.types';
import type {
  ServerScopeResponse
} from '../state/scope/Scope.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { Space } from 'src/space';
import { PlanId } from 'src/state/scope/codecs/PlanMetadata';
import LoggingService from '../services/Logging.service';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { noop } from 'lodash';
import { Command, zCommands } from 'src/state/scope/codecs/Commands';
import { Publishing } from 'src/state/scope/ScopeManagement.slice';

export interface ScopeTarget {
  id: string,
  display: string
}

export const SCOPECREATE_WITH_WP = 'with-wp';
export const SCOPECREATE_WITHOUT_WP = 'without-wp';
export const SCOPECREATE_SMARTPLAN = 'with-smart-plan';
type SCOPECREATE_TYPES = typeof SCOPECREATE_WITH_WP | typeof SCOPECREATE_WITHOUT_WP | typeof SCOPECREATE_SMARTPLAN;
export interface ScopeCreateRequest {
  initParams: {
    type: SCOPECREATE_TYPES,
    name?: string
  },
  anchor: TopMembers,
  workflow: Workflow
}

export type Workflow = 'in-season' | 'pre-season';

export type TopMembers = Space<string[]>;

export interface ScopeMemberInfo {
  readonly id: string,
  readonly name: string,
  readonly description: string,
  readonly level: string,
  readonly inSeason?: boolean
}

export interface AvailableMembers {
  space: Space<ScopeMemberInfo[]>,
  controlPoints: { [key: string]: Space<ScopeMemberInfo[]> },
  inSeason: string,
  maxSelections: Space<number>
}

export interface SummarizedMetric {
  revision: string,
  metric: string,
  value: number
}

export const getModuleRoute = (siloId: string, module: string) => `${API_BASE_URL}/silo/${siloId}/modules/${module}` as const;
export const CONTEXT_BASE = `${API_BASE_URL}/context` as const;

export default class Scope {
  protected client: AxiosInstance;
  protected logger: LoggingService;
  private logError: (error: Error) => never;
  public static logAsyncOrDecoderError = (logger: LoggingService, error: Error): never => {
    if (error instanceof z.ZodError) {
      logger.error('Error: An error occued parsing a shape', error);
      throw new Error('Error: An error occued parsing a shape');
    }
    // otherwise, let the downstream error handler get it
    logger.error('Error: An unknown error occued downloading a resource', error);
    throw new Error('Error: An unknown error occued downloading a resource');
  };

  constructor(client: AxiosInstance) {
    this.client = client;
    this.logger = new LoggingService(this.client);
    this.logError = (error: Error) => Scope.logAsyncOrDecoderError(this.logger, error);
  }

  public createScope(create: ScopeCreateRequest, siloId: string, module: string): Promise<ServerScopeResponse> {
    const jsonStr = JSON.stringify(create);
    // @ts-ignore the type for ServerScopeResponse doesn't actually infer correctly from the codec :(
    return this.client
      .post<unknown>(getModuleRoute(siloId, module), jsonStr)
      .then(resp => zServerScopeResponse.parse(resp.data))
      .catch(err => {
        this.logger.error(`An error occured creating scope ${jsonStr}`, LoggingService.errorToLoggingPayload(err));
        toast.error('An error occured creating your scope');
      });
  }

  public getScope(scopeId: string): Promise<ServerScopeResponse> {
    return this.client
      .get(`${CONTEXT_BASE}/${scopeId}`)
      .then(resp => zServerScopeResponse.parse(resp.data))
      .catch(err => {
        this.logger.error(`An error occured creating scope ${scopeId}`, LoggingService.errorToLoggingPayload(err));
        // Don't toast on 404, as this is regular behavior for deleting scopes or changing config
        if (err.response.status !== 404) {
          toast.error('An error occured creating your scope');
        }
        throw new Error(`An error occured creating scope ${scopeId}`);
      });
  }

  public getAvailableMembers(siloId: string, module: string) {
    return this.client
      .get<AvailableMembers>(getModuleRoute(siloId, module))
      .then(response => response.data);
  }

  public seedScope(
    scopeId: string,
    seed: Command['command']
  ) {
    const jsonStr = JSON.stringify(seed);
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/commands`, jsonStr);
  }

  public importVersion(
    scopeId: string,
    importOption: Command['command']
  ) {
    const jsonStr = JSON.stringify(importOption);
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/commands`, jsonStr);
  }


  public getScopeLockState(scopeId: string): Promise<boolean> {
    return this.client
      .get<boolean>(`${CONTEXT_BASE}/${scopeId}/locked`)
      .then(response => response.data);
  }

  public unlockScopeLockState(scopeId: string): Promise<boolean> {
    return this.client
      .post<boolean>(`${CONTEXT_BASE}/${scopeId}/locked`)
      .then(response => {
        if (response.status === 204) {
          return false;
        }
        return response.data;
      });
  }

  public postOverlay(scopeId: string, applyTo: PlanId, overlayId: string): Promise<{}> {
    const jsonStr = JSON.stringify({
      type: 'overlay',
      source: overlayId
    });
    return this.client.post<{}>(`${CONTEXT_BASE}/${scopeId}/commands`, jsonStr)
      .then(d => d.data);
  }

  public saveVersion(scopeId: string, versionName: string | null): Promise<void> {
    const payload = JSON.stringify({ name: versionName });
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/save`, payload).then((_resp) => {
      noop();
    }).catch((err) => {
      throw this.logError(err);
    });
  }

  public saveSmartPlanVersion(scopeId: string, versionName: string | null): Promise<void> {
    const payload = JSON.stringify({ name: versionName });
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/smartsave`, payload).then((_resp) => {
      noop();
    }).catch((err) => {
      throw this.logError(err);
    });
  }

  public balanceScope(
    scopeId: string,
    balance: Command['command'],
  ) {
    const jsonStr = JSON.stringify(balance);
    return this.client.post(`${CONTEXT_BASE}/${scopeId}/commands`, jsonStr);
  }

  public undoScope(scopeId: string): Promise<void> {
    return this.client
      .post<void>(`${CONTEXT_BASE}/${scopeId}/undo`)
      .then(response => response.data);
  }

  public redoScope(scopeId: string): Promise<void> {
    return this.client
      .post<void>(`${CONTEXT_BASE}/${scopeId}/redo`)
      .then(response => response.data);
  }

  public attach(scopeId: string, planId: number, callback?: () => void): Promise<{}> {
    const payload = {
      planId,
      revName: 'ty-review-approved'
    };
    const jsonStr = JSON.stringify(payload);
    return this.client
      .post<{}>(`${API_BASE_URL}/context/${scopeId}/attach`, jsonStr)
      .then(response => {
        if (callback) {
          callback();
        }
        return response.data;
      });
  }

  public getPublishVersions(scopeId: string, applyTo: PlanId) {
    return this.client
      .get<Publishing['publishVersions']>(`${CONTEXT_BASE}/${scopeId}/publishv2/${applyTo}`)
      .then(resp => resp.data)
      .catch(this.logError);
  }

  public publishToVersion(scopeId: string, versionId: string, applyTo: PlanId) {
    return this.client
      .post(`${CONTEXT_BASE}/${scopeId}/publishv2/${applyTo}`, `"${versionId}"`) // extra quotes required here
      .then((_resp) => {
        _resp;
      })
      .catch((err) => {
        throw new Error('An error occured submitting your plan');
      });
  }

  public getCommands(scopeId: string) {
    return this.client
      .get(`${CONTEXT_BASE}/${scopeId}/commands`)
      .then(response => zCommands.parse(response.data))
      .catch(this.logError);
  }

  public deleteScope(scopeId: string): Promise<void> {
    return this.client
      .delete<void>(`${CONTEXT_BASE}/${scopeId}`)
      .then(_response => {
        // intentional non-return
        _response
      })
      .catch(this.logError);
  }
}