import React from 'react';
import { Report } from 'src/dao/reportClient';
import { ASSORTMENT, API_URL_PREFIX } from 'src/utils/Domain/Constants';
import { defaultTo } from 'lodash';
import { getLatestAccessToken } from 'src/services/auth/platform';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

function reportParamToIcon(reportParam: string, key: number) {
  switch (reportParam) {
    case 'Product':
      return <i key={key} className="report-card-dimension fas fa-cube" />;
    case 'Location':
      return <i key={key} className="report-card-dimension fas fa-map-marker" />;
    case 'Time':
      return <i key={key} className="report-card-dimension fas fa-clock" />;
    default: // nothing
  }
  return undefined;
}

export const generateReportUrl = (report: Pick<Report, 'modelId' | 'templateId'> & { perspective?: AppType }) => {
  const latestToken = getLatestAccessToken();
  const accessToken: string = latestToken != null ? latestToken : '';
  const reportUrl = `${API_URL_PREFIX}/api/pivot3/export?appName=${defaultTo(
    report.perspective,
    ASSORTMENT
  )}&defnId=${encodeURIComponent(report.modelId)}&flowStatus=&groupBy=&sortBy=&xlsxTemplate=${encodeURIComponent(
    report.templateId
  )}&token=${encodeURIComponent(accessToken)}`;
  return reportUrl;
};

const reportUrlFromId = (report: Pick<Report, 'modelId' | 'templateId'> & { perspective?: AppType }) => {
  window.open(generateReportUrl(report));
};

const reportDownloadBtnStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  marginTop: 'auto',
};

export function ReportCard(report: Report & { perspective?: AppType }) {
  return (
    <div className="report-card">
      <header>
        {report.name.split('\n').map((token, i) => (
          <div key={i}>{token}</div>
        ))}
      </header>
      <main>
        <p>{report.description}</p>
        <div className="icons">{report.reportParams.split(',').map((param, i) => reportParamToIcon(param, i))}</div>
      </main>
      <footer>
        {/* <i className="footer-icon configure fas fa-cog" /> */}
        <button className="footer-icon download" style={reportDownloadBtnStyle} onClick={() => reportUrlFromId(report)}>
          <i className="fas fa-download" />
        </button>
      </footer>
    </div>
  );
}
