import { styles } from './AggregateCard.styles';
import * as React from 'react';

export interface Props {
  className?: string;
  index: string | number;

  title: string;
  primary: string | number | null | JSX.Element;
  secondary: {
    desc: string;
    value: string | number | null | JSX.Element;
  }[];
  onClick?: (id: string) => void;
}
export function AggregateCard(props: Props) {
  const className = props.className ? styles.card + ' ' + props.className : styles.card;
  const secondaries = props.secondary.map((item, ind) => {
    return (
      <tr key={ind}>
        <td className="right-desc">{item.desc}</td>
        <td className="right-value">{item.value}</td>
      </tr>
    );
  });
  return (
    <div
      className={className + ' flat-card'}
      key={props.index}
      onClick={() => (props.onClick ? props.onClick(props.title) : undefined)}
    >
      <span className="left-pane">
        <span className="title">{props.title}</span>
        <span className="primary">{props.primary}</span>
      </span>
      <table className="right-pane">
        <tbody>{secondaries}</tbody>
      </table>
    </div>
  );
}
