import * as React from 'react';
import { ExportableFilters } from './Filters.interfaces';
import FiltersHeader from './FiltersHeader';
import FilterTab from './FilterTab';
import FilterSection from './FilterSection';
import styles from './Filters.styles';
import { DaysRangeListResponse, Filter, FilterGroup } from 'src/types/Scope';

export interface FilterSelection {
  id: string;
  filterDefnId: string;
}

export interface SelectionOverride {
  id: string;
  filterDefnId: string;
  value: boolean;
}

export interface Props {
  data: FilterGroup[];
  width?: number | string;
  height?: number | string;
  lockedNavigation: boolean;
  selectionOverrides: SelectionOverride[];
  selectionsChanged: boolean;
  hasFiltersSelected: boolean;
  allowFrom: string;
  allowTo: string;
  daysRangeList: DaysRangeListResponse;
  onViewUpdate(): void;
  onFilterToggled(selection: FilterSelection, state: boolean): void;
  onFiltersCleared(): void;
  onFilterSectionCleared(tab: number): void;
  onFiltersSubmit(): void;
  onToggleSection(filter: Filter): void;
  onClearSection(sectionId: string, isOpen: boolean): void;
  onRangeFiltersUpdated?(id: string, labels: string[]): void;
}

export interface State {
  currentTab: number;
  currentSection: string[];
  filterSets: {
    appName: string;
    history: boolean;
  };
}

export default class Filters extends React.Component<Props, State> implements ExportableFilters {
  state: State = {
    currentTab: 0,
    currentSection: [],
    filterSets: {
      appName: 'assortment',
      history: false,
    },
  };

  constructor(props: Props) {
    super(props);
  }

  onSubmitFilters = () => {
    this.setState({ ...this.state, currentSection: [] });
    this.props.onViewUpdate();
    this.props.onFiltersSubmit();
  };

  onSelectTab = (tab: number) => {
    if (!this.props.lockedNavigation) {
      this.setState({
        // We need to force a reset of open section on every tab switch
        // This can probably change to just a string | undefined in the future
        // but I wasn't comfortable making more invasive changes
        currentSection: [],
        currentTab: tab,
      });
    }
  };

  updateRangeFilters = (id: string, labels: string[]) => {
    const { onRangeFiltersUpdated } = this.props;
    if (onRangeFiltersUpdated) {
      onRangeFiltersUpdated(id, labels);
    }
  };

  toggleFilter = (id: string, filterDefnId: string, state: boolean) => {
    const obj = {
      id,
      filterDefnId,
    };
    this.props.onFilterToggled(obj, state);
  };

  toggleSection = (newSection: string) => {
    if (!this.props.lockedNavigation) {
      const { currentSection, currentTab } = this.state;
      const updatedSection = currentSection;

      if (currentSection[currentTab] !== newSection) {
        updatedSection[currentTab] = newSection;
      } else {
        updatedSection[currentTab] = '';
      }

      const { data } = this.props;
      const currentFilters = data[currentTab].filters;
      const filter = currentFilters.find((x) => x.id === currentSection[currentTab]);
      if (filter) {
        this.props.onToggleSection(filter);
      }
      this.setState({
        currentSection: updatedSection,
      });
    }
  };

  clearAllFilters = () => {
    this.setState({ ...this.state, currentSection: [] });
    this.props.onFiltersCleared();
  };

  clearTabSelections = (tab: number) => {
    this.props.onFilterSectionCleared(tab);
  };

  onClearSection = (sectionId: string, isOpen: boolean) => {
    this.props.onClearSection(sectionId, isOpen);
  };

  render() {
    const {
      data,
      selectionsChanged,
      selectionOverrides = [],
      hasFiltersSelected,
      allowFrom,
      allowTo,
      daysRangeList,
    } = this.props;
    const { currentTab, currentSection } = this.state;
    if (!data[currentTab]) return <div />;
    const tabs = data.map((x) => x.id);
    const currentFilters: Filter[] = data[currentTab].filters;

    return (
      <div className={styles.FiltersView}>
        <FiltersHeader
          onSubmitFilters={this.onSubmitFilters}
          onClearAllFilters={this.clearAllFilters}
          hasFiltersSelected={hasFiltersSelected}
          areFiltersChanged={selectionsChanged}
        />
        <div className="tabs" data-qa="filter-tab-container">
          {tabs.map((tab, index) => (
            <FilterTab
              key={`tab_${index}`}
              name={tab}
              index={index}
              selected={index === currentTab}
              onSelectTab={this.onSelectTab}
              clearTabSelections={this.clearTabSelections}
            />
          ))}
        </div>
        <div className="group-container" data-qa="filter-section-container">
          {currentFilters &&
            currentFilters.map((filter, i) => {
              const { id, name, filterValues = [], inputType } = filter;

              const sectionProps = {
                selectionOverrides,
                id,
                inputType,
                name,
                open: currentSection[currentTab] === filter.id,
                filterValues,
                allowFrom,
                allowTo,
                daysRangeList,
                onClearSection: (isOpen: boolean) => this.onClearSection(id, isOpen),
                onToggleItem: this.toggleFilter,
                onToggleSection: this.toggleSection,
                onRangeFiltersUpdated: this.updateRangeFilters,
              };

              return <FilterSection key={'filter_section_' + i} {...sectionProps} />;
            })}
        </div>
      </div>
    );
  }
}
