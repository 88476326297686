import { AppState } from 'src/store';
import { setPrintMode } from 'src/components/higherOrder/Print/Print.slice';
import { setPrintSize, printMargin } from 'src/components/higherOrder/Print/Print';
import { EnhancedStore } from '@reduxjs/toolkit';

export interface PrintService {
  hydrate(store: EnhancedStore<AppState>): void;
  openPrint(width: number, height: number): void;
  setPrintFromUrl(): void;
  setPrintMode(enabled: boolean, width?: number, height?: number): void;
}

export function makePrintService(): PrintService {
  let store: EnhancedStore<AppState>;

  const printService: PrintService = {
    hydrate(_store: EnhancedStore<AppState>) {
      store = _store;
    },
    setPrintFromUrl() {
      const url = new URL(window.location.href);
      const enabled = url.searchParams.has('print');
      printService.setPrintMode(enabled);
    },
    openPrint(width: number, height: number) {
      printService.setPrintMode(true, width, height);
    },
    setPrintMode(enabled: boolean, width = 11, height = 8.5) {
      const newUrl = new URL(window.location.href);

      if (enabled) {
        newUrl.searchParams.set('print', '');
        window.history.pushState(undefined, document.title, newUrl.toString());
      } else {
        newUrl.searchParams.delete('print');
        window.history.replaceState(undefined, document.title, newUrl.toString());
      }

      setPrintSize(width, height);
      const printModeParams = {
        isPrintMode: enabled,
        width: (width).toString(),
        height: (height).toString(),
      };
      store.dispatch(setPrintMode(printModeParams));
    },
  };

  return printService;
}
