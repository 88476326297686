import React from 'react';
import type { ICellRendererParams, ColDef, ColGroupDef, GridOptions, CellClassParams, EditableCallbackParams } from '@ag-grid-community/core';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { pick, isNil, get, has, mapValues } from 'lodash';
import Renderer from 'src/utils/Domain/Renderer';
import { DefaultShownValues } from 'src/common-ui/components/DataGrid/DataGrid';
import { isNumber, isString } from 'lodash';
import { ClientDataApi, ListDataApi } from 'src/services/configuration/codecs/confdefnView';
import { GroupHeaderKey } from './AgDataFormat';
import { coalesce } from 'src/utils/Functions/Coalesce';
import { ConfigurableGridConfigItem } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { MIN_COL_WIDTH } from 'src/components/ListGridPair/ListGridPair.styles';

export type FrameworkComponents = GridOptions['components'];

export interface CellParams extends ICellRendererParams {
  config?: ConfigurableGridConfigItem;
}

export interface ParseResult {
  rowHeight?: number;
  groupRowHeight?: number;
  columnWidth?: number;
  frameworkComponents: FrameworkComponents;
  colDefs: ColDef[];
  treeColumnDefinition: ColDef | undefined;
  stylePaneTriggerSet: Set<string>;
  defaultShownValues: DefaultShownValues;
  masterDetailConfig: MasterDetailConfig | null;
  hiddenGroupColumnDataIndices: Set<string>;
}

export interface MasterDetailConfig {
  dataApi: ClientDataApi | ListDataApi;
  configApi: ClientDataApi;
}

export interface IncrementalResult {
  renderers: Set<string>;
  colDefs: ExtraColDef[];
  treeColumnDefinition: ColDef | undefined;
  stylePaneTriggerSet: Set<string>;
  masterDetailConfig: MasterDetailConfig | null;
  hiddenGroupColumnDataIndices: Set<string>;
}
interface ExtraColDef extends ColDef {
  filterType?: string;
}
export type CellStyler = (params: CellClassParams) => any;

function parseViewDefns(configViewItems: TenantConfigViewItem[]): IncrementalResult {
  const colDefs: ExtraColDef[] = [];
  const renderers = new Set<string>();
  const stylePaneTriggerSet = new Set<string>();
  const hiddenGroupColumnDataIndices = new Set<string>();
  let treeColumnDefinition: ColDef | undefined;
  let masterDetailConfig: MasterDetailConfig | null = null;

  if (!configViewItems || !configViewItems[Symbol.iterator]) {
    throw new Error('nooop');
  }

  for (const configViewItem of configViewItems) {
    let isTreeColumn = false;
    let isStylePaneTrigger = false;
    const cellStylers: CellStyler[] = [];
    if (configViewItem.visible === false) {
      continue;
    }

    /* eslint-disable-next-line */
    //@ts-ignore
    const colDef: ExtraColDef = Object.keys(configViewItem).reduce((transformed: ColDef & ColGroupDef, key: string) => {
      const value = configViewItem[key];

      switch (key) {
        case 'text':
          transformed.headerName = value;
          break;
        case 'dataIndex':
          transformed.field = value.toLowerCase();
          break;
        // :( I'm sorry
        case 'hidden':
          transformed.hide = configViewItem.visible != null ? !configViewItem.visible : value;
          break;
        case 'visible':
          transformed.suppressColumnsToolPanel = !value;
          transformed.hide = !value;
          break;
        case 'renderer': {
          if (value === 'backgroundFill') {
            cellStylers.push((params: CellClassParams) => ({
              backgroundColor: params.value,
              color: 'transparent',
              padding: 0,
            }));
            // Fixed depecation warnings causing ListView to crash
            transformed.suppressMenu = true;
            transformed.sortable = false;
            transformed.suppressMovable = true;
            transformed.filter = false;
            transformed.resizable = false;
            transformed.suppressAutoSize = true;
            transformed.suppressSizeToFit = true;
            transformed.minWidth = MIN_COL_WIDTH;
            transformed.cellClass = (params: CellClassParams) => {
              // the fact that the column banding columns end in `_color` is partially a convention,
              // as well as being explicitly part of the color banding code in darwin.
              // However, the columns are able to be configured to any dataIndex, and don't *have* to be paired with
              // a `_color` column.  We accept the convention here, but should revisit this in the future.
              const key = configViewItem.dataIndex.replace(/_color$/, '')
              const bandNumber = get(params.data, key, undefined);
              return `compositeColor${bandNumber}`;
            }
          } else {
            transformed.cellRenderer = value;
            renderers.add(value);
          }
          break;
        }
        case 'stylePaneOpenOnClick': {
          isStylePaneTrigger = value;
          break;
        }
        case 'align': {
          // INT-3881 (alignment is flex based rather than element based. So we need to start the flex handling here.)
          const alignmentStyles = {
            flexDirection: 'column',
            display: 'flex',
            flexGrow: 1,
            alignItems: (value == 'right') ? 'flex-end' : (value == 'center') ? 'center' : 'flex-start'
          }
          cellStylers.push(() => ({ textAlign: value, ...alignmentStyles }));
          break;
        }
        case 'highlightText': {
          if (!isNil(value)) {
            transformed.cellClass = value ? 'highlight' : undefined;
          }
          break;
        }
        // drop-in
        case 'headerCheckboxSelection':
        case 'headerCheckboxSelectionFilteredOnly':
        case 'checkboxSelection':
        case 'headerClass':
        case 'resizable':
        case 'suppressMovable':
        case 'suppressMenu':
        case 'width':
        case 'minWidth':
        case 'pinned':
          transformed[key] = value;
          break;
        case 'aggregator':
          transformed[key] = value;
          transformed['aggFunc'] = value;
          break;
        case 'columns':
          delete transformed.field;
          const result = parseViewDefns(value);
          transformed.children = result.colDefs;
          result.stylePaneTriggerSet.forEach((item) => stylePaneTriggerSet.add(item));
          result.renderers.forEach((item) => renderers.add(item));
          break;
        case 'treeColumn':
          isTreeColumn = value;
          break;
        case 'masterDetail':
          masterDetailConfig = value;
          break;
        case 'editable':
          transformed[key] = (params: EditableCallbackParams) => {
            // only allow group headers of configured columns to be editable
            return !isNil(params.data[GroupHeaderKey]) && value;
          };
          break;
        default:
        // fallthrough, okay
      }

      transformed.comparator = (valueA: any, valueB: any) => {
        if (valueA === undefined) {
          return -1;
        } else if (valueB === undefined) {
          return 1;
        }
        if (isNumber(valueA) && isNumber(valueB)) {
          if (valueA === valueB) {
            return 0;
          }

          return valueA > valueB ? 1 : -1;
        } else if (isString(valueA) && isString(valueB)) {
          if (valueA === valueB) return 0;
          else return valueA > valueB ? 1 : -1;
        } else if (isString(valueA) || isString(valueB)) {
          // count NaN as 0 for now
          const modA = valueA === 'NaN' ? 0 : isString(valueA) ? parseInt(valueA) : valueA;
          const modB = valueB === 'NaN' ? 0 : isString(valueB) ? parseInt(valueB) : valueB;

          if (modA === modB) {
            return 0;
          }

          return modA > modB ? 1 : -1;
        }

        return 0;
      };

      return transformed;
    }, {});

    // assigning colId explicitly to allow for columns w/ duplicate dataIndexes.
    // pinned update is necessary to force agGrid to notice field change.
    colDef.colId = get(configViewItem, 'id') || configViewItem.dataIndex;
    colDef.pinned = colDef.pinned || false;
    if (cellStylers.length) {
      colDef.cellStyle = (params) => {
        return cellStylers.map((fn) => fn(params)).reduce((acc, result) => ({ ...acc, ...result }));
      };
    }
    if (configViewItem.filterType) {
      colDef.filterType = configViewItem.filterType
    }
    if (isStylePaneTrigger && colDef.field) {
      stylePaneTriggerSet.add(colDef.field);
    }

    if (isTreeColumn) {
      treeColumnDefinition = colDef;
    } else {
      colDefs.push(colDef);
    }

    if (configViewItem.hideGroupedColumn) {
      hiddenGroupColumnDataIndices.add(configViewItem.dataIndex);
    }
  }

  return {
    renderers,
    colDefs,
    treeColumnDefinition,
    stylePaneTriggerSet,
    masterDetailConfig,
    hiddenGroupColumnDataIndices,
  };
}

interface GetFrameworkComponentsOptions {
  groupingField?: string;
  /** Determines if the renderer will be wrapped in a container `<div>` element, defaults to true */
  wrapRenderer?: boolean;
}

/**
 * This function returns all renderers wrapped in a div for consumption by ag-grid
 * and also does some setup of inputs for grouped values and template renderers
 * Building all renderers doesn't appear to affect grid performance, and guards against some instances where
 * columns are set with a renderer that isn't attached to the grid instance
 * @param groupingField when grouping by level:class:name on a template renderer, we need to template on id+name
 * @returns Record<string,frameworkComponent> as {} any
 */
export const getFrameworkComponents = (options: GetFrameworkComponentsOptions = {}): GridOptions['components'] => {
  const { groupingField = undefined, wrapRenderer = true } = options ?? {};
  // build frameworkComponents of all renderers everytime
  // this doesn't appear to affect performance, and guards against some instances where
  // columns are set with a renderer that isn't attached to the grid instance
  return [...Object.keys(Renderer)].reduce((acc, renderKey) => {
    const renderer = Renderer[renderKey];

    // eslint-disable-next-line react/display-name
    acc[renderKey] = (params: CellParams) => {
      let renderedValue;
      if (renderKey === 'template' || renderKey === 'styledTemplate') {
        if (has(params.config, 'rendererParams')) {
          const rendererParams = get(params.config, 'rendererParams');
          const styles = has(params.config, 'rendererParams') ? get(params.config, 'classes') : [];
          const renderValues = mapValues(rendererParams?.maskParams, (keyName) => {
            return coalesce(
              params.api?.getValue(keyName, params.node!),
              get(params.data, keyName))
          })
          renderedValue = renderer(renderValues, rendererParams?.mask, styles);
        } else if (groupingField === 'level:class:name') {
          // only want templated output when grouping by class
          renderedValue = renderer(params.data, '{id} - {name}');
        } else {
          // for non-templated row header values, use original dataIndex
          renderedValue = renderer(params.data, '{name}');
        }
      } else {
        renderedValue = renderer(params.value);
      }
      if (has(params.data, '$$GroupHeader')) {
        if (isNil(params.value)) {
          renderedValue = '';
          if (has(params.column, 'aggregator') || has(params.column, 'aggFunc')) renderedValue = '0';
        } else {
          renderedValue = renderer(params.value);
        }
      }
      return wrapRenderer ? <div>{renderedValue}</div> : renderedValue;
    };

    return acc;
  }, {});
};

export function parseGridConfig(gridConfig: TenantConfigViewData, groupingField?: string): ParseResult {
  const results = parseViewDefns(gridConfig.view);

  const frameworkComponents = getFrameworkComponents({ groupingField });

  const defaultShownValues = gridConfig.view.reduce((acc, next) => {
    if (next.columns) {
      next.columns.forEach((column) => {
        if (column.defaultShownValues) {
          acc[column.dataIndex] = column.defaultShownValues;
        }
      });
    }

    if (next.defaultShownValues) {
      acc[next.dataIndex] = next.defaultShownValues;
    }
    return acc;
  }, {} as any);

  return {
    defaultShownValues,
    rowHeight: gridConfig.main ? gridConfig.main.rowHeight : undefined,
    groupRowHeight: gridConfig.main ? gridConfig.main.groupRowHeight : undefined,
    frameworkComponents,
    ...pick(
      results,
      'treeColumnDefinition',
      'stylePaneTriggerSet',
      'colDefs',
      'masterDetailConfig',
      'hiddenGroupColumnDataIndices'
    ),
  };
}
