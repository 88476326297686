import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';

export function buildChartConfig(config: TenantConfigViewItem[]): Highcharts.Options {
  const [graphTitle, xAxisLabel, yAxisLabel] = config;
  return {
    credits: { enabled: false },
    chart: {
      type: 'area',
      backgroundColor: '#FCFCFC',
      height: '250px',
    },
    title: {
      text: graphTitle.text,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: [
      {
        gridLineWidth: 0,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: xAxisLabel.text,
        },
      },
    ],
    yAxis: [
      {
        gridLineWidth: 0,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: yAxisLabel.text,
        },
      },
    ],
  };
}

export function buildCurrentSeries(data: number[]) {
  return [
    {
      data,
      color: '#e5e1da',
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
      showInLegend: false,
    },
  ];
}
