import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ViewDataState } from 'src/types/Domain';
import { cacheCheckFetchPivotData } from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import serviceContainer from 'src/ServiceContainer';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { ErrorBoundaryComponentError } from 'src/components/ErrorBoundary/ErrorBoundary.slice';

export interface ProductDetailsConfig {
  grid: TenantConfigViewData;
  macro: TenantConfigViewData;
  unmodifiedViewDefn: TenantConfigViewData;
}

export interface ProductDetailsSlice {
  viewDefns: ProductDetailsConfig;
  isConfigLoading: boolean;
  macroDataState: ViewDataState;
  gridDataState: ViewDataState;
  macroCacheHash: string | null;
  gridCacheHash: string | null;
}

const initialState: ProductDetailsSlice = {
  viewDefns: {
    grid: {} as TenantConfigViewData,
    macro: {} as TenantConfigViewData,
    unmodifiedViewDefn: {} as TenantConfigViewData,
  },
  isConfigLoading: false,
  macroDataState: ViewDataState.idle,
  gridDataState: ViewDataState.idle,
  macroCacheHash: null,
  gridCacheHash: null,
};

const productDetailsReducer = createSlice({
  name: 'ProductDetails',
  initialState,
  reducers: {
    requestConfig(state) {
      state.isConfigLoading = true;
    },
    receiveConfig(state, action: PayloadAction<ProductDetailsConfig>) {
      state.isConfigLoading = false;
      state.viewDefns = action.payload;
    },
    receiveSomeProductDetailsConfig(state, action: PayloadAction<TenantConfigViewItem[]>) {
      state.isConfigLoading = false;
      state.viewDefns.grid.view = action.payload;
    },
    requestMacroData(state) {
      state.macroDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveMacroCacheHash(state, action: PayloadAction<string>) {
      state.macroCacheHash = action.payload;
    },
    receiveMacroCachedData(state, action: PayloadAction<string>) {
      // Ignore receipts from loads unrelated to current fetch.
      // (This could entirely be replaced with an epic for all screens using this technique.)
      if (action.payload === state.macroCacheHash) {
        state.macroDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveMacroLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.macroCacheHash) {
        state.macroDataState = ViewDataState.liveDataReady;
      }
    },
    requestGridData(state) {
      state.gridDataState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveGridCacheHash(state, action: PayloadAction<string>) {
      state.gridCacheHash = action.payload;
    },
    receiveGridCachedData(state, action: PayloadAction<string>) {
      if (action.payload === state.gridCacheHash) {
        state.gridDataState = ViewDataState.liveDataLoadingFoundCache;
      }
    },
    receiveGridLiveData(state, action: PayloadAction<string>) {
      if (action.payload === state.gridCacheHash) {
        state.gridDataState = ViewDataState.liveDataReady;
      }
    },
    receiveError(_state, _action: PayloadAction<ErrorBoundaryComponentError>) {
      return initialState;
    },
    cleanUp() {
      return initialState;
    },
  },
});

export const {
  requestConfig,
  receiveConfig,
  receiveSomeProductDetailsConfig,
  requestMacroData,
  receiveMacroCacheHash,
  receiveMacroCachedData,
  receiveMacroLiveData,
  requestGridData,
  receiveGridCacheHash,
  receiveGridCachedData,
  receiveGridLiveData,
  receiveError,
  cleanUp,
} = productDetailsReducer.actions;

export function fetchProductDetailsMacroData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestMacroData,
    receiveMacroCacheHash,
    receiveMacroCachedData,
    receiveMacroLiveData
  );
}

export function fetchProductDetailsGridData(modelDefn: string, options: ListDataOptions) {
  return cacheCheckFetchPivotData(
    serviceContainer.pivotService.listDataCacheCheck(modelDefn, options),
    requestGridData,
    receiveGridCacheHash,
    receiveGridCachedData,
    receiveGridLiveData
  );
}

export default productDetailsReducer.reducer;
