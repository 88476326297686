import classNames from 'classnames';
import { BindAll } from 'lodash-decorators';
import React from 'react';

export interface ReportGroupProps {
  title: string
}
export interface ReportGroupState {
  show: boolean
}

@BindAll()
export default class ReportGroup extends React.Component<
ReportGroupProps,
ReportGroupState
> {
  public constructor(props: ReportGroupProps) {
    super(props);
    this.state = {
      show: true
    };
  }

  public toggle() {
    this.setState({
      show: !this.state.show
    });
  }

  public render() {
    const reportDisplayClass = this.state.show
      ? 'report-group-show'
      : 'report-group-hide';
    const reportIconDisplay = this.state.show
      ? 'fa-chevron-up'
      : 'fa-chevron-down';

    return (
      <div className={classNames('report-group', reportDisplayClass)}>
        <div className="report-group-title" onClick={this.toggle}>
          <i className={classNames('chevron', 'far', reportIconDisplay)} />
          {this.props.title}
        </div>
        <div className="report-group-items">{this.props.children}</div>
      </div>
    );
  }
}
