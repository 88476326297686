import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { concat, isEmpty } from 'lodash';
import Axios from 'src/services/axios';
import { toast } from 'react-toastify';
import { AppThunkDispatch } from 'src/store';
import { ViewDataState } from 'src/types/Domain';
import { clearAndCloseRightContainer } from 'src/components/RightContainer/RightContainer.slice';
import { provideAppName } from 'src/utils/Domain/Perspective';
import ServiceContainer from 'src/ServiceContainer';
import { PlanTrackerSlice, PlanItemStatus, PlanItem, UndoItem } from './Planning.types';

export const UNDO_ENDPOINT = '/api/property/undo';


const initPlanTrackerState: PlanTrackerSlice = {
  assortmentPlanInProgress: false,
  shouldCheckAssortment: true,
  askForRefresh: false,
  planItems: [],
  undoViewState: ViewDataState.idle,
  undoItems: [],
};

const planTrackerSlice = createSlice({
  name: 'PlanTracker',
  initialState: initPlanTrackerState,
  reducers: {
    init: () => initPlanTrackerState,
    startPlan: (state) => {
      const pending = state.planItems.filter((it) => it.status === 'pending') || [];
      const failed = state.planItems.filter((it) => it.status === 'failed') || [];
      if (pending.length > 0 || failed.length > 0) {
        state.assortmentPlanInProgress = true;
        state.shouldCheckAssortment = false;
        state.askForRefresh = false;
      }
    },
    planItemUpdate: (state, action: PayloadAction<Record<PlanItemStatus, PlanItem[]>>) => {
      const { pending, processing, completed, failed } = action.payload;
      if (state.assortmentPlanInProgress == true && processing.length == 0 && isEmpty(pending)) {
        state.assortmentPlanInProgress = false;
        state.shouldCheckAssortment = false;
        state.askForRefresh = true;
      }
      if (state.assortmentPlanInProgress == false && processing.length > 0) {
        state.assortmentPlanInProgress = true;
      }
      state.planItems = concat(pending, processing, completed, failed);
    },
    resetPlanItem: (state) => {
      state.askForRefresh = false;
    },
    // used for both item retrieval and sending undo item
    requestUndoAction: (state) => {
      state.undoViewState = ViewDataState.liveDataLoadingNoCache;
    },
    undoUpdate: (state, action: PayloadAction<UndoItem[]>) => {
      state.undoViewState = ViewDataState.liveDataLoadingNoCache;
    },
    receiveUndoItems: (state, action: PayloadAction<UndoItem[]>) => {
      state.undoViewState = ViewDataState.liveDataReady;
      state.undoItems = action.payload;
    },
    completeItemUndo: (state) => {
      state.undoViewState = ViewDataState.liveDataReady;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAndCloseRightContainer, (state) => {
      state.undoItems = [];
    });
  },
});

export const {
  planItemUpdate,
  startPlan,
  resetPlanItem,
  requestUndoAction,
  receiveUndoItems,
  completeItemUndo,
  undoUpdate,
} = planTrackerSlice.actions;

export function fetchUndoItems() {
  return async (dispatch: AppThunkDispatch) => {
    dispatch(requestUndoAction());
    return provideAppName(async (appName) => {
      return await Axios.get<UndoItem[]>(`${UNDO_ENDPOINT}?appName=${appName}`)
        .then((resp) => {
          dispatch(receiveUndoItems(resp.data));
        })
        .catch((err: any) => {
          const msg = `An error occurred fetching Undo Item`;
          toast.error(msg);
          ServiceContainer.loggingService.error(msg, err.stack);
          Promise.reject(msg);
        });
    });
  };
}

export function asyncUndoItem(undoId: string) {
  return (dispatch: AppThunkDispatch): Promise<void> => {
    return provideAppName(async (appName) => {
      dispatch(requestUndoAction());
      return Axios.post(`${UNDO_ENDPOINT}?appName=${appName}`, {
        undo_id: undoId,
      })
        .then(() => {
          dispatch(fetchUndoItems());
        })
        .then(() => {
          toast.info('Undo successfully completed');
        })
        .catch((err: any) => {
          const msg = `An error occurred updating Undo items`;
          toast.error(msg);
          ServiceContainer.loggingService.error(msg, err.stack);
          Promise.reject(msg);
        })
        .finally(() => {
          Promise.resolve('Undo completed');
          dispatch(completeItemUndo());
        });
    });
  };
}

export default planTrackerSlice.reducer;
