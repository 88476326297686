import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PrintSlice {
  isViewPrintable: boolean;
  isPrintMode: boolean;
  width: string;
  height: string;
}

interface PrintMode {
  isPrintMode: boolean;
  width: string;
  height: string;
}

export const initialState: PrintSlice = {
  isViewPrintable: true, // right now more views are printable than not, so default to true
  isPrintMode: false,
  width: '11in',
  height: '8.5in',
};

const printSlice = createSlice({
  name: 'Print',
  initialState,
  reducers: {
    setIsViewPrintable(state, action: PayloadAction<boolean>) {
      state.isViewPrintable = action.payload;
    },
    setPrintMode(state, action: PayloadAction<PrintMode>) {
      state.isPrintMode = action.payload.isPrintMode;
      state.width = `${action.payload.width}in`;
      state.height = `${action.payload.height}in`;
    },
  },
});

export const { setIsViewPrintable, setPrintMode } = printSlice.actions;

export default printSlice.reducer;
