import ValidOption from './ValidOption';
import * as React from 'react';
import styles from './ValidOptions.styles';

type ValidOptionsReadOnly = {
  editable: false;
  selected: string[];
};
type ValidOptionsEditable = {
  editable: true;
  options: string[];
  selected: string[];
  onChange: (selected: string[]) => void;
};

type ValidOptionsProps = ValidOptionsReadOnly | ValidOptionsEditable;

export default class ValidOptions extends React.Component<ValidOptionsProps> {
  static createNewSelection(option: string, previousSelected: string[]) {
    const newSelection = previousSelected.filter((value) => value !== option);
    if (previousSelected.length === newSelection.length) {
      newSelection.push(option);
    }
    return newSelection;
  }

  constructor(props: ValidOptionsProps) {
    super(props);
  }

  onOptionClick = (option: string) => {
    if (!this.props.editable) {
      return false;
    }
    const { selected, onChange } = this.props;
    const newSelection = ValidOptions.createNewSelection(option, selected);
    if (onChange) {
      onChange(newSelection);
    }
    return true;
  };

  buildValidOptionsReadOnly = (selected: string[]) => {
    if (Array.isArray(selected) && selected.length > 0) {
      return selected.map((option, index) => {
        return <ValidOption key={index} editable={false} value={option} />;
      });
    }
    return [];
  };

  buildValidOptionsEditable = (options: string[], selected: string[]) => {
    if (Array.isArray(options) && options.length > 0) {
      return options.map((option, index) => {
        const isSelected = selected.find((value) => value === option);
        return (
          <ValidOption
            key={index}
            editable={true}
            value={option}
            selected={!!isSelected}
            onOptionClick={this.onOptionClick}
          />
        );
      });
    }
    return [];
  };

  render() {
    let jsxElements: JSX.Element[] = [];

    if (this.props.editable) {
      const { options, selected } = this.props;
      jsxElements = this.buildValidOptionsEditable(options, selected);
    } else {
      const { selected } = this.props;
      jsxElements = this.buildValidOptionsReadOnly(selected);
    }

    return <div className={styles.validOptions}>{jsxElements}</div>;
  }
}
