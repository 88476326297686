import { ValueProps, PeriodFormReduxSlice, FunctionProps, OwnProps } from './PeriodForm.types';
import { SelectedStartEnd } from '../TargetCreation/TargetCreation.types';
import { update } from 'src/services/lenses/Lenses.actions';
import { connect } from 'react-redux';
import { PeriodForm } from './DatePicker';
import { AppDispatch, AppState } from 'src/store';

function mapStateToProps(state: AppState, ownProps: OwnProps): ValueProps {
  const period = ownProps.lens.get(state);
  return {
    ...period,
    label: ownProps.label,
  };
}

function mapDispatchToProps(dispatch: AppDispatch, ownProps: OwnProps): FunctionProps {
  const dU = (p: (s: AppState) => AppState, _message?: string) => {
    dispatch(update(p));
  };
  return {
    onChangeStartWeek: (selectedStartWeek?: string): void => {
      if (ownProps.onChange) {
        ownProps.onChange(selectedStartWeek);
      }
      if (ownProps.selectedLens) {
        dU(
          // @ts-ignore
          ownProps.selectedLens.modify((data: SelectedStartEnd) => {
            return {
              ...data,
              start: selectedStartWeek,
            };
          }),
          'Update Start'
        );
      }
      dU(
        ownProps.lens.modify((period: PeriodFormReduxSlice) => {
          return {
            ...period,
            selectedStartWeek,
          };
        }),
        'Update Start Week'
      );
    },
    onChangeEndWeek: (selectedEndWeek?: string): void => {
      if (ownProps.onChange) {
        ownProps.onChange(selectedEndWeek);
      }
      if (ownProps.selectedLens) {
        dU(
          // @ts-ignore
          ownProps.selectedLens.modify((data: SelectedStartEnd) => {
            return {
              ...data,
              end: selectedEndWeek,
            };
          }),
          'Update End'
        );
      }
      dU(
        ownProps.lens.modify((period: PeriodFormReduxSlice) => {
          return {
            ...period,
            selectedEndWeek,
          };
        }),
        'Update End Week'
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodForm);
