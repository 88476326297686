import React from 'react';
import { Option } from 'src/components/Configure/ConfigureModal';
import { GridMeasureDefn, State } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { companionDataParse, convertPivotItemsToRowData } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.utils';
import { Props as CompanionProps, SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import { companionStyles } from '../../../common-ui/components/CompanionListView/CompanionListView.styles';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Subheader from 'src/components/Subheader/Subheader.container';
import CompanionListView from 'src/common-ui/components/CompanionListView/CompanionListView';
import { resolvePath } from 'src/cdn';
import { PricingGrid, PricingGridProps } from 'src/pages/AssortmentBuild/Pricing/PricingGrid';
import { isNil, forEach, isEmpty, isEqual } from 'lodash';
import { MassEdit, MassEditSubmissionData } from 'src/components/MassEdit/MassEdit';
import { ASSORTMENT_BUILD_FILTER_WARNING, CACHED_DATA_EDITING_DISABLED_WARNING } from 'src/utils/Domain/Constants';
import { FabType } from 'src/components/higherOrder/withFab';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { PricingOverTimeProps } from './PricingOverTime.types';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { LegacySubheaderActionButtonProps, SortByDirection } from 'src/components/Subheader/Subheader.types';

import { GranularEditPayloadItem } from 'src/dao/pivotClient';
const noImage = resolvePath(noImagePath);

export class PricingOverTime extends React.Component<PricingOverTimeProps, State> {
  private _isUnMounted = false;
  protected pGrid!: PricingGrid | null;

  constructor(props: PricingOverTimeProps) {
    super(props);

    this.state = {
      companionCollapsed: false,
      companionSortDirection: 'desc',
      configureIsOpen: false,
      companionData: [],
      uniqueItems: props.dataLoaded ? props.uniqueItems : [],
      rowData: [],
    };
  }

  static getDerivedStateFromProps(newProps: PricingOverTimeProps, currentState: State): State {
    const {
      companionSortDirection,
      companionSortField = newProps.dataLoaded ? newProps.defaultCompanionSortField.dataIndex : '',
    } = currentState;

    let favoriteSortField, favoriteSortDirection: SortByDirection | undefined, favoriteCompIsCollapsed;

    if (newProps.dataLoaded) {
      const { uniqueItems, defaultCompanionSortField } = newProps;

      const activeFavorite = newProps.favoritesList && newProps.favoritesList.find((fav) => fav.active === true);
      if (activeFavorite && activeFavorite.jsonBlob && activeFavorite.jsonBlob.companionData) {
        const {
          companionCollapsed,
          companionSortDirection,
          companionSortField,
        } = activeFavorite.jsonBlob.companionData;
        favoriteSortField = companionSortField;
        favoriteSortDirection = companionSortDirection;
        favoriteCompIsCollapsed = companionCollapsed;
      }

      if (
        !isEqual(uniqueItems, currentState.uniqueItems) ||
        activeFavorite ||
        currentState.isDefault ||
        currentState.isCompanionChangeOnDefault
      ) {
        const direction = favoriteSortDirection ? favoriteSortDirection : companionSortDirection;
        const field = favoriteSortField
          ? favoriteSortField
          : companionSortField
          ? companionSortField
          : defaultCompanionSortField.dataIndex;
        return {
          ...currentState,
          companionSortField: field,
          uniqueItems,
          companionCollapsed: favoriteCompIsCollapsed ? favoriteCompIsCollapsed : false,
          companionSortDirection: direction,
        };
      }

      return currentState;
    } else {
      return {
        ...currentState,
        companionSortField,
        uniqueItems: [],
      };
    }
  }

  componentDidMount() {
    if (this._isUnMounted) {
      return;
    }
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: PricingOverTimeProps, prevState: State) {
    const { overTimeData, viewDefns } = this.props;

    if (!isEqual(prevProps.overTimeData, overTimeData) || !isEqual(prevProps.viewDefns, viewDefns)) {
      const updatedData = convertPivotItemsToRowData(
        overTimeData,
        viewDefns.grid.view as GridMeasureDefn[],
        ['addoff', 'eo', 'eff_aur', 'corpaddoff', 'corpexcl', 'cccurp', 'ccdiscountpct'],
        'stylecolor',
        'week'
      );

      this.setState({
        rowData: updatedData,
      });
    }

    if (!isEqual(prevProps, this.props) && !isEqual(prevState, this.state)) {
      this.isCompanionChange(prevState);
    }

    if (!isEqual(this.state.uniqueItems, prevState.uniqueItems)) {
      this.rebuildCompanionData();
    }
  }

  isCompanionChange(prevState: State) {
    if (this.props.dataLoaded) {
      const defaultCompanion = {
        companionSortField: this.props.defaultCompanionSortField.dataIndex,
        companionSortDirection: 'desc',
        companionCollapsed: false,
      };
      if (
        defaultCompanion.companionCollapsed == this.state.companionCollapsed ||
        defaultCompanion.companionSortDirection == this.state.companionSortDirection ||
        defaultCompanion.companionSortField == this.state.companionSortField
      ) {
        this.setState({
          isCompanionChangeOnDefault: false,
          isDefault: true,
        });
      } else if (prevState.companionData !== this.state.companionData) {
        this.setState({
          isCompanionChangeOnDefault: true,
          isDefault: false,
        });
      }
    }
  }
  updateConfigureSelections(selections: Option[]) {
    if (this.props.updateConfigureSelections) {
      this.props.updateConfigureSelections(selections);
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  rebuildCompanionData() {
    if (!this.props.dataLoaded) {
      return;
    }
    const companionData = companionDataParse(
      this.state.uniqueItems,
      this.props.companionDataLookup,
      this.state.companionSortDirection,
      this.state.companionSortField,
      this.state.companionLevelField
    );

    this.setState(
      {
        companionData,
      },
      () => {
        if (isEmpty(companionData)) {
          this.onCompanionItemClick('');
          this.setState({
            rowData: [],
          });
        } else {
          const selectedIndex = companionData.findIndex((datum) => datum.id === this.props.selectedId);
          if (selectedIndex === -1) {
            this.onCompanionItemClick(companionData[0].id);
          }
        }
      }
    );
  }

  onFabClick = () => {
    switch (this.props.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  /*  Start Companion List Events */

  onCompanionItemClick = (itemId: string) => {
    if (this.props.onCompanionItemClick) {
      this.props.onCompanionItemClick(itemId);
    }
  };

  onCompanionDirectionChange = (newDir: SortByDirection) => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionSortDirection: newDir, isDefault: false }, () => {
      this.rebuildCompanionData();
    });
  };

  onCompanionSortChange = (option: SortOption) => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionSortField: option.dataIndex, isDefault: false }, () => {
      this.rebuildCompanionData();
    });
  };

  onCompanionLevelChange = (option: SortOption) => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionLevelField: option.dataIndex, isDefault: false }, () => {
      this.rebuildCompanionData();
    });
  };

  onCompanionCollapseToggle = (collapse: boolean) => {
    if (this._isUnMounted) {
      return;
    }
    this.setState({ companionCollapsed: collapse });
  };

  /* End Companion List Events */

  onGridValueChange = (payload: GranularEditPayloadItem[]) => {
    // on grid value changes, don't want a data refresh, so pass false
    this.props.submitPayload(payload, false);
  };

  handleMassEditSubmission = (submission: MassEditSubmissionData) => {
    const { selectedModifier, selectedItems, selectedWeekList, modifierValue } = submission;

    if (!isNil(selectedModifier) && !isNil(selectedWeekList)) {
      // no null submission data expected, except for modifier value
      const updatedData: GranularEditPayloadItem[] = [];

      forEach(selectedWeekList, (weekNumber) => {
        const updatedWeekData = selectedItems.map((item) => {
          return {
            coordinates: {
              product: item.value, // styleColor id
              time: weekNumber,
            },
            [selectedModifier]: modifierValue,
          };
        });

        updatedData.push(...updatedWeekData);
      });

      if (this.props.submitPayload) {
        this.props.submitPayload(updatedData);
      }
    }
  };
  onUpdateConfig = (config: TenantConfigViewData) => {
    if (this.props.configLoaded && this.state.isDefault && config.isDefault) {
      this.setState({
        companionSortField: this.props.defaultCompanionSortField.dataIndex,
        companionSortDirection: 'desc',
        companionCollapsed: false,
        isDefault: true,
      });
    }
    this.props.onUpdateConfig(config);
  };

  generateExtraActionButtonProps(): LegacySubheaderActionButtonProps {
    const { massEditDataLoaded, massEditData, allowFrom, allowTo, rangeList, scopeStart, viewDefns } = this.props;
    const { massEdit: massEditConfig } = viewDefns;
    const configViewItems = MassEdit.getConfigViewItems(massEditConfig);
    const itemsDataIndex = !isEmpty(configViewItems) ? configViewItems[1].dataIndex : '';
    const editableItems =
      massEditData &&
      massEditData.flat.map((dataItem) => {
        const label = `${dataItem['name']}-${dataItem[itemsDataIndex]}`;
        return {
          value: dataItem['id'],
          label,
        };
      });

    const massEdit = isNil(massEditConfig)
      ? undefined
      : {
          config: massEditConfig,
          allowFrom,
          allowTo,
          scopeStart: scopeStart || '',
          rangeList,
          editableItems,
          title: 'Mass Copy Pricing Events',
          handleSubmit: this.handleMassEditSubmission,
          dataLoading: !massEditDataLoaded,
        };

    return {
      massEdit,
    };
  }

  render() {
    const {
      title,
      configLoaded,
      dataLoaded,
      overTimeDataLoaded,
      liveDataReady,
      isPrintMode,
      viewDataStateCompanion,
      viewDataStateOverTime,
      showFlowStatus,
      onItemClicked,
      selectedId,
      rowHeight,
      groupRowHeight,
      columnWidth,
      showUndoBtn,
    } = this.props;

    const sortOptions = dataLoaded ? this.props.sortOptions : [];
    const companionDataLookup = dataLoaded ? this.props.companionDataLookup : undefined;
    const companionData = this.state.companionData;
    const defaultSort = this.state.companionSortField;

    const defaultSelection = sortOptions.findIndex((option) => option.dataIndex === defaultSort);
    const selectedIndex = companionData.findIndex((datum) => datum.id === selectedId);

    const levelOptions = this.props.viewDefns?.listLevelBy?.view;
    const defaultLevelSelection = 0;

    const companionProps: CompanionProps = {
      defaultSelection: defaultSelection,
      defaultLevelSelection,
      sortOptions,
      levelOptions,
      isDataloaded: dataLoaded,
      label: 'Count',
      selectedIndex: selectedIndex,
      className: companionStyles,
      data: companionData,
      noImageUrl: noImage,
      dataLookup: companionDataLookup,
      initialSortDirection: this.state.companionSortDirection,
      onListItemClicked: this.onCompanionItemClick,
      onChangeDirection: this.onCompanionDirectionChange,
      onSortSelection: this.onCompanionSortChange,
      onToggleCollapse: this.onCompanionCollapseToggle,
      onLevelSelection: this.onCompanionLevelChange,
    };

    const pricingGridProps: PricingGridProps = {
      timeEntries: this.props.timeInfo.entries,
      rowData: this.state.rowData,
      rowHeight: this.props.dataLoaded ? rowHeight : 30,
      anchorField: this.props.scopeStart || this.props.timeInfo.planCurrent,
      events: this.props.events,
      editable: liveDataReady,
      onValueChange: this.onGridValueChange,
      onItemClicked,
      groupRowHeight,
      columnWidth: columnWidth,
      loaded: overTimeDataLoaded,
      adornments: this.props.adornments,
    };

    const viewConfigurator: ViewConfiguratorModalProps | undefined =
      configLoaded && this.props.configuratorViewDefn && this.props.unmodifiedViewDefn
        ? {
            viewConfig: this.props.configuratorViewDefn,
            unmodifiedViewDefn: this.props.unmodifiedViewDefn,
            updateConfig: this.onUpdateConfig,
            showPinCheckboxForGrid: false,
            companionData: {
              companionSortDirection: this.state.companionSortDirection,
              companionCollapsed: this.state.companionCollapsed,
              companionSortField: this.state.companionSortField,
            },
            defaultCompanionData: {
              companionSortDirection: 'desc',
              companionCollapsed: false,
              companionSortField: this.props.defaultCompanionSortField.dataIndex,
            },
          }
        : undefined;
    const errorCondition = [ASSORTMENT_BUILD_FILTER_WARNING];
    if (!liveDataReady) {
      errorCondition.push(CACHED_DATA_EDITING_DISABLED_WARNING);
    }

    return (
      <div className={listPairStyle}>
        <Overlay type="loading" visible={!configLoaded} />
        <Subheader
          title={title || ''}
          errorCondition={ASSORTMENT_BUILD_FILTER_WARNING}
          showSearch={true}
          showFlowStatus={showFlowStatus}
          showUndoBtn={showUndoBtn}
          summary={this.props.dataLoaded ? this.props.subheaderSummary : undefined}
          viewDataState={[viewDataStateCompanion, viewDataStateOverTime]}
          viewConfigurator={viewConfigurator}
          extraLegacyActionButtons={this.generateExtraActionButtonProps()}
        />
        <div className="data-container">
          {!isPrintMode && <CompanionListView {...companionProps} />}
          <PricingGrid ref={(el) => (this.pGrid = el)} {...pricingGridProps} />
        </div>
      </div>
    );
  }
}
