import { style } from 'typestyle';
import { negativeTrend, positiveTrend, neutralTrend, fadedNeutral } from 'src/common-ui/theme';
import { mediumBreakpoint, smallBreakpoint } from 'src/utils/Style/Theme';
import { rangePx } from 'src/utils/Style/Media';

export const choiceProductivityStyle = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 300,
  width: '33.333%',
  padding: '0.5rem 0',
  $nest: {
    [rangePx(0, smallBreakpoint)]: {
      width: '100%',
    },
    [rangePx(smallBreakpoint, mediumBreakpoint)]: {
      width: '50%',
    },
    '.left-container': {
      color: fadedNeutral,
      fontSize: '0.7rem',
      width: '8rem',
    },
    '.middle-container': {
      width: 65,
      height: 65,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.7rem',
      borderRadius: '50%',
      $nest: {
        '&.positive': {
          border: '3px solid ' + positiveTrend,
        },
        '&.negative': {
          border: '3px solid ' + negativeTrend,
        },
        '&.zero': {
          border: '3px solid ' + neutralTrend,
        },
      },
    },
    '.middle-container-skeleton': {
      border: 'none',
    },
    '.right-container': {
      marginLeft: '1rem',
      width: 80,
      whiteSpace: 'nowrap',
    },
    '.primary': {
      flex: 1,
      fontSize: '1.5rem',
      paddingLeft: 20,
    },
    '.secondary-skeleton': {
      display: 'flex',
      padding: 0,
      margin: 0,
    },
    '.dir': {
      lineHeight: '2rem',
      fontSize: '1.2rem',
      margin: '0 0.3rem 0 0',
    },
    '.up': {
      color: positiveTrend,
    },
    '.down': {
      color: negativeTrend,
    },
    '.neutral': {
      color: neutralTrend,
    },
    '.diff-container': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.diff': {
      fontSize: '1.2rem',
    },
    '.diff-skeleton': {
      width: '-webkit-fill-available',
    },
    '.arrow-skeleton': {
      width: '20px',
    },
    '.secondary': {
      fontSize: '0.6rem',
      textAlign: 'end',
      color: fadedNeutral,
      fontWeight: 500,
    },
  },
});
