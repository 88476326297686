import { ScopeMemberInfo } from 'src/services/Scope.client';
import { Space } from 'src/space';

export interface ControlPoints {
  [key: string]: Space<ReadonlyArray<ScopeMemberInfo>>;
}

export interface DropdownOption {
  text: string;
  value: string;
}

interface PlanMovement {
  left: string;
  right: string;
}

export interface PlanMovementConfig {
  adminWorkflows: PlanMovement[];
}

export enum WizardSection {
  Plan,
  Product,
  Location,
  Time,
  Summary,
}
