import { style } from 'typestyle';
import { px, important, percent, rem } from 'csx';

const SCROLLABLE_REGION_HORIZONTAL_SPACING = '24px';
const SCROLLABLE_REGION_VERTICAL_SPACING = '100px';

function buildStyleEditContainer(isPrintMode: boolean): string {
  return style({
    display: 'flex',
    flexDirection: 'column',
    ...(isPrintMode ? undefined : { height: '100%' }),
    $nest: {
      '.header-row': {
        fontWeight: 700,
      },
      '.data-container': {
        flex: 1,
        display: 'flex',
        minHeight: 1,
      },
    },
  });
}

const editViewContainer = style({
  $debugName: 'editViewContainer',
  flexGrow: 1,
  flexDirection: 'column',
  $nest: {
    '.header-row': {
      fontWeight: 700,
    },
    '.data-container': {
      flex: 1,
      display: 'flex',
      minHeight: 1,
    },
  },
});

function buildStyleEditOverview(isValidStyleName: boolean): string {
  return style({
    height: 76,
    marginLeft: isValidStyleName ? '0' : '0.5rem',
    width: '100%',
    zIndex: 40,
    position: 'sticky',
    top: 0,
    left: 0,
    background: 'white',
  });
}

const styleEditOverviewBackground = style({
  height: 100,
  position: 'sticky',
  width: '100%',
  background: 'white',
  zIndex: 40,
  top: 0,
});

const styleEditOverviewImages = style({
  display: 'flex',
  position: 'sticky',
  left: 0,
  marginBottom: '1rem',
  $nest: {
    img: {
      height: px(250),
      width: px(150),
      marginRight: px(20),
      objectFit: 'contain',
    },
    'div.overviewRatingsContainer': {
      marginTop: rem(0.25),
      paddingTop: px(20),
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '.star-ratings': {
          marginLeft: px(8),
        },
      },
      flexGrow: 2,
    },
  },
});

const exceptionList = style({
  $debugName: 'exceptionList',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'end',
  marginRight: 60,
  backgroundColor: '#eff0f0',
  padding: '10px 10px 10px 30px',
  marginTop: 20,
  width: 300,
  $nest: {
    '& .exception-icon': {
      position: 'relative',
      // we set height to 0 to allow box to basically ignore the element for flow purposes...kind of
      height: 0,
      // the following are directly correlated to the padding set in the parent and icon size (4px wider than tall)
      top: -10,
      left: -26,
      color: '#ff5c5c',
    },
  },
});

const styleEditFilterCount = style({
  marginTop: px(5),
  marginLeft: px(10),
  fontSize: rem(0.85),
});

const styleEditSections = style({
  overflow: 'visible',
  height: '90%',
});

const sectionText = style({
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: '1.4px',
  position: 'sticky',
  left: 24,
});

const stickySection = style({
  position: important('sticky'), // FIXME: :(
  $debugName: 'stickySection',
  top: 76,
  background: important('white'), // important overrides material-ui
  zIndex: 30,
});

const stickyHeaderArrow = style({
  $debugName: `stickyArrow`,
  $nest: {
    '& > div:last-child': {
      position: 'sticky',
      right: 8,
      // marginRight: 'unset',
      transform: 'none',
    },
  },
});

const stickyHeaderArrowExpanded = style({
  $debugName: `stickyArrowExpanded`,
  $nest: {
    '& > div:last-child': {
      position: 'sticky',
      right: 8,
      transform: 'translateY(-20%) rotate(180deg)',
    },
  },
});

const scrollableSectionDetails = style({
  $debugName: 'scrollableSectionDetails',
  overflow: 'visible',
  flexDirection: 'column',
  minHeight: px(250), // for multisection loader to display in section
});

const scrollableSectionDetailsExpanded = style({
  $debugName: 'scrollableSectionDetailsExpanded',
  margin: `
    ${SCROLLABLE_REGION_VERTICAL_SPACING}
    ${SCROLLABLE_REGION_HORIZONTAL_SPACING}
    ${SCROLLABLE_REGION_HORIZONTAL_SPACING}
    calc(${SCROLLABLE_REGION_HORIZONTAL_SPACING} - 8px)
  `, // '100px 24px 24px 24px'
  padding: important(0), // overrides material-ui's default padding
});

const sectionContainerWrapper = style({
  height: 632,
  width: '100%',
});

const companionMarginStyle = style({
  marginRight: '30px',
});

const multiSectionLoader = style({
  height: percent(50),
});

const headerColumnContainer = style({
  $debugName: 'headerColumnContainer',
  background: 'white',
  position: 'absolute',
  display: 'flex',
  height: '100px',
  width: `calc(100% - ${SCROLLABLE_REGION_HORIZONTAL_SPACING})`,
  top: 60,
  fontSize: 14,
  textAlign: 'center',
  padding: 0,
  paddingRight: important(0),
  overflow: 'visible',
  // the header can, in some browser contexts, overlap the underlying grid, and cause pointer events to go to the header,
  // instead of any element under the header.  Set events to none (and to 'auto' on child elements that need clicks),
  // so that this doesn't happen
  pointerEvents: 'none',
  $nest: {
    '.addColorDecoy': {
      width: '7rem',
      margin: '0px 19.2px',
      padding: '0px 16px',
    },
    '.columnColorContainer': {
      width: '100%',
      height: '100px',
      $nest: {
        '& > div:first-child': {
          overflow: 'hidden',
        },
        '.ag-pinned-left-cols-container': {
          maxWidth: important('208px'),
          width: important('208px'),
        },
      },
    },
  },
});

const headerStylingDecoy = style({
  $debugName: 'headerStylingDecoy',
  position: 'absolute',
  height: '100px',
  width: `calc(100% - ${SCROLLABLE_REGION_HORIZONTAL_SPACING})`,
  top: 60,
  pointerEvents: 'none',
  zIndex: 20,
  $nest: {
    '& > div': {
      width: 199,
      height: '100%',
      background: 'white',
      position: 'sticky',
      left: 0,
      pointerEvents: 'initial',
      cursor: 'initial',
    },
  },
});

export default {
  buildStyleEditContainer,
  buildStyleEditOverview,
  styleEditOverviewImages,
  styleEditFilterCount,
  editViewContainer,
  styleEditSections,
  sectionText,
  stickySection,
  scrollableSectionDetails,
  scrollableSectionDetailsExpanded,
  sectionContainerWrapper,
  companionMarginStyle,
  multiSectionLoader,
  stickyHeaderArrow,
  stickyHeaderArrowExpanded,
  headerColumnContainer,
  headerStylingDecoy,
  styleEditOverviewBackground,
  exceptionList,
};
