import { style } from 'typestyle';

const imageContainer = style({
  display: 'inline-block',
  position: 'absolute',
  margin: '1px',
  border: '1px solid transparent',
  boxSizing: 'border-box',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  overflow: 'hidden',
  transition: 'top 1.5s, left 1.5s, width 1.5s, height 1.5s, opacity 2s, border-color 0.2s, transform 2s',
});
const adornmentStyle = style({
  fontSize: '0.8rem',
  position: 'absolute',
  $nest: {
    span: {
      fontSize: 'inherit',
      $nest: {
        i: {
          fontSize: '0.7rem',
        },
      },
    },
    svg: {
      fontSize: 'inherit',
    },
  },
});
export default { imageContainer, adornmentStyle };
