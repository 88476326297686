import * as React from 'react';
import { isNil } from 'lodash';
import { style } from 'typestyle';

type ArrowPercentProps = {
  className: string | undefined;
  color: string | undefined;
  fontSize: string;
  top: number;
  result: string;
  arrowLeft: boolean;
};

type ArrowPercentStyles = Omit<ArrowPercentProps, 'result' | 'arrowLeft'>;

// TODO: kept same logic to not cause last minute bugs,
// but would prefer isNegative to be boolean, and to clean up conditionals
export function getArrowStyles(numberVal: number, isNegative: 1 | -1): ArrowPercentStyles {
  let className,
    color,
    top = 3,
    fontSize = '1.1rem';

  if (numberVal === 0 || isNil(numberVal)) {
    className = 'fa-minus';
    color = '#a5a5a5';
    fontSize = '0.9rem';
  } else if (isNegative < 0) {
    className = 'fa-sort-down';
    color = 'rgb(249, 10, 10)';
    top = -5;
  } else if (isNegative > 0) {
    className = 'fa-sort-up';
    color = 'rgb(21, 197, 85)';
    top = 5;
  }

  return {
    className,
    color,
    fontSize,
    top,
  };
}
const arrowStyle = (color: string | undefined, fontSize: string, top: number) => {
  return style({
    fontSize,
    top,
    position: 'relative',
    color,
    float: 'right',
    marginRight: 10,
  });
};

export function ArrowPercent({ className, color, fontSize, top, result, arrowLeft = false }: ArrowPercentProps) {
  const arrowClass = arrowStyle(color, fontSize, top);
  return (
    <div style={arrowLeft ? { width: '100%', display: 'flex' } : { width: '100%' }}>
      <i className={`fas  ${arrowClass} ${className}`} />
      <span>{result}</span>
    </div>
  );
}
