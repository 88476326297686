import React, { useCallback } from 'react';
import { Collapse } from '@material-ui/core';
import qs from 'query-string';
import { NavigationItem } from './NavigationItem';
import styles from 'src/components/Sidenav/Sidenav.styles';
import {
  BoundSection,
  BoundTab,
  BoundView,
  BoundEnabledPerspective,
} from 'src/services/configuration/codecs/bindings.types';
import { Tooltip } from '@material-ui/core';
import { FavoritesEntry } from 'src/services/Favorites';
import { getFirstMFPFavoriteComponentPath } from 'src/pages/NavigationShell/NavigationShell.utils';
import { viewRequiresMfpScope } from 'src/services/configuration/codecs/confdefnView';

interface NavigationGroupProps {
  expandable?: boolean;
  expanded: boolean;
  activeViewFrom: boolean;
  perspective: BoundEnabledPerspective;
  tab: BoundTab;
  section: BoundSection;
  collapsed?: boolean;
  setFocusedGroup: (groupId: string) => void;
  onItemClick: () => void;
  mfpFavoritesBoundViews?: JSX.Element;
  onlyIndirectMfpScoping: boolean;
  mfpScopeReady: boolean;
  tooltip?: string;
}

export function computeItemLink(
  perspective: BoundEnabledPerspective,
  tab: BoundTab,
  section: BoundSection,
  view: BoundView
): string {
  if (view && view.componentProps && view.componentProps.defaultPathSlot) {
    return (
      '/' +
      [perspective.pathSlot, tab.pathSlot, section.pathSlot, view.pathSlot, view.componentProps.defaultPathSlot].join(
        '/'
      )
    );
  }
  return '/' + [perspective.pathSlot, tab.pathSlot, section.pathSlot, view.pathSlot].join('/');
}

interface FavoriteGroupProps {
  favorites: FavoritesEntry[];
  pathname: string;
  groupId: string | null;
  onFaveRemoveClick: (e: React.MouseEvent<HTMLElement>, fave: FavoritesEntry) => void;
  queryString: qs.ParsedQuery;
  scopeId?: string;
  onItemClick: () => void;
  tab: BoundTab;
  onlyIndirectMfpScoping: boolean;
  mfpScopeReady: boolean;
}

export const FavoriteGroups = ({
  favorites = [],
  groupId = '',
  pathname = '',
  queryString = {},
  scopeId = '',
  onFaveRemoveClick,
  onItemClick,
  tab,
  onlyIndirectMfpScoping,
  mfpScopeReady,
}: FavoriteGroupProps) => {
  const pathPrefix = `/${pathname
    .split('/')
    .slice(1, 3)
    .join('/')}/`;

  const onNavigationItemClick = useCallback(() => onItemClick(), [onItemClick]);
  const globalMfpDisable = onlyIndirectMfpScoping && !mfpScopeReady;

  return (
    <React.Fragment>
      {favorites &&
        favorites.length > 0 &&
        favorites.map((favorite) => {
          const { key = '' } = favorite;
          // Get favorite path by searching for first available route with Favorite component.
          const favoritePath = `${pathPrefix}${getFirstMFPFavoriteComponentPath(tab)}?favoriteId=${encodeURIComponent(
            key
          )}&scope=${scopeId}`;
          const currentFavoritePath = queryString.favoriteId ? queryString.favoriteId : '';
          const active = key === currentFavoritePath;
          const removeMessage = `Remove ${key} from Favorites`;

          // presumed to be mfpfavorite at this level, so disabled when mfp global is disabled
          // if other favorites other than mfpfavorites end up here, we'll need to check for mfp favorites before
          // disabling when using `onlyIndirectMfpScoping`
          const disableByMfpScope = globalMfpDisable;

          return (
            <div key={key} className={styles.mfpFavoriteSidenav(active)}>
              <NavigationItem
                name={key}
                icon={'far fa-heart'}
                link={favoritePath}
                disabled={disableByMfpScope}
                qa={`${key}-link`}
                onClick={onNavigationItemClick}
              />
              <button
                aria-label={removeMessage}
                className="remove-favorite"
                onClick={(e) => onFaveRemoveClick(e, favorite)}
                title={removeMessage}
                type="button"
              >
                X
              </button>
            </div>
          );
        })}
    </React.Fragment>
  );
};

const NavigationGroup = ({
  perspective,
  tab,
  section,
  expanded,
  activeViewFrom,
  expandable = true,
  collapsed,
  setFocusedGroup,
  onItemClick,
  mfpFavoritesBoundViews,
  onlyIndirectMfpScoping,
  mfpScopeReady,
  tooltip,
}: NavigationGroupProps) => {
  const toggleGroup = useCallback(() => {
    setFocusedGroup(section.id);
  }, [section.id, setFocusedGroup]);

  const onNavigationItemClick = useCallback(() => onItemClick(), [onItemClick]);
  const globalMfpDisable = onlyIndirectMfpScoping && !mfpScopeReady;
  const configuredTooltip = tooltip ?? section.name;
  const text = collapsed ? configuredTooltip : '';

  return (
    <div className={`${styles.title}`}>
      <Tooltip title={text} enterDelay={0} enterNextDelay={0} placement="right-start" arrow={true}>
        <div
          data-qa="NavigationGroupToggle"
          onClick={toggleGroup}
          style={{ cursor: expandable ? 'pointer' : 'default' }}
          className={styles.groupCollapser(expanded, activeViewFrom)}
        >
          <div className="col-1">
            <i className={section.icon} />
          </div>
          <div className="col-8">{section.name}</div>
          <div className="col-1" style={{ visibility: expandable ? 'visible' : 'hidden' }}>
            <i
              className={`fas fa-angle-down ${expanded ? styles.expandedCaret : styles.collapsedCaret}`}
              data-qa={`${section.id}-caret`}
            />
          </div>
        </div>
      </Tooltip>
      <Collapse in={expanded}>
        <ul className="nav flex-column">
          {section.id !== 'favorites'
            ? section.boundViews.map((view) => {
                const disableByMfpScope = globalMfpDisable && viewRequiresMfpScope(view);
                return (
                  <NavigationItem
                    name={view.name}
                    errorView={view.errorView}
                    icon={view.icon}
                    link={computeItemLink(perspective, tab, section, view)}
                    disabled={view.disabled || disableByMfpScope}
                    hidden={view.hidden}
                    key={`${perspective.title}-${tab.id}-${section.id}-${view.id}`}
                    qa={`${view.id}-link`}
                    collapsed={collapsed}
                    onClick={onNavigationItemClick}
                    tooltip={view.tooltip}
                  />
                );
              })
            : mfpFavoritesBoundViews}
        </ul>
      </Collapse>
    </div>
  );
};

export const MemoizedNavigationGroup = React.memo(NavigationGroup);
