import { AxiosResponse } from 'axios';
import { isNil, keys, omitBy, isObject } from 'lodash';
import Axios from 'src/services/axios';
import ServiceContainer from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import type { PivotService } from 'src/services/pivotService';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

export function getStyleColorsWithAttributes(styleId: string, returnTree = false): Promise<BasicPivotItem[]> {
  return ServiceContainer.pivotService
    .listData('StyleReview', AppType.Assortment, {
      aggBy: 'level:style,level:stylecolor',
      topMembers: styleId,
    })
    .then((resp) => {
      if (returnTree) {
        return resp.tree;
      }
      // wrapping for now to minimize refactoring in StyleEditSection.container.tsx
      return (resp.flat as unknown) as BasicPivotItem[];
    })
    .then((stylecolors) => {
      return stylecolors;
    });
}

export function getStyleColors(pivotService: PivotService, styleId: string, returnTree = false): Promise<BasicPivotItem[]> {
  return pivotService
    .listData('StyleEditReview', AppType.Assortment, {
      aggBy: 'level:style,level:stylecolor',
      topMembers: styleId,
      nestData: false,
    })
    .then((resp) => {
      if (returnTree) {
        return resp.tree;
      }
      // wrapping for now to minimize refactoring in StyleEditSection.container.tsx
      return (resp.flat as unknown) as BasicPivotItem[];
    })
    .then((stylecolors) => {
      return stylecolors;
    });
}

// only return removable items
function filterRemovable(canRemoveResponse: Record<string, boolean>) {
  return keys(omitBy(canRemoveResponse, (value) => value === false));
}

export async function canRemoveFromAssortment(
  styleColors: string[]
): Promise<string[]> {
  const canRemoveResponse = await Axios.get(`/api/assortment/canRemoveFromAssortment`, {
    params: {
      appName: ASSORTMENT,
      productIds: styleColors.join(','),
    },
  })

  return filterRemovable(canRemoveResponse.data.data);
}

export function getStyleColorsAttr(styleId: string, _includeNotAssortment?: boolean): Promise<BasicPivotItem[]> {
  return Axios.get(`api/attribute/all`, {
    params: {
      appName: 'Assortment',
      memberId: styleId,
      childLevel: 'stylecolor',
    },
  })
    .then((resp) => {
      return resp.data.success ? resp.data.data : [];
    })
    .then((stylecolors: BasicPivotItem[]) => {
      return stylecolors;
    });
}

export function getStyleAttributes(styleId: string): Promise<BasicPivotItem> {
  return Axios.get(`/api/attribute/getAllAttributes`, {
    params: {
      appName: 'Assortment',
      memberId: styleId,
    }
  })
    .then((resp) => {
      return resp.data.success ? (resp.data.data as BasicPivotItem) : ({} as BasicPivotItem);
    })
    .catch(() => {
      return {} as BasicPivotItem;
    });
}

// Extracts the first key's values as is structured in the '/api/v2/dependents' endpoint.
export function getDependentsFromResp(resp: { (s: string): string[] }): string[] {
  if (isObject(resp) && Object.keys(resp).length > 0) {
    return resp[Object.keys(resp)[0]];
  } else {
    return [];
  }
}

export async function checkAssortmentInProg(): Promise<boolean> {
  const result = await Axios.get('/api/assortment/queue/size?appName=Assortment');
  const count = result.data.data;
  return count > 0;
}

export function updateStyleItem(newData: Record<string, any>): Promise<AxiosResponse> {
  // update style member or attribute
  return Axios.post('/api/assortment/product?appName=Assortment', newData);
}

export function getProductItem(id: string): Promise<AxiosResponse> {
  // fetch product infomation (member data + attributes)
  const encodedValue = encodeURIComponent(id);

  return Axios.get('/api/assortment/product', {
    params: {
      appName: ASSORTMENT,
      memberId: encodedValue,
    },
  });
}

export enum ValidationLevel {
  style = 'style',
  dept = 'department',
}

export async function validateChoiceAtLevel(
  requestedName: string,
  level: ValidationLevel,
  invertValidation: boolean
): Promise<{
  isValid: boolean;
  invalidLevel: string | null;
}> {
  const url = `/api/member/nameExists?name=${requestedName}&levelId=${level}`;
  const resp = await Axios.get(url);
  const success = resp.data.success;
  let isValid = success;
  let invalidLevel: string | null = null;

  const levelId = success ? (resp.data.data.levelId as string).toLowerCase() : '';
  if (success && levelId !== 'stylecolor') {
    // invalid level detected, no longer valid
    isValid = !success;
    invalidLevel = levelId;
  }

  // expects choice to exist at level (valid)
  if (!invertValidation) {
    return {
      isValid,
      invalidLevel,
    };
  }

  // expects choice not to exist at level
  isValid =
    success === true
      ? false // choice already exists at level (invalid)
      : true; // choice is available at level

  return {
    isValid,
    invalidLevel,
  };
}

export function validateStyleName(requestedName: string): Promise<boolean> {
  const encodedValue = encodeURIComponent(requestedName);

  return Axios.get(`/api/member/nameExists?name=${encodedValue}&levelId=style`).then((resp) => {
    return resp.data.success === true
      ? false // name already exists (invalid)
      : true; // name is available
  });
}

export function checkNameConflict(item: BasicPivotItem, items: BasicPivotItem[]): boolean {
  if (isNil(item.valid) || item.valid === true) {
    if (item.type === 'existing') {
      return true;
    }
    return items.findIndex((cartItem) => cartItem.name === item.name && cartItem !== item) < 0;
  }
  return false;
}

type StyleNameValidation = {
  valid: boolean;
  name: string;
};
export async function validateAllStyleNames(items: BasicPivotItem[]): Promise<StyleNameValidation[]> {
  return await Promise.all(
    items.map(async (item) => {
      return {
        valid: (item.type === 'existing' || (await validateStyleName(item.name))) && checkNameConflict(item, items),
        name: item.name,
      };
    })
  );
}
