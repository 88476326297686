// importing only number reduces bundle size by about 250kb
// the type lib doesn't have a specific type for this narrow module, so we ignore the error and assert below
// @ts-ignore
import { create, all } from 'mathjs/lib/esm/number';
import type { create as mcreate } from 'mathjs';

// this sets up a global evaluate object with some of the more dangerous functions disabled
// First load objects and functions you want
const math = create(all) as ReturnType<typeof mcreate>;
const limitedEvaluate = math.evaluate;

// null guard, because the type thinks these are nullable
if (!limitedEvaluate || !math || !math.import) { throw new Error('Unable to start the math evaluator'); }

// these force MathJS to throw if a user tried to enter any of these functions in a gridcell
math.import({
  'import': function () { throw new Error('Function import is disabled'); },
  'createUnit': function () { throw new Error('Function createUnit is disabled'); },
  'evaluate': function () { throw new Error('Function evaluate is disabled'); },
  'parse': function () { throw new Error('Function parse is disabled'); },
  'simplify': function () { throw new Error('Function simplify is disabled'); },
  'derivative': function () { throw new Error('Function derivative is disabled'); }
}, { override: true });

export default limitedEvaluate!;
