import { BasicItem } from 'src/types/Scope';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { Option } from 'src/components/Configure/ConfigureModal';
import { CompanionFavoriteData } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { FilterModelType } from 'src/components/Sidenav/SideNav.slice';
import { ColumnState } from '@ag-grid-community/core';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export const DEFAULT_FAVORITE_ID = 'DEFAULT_FAVORITE_bd57c309';

export interface FavoriteSubheader {
  groupByDataIndexSelection: string | null;
  configureDataIndexSelections: string[];
  sortByDataIndexSelection: string | null;
  sortByDirection: SortByDirection;
  pareDownValueSelections: string[];
  flowStatusSelections: number[];
  limitSelection: number | null;
}
export interface FavoriteCard {
  /** An array of dataIndex values for columns to be visible on card */
  columns: string[];
}

interface FavoriteGridColumn {
  dataIndex: string;
  pinned: string | boolean | undefined;
  visible: boolean | undefined;
}
export interface FavoriteGrid {
  columns: FavoriteGridColumn[];
  gridColumnState: ColumnState[];
  gridFilterModel: FilterModelType | null;
}

export interface FavoriteCompanion {
  collapsed: boolean;
  flowStatusSelections: number[];
  sortByDataIndexSelection: string | null;
  sortByDirection: SortByDirection;
  levelByDataIndexSelection: string | null;
}

export const DEFAULT_FAVORITE_COMPANION: FavoriteCompanion = {
  collapsed: true,
  flowStatusSelections: [],
  sortByDataIndexSelection: null,
  sortByDirection: 'desc',
  levelByDataIndexSelection: null,
};

export enum AssortmentFavoriteType {
  default = 'default',
  card = 'card',
  grid = 'grid',
  gridCompanion = 'gridCompanion',
  subheaderOnly = 'subheaderOnly',
  notImplemented = 'notImplemented',
}

export interface AssortmentFavorite {
  type: AssortmentFavoriteType;
  /** The version of the viewdefn that this favorite was saved against for quick validation */
  version: number;
  /** frontend generated cuid */
  id: string;
  /** user provided name for favorite */
  name: string;
  subheader: FavoriteSubheader;
  card: FavoriteCard;
  grid: FavoriteGrid;
  companion: FavoriteCompanion;
}

export interface ValidatedAssortmentFavorite extends AssortmentFavorite {
  /**
   * frontend injected validation flag that determines
   * if favorite matches the currently loaded viewDefn
   * */
  isValid: boolean;
}

/**
 *
 * @param defaultCompanionData
 * @param {number} currentVersion The version of the currently loaded viewdefn for a component
 * @returns {ValidatedAssortmentFavorite}
 */
export function getDefaultAssortmentFavorite(
  defaultCompanionData: FavoriteCompanion,
  currentVersion: number
): ValidatedAssortmentFavorite {
  return {
    isValid: true,
    type: AssortmentFavoriteType.default,
    version: currentVersion,
    id: DEFAULT_FAVORITE_ID,
    name: 'Default View',
    subheader: {
      groupByDataIndexSelection: null,
      configureDataIndexSelections: [],
      sortByDataIndexSelection: null,
      sortByDirection: 'desc',
      pareDownValueSelections: [],
      flowStatusSelections: [],
      limitSelection: null,
    },
    card: { columns: [] },
    grid: {
      columns: [],
      gridColumnState: [],
      gridFilterModel: null,
    },
    companion: defaultCompanionData,
  };
}

export const DEFAULT_ASSORTMENT_FAVORITE_RESPONSE: FavoriteResponse = {
  favorite_name: '',
  jsonBlob: getDefaultAssortmentFavorite(DEFAULT_FAVORITE_COMPANION, 1),
  key: '',
  module: '',
  user_id: '',
  active: false,
};

export interface SliceViewDefnUpdaterWrapper {
  (options: ApplyFavoriteOptions): void;
}

export interface FavoritableComponent {
  favoriteType: AssortmentFavoriteType;
  favoriteDefnId: string;
  favoriteOverrides?: {
    subheader?: Partial<FavoriteSubheader>;
    companion?: Partial<FavoriteCompanion>;
  };
  /**
   * A function triggered when a favorite selection is changed in the subheader,
   * it handles setting up favorites in global and component store state
   *
   * // TODO: will not be optional once all components properly implement new favorites
   * */
  favoriteViewDefnUpdater?: SliceViewDefnUpdaterWrapper;
}

export interface FavoriteResponse {
  favorite_name: string;
  jsonBlob: AssortmentFavorite;
  /** a server generated id */
  key: string;
  module: string;
  user_id: string;
  active: boolean;
}

export interface ValidatedFavoriteResponse extends FavoriteResponse {
  jsonBlob: ValidatedAssortmentFavorite;
}

/** @deprecated see AssortmentFavorite, this will be removed after favorites improvements are completed */
export interface FavoriteListItemStorage {
  config?: TenantConfigViewData;
  icon?: string;
  groupBySelection?: number;
  sortBySelection?: number;
  sortByDirection?: string;
  groupByDataIndexSelection?: string;
  sortByDataIndexSelection?: string;
  flowStatus?: number[];
  configurationSelections?: Option[];
  pareDownSelections?: TenantConfigViewItem[];
  limitSelection?: number;
  companionData?: CompanionFavoriteData;
  gridFilterModel?: FilterModelType;
  gridSortModel?: ColumnState[];
}

/** @deprecated see AssortmentFavorite, this will be removed after favorites improvements are completed */
export interface FavoriteListItem extends BasicItem, FavoriteListItemStorage {}

/** @deprecated see FavoriteResponse, this will be removed after favorites improvements are completed */
export interface FavoriteResponseItem {
  favorite_name: string;
  jsonBlob: FavoriteListItem | null;
  key: string;
  module: string;
  user_id: string;
  active: boolean;
}

/** @deprecated see AssortmentFavorite, this will be removed after favorites improvements are completed */
export const DEFAULT_FAVORITE_RESPONSE: FavoriteResponseItem = {
  favorite_name: '',
  jsonBlob: null,
  key: '',
  module: '',
  user_id: '',
  active: false,
};

/** @deprecated see getDefaultAssortmentFavorite, this will be removed after favorites improvements are completed */
export const DEFAULT_FAVORITE = (
  unmodifiedViewDefn: TenantConfigViewData,
  defaultCompanionData?: CompanionFavoriteData
): FavoriteListItem => ({
  id: DEFAULT_FAVORITE_ID,
  name: 'Default View',
  icon: 'fal fa-sync-alt',
  config: unmodifiedViewDefn,
  groupBySelection: 0,
  sortBySelection: 0,
  sortByDirection: 'desc',
  flowStatus: [],
  pareDownSelections: [],
  limitSelection: 0,
  companionData: defaultCompanionData,
});

export interface ApplyFavoriteOptions {
  // props required by favorites component
  defaultSubheader?: FavoriteSubheader;

  // props required by favoritable component to load config/favorites
  favoriteDefnId?: string;

  validVersion: number;
  isDefault?: boolean;
}
